import React from "react";
import usePostGoogleLogin from "./Data/usePostGoogleLogin";
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { myContext } from "App";
import { useNavigate } from 'react-router-dom'

function GoogleLoginLoader() {
const {isLoggedIn,setIsLoggedIn} = useContext(myContext)
const history = useNavigate();

React.useEffect(() => {
  const fetchUserData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      console.log(queryParams)

      const token = queryParams.get('code');
      console.log(token)
      localStorage.setItem('authToken', token);
      localStorage.setItem('query', queryParams);
      // queryParams

      if (token) {
        postGoogleLogin({token:token})
          // Store the token in localStorage or any secure storage
          // localStorage.setItem('authToken', token);
          

          // Fetch user data if needed
          // const response = await axios.get('http://localhost:8000/dj-rest-auth/user/', {
          //     headers: {
          //         Authorization: `Token ${token}`,
          //     },
          // });

          // const userData = response.data;
          // // Store or use the user data as needed
          // console.log(userData);
          // history.push('/');  // Redirect to the desired page after login
      }
  };

  fetchUserData();
}, [history]);
  const [
    googleLoginData,
    googleLoginError,
    googleLoginIsLoading,
    postGoogleLogin,
    setSuccessGoogleLoginData,
    setGoogleLoginError,
  ] = usePostGoogleLogin();
  if(googleLoginError){
    console.log(googleLoginError)
  }
  var token;
var expiryDate;
//   if(googleLoginData ){
// if(googleLoginData.status_code === 200){
//   setIsLoggedIn(true)
//   console.log('logged in');
//   token = googleLoginData.data.token;
// expiryDate = googleLoginData.data.expiry;
// localStorage.setItem("token", token);
// localStorage.setItem("expiry", expiryDate);
// return <Navigate to={'/dashboard'} />
// }
//   }else{
//     return <Navigate to={'/dashboard'} />

//   }
  return <div>Loading...</div>;
}

export default GoogleLoginLoader;
