import React from "react";
import IncomeTaxBasicProfile from "./IncomeTaxBasicProfile";
import IncomeTaxReturns from "./IncomeTaxReturns";

function IncomeTax() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 md:col-span-8">
        <IncomeTaxReturns/>
      </div>
      <div className="col-span-4 hidden md:block">
        <IncomeTaxBasicProfile />
      </div>
    </div>
  );
}

export default IncomeTax;
