import React, { useState, useEffect } from "react";
import BankAccounts from "./BankAccounts";
import MobileBankAccounts from "./MobileBankAccounts";

const Accounts = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? <MobileBankAccounts /> : <BankAccounts />}
    </div>
  );
};

export default Accounts;
