import Button from "components/Button/BtnOrange";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import useGetCategoryAsset from "../data/useGetCategoryAsset";
import AddNewDepositType from "./AddNewDepositType";
import {useLocation} from "react-router-dom";
import WhiteButton from "components/Button/WhiteButton";

const AddNewDeposit = () => {
  const location = useLocation();
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const addDepositBtnRef = useRef(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  const [getData, getError, getIsLoading, getCategoryAsset] =
    useGetCategoryAsset();

  useEffect(() => {
    getCategoryAsset("DEPOSITS");
  }, []);

  const handleLandClick = (depositType, assetId) => {
    setSelectedDeposit(depositType);
    setSelectedAssetId(assetId);
    addDepositBtnRef.current.click();
  };

  const depositToBeDisabled = [
    "Fixed Deposit",
    "Provident Fund",
    "Term Insurance",
  ];

  return (
    <div>
      <DialogBox
        triggerElement={
          location.pathname === "/assets/" ? 
          <WhiteButton
          px={"px-[0.8rem]"}
          py={"py-[0.3rem]"}
          name="Add New"
        />
          : 
          <Button
            px={"px-[0.9rem]"}
            py={"py-[0.3rem]"}  
            name="+ Add New Deposit"
          />
        }
        heading={
          <h4 className="text-lightGray font-[500] text-[1rem]">
            Add to your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem] m-0">
              Choose Deposit type
            </h4>
            <div className="border rounded-md mt-[1rem] mb-[0.6rem]">
              <div className="grid grid-cols-2 gap-[1rem] p-[0.8rem]">
                {getData?.data && (
                  <>
                    {getData?.data?.map((item) => {
                      const isDisabled = depositToBeDisabled.includes(
                        item.asset_name
                      );

                      return (
                        <div
                          onClick={() => {
                            if (!isDisabled) {
                              handleLandClick(item.asset_name, item.id);
                              handleClose();
                            }
                          }}
                          className={`cursor-pointer bg-[#FAF8FC] border-1 border-[#EAE1F3] rounded-md flex flex-col justify-center items-center p-[0.8rem] ${
                            isDisabled
                              ? "opacity-50 cursor-not-allowed"
                              : "hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out"
                          } overflow-hidden`}
                        >
                          <img
                            src={item.asset_image}
                            alt="building"
                            className="w-7 h-7 mb-[0.3rem]"
                          />

                          <h5 className="text-center text-darkGray font-[500] text-[1rem] m-0">
                            {item.asset_name}
                          </h5>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      />

      <AddNewDepositType
        depositType={selectedDeposit}
        assetId={selectedAssetId}
        triggerElement={
          <button ref={addDepositBtnRef} className="hidden">
            Add Deposit
          </button>
        }
      />
    </div>
  );
};

export default AddNewDeposit;
