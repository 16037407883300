import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import AddNewDeposit from "./AddNewDeposit";
import WhiteButton from "components/Button/WhiteButton";
import { Link, useLocation } from "react-router-dom";
import {
  ASSETS_DEPOSITS_DETAILED_URL,
  ASSETS_TYPE_URL,
} from "constants/routes";
import fixedD from "assets/images/assets/fixedD.svg";
import provident from "assets/images/assets/provident.svg";
import termInsurance from "assets/images/assets/termInsurance.svg";
import sukanya from "assets/images/assets/sukanya.svg";
import AddNewDepositType from "./AddNewDepositType";
import useGetRealEstateOverview from "../data/useGetAssetTypeOverview";

const Deposits = () => {
  const location = useLocation();
  const addNewBtnRef = useRef(null);
  const [selectedDeposit, setSelectedDeposit] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedAssetId, setSelectedAssetId] = useState("");

  const asset_type = location.state?.asset_type;

  const assetId = location.state?.assetId;
  // console.log("sukanya id:", assetId["Sukanya Samriddhi Yojana"])
  
  const [getData, getrror, getIsLoading, getRealEstate] =
    useGetRealEstateOverview();

  const handleAddNew = (depositType, assetId) => {
    setSelectedDeposit(depositType);
    setSelectedAssetId(assetId);
    addNewBtnRef.current.click();
  };

  useEffect(() => {
    getRealEstate("deposits");
  }, []);

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset_type={asset_type} asset={"Deposits"} />
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">₹{getData?.data?.total_assets_value}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getData?.data?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getData?.data?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getData?.data?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddNewDeposit />
        </div>
      </div>

      {/* Fixed Deposit */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Fixed Deposit
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              0 Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                disabled={true}
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
                onClick={() => handleAddNew("Fixed Deposit")}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {/* <Link
            to={ASSETS_DEPOSITS_DETAILED_URL.replace(
              ":deposit_type",
              "fixed-deposit"
            )}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={fixedD} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Fixed Deposit
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ 55,00,000
                </h4>
              </div>{" "}
            </div>
          </Link> */}

          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={fixedD} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Fixed Deposit
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>
        </div>
      </div>

      {/* Provident Funds */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Provident Funds
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              0 Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                disabled={true}
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
                onClick={() => handleAddNew("Provident Funds")}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {/* <Link
            to={ASSETS_DEPOSITS_DETAILED_URL.replace(
              ":deposit_type",
              "provident-fund"
            )}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={provident} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Provident Fund
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ 55,00,000
                </h4>
              </div>{" "}
            </div>
          </Link> */}

          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={provident} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Provident Fund
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>
        </div>
      </div>

      {/* Term Insurance */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Term Insurance
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              0 Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                disabled={true}
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
                onClick={() => handleAddNew("Term Insurance")}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {/* <Link
            to={ASSETS_DEPOSITS_DETAILED_URL.replace(
              ":deposit_type",
              "term-insurance"
            )}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={termInsurance} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Term Insurance
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ 55,00,000
                </h4>
              </div>{" "}
            </div>
          </Link> */}

          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={termInsurance} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Term Insurance
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>
        </div>
      </div>

      {/* sukanya samriddhi yojana*/}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Sukanya Samriddhi Yojana
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
            {getData?.data?.ssy[0]?.total_SSY_deposit_value ? getData?.data?.ssy[0]?.total_SSY_deposit_value : 0} Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
                onClick={() =>
                  handleAddNew("Sukanya Samriddhi Yojana", assetId["Sukanya Samriddhi Yojana"])
                }
              />
            </div>  
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {getData?.data?.ssy?.length !== 0 ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "deposits-sukanya-samridhi"
              )}?id=${assetId["Sukanya Samriddhi Yojana"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={sukanya} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Sukanya Samriddhi Yojana
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.ssy[0]?.total_SSY_deposit_value}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={sukanya} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Sukanya Samriddhi Yojana
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() => handleAddNew("Sukanya Samriddhi Yojana")}
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      <AddNewDepositType
        assetId={selectedAssetId}
        depositType={selectedDeposit}
        triggerElement={
          <button ref={addNewBtnRef} className="hidden">
            Add New
          </button>
        }
      />
    </div>
  );
};

export default Deposits;
