import React from "react";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PAGE_URL } from "constants/routes";

const HeadingProfile = () => {
  const navigate = useNavigate();
  const handlePrevious = () => {
    navigate(DASHBOARD_PAGE_URL);
  };

  return (
    <div className="pt-[2.2rem] pb-[1.7rem] gap-[1rem] items-center md:flex hidden">
      <button onClick={handlePrevious}>
        <img src={previousBtn} alt="" className="w- h-" />
      </button>
      <h4 className="text-[#334155] text-[1.4rem] font-[500] m-0">Profile</h4>
    </div>
  );
};

export default HeadingProfile;
