import useAPICall from "hooks/useAPICall";
import { POST_UPLOAD_DOCUMENTS_URL } from "constants/apis";

const usePostDocs = () => {
  const [
    docUploadData,
    docUploadErrors,
    docUploadIsLoading,
    callPostDocs,
    setSuccessdocUploadData,
    setdocUploadErrors,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setdocUploadErrors(en.something_went_wrong);
    setSuccessdocUploadData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const docUploadData = res;
        if (docUploadData && typeof docUploadData === "object") {
          setSuccessdocUploadData(docUploadData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
  // console.log(localStorage.getItem('token'))
  const postDocs = (body) => {
    const url = POST_UPLOAD_DOCUMENTS_URL;
    // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
    var config = {
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    // console.log(body)
    console.log("postDocs running");
    callPostDocs({
      url,
      method: "POST",
      statusObj,

      config,
      defaultFallback,
      body,
    });
  };
  return [
    docUploadData,
    docUploadErrors,
    docUploadIsLoading,
    postDocs,
    setSuccessdocUploadData,
    setdocUploadErrors,
  ];
};

export default usePostDocs;
