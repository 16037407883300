import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";
import upload from "assets/images/liabilities/upload.svg";
import usePatchIntellectualDetails from "../../data/usePatchIntellectualDetails";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const EditIntellectual = ({ editValue, detailData, setIsReload }) => {
  const { estate_type } = useParams();
  const removeBtnRef = useRef(null);
  const [name, setName] = useState("");
  const [assetType, setAssetType] = useState("active");
  const [errors, setErrors]= useState({})
  const [isClose, setIsClose]=useState(false);

  const [
    patchIntellectualData,
    patchIntellectualError,
    patchIntellectualIsLoading,
    patchIntellectualDetails,
  ] = usePatchIntellectualDetails();

  const [intellectualForm, setIntellectualForm]= useState({
    ip_type:"",
    account_holder:"",
    asset_ip_name:"",
    registration_number:"",
    class_or_type_of_work:"",
    registration_date:"",
    status:false,
    reminder:null,
    document:null,
    amount_invested:"",
    estimated_sale_value:""
  })

  useEffect(()=>{
    if(patchIntellectualData){
      showSuccessToast("Data updated successfully")
      setIsReload(true)
      setIsClose(true)
    }
    if(patchIntellectualError){
      showErrorToast("Error in Updating")
    }
  },[patchIntellectualData, patchIntellectualError])

  const handleInputChange=(e)=>{
    let { name, value, type, files } = e.target;
     if(type==="radio"){
        const bool= value==="true"?true: value==="false"?false:null;
        value=bool
     }
    setIntellectualForm((prevForm) => ({
      ...prevForm,
      [name]: type === 'file' ? files[0] : value
    }));
  }

  useEffect(()=>{
    if(detailData){
       const {total_active_assets, total_assets, total_assets_amount, total_idle_assets, user, ...rest}=detailData;
       setIntellectualForm(rest)
    }
  },[detailData])

  const validateForm = () => {
    const newErrors = {};
  
    if(editValue==="intellectualValue"){
        // Amount Invested: Required, numeric, and positive
      //   if (!intellectualForm.amount_invested) {
      //   newErrors.amount_invested = "Amount invested is required.";
      // } else if (isNaN(intellectualForm.amount_invested) || intellectualForm.amount_invested <= 0) {
      //   newErrors.amount_invested = "Amount invested should be a positive number.";
      // }
    
      // // Estimated Sale Value: Required, numeric, and positive
      // if (!intellectualForm.estimated_sale_value) {
      //   newErrors.estimated_sale_value = "Estimated sale value is required.";
      // } else if (isNaN(intellectualForm.estimated_sale_value) || intellectualForm.estimated_sale_value <= 0) {
      //   newErrors.estimated_sale_value = "Estimated sale value should be a positive number.";
      // }
    }else if(editValue==="intellectualInfo"){
        // IP Type: Required (dropdown)
        if (!intellectualForm.ip_type) {
          newErrors.ip_type = "IP Type is required.";
        }

        // Account Holder: Required (dropdown)
        if (!intellectualForm.account_holder) {
          newErrors.account_holder = "Account holder is required.";
        }

        // Asset IP Name: Required, only letters and spaces allowed
        const namePattern = /^[a-zA-Z\s]+$/;
        if (!intellectualForm.asset_ip_name) {
          newErrors.asset_ip_name = "Asset IP name is required.";
        } else if (!namePattern.test(intellectualForm.asset_ip_name)) {
          newErrors.asset_ip_name = "Asset IP name should only contain letters and spaces.";
        }

        // Registration Number: Optional, only alphanumeric characters allowed
        const registrationPattern = /^[a-zA-Z0-9]+$/;
        if(!intellectualForm.registration_number){
          newErrors.registration_number="Registration Number is required"
        }
        else if (intellectualForm.registration_number && !registrationPattern.test(intellectualForm.registration_number)) {
          newErrors.registration_number = "Registration number should be alphanumeric.";
        }

        // Class or Type of Work: Required (dropdown)
        if (!intellectualForm.class_or_type_of_work) {
          newErrors.class_or_type_of_work = "Class or type of work is required.";
        }

        // Registration Date: Required, valid date
        // if (!intellectualForm.registration_date) {
        //   newErrors.registration_date = "Registration date is required.";
        // } else if (isNaN(new Date(intellectualForm.registration_date).getTime())) {
        //   newErrors.registration_date = "Registration date should be a valid date.";
        // }

        // Reminder: Optional, valid date
        // if (intellectualForm.reminder && isNaN(new Date(intellectualForm.reminder).getTime())) {
        //   newErrors.reminder = "Reminder should be a valid date.";
        // }

        // if(typeof(intellectualForm.document==="string")){

        // }else{
        //     // Document: Required file, must be a valid file format (PDF, JPG, or PNG)
        //     const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
        //     if (!intellectualForm.document) {
        //       newErrors.document = "Document file is required.";
        //     } else if (!filePattern.test(intellectualForm.document.name)) {
        //       newErrors.document = "Document should be a valid file format (PDF, JPG, or PNG).";
        //     }
        // } 
    } 
    // Set error state and return whether there are errors
    setErrors(newErrors);
    console.log('newErrors', newErrors)
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = () => {
    if (!validateForm()) return;
  
    const formData = new FormData();
    
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append the common 'id' field
    formData.append("id", intellectualForm.id);
  
    // Handle different edit values
    if (editValue === "intellectualValue") {
      if (isNotEmpty(intellectualForm.amount_invested)) {
        formData.append("amount_invested", intellectualForm.amount_invested);
      }
      if (isNotEmpty(intellectualForm.estimated_sale_value)) {
        formData.append("estimated_sale_value", intellectualForm.estimated_sale_value);
      }
    } else if (editValue === "intellectualInfo") {
      if (isNotEmpty(intellectualForm.ip_type)) {
        formData.append("ip_type", intellectualForm.ip_type);
      }
      if (isNotEmpty(intellectualForm.account_holder)) {
        formData.append("account_holder", intellectualForm.account_holder);
      }
      if (isNotEmpty(intellectualForm.asset_ip_name)) {
        formData.append("asset_ip_name", intellectualForm.asset_ip_name);
      }
      if (isNotEmpty(intellectualForm.registration_number)) {
        formData.append("registration_number", intellectualForm.registration_number);
      }
      if (isNotEmpty(intellectualForm.class_or_type_of_work)) {
        formData.append("class_or_type_of_work", intellectualForm.class_or_type_of_work);
      }
      if (isNotEmpty(intellectualForm.registration_date)) {
        formData.append("registration_date", intellectualForm.registration_date);
      }
      if (isNotEmpty(intellectualForm.status)) {
        formData.append("status", intellectualForm.status);
      }
      if (isNotEmpty(intellectualForm.reminder)) {
        formData.append("reminder", intellectualForm.reminder || "");
      }
      if (typeof intellectualForm.document !== "string" && isNotEmpty(intellectualForm.document)) {
        formData.append("document", intellectualForm.document);
      }
    }
  
    // Debugging: Log FormData entries
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    console.log("Form in submission:", intellectualForm);
  
    // Send formData to your API
    patchIntellectualDetails(formData, intellectualForm.asset);
  };
  
  useEffect(() => {
    if (editValue) {
      switch (editValue) {
        case "intellectualValue":
          setName("Edit IP Vlaue");
          break;
        case "intellectualInfo":
          setName("Edit IP Information");
          break;
        default:
          break;
      }
    }
  }, [editValue]);
  const toggleAssetType = (type) => {
    setAssetType(type);
  };

  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };

  const getContent = () => {
    switch (editValue) {
      case "intellectualValue":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-12 gap-3 mb-3">
                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Amount Invested
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="amount_invested"
                        value={intellectualForm.amount_invested}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.amount_invested && <p className="text-red-500 text-[0.75rem]">{errors.amount_invested}</p>}
                </div>

                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Sale Value
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                      <InputField
                        type="number"
                        name="estimated_sale_value"
                        value={intellectualForm.estimated_sale_value}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.estimated_sale_value && <p className="text-red-500 text-[0.75rem]">{errors.estimated_sale_value}</p>}
                </div>
              </div>
            </div>
            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] mb-3`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Trademark from Assets ?
              </h4>
              <div className="w-[20%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove`}
                  px={"px-[0.4rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        );
      case "intellectualInfo":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-4 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    IP Type
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Trademark", label: "Trademark" },
                      { value: "Patent", label: "Patent" },
                      { value: "Copyright", label: "Copyright" },
                      { value: "Design", label: "Design" },
                    ]}
                    name="ip_type"
                    value={intellectualForm.ip_type}
                    onChange={handleInputChange}
                  />
                  {errors.ip_type && <p className="text-red-500 text-[0.75rem]">{errors.ip_type}</p>}
                </div>
                <div className="input-container col-span-4 w-[100%] flex flex-col gap-1.5 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Account Holder
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Self", label: "Self" },
                      { value: "Company", label: "Company" },
                      { value: "Other", label: "Other" },
                    ]}
                    name="account_holder"
                    value={intellectualForm.account_holder}
                    onChange={handleInputChange}
                  />
                  {errors.account_holder && <p className="text-red-500 text-[0.75rem]">{errors.account_holder}</p>}
                </div>
              </div>
            </div>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Asset/IP Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={intellectualForm.asset_ip_name}
                    onChange={handleInputChange}
                    name="asset_ip_name"
                    placeholder="Asset/IP Name"
                  />
                  {errors.asset_ip_name && <p className="text-red-500 text-[0.75rem]">{errors.asset_ip_name}</p>}
                </div>
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Registration Number
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={intellectualForm.registration_number}
                    onChange={handleInputChange}
                    name="registration_number"
                    placeholder="Registration Number"
                  />
                  {errors.registration_number && <p className="text-red-500 text-[0.75rem]">{errors.registration_number}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-5 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Class/Type of Work
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Trademark", label: "Trademark" },
                      { value: "Patent", label: "Patent" },
                      { value: "Copyright", label: "Copyright" },
                      { value: "Design", label: "Design" },
                    ]}
                    name="class_or_type_of_work"
                    value={intellectualForm.class_or_type_of_work}
                    onChange={handleInputChange}
                  />
                  {errors.class_or_type_of_work && <p className="text-red-500 text-[0.75rem]">{errors.class_or_type_of_work}</p>}
                </div>
              </div>
            </div>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 gap-3 px-3 py-2 mt-1.5">
                <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                  Registration Date
                  </label>
                  <InputField
                    type="date"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={intellectualForm.registration_date}
                    name="registration_date"
                    onChange={handleInputChange}
                  />
                  {errors.registration_date && <p className="text-red-500 text-[0.75rem]">{errors.registration_date}</p>}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 px-3">
                <div className="input-container">
                  <label className="input-label">Status</label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        value={true}
                        name="status"
                        onChange={handleInputChange}
                        checked={intellectualForm.status===true}
                        className="mr-2 custom-radio"
                      />
                      Active
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        value={false}
                        name="status"
                        onChange={handleInputChange}
                        checked={intellectualForm.status===false}
                        className="mr-2 custom-radio"
                      />
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
              <div
              onClick={()=>document.getElementById("file-upload-reminder").click()}
              className="ml-3 mt-2.5 w-[37%] px-3 py-1.5 flex items-center border-[1px] border-[#94A3B8] cursor-pointer rounded-lg gap-1.5 bg-white">
                <img src={upload} alt="uploadPic" />
                <p className="text-[#334155] m-0 font-[600]">Set Reminder</p>
              </div>
              <InputField 
                id={`file-upload-reminder`}
                type="file"
                onChange={handleInputChange}
                className="hidden"
                name="reminder"
              />
            </div>
            <div 
            onClick={()=>document.getElementById("file-upload-document").click()}
            className="mt-[1rem] w-[50%] px-4 py-1.5 cursor-pointer flex items-center justify-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white">
              <img src={upload} alt="uploadPic" />
              <p className="text-[#334155] m-0 font-[600]">Upload Document</p>
            </div>
            {errors.document && <p className="text-red-500 text-[0.75rem]">{errors.document}</p>}
            <InputField 
                id={`file-upload-document`}
                type="file"
                onChange={handleInputChange}
                className="hidden"
                name="document"
              />
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" onClick={()=>setIsClose(false)} />
        }
        heading={name}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} onClick={handleSubmit} />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
      <RemoveAsset
        heading="Loan"
        assetId={intellectualForm.asset}
        deleteId={`${intellectualForm.asset}/?id=${intellectualForm.id}&model_type=intellectual_property`}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditIntellectual;
