import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import "../liabilities.css";
import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import uploadPic from "assets/images/assetsAndLiabilities/upload.svg";
import eyePic from "assets/images/assetsAndLiabilities/eye.svg";
import trashPic from "assets/images/assetsAndLiabilities/trash-2.svg";
import usePostFamilyLoans from "../data/usePostFamilyLoans";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import filePic from "assets/images/assetsAndLiabilities/file-text.svg"
import usePatchFamilyLoans from "../data/usePatchFamilyLoans";
import { useLocation, useNavigate } from "react-router-dom";
import { LIABILITIES_URL } from "constants/routes";
import RedButton from "components/Button/RedButton";

const FamilyAndInformalLoanLenders = ({ triggerElement, loanType , loanTypeId, isClose, setIsClose}) => {
  const [postFamilyData, postFamilyError, postFamilyIsLoading, postFamilyLoanDetails] = usePostFamilyLoans();
  const [patchFamilyData, patchFamilyError, patchFamilyIsLoading, patchFamilyLoanDetails] = usePatchFamilyLoans();
  const [currentStep, setCurrentStep] = useState(0); // State for the current step
  const [formId, setFormId]= useState(0);
  const [showDoc, setShowDoc] = useState(false);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [readPart1, setReadPart1]=useState(true)
  const [readPart2, setReadPart2]=useState(true)
  const [readPart3, setReadPart3]=useState(true)
  const [errors, setErrors]=useState([{}])
  const navigate=useNavigate()
  const location = useLocation()
  const [loans, setLoans] = useState([
    {
      uniqueKey: Date.now(),
      lender_name: "",
      relation: "",
      amount_borrowed: "",
      agreement_file: "",
      start_date: "",
      period_years: "",
      payment_date: "",
      end_date: "",
      interest_charged: false,
      interest_rate: "",
      amount_repaid: "",
      balance_amount: "",
      payable_per_month: "",
      loan_type:""
    }
  ])

  const resetState = () => {
    setLoans([
      {
        uniqueKey: Date.now(),
        lender_name: "",
        relation: "",
        amount_borrowed: "",
        agreement_file: "",
        start_date: "",
        period_years: "",
        payment_date: "",
        end_date: "",
        interest_charged: false,
        interest_rate: "",
        amount_repaid: "",
        balance_amount: "",
        payable_per_month: "",
        loan_type:""
      }
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  }


  useEffect(()=>{
   if(postFamilyData){
    if (location.pathname === LIABILITIES_URL) {
      showSuccessToast("Data Posted Successfully")
      setIsClose(true);
      setVisitedSteps([])
      setCurrentStep(0)
      setLoans([
        {
          uniqueKey: Date.now(),
          lender_name: "",
          relation: "",
          amount_borrowed: "",
          agreement_file: "",
          start_date: "",
          period_years: "",
          payment_date: "",
          end_date: "",
          interest_charged: false,
          interest_rate: "",
          amount_repaid: "",
          balance_amount: "",
          payable_per_month: "",
          loan_type:""
        }
      ])
      navigate(LIABILITIES_URL, { state: { value: true } });
    } else {
      navigate(LIABILITIES_URL); // Otherwise, navigate to the liabilities page
    }
   }
  },[postFamilyData])

  useEffect(()=>{
    if(postFamilyError){
     showErrorToast("Error in Posting Data")
    }
   },[postFamilyError])


   useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const handleAddNewUser=()=>{
    setLoans([...loans, 
      {
        uniqueKey: Date.now(),
        lender_name: "",
        relation: "",
        amount_borrowed: "",
        agreement_file: "",
        start_date: "",
        period_years: "",
        payment_date: "",
        end_date: "",
        interest_charged: false,
        interest_rate: "",
        amount_repaid: "",
        balance_amount: "",
        payable_per_month: "",
        loan_type:""
      }
    ])
  }
  const handleDeleteUser=(id)=>{
    const updatedLoans=loans.filter(obj=>obj.uniqueKey !== id)
    setLoans(updatedLoans)
  }
  const handleFileDrop = (index, e) => {
    e.preventDefault();
    const files = e.dataTransfer.files; // Get dropped files
    handleDrop(index, files); // Call the handleDrop method
  };

  const handleDrop = (index, files) => {
    const updatedLoans = [...loans];
    updatedLoans[index].agreement_file = files[0]; // Assuming you're only handling one file
    console.log('Dropped file updatedLoans', updatedLoans);
    setLoans(updatedLoans);
  };
  
    const handleInputChange = (index, e) => {
      const { name, value, type, files } = e.target;
    
      // Check if the input type is a file
      if (type === "file") {
        // If it's a file input, take the first file and set it in the loans state
        const updatedLoans = [...loans];
        updatedLoans[index][name] = files[0]; // Store the file object
        console.log('File updatedLoans', updatedLoans);
        setLoans(updatedLoans);
      } else {
        // For text inputs
        const updatedLoans = [...loans];
        const splitName = name.split('_');
        const baseName = splitName.slice(0, -1).join('_');
          // Ensure boolean for interest_charged
        if (baseName === "interest_charged") {
          updatedLoans[index][name] = value === "true";  // Convert string "true" to boolean true
        } else {
          updatedLoans[index][name] = value;
        }
        setLoans(updatedLoans);
      }
    };

    const handleViewInvoice = (pdfUrl) => {
     if(pdfUrl){
      const fileURL = URL.createObjectURL(pdfUrl);
      window.open(fileURL, '_blank');
      // Clean up the URL when no longer needed
      URL.revokeObjectURL(fileURL);
     }
    };


    const handleSubmit=()=>{
      console.log("hiiiiiii")
      const formData = new FormData(); 
      loans.forEach((loan, index) => {
        // Loop through each field in the loan object
        Object.keys(loan).forEach((key) => {
          if(key==="uniqueKey"){

          }
          if (key === 'agreement_file' && loan[key]) {
            // If it's the agreement file, append it as a file
            formData.append(`loans[${index}][${key}]`, loan[key]);
          } else {
            // Otherwise, append it as a normal field
            formData.append(`loans[${index}][${key}]`, loan[key]);
          }
        });
      });
    if(validateLoans()){
      console.log("hiiiiiii kkkkkk")
      postFamilyLoanDetails(formData, loanTypeId)
    }
    }

    const validateLoans = (validateFinal=false) => {
      console.log("loans", loans)
      const newErrors = loans.map((loan, index) => {
        const error = {};
        
        // Lender Name: only letters and spaces allowed
        const namePattern = /^[a-zA-Z\s]+$/;
        if (!loan.lender_name) {
          error.lender_name = "Lender Name is required";
        } else if (!namePattern.test(loan.lender_name)) {
          error.lender_name = "Lender Name should not contain special characters or numbers";
        }
    
        if (!loan.relation) {
          error.relation = "Relation Name is required";
        } else if (!namePattern.test(loan.relation)) {
          error.relation = "Relation Name should not contain special characters or numbers";
        }

        if (!loan.loan_type) {
          error.loan_type = "Loan Type is required";
        }
        // Amount Borrowed: only whole numbers allowed, must be > 0
        if (!loan.amount_borrowed || !/^\d+$/.test(loan.amount_borrowed)) {
          error.amount_borrowed = "Valid Amount Borrowed is required (whole numbers only)";
        } else if (loan.amount_borrowed <= 0) {
          error.amount_borrowed = "Amount Borrowed must be greater than 0";
        }

        if (!loan.agreement_file) {
          error.agreement_file = "Agreement File is required";
        }
    
        if(currentStep === 1 || validateFinal){
            // Start Date and End Date validation
            if (!loan.start_date) {
              error.start_date = "Start Date is required";
            }
            if (!loan.end_date) {
              error.end_date = "End Date is required";
            }
            // Period: must be a number between 0 and 12 years
            if (!loan.period_years) {
              error.period_years = "Period is required";
            }
            if (!loan.payment_date) {
              error.payment_date = "Payment Date is required";
            }
        }

        if(currentStep === 2 || validateFinal){
          // Interest Rate: between 0 and 100
            if (!loan.interest_rate || Number(loan.interest_rate) < 0 || Number(loan.interest_rate) > 100) {
              error.interest_rate = "Valid Interest Rate (0-100) is required";
            }
            // Amount Repaid: only whole numbers allowed
            if (!loan.amount_repaid || !/^\d+$/.test(loan.amount_repaid)) {
              error.amount_repaid = "Valid Amount Repaid is required (whole numbers only)";
            }
        
            // Balance Amount: only whole numbers allowed
            if (!loan.balance_amount || !/^\d+$/.test(loan.balance_amount)) {
              error.balance_amount = "Valid Balance Amount is required (whole numbers only)";
            }
        
            // Payable per Month: only whole numbers allowed
            if (!loan.payable_per_month || !/^\d+$/.test(loan.payable_per_month)) {
              error.payable_per_month = "Valid Payable per Month is required (whole numbers only)";
            }
        }
        return error;
      });
      console.log("errors", newErrors)
      setErrors(newErrors);
      return newErrors.every(error => Object.keys(error).length === 0); // returns true if no errors
    };

    const handleDeleteFile =(key)=>{
        const updatedLoans=loans.map((el)=>{
           if(el.uniqueKey===key){
             el.agreement_file = "";
             return el
           }
           return el
        })
       setLoans(updatedLoans)
    }
    

  const progressSteps = [
    { label: "Lender Info", active: currentStep === 0, visited: visitedSteps.includes(1) },
    { label: "Loan Info", active: currentStep === 1, visited: visitedSteps.includes(2) },
    { label: "Payment Details", active: currentStep === 2 , visited: visitedSteps.includes(3)},
  ];

  const handleNext = () => {
    if(currentStep==3){
      handleSubmit()
    }
    if (showDoc) {
      setShowDoc(false);
    }
    if (currentStep < progressSteps.length + 1 - 1 && validateLoans()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            {showDoc ? (
              <>
                {
                  loans.map((el,i)=>{
                    return(
                      <div key={el.uniqueKey}>
                      <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                        Upload Documents
                      </h4>
                      <div className="p-4">
                        <div className="p-14 border-[3px] border-dashed rounded-lg"
                          onDrop={(e) => handleFileDrop(i, e)} 
                          onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
                        >
                          {/* <p className="text-center text-[#334155] font-[600] m-0">
                            Drag & Drop Here to upload
                          </p> */}
                                      {/* Conditionally render the file name or the drag-and-drop text */}
                          {el.agreement_file ? (
                            <div className="text-center flex flex-col items-center">
                              <img src={filePic} alt="File Icon" />
                              <p className="text-[#334155] font-[600] m-0">
                                {el.agreement_file.name || 'Uploaded Document'}
                              </p>
                            </div>
                          ) : (
                            <p className="text-center text-[#334155] font-[600] m-0">
                              Drag & Drop Here to upload
                            </p>
                          )}
                        </div>
                        <div className="mt-4 flex flex-col gap-2.5 items-center">
                          <p className="text-[#334155] text-[1rem] font-[500] m-0">
                            Select the files Manually From your PC
                          </p>
                          <button
                              className="text-[#64748B] text-center border-[1px] border-[#D1D5DB] text-[0.8rem] font-[600] p-1 rounded-md"
                              onClick={() => document.getElementById(`file-upload-${i}`).click()}
                            >
                              Open Folder
                            </button>
                            {/* <input
                              id={`file-upload-${i}`}
                              type="file"
                              onChange={(e) => handleInputChange(i, e.target.files[0])}
                              className="hidden"
                            /> */}
                            <InputField 
                              id={`file-upload-${i}`}
                              type="file"
                              onChange={(e) => handleInputChange(i, e)}
                              className="hidden"
                              name="agreement_file"
                            />
                        </div>
                      </div>
                    </div> 
                    )
                  })
                }
              </>
            ) : (
              <div>
                <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                  Lender Details
                </h4>
                {/* <div className="rounded-md bg-[#F1F5F9] mt-[1rem]"> */}
                  {
                    loans.map((el, i)=>{
                      return(
                        <div key={el.uniqueKey}>
                        <p className={`m-0 font-[600] mt-1 ${loans.length>1 ? "":"hidden"}`}>Member-{i+1}</p>
                        <div className="rounded-md bg-[#F1F5F9] mt-[1rem]">
                    <div className="grid grid-cols-12 px-3 py-1 gap-3">
                    <div className="input-container col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                        Lender Name
                      </label>
                      <InputField
                        type="text"
                        className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                        value={el.lender_name}
                        onChange={(e)=>handleInputChange(i,e)}
                        name="lender_name"
                      />
                       {errors[i]?.lender_name && <p className="text-red-500 text-[0.8rem]">{errors[i].lender_name}</p>}
                    </div>

                    <div className="input-container col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                        Relation
                      </label>
                      <InputField
                        type="text"
                        className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                        value={el.relation}
                        onChange={(e)=>handleInputChange(i,e)}
                        name="relation"
                      />
                      {errors[i]?.relation && <p className="text-red-500 text-[0.8rem]">{errors[i].relation}</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                        Loan Type
                      </label>
                      <InputField
                        type="select"
                        className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                        options={[
                          { value: "", label: "Select an Option" },
                          { value: "SECURED", label: "SECURED" },
                          { value: "UNSECURED", label: "UNSECURED" }
                        ]}
                        name="loan_type"
                        value={el.loan_type}
                        onChange={(e)=>handleInputChange(i,e)}
                      />
                      {errors[i]?.loan_type && <p className="text-red-500 text-[0.8rem]">{errors[i].loan_type}</p>}
                    </div>

                    <div className="col-span-6 w-[100%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Amount Borrowed
                      </label>
                      <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                        <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                          Rs.
                        </span>
                        <InputField
                          type="number"
                          name="amount_borrowed"
                          value={el.amount_borrowed}
                          onChange={(e)=>handleInputChange(i,e)}
                          className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                        />
                      </div>
                      {errors[i]?.amount_borrowed && <p className="text-red-500 text-[0.8rem]">{errors[i].amount_borrowed}</p>}
                    </div>
                  </div>
                  <div>
                    <div className="py-2 px-3 flex justify-end items-center">
                       <div>
                          <button
                            onClick={() => setShowDoc(true)}
                            className="text-[#334155] bg-white font-[600] text-[0.9rem] border-1 border-[#64748B] py-1.5 px-[1.5rem] rounded-md flex gap-2 items-center"
                          >
                            <img src={uploadPic} alt="uploadPic" />
                            Upload Agreement
                          </button>
                          {errors[i]?.agreement_file && <p className="text-red-500 text-[0.8rem]">{errors[i].agreement_file}</p>}
                       </div>
                    </div>
                  </div>
                        </div>
                        <div className="flex justify-end mt-1">
                          {
                            i > 0 && <>
                            <button onClick={()=>handleDeleteUser(el.uniqueKey)} className="px-[0.6rem] py-[0.4rem] rounded-md bg-[#FEF2F2] text-[#DC2626] font-[600] text-[0.9rem]">
                             X Remove
                            </button>
                            </>
                          }
                        </div>
                        </div>
                      )
                    })
                  }
                {/* </div> */}
                <div className="flex justify-end mt-3">
                  <WhiteButton
                    onClick={handleAddNewUser}
                    name="+ Add Multiple Lenders"
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                </div>
              </div>
            )}
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Loan Interest
            </h4>
               {
                loans.map((el,i)=>{
                  return(
                    <div key={el.uniqueKey}>
                      <p className={`m-0 font-[600] mt-1 ${loans.length>1 ? "":"hidden"}`}>Member-{i+1}</p>
                    <div  className="rounded-md bg-[#F1F5F9] mt-[1rem]">
                <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Start Date
                  </label>
                  <InputField
                    type="date"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={el.start_date}
                    name="start_date"
                    onChange={(e)=>handleInputChange(i,e)}
                  />
                  {errors[i]?.start_date && <p className="text-red-500 text-[0.8rem]">{errors[i].start_date}</p>}
                </div>

                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Period
                  </label>
                  <InputField
                    type="select"
                    value={el.period_years}
                    name="period_years"
                    onChange={(e)=>handleInputChange(i,e)}
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: 1, label: "1 years" },
                      { value: 2, label: "2 years" },
                      { value: 3, label: "3 years" },
                    ]}
                  />
                  {errors[i]?.period_years && <p className="text-red-500 text-[0.8rem]">{errors[i].period_years}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Payment Date
                  </label>
                  <InputField
                    type="date"
                    value={el.payment_date}
                    name="payment_date"
                    onChange={(e)=>handleInputChange(i,e)}
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                  />
                  {errors[i]?.payment_date && <p className="text-red-500 text-[0.8rem]">{errors[i].payment_date}</p>}
                </div>

                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    End Date
                  </label>
                  <InputField
                    type="date"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={el.end_date}
                    name="end_date"
                    onChange={(e)=>handleInputChange(i,e)}
                  />
                  {errors[i]?.end_date && <p className="text-red-500 text-[0.8rem]">{errors[i].end_date}</p>}
                </div>
              </div>
                    </div>
                    </div>
                  )
                })
               }
            
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Payment Details
            </h4>
                {
                  loans.map((el,i)=>{
                    return(
                      <div key={el.uniqueKey}>
                        <p className={`m-0 font-[600] mt-1 ${loans.length>1 ? "":"hidden"}`}>Member-{i+1}</p>
                     <div  className="rounded-md bg-[#F1F5F9] mt-[1rem] py-2">
                <div>
                <p className="m-0 text-[#334155] px-3 mt-3 text-[0.8rem] font-[500]">
                  Interest Charged
                </p>
                <div className="flex gap-3 justify-start items-center px-3 mt-1.5 mb-2">
                  <label className="font-[500] text-[.8rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center ">
                    <InputField
                      type="radio"
                      name={`interest_charged_${el.uniqueKey}`}
                      value={ true }
                      onChange={(e)=>handleInputChange(i,e)}
                      checked={el[`interest_charged_${el.uniqueKey}`] === true}
                      className="peer ml-1 mr-1 text-[#334155] text-[0.8rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                    />
                    Yes
                  </label>
                  <label className="font-[500] text-[.8rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center">
                    <InputField
                      type="radio"
                      name={`interest_charged_${el.uniqueKey}`}
                      value={ false }
                      onChange={(e)=>handleInputChange(i,e)}
                      checked={el[`interest_charged_${el.uniqueKey}`] === false}
                      className="peer ml-1 mr-1 text-[#334155] text-[0.8rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Interest Rate
                  </label>
                  <InputField
                    type="number"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={el.interest_rate}
                    name="interest_rate"
                    onChange={(e)=>handleInputChange(i,e)}
                  />
                  {errors[i]?.interest_rate && <p className="text-red-500 text-[0.8rem]">{errors[i].interest_rate}</p>}
                </div>

                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Amount Repaid
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={el.amount_repaid}
                    name="amount_repaid"
                    onChange={(e)=>handleInputChange(i,e)}
                  />
                  {errors[i]?.amount_repaid && <p className="text-red-500 text-[0.8rem]">{errors[i].amount_repaid}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Balance Amount
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      value={el.balance_amount}
                      name="balance_amount"
                      onChange={(e)=>handleInputChange(i,e)}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors[i]?.balance_amount && <p className="text-red-500 text-[0.8rem]">{errors[i].balance_amount}</p>}
                </div>
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                    Payable Amount per month
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      value={el.payable_per_month}
                      name="payable_per_month"
                      onChange={(e)=>handleInputChange(i,e)}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors[i]?.payable_per_month && <p className="text-red-500 text-[0.8rem]">{errors[i].payable_per_month}</p>}
                </div>
              </div>
                      </div>
                      </div>
                    )
                  })
                }
          </>
        );
      case 3:
        return (
          <>
            {
              loans.map((el, i)=>{
                return (
                  <div key={el.uniqueKey}>
              <div>
              <div className="flex justify-between">
                <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                  Lender Details
                </h4>
                <button
                 onClick={()=>{
                  if(validateLoans(true)){
                    setReadPart1(!readPart1)
                  }
                  }}
                  className={`py-1.5 px-2.5 border-[1px] border-[#D9D9D9] rounded-md text-[0.8rem] ${readPart1?"text-[#334155]":"bg-[#9065B4] text-white"}`}>
                  {readPart1 ? "Edit":"Done"}
                </button>
              </div>
              <div className="rounded-md border-[1px] border-[#D9D9D9] py-1 mt-[1rem]">
                <div className="grid grid-cols-12 px-3 py-1 gap-3">
                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Lender Name
                    </label>
                    <InputField
                      type="text"
                      readOnly={readPart1}
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.lender_name}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="lender_name"
                    />
                    {errors[i]?.lender_name && <p className="text-red-500 text-[0.8rem]">{errors[i].lender_name}</p>}
                  </div>

                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Relation
                    </label>
                    <InputField
                      type="text"
                      readOnly={readPart1}
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.relation}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="relation"
                    />
                    {errors[i]?.relation && <p className="text-red-500 text-[0.8rem]">{errors[i].relation}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Loan Type
                    </label>
                    <InputField
                      type="select"
                      readOnly={readPart1}
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                      options={[
                        { value: "", label: "Select an Option" },
                        { value: "SECURED", label: "SECURED" },
                        { value: "UNSECURED", label: "UNSECURED" }
                      ]}
                      name="loan_type"
                      value={el.loan_type}
                      onChange={(e)=>handleInputChange(i,e)}
                    />
                    {errors[i]?.loan_type && <p className="text-red-500 text-[0.8rem]">{errors[i].loan_type}</p>}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Amount Borrowed
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        readOnly={readPart1}
                        name="amount_borrowed"
                        value={el.amount_borrowed}
                        onChange={(e)=>handleInputChange(i,e)}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors[i]?.amount_borrowed && <p className="text-red-500 text-[0.8rem]">{errors[i].amount_borrowed}</p>}
                  </div>
                </div>
                <div>
                  <div className="py-2 px-3 flex justify-end gap-1 items-center">
                    {
                      el.agreement_file ? (
                      <button
                        onClick={() => handleViewInvoice(el.agreement_file)}  
                        className="text-[#334155] bg-white font-[600] text-[0.9rem] border-[1px] border-[#94A3B8] py-1.5 px-[1.5rem] rounded-md flex gap-2 items-center">
                        <img src={eyePic} alt="eyePic" />
                        View Invoice
                      </button>):(
                        <>
                       <div className={`flex flex-col`}>
                        <button
                            onClick={() => document.getElementById(`file-upload-${i}`).click()} 
                            className="text-[#334155] bg-white font-[600] text-[0.9rem] border-[1px] border-[#94A3B8] py-1.5 px-[1.5rem] rounded-md flex gap-2 items-center">
                            <img src={eyePic} alt="eyePic" />
                            Upload Invoice
                        </button>
                          <InputField 
                          id={`file-upload-${i}`}
                          type="file"
                          onChange={(e) => handleInputChange(i, e)}
                          className="hidden"
                          name="agreement_file"
                        />
                         {errors[i]?.agreement_file && <p className="text-red-500 text-[0.8rem]">{errors[i].agreement_file}</p>}
                       </div>
                      </>
                      )
                    }
                    <button onClick={()=>handleDeleteFile(el.uniqueKey)} className={`text-[#334155] bg-[#FEE2E2] border-[1px] border-[#FEE2E2] py-1.5 px-2 rounded-md `}>
                      <img src={trashPic} alt="trashPic" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mt-3">
                <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                  Loan Interest
                </h4>
                <button                 
                 onClick={()=>{
                  if(validateLoans(true)){
                    setReadPart2(!readPart2)
                  }
                  }} className={`py-1.5 px-2.5 border-[1px] border-[#D9D9D9] rounded-md text-[0.8rem]  ${readPart2?"text-[#334155]":"bg-[#9065B4] text-white"}`}>
                {readPart2 ? "Edit":"Done"}
                </button>
              </div>
              <div className="rounded-md border-[1px] border-[#D9D9D9] mt-[1rem]">
                <div className="grid grid-cols-12 px-3 py-1 gap-3">
                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Start Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.start_date}
                      name="start_date"
                      readOnly={readPart2}
                      onChange={(e)=>handleInputChange(i,e)}
                    />
                    {errors[i]?.start_date && <p className="text-red-500 text-[0.8rem]">{errors[i].start_date}</p>}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Period
                    </label>
                    <InputField
                      type="select"
                      value={el.period_years}
                      name="period_years"
                      readOnly={readPart2}
                      onChange={(e)=>handleInputChange(i,e)}
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                      options={[
                        { value: "", label: "Select an Option" },
                        { value: 1, label: "1 years" },
                        { value: 2, label: "2 years" },
                        { value: 3, label: "3 years" },
                      ]}
                    />
                    {errors[i]?.period_years && <p className="text-red-500 text-[0.8rem]">{errors[i].period_years}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Payment Date
                    </label>
                    <InputField
                      type="date"
                      readOnly={readPart2}
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.payment_date}
                      name="payment_date"
                      onChange={(e)=>handleInputChange(i,e)}
                    />
                    {errors[i]?.payment_date && <p className="text-red-500 text-[0.8rem]">{errors[i].payment_date}</p>}
                  </div>

                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      End Date
                    </label>
                    <InputField
                      type="date"
                      readOnly={readPart2}
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.end_date}
                      name="end_date"
                      onChange={(e)=>handleInputChange(i,e)}
                    />
                    {errors[i]?.end_date && <p className="text-red-500 text-[0.8rem]">{errors[i].end_date}</p>}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-between mt-3">
                <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                  Payment Details
                </h4>
                <button                  
                onClick={()=>{
                  if(validateLoans(true)){
                    setReadPart3(!readPart3)
                  }
                  }} className={`py-1.5 px-2.5 border-[1px] border-[#D9D9D9] rounded-md text-[0.8rem]  ${readPart3?"text-[#334155]":"bg-[#9065B4] text-white"}`}>
                {readPart3 ? "Edit":"Done"}
                </button>
              </div>
              <div className="rounded-md border-[1px] border-[#D9D9D9] mt-[1rem] py-2">
                <div className="">
                  <p className="m-0 text-[#334155] px-3 mt-3 text-[0.8rem] font-[500]">
                    Interest Charged
                  </p>
                  <div className="flex gap-3 justify-start items-center px-3 mt-1.5 mb-2">
                    <label className="font-[500] text-[.8rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center ">
                      <InputField
                        type="radio"
                        name={`interest_charged_${el.uniqueKey}`}
                        value={ true }
                        readOnly={readPart3}
                        onChange={(e)=>handleInputChange(i,e)}
                        checked={el[`interest_charged_${el.uniqueKey}`] === true}
                        className="peer ml-1 mr-1 text-[#334155] text-[0.8rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                      />
                      Yes
                    </label>
                    <label className="font-[500] text-[.8rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center">
                      <InputField
                        type="radio"
                        name={`interest_charged_${el.uniqueKey}`}
                        value={false}
                        readOnly={readPart3}
                        onChange={(e)=>handleInputChange(i,e)}
                        checked={el[`interest_charged_${el.uniqueKey}`] === false}
                        className="peer ml-1 mr-1 text-[#334155] text-[0.8rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                      />
                      No
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 px-3 py-1 gap-3">
                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Interest Rate
                    </label>
                    <InputField
                      type="number"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.interest_rate}
                      name="interest_rate"
                      readOnly={readPart3}
                      onChange={(e)=>handleInputChange(i,e)}
                    />
                    {errors[i]?.interest_rate && <p className="text-red-500 text-[0.8rem]">{errors[i].interest_rate}</p>}
                  </div>

                  <div className="input-container col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Amount Repaid
                    </label>
                    <InputField
                      type="text"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={el.amount_repaid}
                      readOnly={readPart3}
                      name="amount_repaid"
                      onChange={(e)=>handleInputChange(i,e)}
                    />
                    {errors[i]?.amount_repaid && <p className="text-red-500 text-[0.8rem]">{errors[i].amount_repaid}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Balance Amount
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        value={el.balance_amount}
                        name="balance_amount"
                        readOnly={readPart3}
                        onChange={(e)=>handleInputChange(i,e)}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors[i]?.balance_amount && <p className="text-red-500 text-[0.8rem]">{errors[i].balance_amount}</p>}
                  </div>
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.8rem]">
                      Payable Amount per month
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        value={el.payable_per_month}
                        name="payable_per_month"
                        readOnly={readPart3}
                        onChange={(e)=>handleInputChange(i,e)}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors[i]?.payable_per_month && <p className="text-red-500 text-[0.8rem]">{errors[i].payable_per_month}</p>}
                  </div>
                </div>
              </div>
            </div>
                  </div>
                )
              })
            }
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
      onClose={resetState}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">{loanType}</span> to your
            Liabilities
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] mt-[2rem]">
              <WhiteButton
                onClick={handleBack}
                name={"Back"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                hidden={currentStep===3?"hidden":""}
              />
              <Button
                onClick={handleNext}
                name={currentStep===2 ? "Preview":currentStep===3?"Add to Liabilities":"Next"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
            {isClose&& handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default FamilyAndInformalLoanLenders;
