import React from "react";
import { ServiceDetails } from "components/serviceDetails/GetServiceDetails";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StepsSlider from "./StepsSlider";

function ServicesGuide(props) {
  const stepByStepArr = props.sendData.step_by_step_description;
  // console.log(stepByStepArr[0].step1);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {props.sendData && 
      (
        <>
          {" "}
          <div className="slightDarkBlack p-[8%] my-10 grid grid-cols-12 lg:bg-purple-light-shade md:bg-purple-light-shade">
            <h2 className="font-[700] lg:text-[3rem] lg:leading-[3.5rem]  md:text-[3rem] md:leading-[3.5rem] text-[1.3rem] leading-[1.6rem] lg:text-center md:text-center lg:px-[14%] md:px-[14%] col-span-12">{props.sendData.step_by_step_title}</h2>
           

            <div className="col-span-12 py-[7%] grid grid-cols-12 gap-3">
              {/* <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div> */}
              <div className="  col-span-6 h-[100%] w-[100%]">
              {/* <Slider {...settings}>
                      <div className=" p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 01
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[0].step1}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 02</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 02
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[1].step2}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 03</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 03
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[2].step3}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 04</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 04
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[3].step4}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 05</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 05
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[4].step5}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 06</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 06
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[5].step6}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 07</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 07
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[6].step7}</p>
                        <div className=" border-l-2 border-black">
                          <span className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-white bg-purple-400 py-1 rounded-xl">STEP 08</span>
                        </div>
                      </div>
                      <div className="h-[100%] w-[100%] p-[18%]">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 08
                        </span>
                        <p className=' font-[500] text-[1.5rem] leading-[1.8rem] text-gray-600 mt-4'>{stepByStepArr[7].step8}</p>
                       
                      </div>
                    </Slider> */}
              </div>
              <div className=" col-span-12">
                <StepsSlider sendData={props.sendData}/>
              </div>
            </div>
          </div>
        </>
      )
      }
    </>
  );
}

export default ServicesGuide;
