import React, { useEffect, useState } from "react";
import arrowPic from "assets/images/incomeTax/arrow-right-line.svg";
import deletePic from "assets/images/incomeTax/removeCapital.png";
import datePicker from "assets/images/incomeTax/datePicker.png";
import info1 from "assets/images/incomeTax/info1.svg";
import { useFieldArray, useForm } from "react-hook-form";
import useGetTaxProfileInformation from "./Data/useGetTaxProfileInformation";
import usePostTaxProfileInformation from "./Data/usePostTaxProfileInformation";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";
import HeadingProfile from "../Tax Profile/HeadingProfile";
import { BUSINESS_PROFILE_URL } from "constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";

const IncomeTaxProfileInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [flagToPatch, setFlagToPatch] = useState(false);

  const [getData, getError, getIsLoading, getTaxProfileInfo] =
    useGetTaxProfileInformation();

  const [postData, postError, postIsLoading, postTaxProfileInformation] =
    usePostTaxProfileInformation();

  useEffect(() => {
    getTaxProfileInfo();
  }, []);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Profile Info Posted Successfully");
    }
  }, [postData]);

  useEffect(() => {
    if (postError) {
      showErrorToast("Error in Posting Profile Info");
    }
  }, [postError]);

  useEffect(() => {
    if (getData?.data) {
      const formData = getData?.data;
      formData.is_partner_in_firm = formData?.is_partner_in_firm.toString();
      if (
        formData?.unlisted_share_holdings[0]?.id ||
        formData?.directorships[0]?.id ||
        formData?.esops[0]?.id ||
        formData?.esops[0]?.id ||
        formData?.return_filing_info[0]?.id
      ) {
        setFlagToPatch(true);
      }
      if (formData?.unlisted_share_holdings.length === 0) {
        formData.unlisted_share_holdings = defaultValue.unlisted_share_holdings;
      }
      if (formData?.directorships.length === 0) {
        formData.directorships = defaultValue.directorships;
      }
      if (formData?.esops.length === 0) {
        formData.esops = defaultValue.esops;
      }
      if (formData?.return_filing_info.length === 0) {
        formData.return_filing_info = defaultValue.return_filing_info;
      }
      reset(formData);
    }
  }, [getData]);
  const defaultValue = {
    is_partner_in_firm: "",
    has_unlisted_shares: false,
    is_director_in_company: false,
    has_esops: false,
    unlisted_share_holdings: [
      {
        company_name: "",
        pan_of_company: "",
        company_type: "",
        isin_code: "",
        face_price_per_share: "",
        purchase_price_per_share: "",
        balance_cost: "",
        holding_quantity: "",
        transfer_quantity: "",
        holding_proof: "",
        date: "",
        transfer_date: "",
        fresh_issue: "",
      },
    ],
    directorships: [
      {
        company_name: "",
        pan_of_company: "",
        company_type: "",
        isin_code: "",
      },
    ],
    esops: [
      {
        startup_name: "",
        pan_of_company: "",
        company_type: "",
        dpit_reg_no: "",
        assessment_year: "",
        tax_deferred: "",
        date_of_cessation_of_employment: "",
        balance_tax_c_or_f: "",
        holding_assessment_year: "",
        date_of_sale: "",
        tax_related_to_sale: "",
        balance_tax_payable: "",
      },
    ],
    return_filing_info: [
      {
        section_filed_under: "",
        return_type: "",
        has_representative_access: "",
      },
    ],
    residential_status: "1",
  };
  const { formState, register, handleSubmit, reset, watch, control, setValue , trigger} =
    useForm({
      defaultValues: defaultValue,
    });
  const { errors } = formState;

  // Watching the toggle values to conditionally render sections
  const hasUnlistedShares = watch("has_unlisted_shares");
  const isDirectorInCompany = watch("is_director_in_company");
  const hasEsops = watch("has_esops");
  const isPartnerInFirm = watch("is_partner_in_firm");

  // Use useFieldArray to manage dynamic arrays
  const {
    fields: unlistedSharesFields,
    append: appendUnlistedShares,
    remove: removeUnlistedShares,
  } = useFieldArray({
    control,
    name: "unlisted_share_holdings",
  });

  const {
    fields: directorshipFields,
    append: appendDirectorship,
    remove: removeDirectorship,
  } = useFieldArray({
    control,
    name: "directorships",
  });

  const {
    fields: esopsFields,
    append: appendEsop,
    remove: removeEsop,
  } = useFieldArray({
    control,
    name: "esops",
  });

  const [residentialStatus, setResidentialStatus] = useState(1); // Default: Indian Resident

  const handleStatusChange = (newStatus) => {
    setResidentialStatus(newStatus);
    setValue("residential_status", newStatus); // Update form state
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("is_partner_in_firm", data.is_partner_in_firm);
    formData.append("has_unlisted_shares", data.has_unlisted_shares);
    formData.append("is_director_in_company", data.is_director_in_company);
    formData.append("has_esops", data.has_esops);
    formData.append("residential_status", data.residential_status);
    data.unlisted_share_holdings.map((el, index) => {
      formData.append(
        `unlisted_share_holdings[${index}]company_name`,
        el.company_name
      );
      formData.append(
        `unlisted_share_holdings[${index}]pan_of_company`,
        el.pan_of_company
      );
      formData.append(
        `unlisted_share_holdings[${index}]company_type`,
        el.company_type
      );
      formData.append(
        `unlisted_share_holdings[${index}]isin_code`,
        el.isin_code
      );
      formData.append(
        `unlisted_share_holdings[${index}]face_price_per_share`,
        el.face_price_per_share
      );
      formData.append(
        `unlisted_share_holdings[${index}]purchase_price_per_share`,
        el.purchase_price_per_share
      );
      formData.append(
        `unlisted_share_holdings[${index}]balance_cost`,
        el.balance_cost
      );
      formData.append(
        `unlisted_share_holdings[${index}]holding_quantity`,
        el.holding_quantity
      );
      formData.append(
        `unlisted_share_holdings[${index}]transfer_quantity`,
        el.transfer_quantity
      );
      formData.append(
        `unlisted_share_holdings[${index}]holding_proof`,
        el.holding_proof
      );
      formData.append(`unlisted_share_holdings[${index}]date`, el.date);
      formData.append(
        `unlisted_share_holdings[${index}]transfer_date`,
        el.transfer_date
      );
      formData.append(
        `unlisted_share_holdings[${index}]fresh_issue`,
        el.fresh_issue
      );
    });
    data.directorships.map((el, index) => {
      formData.append(`directorships[${index}]company_name`, el.company_name);
      formData.append(
        `directorships[${index}]pan_of_company`,
        el.pan_of_company
      );
      formData.append(`directorships[${index}]company_type`, el.company_type);
      formData.append(`directorships[${index}]isin_code`, el.isin_code);
    });
    data.esops.map((el, index) => {
      formData.append(`esops[${index}]startup_name`, el.startup_name);
      formData.append(`esops[${index}]pan_of_company`, el.pan_of_company);
      formData.append(`esops[${index}]company_type`, el.company_type);
      formData.append(`esops[${index}]dpit_reg_no`, el.dpit_reg_no);
      formData.append(`esops[${index}]assessment_year`, el.assessment_year);
      formData.append(`esops[${index}]tax_deferred`, el.tax_deferred);
      formData.append(
        `esops[${index}]date_of_cessation_of_employment`,
        el.date_of_cessation_of_employment
      );
      formData.append(
        `esops[${index}]balance_tax_c_or_f`,
        el.balance_tax_c_or_f
      );
      formData.append(
        `esops[${index}]holding_assessment_year`,
        el.holding_assessment_year
      );
      formData.append(`esops[${index}]date_of_sale`, el.date_of_sale);
      formData.append(
        `esops[${index}]tax_related_to_sale`,
        el.tax_related_to_sale
      );
      formData.append(
        `esops[${index}]balance_tax_payable`,
        el.balance_tax_payable
      );
    });
    data.return_filing_info.map((el, index) => {
      formData.append(
        `return_filing_info[${index}]section_filed_under`,
        el.section_filed_under
      );
      formData.append(
        `return_filing_info[${index}]return_type`,
        el.return_type
      );
      formData.append(
        `return_filing_info[${index}]has_representative_access`,
        el.has_representative_access
      );
    });
    if (flagToPatch) {
    } else {
      postTaxProfileInformation(formData);
    }
  };

  const handleUnlistedShares = (e) => {
    setValue("has_unlisted_shares", e.target.checked);
  };

  const handlePrevious = () => {
    navigate(BUSINESS_PROFILE_URL);
  };

  const handleFileInputChange = (e, fieldName) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("fieldnam",fieldName, "filename", file)
    setValue(fieldName, file); // Set the file in React Hook Form's state
    // trigger(fieldName)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%]  md:px-4 px-3">
        {location.pathname.includes("income-tax-profile") ? (
          <HeadingProfile />
        ) : (
          <div className="py-[2.2rem] gap-[1rem] items-center md:flex hidden">
            <button onClick={handlePrevious}>
              <img src={previousBtn} alt="" className="w- h-" />
            </button>
            <h4 className="text-[#334155] text-[1.4rem] font-[600] m-0">
              Tax Information
            </h4>
          </div>
        )}
        <form>
          <div className="bg-white-body rounded-xl border border-[#E2E8F0] ">
            <div className="bg-[#FAF8FC] px-6 py-[1rem] rounded-tr-xl rounded-tl-xl flex flex-col gap-3">
              <h4 className="text-[#334155] text-[1.2rem] font-[500] m-0">
                Financial and Ownership Details
              </h4>
              <h6 className="text-[#64748B] text-[0.9rem] font-[500] m-0 hidden md:block">
                Please provide information about your roles, shares, and
                directorships during the previous year.
              </h6>
            </div>
            <div className="px-6 py-[1rem] border-b border-[#E2E8F0]">
              <p className="m-0 text-[#334155]">
                Were you a partner in any firm during the previous financial
                year?
              </p>
              <div className="flex gap-3 justify-start items-center mt-3">
                <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center ">
                  {/* <input
                  type="radio"
                  value={true}
                  {...register("is_partner_in_firm")}
                  className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                /> */}
                  <InputField
                    type="radio"
                    name="is_partner_in_firm" // Use the same name for the group of radio buttons
                    value={true} // Set the value for this radio button
                    register={register} // Registering with React Hook Form
                    className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                    validation={{
                      required: {
                        value: true,
                        message: "Partner in Firm is required",
                      },
                    }}
                  />
                  Yes
                </label>
                <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center">
                  {/* <input
                  type="radio"
                  value={false}
                  {...register("is_partner_in_firm")}
                  className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                /> */}
                  <InputField
                    type="radio"
                    name="is_partner_in_firm" // Use the same name for the group of radio buttons
                    value={false} // Set the value for this radio button
                    register={register} // Registering with React Hook Form
                    className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                    validation={{
                      required: {
                        value: true,
                        message: "Partner in Firm is required",
                      },
                    }}
                  />
                  No
                </label>
              </div>
              <p className="text-red-600 font-[500] text-[0.8rem]">
                  {errors.is_partner_in_firm?.message}
                </p>
            </div>
            <div className="px-6 py-[1rem] border-b border-[#E2E8F0]">
              <p className="m-0 text-[#334155]">
                Did you hold any unlisted shares in the previous financial year?
              </p>
              <div className="mt-3">
                <label className="flex items-center cursor-pointer">
                  <div className="relative">
                    <InputField
                      type="checkbox"
                      className="sr-only"
                      name="has_unlisted_shares"
                      register={register}
                      onChange={handleUnlistedShares}
                    />
                    <div
                      className={`block w-9 h-5 rounded-full ${
                        hasUnlistedShares
                          ? "border-[0.6px] border-[#9065B4]"
                          : "border-[0.6px] border-[#94A3B8]"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 w-3 h-3 rounded-full transition-transform transform ${
                        hasUnlistedShares
                          ? "translate-x-4 bg-[#9065B4]"
                          : "bg-[#94A3B8]"
                      }`}
                    ></div>
                  </div>
                  <span
                    className={`ml-3 text-[16px] font-[500] ${
                      hasUnlistedShares ? "text-[#9065B4]" : "text-[#94A3B8]"
                    }`}
                  >
                    {hasUnlistedShares ? "Yes" : "No"}
                  </span>
                </label>
                {hasUnlistedShares &&
                  unlistedSharesFields.map((field, index) => {
                    return (
                      <div key={field.id}>
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between md:h-[50px]">
                          <div className="h-[100%]">
                            <h4 className="m-0 mt-3 text-[#334155] text-[1.1rem]">
                              Unlisted Equity Shares
                            </h4>
                            <p className="m-0 text-[#667085] text-[0.9rem]">
                              Enter details for unlisted equity shares held
                              during the previous year.
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 mt-4 ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Name of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              {/* <input
                              type="text"
                              placeholder="Name of Company"
                              {...register(
                                `unlisted_share_holdings.${index}.company_name`
                              )}
                              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                            /> */}
                              <InputField
                                type="text"
                                name={`unlisted_share_holdings.${index}.company_name`}
                                register={register}
                                placeholder="Name of Company"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Name of company is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.company_name?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              PAN of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              {/* <input
                              type="text"
                              placeholder="PAN No"
                              {...register(
                                `unlisted_share_holdings.${index}.pan_of_company`
                              )}
                              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                            /> */}
                              <InputField
                                type="text"
                                name={`unlisted_share_holdings.${index}.pan_of_company`}
                                register={register}
                                placeholder="PAN No"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "PAN of company is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.pan_of_company?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Type of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              {/* <select
                              {...register(
                                `unlisted_share_holdings.${index}.company_type`
                              )}
                              className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                            >
                              <option value="">Select an option</option>
                              <option value="Private Limited">
                                Private Limited
                              </option>
                              <option value="Public Limited">
                                Public Limited
                              </option>
                            </select> */}
                              <InputField
                                type="select"
                                name={`unlisted_share_holdings.${index}.company_type`}
                                register={register}
                                options={[
                                  { value: "", label: "Select an option" },
                                  {
                                    value: "Private Limited",
                                    label: "Private Limited",
                                  },
                                  {
                                    value: "Public Limited",
                                    label: "Public Limited",
                                  },
                                ]}
                                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Company Type is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.company_type?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              ISIN Code
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              {/* <input
                              type="text"
                              {...register(
                                `unlisted_share_holdings.${index}.isin_code`
                              )}
                              placeholder="ISIN Code"
                              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                            /> */}
                              <InputField
                                type="text"
                                name={`unlisted_share_holdings.${index}.isin_code`}
                                register={register}
                                placeholder="ISIN Code"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "ISIN Code is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.isin_code?.message
                              }
                            </p>
                          </div>
                        </div>
                        <hr className="text-[#E2E8F0] border mt-4" />
                        {/* <div className="flex items-baseline justify-between h-[50px]">
                    <h4 className="m-0 mt-3 text-[#334155] text-[1.1rem]">
                      Holding Details
                    </h4>
                    <div>
                      <button
                        type="button"
                        className=" md:p-[1%] px-3 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem]"
                      >
                        Attach Proof
                      </button>
                    </div>
                  </div> */}
                        <div className="flex items-baseline justify-between h-[50px]">
                          <h4 className="m-0 mt-3 text-[#334155] text-[1.1rem]">
                            Holding Details
                          </h4>
                          <div>
                            <label
                              htmlFor="file-upload"
                              className="cursor-pointer md:p-[1%] px-3 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem]"
                            >
                              Attach Proof
                            </label>
                            {/* <input
                            id="file-upload"
                            type="file"
                            className="hidden" // Hide the default file input
                            {...register(
                              `unlisted_share_holdings.${index}.holding_proof`,
                              {}
                            )}
                          /> */}
                            <InputField
                              type="file"
                              id="file-upload"
                              className="hidden"
                              register={register}
                              name={`unlisted_share_holdings[${index}].holding_proof`}
                              onChange={(e)=>handleFileInputChange(e,`unlisted_share_holdings.${index}.holding_proof`)}
                              // validation={{
                              //   required: {
                              //     value: true,
                              //     message: "Proof is required",
                              //   },
                              // }}
                            />
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                            {
                              errors.unlisted_share_holdings?.[index]
                                ?.holding_proof?.message
                            }
                          </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-12  ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Date
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              {/* <input
                              type="date"
                              placeholder="Select"
                              {...register(
                                `unlisted_share_holdings.${index}.date`
                              )}
                              className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            /> */}
                              <InputField
                                type="date"
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                register={register}
                                name={`unlisted_share_holdings.${index}.date`}
                                placeholder="Select"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Date is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]?.date
                                  ?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Quantity
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="text"
                                placeholder=""
                                register={register}
                                name={`unlisted_share_holdings.${index}.holding_quantity`}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded  focus:outline-none "
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Quantity is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.holding_quantity?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Face Price/Share
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <span className="px-2 h-full text-[#667085] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <InputField
                                type="number"
                                placeholder=""
                                register={register}
                                name={`unlisted_share_holdings.${index}.face_price_per_share`}
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Face Price/Share is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.face_price_per_share?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Purchase Price/Share
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <span className="px-2  h-full text-[#667085] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <InputField
                                type="number"
                                register={register}
                                name={`unlisted_share_holdings.${index}.purchase_price_per_share`}
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Purchase Price/Share is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.purchase_price_per_share?.message
                              }
                            </p>
                          </div>
                        </div>
                        <label className="flex items-center cursor-pointer mt-3 mb-3">
                          <div className="relative">
                            <input
                              type="checkbox"
                              className="sr-only"
                              {...register(
                                `unlisted_share_holdings.${index}.fresh_issue`
                              )}
                            />
                            <div
                              className={`block w-9 h-5 rounded-full ${
                                watch(
                                  `unlisted_share_holdings.${index}.fresh_issue`
                                )
                                  ? "border-[0.6px] border-[#9065B4]"
                                  : "border-[0.6px] border-[#94A3B8]"
                              }`}
                            ></div>
                            <div
                              className={`absolute left-1 top-1 w-3 h-3 rounded-full transition-transform transform ${
                                watch(
                                  `unlisted_share_holdings.${index}.fresh_issue`
                                )
                                  ? "translate-x-4 bg-[#9065B4]"
                                  : "bg-[#94A3B8]"
                              }`}
                            ></div>
                          </div>
                          <span
                            className={`ml-3 text-[16px] font-[500] ${
                              watch(
                                `unlisted_share_holdings.${index}.fresh_issue`
                              )
                                ? "text-[#9065B4]"
                                : "text-[#94A3B8]"
                            }`}
                          >
                            Fresh Issue
                          </span>
                        </label>
                        <div className="grid grid-cols-12  ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Transfer Date
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              <InputField
                                type="date"
                                register={register}
                                name={`unlisted_share_holdings.${index}.transfer_date`}
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Transfer Date is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.transfer_date?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Quantity
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="text"
                                placeholder=""
                                register={register}
                                name={`unlisted_share_holdings.${index}.transfer_quantity`}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded  focus:outline-none "
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Quantity is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.transfer_quantity?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Sale Consideration
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <span className="px-2 h-full text-[#667085] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <input
                                type="number"
                                // name={`unlisted_share_holdings.${index}.fresh_issue`}
                                // register={register}
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Sale Consideration is required",
                                  },
                                }}
                              />
                            </div>
                            {/* <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.fresh_issue?.message
                              }
                            </p> */}
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Balance Cost
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <span className="px-2  h-full text-[#667085] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <InputField
                                type="number"
                                register={register}
                                name={`unlisted_share_holdings.${index}.balance_cost`}
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Balance Cost is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.unlisted_share_holdings?.[index]
                                  ?.balance_cost?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div
                          className={`flex flex-col items-end md:flex-row md:h-[100%] md:items-center md:justify-end gap-3 mt-3`}
                        >
                          <button
                            type="button"
                            className={`${index>0 ? "flex":"hidden"} px-2.5 py-[0.5rem] text-[0.9rem] rounded-md bg-[#FEF2F2] text-[#DC2626]  font-[500] items-center`}
                            onClick={() => removeUnlistedShares(index)}
                          >
                            <img
                              src={deletePic}
                              alt="deletePic"
                              className="mr-1.5 h-[0.9rem] w-[0.9rem]"
                            />
                            Remove
                          </button>
                          <button
                            type="button"
                            className=" px-2.5 py-[0.2rem] rounded-md bg-[#F1F5F9] text-[#334155]  flex gap-2 items-center font-[500]"
                            onClick={() => {
                              appendUnlistedShares({
                                company_name: "",
                                pan_of_company: "",
                                company_type: "",
                                isin_code: "",
                                face_price_per_share: "",
                                purchase_price_per_share: "",
                                balance_cost: "",
                                holding_quantity: "",
                                transfer_quantity: "",
                                holding_proof: "",
                                date: "",
                                transfer_date: "",
                                fresh_issue: "",
                              });
                            }}
                          >
                            <span className="text-[1.3rem]">+</span>
                            <span className="text-[0.9rem]">
                              Add Another Unlisted Holding
                            </span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="px-6 py-[1rem] border-b border-[#E2E8F0]">
              <p className="m-0 text-[#334155]">
                Were you a director in any company during the previous financial
                year?
              </p>
              <div className="mt-3">
                <label className="flex items-center cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      {...register("is_director_in_company")}
                      onChange={(e) =>
                        setValue("is_director_in_company", e.target.checked)
                      }
                    />
                    <div
                      className={`block w-9 h-5 rounded-full ${
                        isDirectorInCompany
                          ? "border-[0.6px] border-[#9065B4]"
                          : "border-[0.6px] border-[#94A3B8]"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 w-3 h-3 rounded-full transition-transform transform ${
                        isDirectorInCompany
                          ? "translate-x-4 bg-[#9065B4]"
                          : "bg-[#94A3B8]"
                      }`}
                    ></div>
                  </div>
                  <span
                    className={`ml-3 text-[16px] font-[500] ${
                      isDirectorInCompany ? "text-[#9065B4]" : "text-[#94A3B8]"
                    }`}
                  >
                    {isDirectorInCompany ? "Yes" : "No"}
                  </span>
                </label>
                {isDirectorInCompany &&
                  directorshipFields.map((field, index) => {
                    return (
                      <div key={field.id}>
                        <div className="flex items-center justify-between h-[50px]">
                          <div className="h-[100%]">
                            <h4 className="m-0 mt-3 text-[#334155] text-[1.1rem]">
                              Directorship Details
                            </h4>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 md:mt-4 ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Name of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder="Name of Company"
                                register={register}
                                name={`directorships.${index}.company_name`}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Name of company is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.directorships?.[index]?.company_name
                                  ?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              PAN of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder="PAN No"
                                register={register}
                                name={`directorships.${index}.pan_of_company`}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "PAN of company is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.directorships?.[index]?.pan_of_company
                                  ?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Type of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="select"
                                register={register}
                                name={`directorships.${index}.company_type`}
                                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Company Type is required",
                                  },
                                }}
                                options={[
                                  { value: "", label: "Select an Option" },
                                  {
                                    value: "Public Limited",
                                    label: "Public Limited",
                                  },
                                  {
                                    value: "Private Limited",
                                    label: "Private Limited",
                                  },
                                ]}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.directorships?.[index]?.company_type
                                  ?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              ISIN Code
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder="ISIN Code"
                                register={register}
                                name={`directorships.${index}.isin_code`}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "ISIN Code is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.directorships?.[index]?.isin_code
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div
                          className={`flex flex-col md:flex-row items-end h-[100%] md:items-center md:justify-end gap-3 mt-3`}
                        >
                          <button
                            type="button"
                            onClick={() => removeDirectorship(index)}
                            className={`${index>0 ? "flex":"hidden"} px-2.5 py-[0.5rem] text-[0.9rem] rounded-md bg-[#FEF2F2] text-[#DC2626]  font-[500] items-center`}
                          >
                            <img
                              src={deletePic}
                              alt="deletePic"
                              className="mr-1.5 h-[0.9rem] w-[0.9rem]"
                            />
                            Remove
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              appendDirectorship({
                                company_name: "",
                                pan_of_company: "",
                                company_type: "",
                                isin_code: "",
                              });
                            }}
                            className=" px-2.5 py-[0.2rem] rounded-md bg-[#F1F5F9] text-[#334155]  flex gap-2 items-center font-[500]"
                          >
                            <span className="text-[1.3rem]">+</span>
                            <span className="text-[0.9rem]">
                              Add Another Company
                            </span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="px-6 py-[1rem] border-b border-[#E2E8F0]">
              <p className="m-0 text-[#334155]">
                Do you own any Employee Stock Options (ESOPs)?
              </p>
              <div className="mt-3">
                <label className="flex items-center cursor-pointer">
                  <div className="relative">
                    <input
                      type="checkbox"
                      className="sr-only"
                      {...register("has_esops")}
                      onChange={(e) => setValue("has_esops", e.target.checked)}
                    />
                    <div
                      className={`block w-9 h-5 rounded-full ${
                        hasEsops
                          ? "border-[0.6px] border-[#9065B4]"
                          : "border-[0.6px] border-[#94A3B8]"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 w-3 h-3 rounded-full transition-transform transform ${
                        hasEsops ? "translate-x-4 bg-[#9065B4]" : "bg-[#94A3B8]"
                      }`}
                    ></div>
                  </div>
                  <span
                    className={`ml-3 text-[16px] font-[500] ${
                      hasEsops ? "text-[#9065B4]" : "text-[#94A3B8]"
                    }`}
                  >
                    {hasEsops ? "Yes" : "No"}
                  </span>
                </label>
                {hasEsops &&
                  esopsFields.map((field, index) => {
                    return (
                      <div key={field.id}>
                        <div className=" md:h-[50px]">
                          <h4 className="m-0 mt-3 text-[#334155] text-[1.1rem]">
                            Tax Deferred on Sweat Equity Shares/Securities
                          </h4>
                          <p className="m-0 text-[#667085] text-[0.9rem]">
                            Enter details related to tax deferral for sweat
                            equity shares or securities.
                          </p>
                        </div>
                        <div className="grid grid-cols-12 mt-4 ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Name of Startup (Employer)
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                name={`esops.${index}.startup_name`}
                                register={register}
                                type="text"
                                placeholder="Name of Company"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Startup Name is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.startup_name?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              PAN of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder="PAN No"
                                name={`esops.${index}.pan_of_company`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "PAN of company is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.pan_of_company?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Type of Company
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="select"
                                name={`esops.${index}.company_type`}
                                register={register}
                                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Company Type is required",
                                  },
                                }}
                                options={[
                                  { value: "", label: "Select an Option" },
                                  {
                                    value: "Public Limited",
                                    label: "Public Limited",
                                  },
                                  {
                                    value: "Private Limited",
                                    label: "Private Limited",
                                  },
                                ]}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.company_type?.message}
                            </p>
                          </div>
                          {/* <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple flex">
                    DPIIT Reg. No.
                    <img src={info1} alt="info"/>
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <input
                        type="text"
                        placeholder=""
                        className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                  </div> */}
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 relative">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple flex items-center">
                              DPIIT Reg. No.
                              <div className="relative ml-1 flex items-center">
                                <img
                                  src={info1}
                                  alt="info"
                                  className="cursor-pointer"
                                />
                                <div className="absolute hidden group-hover:block bg-[#000000B2] text-[#FFFFFF] text-xs rounded-lg px-3 py-2 w-[200px] left-[-60px] top-[-65px] shadow-md">
                                  <p className="m-0">
                                    Department for Promotion of Industry and
                                    Internal Trade registration number
                                  </p>
                                  <div className="absolute bg-[#334155] w-2 h-2 rotate-45 left-16 bottom-[-4px]"></div>
                                </div>
                              </div>
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder=""
                                name={`esops.${index}.dpit_reg_no`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[100%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Registration No. is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.dpit_reg_no?.message}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 mt-4 ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Assessment Year
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              <InputField
                                type="date"
                                placeholder="Select"
                                name={`esops.${index}.assessment_year`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Assessment Year is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.assessment_year?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Tax Deferred - B/F
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder=""
                                name={`esops.${index}.tax_deferred`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Tax Deferred is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.tax_deferred?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Date of Cessation of Employment
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              <InputField
                                type="date"
                                placeholder="Select"
                                name={`esops.${index}.date_of_cessation_of_employment`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Date of Cessation is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.esops?.[index]
                                  ?.date_of_cessation_of_employment?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Balance Tax - C/F
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder=""
                                name={`esops.${index}.balance_tax_c_or_f`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Balance Tax is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.esops?.[index]?.balance_tax_c_or_f
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between h-[50px] mt-3">
                          <h4 className="m-0 mt-3 text-[#334155] text-[1.1rem]">
                            Holding Details
                          </h4>
                        </div>
                        <div className="grid grid-cols-12 ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Assessment Year
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              <InputField
                                type="date"
                                placeholder="Select"
                                name={`esops.${index}.holding_assessment_year`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Assessment Year is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.esops?.[index]?.holding_assessment_year
                                  ?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Date of Sale
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              <InputField
                                type="date"
                                name={`esops.${index}.date_of_sale`}
                                register={register}
                                placeholder="Select"
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Date of Sale is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.esops?.[index]?.date_of_sale?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Tax Related to Sale
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                name={`esops.${index}.tax_related_to_sale`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Tax related to Sale is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.esops?.[index]?.tax_related_to_sale
                                  ?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Balance Tax Payable
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <span className="px-2 h-full text-[#667085] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <InputField
                                type="number"
                                placeholder=""
                                name={`esops.${index}.balance_tax_payable`}
                                register={register}
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Balance Tax Payable is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.esops?.[index]?.balance_tax_payable
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div
                          className={`flex flex-col md:flex-row items-end h-[100%] md:items-center md:justify-end gap-3 mt-3`}
                        >
                          <button
                            type="button"
                            onClick={() => removeEsop(index)}
                            className={`${index>0 ? "flex":"hidden"} px-2.5 py-[0.5rem] text-[0.9rem] rounded-md bg-[#FEF2F2] text-[#DC2626]  font-[500] items-center`}
                          >
                            <img
                              src={deletePic}
                              alt="deletePic"
                              className="mr-1.5 h-[0.9rem] w-[0.9rem]"
                            />
                            Remove
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              appendEsop({
                                startup_name: "",
                                pan_of_company: "",
                                company_type: "",
                                dpit_reg_no: "",
                                assessment_year: "",
                                tax_deferred: "",
                                date_of_cessation_of_employment: "",
                                balance_tax_c_or_f: "",
                                holding_assessment_year: "",
                                date_of_sale: "",
                                tax_related_to_sale: "",
                                balance_tax_payable: "",
                              });
                            }}
                            className=" px-2.5 py-1.5 rounded-md bg-[#F1F5F9] text-[#334155]  flex gap-2 items-center font-[500]"
                          >
                            <span className="text-[1.3rem]">+</span>
                            <span className="text-[0.9rem]">
                              Add Another Holding
                            </span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </form>
        <div className="bg-white-body rounded-xl border border-[#E2E8F0] mt-5 ">
          <div className="bg-[#FAF8FC] px-6 py-[1rem] rounded-tr-xl rounded-tl-xl flex flex-col gap-3">
            <h4 className="text-[#334155] text-[1.2rem] font-[500] m-0">
              Return Filing Information
            </h4>
            <h6 className="text-[#64748B] text-[0.9rem] font-[500] m-0 hidden md:block">
              Specify the section, type of return filed, and any representative
              assessee details.
            </h6>
          </div>
          <div className="px-6 py-[1rem] flex flex-col md:flex-row md:items-center md:justify-between gap-2 md:gap-0">
            <p className="m-0 text-[#334155] md:w-[60%] w-[100%]">
              Under which section was the return filed?
            </p>
            <div className="flex items-center border-[#94A3B8] border rounded md:w-[25%] h-[44px] md:h-[37px]">
              <InputField
                type="select"
                name={`return_filing_info[0].section_filed_under`}
                register={register}
                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                validation={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
                options={[
                  { value: "", label: "Select an Option" },
                  {
                    value: "Section 139(1)",
                    label: " Section 139(1) - On or before the due date",
                  },
                  {
                    value: "Section 139(4)",
                    label: "Section 139(4) - Trustee return",
                  },
                  {
                    value: "Section 139(5)",
                    label: "Section 139(5) - Revised return",
                  },
                  {
                    value: "Section 139(3)",
                    label: "Section 139(3) - Loss return",
                  },
                  {
                    value: "Section 92CD",
                    label: "Section 92CD - Modified return under APA",
                  },
                  {
                    value: "Section 119(2)(b)",
                    label:
                      "Section 119(2)(b) - Return after condonation of delay",
                  },
                ]}
              />
            </div>
          
          <p className="text-red-600 font-[500] text-[0.8rem]">
            {errors.return_filing_info?.[0]?.section_filed_under?.message}
          </p>
          </div>
          <div className="px-6 py-[1rem] flex flex-col md:flex-row md:items-center md:justify-between gap-2 md:gap-0">
            <p className="m-0 text-[#334155] md:w-[60%] w-[100%]">
              What type of return was filed?
            </p>
            <div className="flex items-center border-[#94A3B8] border rounded md:w-[25%] h-[44px] md:h-[37px]">
              <InputField
                type="select"
                register={register}
                name={`return_filing_info[0].return_type`}
                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                validation={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
                options={[
                  { value: "", label: "Select an Option" },
                  { value: "Original", label: "Original" },
                  { value: "Revised", label: "Revised" },
                  { value: "Modified", label: "Modified" },
                  { value: "Belated", label: "Original" },
                ]}
              />
            </div>
          
          <p className="text-red-600 font-[500] text-[0.8rem]">
            {errors.return_filing_info?.[0]?.return_type?.message}
          </p>
          </div>
          <div className="px-6 py-[1rem] flex flex-col md:flex-row md:items-center md:justify-between gap-2 md:gap-0">
            <p className="m-0 text-[#334155] md:w-[60%] w-[100%]">
              Is there a representative assessee? (If applicable)
            </p>
            <div className="flex items-center border-[#94A3B8] border rounded md:w-[25%] h-[44px] md:h-[37px]">
              <InputField
                type="select"
                name={`return_filing_info[0].has_representative_access`}
                register={register}
                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                validation={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
                options={[
                  { value: "", label: "Select an Option" },
                  {
                    value: "Legal Representative",
                    label: "Legal Representative",
                  },
                  { value: "Agent", label: "Agent" },
                  { value: "Guardian", label: "Guardian" },
                  { value: "Trustee", label: "Trustee" },
                  {
                    value: "Any other person representing the assessee",
                    label: "Any other person representing the assessee",
                  },
                ]}
              />
            </div>
          
          <p className="text-red-600 font-[500] text-[0.8rem]">
            {errors.return_filing_info?.[0]?.has_representative_access?.message}
          </p></div>
        </div>
        <div className="bg-white-body rounded-xl border border-[#E2E8F0] mt-5 md:mb-2">
          <div className="bg-[#FAF8FC] px-6 py-[1rem] rounded-tr-xl rounded-tl-xl flex flex-col gap-3">
            <h4 className="text-[#334155] text-[1.2rem] font-[500] m-0">
              Residential Status
            </h4>
            <h6 className="text-[#64748B] text-[0.9rem] font-[500] m-0 hidden md:block">
              The residential status depends on the number of days you stayed in
              India. Please follow the process to choose the correct residential
              status.
            </h6>
          </div>
          <div className="px-6 py-[1rem] flex flex-col gap-2">
            <p className="m-0 text-[#667085] font-[500] text-[1rem]">
              YOUR RESIDENTIAL STATUS
            </p>
            <div className="flex items-center justify-between bg-[#F1F2F5] rounded-md px-2.5 py-1.5">
              <span className="text-[#344054] font-[500]">
                {" "}
                {residentialStatus === 1
                  ? "Indian Resident"
                  : residentialStatus === 2
                  ? "Non Resident Indian"
                  : residentialStatus === 3
                  ? "Indian Resident (Not Ordinary)"
                  : "Indian Resident (Ordinary)"}
              </span>
              <span
                onClick={() => handleStatusChange((residentialStatus % 4) + 1)}
                className="text-[#9065B4] flex gap-2 font-[500] cursor-pointer"
              >
                <span className="hidden md:block">
                  Change Residential status
                </span>
                <img src={arrowPic} alt="arrowPic" />
              </span>
            </div>
            <p className="m-0 text-[#667085] text-[0.9rem]">
              You were a{" "}
              {residentialStatus === 1
                ? "Indian Resident"
                : residentialStatus === 2
                ? "Non Resident Indian"
                : residentialStatus === 3
                ? "Indian Resident (Not Ordinary)"
                : "Indian Resident (Ordinary)"}{" "}
              during the FY 2023-24 (April 2023 to March 2024)
            </p>
            <input
              type="hidden"
              {...register("residential_status")}
              value={residentialStatus}
            />
          </div>
        </div>
        <div className="md:mt-4 md:mb-2 mt-2 mb-[5.75rem] flex justify-end items-center">
          <button
            type="submit"
            className="rounded px-2 py-1.5 text-[#FFFFFF] bg-[#9065B4]"
          >
            Save Tax Information
          </button>
        </div>
      </div>
    </form>
  );
};

export default IncomeTaxProfileInformation;
