import React from "react";
import avatar from "assets/images/home/Avatar 2.png";
import puzzle from "assets/images/home/puzzle.svg";
import charity from "assets/images/home/charity 2.svg";
import group from "assets/images/home/Group.svg";
import DateCalendarFormProps from "./Calender";

function Reason() {
  return (
    <>
      <div className="p-[8%] grid grid-cols-12 relative mb-[20%] ">
        <div className="lg:col-span-6 md:col-span-6 col-span-12">
          {" "}
          <h2 className=" font-[700] lg:text-[3rem] lg:leading-[4rem]  md:text-[3rem] md:leading-[4rem] text-[2rem] leading-[2.5rem]">
            Why Choose Us? 
          </h2>
          <button className="blackBgBtn text-white px-[2.5rem] py-[0.6rem] rounded-lg my-2">
            Apply Now
          </button>
        </div>
        <div className=" bg-purple-light-shade col-span-5 lg:flex md:flex hidden rounded-lg w-[100%] ">
          <img className="avatar w-[80%] mx-auto p-[10%]" src={avatar} />
        </div>
        <div className="lg:absolute md:absolute  grid grid-cols-12 md:bottom-[-55%] p-[8%] lg:bottom-[-25%] gap-4 ">
          <div className="md:col-span-4 col-span-12 lg:col-span-4 p-[5%]  flex  h-52   justify-between flex-col gap-5 purpleGlassMorphism purlpleGMHover">
            <div className="flex  items-center gap-2  ">
              <img src={puzzle} alt="" />
              <p className=" font-[500] lg:text-[1rem] md:text-[1rem] text-[0.8rem] m-0 leading-[1.5rem]">
                We make the process easy-peasy.
              </p>
            </div>
            <h5 className=" font-[500] text-[1.5rem] leading-[2rem] mt-[10%]">
              Simplicity
            </h5>
          </div>
          <div className="md:col-span-4 col-span-12 lg:col-span-4 p-[5%]  h-52   justify-between  flex flex-col gap-5 purpleGlassMorphism purlpleGMHover">
            <div className="flex  items-center gap-2  ">
              <img src={charity} alt="" />
              <p className=" font-[500] lg:text-[1rem] md:text-[1rem] text-[0.8rem] m-0 leading-[1.5rem]">
                Like a GPS, we navigate you through every step.
              </p>
            </div>
            <h5 className=" font-[500] text-[1.5rem] leading-[2rem] mt-[10%]">
              Guidance
            </h5>
          </div>
          <div className="md:col-span-4 col-span-12 lg:col-span-4 p-[5%]  h-52   justify-between  flex flex-col gap-5 purpleGlassMorphism purlpleGMHover">
            <div className="flex  items-center gap-2  ">
              <img src={group} alt="" />
              <p className=" font-[500] lg:text-[1rem] md:text-[1rem] text-[0.8rem] m-0 leading-[1.5rem]">
                Got questions? We have answers and we’re just a call or click
                away.
              </p>
            </div>
            <h5 className=" font-[500] text-[1.5rem] leading-[2rem] mt-[10%]">
              Support
            </h5>
          </div>
        </div>
      </div>
      {/* <DateCalendarFormProps /> */}
    </>
  );
}

export default Reason;
