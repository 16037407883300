import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import removeFrnd from "assets/images/incomeTax/removeFrnd.png";
import editPen from "assets/images/incomeTax/editPen.png";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import useGetSalaryIncome from "./Data/useGetSalaryIncome";
import { useNavigate, useParams } from "react-router-dom";
import usePostSalaryIncome from "./Data/usePostSalaryIncome";
import usePatchSalaryIncome from "./Data/usePatchSalaryIncome";
import { INCOME_TAX_SUMMARY_USER_DETAILS } from "constants/routes";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

const TaxSummarySalary = forwardRef(({ isDisabled }, ref) => {
  const { income_tax_return_id } = useParams();
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [
    salaryIncomeData,
    salaryIncomeError,
    salaryIncomeIsLoading,
    postSalaryIncomeData,
  ] = usePostSalaryIncome();

  const [getData, getError, getIsLoading, getSalaryIncomeData] =
    useGetSalaryIncome();

  const [patchData, patchError, patchIsLoading, patchSalaryIncomeData] =
    usePatchSalaryIncome();

  const defaultValue = {
    employer_name: "",
    tan: "",
    employer_category: "",
    tds_deduction: "",
    income_reported: "",
    upload_form_type: "1",
    upload_form_file: "",
    gross_salary: "",
    basic_salary_component: "",
    hra_component: "",
    annual_rent_paid: "",
    do_you_live_in_these_cities: true,
  };

  const form = useForm({ defaultValues: { salary_income: [defaultValue] } });
  const { register, control, handleSubmit, setValue, reset, formState } = form;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "salary_income",
    control,
  });

  const watchFieldArray = useWatch({
    control,
    name: "salary_income",
  });

  let controlledFields = fields.map((field, index) => {
    return {
      ...field,
      upload_form_type:
        watchFieldArray && Number(watchFieldArray[index]?.upload_form_type),
      do_you_live_in_these_cities:
        watchFieldArray &&
        Boolean(watchFieldArray[index]?.do_you_live_in_these_cities),
    };
  });

  
  useEffect(() => {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        do_you_live_in_these_cities:
          watchFieldArray &&
          Boolean(watchFieldArray[index]?.do_you_live_in_these_cities),
      };
    });
  }, [watchFieldArray]);

  useEffect(() => {
    getSalaryIncomeData(income_tax_return_id);
  }, []);

  const path = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
    ":detailType",
    "rental"
  ).replace(":income_tax_return_id", income_tax_return_id);

  useEffect(() => {
    if (salaryIncomeData) {
      showSuccessToast("Salary Data uploaded");
      if (isFormSubmitted) {
        navigate(path);
      }
    }
    if (patchData) {
      showSuccessToast("Salary Data updated");
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [salaryIncomeData, patchData]);

  useEffect(() => {
    if (salaryIncomeError || patchError) {
      showErrorToast("Try Again! Salary Data not uploaded");
    }
  }, [patchError, salaryIncomeError]);

  useEffect(() => {
    if (getData && getData.data?.total_count >= 1) {
      const totalCount = getData?.data?.total_count || 0;
      const salaryIncome = getData.data.results || [];
      const initializedData = Array.from({ length: totalCount }, (_, index) => {
        const salary = salaryIncome[index] || defaultValue;
        return {
          id: salary.id,
          employer_name: salary.employer_name,
          tan: salary.tan,
          employer_category: salary.employer_category,
          tds_deduction: salary.tds_deduction,
          income_reported: salary.income_reported,
          upload_form_type: salary.upload_form_type,
          upload_form_file: salary.upload_form_file,
          gross_salary: salary.gross_salary,
          basic_salary_component: salary.basic_salary_component,
          hra_component: salary.hra_component,
          annual_rent_paid: salary.annual_rent_paid,
          do_you_live_in_these_cities: salary.do_you_live_in_these_cities,
        };
      });
      reset({ salary_income: initializedData });
    } else {
      reset(defaultValue);
    }
  }, [getData]);

  const salaryRemoveForm = (index) => {
    remove(index);
  };

  useImperativeHandle(ref, () => ({
    salaryAddEmployee() {
      append({ ...defaultValue });
    },
    handleSubmit: () => handleSubmit(onSubmit)(),
  }));

  const onSubmit = (data) => {
    setIsFormSubmitted(true);
    const formData = new FormData();
    data.salary_income.map((item, index) => {
      // formData.append("data", JSON.stringify(item));
      const updatedItem = {
        ...item,
        do_you_live_in_these_cities:
          item.do_you_live_in_these_cities === "true",
      };

      formData.append("data", JSON.stringify(updatedItem));
      const file = item.upload_form_file;
      const encoder = new TextEncoder();
      const binaryFile = encoder.encode(file);
      if (binaryFile && binaryFile.length > 0) {
        formData.append("files", new Blob([binaryFile[0]]));
      }
    });

    const ids = getData?.data?.results.map((item) => item.id) || [];
    if (ids.length) {
      ids.forEach((id, index) => {
        patchSalaryIncomeData(income_tax_return_id, formData);
      });
    } else {
      postSalaryIncomeData(income_tax_return_id, formData);
    }
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {console.log("controled fields", controlledFields)}
          {controlledFields.map((field, index) => {
            return (
              <div
                index={index}
                key={field.id}
                field={field}
                className="border border-gray-200 rounded mt-4 p-[2%]"
              >
                <div className="flex justify-between items-center mb-2.5 md:mb-0">
                  <h4 className="mt-3 text-[#334155] text-[1rem] md:text-auto">
                    Employer Details
                  </h4>
                  {index > 0 ? (
                    <button
                      type="button"
                      disabled={isDisabled || false}
                      onClick={() => salaryRemoveForm(index)}
                      className=" md:p-[1%] p-[8px] rounded-md bg-[#FEF2F2] text-[#DC2626] font-[500] flex text-[0.9rem] md:text-auto items-center justify-between gap-2"
                    >
                      <img src={removeFrnd} alt="removeFrnd" className="w-4" />
                      Remove Employer
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="grid grid-cols-12">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      Employer
                    </h6>
                    <div className="relative w-[95%]  h-[44px] md:h-[37px] ">
                      <InputField
                        type="text"
                        name={`salary_income[${index}].employer_name`}
                        className="text-[#667085] w-full h-full rounded px-[0.7rem] py-[0.7rem] pr-[3rem] focus:outline-none focus-within:border-customPurple border-1 border-[#94A3B8]"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "Employer is required",
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/,
                            message: "Only alphanumeric characters are allowed",
                          },
                        }}
                      />
                      <span className="absolute right-0 inset-y-0 flex items-center px-2">
                        <img
                          src={editPen}
                          alt="editPen"
                          className="w-[9px] mr-1"
                        />
                        <span className="text-[10px] text-[#64748B]">Edit</span>
                      </span>
                    </div>

                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.employer_name?.message}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="group md:col-span-3 col-span-12  md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      TAN
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <InputField
                        type="text"
                        name={`salary_income[${index}].tan`}
                        className="text-[#667085] w-[100%] md:w-[100%] px-[0.7rem] md:py-[.3rem] rounded focus:outline-none"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "TAN is required",
                          },
                          pattern: {
                            value: /^[A-Z]{4}[0-9]{5}[A-Z]$/,
                            message:
                              "TAN must be in the format: 4 capital letters, 5 digits, and 1 capital letter",
                          },
                          validate: (value) =>
                            value.length === 10 ||
                            "TAN must be exactly 10 characters",
                        }}
                        maxLength={10}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.tan?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#667085] group-focus-within:text-customPurple">
                      Category
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <InputField
                        className="text-[#334155] md:w-[100%] w-[100%] px-[0.5rem] md:px-[0.7rem] md:py-[.4rem] rounded focus:outline-none"
                        type="select"
                        name={`salary_income[${index}].employer_category`}
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "Category is required",
                          },
                        }}
                        options={[
                          { value: "", label: "Select" },
                          { value: 1, label: "Others" },
                          { value: 2, label: "Central Government" },
                          { value: 3, label: "State Government" },
                          { value: 4, label: "Public Sector Unit" },
                        ]}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {
                        errors.salary_income?.[index]?.employer_category
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      TDS Deducted
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`salary_income[${index}].tds_deduction`}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] md:py-[.3rem] rounded  focus:outline-none "
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "TDS Deducted is required",
                          },
                        }}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.tds_deduction?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      Income Reported
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9]  h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`salary_income[${index}].income_reported`}
                        className="text-[#667085] w-[100%] md:w-[95%] px-[.7rem] md:py-[.3rem] rounded focus:outline-none "
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "Income Reported is required",
                          },
                        }}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.income_reported?.message}
                    </p>
                  </div>
                </div>
                {/* <div className="grid grid-cols-12 mt-3 mb-4 ">
                  <label className="col-span-5 md:col-span-3 font-[500] text-[.9rem] leading-[1rem] hover:cursor-pointer text-[#667085] checked:text-customPurple">
                    <input
                      type="radio"
                      // {...register("pensionIncome")}
                      className="ml-1 mr-1 checked:bg-customPurple hover:cursor-pointer"
                      value="pension"
                    />
                    Pension Income
                  </label>
                </div> */}
                <hr />
                <h4 className="mt-5 mb-[1.20rem] text-[#334155] font-[500]">
                  Salary Details
                </h4>
                <div className="flex justify-between h-[45px] md:mb-2 mb-5">
                  {/* form type */}
                  <div>
                    <h6 className="text-[#334155] font-[400] text-[0.9rem] md:text-[1rem] leading-[1rem] mb-[0.9rem]">
                      Import Your Salary Details
                    </h6>
                    <div className="flex justify-between flex-col gap-3 md:flex-row md:gap-0">
                      <label className="col-span-3 font-[500] text-[0.8rem] md:text-[.9rem] leading-[1rem] hover:cursor-pointer text-[#64748B] focus-within:text-customPurple ">
                        <InputField
                          type="radio"
                          name={`salary_income.${index}.upload_form_type`}
                          value={1}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "Salary Details is required",
                            },
                          }}
                          className="ml-1 mr-1  focus:accent-customPurple hover:cursor-pointer"
                          onChange={() =>
                            setValue(
                              `salary_income.${index}.upload_form_type`,
                              1
                            )
                          }
                          checked={field.upload_form_type === 1}
                        />
                        Form 16
                      </label>
                      <label className="col-span-3 font-[500] text-[0.8rem] md:text-[.9rem] leading-[1rem] hover:cursor-pointer text-[#64748B] focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          name={`salary_income.${index}.upload_form_type`}
                          value={2}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "Salary Details is required",
                            },
                          }}
                          className="ml-1 mr-1  focus:accent-customPurple hover:cursor-pointer"
                          onChange={() =>
                            setValue(
                              `salary_income.${index}.upload_form_type`,
                              2
                            )
                          }
                          checked={field.upload_form_type === 2}
                        />
                        Payslip
                      </label>
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.upload_form_type?.message}
                    </p>
                  </div>
                  <div className="relative top-[1.87rem] md:top-[-2.5rem] md:w-[17%]">
                    <button
                      onClick={() =>
                        document.querySelector(`#file-input-${index}`).click()
                      }
                      type="button"
                      className=" p-[10px] md:p-[1.5%] md:px-4 md:h-[100%] w-[100%]  rounded-md bg-purple-100 text-[#9065B4] md:text-purple-600 font-[500] md:font-[600] text-[0.9rem] md:text-[1rem] mr-3 hover:cursor-pointer"
                    >
                      Upload Form 16
                    </button>
                    <input
                      disabled={isDisabled || false}
                      type="file"
                      className="hidden"
                      id={`file-input-${index}`}
                      {...register(`salary_income.${index}.upload_form_file`, {
                        required: {
                          value: true,
                          message: "File is required",
                        },
                      })}
                    />
                    {/* <InputField
                      type="file"
                      // accept=".pdf"
                      register={register}
                      name={`salary_income.${index}.upload_form_file`}
                      id={`file-input-${index}`}
                      className="hidden"
                      validation={{
                        required: {
                          value: true,
                          message: "File is required",
                        },
                      }}
                      onChange={(e) => {
                        const file = e.target.files[0]; // Capture the file on change
                        if (file) {
                          console.log("File uploaded:", file);
                        }
                      }}
                    /> */}

                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.upload_form_file?.message}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 mb-3 mt-2 md:mt-0">
                  <div className="group md:col-span-3 col-span-12  md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      Gross Salary
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9]  h-full md:w-[15%] flex items-center justify-center rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`salary_income[${index}].gross_salary`}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] md:py-[.3rem] rounded  focus:outline-none"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "Gross Salary is required",
                          },
                        }}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.gross_salary?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      Basic Salary Component
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`salary_income[${index}].basic_salary_component`}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "Basic Salary Component is required",
                          },
                        }}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {
                        errors.salary_income?.[index]?.basic_salary_component
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1 ">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      HRA Component
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`salary_income[${index}].hra_component`}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] md:py-[.3rem] rounded  focus:outline-none"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "HRA Component is required",
                          },
                        }}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.hra_component?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      Annual Rent Paid
                    </h6>
                    <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`salary_income[${index}].annual_rent_paid`}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] md:py-[.3rem] rounded focus:outline-none "
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "Annual Rent Paid is required",
                          },
                        }}
                      />
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.salary_income?.[index]?.annual_rent_paid?.message}
                    </p>
                  </div>
                </div>
                {/* <div className="mb-4">
                  <button
                    type="button"
                    onClick={addAllowance}
                    disabled={isDisabled || false}
                    className=" md:p-[1%] px-3 py-2 rounded-md bg-gray-100 text-slate-800 hover:cursor-pointer"
                  >
                    Add Allowances
                  </button>
                </div> */}
                <hr />
                <div className="mt-4 flex md:justify-between md:flex-row flex-col">
                  <div>
                    <p>
                      Do You live in cities like Kolkata, Bangalore, Mumbai, or
                      Delhi?{" "}
                    </p>
                  </div>
                  {/* do you radios */}
                  <div className="flex gap-4">
                    <label className="font-[500] text-[.9rem] leading-[1rem] hover:cursor-pointer focus-within:text-customPurple text-[#667085]">
                      <InputField
                        type="radio"
                        name={`salary_income[${index}].do_you_live_in_these_cities`}
                        value={true}
                        checked={field.do_you_live_in_these_cities === true}
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "This fields is required",
                          },
                        }}
                        onChange={() =>
                          setValue(
                            `salary_income[${index}].do_you_live_in_these_cities`,
                            true
                          )
                        }
                        className="ml-2 mr-2 hover:cursor-pointer focus:accent-customPurple"
                      />
                      Yes
                    </label>
                    <label className="font-[500] text-[.9rem] leading-[1rem] hover:cursor-pointer focus-within:text-customPurple text-[#667085] ">
                      <InputField
                        type="radio"
                        name={`salary_income[${index}].do_you_live_in_these_cities`}
                        value={false}
                        checked={field.do_you_live_in_these_cities === false}
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        }}
                        onChange={() =>
                          setValue(
                            `salary_income[${index}].do_you_live_in_these_cities`,
                            false
                          )
                        }
                        className="ml-2 mr-2 hover:cursor-pointer focus:accent-customPurple"
                      />
                      No
                    </label>
                  </div>
                  <p className="text-red-600 font-[500] text-[0.8rem]">
                    {
                      errors.salary_income?.[index]?.do_you_live_in_these_cities
                        ?.message
                    }
                  </p>
                </div>
              </div>
            );
          })}
        </form>{" "}
      </FormProvider>
    </div>
  );
});

export default TaxSummarySalary;
