import React from "react";
import diveHomeImg from "assets/images/home/dive.png";
function DiveHome() {
  return (
    <>
      <div className="DiveHome w-screen max-h-screen h-fit flex items-center lg:py-[8%] lg:px-[4%] md:py-[8%] md:px-[4%] py-[20%] px-[4%] ">
        <div className=" innerDiveHome lg:py-28 lg:px-14 md:py-28 md:px-14 py-8 px-4 grid grid-cols-12">
          <div className=" lg:col-span-7 md:col-span-7 col-span-12 flex flex-col items-stretch justify-evenly">
            <h2 className="lg:font-[700] lg:text-[3rem] lg:leading-[3.8rem] md:font-[700] md:text-[3rem] md:leading-[3.8rem] font-[700] text-[1.5rem] leading-[2.5rem]">
              Dive into &nbsp;
              <span className=" bg-gradient-to-r from-violet-800 to-red-600 text-transparent bg-clip-text">
                Beyondtax
              </span>
            </h2>
            <p className=" text-gray-500 font-[500] text-[1rem] leading-[1.5rem] mb-10">
              Where tax solutions are just the beginning. Imagine all the
              paperwork, numbers, and deadlines simplified into a seamless flow
              of services tailored just for you. From starting up your dream
              business to managing your money matters with ease — we've got it
              all under one roof. Income Tax, GST, TDS, Accounting, and beyond.
              Wave goodbye to financial puzzles and hello to hassle-free
              solutions.
            </p>
          </div>
          <div className="md:flex lg:flex col-span-5 hidden">
            <img src={diveHomeImg} alt="" className="diveImg w-fit h-fit"/>
          </div>
        </div>
      </div>
    </>
  );
}

export default DiveHome;
