import React, { useEffect, useState } from "react";
import AddYourLiability from "../AddYourLiability";
import "../liabilities.css";

// images
import RemoveLiability from "../RemoveLiability";
import WhiteButton from "components/Button/WhiteButton";
import InputField from "components/input/InputField";
import { useLocation } from "react-router-dom";
import LiabilitiesHeading from "../LiabilitiesHeading";
import useGetCreditCard from "../data/useGetCreditCards";
import Button from "components/Button/BtnOrange";
import usePatchCredit from "../data/usePatchCredit";
import { showSuccessToast } from "components/toaster/toastHelper";

const RevolvingLoanDetailed = () => {
  const location = useLocation();
  const [cardTitle, setcardTitle] = useState("");
  const [cardIssuer, setcardIssuer] = useState("");
  const [issueDate, setissueDate] = useState("");
  const [creditLimit, setcreditLimit] = useState("");
  const [amtUtilised, setamtUtilised] = useState("");
  const [balance, setbalance] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [amtLastPaid, setamtLastPaid] = useState("");
  const [amtPayable, setamtPayable] = useState("");
  const [errors, setErrors] = useState({});
  const [isEditClicked, setisEditClicked] = useState(false);

  const [
    getCreditData,
    getCreditError,
    getCreditIsLoading,
    getCreditCardDetails,
  ] = useGetCreditCard();

  const [patchData, patchError, patchIsLoading, patchCreditDetails] =
    usePatchCredit();

  useEffect(() => {
    getCreditCardDetails(id);
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const id = decodeURIComponent(queryParams.get("id"));
  const loan_type = location.state.loan_type;
  const loan_type_id = location.state.loanTypeID;

  const validateInputs = () => {
    const newErrors = {};
    const namePattern = /^[a-zA-Z\s]+$/;

    if (!cardTitle) {
      newErrors.cardTitle = "Card Title is required";
    } else if (!namePattern.test(cardTitle)) {
      newErrors.cardTitle =
        "Card Title should not contain special characters or numbers";
    }

    if (!cardIssuer) {
      newErrors.cardIssuer = "Card Issuer is required";
    } else if (!namePattern.test(cardIssuer)) {
      newErrors.cardIssuer =
        "Card Issuer should not contain special characters or numbers";
    }

    // Validate issue date
    if (!issueDate) {
      newErrors.issueDate = "Issue Date is required";
    }

    // if (!creditLimit || !/^\d+$/.test(creditLimit)) {
    //   newErrors.creditLimit =
    //     "Valid Credit Limit is required (whole numbers only)";
    // } else if (creditLimit < 0) {
    //   newErrors.creditLimit = "Credit Limit must be greater than or equal to 0";
    // }
    if (
      !creditLimit || 
      !/^\d+(\.00)?$/.test(creditLimit) // Accept whole numbers or numbers with `.00`
    ) {
      newErrors.creditLimit =
        "Valid Credit Limit is required (whole numbers only)";
    } else if (creditLimit < 0) {
      newErrors.creditLimit = "Credit Limit must be greater than or equal to 0";
    }
    

    // Validate amount utilized: only whole numbers and must be >= 0
    if (!amtUtilised || !/^\d+(\.00)?$/.test(amtUtilised)) {
      newErrors.amtUtilised =
        "Valid Amount Utilised is required (whole numbers only)";
    } else if (amtUtilised < 0) {
      newErrors.amtUtilised =
        "Amount Utilised must be greater than or equal to 0";
    }

    // Validate available balance: only whole numbers and must be >= 0
    if (!balance || !/^\d+(\.00)?$/.test(balance)) {
      newErrors.balance =
        "Valid Available Balance is required (whole numbers only)";
    } else if (balance < 0) {
      newErrors.balance =
        "Available Balance must be greater than or equal to 0";
    }

    // Validate payment due date
    if (!dueDate) {
      newErrors.dueDate = "Payment Due Date is required";
    }

    // Validate amount last paid: only whole numbers and must be >= 0
    if (!amtLastPaid || !/^\d+(\.00)?$/.test(amtLastPaid)) {
      newErrors.amtLastPaid =
        "Valid Amount Last Paid is required (whole numbers only)";
    } else if (amtLastPaid < 0) {
      newErrors.amtLastPaid =
        "Amount Last Paid must be greater than or equal to 0";
    }

    // Validate amount payable: only whole numbers and must be >= 0
    if (!amtPayable || !/^\d+(\.00)?$/.test(amtPayable)) {
      newErrors.amtPayable =
        "Valid Amount Payable is required (whole numbers only)";
    } else if (amtPayable < 0) {
      newErrors.amtPayable =
        "Amount Payable must be greater than or equal to 0";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if there are no errors
  };

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Credit Card Details updated");
    }
  }, [patchData]);

  const [liabilitiesRatio, setLiabilitiesRatio] = useState("");
  const [totalLiabilitiesValue, setTotalLiabilitiesValue] = useState("");

  useEffect(() => {
    if (getCreditData) {
      const data = getCreditData?.data;
      setLiabilitiesRatio(data?.liabilities_ratio);
      setTotalLiabilitiesValue(data?.total_liabilities_value);
      setcardTitle(data.card_type);
      setcardIssuer(data.card_issuer);
      setissueDate(data.issue_date);
      setcreditLimit(data.credit_limit);
      setamtUtilised(data.amount_utilised);
      setbalance(data.available_balance);
      setdueDate(data.payment_due_date);
      setamtLastPaid(data.amount_last_paid);
      setamtPayable(data.amount_payable);
    }
  }, [getCreditData]);

  const handleUpdateDetails = () => {
    const encodedID = getCreditData?.data?.id;
    if (validateInputs()) {
      const updatedData = {
        encoded_credit_card_id: encodedID,
        card_type: cardTitle,
        card_issuer: cardIssuer,
        issue_date: issueDate,
        credit_limit: creditLimit,
        amount_utilised: amtUtilised,
        available_balance: balance,
        payment_due_date: dueDate,
        amount_last_paid: amtLastPaid,
        amount_payable: amtPayable,
      };
      setisEditClicked(false);
      patchCreditDetails(updatedData, loan_type_id);
    }
  };

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100">
      <LiabilitiesHeading
        loan_type_details={getCreditData?.data?.card_type}
        loan_type={loan_type}
        loan_type_id={loan_type_id}
      />

      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-customPurple font-[500] text-[1.8rem]">
            ₹{balance} Lakhs
          </h4>

          <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            Total Loan Amount
          </h4>
        </div>

        <div className="p-[1rem] col-span-4">
          <h4 className="text-customPurple font-[500] text-[1.8rem]">
            {liabilitiesRatio} %
          </h4>

          <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            of Total Liabilities (₹{totalLiabilitiesValue})
          </h4>
        </div>

        <div className="flex justify-end items-center p-[1rem] col-span-5">
          <AddYourLiability />
        </div>
      </div>

      {/* Loan Details */}
      <div className="py-[1rem] px-[1.5rem] mt-[1rem] border rounded-xl bg-white">
        <div className="flex justify-between ">
          <h4 className="font-[600] text-[1rem] text-darkGray">Loan Details</h4>
          <div className="flex gap-[1rem]">
            <RemoveLiability
              remove_id={id}
              loan_name={getCreditData?.data?.card_type}
            />
            <button className="border-1 border-customPurple text-customPurple px-[0.8rem] py-[0.1rem] bg-[#F3EFF8] rounded-md text-[0.9rem] font-[500]">
              Link to Mandate
            </button>
            {isEditClicked ? (
              <Button
                onClick={handleUpdateDetails}
                px={"px-[0.9rem]"}
                py={"py-[0.3rem]"}
                name="Save"
              />
            ) : (
              <WhiteButton
                onClick={() => setisEditClicked(true)}
                px={"px-[0.9rem]"}
                py={"py-[0.3rem]"}
                name="Edit"
              />
            )}{" "}
          </div>
        </div>

        {/* form */}
        {isEditClicked ? (
          <>
            <div className="grid grid-cols-4">
              <div>
                <label className="input-label">Card Title</label>
                <InputField
                  className="input-box"
                  value={cardTitle}
                  onChange={(e) => setcardTitle(e.target.value)}
                />
                <br></br>
                {errors.cardTitle && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.cardTitle}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Card Issuer</label>
                <InputField
                  className="input-box"
                  value={cardIssuer}
                  onChange={(e) => setcardIssuer(e.target.value)}
                />
                <br></br>
                {errors.cardIssuer && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.cardIssuer}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Issue Date</label>
                <InputField
                  type="date"
                  className="input-box"
                  value={issueDate}
                  onChange={(e) => setissueDate(e.target.value)}
                />
                <br></br>
                {errors.issueDate && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.issueDate}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 my-[1.5rem] gap-2">
              <div>
                <label className="input-label">Credit Limit</label>
                <InputField
                  className="input-box"
                  type="number"
                  value={creditLimit}
                  onChange={(e) => setcreditLimit(e.target.value)}
                />
                <br></br>
                {errors.creditLimit && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.creditLimit}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Amount Utilised</label>
                <InputField
                  type="number"
                  className="input-box"
                  value={amtUtilised}
                  onChange={(e) => setamtUtilised(e.target.value)}
                />
                <br></br>
                {errors.amtUtilised && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.amtUtilised}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Balance Amount
                </label>
                <InputField
                  type="number"
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={balance}
                  onChange={(e) => setbalance(e.target.value)}
                />
                <br></br>
                {errors.balance && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.balance}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 ">
              <div>
                <label className="input-label">Due Date</label>
                <InputField
                  type="date"
                  className="input-box"
                  value={dueDate}
                  onChange={(e) => setdueDate(e.target.value)}
                />
                <br></br>
                {errors.dueDate && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.dueDate}
                  </span>
                )}
              </div>
              <div>
                <label className="input-label">Amount last Paid</label>
                <InputField
                  type="number"
                  className="input-box"
                  value={amtLastPaid}
                  onChange={(e) => setamtLastPaid(e.target.value)}
                />
                <br></br>
                {errors.amtLastPaid && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.amtLastPaid}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Amount Payable
                </label>
                <InputField
                  type="number"
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={amtPayable}
                  onChange={(e) => setamtPayable(e.target.value)}
                />
                <br></br>
                {errors.amtPayable && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.amtPayable}
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-4">
              <div>
                <label className="input-label">Card Title</label>
                <InputField className="input-box" value={cardTitle} readOnly />
              </div>

              <div>
                <label className="input-label">Card Issuer</label>
                <InputField className="input-box" value={cardIssuer} readOnly />
              </div>

              <div>
                <label className="input-label">Issue Date</label>
                <InputField className="input-box" value={issueDate} readOnly />
              </div>
            </div>

            <div className="grid grid-cols-4 my-[1.5rem]">
              <div>
                <label className="input-label">Credit Limit</label>
                <InputField
                  className="input-box"
                  value={creditLimit}
                  readOnly
                />
              </div>

              <div>
                <label className="input-label">Amount Utilised</label>
                <InputField
                  className="input-box"
                  value={amtUtilised}
                  readOnly
                />
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Balance Amount
                </label>
                <InputField
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={balance}
                  readOnly
                />
              </div>
            </div>

            <div className="grid grid-cols-4 ">
              <div>
                <label className="input-label">Due Date</label>
                <InputField className="input-box" value={dueDate} readOnly />
              </div>
              <div>
                <label className="input-label">Amount last Paid</label>
                <InputField
                  className="input-box"
                  value={amtLastPaid}
                  readOnly
                />
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Amount Payable
                </label>
                <InputField
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={amtPayable}
                  readOnly
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RevolvingLoanDetailed;
