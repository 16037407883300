import React from "react";
import Button from "components/Button/BtnOrange";

function BlogsTitle() {
  return (
    <>
      <div className="h-2/5 lg:pt-[12%] px-3	pb-[8%] md:pt-[12%] pt-[35%]">
        <p className=" text-center">
          <b className=" text-purple-800">Our blog</b>
        </p> 
        <h1 className="lg:hidden md:hidden font-[700] text-[2.3rem] leading-[3rem] text-center ">Latest news and updates</h1>
        <p className="font-[500] text-[1rem] leading-[1.5rem] text-gray-500 text-center">
          Subscribe to learn about new product features, the latest in tax,
          auditing and compliance
        </p>
        <div className="lg:flex lg:justify-center  lg:w-screen lg:gap-2 md:flex md:justify-center  md:w-screen md:gap-2 ">
          <div className="flex flex-col">
       
          <input placeholder="Enter your email" id="emailLabel" className="p-[4%] border-1 rounded-lg " />
          <label for='emailLabel'> We care about your data in our privacy policy</label></div>
          <Button name={"Subscribe"} px={'px-[1.3rem]'}
            py={'py-[0.8rem]'}  />
          {/* <button className="Btn">Subscribe</button> */}
    
        </div>
        
      </div>
    </>
  );
}

export default BlogsTitle;
