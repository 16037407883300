import { GET_PROFILE_NAV_TABS } from "constants/apis";
import useAPICall from "hooks/useAPICall";

const useGetProfileNavTabs = () => {
  const [data, error, isLoading, callGetProfileNavTabs, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getProfileNavTabs = () => {
    const url = GET_PROFILE_NAV_TABS;
    var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    callGetProfileNavTabs({
      url,
      method: "GET",
      statusObj,
      config,  
      defaultFallback,
    });
  };

  return [data, error, isLoading, getProfileNavTabs, setSuccessData, setError];
};

export default useGetProfileNavTabs;
