import React, { useEffect, useState } from "react";
import add from "assets/images/taxProfile/add.svg";
import InputField from "components/input/InputField";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

// APIs
import useDeleteStakeHolder from "./data/useDeleteStakeholder";
import usePatchStakeHolder from "./data/usePatchStakeHolder";
import usePostStakeHolder from "./data/usePostStakeholder";
import useGetStakeholder from "./data/useGetStakeholder";
import useGetTabs from "./data/useGetTabs";
import useGetEmailSuggestions from "./data/useGetEmailSuggestions";


const SampleStakeholder = () => {
  const [getStakeData, getError, getIsLoading, getStakeholder] =
    useGetStakeholder();
    const [getEmailSuggestionData, getEmailSuggestionError, getEmailSuggestionIsLoading, getEmailSuggestion] =
    useGetEmailSuggestions();
  const [getTabsData, getTabsError, getTabsIsLoading, getTabs] = useGetTabs();
  const [deleteData, deleteError, deleteIsLoading, deleteStakeHolder] =
    useDeleteStakeHolder();
  const [patchData, patchError, patchIsLoading, patchStakeHolder] =
    usePatchStakeHolder();
  const [postStakeData, postStakeError, postStakeIsLoading, postStakeHolder] =
    usePostStakeHolder();

  const [errors, setErrors] = useState({});
  const [emailSuggestionData, setEmailSuggestionData]=useState("")
  const [showDropdown, setShowDropdown]= useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [stakeholder, setStakeholder] = useState([
    {
      email: "",
      tab: "",
      type: "",
      permission: "",
      tab_name: "",
      isNewAccount: true,
      isReadOnly: false,
    },
  ]);



  const handleAddStakeHolder = () => {
    setStakeholder([
      ...stakeholder,
      {
        email: "",
        tab: "",
        type: "",
        permission: "",
        tab_name: "",
        isNewAccount: true,
        isReadOnly: false,
      },
    ]);
  };

  useEffect(() => {
    getStakeholder();
    // getTabs();
  }, []);

  useEffect(()=>{
  if(getEmailSuggestionData){
    setEmailSuggestionData(getEmailSuggestionData)
    console.log("getEmailSuggestionData",getEmailSuggestionData)
  }
  },[getEmailSuggestionData])

  useEffect(() => {
    if (postStakeData) {
      showSuccessToast("Stakeholder Details Added");
      getStakeholder();
    }
  }, [postStakeData]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Stakeholder Details Updated");
    }
  }, [patchData]);

  useEffect(() => {
    if (deleteData) {
      showSuccessToast("Stakeholder Details Deleted");
      getStakeholder();
    }
  }, [deleteData]);

  useEffect(() => {
    if (postStakeError) {
      showErrorToast(postStakeError?.err?.response?.data?.data?.error);
    }
  }, [postStakeError]);



  useEffect(() => {
    if (getStakeData?.data) {
      const getHolder = getStakeData.data.results;
      if (getHolder?.length > 0) {
        const stakeholderDetails = getHolder.map((item) => ({
          id: item.id,
          user_id: item.user_id,
          email: item.access_given_to_email,
          tab: item.tab_name,
          type: item.stakeholder_type,
          permission: item.permission_type,
          isNewAccount: false,
          isReadOnly: true,
        }));
        setStakeholder(stakeholderDetails);
      }
    }
  }, [getStakeData]);

  const handleChange = (index, field, value) => {
    if (field === "type") {
      const alphabetOnly = /^[A-Za-z\s]*$/;
      if (!alphabetOnly.test(value)) {
        return;
      }
    }
    const newAccounts = [...stakeholder];
    newAccounts[index][field] = value;
    setStakeholder(newAccounts);
  };

  const handleEditDetails = (index) => {
    const updatedAccounts = [...stakeholder];
    updatedAccounts[index].isReadOnly = false;
    setStakeholder(updatedAccounts);
  };

  const handleCancel = (index) => {
    const updatedAccounts = [...stakeholder];
    updatedAccounts[index].isReadOnly = true;
    setStakeholder(updatedAccounts);
  };  

  const isStakeholderValid = (stake) => {
    return (
      stake.email !== "" &&
      stake.tab !== "" &&
      stake.type !== "" &&
      stake.permission !== ""
    );
  };

  const tabId={
    Incometax:"_8PGAAAG",
    Assets:"TQ78AAAG",
    Liabilities:"NWX8AAAG"
  }

  const createDetails = (item) => {
    let createData = {
      email: item.email,
      // tab_id: item.tab,
      tab_id: tabId[item.tab],
      stakeholder_type: item.type,
      permission_type: parseInt(item.permission),
    };
    postStakeHolder(createData);
    const updatedAccounts = [...stakeholder];
    updatedAccounts[stakeholder.indexOf(item)].isReadOnly = true;
    setStakeholder(updatedAccounts);
    getStakeholder();
  };

  const handleAddStakeholder = (index) => {
    createDetails(stakeholder[index]);
  };

  const updateStakeHolderDetails = (item) => {
    let id = item.id;
    let updatedData = {
      email: item.email,
      // tab_id: item.tab,
      tab_id: tabId[item.tab],
      stakeholder_type: item.type,
      permission_type: parseInt(item.permission),
    };
    patchStakeHolder(id, updatedData);
    const updatedAccounts = [...stakeholder];
    updatedAccounts[stakeholder.indexOf(item)].isReadOnly = true;
    setStakeholder(updatedAccounts);
  };

  const handleApplyChanges = (index) => {
    updateStakeHolderDetails(stakeholder[index]);
  };

  const remove = (item) => {
    let id = item.id;
    deleteStakeHolder(id);
  };
  const handleRemoveAccount = (index) => {
    remove(stakeholder[index]);
  };

  const handleEmailInputChange = (index,type, value) => {
    const updatedStakeholder = [...stakeholder];
    updatedStakeholder[index][type] = value;
    setStakeholder(updatedStakeholder);
  
    // Debounce logic
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
  
    const newTimeout = setTimeout(() => {
      if (value.trim()) {
        getEmailSuggestion(`?query=${value}`);
      }
    }, 500); // 300ms delay
  
    setDebounceTimeout(newTimeout);
  };

  const arrayTest=[
    "bt1997vng@gmail.com",
    "bt1997vng@gmail.com",
    "bt1997vng@gmail.com"
  ]

  return (
    <div className="md:bg-gray-100 h-screen md:mb-0 ">
      <div className="px-3 md:py-[5.5rem]">
        <div className="md:border border-[#E2E8F0] rounded-xl mt-[1rem]">
          <div className="bg-[#FAF8FC] px-3 py-3 m-0 rounded-xl">
            <h4 className="text-[#334155] text-[1.3rem] font-[500] ">
              My Stakeholders
            </h4>
            <h6 className="text-[#334155] text-[0.9rem] font-[500] m-0">
              Manage Stakeholders who can access your profile
            </h6>
          </div>

          {/* input fields */}
          <div className="md:bg-white px-3 py-3 rounded">
            {stakeholder.map((stake, index) => (
              <>
                <div className="flex" key={index}>
                  <div className="input-container ">
                    <h6 className="input-label ">Email</h6>

                    <input
                      type="email"
                      className={`input-box bg-[#F8FAFC] text-[0.9rem] ${
                        stake.isReadOnly ? "border-none" : ""
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.email}
                      // onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                      // onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Hide after a slight delay to allow click
                      onChange={(e) =>
                        handleEmailInputChange (index, "email",e.target.value)
                      }
                    />
                  {/* {arrayTest && showDropdown && (
                    <ul className="absolute z-10 mt-2 pl-0 p-1  bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto">
                      {arrayTest.map((email, idx) => (
                        <li
                          key={idx}
                          className="py-2 hover:bg-blue-100 cursor-pointer text-[0.9rem]"
                          // onClick={() => handleEmailSuggestionClick(index, email)}
                        >
                          {email}
                        </li>
                      ))}
                    </ul>
                  )} */}
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Tab</h6>
                    <select
                      className={`input-box bg-[#F8FAFC]  ${
                        stake.isReadOnly
                          ? "border-none text-[0.9rem]"
                          : "text-[1rem]"
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.tab}
                      onChange={(e) =>
                        handleChange(index, "tab", e.target.value)
                      }
                    >
                      {/* {console.log("tab:", stake.tab)} */}
                      <option value="">Choose Tab</option>
                      <option value="Incometax">Incometax</option>
                      <option value="Assets">Assets</option>
                      <option value="Liabilities">Liabilities</option>
                      {/* value="_8PGAAAG" */}

                      {/* {getTabsData?.data?.results?.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          className="text-[0.9rem]"
                        >
                          {item.tab_name}
                        </option>
                      ))} */}
                    </select>
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Stakeholder Type </h6>

                    <select
                      className={`input-box bg-[#F8FAFC] text-[0.9rem] ${
                        stake.isReadOnly ? "border-none" : ""
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.type}
                      onChange={(e) =>
                        handleChange(index, "type", e.target.value)
                      }
                    >
                      <option value="">Choose Stakeholder type</option>
                      <option value="Chartered Accountant">Chartered Accountant</option>
                      <option value="Portfolio Manager">Portfolio Manager</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Permission Type</h6>
                    <select
                      className={`input-box bg-[#F8FAFC]  ${
                        stake.isReadOnly
                          ? "border-none text-[0.9rem]"
                          : "text-[1rem]"
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.permission}
                      onChange={(e) =>
                        handleChange(index, "permission", e.target.value)
                      }
                    >
                      <option value="">Choose Permission type</option>
                      <option value={1}>Read</option>
                      <option value={3}>Edit </option>
                      <option value={2}>Download</option>
                      <option value={4}>Read & Download</option>
                    </select>
                  </div>
                  <div className="">
                    {stake.isNewAccount ? (
                      <>
                        <button
                          disabled={!isStakeholderValid(stake)}
                          onClick={() => handleAddStakeholder(index)}
                          className="mb-1 bg-[#9065B4] w-[60%] text-white rounded py-2 px-3 font-[600] text-[0.8rem]"
                        >
                          Add Account
                        </button>
                        <button className=" bg-[#F1F5F9] w-[60%] text-[#334155] rounded py-2 px-6 font-[600] text-[0.8rem]">
                          Cancel
                        </button>
                      </>
                    ) : stake.isReadOnly ? (
                      <>
                        <button className="w-[60%] text-[#334155] rounded py-2 px-2 font-[600] text-[0.8rem]"></button>
                        <button
                          onClick={() => handleEditDetails(index)}
                          className=" bg-[#F1F5F9] text-[#334155] rounded py-2 px-3 font-[600] text-[0.8rem]"
                        >
                          Edit Details
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleApplyChanges(index)}
                          className=" mb-1 w-[60%] bg-[#9065B4] text-white rounded p-2 font-[600] text-[0.8rem]"
                        >
                          Apply Changes
                        </button>

                        <button
                          onClick={() => handleRemoveAccount(index)}
                          className="mb-1 w-[60%] bg-[#FEF2F2] text-[#DC2626] rounded p-2  font-[600] text-[0.8rem]"
                        >
                          Delete Account
                        </button>
                        <button
                          onClick={() => handleCancel(index)}
                          className=" bg-[#F1F5F9]  w-[60%] p-2 text-[#334155] rounded font-[600] text-[0.8rem]"
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <hr className="" />
              </>
            ))}

            <div className="flex justify-center items-center rounded py-3 border-dashed border-2 border-[#E2E8F0]">
              <button
                onClick={handleAddStakeHolder}
                className="flex items-center gap-2 text-[#334155] font-[600]"
              >
                <img src={add} alt="add account icon" />
                Add New Stakeholder
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleStakeholder;
