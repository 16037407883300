import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";
import usePatchGoldDetails from "../../data/usePatchGoldDetails";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const EditGold = ({ editValue, detailData, setIsReload }) => {
  const [
    patchGoldData,
    patchGoldError,
    patchGoldIsLoading,
    patchGoldDetails,
  ] = usePatchGoldDetails();
  const removeBtnRef = useRef(null);
  const [name, setName] = useState("");
  const [isClose, setIsClose]= useState(false)
  const [errors, setErrors]=useState([]);
  const [goldForm, setGoldForm]=useState({
    type_of_metal: "",
    item_name: "",
    purity: "",
    weight: "",
    purchase_date: "",
    purchase_price: "",
    estimated_sale_value: "",
    loan_taken: false,
    invoice: null
  })

  useEffect(()=>{
    if(detailData){
       const {total_active_assets, total_assets, total_assets_amount, total_idle_assets, user, ...rest}=detailData;
       setGoldForm(rest)
    }
  },[detailData])

  useEffect(()=>{
    if(patchGoldData){
      showSuccessToast("Data updated successfully")
      setIsReload(true)
      setIsClose(true)
    }
    if(patchGoldError){
      showErrorToast("Error in Updating")
    }
  },[patchGoldData, patchGoldError])

  const handleInputChange=(e)=>{
    let { name, value, type, files } = e.target;
     if(type==="radio"){
        const bool= value==="true"?true: value==="false"?false:null;
        value=bool
     }
    setGoldForm((prevForm) => ({
      ...prevForm,
      [name]: type === 'file' ? files[0] : value
    }));
  }

  const validateForm = () => {
    const newErrors = {};

    if(editValue==="goldValue"){

    }else if(editValue==="goldDetails"){
        // Type of Metal: Required field
        if (!goldForm.type_of_metal) {
          newErrors.type_of_metal = "Type of metal is required.";
        }
      
        // Item Name: Required field, only letters and spaces allowed
        const namePattern = /^[a-zA-Z\s]+$/;
        if (!goldForm.item_name) {
          newErrors.item_name = "Item name is required.";
        } else if (!namePattern.test(goldForm.item_name)) {
          newErrors.item_name = "Item name should only contain letters and spaces.";
        }
      
        // Purity: Required field, must be a valid format (e.g., 24K, 22K)
        const purityPattern = /^\d{1,2}K$/;
        if (!goldForm.purity) {
          newErrors.purity = "Purity is required.";
        } else if (!purityPattern.test(goldForm.purity)) {
          newErrors.purity = "Purity should be in a valid format (e.g., 24K).";
        }
      
        // // Weight: Required, numeric, and positive
        // if (!goldForm.weight || isNaN(goldForm.weight)) {
        //   newErrors.weight = "Weight is required and should be a number.";
        // } else if (goldForm.weight <= 0) {
        //   newErrors.weight = "Weight must be greater than 0.";
        // }
    }else if(editValue==="goldPurchaseDetails"){
        // Purchase Date: Required, should be a valid date
        // if (!goldForm.purchase_date) {
        //   newErrors.purchase_date = "Purchase date is required.";
        // } else if (isNaN(new Date(goldForm.purchase_date).getTime())) {
        //   newErrors.purchase_date = "Purchase date should be a valid date.";
        // }
      
        // // Purchase Price: Required, numeric, and positive
        // if (!goldForm.purchase_price || isNaN(goldForm.purchase_price)) {
        //   newErrors.purchase_price = "Purchase price is required and should be a number.";
        // } else if (goldForm.purchase_price <= 0) {
        //   newErrors.purchase_price = "Purchase price must be greater than 0.";
        // }
      
        // // Estimated Sale Value: Required, numeric, and positive
        // if (!goldForm.estimated_sale_value || isNaN(goldForm.estimated_sale_value)) {
        //   newErrors.estimated_sale_value = "Estimated sale value is required and should be a number.";
        // } else if (goldForm.estimated_sale_value <= 0) {
        //   newErrors.estimated_sale_value = "Estimated sale value must be greater than 0.";
        // }
      
        // if(typeof(goldForm.invoice) === "string"){

        // }else{
        //  // Invoice File: Required, must be a valid file format (e.g., PDF)
        // const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
        // if (!filePattern.test(goldForm.invoice.name)) {
        //     newErrors.invoice = "Invoice should be a valid file format (PDF, JPG, or PNG).";
        //   }
        // }
    }  
  // Set error state and check if no errors remain
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
  
    const formData = new FormData();
  
    // Helper function to check if a value is not empty
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Always append the `id`
    if (isNotEmpty(goldForm.id)) {
      formData.append("id", goldForm.id);
    }
  
    if (editValue === "goldValue") {
      // No specific handling required here
    } else if (editValue === "goldDetails") {
      if (isNotEmpty(goldForm.type_of_metal)) {
        formData.append("type_of_metal", goldForm.type_of_metal);
      }
      if (isNotEmpty(goldForm.item_name)) {
        formData.append("item_name", goldForm.item_name);
      }
      if (isNotEmpty(goldForm.purity)) {
        formData.append("purity", goldForm.purity);
      }
      if (isNotEmpty(goldForm.weight)) {
        formData.append("weight", goldForm.weight);
      }
    } else if (editValue === "goldPurchaseDetails") {
      if (isNotEmpty(goldForm.purchase_date)) {
        formData.append("purchase_date", goldForm.purchase_date);
      }
      if (isNotEmpty(goldForm.purchase_price)) {
        formData.append("purchase_price", goldForm.purchase_price);
      }
      if (isNotEmpty(goldForm.estimated_sale_value)) {
        formData.append("estimated_sale_value", goldForm.estimated_sale_value);
      }
      if (isNotEmpty(goldForm.loan_taken)) {
        formData.append("loan_taken", goldForm.loan_taken);
      }
  
      // Append file only if it is not a string and exists
      if (typeof goldForm.invoice !== "string" && isNotEmpty(goldForm.invoice)) {
        formData.append("invoice", goldForm.invoice);
      }
    }
  
    // Debugging: Print FormData entries
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    console.log("form in sub", goldForm);
  
    // Send FormData to the API
    patchGoldDetails(formData, goldForm.asset);
  };
  

  useEffect(() => {
    if (editValue) {
      switch (editValue) {
        case "goldValue":
          setName("Edit Gold Vlaue");
          break;
        case "goldDetails":
          setName("Edit Item Details");
          break;
        case "goldPurchaseDetails":
          setName("Edit Purchase Details");
          break;
        default:
          break;
      }
    }
  }, [editValue]);

  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };

  const getContent = () => {
    switch (editValue) {
      case "goldValue":
        return (
          <>
            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] mb-3`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Gold from the Assets?
              </h4>
              <h4 className="text-[0.7rem] font-[500] text-lightGray">
                This action is permanent and may affect your financial records.
              </h4>
              <div className="w-[20%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove`}
                  px={"px-[0.4rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        );
      case "goldDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Type of Metal
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Gold", label: "Gold" },
                      { value: "Silver", label: "Silver" },
                      { value: "Platinum", label: "Platinum" },
                      { value: "Other", label: "Other" },
                    ]}
                    name="type_of_metal"
                    value={goldForm.type_of_metal}
                    onChange={handleInputChange}
                  />
                  {errors.type_of_metal && <p className="text-red-500 text-[0.75rem]">{errors.type_of_metal}</p>}
                </div>
                <div className="input-container col-span-6 w-[100%] flex flex-col gap-1.5 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Item Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={goldForm.item_name}
                    onChange={handleInputChange}
                    name="item_name"
                  />
                  {errors.item_name && <p className="text-red-500 text-[0.75rem]">{errors.item_name}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purity
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={goldForm.purity}
                    onChange={handleInputChange}
                    name="purity"
                  />
                  {errors.purity && <p className="text-red-500 text-[0.75rem]">{errors.purity}</p>}
                </div>

                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Weight
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[30%] p-[0.40rem] group-focus-within:text-customPurple text-[0.8rem]">
                      Grams
                    </span>
                    <InputField
                      type="text"
                      name="weight"
                      value={goldForm.weight}
                      onChange={handleInputChange}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors.weight && <p className="text-red-500 text-[0.75rem]">{errors.weight}</p>}
                </div>
              </div>
            </div>
          </>
        );
      case "goldPurchaseDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-12 gap-3 mb-3 mt-1.5">
                <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Date
                  </label>
                  <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={goldForm.purchase_date}
                      name="purchase_date"
                      onChange={handleInputChange}
                    />
                    {errors.purchase_date && <p className="text-red-500 text-[0.75rem]">{errors.purchase_date}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mb-3">
                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Price
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="purchase_price"
                          value={goldForm.purchase_price}
                          onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.purchase_price && <p className="text-red-500 text-[0.75rem]">{errors.purchase_price}</p>}
                </div>

                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Sale Value
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="estimated_sale_value"
                        value={goldForm.estimated_sale_value}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.estimated_sale_value && <p className="text-red-500 text-[0.75rem]">{errors.estimated_sale_value}</p>}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div className="input-container">
                  <label className="input-label">Taken any loan?</label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        name="loan_taken"
                        value={true}
                        onChange={handleInputChange}
                        checked={goldForm.loan_taken===true}
                        className="mr-2 custom-radio"
                      />
                      Yes
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                      type="radio"
                      name="loan_taken"
                      value={false}
                      onChange={handleInputChange}
                      checked={goldForm.loan_taken===false}
                      className="mr-2 custom-radio"
                    />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mb-3">
                <div className="col-span-4">
                  <WhiteButton
                    name="Upload Invoice"
                    px={"px-[0.6rem]"}
                    py={"py-[0.52rem]"}
                    text={"text-[0.9rem]"}
                    mt={"mt-[1.2rem]"}
                    border={"!border-[1px] !border-[#94A3B8]"}
                    onClick={()=>document.getElementById("file-upload").click()}
                  />
                   {errors.invoice && <p className="text-red-500 text-[0.75rem]">{errors.invoice}</p>}
                </div>    
                <InputField 
                  id={`file-upload`}
                  type="file"
                  onChange={handleInputChange}
                  className="hidden"
                  name="invoice"
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" onClick={()=>setIsClose(false)} />
        }
        heading={name}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} onClick={handleSubmit} />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
      <RemoveAsset
        heading="Gold"
        assetId={goldForm.asset}
        deleteId={`${goldForm.asset}/?id=${goldForm.id}&model_type=gold_and_jewellery`}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditGold;
