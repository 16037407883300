import DialogBox from "components/DialogBox/DialogBox";
import React, { useRef, useState } from "react";
import UnsecuredLoansLoanLender from "./UnsecuredLoans/UnsecuredLoansLoanLender";
import FamilyAndInformalLoanLenders from "./FamilyAndInformalLoan/FamilyAndInformalLoanLenders";
import CreditCard from "./RevolvingCredit/CreditCard";

// images
import link from "assets/images/liabilities/link.svg";
import download from "assets/images/liabilities/download.svg";
import upload from "assets/images/liabilities/upload.svg";
import manually from "assets/images/liabilities/manually.svg";
import AddYourLiability from "./AddYourLiability";
const AddLoanTypeDialog = ({ loanType, triggerElement, loanTypeId }) => {
  const enterManuallyBtnRef = useRef(null);
  const enterManuallyFamilyBtnRef = useRef(null);
  const enterManuallyBtnRefCreditCard = useRef(null);
  const [isClose, setIsClose] = useState(false);
  // const backBtnRef = useRef(null);

  // const handleOpenLiability = (handleClose) => {
  //   handleClose();
  //   backBtnRef.current.click();
  // };

  const handleEnterManually = (handleClose) => {
    handleClose();
    setIsClose(false);
    // Loan types that should trigger enterManuallyBtnRef
    const manuallyLoanTypes = [
      "car loan",
      "loan against property",
      "gold loan",
      "home loan",
      "personal loan",
      "business loan",
      "education loan",
      "medical loan",
      "emergency personal loan",
    ];

    // Loan types that should trigger enterManuallyFamilyBtnRef
    const manuallyFamilyLoanTypes = [
      "family loan",
      "loan from friends",
      "loan for wedding expense",
    ];

    const manuallyRevolvingCreditLoanTypes = ["credit card debt"];

    // Check which button to click based on loanType
    if (manuallyLoanTypes.includes(loanType.toLowerCase())) {
      enterManuallyBtnRef.current.click();
    } else if (manuallyFamilyLoanTypes.includes(loanType.toLowerCase())) {
      enterManuallyFamilyBtnRef.current.click();
    } else if (
      manuallyRevolvingCreditLoanTypes.includes(loanType.toLowerCase())
    ) {
      enterManuallyBtnRefCreditCard.current.click();
    }
  };

  return (
    <div>
      <DialogBox
        triggerElement={triggerElement}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">{loanType}</span> to your
            Liabilities
          </h4>
        }
        content={(handleClose) => (
          <div className="min-h-[440px] ">
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Auto Import Details or Enter Manually
            </h4>
            <div className="border rounded-lg bg-[#F1F5F9] p-[1rem] mt-[1rem]">
              <h5 className="text-[#334155] font-[600] text-[0.9rem] ">
                Link Loan or Import Data
              </h5>
              <h5 className="text-[#9065B4] font-[500] text-[0.78rem] ml-[0.3rem]">
                1. Link Loan Based Bank Accounts to Import Details automatically
              </h5>
              <h5 className="text-[#9065B4] font-[500] text-[0.78rem] ml-[0.3rem]">
                2. Import Data from Credit Reports
              </h5>
              {loanType == "Credit Card Debt" ? (
                <h5 className="text-[#9065B4] font-[500] text-[0.78rem] ml-[0.3rem]">
                  3. Upload Statement to Import Details
                </h5>
              ) : (
                ""
              )}

              <div className="flex justify-between my-[1rem]">
                <button className="text-[#334155] bg-white font-[600] text-[0.9rem] border-1 border-[#64748B] py-1 px-[1.7rem] rounded-md flex gap-2 items-center">
                  <img src={link} alt="link" />
                  Link Accounts
                </button>
                <button className="text-[#334155] bg-white font-[600] text-[0.9rem] border-1 border-[#64748B] py-1 px-2 rounded-md flex gap-2 items-center">
                  <img src={download} alt="download" />
                  Import from Credit Report
                </button>
              </div>
              {loanType == "Credit Card Debt" ? (
                <div className="flex  justify-center">
                  <button className="text-[#334155] bg-white font-[600] text-[0.9rem] border-1 border-[#64748B] py-1 px-[1.8rem] rounded-md flex gap-2 items-center">
                    <img src={upload} alt="upload" />
                    Upload Statement
                  </button>
                </div>
              ) : (
                ""
              )}

              <h5 className="text-[#334155] font-[600] text-[0.9rem] text-center my-[1rem]">
                OR
              </h5>
              <div className="flex  justify-center">
                <button
                  onClick={() => handleEnterManually(handleClose)}
                  className="text-[#334155] bg-white font-[600] text-[0.9rem] border-1 border-[#64748B] py-1 px-[2.5rem] rounded-md flex gap-2 items-center"
                >
                  <img src={manually} alt="manually" />
                  Enter Manually
                </button>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                // onClick={() => handleOpenLiability(handleClose)}
                onClick={handleClose}
                className="mt-[8rem] text-[#64748B] bg-white font-[600] text-[0.9rem] border py-[0.4rem] px-[0.8rem] rounded-md"
              >
                Back
              </button>
            </div>
          </div>
        )}
      />

      {/* <AddYourLiability 
      triggerElement={
        <button className="hidden" ref={backBtnRef}>
          Enter Manually
        </button>
      }
      /> */}

      <UnsecuredLoansLoanLender
        setIsClose={setIsClose}
        isClose={isClose}
        loanType={loanType}
        loanTypeId={loanTypeId}
        triggerElement={
          <button className="hidden" ref={enterManuallyBtnRef}>
            Enter Manually
          </button>
        }
      />

      <FamilyAndInformalLoanLenders
        setIsClose={setIsClose}
        isClose={isClose}
        loanType={loanType}
        loanTypeId={loanTypeId}
        triggerElement={
          <button className="hidden" ref={enterManuallyFamilyBtnRef}>
            Enter Manually
          </button>
        }
      />
      <CreditCard
        setIsClose={setIsClose}
        isClose={isClose}
        loanType={loanType}
        loanTypeId={loanTypeId}
        triggerElement={
          <button className="hidden" ref={enterManuallyBtnRefCreditCard}>
            Enter Manually
          </button>
        }
      />
    </div>
  );
};

export default AddLoanTypeDialog;
