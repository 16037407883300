import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useState, useEffect } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import upload from "assets/images/liabilities/upload.svg";

const AddFurniture = ({
  postData,
  furExpenseType,
  furExpenseDate,
  furAmtPaid,
  furUploadInvoice,
  setfurExpenseType,
  setfurExpenseDate,
  setfurAmtPaid,
  setfurUploadInvoice,
  updateFurnitureData,
  triggerElement,
  selectedFurItem,
  setSelectedFurItem
}) => {
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(true);
  const [touched, setTouched] = useState(false); // Track whether user has tried to save

  const resetState = () => {
    setfurExpenseType("");
    setfurExpenseDate("");
    setfurAmtPaid("");
    setfurUploadInvoice("");
    setErrors({});
    setTouched(false);
    setFormValid(true);
  }

  useEffect(() => {
    if (!touched) return; 
    const newErrors = {};

    const alphabetRegex = /^[A-Za-z\s]+$/; 
    const positiveAmountRegex = /^[+]?\d+(\.\d+)?$/; 
    // if (!furExpenseType || !alphabetRegex.test(furExpenseType)) {
    //   newErrors.furExpenseType = "Expense Type can have only alphabets.";
    // }
    if (!furExpenseType) {
      newErrors.furExpenseType = "Expense Type is required.";
    }
    if (
      !furAmtPaid ||
      !positiveAmountRegex.test(furAmtPaid) ||
      parseFloat(furAmtPaid) <= 0
    ) {
      newErrors.furAmtPaid = "Amount Paid must be greater than 0.";
    }
    if (!furExpenseDate) {
      newErrors.furExpenseDate = "Date is required.";
    }
    if (!furUploadInvoice) {
      newErrors.furUploadInvoice = "Invoice is required.";
    }

    setErrors(newErrors);
    setFormValid(Object.keys(newErrors).length === 0); 
  }, [furExpenseType, furAmtPaid, touched, furUploadInvoice, furExpenseDate]); 

  const handleSaveClick = () => {
    setTouched(true); 
    if (formValid) {
      postData(); 
    }
  };

  return (
    <div>
      <DialogBox
      onClose={resetState}
        triggerElement={
          triggerElement ? (
            triggerElement
          ) : (
            <button className="mb-[1rem] text-darkGray font-[500] text-[0.9rem] py-[0.4rem] px-[0.8rem] bg-[#F1F5F9] rounded-md">
              + Furniture & Fixtures
            </button>
          )
        }
        heading={"Add Furniture & Fixture"}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.6rem] mb-[14rem]">
              <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Expense Type</label>
                  <InputField
                    value={
                      triggerElement
                        ? selectedFurItem?.expense_type
                        : furExpenseType
                    }
                    onChange={(e) => triggerElement ? setSelectedFurItem({ ...selectedFurItem, expense_type: e.target.value }) : setfurExpenseType(e.target.value)}
                    type="select"
                    className="input-box border rounded-md bg-white"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "Security", label: "Security" },
                      { value: "Signage", label: "Signage" },
                      { value: "Mailboxes", label: "Mailboxes" },
                      { value: "Irrigation", label: "Irrigation" },
                      { value: "Other", label: "Other" },
                    ]}
                  />
                  {touched && errors.furExpenseType && (
                    <p className="text-red-500 text-xs">
                      {errors.furExpenseType}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Amount Paid</label>
                  <InputField
                    value={
                      triggerElement ? selectedFurItem?.amount_paid : furAmtPaid
                    }
                    onChange={(e) => triggerElement ? setSelectedFurItem({ ...selectedFurItem, amount_paid: e.target.value }) : setfurAmtPaid(e.target.value)}

                    // onChange={(e) => setfurAmtPaid(e.target.value)}
                    type="number"
                    placeholder="Enter Amount Paid"
                    className="input-box border rounded-md bg-white"
                  />
                  {touched && errors.furAmtPaid && (
                    <p className="text-red-500 text-xs">{errors.furAmtPaid}</p>
                  )}
                </div>

                <div className="input-container mt-[0.3rem]">
                  <label className="input-label">Expense Date</label>
                  <InputField
                    value={
                      triggerElement
                        ? selectedFurItem?.expense_date
                        : furExpenseDate
                    }
                    onChange={(e) => triggerElement ? setSelectedFurItem({ ...selectedFurItem, expense_date: e.target.value }) : setfurExpenseDate(e.target.value)}
                    // onChange={(e) => setfurExpenseDate(e.target.value)}
                    type="date"
                    className="input-box border rounded-md bg-white"
                  />
                  {touched && errors.furExpenseDate && (
                    <p className="text-red-500 text-xs">{errors.furExpenseDate}</p>
                  )}
                </div>

                <div className="mt-[1.7rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-invoice`}
                    className="hidden"
                    onChange={(e) => triggerElement ? setSelectedFurItem({ ...selectedFurItem, upload_invoice: e.target.files[0]}) : setfurUploadInvoice(e.target.files[0])}
                    
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input-invoice`).click()
                    }
                    className="text-lightGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.2rem] px-[0.7rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Invoice
                  </button>
                  {touched && errors.furUploadInvoice && (
                    <p className="text-red-500 text-xs">{errors.furUploadInvoice}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-[1rem]">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={triggerElement ? updateFurnitureData : handleSaveClick} // Use handleSaveClick to trigger validation
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                disabled={!formValid} // Disable Save button if form is invalid
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default AddFurniture;
