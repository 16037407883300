import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const CreditScoreChart = () => {
  const data = {
    datasets: [
      {
        data: [63, 37], // 37% Credit Score, Remaining
        backgroundColor: ["#F0F9FF", "#38BDF9"], // Blue and light gray
        borderWidth: 0, // No border
      },
    ],
  };

  const options = {
    cutout: "50%", // Inner radius of the doughnut
    plugins: {
      tooltip: { enabled: false }, // Disable tooltip
    },
  };

  return (
    <div className="relative flex justify-center items-center">
      <Doughnut
        data={data}
        options={options}
        // className="w-[160px] h-[160px]"
        style={{ width: "180px", height: "180px" }}
      />
      <div className="absolute text-[1.4rem] font-[500] text-darkGray">37</div>
    </div>
  );
};

export default CreditScoreChart;
