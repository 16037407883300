import React, { useEffect, useRef ,useState} from "react";
import Glide from "@glidejs/glide";
import prev from 'assets/images/home/chev.svg'
import next from 'assets/images/home/next.svg'

const ITSlider = () => {
  const glideRef = useRef(null);


  const ITListNames = [
    "Salaried Income Returns",
    "Business/ Freelancing Income Returns",
    "Refund Re-Issue/Status",
    "Reply to Notice/Order",
  ];
  const [count,setCount] = useState(0)

  useEffect(() => {
    // Initialize Glide.js when the component mounts
    const glide = new Glide(glideRef.current, {
      //   type: 'slider', // Type of the slider (carousel, slider, carousel-3d, or slider-3d)
      perView: 3.3, // Number of slides to show per view
      //   focusAt: 'center', // Set where the slider should focus
      gap: 16, // Space between slides (in pixels)
      breakpoints: {
        1280: {
          perView: 3.3,
        },
        768: {
          perView: 2,
        },
        576: {
          perView: 1,
        },
      },
    });

    glide.mount(); // Mount the Glide.js instance

    // Clean up the Glide.js instance when the component unmounts
    return () => {
      glide.destroy();
    };
  }, []); // Run this effect only once when the component mounts

  return (
    <div className="glide  " ref={glideRef}>
       <div className="glide__arrows flex flex-row-reverse px-[2%] gap-2" data-glide-el="controls">
       {count === 0 ? <button onClick={()=>{setCount(1)}} className="glide__arrow glide__arrow--right " data-glide-dir=">">
         <img src={next}/>
        </button> : <img src={next} />} 
        {count === 1 ? <button onClick={()=>{setCount(0)}} className="glide__arrow glide__arrow--left " data-glide-dir="<">
       <img src={prev}/>
        </button> : <img src={prev} />
        } 
      
      </div>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
       
          {ITListNames.map((i) => {
            return (
              <i className="glide__slide">
                {" "}
              <div className="  bg-light-purple  h-72  flex flex-col-reverse pb-[5%]  mr-[2%] rounded-2xl ">
                  <h3 className=" not-italic pl-[5%] font-[700] text-gray-700 text-[2.2rem] leading-[3rem] pr-[3rem]	">{i}</h3>
                </div>
              </i>
            );
          })}
        </ul>
      </div>
     
    </div>
  );
};

export default ITSlider;
