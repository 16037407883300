import React, { useState, useContext, useEffect, useRef } from "react";
import ProfileNav from "containers/privateRoutes/Dashboard/ProfileNav";
import useGetUser from "containers/services/Data/useGetUserDetails";
import { myContext } from "App";
// import profilePic from "assets/images/dashboard/profilePiv.svg";
import notification from "assets/images/dashboard/notification.svg";
import { Link } from "react-router-dom";
import edit from "assets/images/profile/Vector.svg";
import usePatchUserUpdate from "../Orders/data/usePatchUpdateUser";
import dayjs from "dayjs";
import attachImg from "assets/images/orders/attachment-line.svg";
import useGetUserBasic from "containers/privateRoutes/Dashboard/data/getUserbasicDetails";
import EditButton from "assets/images/profile/lucide_edit.svg";
import Edit from "assets/images/profile/edit_button.png";
import Avatar from "assets/images/profile/Avatar.png";
import logout from "assets/images/dashboard/log-out.svg";

import { DatePicker } from "antd";
import LogoutDialog from "components/loginProfileNavbar/LogoutDialog";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import { ProfileContext } from "./Layout";

function Profile() {
  const [editClicked, setEditClicked] = useState(false);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [state, setState] = useState("");
  const [pass, setPass] = useState("");
  const [PP, setPP] = useState();
  const [businessProfile, setBusinessProfile] = useState(false);

  const [editedFullName, setEditedFullName] = useState();
  const [editedFirstName, setEditedFirstName] = useState();
  const [editedLastName, setEditedLastName] = useState();
  const [editedDob, setEditedDob] = useState();
  const [editedEmail, setEditedEmail] = useState();
  const [editedPhnNo, setEditedPhnNo] = useState();
  const [editedState, setEditedState] = useState();
  const [editedPass, setEditedPass] = useState();
  const [editedConfirmPass, setEditedConfirmPass] = useState();
  const [editedPP, setEditedPP] = useState();
  const [businessName, setBusinessName] = useState("");
  const [BusinessType, setBusinessType] = useState("");
  const [dateOfFormation, setDateOfFormation] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessMobNumber, setBusinessMobNumber] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [annualRevenue, setAnnualRevenue] = useState("");

  const fileInputRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const [saveChanges, setSaveChanges] = useState(false);
  const [
    userUpdateData,
    userUpdateError,
    userUpdateIsLoading,
    patchUserUpdate,
  ] = usePatchUserUpdate();

  const { isLoggedIn } = useContext(myContext);

  const [UserData, userError, userIsLoading, getUser] = useGetUser();

  const [UserBasicData, userBasicError, userBasicIsLoading, getUserBasic] =
    useGetUserBasic();

  const [showPP, setShopPP] = useState(false);
  const [firstLetter, setFirstLetter] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userType, setUserType] = useState("");
  const [editedUserType, setEditedUserType] = useState("");
  const [age, setAge] = useState(null);

  const { updateProfilePic } = useContext(ProfileContext);

  useEffect(() => {
    getUser();
    getUserBasic();
  }, [isLoggedIn]);

  useEffect(() => {
    if (UserData) {
      const formattedName = `${UserData.data?.first_name}${
        UserData.data?.last_name ? ` ${UserData.data?.last_name}` : ""
      }`
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        )
        .join(" ");

      setName(formattedName);

      setDob(UserData.data.date_of_birth);
      setEmail(UserData.data.email);
      setPhnNo(UserData.data.mobile_number);
      setState(UserData.data.state);
      setPass(UserData.data.password);
      setUserType(UserData.data.client_type);
      setEditedUserType(UserData.data.client_type);

      setEditedFirstName(UserData.data.first_name);
      setEditedLastName(UserData.data.last_name);
      setEditedState(UserData.data.state);
      setEditedEmail(UserData.data.email);
      setEditedPhnNo(UserData.data.mobile_number);
      setEditedPP(UserData.data.profile_picture);

      setEditedDob(`${UserData.data.date_of_birth}`);
      setBusinessName(
        UserData.data.business_name
          ?.split(" ")
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.substr(1)?.toLowerCase()
          )
          ?.join(" ")
      );
      setBusinessType(UserData.data.client_type);
      setBusinessEmail(UserData.data.business_email);
      setBusinessMobNumber(UserData.data.business_mobile_number);

      setContactPerson(UserData.data.contact_person);
      setDateOfFormation(UserData.data.date_of_formation);
      setNumberOfEmployees(UserData.data.number_of_employees);
      setIndustry(UserData.data.industry_type);
      setBusinessNature(UserData.data.nature_of_business);
      setAnnualRevenue(UserData.data.annual_revenue);
      if (UserData.data.client_type !== 1) {
        setBusinessProfile(true);
      }
    }
    if (UserBasicData) {
      setFirstLetter(UserBasicData.data.profile_title);
      setProfilePic(UserBasicData.data.profile_picture);
      if (UserBasicData.data.profile_picture?.length > 0) {
        setShopPP(true);
      }
    }
  }, [UserData, UserBasicData, userUpdateData]);

  useEffect(() => {}, [saveChanges]);

  const clientTypeMap = {
    1: "Individual",
    2: "Proprietorship (Business)",
    3: "HUF",
    4: "Firm",
    5: "LLP",
    6: "Private Limited Company",
    7: "Public Limited Company",
    8: "Trust",
  };

  const getClientTypeString = (userType) => {
    return clientTypeMap[userType];
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setAge(storedUser.age || null);
    }
  }, []);

  const dateChange = (date, dateString) => {
    const formattedDob = `${dateString.split("-")[2]}-${
      dateString.split("-")[1]
    }-${dateString.split("-")[0]}`;
    setEditedDob(formattedDob);
    user.date_of_birth = dateString;
    localStorage.setItem("user", JSON.stringify(user));
    calculateAge();
  };

  const calculateAge = () => {
    const userDob = user?.date_of_birth;
    const formattedDob = `${userDob?.split("-")[2]}-${userDob?.split("-")[1]}-${
      userDob?.split("-")[0]
    }`;

    const dob = dayjs(formattedDob, "DD-MM-YYYY");
    const currentAge = dayjs().diff(dob, "year");
    setAge(currentAge);
  };

  useEffect(() => {
    calculateAge();
  }, []);

  const dateOfFormationChange = (date, dateString) => {
    setDateOfFormation(
      `${dateString.split("-")[2]}-${dateString.split("-")[1]}-${
        dateString.split("-")[0]
      }`
    );
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^[0-9]{10}$/;
    return re.test(String(phone));
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEditedEmail(value);
    if (!validateEmail(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setEditedPhnNo(value);
    if (!validatePhone(value)) {
      setPhoneError("Invalid phone number");
    } else {
      setPhoneError("");
    }
  };

  useEffect(() => {
    if (saveChanges) {
      const userData = {};
      if (businessProfile === false) {
        userData.first_name = editedFirstName;
        userData.last_name = editedLastName || "";
        userData.date_of_birth = editedDob;
        userData.state = editedState;
        userData.email = editedEmail;
        userData.password = editedPass;
        userData.confirm_password = editedConfirmPass;
        userData.client_type = editedUserType;
        userData.mobile_number = editedPhnNo;

        if (editedPP instanceof File) {
          userData.profile_picture = editedPP;
        }
      } else {
        userData.business_mobile_number = businessMobNumber;
        userData.business_name = businessName;
        userData.contact_person = contactPerson;
        userData.business_email = email;
        userData.annual_revenue = annualRevenue;
        userData.number_of_employees = numberOfEmployees;
        userData.industry_type = industry;
        userData.client_type = BusinessType;
        userData.date_of_formation = dateOfFormation;
        userData.nature_of_business = businessNature;
        userData.password = editedPass;
        userData.confirm_password = editedConfirmPass;

        if (editedPP instanceof File) {
          userData.profile_picture = editedPP;
        }
      }

      patchUserUpdate(userData);
      console.log("firstName after save :", editedFirstName);
      console.log("last name after save:", editedLastName);
    }

    setSaveChanges(false);
  }, [saveChanges]);

  const handleSaveChanges = () => {
    if (validateEmail(editedEmail) && validatePhone(editedPhnNo)) {
      setSaveChanges(true);
    } else {
      if (!validateEmail(editedEmail)) {
        showErrorToast("", "Invalid email format");
      }
      if (!validatePhone(editedPhnNo)) {
        showErrorToast("", "Invalid phone number");
      }
    }
  };

  useEffect(() => {
    if (userUpdateData) {
      if (userUpdateData.status_code === 200) {
        updateProfilePic(userUpdateData.data.data.profile_picture)
        showSuccessToast("Updated successfully")
      }
    }
  }, [userUpdateData]);

  useEffect(() => {
    if (userUpdateError) {
      if (userUpdateError) {
        showErrorToast("", "something went wrong");
      }
    }
    if (userError) {
      console.log("UserEror:", userError);
      showErrorToast("", "User Error");
    }
    if (userBasicError) {
      console.log("userBasicError:", userBasicError);
      showErrorToast("", "UserBasic Error");
    }
  }, [userUpdateError, userBasicError, userError]);

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="min-h-screen grid grid-cols-12 ">
      <div className="md:bg-gray-100  md:col-span-12 col-span-12 p-[2%]">
        {businessProfile ? (
          <>
            <div className=" flex flex-row-reverse">
              {userBasicIsLoading ? (
                <div className=" flex flex-row-reverse">
                  <div
                    role="status"
                    className="flex flex-row-reverse gap-3 justify-center items-center animate-pulse"
                  >
                    <div className="w-[2rem] h-[2rem] rounded-full bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                    <div className="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                    <div className="w-[1rem] h-[1rem] rounded-full bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>

                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {/* <Link className=" text-black no-underline" to={"/profile"}>
                    <div className="flex flex-row-reverse gap-[8%] items-center ">
                      {showPP === false && (
                        <div className=" w-[4rem] h-[3rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                          {" "}
                          <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                            {`${businessName.split(" ")[0][0]}${
                              businessName.split(" ").slice(-1)[0][0]
                            }`}
                          </p>
                        </div>
                      )}
                      {showPP === true && (
                        <img
                          className=" w-[50px] h-[50px] rounded-full md:block hidden"
                          src={profilePic}
                          alt=""
                        />
                      )}

                      <p className="md:block hidden m-0 font-[600] text-[1rem] leading-[1.3rem] text-[#9065B4]  whitespace-nowrap ">
                        {businessName}
                      </p>
                    </div>
                  </Link>
                  <div className="my-[1%] mx-[4%] md:block hidden">
                    <img src={notification} alt="" />
                  </div> */}
                </>
              )}
            </div>
            <h5 className="my-4 mx-2 font-[600] text-[1.5rem]">
              Business Profile
            </h5>
            <div className="flex flex-col items-center mt-8 mb-8 md:hidden">
              <div className="relative">
                <img
                  className="w-32 h-32 rounded-full border-2 border-purple-500"
                  src={profilePic}
                  alt="Profile"
                />
                <input
                  className="hidden"
                  ref={fileInputRef}
                  type="file"
                  onChange={(e) => {
                    setEditedPP(e.target.files[0]);
                  }}
                />
              </div>
              <div className="absolute mt-28 ml-20 ">
                <button className="p-1" onClick={handleEditClick}>
                  <img src={Edit} className="Edit" />
                </button>
              </div>
            </div>
            <div className=" bg-white-body rounded-xl p-[2%] my-[1%]">
              <div className="flex justify-between md:mb-0 mb-4">
                <h5 className="text-[#9092A3] m-0 font-[500] text-[1.1rem]">
                  Business Details
                </h5>
              </div>{" "}
              <hr className="hidden md:block" />
              <div className="grid grid-cols-12 gap-6">
                <div className=" col-span-12 grid grid-cols-12">
                  <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      BUSINESS NAME
                    </h6>
                    <input
                      value={businessName}
                      onChange={(e) => {
                        setBusinessName(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                  <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      BUSINESS TYPE
                    </h6>
                    <div className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md">
                      {getClientTypeString(userType)}
                    </div>
                  </div>

                  <div className="col-span-12 md:col-span-4 ">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      DATE OF FORMATION
                    </h6>
                    <DatePicker
                      defaultValue={dayjs(
                        String(
                          `${dateOfFormation?.split("-")[2]}-${
                            dateOfFormation?.split("-")[1]
                          }-${dateOfFormation?.split("-")[0]}`
                        ),
                        "YYYY-MM-DD"
                      )}
                      onChange={dateOfFormationChange}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                </div>
                <hr className="hidden md:block md:col-span-12" />
                <div className=" col-span-12 grid grid-cols-12">
                  <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      CONTACT PERSON
                    </h6>
                    <input
                      value={contactPerson}
                      onChange={(e) => {
                        setContactPerson(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>

                  <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      EMAIL ID
                    </h6>
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                  <div className="col-span-12 md:col-span-4 ">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      MOBILE NO
                    </h6>

                    <input
                      value={businessMobNumber}
                      onChange={(e) => {
                        setBusinessMobNumber(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                </div>

                <hr className="hidden md:block md:col-span-12" />
                <div className=" col-span-12 grid grid-cols-12">
                  <div className="col-span-12 md:col-span-3 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      NO OF EMPLOYEES
                    </h6>

                    <select
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                      value={numberOfEmployees}
                      onChange={(e) => {
                        setNumberOfEmployees(e.target.value);
                      }}
                    >
                      <option>select</option>
                      <option>Upto 5 Employees</option>
                      <option>Upto 25 Employees</option>
                      <option>more than 25 Employees</option>
                    </select>
                  </div>

                  <div className="col-span-12 md:col-span-3 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      INDUSTRY
                    </h6>
                    <select
                      className="border-1 border-[#9092A3] w-[100%] md:w-[90%] p-2 md:p-1 rounded-md"
                      value={industry}
                      onChange={(e) => {
                        setIndustry(e.target.value);
                      }}
                    >
                      <option>select</option>

                      <option>Consultancy</option>
                      <option>Technology</option>
                      <option>Construction</option>
                      <option>Clothing</option>
                      <option>Agriculture</option>
                      <option>Salaried</option>
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-3 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      BUSINESS NATURE
                    </h6>

                    <select
                      className="border-1 border-[#9092A3] w-[100%] md:w-[90%] p-2 md:p-1 rounded-md"
                      value={businessNature}
                      onChange={(e) => {
                        setBusinessNature(e.target.value);
                      }}
                    >
                      <option>select</option>

                      <option>Service</option>
                      <option>Manufacturing</option>
                      <option>Retailer</option>
                      <option>Wholesaler</option>
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-3 ">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      ANNUAL REVENUE
                    </h6>
                    <input
                      value={annualRevenue}
                      onChange={(e) => {
                        setAnnualRevenue(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[90%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                </div>
                <hr className="hidden md:block md:col-span-12" />
                <div className=" col-span-12 grid grid-cols-12">
                  <div className=" col-span-4 hidden md:block">
                    <h6 className="text-gray-400">PROFILE PICTURE</h6>
                    <input
                      className="hidden"
                      id="aadhar"
                      type="file"
                      onChange={(e) => {
                        setEditedPP(e.target.files[0]);
                      }}
                    />
                    <label
                      htmlFor="aadhar"
                      name="cover_image"
                      className=" rounded w-[50%] bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                    >
                      <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                      Attach file
                    </label>
                  </div>
                  <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      PASSWORD
                    </h6>
                    <input
                      value={editedPass}
                      onChange={(e) => {
                        setEditedPass(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                  <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                    <h6 className=" text-[#272727] md:text-gray-400">
                      CONFIRM PASSWORD
                    </h6>
                    <input
                      type="password"
                      value={editedConfirmPass}
                      onChange={(e) => {
                        setEditedConfirmPass(e.target.value);
                      }}
                      className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse gap-4 mt-2 mb-24 md:mb-4 md:mt-2">
              <button
                onClick={() => {
                  setSaveChanges(true);
                }}
                className=" p-2 md:p-[1%] px-3  rounded-md bg-purple-dark-shade text-white"
              >
                Save Changes
              </button>
              <button
                onClick={() => {
                  setEditClicked(false);
                }}
                className="  p-2 md:p-[1%] md:px-[4%] px-3 rounded-md bg-purple-100 "
              >
                Discard
              </button>
            </div>
          </>
        ) : (
          <>
            {" "}
            {editClicked !== true && (
              <>
                <div className=" flex flex-row-reverse">
                  {userBasicIsLoading ? (
                    <div className=" flex flex-row-reverse">
                      <div
                        role="status"
                        className="flex flex-row-reverse gap-3 justify-center items-center animate-pulse"
                      >
                        <div className="w-[2rem] h-[2rem] rounded-full bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                        <div className="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                        <div className="w-[1rem] h-[1rem] rounded-full bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>

                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <Link
                        className="flex items-center text-black no-underline"
                        to={"/profile"}
                      >
                        <div className="flex flex-row-reverse gap-[8%] items-center ">
                          {showPP === false && (
                            <div className=" w-[5rem] h-[3rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                              <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                                {`${name.split(" ")[0][0]}${
                                  name.split(" ").slice(-1)[0][0]
                                }`}
                              </p>
                            </div>
                          )}
                          {showPP === true && (
                            <img
                              className=" w-[50px] h-[50px] rounded-full md:block hidden"
                              src={profilePic}
                              alt=""
                            />
                          )}
                          <p className="md:block hidden m-0 font-[600] text-[1rem] leading-[1.3rem] text-[#9065B4]  whitespace-nowrap ">
                            {name}
                          </p>
                          
                        </div>
                      </Link>
                      <div className="my-[1%] mx-[4%] md:block hidden">
                        <img src={notification} alt="" />
                      </div> */}
                    </>
                  )}
                </div>
                <div className="flex justify-between">
                  <h5 className="my-4 mx-3 font-[600] text-[1.5rem]">
                    Profile
                  </h5>
                  <div
                    onClick={() => {
                      setEditClicked(true);
                    }}
                    className="flex gap-2 items-center hover:cursor-pointer md:hidden"
                  >
                    <img src={edit} alt="" />
                    <h5 className=" text-gray-400 m-0">Edit</h5>
                  </div>
                </div>

                <div className="flex flex-col items-center mt-8 mb-8 md:hidden">
                  <div className="relative">
                    <img
                      className="w-32 h-32 rounded-full border-2 border-purple-500"
                      src={profilePic}
                      alt="Profile"
                    />
                  </div>
                </div>
                <div className=" md:bg-white-body rounded-xl p-[2%] my-[1%]">
                  <div className="flex justify-between">
                    <h5 className=" text-gray-400 md:m-0 mb-4">
                      Personal Details
                    </h5>
                    <div
                      onClick={() => {
                        setEditClicked(true);
                      }}
                      className="flex gap-2 items-center hover:cursor-pointer hidden md:block"
                    >
                      <img src={edit} alt="" />
                      <h5 className=" text-gray-400 m-0">Edit</h5>
                    </div>
                  </div>{" "}
                  <hr className="md:block hidden" />
                  <div className="grid grid-cols-12 gap-6">
                    <div className=" col-span-12 md:col-span-3 ">
                      <h6 className="text-gray-400">NAME</h6>
                      {userIsLoading ? (
                        <div role="status" class=" animate-pulse">
                          <div class="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <p className="font-[600] text-[1rem]">{name}</p>
                      )}
                    </div>
                    {/* user type: */}
                    <div className=" col-span-12 md:col-span-3 ">
                      <h6 className="text-gray-400">USER TYPE</h6>
                      {userIsLoading ? (
                        <div role="status" class=" animate-pulse">
                          <div class="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <p className="font-[600] text-[1rem]">
                          {getClientTypeString(userType)}
                        </p>
                      )}
                    </div>
                    <div className="col-span-12 md:col-span-3 ">
                      <h6 className="text-gray-400">DATE OF BIRTH</h6>
                      {userIsLoading ? (
                        <div role="status" class=" animate-pulse">
                          <div class="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <p className="font-[600] text-[1rem]">{dob}</p>

                          <div className="mt-2">
                            {age !== null && (
                              <p className="text-gray-400">
                                AGE:{" "}
                                <span className="text-black font-[600] text-[1rem]">
                                  {age} years
                                </span>
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-span-12 md:col-span-3 ">
                      <h6 className="text-gray-400">EMAIL ID</h6>
                      {userIsLoading ? (
                        <div role="status" class=" animate-pulse">
                          <div class="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <p className="font-[600] text-[1rem]">{email}</p>
                      )}
                    </div>
                    <div className="col-span-12 md:col-span-3 ">
                      <h6 className="text-gray-400">MOBILE NUMBER</h6>
                      {userIsLoading ? (
                        <div role="status" class=" animate-pulse">
                          <div class="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <p className="font-[600] text-[1rem]">{phnNo}</p>
                      )}
                    </div>
                    <div className="col-span-12 md:col-span-3 ">
                      <h6 className="text-gray-400">STATE</h6>
                      {userIsLoading ? (
                        <div role="status" class=" animate-pulse">
                          <div class="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <p className="font-[600] text-[1rem]">{state}</p>
                      )}
                    </div>{" "}
                    <div className="col-span-12 md:col-span-3 mb-4">
                      <h6 className="text-gray-400">PASSWORD</h6>
                      {userIsLoading ? (
                        <div role="status" className=" animate-pulse">
                          <div className="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          {/* <p className="font-[600] text-[1rem]">{pass}</p> */}
                          <p className="font-[800] text-[1.2rem] ">*********</p>
                        </>
                      )}
                    </div>
                  </div>
                  {/* logout button */}
                  <div className="flex justify-center items-center mt-2 mb-24 md:hidden">
                    <button className=" p-2 md:p-[1%] px-3  rounded-md bg-purple-dark-shade text-white">
                      <LogoutDialog />
                    </button>
                  </div>
                </div>
              </>
            )}
            {editClicked && (
              <>
                <div className=" flex flex-row-reverse">
                  {userBasicIsLoading ? (
                    <div className=" flex flex-row-reverse">
                      <div
                        role="status"
                        className="flex flex-row-reverse gap-3 justify-center items-center animate-pulse"
                      >
                        <div className="w-[2rem] h-[2rem] rounded-full bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                        <div className="w-[8rem] h-[1rem] rounded bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>
                        <div className="w-[1rem] h-[1rem] rounded-full bg-[#f5eef5] dark:bg-[#FFFBFF]   mb-4"></div>

                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <Link
                        className="flex items-center text-black no-underline"
                        to={"/profile"}
                      >
                        {" "}
                        <div className="flex flex-row-reverse gap-[8%] items-center ">
                          {showPP === false && (
                            <div className=" w-[5rem] h-[3rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                              {" "}
                              <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                                {`${name.split(" ")[0][0]}${
                                  name.split(" ").slice(-1)[0][0]
                                }`}
                              </p>
                            </div>
                          )}
                          {showPP === true && (
                            <img
                              className=" w-[50px] h-[50px] rounded-full md:block hidden"
                              src={profilePic}
                              alt=""
                            />
                          )}
                          <p className="md:block hidden m-0 font-[600] text-[1rem] leading-[1.3rem] text-[#9065B4]  whitespace-nowrap ">
                            {name}
                          </p>
                          
                        </div>
                      </Link>
                      <div className="my-[1%] mx-[4%] md:block hidden">
                        <img src={notification} alt="" />
                      </div> */}
                    </>
                  )}
                </div>

                {/* Profile pic edit */}
                <h5 className="my-[3rem] mx-3 font-[600] text-[1.5rem]">
                  Profile
                </h5>
                <div className="flex flex-col items-center mt-8 mb-8 md:hidden">
                  <div className="relative">
                    <img
                      className="w-32 h-32 rounded-full border-2 border-purple-500"
                      src={profilePic}
                      alt="Profile"
                    />
                    <input
                      className="hidden"
                      ref={fileInputRef}
                      type="file"
                      onChange={(e) => {
                        setEditedPP(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="absolute mt-28 ml-20 ">
                    <button className="p-1" onClick={handleEditClick}>
                      <img src={Edit} className="Edit" />
                    </button>
                  </div>
                </div>
                <div className=" md:bg-white-body rounded-xl p-[2%] my-[2%]">
                  <div className="flex justify-between md:mb-0 mb-4">
                    <h5 className="text-[#9092A3]">Personal Details</h5>
                  </div>{" "}
                  <hr className="hidden md:block" />
                  <div className="grid grid-cols-12 ">
                    <div className=" col-span-12 grid grid-cols-12">
                      <div className=" col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className=" text-[#272727] md:text-gray-400">
                          FIRST NAME
                        </h6>
                        <input
                          value={editedFirstName}
                          onChange={(e) => {
                            setEditedFirstName(e.target.value);
                          }}
                          
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        />
                      </div>
                      <div className=" col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className=" text-[#272727] md:text-gray-400">
                          LAST NAME
                        </h6>
                        <input
                          value={editedLastName}
                          onChange={(e) => {
                            setEditedLastName(e.target.value);
                          }}
                          
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        />
                      </div>

                      <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className="text-[#272727] md:text-gray-400">
                          DATE OF BIRTH
                        </h6>
                        <DatePicker
                          defaultValue={editedDob !== 'null' ? dayjs(
                            String(   
                              `${editedDob.split("-")[2]}-${
                                editedDob.split("-")[1]
                              }-${editedDob.split("-")[0]}`
                            ),
                            "YYYY-MM-DD"
                          ): null}
                          placeholder="DD/MM/YYYY"
                          onChange={dateChange}
                          disabledDate={disabledDate}
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1"
                        />
                      </div>
                    </div>
                    <hr className="hidden md:block md:col-span-12" />
                    <div className=" col-span-12 grid grid-cols-12 ">
                      <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className="text-[#272727] md:text-gray-400">
                          EMAIL ID
                        </h6>
                        <input
                          value={editedEmail}
                          onChange={handleEmailChange}
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        />
                        {emailError && (
                          <p className="text-red-500 text-sm">{emailError}</p>
                        )}
                      </div>

                      <div className="col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className="text-[#272727] md:text-gray-400">
                          MOBILE NO
                        </h6>
                        <input
                          value={editedPhnNo}
                          onChange={handlePhoneChange}
                          // onChange={(e) => {
                          //   setEditedPhnNo(e.target.value);
                          // }}
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        />
                        {phoneError && (
                          <p className="text-red-500 text-sm">{phoneError}</p>
                        )}
                      </div>
                      <div className=" col-span-4 hidden md:block">
                        <h6 className="text-gray-400">PROFILE PICTURE</h6>
                        <input
                          className="hidden"
                          id="aadhar"
                          type="file"
                          onChange={(e) => {
                            setEditedPP(e.target.files[0]);
                          }}
                        />
                        {/* <p>{editedPP.name}</p> */}
                        <label
                          for="aadhar"
                          name="cover_image"
                          className=" rounded w-[50%] bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                        >
                          <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                          Attach file
                        </label>
                      </div>
                    </div>
                    <hr className=" md:col-span-12 md:block hidden" />
                    <div className=" col-span-12 grid grid-cols-12">
                      <div className=" col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className="text-[#272727] md:text-gray-400">
                          STATE
                        </h6>

                        <select
                          value={editedState}
                          onChange={(e) => {
                            setEditedState(e.target.value);
                          }}
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        >
                          <option>Select state</option>
                          <option>Andhra Pradesh</option>
                          <option>Arunachal Pradesh</option>
                          <option>Assam</option>
                          <option>Bihar</option>
                          <option>Chhattisgarh</option>
                          <option>Goa</option>
                          <option>Gujarat</option>
                          <option>Haryana</option>
                          <option>Himachal Pradesh</option>
                          <option>Jammu and Kashmir</option>
                          <option>Jharkhand</option>
                          <option>Karnataka</option>
                          <option>Kerala</option>
                          <option>Madhya Pradesh</option>
                          <option>Maharashtra</option>
                          <option>Manipur</option>
                          <option>Meghalaya</option>
                          <option>Mizoram</option>
                          <option>Nagaland</option>
                          <option>Odisha</option>
                          <option>Punjab</option>
                          <option>Rajasthan</option>
                          <option>Sikkim</option>
                          <option>Tamil Nadu</option>
                          <option>Telangana</option>
                          <option>Tripura</option>
                          <option>Uttar Pradesh</option>
                          <option>Uttarakhand</option>
                          <option>West Bengal</option>
                          <option>Andaman and Nicobar Islands</option>
                          <option>Chandigarh</option>
                          <option>Dadra and Nagar Haveli</option>
                          <option>Daman and Diu</option>
                          <option>Delhi</option>
                          <option>Lakshadweep</option>
                          <option>Puducherry</option>
                        </select>
                      </div>
                      <div className=" col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className="text-[#272727] md:text-gray-400">
                          PASSWORD
                        </h6>
                        <input
                          value={editedPass}
                          onChange={(e) => {
                            setEditedPass(e.target.value);
                          }}
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        />
                      </div>
                      <div className=" col-span-12 md:col-span-4 md:mb-0 mb-4">
                        <h6 className="text-[#272727] md:text-gray-400">
                          CONFIRM PASSWORD
                        </h6>
                        <input
                          type="password"
                          value={editedConfirmPass}
                          onChange={(e) => {
                            setEditedConfirmPass(e.target.value);
                          }}
                          className="border-1 border-[#9092A3] w-[100%] md:w-[80%] p-2 md:p-1 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row-reverse gap-4 mt-2 mb-24 md:mb-4 md:mt-2">
                  <button
                    onClick={handleSaveChanges}
                    className=" p-2 md:p-[1%] px-3  rounded-md bg-purple-dark-shade text-white"
                  >
                    Save Changes
                  </button>
                  <button
                    onClick={() => {
                      setEditClicked(false);
                    }}
                    className=" p-2 md:p-[1%] md:px-[4%] px-3 rounded-md bg-purple-100 "
                  >
                    Discard
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Profile;
