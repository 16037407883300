import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import grayBox from "assets/images/assets/grayBoxRectangle.svg";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_PERSONAL_ASSET_DETAILED_URL} from "constants/routes";
import useGetGoldDetails from "../data/useGetGoldDetails";
import AddNewGold from "./Add/AddNewGold";
import Button from "components/Button/BtnOrange";

const Gold = () => {
  const location = useLocation();
  const [isGold, setIsGold]= useState(false)
  const addGoldBtnRef= useRef(null);
  const [reload, setIsReload]=useState(false)
  const queryParams = new URLSearchParams(location.search);

  const goldId =
    location.state?.landId || decodeURIComponent(queryParams.get("id"));

  const [getGoldData, getGoldError, getGoldIsLoading, getGold] =
    useGetGoldDetails();

  useEffect(() => {
    getGold(goldId);
  }, []);

  useEffect(()=>{
    if(reload){
      getGold(goldId);
    }
  },[reload])

  const openHandleForm=()=>{
    setIsGold(true)
  }

  useEffect(()=>{
   if(isGold){
    addGoldBtnRef.current.click()
   }
  },[isGold])

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Personal Asset"} asset_type={"Gold & Jewellery"}/>
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1rem]">
          Total Gold and Jewellery Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">₹{getGoldData?.data[0]?.total_gold_jewellery_value}</h4>
        </div>  

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getGoldData?.data[0]?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getGoldData?.data[0]?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getGoldData?.data[0]?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
        <Button onClick={openHandleForm} name={"+ Add New Gold/Jewellery "} px={"px-[0.6rem]"} py={"py-[0.4rem]"} />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
       
        <div className="grid grid-cols-4 gap-2 ">
          {getGoldData ? (
            getGoldData?.data?.map((item, index) => (
              <Link
                to={`${ASSETS_PERSONAL_ASSET_DETAILED_URL.replace(
                  ":personal_asset_type",
                  "GoldAndJewellery"
                )}?id=${goldId}/?gold_id=${item.id}`}
                state={{ assetId: goldId, itemId: item.id, itemName: item?.item_name }}
                key={item.id}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={grayBox} alt="land" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                   {item?.item_name}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item?.purchase_price}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={grayBox} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Land</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      {
        isGold && <AddNewGold
        categoryId={goldId}
         setIsGold={setIsGold}
         isGold={isGold}
         setIsReload={setIsReload}
         triggerElement={
          <button ref={addGoldBtnRef} className="hidden">
            Add Gold
          </button>
        }
        />
      }
    </div>
  );
};

export default Gold;
