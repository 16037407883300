import {DELETE_WORK_ORDER_URL} from "constants/apis";
import useAPICall from "hooks/useAPICall";

const useDeleteOrderFile = () => {
    const [
        data,
        error,
        isLoading,
        callDeleteWorkOrder,
        setSuccessData,
        setError,
      ] = useAPICall(undefined, "");
    
      const defaultFallback = () => {
        // setError(en.something_went_wrong);
        setSuccessData(undefined);
      };
      const statusObj = [
        {
          status_code: 200,
          status_text: "OK",
          callBack: (res) => {
            const data = res;
            if (data && typeof data === "object") {
              setSuccessData(data);
            } else {
              defaultFallback();
            }
          },
        },
        {
          status_text: "Bad Request",
          status_code: 400,
          callBack: defaultFallback,
        },
        {
          status_text: "Internal Server Error",
          status_code: 500,
          callBack: defaultFallback,
        },
      ];
      const deleteWorkOrder = (deleteId, body) => {
        const url = DELETE_WORK_ORDER_URL.replace(":deleteId",deleteId)
    
        callDeleteWorkOrder({
          url,
          method: "DELETE",
          statusObj,
          defaultFallback,
          body,

        });
      };
    
      return [
        data,
        error,
        isLoading,
        deleteWorkOrder,
        setSuccessData,
        setError,
      ];
}

export default useDeleteOrderFile