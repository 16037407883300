import React from "react";
import rating from "assets/images/registration/Stars.svg";

function RegistrationReview() {
  const reviewArr = [
    {
      review:
        "“Starting a business is stressful enough, but BeyondTax made registering a breeze! Their online portal was user-friendly, and their representative was incredibly helpful in guiding me through the process”",
      rating: rating,
      name: "Rich Wilson",
    },
    {
      review:
        "“I was dreading the whole registration process, but BeyondTax completely changed my perspective. Their clear instructions and smooth workflow made everything easy to understand. ”",
      rating: rating,
      name: "David",
    },
    {
      review:
        "“They handled all the government interactions, saving me a ton of time and frustration.”",
      rating: rating,
      name: "Dheeraj",
    },
  ];
  return (
    <>
      <div className="p-[6%] ">
        <div className="grid grid-cols-12 overflow-hidden relative">
          <div className="  lg:col-span-6 md:col-span-6 col-span-12">
            <h2 className="font-[700] text-[1.9rem] leading-[2.5rem] md:text-[2.3rem] md:leading-[3rem] lg:text-[2.3rem] lg:leading-[3rem]">See what our clients has to say about our services</h2>
            <p className="font-[500] text-[1rem] leading-[1.2rem] text-gray-500">Around 10,000 people have claimed our services</p>
          </div>
          <div className="wrapper col-span-12 flex lg:p-[5%] flex-wrap md:p-[5%] justify-center z-[2]">
            {reviewArr.map((data) => {
              return (
                <>
                  <div className="lg:w-[30%] md:w-[25%] w-[100%] drop-shadow-md forBlur p-4 rounded-xl flex flex-col justify-between lg:mx-2 md:mx-2 lg:my-0 md:my-0 my-2">
                    <p className="font-[700] text-[.9rem] leading-[1.5rem] text-gray-800">{data.review}</p>
                    <div>
                      <img className="my-2" src={data.rating} alt="" />
                      <h4 className="font-[700] text-[1.5rem] leading-[2rem]">{data.name}</h4>
                    </div>
                  </div>
                </>
              );
            })}
            
          </div>
          <div className=" bg-purple-dark-shade p-4 w-screen bottom-[6%] absolute lg:block md:block hidden"></div>
        </div>
      </div>
    </>
  );
}

export default RegistrationReview;
