import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ToShowNavAndFooter({ children }) {
  // const [show, setShow] = useState(true);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hidePaths = [
      "/orders",
      "/profile",
      "/dashboard",
      "/order",
      "/profileView",
      "/login",
      "/newLogin",
      "/googleLoginLoading",
      "/income-tax",
      "/income-tax-returns",
      "/income-tax-profile",
      "/forgot-password",
      "/otp",
      "/set-password",
      "/income-tax/reports",
      "/income-tax-profile/govt-ids",
      "/income-tax-profile/bank-accounts",
      "/income-tax-profile/invites/",
      "/income-tax-profile/tax-information",
      "/income-tax-business-profile",
      "/income-tax-business-profile/tax-business-information",
      '/income-tax-business-profile/business-invites/',
      '/newSignup',
      '/signUpMobileVerification',
      '/businessDetails', 
      '/sample-stakeholder',
      '/stakeholder-list/',
      '/liabilities/',
      '/liabilities-view/',
      '/liabilities/family-loan-detailed-view/'
    ];

    // Regular expressions for matching paths
    const regexPaths = [
      /^\/income-tax-summary(\/.*)?$/, // matches /income-tax-summary and any subpaths
      /^\/liabilities(\/.*)?$/, // matches /liabilities and any subpaths
      /^\/assets(\/.*)?$/,
      /^\/google-signin-success(\/.*)?$/
    ];

    // Check if the path is included in hidePaths or matches any regex
    if (
      hidePaths.includes(location.pathname) ||
      regexPaths.some((regex) => regex.test(location.pathname))
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location]);

  return <div>{show && children}</div>;
}

export default ToShowNavAndFooter;
