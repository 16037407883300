import React from "react";

function IncomeTaxReviews() {
  return (
    <div className="bg-gray-100 py-[8%] px-[8%]">
      <div className="grid grid-cols-12">
        <h2 className="col-span-12 md:col-span-7 lg:font-[700] lg:text-[2rem] lg:leading-[3rem] md:font-[600] md:text-[2rem] md:leading-[3rem] font-[600] text-[1.5rem] leading-[2rem] text-center md:text-left">
          See what{" "}
          <span className="bg-gradient-to-r from-[#7E0CDE] from-70% via-[#D14588] to-[#E65757] text-transparent bg-clip-text">
            our clients
          </span>{" "}
          has to say about our income tax services.
        </h2>
      </div>
      <p className="font-[500] text-center md:text-left mt-4 md:mt-0">
        around 10,000 people have claimed our services
      </p>

      <div className="relative mt-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-2 md:px-10">
          <div className="z-1 bg-white-body p-6 rounded-lg shadow-md">
            <p className="font-[600] text-[0.9rem] mb-4">
              “I used to dread tax season, but Beyond Tax took the stress
              completely out of it. Their online system was easy to use, and
              their tax expert answered all my questions clearly. Plus, I got a
              bigger refund than I expected!”
            </p>
            <div className="font-[500] text-[1.4rem] text-[#9065B4]">
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
            </div>
            <p className="font-[700] text-[1.3rem]">Prashanth</p>
          </div>
          <div className="z-1 bg-white-body p-6 rounded-lg shadow-md">
            <p className="font-[600] text-[0.9rem] mb-4">
              “Beyond Tax was fantastic! They were incredibly helpful in
              navigating our complicated situation with rental income. They
              saved us money and gave us peace of mind knowing everything was
              filed correctly”
            </p>
            <div className="font-[500] text-[1.4rem] text-[#9065B4]">
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
            </div>
            <p className="font-[700] text-[1.3rem]">Hemanth</p>
          </div>
          <div className="z-1 bg-white-body p-6 rounded-lg shadow-md">
            <p className="font-[600] text-[0.9rem] mb-4">
              “As a small business owner, I rely on Beyond Tax for expert tax
              advice year-round. They go beyond just filing my return”
            </p>
            <div className="font-[500] text-[1.4rem] text-[#9065B4] mt-16">
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
              <span className="inline-block mr-1">★</span>
            </div>
            <p className="font-[700] text-[1.3rem]">Dheeraj</p>
          </div>
        </div>
        <div className="hidden md:block absolute bottom-[-18px] left-0 w-full p-4 bg-[#9065B4]"></div>
      </div>
    </div>
  );
}

export default IncomeTaxReviews;

