import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";
import usePatchVehicleDetails from "../../data/usePatchVehicleDetails";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const EditVehicle = ({ editValue , detailData,  setIsReload}) => {
  const { estate_type } = useParams();
  const [
    patchVehicleData,
    patchVehicleError,
    patchVehicleIsLoading,
    patchVehicleDetails,
  ] = usePatchVehicleDetails();
  const removeBtnRef = useRef(null);
  const [name, setName] = useState("");
  const [assetType, setAssetType] = useState("active");
  const [errors, setErrors]=useState({});
  const [isClose, setIsClose]=useState(false)
  useEffect(()=>{
    if(patchVehicleData){
      showSuccessToast("Data updated successfully")
      setIsReload(true)
      setIsClose(true)
    }
    if(patchVehicleError){
      showErrorToast("Error in Updating")
    }
  },[patchVehicleData, patchVehicleError])
  const [vehicleForm, setVehicleForm] = useState({
    vehicle_number: "",
    vehicle_type: "",
    model: "",
    manufacturer: "",
    year_of_manufacture: "",
    purchase_date: "",
    purchase_price: "",
    registration_fee: "",
    road_tax: "",
    other_expenses: "",
    total_cost:"",
    asset_type:"active",
    registration_certificate:null,
    invoice:null,
    insurance_taken:false,
    loan_taken:false,
    mandates: [
      {
        expense_type: "",
        amount_paid: '',
        expense_date: '',
        upload_certificate: null
      }
    ]
  });

  useEffect(()=>{
    if(detailData){
       const {total_active_assets, total_assets, total_assets_amount, total_idle_assets, user, ...rest}=detailData;
       setVehicleForm(rest);
       setAssetType(rest.asset_type)
    }
  },[detailData]);

  const handleSubmit =()=>{
    if (!validateVehicleForm()) return;

    const formData = new FormData();

    // Helper function to check if a value is not empty
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append ID if it's not empty
    if (isNotEmpty(vehicleForm.id)) {
      formData.append("id", vehicleForm.id);
    }
  
    if (editValue === "vehicleValue") {
      if (isNotEmpty(assetType)) {
        formData.append("asset_type", assetType);
      }
    } else if (editValue === "vehicleDetails") {
      if (isNotEmpty(vehicleForm.vehicle_number)) {
        formData.append("vehicle_number", vehicleForm.vehicle_number);
      }
      if (isNotEmpty(vehicleForm.vehicle_type)) {
        formData.append("vehicle_type", vehicleForm.vehicle_type);
      }
      if (isNotEmpty(vehicleForm.model)) {
        formData.append("model", vehicleForm.model);
      }
      if (isNotEmpty(vehicleForm.manufacturer)) {
        formData.append("manufacturer", vehicleForm.manufacturer);
      }
      if (isNotEmpty(vehicleForm.year_of_manufacture)) {
        formData.append("year_of_manufacture", vehicleForm.year_of_manufacture);
      }
      if (typeof vehicleForm.registration_certificate !== "string" && vehicleForm.registration_certificate) {
        formData.append("registration_certificate", vehicleForm.registration_certificate);
      }
    } else if (editValue === "vehiclePurchaseDetails") {
      if (isNotEmpty(vehicleForm.purchase_date)) {
        formData.append("purchase_date", vehicleForm.purchase_date);
      }
      if (isNotEmpty(vehicleForm.purchase_price)) {
        formData.append("purchase_price", vehicleForm.purchase_price);
      }
      if (isNotEmpty(vehicleForm.registration_fee)) {
        formData.append("registration_fee", vehicleForm.registration_fee);
      }
      if (isNotEmpty(vehicleForm.road_tax)) {
        formData.append("road_tax", vehicleForm.road_tax);
      }
      if (isNotEmpty(vehicleForm.other_expenses)) {
        formData.append("other_expenses", vehicleForm.other_expenses);
      }
      if (isNotEmpty(vehicleForm.total_cost)) {
        formData.append("total_cost", vehicleForm.total_cost);
      }
      if (isNotEmpty(vehicleForm.insurance_taken)) {
        formData.append("insurance_taken", vehicleForm.insurance_taken);
      }
      if (isNotEmpty(vehicleForm.loan_taken)) {
        formData.append("loan_taken", vehicleForm.loan_taken);
      }
      if (typeof vehicleForm.invoice !== "string" && vehicleForm.invoice) {
        formData.append("invoice", vehicleForm.invoice);
      }
    } else if (editValue === "editMandata") {
      vehicleForm.mandates.forEach((mandate, index) => {
        if (isNotEmpty(mandate.expense_type)) {
          formData.append(`mandates[${index}][expense_type]`, mandate.expense_type);
        }
        if (isNotEmpty(mandate.amount_paid)) {
          formData.append(`mandates[${index}][amount_paid]`, mandate.amount_paid);
        }
        if (isNotEmpty(mandate.expense_date)) {
          formData.append(`mandates[${index}][expense_date]`, mandate.expense_date);
        }
        if (mandate.upload_certificate) {
          formData.append(`mandates[${index}][upload_certificate]`, mandate.upload_certificate);
        }
      });
    }
  
    // Print FormData values to console
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    console.log("form in vehicle", vehicleForm);
    patchVehicleDetails(formData, vehicleForm.asset);
  }

  const validateVehicleForm = () => {
    const newErrors = {};
    const yearPattern = /^\d{4}$/;
    const numberPattern = /^[A-Z0-9\s-]+$/;
    const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
    let hasMandateErrors=false;

    if(editValue === "vehicleValue"){

    }else if(editValue === "vehiclePurchaseDetails"){
      // if (!vehicleForm.purchase_date) {
      //   newErrors.purchase_date = "Purchase date is required.";
      // } else if (isNaN(new Date(vehicleForm.purchase_date).getTime())) {
      //   newErrors.purchase_date = "Purchase date should be a valid date.";
      // }
  
      // if (!vehicleForm.purchase_price || isNaN(vehicleForm.purchase_price)) {
      //   newErrors.purchase_price = "Purchase price is required and should be a number.";
      // } else if (vehicleForm.purchase_price <= 0) {
      //   newErrors.purchase_price = "Purchase price must be greater than 0.";
      // }
  
      // if (!vehicleForm.registration_fee || isNaN(vehicleForm.registration_fee)) {
      //   newErrors.registration_fee = "Registration fee is required and should be a number.";
      // } else if (vehicleForm.registration_fee <= 0) {
      //   newErrors.registration_fee = "Registration fee must be greater than 0.";
      // }
  
      // if (!vehicleForm.road_tax || isNaN(vehicleForm.road_tax)) {
      //   newErrors.road_tax = "Road tax is required and should be a number.";
      // } else if (vehicleForm.road_tax <= 0) {
      //   newErrors.road_tax = "Road tax must be greater than 0.";
      // }
  
      // if (!vehicleForm.other_expenses || isNaN(vehicleForm.other_expenses)) {
      //   newErrors.other_expenses = "Other expenses are required and should be a number.";
      // } else if (vehicleForm.other_expenses < 0) {
      //   newErrors.other_expenses = "Other expenses must be 0 or greater.";
      // }
  
      // if (!vehicleForm.total_cost || isNaN(vehicleForm.total_cost)) {
      //   newErrors.total_cost = "Total cost is required and should be a number.";
      // } else if (vehicleForm.total_cost <= 0) {
      //   newErrors.total_cost = "Total cost must be greater than 0.";
      // }

      // if(typeof(vehicleForm.invoice)==="string"){

      // }else{
      //   if (!vehicleForm.invoice) {
      //     newErrors.invoice = `RC is Required`;
      //   } else if (!filePattern.test(vehicleForm.invoice.name)) {
      //     newErrors.invoice = `Invoice should be a valid file format (PDF, JPG, or PNG).`;
      //   }
      // } 
    }else if(editValue === "vehicleDetails"){
      if (!vehicleForm.vehicle_number) {
        newErrors.vehicle_number = "Vehicle number is required.";
      } else if (!numberPattern.test(vehicleForm.vehicle_number)) {
        newErrors.vehicle_number = "Vehicle number should only contain Capital letters, numbers, spaces, or dashes.";
      }
  
      if (!vehicleForm.vehicle_type) {
        newErrors.vehicle_type = "Vehicle type is required.";
      }
  
      if (!vehicleForm.model) {
        newErrors.model = "Model is required.";
      }
  
      if (!vehicleForm.manufacturer) {
        newErrors.manufacturer = "Manufacturer is required.";
      }
  
      if (!vehicleForm.year_of_manufacture) {
        newErrors.year_of_manufacture = "Year of manufacture is required.";
      } else if (!yearPattern.test(vehicleForm.year_of_manufacture)) {
        newErrors.year_of_manufacture = "Year of manufacture should be a valid 4-digit year.";
      }
  
      //  if(typeof(vehicleForm.registration_certificate)==="string"){

      //  }else{
      //   if (!vehicleForm.registration_certificate) {
      //     newErrors.registration_certificate = `RC is Required`;
      //   } else if (!filePattern.test(vehicleForm.registration_certificate.name)) {
      //     newErrors.registration_certificate = `RC should be a valid file format (PDF, JPG, or PNG).`;
      //   }
      //  }
    }else if(editValue === "editMandata"){
      // newErrors.mandates = vehicleForm.mandates.map((mandate, index) => {
      //   const mandateErrors = {};
      //   if (!mandate.expense_type) {
      //     mandateErrors.expense_type = `Expense type is required for mandate ${index + 1}.`;
      //   }
  
      //   if (!mandate.amount_paid || isNaN(mandate.amount_paid)) {
      //     mandateErrors.amount_paid = `Amount paid is required for mandate ${index + 1} and should be a number.`;
      //   } else if (mandate.amount_paid <= 0) {
      //     mandateErrors.amount_paid = `Amount paid for mandate ${index + 1} must be greater than 0.`;
      //   }
  
      //   if (!mandate.expense_date) {
      //     mandateErrors.expense_date = `Expense date is required for mandate ${index + 1}.`;
      //   } else if (isNaN(new Date(mandate.expense_date).getTime())) {
      //     mandateErrors.expense_date = `Expense date for mandate ${index + 1} should be a valid date.`;
      //   }
  
      //   const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
      //   if (!mandate.upload_certificate) {
      //     mandateErrors.upload_certificate = `Upload certificate is required for mandate ${index + 1}.`;
      //   } else if (!filePattern.test(mandate.upload_certificate.name)) {
      //     mandateErrors.upload_certificate = `Upload certificate for mandate ${index + 1} should be a valid file format (PDF, JPG, or PNG).`;
      //   }
      // // Only add mandate errors to the newErrors.mandates array if there are any errors
      // if (Object.keys(mandateErrors).length > 0) {
      //   hasMandateErrors = true;
      //   return mandateErrors;
      // }

      // return null; 
      // }).filter(Boolean);
      // hasMandateErrors = newErrors.mandates.length > 0;
      // if(!hasMandateErrors){
      //   delete newErrors.mandates
      // }
    }
    
    console.log("newErrors", newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !hasMandateErrors;
  };

  const handleChange = (e) => {
    let { name, value, type, files } = e.target;
    if(type==="radio"){
      const bool= value==="true"? true : value === "false"? false: null;
      value=bool
    }
    setVehicleForm((prevForm)=>({
      ...prevForm,
      [name]:type === "file" ? files[0] : value
    }))
  };

  const handleMandateChange = (index, e) => {
    const { name, value, files, type } = e.target;
    const fieldName = name.split(".")[1];
    const updatedMandates = vehicleForm.mandates.map((mandate, i) => (
      i === index ? { ...mandate, [fieldName]:type=== "file"? files[0]: value } : mandate
    ));
    setVehicleForm({ ...vehicleForm, mandates: updatedMandates });
  };

  const addMandate = () => {
    setVehicleForm((prevForm) => ({
      ...prevForm,
      mandates: [
        ...prevForm.mandates,
        {
          expense_type: "",
          amount_paid: '',
          expense_date: '',
          upload_certificate: null
        }
      ]
    }));
  };

  useEffect(() => {
    if (editValue) {
      switch (editValue) {
        case "vehicleValue":
          setName("Edit Vehicle Vlaue");
          break;
        case "vehicleDetails":
          setName("Edit Vehicle Details");
          break;
        case "vehiclePurchaseDetails":
          setName("Edit Purchase Details");
          break;
        default:
          break;
      }
    }
  }, [editValue]);
  const toggleAssetType = (type) => {
    setAssetType(type);
  };


  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };

    // Generate the options array for years from 2005 to 2031
    const yearOptions = Array.from({ length: 2031 - 2005 + 1 }, (_, index) => {
      const year = 2005 + index;
      return { value: year.toString(), label: year.toString() };
    });
  
    // Add a default "Select an Option" entry at the beginning
    yearOptions.unshift({ value: "", label: "Select an Option" });

  const getContent = () => {
    switch (editValue) {
      case "vehicleValue":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[1rem] px-[1rem] py-[0.7rem]">
              <div>
                <label className="input-label text-[0.75rem]">
                  Type of Asset
                </label>
                <div className="p-1 flex items-center border rounded-md overflow-hidden bg-white w-fit">
                  <button
                    onClick={() => toggleAssetType("active")}
                    className={`px-2 py-1.5 text-[0.8rem] font-medium ${
                      assetType === "active"
                        ? "bg-gray-800 text-white"
                        : "bg-white text-gray-500"
                    } rounded-md transition-all`}
                    style={{ minWidth: "100px" }} // ensures both buttons have a consistent width
                  >
                    Active Asset
                  </button>
                  <button
                    onClick={() => toggleAssetType("idle")}
                    className={`px-2 py-1.5 text-[0.8rem] font-medium ${
                      assetType === "idle"
                        ? "bg-gray-800 text-white"
                        : "bg-white text-gray-500"
                    } rounded-md transition-all`}
                    style={{ minWidth: "100px" }} // ensures both buttons have a consistent width
                  >
                    Idle Asset
                  </button>
                </div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  {assetType === "active"
                    ? "This asset is generating income."
                    : "This asset is idle and not generating income."}
                </label>
              </div>
            </div>
            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] mb-3`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Vehicle from the Assets?
              </h4>
              <h4 className="text-[0.7rem] font-[500] text-lightGray">
                This action is permanent and may affect your financial records.
              </h4>
              <div className="w-[20%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove`}
                  px={"px-[0.4rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        );
      case "vehicleDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] px-3 py-3 mt-[1rem] mb-[1rem]">
              <div className="grid grid-cols-12 gap-3 px-3 py-1">
                <div className="input-container group col-span-6">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Vehicle Number
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.vehicle_number}
                    onChange={handleChange}
                    name="vehicle_number"
                    placeholder="Enter Vehicle No."
                  />
                  {errors.vehicle_number && <p className="text-red-500 text-[0.75rem]">{errors.vehicle_number}</p>}
                </div>
                <div className="col-span-4 flex items-end">
                  <Button
                    name={"Get Details"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Vehicle Type
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "car", label: "Car" },
                      { value: "bike", label: "Bike" },
                      { value: "truck", label: "Truck" },
                    ]}
                    name="vehicle_type"
                    value={vehicleForm.vehicle_type}
                    onChange={handleChange}
                  />
                  {errors.vehicle_type && <p className="text-red-500 text-[0.75rem]">{errors.vehicle_type}</p>}
                </div>

                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Model
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.model}
                    onChange={handleChange}
                    name="model"
                    placeholder="Model"
                  />
                  {errors.model && <p className="text-red-500 text-[0.75rem]">{errors.model}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Manufacturer
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.manufacturer}
                    onChange={handleChange}
                    name="manufacturer"
                    placeholder="Manufacturer"
                  />
                  {errors.manufacturer && <p className="text-red-500 text-[0.75rem]">{errors.manufacturer}</p>}
                </div>
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Year of Manufacture
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={yearOptions}
                    name="year_of_manufacture"
                    value={vehicleForm.year_of_manufacture}
                    onChange={handleChange}
                  />
                  {errors.year_of_manufacture && <p className="text-red-500 text-[0.75rem]">{errors.year_of_manufacture}</p>}
                </div>
              </div>
            </div>
            <div className="w-[50%]">
            <WhiteButton
              name="Upload Registration Certificate"
              px={"px-[0.6rem]"}
              py={"py-[0.4rem]"}
              text={"text-[0.75rem]"}
              mt={"mt-[12px]"}
              onClick={()=>document.getElementById("file-upload").click()}
            />
              {errors.registration_certificate && <p className="text-red-500 text-[0.75rem]">{errors.registration_certificate}</p>}
            </div>
            <InputField 
              id={`file-upload`}
              type="file"
              onChange={handleChange}
              className="hidden"
              name="registration_certificate"
            />
          </>
        );
      case "vehiclePurchaseDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                Purchase Details
              </h4>
              <div className="grid grid-cols-12 mt-1.5">
                <div className="input-container w-[100%] group col-span-6 gap-3">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Date
                  </label>
                  <InputField
                    type="date"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.purchase_date}
                    name="purchase_date"
                    onChange={handleChange}
                  />
                  {errors.purchase_date && <p className="text-red-500 text-[0.75rem]">{errors.purchase_date}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mt-2">
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Price
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      name="purchase_price"
                      value={vehicleForm.purchase_price}
                      onChange={handleChange}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors.purchase_price && <p className="text-red-500 text-[0.75rem]">{errors.purchase_price}</p>}
                </div>

                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Registration Fee
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="registration_fee"
                        value={vehicleForm.registration_fee}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.registration_fee && <p className="text-red-500 text-[0.75rem]">{errors.registration_fee}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mt-1.5">
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Road Tax
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      name="road_tax"
                      value={vehicleForm.road_tax}
                      onChange={handleChange}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors.road_tax && <p className="text-red-500 text-[0.75rem]">{errors.road_tax}</p>}
                </div>

                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Other Expenses
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="other_expenses"
                        value={vehicleForm.other_expenses}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.other_expenses && <p className="text-red-500 text-[0.75rem]">{errors.other_expenses}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mt-1.5">
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Total Cost
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="total_cost"
                        value={vehicleForm.total_cost}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.total_cost && <p className="text-red-500 text-[0.75rem]">{errors.total_cost}</p>}
                </div>
                <div className="col-span-4">
                <WhiteButton
                      name="Upload Invoice"
                      px={"px-[0.6rem]"}
                      py={"py-[0.52rem]"}
                      text={"text-[0.9rem]"}
                      mt={"mt-[1.5rem]"}
                      border={"!border-[1px] !border-[#94A3B8]"}
                      onClick={()=>document.getElementById("file-upload-invoice").click()}
                    />
                    {errors.invoice && <p className="text-red-500 text-[0.75rem]">{errors.invoice}</p>}
                    <InputField 
                      id={`file-upload-invoice`}
                      type="file"
                      onChange={handleChange}
                      className="hidden"
                      name="invoice"
                    />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div className="input-container mt-[0.6rem]">
                  <label className="input-label">
                    Insurance taken for this Vehicle?
                  </label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label cursor-pointer focus-within:text-customPurple">
                    <InputField
                      type="radio"
                      name="insurance_taken"
                      value={true}
                      checked={vehicleForm.insurance_taken===true}
                      onChange={handleChange}
                      className="mr-2 custom-radio cursor-pointer"
                    />
                      Yes
                    </label>
                    <label className="input-label cursor-pointer focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        name="insurance_taken"
                        value={false}
                        checked={vehicleForm.insurance_taken===false}
                        onChange={handleChange}
                        className="mr-2 custom-radio cursor-pointer"
                      />
                      No
                    </label>
                  </div>
                </div>
                <div className="input-container mt-[0.6rem]">
                  <label className="input-label">
                    Loan taken for this Vehicle?
                  </label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label cursor-pointer focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        value={true}
                        name="loan_taken"
                        checked={vehicleForm.loan_taken===true}
                        onChange={handleChange}
                        className="mr-2 custom-radio cursor-pointer"
                      />
                      Yes
                    </label>
                    <label className="input-label cursor-pointer focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        value={false}
                        name="loan_taken"
                        checked={vehicleForm.loan_taken===false}
                        onChange={handleChange}
                        className="mr-2 custom-radio cursor-pointer"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" onClick={()=>setIsClose(false)} />
        }
        heading={name}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} onClick={handleSubmit} />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
      <RemoveAsset
        heading="Vehicle"
        assetId={vehicleForm.asset}
        deleteId={`${vehicleForm.asset}/?id=${vehicleForm.id}&model_type=vehicle`}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditVehicle;
