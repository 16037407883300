import React from "react";
import tableAndChairs from "assets/images/blogs/tableAndChairs.svg";
import arrowUpRight from "assets/images/blogs/arrow-up-right.svg";
import meetingImg from "assets/images/blogs/meeting.svg";
import monitorImg from "assets/images/blogs/monitor.svg";

function RecentBlogs() {
  return (
    <div className=" bg-gray-100 p-[6%] min-h-fit grid grid-cols-12 gap-[4%]">
      <h5 className=" col-span-12">Recent blog posts</h5>
      <div className="md:col-span-6 col-span-12 lg:col-span-6">
        <img src={tableAndChairs} alt="" className=" w-[100%]" />
        <div className="grid grid-cols-12 gap-2 pt-[5%]">
          <h5 className="font-[700] text-[1.3rem] leading-[1.8rem] col-span-11">
            Business Registration: A Comprehensive Guide for Entrepreneurs
          </h5>
          <img
            className=" col-start-12 col-span-1 pl-[40%]"
            src={arrowUpRight}
            alt=""
          />
          <p className="font-[500] text-[.8rem] leading-[1.5rem] col-span-11 text-gray-600">
            Starting a business is an exciting journey, but it involves more
            than just having a great idea.
          </p>
          <p className=" col-span-8 md:col-span-7 lg:col-span-5">
            <span className=" p-2 rounded-2xl text-purple-800 font-semibold bg-purple-100">
              Business Registration
            </span>
          </p>
          <p className=" col-span-6 md:col-span-6 lg:col-span-5">
            <span className=" p-2 rounded-2xl text-blue-800 font-semibold bg-blue-100">
              Entrepreneurship
            </span>
          </p>
          <p className=" col-span-6 md:col-span-6 lg:col-span-5">
            <span className=" p-2 rounded-2xl text-pink-800 font-semibold bg-pink-100">
              {" "}
              Small Business
            </span>
          </p>
        </div>
      </div>
      <div className=" md:col-span-6 col-span-12 lg:col-span-6">
        <div className="flex flex-col gap-12">
          <div className="grid grid-cols-12 gap-4">
            <img src={meetingImg} alt="" className="md:col-span-6 col-span-12 lg:col-span-6" />
            <div className=" md:col-span-6 col-span-12 lg:col-span-6">
              <h6 className="font-[700] text-[1.3rem] leading-[1.8rem]">A Guide to Income Tax Audit and ITR Filing</h6>
              <p className="text-gray-600 font-light">
                Filing income tax returns (ITR) can be a daunting task,
                especially when it involves audits.
              </p>
              <span className=" py-1 px-3 rounded-2xl text-blue-800 font-semibold bg-blue-100">
                Tax
              </span>
              <span className=" py-1 px-3 rounded-2xl text-pink-800 font-semibold bg-pink-100">
                {" "}
                Audit
              </span>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <img src={monitorImg} alt="" className=" md:col-span-6 col-span-12 lg:col-span-6" />
            <div className="md:col-span-6 col-span-12 lg:col-span-6">
              <h6 className="font-[700] text-[1.3rem] leading-[1.8rem]">A Guide to Income Tax Audit and ITR Filing</h6>
              <p className="text-gray-600 font-light">
                Filing income tax returns (ITR) can be a daunting task,
                especially when it involves audits.
              </p>
              <span className=" py-1 px-3 rounded-2xl text-green-800 font-semibold bg-green-100">
                ITR
              </span>
              <span className=" py-1 px-3 rounded-2xl text-pink-800 font-semibold bg-pink-100">
                {" "}
                E-filing
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentBlogs;
