import React from "react";

function RedButton(props) {
  return (
    <div>
      <button
        className={`flex w-[100%] items-center whitespace-nowrap bg-[#FEF2F2] rounded border-1 border-[#FEE2E2] ${props.px} ${props.py}`}
        onClick={props.onClick}
      >
        <p className="text-center w-[100%] text-[#DC2626] text-[0.9rem] font-[600] m-0">{props.name}</p>
      </button>
    </div>
  );
}

export default RedButton;
