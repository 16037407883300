import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import info1 from "assets/images/incomeTax/info1.svg";
import EditVehicle from "./Edit/EditVehicle";
import { useLocation } from "react-router-dom";
import useGetVehicleDetails from "../data/useGetVehicleDetails";

const VehiclesDetailedView = () => {
  const location = useLocation();
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const itemName = location.state?.itemName;
  const [isReload, setIsReload]= useState(false)
  const [detailData, setDetailData]=useState({})
  const [getVehicleData, getVehicleError, getVehicleIsLoading, getVehicle] =
  useGetVehicleDetails();

useEffect(() => {
  getVehicle(`${assetId}/?id=${itemId}`);
}, []);

useEffect(()=>{
if(isReload){
  getVehicle(`${assetId}/?id=${itemId}`);
  setIsReload(false)
}
},[isReload])
useEffect(()=>{
  if(getVehicleData?.data){
    setDetailData(getVehicleData?.data)
  }
},[getVehicleData])

const handleViewInvoice =()=>{
  window.open(detailData.invoice, "_blank")
}

const handleViewRc=()=>{
  window.open(detailData.registration_certificate, "_blank")
}
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Personal Asset"} asset_type={"Vehicle"} asset_detail={itemName} assetId={assetId}/>
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between items-center">
          <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            Vehicle Value
          </h4>
          {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
          <EditVehicle editValue="vehicleValue" setIsReload={setIsReload} detailData={detailData}/>
        </div>
        <div className="flex justify-between mt-[1rem]">
          <div className="">
            <h4 className="text-customPurple font-[500] text-[1.7rem] ">
              ₹ {detailData?.total_cost} 
            </h4>
            <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
              <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
                {detailData?.assets_ratio} % of Total Assets (₹ {detailData?.total_assets_value})
              </h4>
            </div>
          </div>

          <div className="bg-[#F8FAFC] border rounded-md w-[20%]">
            <div className="p-[1rem]">
              <h4 className="text-lightGray font-[500] text-[0.8rem]">
                Asset Status
              </h4>
              <div className="flex gap-1">
                <p className="text-darkGray font-[500] text-[1.1rem] m-0">
                  {detailData?.asset_type === "active" ? "Active Asset" : "Idle Asset"}
                </p>
                <img src={info1} alt="info" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
        <div className="border bg-white rounded-xl p-[1rem] w-[46.5%]">
          <div className="flex justify-between">
            <div>
              <p className="m-0 text-[#334155] font-[500]">Purchase Details</p>
            </div>
            <div className="flex gap-2.5">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View Invoice"
                onClick={handleViewInvoice}
              />
              <EditVehicle editValue="vehiclePurchaseDetails" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="flex gap-[10.7rem] mt-2.5">
            <div className="w-[28%]">
              <p className="text-[#9065B4] m-0 font-[500] text-[0.8rem]">
                Total Cost
              </p>
              <p className="text-[#9065B4] m-0 text-[1.2rem] font-[500]">
                {detailData.total_cost} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Purchase Date
              </p>
              <p className="text-[#64748B] m-0 text-[1.2rem] font-[500]">
                {detailData.purchase_date}
              </p>
            </div>
          </div>
          <div className="flex gap-[10.7rem] mt-2.5">
            <div className="w-[28%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Insurance taken?
              </p>
              <p className="text-[#64748B] m-0 text-[1.2rem] font-[500]">{detailData.insurance_taken ? "Yes":"No"}</p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Loan taken?
              </p>
              <p className="text-[#64748B] m-0 text-[1.2rem] font-[500]">{detailData.loan_taken ? "Yes":"No"}</p>
            </div>
          </div>
          <div className="flex gap-[10.7rem] mt-2.5">
            <div className="w-[28%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Road Tax
              </p>
              <p className="text-[#64748B] m-0 text-[1.2rem] font-[500]">
                {detailData.road_tax} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Registration Fee
              </p>
              <p className="text-[#64748B] m-0 text-[1.2rem] font-[500]">
                {detailData.registration_fee} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
          </div>
          <div className="flex gap-[10.7rem] mt-2.5">
            <div className="w-[28%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Other Expenses
              </p>
              <p className="text-[#64748B] m-0 text-[1.2rem] font-[500]">
                {detailData.other_expenses} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
          </div>
        </div>
        <div className="border bg-white rounded-xl p-[1rem] w-[52%] h-[180px]">
          <div className="flex justify-between">
            <div>
              <p className="m-0 text-[#334155] font-[500]">Vehicle Details</p>
            </div>
            <div className="flex gap-2.5">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View RC"
                onClick={handleViewRc}
              />
              <EditVehicle editValue="vehicleDetails" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="mt-2.5">
            <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
              Vehicle Number
            </p>
            <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
              {detailData.vehicle_number}
            </p>
          </div>
          <div className="flex gap-2.5 mt-2.5">
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Type
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">{detailData.vehicle_type}</p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Manufacturer
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.manufacturer}
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Model
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.model}
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Year of Manufacture
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.year_of_manufacture}
              </p>
            </div>
          </div>
        </div>
        </div>
        <div className="mt-3 rounded-lg border">
          <table className="min-w-full border-collapse">
             <thead className="text-[#64748B] rounded-tl-lg">
                <tr>
                <th className="p-2.5 border-r border-b rounded-tl-lg bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                 Opening Balance
                 </th>
                 <th className="p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                 Value after Asset Depreciation as per 
                 the IT rules
                 </th>
                 <th className="p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                 Depreciation as per this year
                 </th>
                 <th className="p-2.5 border-b bg-[#F8FAFC] rounded-tr-lg font-[500] text-[0.8rem] w-[27%]">
                 Balance Amount
                 </th>
                </tr>
             </thead>
             <tbody>
                  <tr>
                    <td className="p-2.5 border-r text-[#334155] font-[500]">
                    30,00,000 Rs.
                    </td>
                    <td className="p-2.5 border-r text-[#334155] font-[500]">
                    5,00,000 Rs.
                    </td>
                    <td className="p-2.5 border-r text-[#334155] font-[500]">
                      15 %
                    </td>
                    <td className="p-2.5 text-[#334155] font-[500]">
                    25,00,000 Rs.
                    </td>
                  </tr>
             </tbody>
          </table>
        </div>
      </div>
      <div className="mt-3">
        <button className="text-[#334155] bg-[#F1F5F9] px-3.5 py-2 rounded-md font-[600] text-[0.9rem]">
          Back To Assets
        </button>
      </div>
    </div>
  );
};

export default VehiclesDetailedView;
