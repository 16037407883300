import React, { useState, useContext, useEffect } from "react";
import useGetUser from "containers/services/Data/useGetUserDetails";
import { myContext } from "App";
import profilePic from "assets/images/dashboard/profilePiv.svg";
import notification from "assets/images/dashboard/notification.svg";
import { Link } from "react-router-dom";
import docLogo from "assets/images/orders/base.svg";
import amtSpentLogo from "assets/images/orders/amountSpent.svg";
import useGetOrderDetails from "containers/privateRoutes/Orders/data/useGetOrderDetails";
import UploadDialog from "containers/privateRoutes/OrderPage/UploadDialog";
import prev from "assets/images/blogs/prev.svg";
import next from "assets/images/blogs/naxt.svg";
import PayDialog from "../Dashboard/PayDialog";
import useGetSummaryDetails from "./data/useGetSummary";
import useGetDownload from "./data/useGetDownloadDoc";
import ConfirmDialog from "./ConfirmationDialog";
import available from "assets/images/orders/available.svg";
import inprocess from "assets/images/orders/inprocess.svg";
import cancelled from "assets/images/orders/canceled.svg";
import useGetUserBasic from "containers/privateRoutes/Dashboard/data/getUserbasicDetails";
import MobileUploadDrawer from "../OrderPage/MobileUploadDrawer";
import MobilePayDrawer from "../Dashboard/MobilePayDrawer";
import uploadImg from "assets/images/dashboard/upload.svg";

function OrdersOverView() {
  const statusColor = [available, inprocess, cancelled];

  const [editClicked, setEditClicked] = useState(false);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [state, setState] = useState("");
  const [workOrderId, setWorkOrderId] = useState("");

  const [requiredDocs, setRequiredDocs] = useState([]);
  const [totalServices, setTotalServices] = useState(null);
  const noOfServicesInPage = 8;
  const totalPages = Math.ceil(totalServices / noOfServicesInPage);
  const pages = [...Array(totalPages + 1).keys()].slice(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesArr, setServicesArr] = useState([]);
  const [inprocessCount, setInprocessCount] = useState(null);
  const [downloadCount, setDownloadCount] = useState(null);
  const [amountPaid, setAmountPaid] = useState(null);
  const [downloadClick, setDownloadClick] = useState(false);
  // console.log(pages)
  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const [openDilog, setOpenDilog] = useState(false)
  const [openWorkOrderId, setOpenWorkOrderId] = useState(null);
  const cardDetails = [
    { Ques: "Document Ready", ans: downloadCount, img: docLogo },
    { Ques: "No of Service inprocess", ans: inprocessCount, img: docLogo },
    { Ques: "Amount Spent", ans: `₹${amountPaid}`, img: amtSpentLogo },
  ];
  const [
    downloadData,
    downloadError,
    downloadIsLoading,
    getDownload,
    setSuccessDownloadData,
    setDownloadError,
  ] = useGetDownload();

  const [
    UserData,
    userError,
    userIsLoading,
    getUser,
    setSuccessUserData,
    setUserError,
  ] = useGetUser();

  useEffect(() => {
    if (isLoggedIn) {
      getUserBasic();
    }
  }, [isLoggedIn]);

  // const downloadClicked = ()=>{ }

  useEffect(() => {
    if (downloadClick) {
      getDownload({ woId: workOrderId });
    }
  }, [downloadClick]);

  if (downloadData) {
    console.log(downloadData.data.document_url);
  }

  const [
    UserBasicData,
    userBasicError,
    userBasicIsLoading,
    getUserBasic,
    setSuccessUserBasicData,
    setUserBasicError,
  ] = useGetUserBasic();
  const [showPP, setShopPP] = useState(false);
  const [firstLetter, setFirstLetter] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [businessName, setBusinessName] = useState("");

  useEffect(() => {
    if (UserBasicData) {
      // console.log(UserBasicData.data);
      setName(
        UserBasicData.data.full_name
          .split(" ")
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.substr(1)?.toLowerCase()
          )
          ?.join(" ")
      );
      setFirstLetter(UserBasicData.data.profile_title);
      setProfilePic(UserBasicData.data.profile_picture);
      setBusinessName(
        UserBasicData?.data?.business_name
          .split(" ")
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.substr(1)?.toLowerCase()
          )
          ?.join(" ")
      );

      if (UserBasicData.data.profile_picture?.length > 0) {
        setShopPP(true);
      }
    }
  }, [UserBasicData]);
  const [
    summaryData,
    summaryError,
    summaryIsLoading,
    getSummaryDetails,
    setSuccessSummaryData,
    setsummaryError,
  ] = useGetSummaryDetails();

  const [
    orderData,
    orderError,
    orderIsLoading,
    getOrderDetails,
    setSuccessOrderData,
    setOrderError,
  ] = useGetOrderDetails();


  useEffect(() => {
    if (isLoggedIn) {
      getOrderDetails({ page_size: noOfServicesInPage, page: currentPage });
      getSummaryDetails();
      console.log(
        "getOrderDetails:",
        getOrderDetails({ page_size: noOfServicesInPage, page: currentPage })
      );
    }
  }, [isLoggedIn, currentPage]);

  // console.log("Summary data:", summaryData)

  useEffect(() => {
    if (orderData) {
      setServicesArr(orderData.data.results);
      // console.log("orders view :",orderData.data.results )
      // console.log("order view",orderData.data.results[0].required_documents_list);
      // console.log(orderData.data.results);
      // setRequiredDocs();
      setTotalServices(orderData.data.total_count);
      // console.log(orderData.data.total_count)
    }
  }, [orderData]);
  useEffect(() => {
    if (summaryData) {
      // console.log(summaryData.data)
      setInprocessCount(summaryData.data.inprocess_count);
      setDownloadCount(summaryData.data.download_count);

      if (summaryData.data.total_amount_paid.amount_paid__sum === null) {
        // console.log(summaryData.data.total_amount_paid.amount_paid__sum)
        setAmountPaid(0);
      } else {
        setAmountPaid(summaryData.data.total_amount_paid.amount_paid__sum);
      }
    }
  }, [summaryData]);

  const prevClicked = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextClicked = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickOpen=(id)=>{
      setOpenDilog(true)
      setOpenWorkOrderId(id)
  }

  return (
    <div className="relative">
       {orderIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <h5 className="font-[500] text-[1.4rem] leading-[2.7rem] ml-2">Orders</h5>
        <div className="wrapper grid grid-cols-12">
          {cardDetails.map((i) => {
            return (
              <div className="overviewCards flex p-[1.5rem] mr-2 my-2 justify-between h-28 bg-white-body rounded-xl md:col-span-3 col-span-6">
                <div className="flex flex-col justify-between">
                  <p className="font-[600] text-[0.9rem] leading-[1rem] text-gray-600">
                    {i.Ques}
                  </p>
                  <b className="font-[600] text-[1.5rem] text-gray-800 leading-[1.1rem]">
                    {i.ans}
                  </b>
                </div>
                <img className=" w-10" src={i.img} />
              </div>
            );
          })}
        </div>


      <div className="bg-white-body rounded-xl ">
        <div className=" w-[100%] grid grid-cols-12 mt-2">
          <table className=" col-span-12 ">
            {/* Non-sticky header for larger screens */}
            <tr className="hidden md:table-row bg-white-body">
              <th className="font-[500] text-[1.4rem] leading-[1.6rem] md:text-gray-400 whitespace-nowrap rounded-2xl p-[2%] text-[#344054]">
                Transactions
              </th>
            </tr> 
            {/* Sticky header for smaller screens */}
            <tr className="tableData col-span-12 bg-white-body sticky top-0 md:hidden">
              <th className="font-[600] text-[1.6rem] leading-[1.6rem] text-[#344054] whitespace-nowrap rounded-2xl p-[2%]">
                Transactions
              </th>
            </tr>
 
            <tr className=" bg-gray-400 headingRow col-span-12 hidden md:table-row">
              <th className="py-[2%] px-4   col-span-2">WO</th>
              <th className="py-[2%] px-4  col-span-2">SERVICE NAME</th>
              <th className="py-[2%] px-4  col-span-2">DATE</th>
              <th className="py-[2%] px-4  col-span-2">AMOUNT</th>
              <th className="py-[2%] px-4  col-span-2">STATUS</th>
            </tr>
              <>
                {" "}
                {orderData && (
                  <>
                    {/* transaction details for desktop  */}
                    {servicesArr.map((data, i) => {
                      return (
                        <>
                          {" "}
                          <tr className="tableData col-span-12 bg-white-body rounded-bl-2xl rounded-br-2xl hidden md:table-row">
                            <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4 rounded-bl-2xl col-span-2">
                              {data.id}
                            </td>
                            <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4  col-span-4 ">
                              {data.service_name}
                            </td>
                            <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4  col-span-2 ">
                              {" "}
                              {data.created_at}
                            </td>
                            <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4  col-span-2">
                              Rs {data.amount_paid}
                            </td>
                            
                            <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4 rounded-br-2xl col-span-2">

                              {data.status === 1 && <span>Requested</span>}
                              {data.status === 2 && (
                                <>
                                  <button onClick={()=>handleClickOpen(data.id)} className="flex gap-2 items-center ">
                                  <img src={uploadImg} alt="" />
                                  <span className="text-[#9065B4]">Upload</span>
                                </button>
                                      {  openDilog && openWorkOrderId === data.id &&
                                        <UploadDialog
                                        setOpenDilog={setOpenDilog}
                                        openDilog={openDilog}
                                        workOrderId={data.id}
                                        docList={data.required_documents_list}
                                      />
                                    }
                                </>
                              )}
                              {data.status === 3 && (
                                <>
                                  <div className="flex gap-2">
                                    <img src={statusColor[1]} />
                                    <span>In Process</span>
                                  </div>
                                </>
                              )}
                              {data.status === 4 && <PayDialog />}
                              {data.status === 5 && (
                                <a
                                  className="text-black no-underline"
                                  href={downloadData?.data?.document_url}
                                >
                                  <span
                                    onClick={() => {
                                      setDownloadClick(true);
                                      setWorkOrderId(data.id);
                                    }}
                                  >
                                    <ConfirmDialog 
                                      href={downloadData?.data?.document_url}
                                    />
                                  </span>
                                </a>
                              )}
                              {/* <button className="forBgcolor border-none p-2 rounded-lg   whitespace-nowrap  text-blue-600 text-sm">
                          <b>View Document</b>

                        </button> */}
                            </td>
                          </tr>
                        </>
                      );
                    })}

                    {/* for transaction details mobile view*/}
                    {servicesArr.map((data, i) => {
                      return (
                        <>
                          <div className="wrapper grid grid-cols-12 md:hidden">
                            <div className="overviewCards p-[1.5rem] mr-2 my-2 ml-2 justify-between  bg-white-body rounded-xl col-span-12 shadow">
                              <div className="grid grid-cols-12">
                                <p className="col-span-8 font-[600] text-[1rem] leading-[1rem] text-[#344054]">
                                  {data.service_name}
                                </p>
                                <p className="col-span-4 font-[600] text-[1rem] leading-[1rem] text-[#667085]">
                                  {data.created_at}
                                </p>
                              </div>

                              <p className="font-[400] text-[1rem] leading-[1rem] text-[#344054]">
                                {data.id}
                              </p>

                              <div className="grid grid-cols-12 justify-center items-center">
                                <p className="col-span-7 font-[700] text-[1.3rem] leading-[1rem] text-[#344054">
                                  {data.amount_paid}
                                </p>

                                <td className=" col-span-5 py-[1%] font-[600] text-[1rem] leading-[1.5rem] text-gray-800 px-4 rounded-br-2xl">
                                  {data.status === 1 && (
                                    <span className="">Requested</span>
                                  )}
                                  {data.status === 2 && (
                                    // <UploadDialog
                                    //   workOrderId={data.id}
                                    //   docList={data.required_documents_list}
                                    // />
                                    <MobileUploadDrawer
                                      workOrderId={data.id}
                                      docList={data.required_documents_list}
                                      serviceName={data.service_name}
                                      createdAt={data.created_at}
                                      amountPaid={data.amount_paid}
                                    />
                                  )}
                                  {data.status === 3 && (
                                    <>
                                      <div className="flex gap-2  ">
                                        <img src={statusColor[1]} />
                                        <span>In Process</span>
                                      </div>
                                    </>
                                  )}
                                  {data.status === 4 && (
                                    <MobilePayDrawer
                                      workOrderId={data.id}
                                      docList={data.required_documents_list}
                                      serviceName={data.service_name}
                                      createdAt={data.created_at}
                                      amountPaid={data.amount_paid}
                                    />
                                  )}
                                  {data.status === 5 && (
                                    <a
                                      className="text-black no-underline "
                                      href={downloadData?.data?.document_url}
                                    >
                                      <span
                                        onClick={() => {
                                          setDownloadClick(true);
                                          setWorkOrderId(data.id);
                                        }}
                                      >
                                        <ConfirmDialog
                                          href={
                                            downloadData?.data?.document_url
                                          }
                                        />
                                      </span>
                                    </a>
                                  )}
                                </td>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </>

            {/* <tr className='border-y-1 col-span-12  bg-white-body'>
<th className='py-1 px-4 rounded-tl-lg rounded-bl-lg  col-span-12' colSpan={6}>Showing 10 items</th>

</tr> */}
          </table>
        </div>
      </div>
      <div className=" py-[2%] flex justify-between mb-14">
        <div
          className="flex hover:cursor-pointer"
          onClick={() => {
            prevClicked();
          }}
        >
          <img src={prev} alt="" />
          <p className=" mb-0 font-semibold p-2">Previous</p>
        </div>
        <div>
         
        </div>
        <div
          className="flex hover:cursor-pointer"
          onClick={() => {
            nextClicked();
          }}
        >
          <p className=" mb-0 font-semibold p-2">Next</p>
          <img src={next} alt="" />
        </div>
      </div>
      {/* <div role="status" class="max-w-full animate-pulse">
    <div class="h-screen w-[100%] bg-gray-200 rounded dark:bg-gray-200  mb-4"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
    <span class="sr-only">Loading...</span>
</div> */}
    </div>
  );
}

export default OrdersOverView;
