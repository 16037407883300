import arUp from "assets/images/Serviceimages/arrowUp.svg";
import arDown from "assets/images/Serviceimages/arDown.svg";
import React, { useState } from "react";

const IncomeTaxServicesFaq = () => {
  const [showPara1, setShowPara1] = useState(true);
  const [showPara2, setShowPara2] = useState(false);
  const [showPara3, setShowPara3] = useState(false);
  const [showPara4, setShowPara4] = useState(false);
  const [showPara5, setShowPara5] = useState(false);

  const FaqDetails = {
    IncomeFaq: {
      heading: "Frequently Asked Questions",
      paraBelowH: `Find answers to common questions and concerns about our program.`,
      btn: "Contact Us",
      difference: {
        ques: ` What’s the difference between Udyog Aadhaar and Udyam
            Registration?`,
        ans: `Udyog Aadhaar is the old system; Udyam Registration is
            the new and improved one.`,
        img: arUp,
      },
      time: {
        ques: `How quickly can I get registered for Udyam Certificate?`,
        ans: `If you're all set with your documents,it can be same-day
            fast!`,
      },
      img: arDown,
      noAadhar: {
        ques: `I don't have an Aadhaar card, now what?`,
        ans: `You'll need to get one; it's an essential requirement.`,
      },
      moreBuss: {
        ques: `What if I have more than one business?`,
        ans: `Each business needs its own unique registration.`,
      },
      editForm: {
        ques: `Made a mistake on the form, can I fix it?`,
        ans: ` Absolutely, with the edit feature, you can make
            corrections.`,
      },
    },
  };

  return (
    <div className="slightDarkBlack grid grid-cols-12 p-[8%]">
      <div className="lg:col-span-6 md:col-span-6 col-span-12">
        <h2 className="font-[700] lg:text-[3rem] lg:leading-[3.5rem]  md:text-[3rem] md:leading-[3.5rem] text-[2rem] leading-[2.8rem]">
          {/* {FaqDetails.IncomeFaq.heading} */}
          Frequently Asked <span className="text-[#7E0CDE]">Questions</span>
        </h2>
        <p className=" font-[500] text-[1rem] leading-[1.3rem] text-gray-500">
          {FaqDetails.IncomeFaq.paraBelowH}
        </p>
        <button className="lg:flex bg-[#9065B4] text-white p-2 rounded-md px-5">
          {FaqDetails.IncomeFaq.btn}
        </button>
      </div>
      <div className="lg:col-span-6 md:col-span-6 col-span-12">
        <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
          <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
            {FaqDetails.IncomeFaq.difference.ques}
          </h5>

          <img
            id="style1"
            className={`${
              showPara1 ? "" : "transform -rotate-180 duration-200"
            } col-start-12`}
            onClick={() => {
              setShowPara1(!showPara1);
            }}
            src={FaqDetails.IncomeFaq.difference.img}
          />
          <br />

          {showPara1 && (
            <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
              {FaqDetails.IncomeFaq.difference.ans}
            </p>
          )}
        </div>

        <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
          <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
            {FaqDetails.IncomeFaq.time.ques}
          </h5>

          <img
            id="style2"
            className={`${
              showPara2 ? "transform -rotate-180 duration-200" : ""
            } col-start-12`}
            onClick={() => {
              setShowPara2(!showPara2);
            }}
            src={FaqDetails.IncomeFaq.img}
          />
          <br />

          {showPara2 && (
            <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
              {FaqDetails.IncomeFaq.time.ans}
            </p>
          )}
        </div>

        <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
          <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
            {FaqDetails.IncomeFaq.noAadhar.ques}
          </h5>

          <img
            id="style3"
            className={`${
              showPara3 ? "transform -rotate-180 duration-200" : ""
            } col-start-12`}
            onClick={() => {
              setShowPara3(!showPara3);
            }}
            src={FaqDetails.IncomeFaq.img}
          />
          <br />

          {showPara3 && (
            <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
              {FaqDetails.IncomeFaq.noAadhar.ans}
            </p>
          )}
        </div>

        <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
          <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
            {FaqDetails.IncomeFaq.moreBuss.ques}
          </h5>

          <img
            id="style4"
            className={`${
              showPara4 ? "transform -rotate-180 duration-200" : ""
            } col-start-12`}
            onClick={() => {
              setShowPara4(!showPara4);
            }}
            src={FaqDetails.IncomeFaq.img}
          />

          <br />
          {showPara4 && (
            <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
              {FaqDetails.IncomeFaq.moreBuss.ans}
            </p>
          )}
        </div>

        <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
          <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
            {FaqDetails.IncomeFaq.editForm.ques}
          </h5>

          <img
            id="style5"
            className={`${
              showPara5 ? "transform -rotate-180 duration-200" : ""
            } col-start-12`}
            onClick={() => {
              setShowPara5(!showPara5);
            }}
            src={FaqDetails.IncomeFaq.img}
          />

          <br />
          {showPara5 && (
            <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
              {FaqDetails.IncomeFaq.editForm.ans}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default IncomeTaxServicesFaq;
