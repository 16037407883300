import Button from "components/Button/BtnOrange";
import React from "react";
import titleImg from "assets/images/registration/Container.svg";
import mobImg from 'assets/images/registration/containerMob.svg'

function RegistrationTitle() {
  return (
    <>
      <div className="px-[6%] py-[10%] min-h-screen">
        <div className="md:px-[25%] lg:px-[25%]">
          <h2 className="lg:p-2 md:p-2 lg:pt-0 md:pt-0 pt-[25%] lg:text-center md:text-center font-[700] md:text-[3.6rem] md:leading-[4rem] lg:text-[3.6rem] lg:leading-[4rem] text-[2.3rem] leading-[3rem]">
            Registrations Service
          </h2>
          <p className="font-[500] text-[1rem] leading-[1.4rem] text-gray-500 lg:text-center md:text-center">
            Say goodbye to the chaos of traditional registration processes. At
            BeyondTax, we bring order to the registration journey, ensuring a
            seamless and organized experience every step of the way.
          </p>
          <div className="lg:flex md:flex w-[100%] lg:justify-center md:justify-center my-4">
           <a href="#registerPart" className="no-underline"><Button px={"px-[1rem]"} py={"py-[0.5rem]"} name="Contact Us" /></a> 
          </div>
        </div>
        <img src={titleImg} className="w-[100%] lg:block md:block hidden" alt="" />
        <img src={mobImg} className="w-[100%] lg:hidden md:hidden block" alt="" />
      </div>
    </>
  );
}

export default RegistrationTitle;
