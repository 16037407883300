import React, { useEffect } from "react";
import info_red from "assets/images/reports/info-red.png";
import trendUp from "assets/images/dashboard/TrendUp.svg";
import IncomeTaxInsightsChart from "./IncomeTaxInsightsChart";
import CreditScoreChart from "./CreditScoreChart";
import TaxWealthChart from "./TaxWealthChart";
import useGetDashboardContent from "./data/getDashboardContent";
// import data from "./data.json";

const DashboardContentNew = () => {
  const income_tax_return_id = JSON.parse(
    localStorage.getItem("user")
  )?.current_income_tax_return_id;

  const [getData, isError, isLoading, getDashboardData] =
    useGetDashboardContent();

  useEffect(() => {
    // getDashboardData(`?income_tax_return_id=${income_tax_return_id}`);
    getDashboardData();
  }, []);

  const data = getData?.data;

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="grid grid-cols-12 gap-[1rem]">
        {/* total assets */}
        <div className="col-span-4 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">
            Total Assets
          </h4>
          <h4 className="text-center text-darkGray font-[600] text-[1.8rem] mt-[1.8rem] mb-3">
            ₹{data?.total_assets_amount || 0}
          </h4>

          <h6 className="flex gap-[0.5rem] items-center justify-center text-center text-lightGray font-[400] text-[0.7rem]">
            Compared to last 6 months
            <span className="flex items-center gap-1 bg-[#F7FEE7] text-[#65A30D] p-1 rounded-md">
              <img src={trendUp} alt="" /> 12%
            </span>
          </h6>
        </div>

        {/* total liabilities */}
        <div className="col-span-4 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">
            Total Liabilities
          </h4>
          <h4 className="text-center text-darkGray font-[600] text-[1.8rem] mt-[1.8rem] mb-3">
            ₹{data?.total_liabilities_amount || 0}
          </h4>

          <h6 className="flex gap-[0.5rem] items-center justify-center text-center text-lightGray font-[400] text-[0.7rem]">
            Compared to last 6 months
            <span className="flex items-center gap-1 bg-[#F7FEE7] text-[#65A30D] p-1 rounded-md">
              <img src={trendUp} alt="" /> 12%
            </span>
          </h6>
        </div>

        {/* networth */}
        <div className="col-span-4 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">
            Networth Vs Total Income
          </h4>

          <div className="mt-[1rem] flex justify-between">
            <div>
              <h4 className=" text-darkGray font-[600] text-[1rem] ">
                ₹ {data?.net_worth || 0}
              </h4>
              <h4 className=" text-darkGray font-[600] text-[0.8rem]">
                Networth
              </h4>
              <h6 className=" text-lightGray font-[400] text-[0.6rem] m-0">
                As per values entered in
                <br /> Beyondtax
              </h6>
            </div>

            <div>
              <h4 className=" text-darkGray font-[600] text-[1rem] ">
                ₹{data?.total_income || 0}
              </h4>
              <h4 className=" text-darkGray font-[600] text-[0.8rem]">
                Total Income
              </h4>
              <h6 className=" text-lightGray font-[400] text-[0.6rem] m-0">
                (Reported as per Income <br /> Tax)
              </h6>
            </div>
          </div>

          <div className="flex gap-2 items-center bg-[#FEF2F2] rounded-md p-1 mt-[0.5rem]">
            <h6 className="flex items-center gap-1 text-[0.7rem] text-[#DC2626] m-0">
              <img src={info_red} alt="" className="w-3 h-3 ml-1" />
              Start Your Tax Planning
            </h6>
            <button className="text-[0.7rem] bg-customPurple text-white rounded-md p-[0.2rem]">
              Create Now
            </button>
          </div>
        </div>

        {/* income tax insights */}
        <div className="col-span-8 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">
            Income tax insights
          </h4>
          <IncomeTaxInsightsChart data={data} />
        </div>

        {/* Credit Score */}
        <div className="col-span-4 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">
            Credit Score
          </h4>
          <CreditScoreChart />
          <div className="mt-[1rem] grid grid-cols-2 text-lightGray ">
            <h6 className="text-[0.8rem] text-[10px]">
              <span className="mr-1">•</span>Excellent: 80 - 100
            </h6>
            <h6 className="text-[0.8rem] text-[10px]">
              <span className="mr-1">•</span>Good Score: 60 - 79
            </h6>
            <h6 className="text-[0.8rem] text-[10px]">
              <span className="mr-1">•</span>Fair Score: 40 - 59
            </h6>
            <h6 className="text-[0.8rem] text-[10px]">
              <span className="mr-1">•</span>Poor Score: Below 40
            </h6>
          </div>
        </div>

        {/* status */}
        <div className="col-span-5 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">Status</h4>

          <div className="flex justify-between mt-[2.2rem]">
            <div className="bg-[#FAF8FC] px-[1rem] py-[1.7rem] rounded-md shadow-md">
              <h4 className="font-[500] text-[1rem] text-lightGray">
                Refund <br /> Reissue Status
              </h4>
              <button className="text-[0.8rem] text-customPurple rounded-md bg-[#F3EFF8] py-1 px-[0.5rem] border mt-[1.7rem]">
                Raise Request
              </button>
            </div>

            <div className="bg-[#FAF8FC] px-[1rem] py-[1.7rem] rounded-md shadow-md">
              <h4 className="font-[500] text-[1rem] text-lightGray">
                E-Verification <br /> Status
              </h4>
              <button className="text-[0.8rem] text-customPurple rounded-md bg-[#F3EFF8] py-1 px-[0.5rem] border mt-[1.7rem]">
                Raise Request
              </button>
            </div>
          </div>
        </div>

        {/* tax and wealth tracker */}
        <div className="col-span-7 bg-white rounded-xl shadow-md p-[1rem]">
          <h4 className="font-[500] text-[1rem] text-lightGray">
            Tax and wealth tracker
          </h4>
          <TaxWealthChart data={data} />
        </div>
      </div>
    </>
  );
};

export default DashboardContentNew;
