import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const TaxWealthChart = ({data}) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  useEffect(() => {
    if(data){
      const years = data?.tax_and_wealth_tracker?.map((item) => {
        const yearRange = item.year;
        // const yearParts = yearRange.split(" - ");
        // const lastYear = yearParts[1].slice(-2);
        // return `20${lastYear}`;
        return yearRange;
      });

      const incomeData = data?.tax_and_wealth_tracker?.map(
        (item) => item.total_income
      );
      const netWorth = data?.tax_and_wealth_tracker?.map(
        (item) => item.net_worth
      );

      setChartData({
        labels: years,
        datasets: [
          {
            label: "Income Tax",
            data: incomeData, // Data points for Income Tax
            borderColor: "#FBBF24", // Yellow
            backgroundColor: "#FBBF24",
            tension: 0.1, // Smooth curves
          },
          {
            label: "Networth",
            data: netWorth, // Data points for Networth
            borderColor: "#06B6D4", // Blue
            backgroundColor: "#06B6D4",
            tension: 0.1, // Smooth curves
          },
        ],
      });
    }

    
  },[data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom", // Position of the legend
        labels: {
          usePointStyle: true, // Circle markers in the legend
          
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      y: {
        grid: {
          display: false, // Light gray grid lines
        },
        ticks: {
          // display: false,
          // stepSize: 10, // Increment of y-axis ticks
        },
      },
    },
  };

  return (
    <div className="max-w-4xl">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default TaxWealthChart;
