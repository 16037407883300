import React, { useEffect, useRef, useState } from "react";
import AddYourLiability from "../AddYourLiability";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { LIABILITIES_PERSONAL_LOAN_DETAILS } from "constants/routes";
import useGetBankingLoans from "../data/useGetBankingLoans";
import LiabilitiesHeading from "../LiabilitiesHeading";

// images
import personalLoanHeart from "assets/images/PersonalLoan/personalLoanHeart.svg";
import WhiteButton from "components/Button/WhiteButton";
import securedLoans from "assets/images/liabilities/securedLoans.svg";
import AddLoanTypeDialog from "../AddLoanTypeDialog";

const PersonalLoan = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const loan_type = location.state?.loan_type;
  const loanButtonRef = useRef(null);
  const [openLoanTypeDilog, setOpenLoanTypeDilog] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [getData, getError, isLoading, getBankingLoans] =
    useGetBankingLoans();

  useEffect(() => {
    getBankingLoans(id);
  }, []);

  const handleOpenLoanTypeDialog = (name) => {
    setOpenLoanTypeDilog(true);
    loanButtonRef.current.click();
    setSelectedLoan(name);
  };

  return (
    <>
    {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
    <div className="pb-[6rem] px-[1rem] bg-gray-100">
      <LiabilitiesHeading loan_type={loan_type} />

      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Liabilities Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">₹{getData?.data[0]?.total_loan_value || 0}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Liabilities
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getData?.data[0]?.total_liabilities || 0}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Active Liabilities
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getData?.data[0]?.active_liabilities_count || 0}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Closed Liabilities
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getData?.data[0]?.closed_liabilities_count || 0}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddYourLiability />
        </div>
      </div>

      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between items-center">
          <div className="flex gap-[1rem]">
            <h4 className="text-darkGray text-[1rem] font-[600]">
              Bank Loans
            </h4>
            <h4 className="text-[#DC2626] font-[500] text-[1rem] m-0 py-[0.2rem] px-[0.3rem] bg-[#FEF2F2] rounded">
              {getData?.data[0]?.total_loan_value} Rs.
            </h4>
          </div>
          <WhiteButton
            px={"px-[0.8rem]"}
            py={"py-[0.3rem]"}
            name="Add New"
            onClick={() => handleOpenLoanTypeDialog(loan_type)}
          />
        </div>

        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {getData?.data?.length > 0 ? (
            getData?.data?.map((item, index) => (
              <div key={index}>
                <Link
                  className="no-underline"
                  to={`${LIABILITIES_PERSONAL_LOAN_DETAILS}?id=${id}/?loan_id=${item.id}`}
                  state={{ loan_type: loan_type, loanTypeID: id }}
                  key={index}
                >
                  <div className="rounded-md border p-[0.8rem] bg-[#FEF2F2]">
                    <img src={securedLoans} alt="heart" className="w-6 h-6" />
                    <h5 className="text-lightGray font-[500] text-[0.9rem] mt-2">
                      {item.bank_name}
                    </h5>
                    <div className="flex justify-end mt-[1.2rem]">
                      <h4 className="text-darkGray font-[600] text-[1.3rem]">
                        ₹ {item.amount_borrowed}
                      </h4>
                    </div>{" "}
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={personalLoanHeart} alt="heart" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Personal Loan
              </h5>
              <div className="flex justify-end mt-[2.5rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      <AddLoanTypeDialog
        loanType={selectedLoan}
        loanTypeId={id}
        triggerElement={
          <button ref={loanButtonRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
    </div>
    </>
  );
};

export default PersonalLoan;
