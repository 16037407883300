import React from "react";
import { useParams } from "react-router-dom";
import LoanDetailView from "./LoanDetailView";
import IntellectualDetailView from "./IntellectualDetailView";
import DomainDetailView from "./DomainDetailView";

const OtherAssetDetailedView = () => {
  const { other_asset_type } = useParams();

  let displayText = "";

  switch (other_asset_type) {
    case "LoansToFamilyFriends":
      displayText = <LoanDetailView />;
      break;
    case "IntellectualProperty":
      displayText = <IntellectualDetailView />;
      break;
    case "Domain":
      displayText = <DomainDetailView />;
      break;
    default:
      displayText = "Unknown";
  }

  return <div>{displayText}</div>;
};

export default OtherAssetDetailedView;
