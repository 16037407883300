import React, { useState } from "react";
import edit_icon from "assets/images/incomeTax/edit-profile.svg";
import eye from "assets/images/incomeTax/eye.svg";
import plus from "assets/images/incomeTax/plus.svg";
import editPen from "assets/images/incomeTax/edit-3.svg";
import CustomizableDrawer from "../Reports/Drawer";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PAGE_URL } from "constants/routes";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";

const BusinessProfile = () => {
  const navigate = useNavigate();
  const [basicDetails, setBasicDetails] = useState(false);
  const [kycDetails, setKycDetails] = useState(false);
  const [addressDetails, setAddressDetails] = useState(false);
  const [mobileAddressExpand, setMobileAddressExpand] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState(false);
  const [emailDetails, setEmailDetails] = useState(false);
  const [mobileBasicExpand, setMobileBasicExpand] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(0);
  const [mobileKycExpand, setMobileKycExpand] = useState(false);
  const [mobileAccountExpand, setMobileAccountExpand] = useState(false);
  const [showPicView, setShowPicView] = useState(null);

  const openingDrawer = (val) => {
    setOpenDrawer(val);
  };

  const changingToViewDrawer = () => {
    setAddressDetails(false);
    setBasicDetails(false);
    setEmailDetails(false);
    setPhoneDetails(false);
    setPasswordDetails(false);
    setKycDetails(false);
  };

  const changingToEdit = () => {
    setBasicDetails(!basicDetails);
  };

  const changingToEditKyc = () => {
    setKycDetails(!kycDetails);
  };

  const changingToEditAddress = () => {
    setAddressDetails(!addressDetails);
  };

  const changeToEditPass = () => {
    setPasswordDetails(!passwordDetails);
  };

  const changeToEditPhone = () => {
    setPhoneDetails(!phoneDetails);
  };

  const changeToEditEmail = () => {
    setEmailDetails(!emailDetails);
  };

  const expandingBasicDetailsView = () => {
    setMobileBasicExpand(true);
  };

  const expandingKycDetailsView = () => {
    setMobileKycExpand(true);
  };
  const expandingAdressDetailsView = () => {
    setMobileAddressExpand(true);
  };

  const expandingAccountDetailsView = () => {
    setMobileAccountExpand(true);
  };

  const handlePrevious = () => {
    navigate(DASHBOARD_PAGE_URL);
  };

  return (
    <div className="grid grid-cols-12 relative">
      <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%]  md:px-4 px-3">
        
        <div className="py-[2.2rem] gap-[1rem] items-center md:flex hidden">
          <button onClick={handlePrevious}>
            <img src={previousBtn} alt="" className="w- h-" />
          </button>
          <h4 className="text-[#334155] text-[1.4rem] font-[600] m-0">
            Business Profile
          </h4>
        </div>
        {basicDetails ? (
          <>
            <div className="hidden md:block">
              <EditBasicDetails changingToEdit={changingToEdit} />
            </div>
            <div className="md:hidden">
              <CustomizableDrawer
                // triggerElement={<img src={dots} alt="dotsPic" />}
                heading={"Basic Details Update"}
                height={400}
                content={<EditBasicDetails changingToEdit={changingToEdit} />}
                placement="bottom"
                showDrawerWithOutTrigger={openDrawer === 1 ? true : false}
                changingToViewDrawer={changingToViewDrawer}
                bgColor="#FAF8FC"
              />
            </div>
          </>
        ) : (
          <>
            {showPicView ? (
              <>
                <div className="md:hidden flex flex-col justify-center items-center gap-2">
                  <img
                    src={showPicView}
                    className="border-2 border-[#9065B4] rounded-[50%]"
                    height="30%"
                    width="30%"
                  />
                  <div className="flex gap-2">
                    <button
                      onClick={() =>
                        document.getElementById("viewPicId").click()
                      }
                      className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Update
                    </button>
                    <button
                      //   onClick={deleteProfilePic}
                      className="border border-[#E2E8F0] text-[#DC2626] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="md:hidden flex flex-col justify-center items-center gap-2">
                  <div className="h-[100px] w-[30%] border-2 border-[#9065B4] rounded-[50%]"></div>
                  <div className="">
                    <button
                      onClick={() =>
                        document.getElementById("viewPicId").click()
                      }
                      className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Add Photo
                    </button>
                  </div>
                </div>
              </>
            )}
            <input
              type="file"
              hidden
              id="viewPicId"
              //   onChange={profilePicUpdation}
            />
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-0 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Basic Details
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEdit}
                >
                  EDIT
                </button>
              </div>
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileBasicExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Basic Details
                </h4>
                <div
                  className={`${mobileBasicExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEdit();
                    openingDrawer(1);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingBasicDetailsView}
                  className={`${mobileBasicExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`w-full md:flex justify-center items-center p-2 ${
                  mobileBasicExpand ? "block" : "hidden"
                }`}
              >
                <div className="w-[20%] md:flex justify-center items-start hidden">
                  <div className=" w-44 h-44 border-2 rounded-[50%]"></div>
                </div>
                <div className="md:w-[80%] w-[100%] md:py-12">
                  <div className="grid grid-cols-12 md:gap-x-4 gap-y-3 md:gap-y-0">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Name
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 text-[#334155] md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none">
                          BeyondTax Pvt Ltd
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Owner name
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5  text-[#334155] md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none">
                          Dheeraj Rao Chikyala
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          GST No: [If Available]
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5  text-[#334155] md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none">
                          22AAAAA0000A1Z5
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 md:gap-x-4 gap-y-3 md:gap-y-0 md:mt-8 mt-3">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Mobile Number
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 text-[#334155] md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none">
                          9876543210
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Email
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5  text-[#334155] md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none">
                          Btaxnon0@gmail.com
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Website Link
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5  text-[#334155] md:w-[92%] border-[1px] border-[#D9D9D9]  md:border-none">
                          www.Beyondtax.co
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {kycDetails ? (
          <>
            <div className="hidden md:block">
              <EditKycDetails changingToEditKyc={changingToEditKyc} />
            </div>
            <div className="md:hidden">
              <CustomizableDrawer
                // triggerElement={<img src={dots} alt="dotsPic" />}
                heading={"Business KYC"}
                height={400}
                content={
                  <EditKycDetails changingToEditKyc={changingToEditKyc} />
                }
                placement="bottom"
                showDrawerWithOutTrigger={openDrawer === 2 ? true : false}
                changingToViewDrawer={changingToViewDrawer}
                bgColor="#FAF8FC"
              />
            </div>
          </>
        ) : (
          <>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Business KYC
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEditKyc}
                >
                  EDIT
                </button>
              </div>
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileKycExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Business KYC
                </h4>
                <div
                  className={`${mobileKycExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEditKyc();
                    openingDrawer(2);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingKycDetailsView}
                  className={`${mobileKycExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`w-full md:block md:p-6 p-2 ${
                  mobileKycExpand ? "block" : "hidden"
                }`}
              >
                <div className="md:w-[90%] w-[100%]">
                  <div className="grid grid-cols-12 md:gap-x-12 gap-y-3 md:gap-y-0">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Company Legal Name
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 text-[#334155] border-[1px] border-[#D9D9D9]  md:border-none md:w-[92%]">
                          Aiyug Beyond Tax Pvt Ltd
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Business Entity
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none  text-[#334155] md:w-[92%]">
                          Corporation
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Industry Type
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none  text-[#334155] md:w-[92%]">
                          Finance
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 md:gap-x-12 md:mt-8 gap-y-3 md:gap-y-0 mt-3">
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Company PAN
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none text-[#334155] md:w-[92%]">
                          ABCTY1234D
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          No of Employees [Working Currently]
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none text-[#334155] md:w-[92%]">
                          10k+ Members
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="flex flex-col gap-1.5">
                        <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                          Date of Foundation
                        </h4>
                        <div className="bg-[#F1F5F9] rounded-md py-1.5 px-1.5 border-[1px] border-[#D9D9D9]  md:border-none text-[#334155] md:w-[92%]">
                          01/01/2018
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {addressDetails ? (
          <>
            <div className="hidden md:block">
              <EditAddressDetails
                changingToEditAddress={changingToEditAddress}
              />
            </div>
            <div className="md:hidden">
              <CustomizableDrawer
                // triggerElement={<img src={dots} alt="dotsPic" />}
                heading={"Address"}
                height={400}
                content={
                  <EditAddressDetails
                    changingToEditAddress={changingToEditAddress}
                  />
                }
                placement="bottom"
                showDrawerWithOutTrigger={openDrawer === 3 ? true : false}
                changingToViewDrawer={changingToViewDrawer}
                bgColor="#FAF8FC"
              />
            </div>
          </>
        ) : (
          <>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEditAddress}
                >
                  EDIT
                </button>
              </div>
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileAddressExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <div
                  className={`${mobileAddressExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEditAddress();
                    openingDrawer(3);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingAdressDetailsView}
                  className={`${mobileAddressExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`md:p-6 p-3 md:block ${
                  mobileAddressExpand ? "block" : "hidden"
                }`}
              >
                <div className="md:flex justify-between items-center hidden">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address
                  </p>
                  <div className="flex gap-[1rem] w-[28%] items-center justify-end">
                    <p className="m-0 text-[#334155] text-[0.9rem] font-[500] rounded-md bg-[#F1F5F9] py-1.5 px-2">
                      Owned
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 mb-4 flex-col md:hidden">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address <span>[Rented]</span>
                  </p>
                  <p className="m-0 text-[#334155] text-[0.8rem] font-[500] rounded-lg bg-[#F1F5F9] py-2 px-2.5 w-[50%]">
                    View Address Proof
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 1
                  </h4>
                  <div className="bg-[#F8FAFC] text-[#334155] w-full  py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-none md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap">
                    B-109/B-Block , Aparna Apartments
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 2
                  </h4>
                  <div className="bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border md:border-transparent rounded-lg md:rounded-none overflow-hidden whitespace-nowrap">
                    Jyothinagar Road no-3, Nirmal [Adilabad], 500050
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 3
                  </h4>
                  <div className="bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border md:border-transparent rounded-lg md:rounded-none overflow-hidden whitespace-nowrap">
                    Adilabad, Telangana, India
                  </div>
                </div>
                <div className="mt-[2rem] md:mb-[1.5rem] mb-[1rem] text-[#334155] text-[0.9rem] font-[500] ">
                  Permanent Address
                </div>
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 1
                  </h4>
                  <div className="bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2 font-[400] border-[1px] border-[#E2E8F0] md:border md:border-transparent rounded-lg md:rounded-none overflow-hidden whitespace-nowrap">
                    B-109/B-Block , Aparna Apartments
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 2
                  </h4>
                  <div className="bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border md:border-transparent rounded-lg md:rounded-none overflow-hidden whitespace-nowrap">
                    Jyothinagar Road no-3, Nirmal [Adilabad], 500050
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 3
                  </h4>
                  <div className="bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border md:border-transparent rounded-lg md:rounded-none overflow-hidden whitespace-nowrap">
                    Adilabad, Telangana, India
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem] md:mb-5 mb-[5rem]">
          <div
            className={`bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl flex justify-between ${
              mobileAccountExpand ? "rounded-tl-xl rounded-tr-xl" : "rounded-xl"
            }`}
          >
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Account Settings
            </h4>
            <img
              src={plus}
              alt="plusPic"
              className={`md:hidden ${
                mobileAccountExpand ? "hidden" : "block"
              }`}
              onClick={expandingAccountDetailsView}
            />
          </div>
          <div
            className={`md:grid md:grid-cols-12 p-6 md:gap-[3rem] flex flex-col gap-3 ${
              mobileAccountExpand ? "block" : "hidden"
            }`}
          >
            <div className="md:col-span-4">
              {passwordDetails ? (
                <>
                  <div className="hidden md:block">
                    <EditPasswordDetails changeToEditPass={changeToEditPass} />
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Password"}
                      height={400}
                      content={
                        <EditPasswordDetails
                          changeToEditPass={changeToEditPass}
                        />
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 4 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Password
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0">****************</p>
                    <div className="flex items-center justify-center gap-1">
                      <img src={eye} alt="eye" />
                      <img
                        src={edit_icon}
                        alt="editPen"
                        className="md:hidden"
                        onClick={() => {
                          changeToEditPass();
                          openingDrawer(4);
                        }}
                      />
                    </div>
                  </div>
                  <h6
                    className="m-0 text-[#64748B] text-[0.8rem] mt-1 text-right w-[98%] cursor-pointer hidden md:block"
                    onClick={() => {
                      changeToEditPass();
                    }}
                  >
                    Change Password
                  </h6>
                </>
              )}
            </div>
            <div className="md:col-span-4">
              {phoneDetails ? (
                <>
                  <div className="hidden md:block">
                    <EditPhoneDetails changeToEditPhone={changeToEditPhone} />
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Mobile Number"}
                      height={400}
                      content={
                        <EditPhoneDetails
                          changeToEditPhone={changeToEditPhone}
                        />
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 5 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Phone Number
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0 text-[0.9rem]">+91-9876543210</p>
                    <p
                      className="m-0 text-[0.7rem] text-[#9065B4] cursor-pointer hidden md:block"
                      onClick={changeToEditPhone}
                    >
                      EDIT
                    </p>
                    <img
                      src={edit_icon}
                      alt="editPen"
                      className="md:hidden"
                      onClick={() => {
                        changeToEditPhone();
                        openingDrawer(5);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="md:col-span-4">
              {emailDetails ? (
                <>
                  <div className="hidden md:block">
                    <EditEmailDetails changeToEditEmail={changeToEditEmail} />
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Mail Address"}
                      height={400}
                      content={
                        <EditEmailDetails
                          changeToEditEmail={changeToEditEmail}
                        />
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 6 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Email Address
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0 text-[0.9rem]">Dheerajrao@gmail.com</p>
                    <p
                      className="m-0 text-[0.7rem] text-[#9065B4] cursor-pointer hidden md:block"
                      onClick={changeToEditEmail}
                    >
                      EDIT
                    </p>
                    <img
                      src={edit_icon}
                      alt="editPen"
                      className="md:hidden"
                      onClick={() => {
                        changeToEditEmail();
                        openingDrawer(6);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;

const EditBasicDetails = ({ changingToEdit }) => {
  return (
    <form>
      <div className="bg-white-body rounded-xl md:border md:border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
        <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
            Basic Details
          </h4>
          <button
            onClick={changingToEdit}
            className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
          >
            Done
          </button>
        </div>
        <div className="w-full flex justify-center items-center p-2">
          <div className="w-[20%] md:flex flex-col items-center justify-start gap-2 hidden">
            <div className="w-44 h-44 border-2 rounded-[50%]"></div>
            <div className="flex gap-2">
              <button
                type="button"
                className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
              >
                Update
              </button>
              <button
                type="button"
                className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
              >
                Remove
              </button>
            </div>
          </div>
          <div className="md:w-[80%] w-full md:py-12">
            <div className="grid grid-cols-12 md:gap-x-4 gap-y-4 md:gap-y-0">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Name
                  </h4>
                  <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Owner name
                  </h4>
                  <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    GST No: [If Available]
                  </h4>
                  <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-x-4 md:mt-8 mt-4 md:gap-x-4 gap-y-4 md:gap-y-0">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Mobile Number
                  </h4>
                  <input
                    type="number"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Email
                  </h4>
                  <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8]  rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Website Link
                  </h4>
                  <input
                    type="text"
                    placeholder=""
                    className="!border md:!border-[#D9D9D9] !border-[#94A3B8] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                  <div className="md:flex hidden justify-end items-center gap-2 w-[92%]">
                    <span className="text-[#DC2626] text-[0.8rem]">Delete</span>
                    <span className="text-[#9065B4] text-[0.8rem]">
                      Visit Website
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[1rem] w-[100%] md:hidden flex items-center justify-between">
              <button
                type="button"
                // onClick={submitingBasicForm}
                className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              >
                Update
              </button>
              <button
                type="button"
                onClick={changingToEdit}
                className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const EditKycDetails = ({ changingToEditKyc }) => {
  return (
    <form>
      <div className="bg-white-body rounded-xl md:border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
        <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
          <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
            Business KYC
          </h4>
          <button
            className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
            onClick={changingToEditKyc}
          >
            DONE
          </button>
        </div>
        <div className="w-full md:p-6 p-3">
          <div className="md:w-[90%]">
            <div className="grid grid-cols-12 md:gap-x-12 gap-y-3 md:gap-y-0">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Company Legal Name
                  </h4>
                  <input
                    type="text"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Business Entity
                  </h4>
                  <select className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none]">
                    <option>Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                  </select>
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Industry Type
                  </h4>
                  <select className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none]">
                    <option>Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-x-12 gap-y-3 md:gap-y-0 mt-3 md:mt-8">
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Company PAN
                  </h4>
                  <input
                    type="text"
                    className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                  />
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    No of Employees [Working Currently]
                  </h4>
                  <select className="!border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-1.5 focus:outline-none]">
                    <option>Select an option</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                  </select>
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[0.8rem] font-[500] m-0">
                    Date of Foundation
                  </h4>
                  <input
                    type="date"
                    className=" !border border-[#94A3B8] md:!border-[#D9D9D9] rounded-md md:w-[92%] text-[#334155] py-1.5 px-3 focus:outline-none pr-10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const EditAddressDetails = ({ changingToEditAddress }) => {
  return (
    <div className="bg-white-body rounded-xl md:border border-[#E2E8F0] md:mt-5 ">
      <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
        <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">Address</h4>
        <button
          type="button"
          className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
          onClick={changingToEditAddress}
        >
          DONE
        </button>
      </div>
      <div className="md:p-6 p-3">
        <div className="flex md:flex-row flex-col md:justify-between md:items-center">
          <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
            Communication Address
          </p>
          <div className="flex gap-[1rem] md:w-[36%] justify-between items-center md:justify-end mt-2 md:mt-0">
            <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
              <input
                type="radio"
                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
              />
              Owned
            </label>
            <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
              <input
                type="radio"
                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
              />
              Rented
            </label>
            {/* {rentStatus0 == 2 && (
                <div className="">
                  <input
                    type="file"
                    accept=".pdf"
                    {...registerFormAddress(
                      "addresses[0].rental_agreement"
                    )}
                    id="rental_agreement"
                    className="hidden"
                  />
                  <label
                    htmlFor="rental_agreement"
                    className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md cursor-pointer inline-block"
                  >
                    Upload Rental Agreement
                  </label>
                </div>
              )} */}
            <p className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md hidden md:block">
              Upload Rental Agreement
            </p>
            <p className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md md:hidden block">
              Upload Proof
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Flat / Door No.
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Premise Name
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Road / Street
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Area Locality*
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Pincode / Zipcode
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="number"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Town / City
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              State
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none">
                <option value="">Select an option</option>
                <option>State 1</option>
                <option>State 2</option>
                <option>State 3</option>
              </select>
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Country
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none">
                <option value="">Select an option</option>
                <option>India</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mt-[2rem]  text-[#334155] text-[0.9rem] font-[500]">
          Permanent address
          <div className="flex items-center mt-1">
            <input
              type="checkbox"
              // onChange={handleCheckboxChange}
              className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
              id="customCheckbox"
            />
            <label
              htmlFor="customCheckbox"
              className="ml-2 text-[#334155] text-[0.9rem] font-[500]"
            >
              Check if your communication address is your permanent address
            </label>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Flat / Door No.
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Premise Name
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Road / Street
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Area Locality*
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
              Pincode / Zipcode
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="number"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Town / City
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <input
                type="text"
                className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
              />
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              State
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none">
                <option value="">Select an option</option>
                <option>State 1</option>
                <option>State 2</option>
                <option>State 3</option>
              </select>
            </div>
          </div>
          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
              Country
            </h6>
            <div className="flex items-center md:!border-[#D9D9D9] !border-[#94A3B8] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <select className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none">
                <option value="">Select an option</option>
                <option>India</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditPasswordDetails = ({ changeToEditPass }) => {
  return (
    <form>
      <div className="p-3 md:p-0">
        <div>
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Current Password
          </h6>
          <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            <input
              type="password"
              placeholder="********"
              className="w-full bg-transparent border-none outline-none text-[#334155]"
            />
            <img src={eye} alt="eye" />
          </div>
        </div>
        <div className="mt-3">
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            New Password
          </h6>
          <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            <input
              type="password"
              placeholder="********"
              className="w-full bg-transparent border-none outline-none"
            />
            <img src={eye} alt="eye" />
          </div>
        </div>
        <div className="mt-3">
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Confirm New Password
          </h6>
          <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            <input
              type="password"
              placeholder="********"
              className="w-full bg-transparent border-none outline-none"
            />
            <img src={eye} alt="eye" />
          </div>
        </div>
        <div className="mt-3 flex items-center justify-between">
          <button
            type="button"
            className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
          >
            Update Password
          </button>
          <button
            type="button"
            className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            onClick={changeToEditPass}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

const EditPhoneDetails = ({ changeToEditPhone }) => {
  return (
    <>
      <div className="md:block p-3 md:p-0">
        <div>
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Phone Number
          </h6>
          <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            <input
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter Phone No."
            />
          </div>
        </div>
        {/* {otpSent && (
          <div className="mt-3">
            <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">OTP</h6>
            <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
              <p className="m-0 text-[0.9rem]">+91-9876543210</p>
              
              <input
                {...registerMobile("otp", {
                  required: "OTP is required",
                })}
                className="w-full text-[0.9rem] bg-transparent outline-none"
                placeholder="Enter OTP."
              />
            </div>
            {errorsMobile.otp && <p>{errorsMobile.otp.message}</p>}
          </div>
        )} */}
        <div className="mt-3 flex items-center justify-between">
          <button
            type="button"
            className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
          >
            Update Phone
          </button>
          <button
            type="button"
            className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            onClick={changeToEditPhone}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

const EditEmailDetails = ({ changeToEditEmail }) => {
  return (
    <form>
      <div className="md:block p-3 md:p-0">
        <div>
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            Email Address
          </h6>
          <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            <input
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter Email."
            />
          </div>
        </div>
        {/* {otpSentEmail && (
        <div className="mt-3">
          <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
            OTP
          </h6>
          <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
            <p className="m-0 text-[0.9rem]">+91-9876543210</p>
            
            <input
              {...registerEmail("otp", {
                required: "OTP is required",
              })}
              className="w-full text-[0.9rem] bg-transparent outline-none"
              placeholder="Enter OTP."
            />
          </div>
          {errorsEmail.otp && <p>{errorsEmail.otp.message}</p>}
        </div>
      )} */}
        <div className="mt-3 flex items-center justify-between">
          <button
            type="button"
            className="bg-[#9065B4] cursor-pointer border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            //   onClick={emailFormSubmission}
          >
            Update Email
          </button>
          <button
            type="button"
            className="bg-[#FFFFFF] cursor-pointer border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
            onClick={changeToEditEmail}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};
