import React, { useEffect, useRef, useState } from "react";
import Button from "components/Button/BtnOrange";
import { useNavigate, useParams } from "react-router-dom";
import useGetTaxPaid from "./Data/useGetTaxPaid";
import { useForm } from "react-hook-form";
import usePostTaxPaid from "./Data/usePostTaxPaid";
import usePatchTaxPaid from "./Data/usePatchTaxPaid";
import {
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";
import usePostUploadChallan from "./Data/usePostUploadChallan";
import usePatchUploadChallan from "./Data/usePatchUploadChallan";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";
import usePostUpload26AS from "./Data/usePostUpload26AS";
import usePostUploadAIS from "./Data/usePostUploadAIS";

const TaxesPaid = ({ isDisabled }) => {
  const navigate = useNavigate();
  const { income_tax_return_id } = useParams();
  const path = USER_INCOME_SUMMARY_PAGE_URL.replace(
    ":income_tax_return_id",
    income_tax_return_id
  ).replace(":type", "summary");

  const [tdsFields, setTDSFields] = useState([
    {
      name_of_deductor: "",
      tan: "",
      tds_or_tcs_amount: "",
      gross_receipts: "",
    },
  ]);

  const [selfTaxFields, setSelfTaxFields] = useState([
    {
      bsr_code: "",
      challan_no: "",
      amount: "",
      date: "",
      challan_pdf: "",
    },
  ]);

  const [getData, getError, getIsLoading, getTaxPaidData] = useGetTaxPaid();
  const [taxPaidData, taxPaidError, taxPaidIsLoading, postTaxPaidData] =
    usePostTaxPaid();
  const [patchData, patchError, patchIsLoading, patchTaxPaidData] =
    usePatchTaxPaid();

  const [challanData, challanError, challanIsLoading, postChallanUpload] =
    usePostUploadChallan();

  const [
    patchChallanData,
    patchChallanError,
    patchChallanIsLoading,
    patchChallanUpload,
  ] = usePatchUploadChallan();

  const [ASData, ASError, ASIsLoading, postUpload26AS] = usePostUpload26AS();
  const [AISData, AISError, postUploadAISLoading, postUploadAIS] =
    usePostUploadAIS();

  const form = useForm();
  const { register, handleSubmit, formState, setValue, reset, getValues } =
    form;
  const { errors } = formState;

  useEffect(() => {
    getTaxPaidData(income_tax_return_id);
  }, [income_tax_return_id]);

  useEffect(() => {
    if (getData) {
      const taxPaidData = getData.data.data.tds_or_tcs_deductions || [];
      const selfPaidData =
        getData.data.data.self_assessment_and_advance_tax_paid || [];
      if (taxPaidData.length > 0) {
        setTDSFields(taxPaidData);
      }
      if (selfPaidData.length > 0) {
        setSelfTaxFields(selfPaidData);
      }
      taxPaidData.forEach((taxPaid, index) => {
        setValue(`tds_or_tcs_deductions[${index}].tan`, taxPaid.tan);
        setValue(
          `tds_or_tcs_deductions[${index}].name_of_deductor`,
          taxPaid.name_of_deductor
        );
        setValue(
          `tds_or_tcs_deductions[${index}].tds_or_tcs_amount`,
          taxPaid.tds_or_tcs_amount
        );
        setValue(
          `tds_or_tcs_deductions[${index}].gross_receipts`,
          taxPaid.gross_receipts
        );
      });

      selfPaidData.forEach((selfPaid, index) => {
        // setValue(
        //   `self_assessment_and_advance_tax_paid[${index}].id`,
        //   selfPaid.id
        // );
        setValue(
          `self_assessment_and_advance_tax_paid[${index}].bsr_code`,
          selfPaid.bsr_code
        );
        setValue(
          `self_assessment_and_advance_tax_paid[${index}].challan_no`,
          selfPaid.challan_no
        );
        setValue(
          `self_assessment_and_advance_tax_paid[${index}].date`,
          selfPaid.date
        );
        setValue(
          `self_assessment_and_advance_tax_paid[${index}].amount`,
          selfPaid.amount
        );
        setValue(
          `self_assessment_and_advance_tax_paid[${index}].challan_pdf`,
          selfPaid.challan_pdf
        );
      });
    }
  }, [getData]);

  useEffect(() => {
    if (taxPaidData) {
      showSuccessToast("Taxes Paid Data Uploaded");
      navigate(path);
    }
    if (patchData) {
      showSuccessToast("Taxes Paid Data Updated");
      navigate(path);
    }
  }, [taxPaidData, patchData]);

  useEffect(() => {
    if (ASData) {
      showSuccessToast("26AS form uploaded");
      getTaxPaidData(income_tax_return_id);
    }
  }, [ASData]);

  useEffect(() => {
    if(ASError){
       showErrorToast("26AS file not uploaded. Try Again")
    }
  }, [ASError]);

  useEffect(() => {
    if(AISError){
       showErrorToast("AIS file not uploaded. Try Again")
    }
  }, [AISError]);

  useEffect(() => {
    if (AISData) {
      showSuccessToast("AIS data imported successfully");
      getTaxPaidData(income_tax_return_id);
    }
  }, [AISData]);

  useEffect(() => {
    if (challanData) {
      showSuccessToast("Challan uploaded");
      getTaxPaidData(income_tax_return_id);
    }
    if (patchChallanData) {
      showSuccessToast("Challan updated");
      getTaxPaidData(income_tax_return_id);
    }
  }, [challanData, patchChallanData]);

  useEffect(() => {
    if (taxPaidError) {
      showErrorToast("", "Data not posted");
    }
    if (patchError) {
      showErrorToast("", "Data not updated");
    }
    if (challanError) {
      showErrorToast("", "Challan not uploaded");
    }
    if (patchChallanError) {
      showErrorToast("", "Challan not updated");
    }
  }, [taxPaidError, patchError, challanError, patchChallanError]);

  const handleTDSAddNew = () => {
    setTDSFields([
      ...tdsFields,
      {
        name_of_deductor: "",
        tan: "",
        tds_or_tcs_amount: "",
        gross_receipts: "",
      },
    ]);
  };

  const handleSelfTaxAddNew = () => {
    setSelfTaxFields([
      ...selfTaxFields,
      {
        bsr_code: "",
        date_of_deposit: "",
        amount: "",
        challan_no: "",
        challan_pdf: "",
      },
    ]);
  };

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  const fileInputRef = useRef();
  const form26ASFileInputRef = useRef(null);
  const aisFileInputRef = useRef(null);

  const handleAISFileUploadClick = () => {
    aisFileInputRef.current.click();
  };

  const handle26ASFileUploadClick = (index) => {
    const fileInput = document.getElementById(`file-input-AS-${index}`);
    if (fileInput) {
      form26ASFileInputRef.current.click();
    } else {
      console.error("File input reference is null for index:", index);
    }
  };

  const handleFileUploadClick = (index) => {
    const fileInput = document.getElementById(`file-input-${index}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input reference is null for index:", index);
    }
  };

  const handleAISFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("ais_pdf", file);
      postUploadAIS(income_tax_return_id, formData);
    }
  };

  const handle26ASFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("tds_pdf", file);
      postUpload26AS(income_tax_return_id, formData);
    }
  };

  const handleFileChange = (event, index) => {
    const formData = new FormData();
    const file = event.target.files[0];
    if (file) {
      formData.append("challan_pdf", file);
    }

    const currentField = selfTaxFields[index];
    if (currentField?.id) {
      formData.append("id", currentField.id);
      patchChallanUpload(income_tax_return_id, formData);
    } else {
      postChallanUpload(income_tax_return_id, formData);
    }
  };

  const handleRemoveTDSDetails = (index) => {
    const updatedTDSFields = tdsFields.filter((_, i) => i !== index);

    const filteredTDS = updatedTDSFields.map((deduction) => ({
      name_of_deductor: deduction.name_of_deductor,
      tan: deduction.tan,
      tds_or_tcs_amount: deduction.tds_or_tcs_amount,
      gross_receipts: deduction.gross_receipts,
    }));

    setTDSFields(filteredTDS);

    const { created_at, updated_at, ...formValuesToUpdate } = getValues();

    reset({
      ...formValuesToUpdate,
      tds_or_tcs_deductions: filteredTDS,
    });
  };

  const handleRemoveChallanDetails = (index, data) => {
    const newChallan = selfTaxFields.filter((_, i) => i !== index);

    const filteredChallan = newChallan.map((account) => ({
      amount: account.amount,
      bsr_code: account.bsr_code,
      challan_no: account.challan_no,
      challan_pdf: account.challan_pdf,
      date: account.date,
    }));
    setSelfTaxFields(filteredChallan);
    const { created_at, updated_at, ...formValuesToUpdate } = getValues();

    reset({
      ...formValuesToUpdate,
      self_assessment_and_advance_tax_paid: filteredChallan,
    });
  };

  const handleSaveAndNext = (data) => {
    const formData = new FormData();
    data?.tds_or_tcs_deductions?.map((item) => {
      formData.append("tds_or_tcs_deductions", JSON.stringify(item));
    });

    data?.self_assessment_and_advance_tax_paid?.map((item) => {
      formData.append(
        "self_assessment_and_advance_tax_paid",
        JSON.stringify(item)
      );
      const file = item.challan_pdf;
      const encoder = new TextEncoder();
      const binaryFile = encoder.encode(file);
      if (binaryFile && binaryFile.length > 0) {
        formData.append("files", new Blob([binaryFile[0]]));
      }
    });

    const tdsOrTcsDeductions = getData?.data?.data?.tds_or_tcs_deductions || [];
    const selfAssessmentAndAdvanceTaxPaid =
      getData?.data?.data?.self_assessment_and_advance_tax_paid || [];

    const hasTdsOrTcsId = tdsOrTcsDeductions.some((deduction) => deduction.id);
    const hasSelfAssessmentId = selfAssessmentAndAdvanceTaxPaid.some(
      (taxPaid) => taxPaid.id
    );

    if (hasTdsOrTcsId || hasSelfAssessmentId) {
      patchTaxPaidData(income_tax_return_id, formData);
    } else {
      postTaxPaidData(income_tax_return_id, formData);
    }
  };

  const dateChange = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handlePrevious = () => {
    navigate(
      INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 z-50 border border-[#E2E8F0] rounded p-3 flex justify-center items-start">
          <div className="loader mt-48"></div>
        </div>
      )}

      <div className="flex gap-2 md:hidden">
        <button onClick={handlePrevious}>
          <img src={previousBtn} alt="" className="w-4 h-4 mb-2" />
        </button>
        <h4 className=" text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
          Taxes Paid
        </h4>
      </div>
      <div className="md:bg-white md:border md:border-[#E2E8F0] rounded md:p-3 mb-[6rem]">
        <div className="bg-white border border-[#E2E8F0] rounded py-4 px-3">
          <div className="flex justify-between">
            <h4 className="font-[600] text-[1.1rem] md:text-[1.2rem] text-[#334155]">
              TDS/TCS Deducted
            </h4>
            {/* <button className="text-[#9065B4] bg-[#EAE1F3] rounded py-2 px-3 md:block hidden">
              Import from Form 26AS
            </button> */}
            <button className="bg-[#64748B] text-white rounded px-2 py-1 md:hidden">
              Salary Income
            </button>
          </div>
          <div className="flex justify-between mt-[1rem]">
            <h4 className="text-[1rem] bg-[#64748B] text-white rounded px-2 py-1 md:block hidden cursor-text">
              Salary Income
            </h4>
            <h4 className="font-[500] text-[1rem] text-[#334155]">
              Sec No.{" "}
              <span className="bg-[#F1F5F9] font-[400] text-[0.8rem] p-1 ml-2 rounded">
                192
              </span>
            </h4>
            {/* <button 
            className="text-[#9065B4] bg-[#EAE1F3] rounded py-2 px-3 md:hidden">
              Import from Form 26AS
            </button> */}
          </div>
          {tdsFields.map((tds, index) => (
            <div className="grid grid-cols-12 " key={index}>
              <div className="col-span-12 flex flex-row-reverse mb-[1rem] gap-3">
                <InputField
                  name="AS_file"
                  type="file"
                  accept=".pdf"
                  // register={register}
                  id={`file-input-AS-${index}`}
                  className="hidden"
                  onChange={(event) => handle26ASFileChange(event, index)}
                  ref={form26ASFileInputRef}
                  validation={{
                    required: {
                      value: true,
                      message: "26AS file is required",
                    },
                  }}
                />

                <button
                  type="button"
                  className="text-[#9065B4] bg-[#EAE1F3] font-[500] text-[0.9rem] py-1 px-2 rounded"
                  onClick={() => handle26ASFileUploadClick(index)}
                >
                  Import from Form 26AS
                </button>
              </div>
              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">Name of Deductor</h6>
                <InputField
                  type="text"
                  className="input-box"
                  name={`tds_or_tcs_deductions[${index}].name_of_deductor`}
                  register={register}
                  defaultValue={tds.name_of_deductor}
                  validation={{
                    required: {
                      value: true,
                      message: "Name of Deductor is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                      message: "Only alphabetic characters are allowed",
                    },
                  }}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.tds_or_tcs_deductions?.[index]?.name_of_deductor
                      ?.message
                  }
                </p>
              </div>
              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">TAN</h6>
                <InputField
                  type="text"
                  className="input-box"
                  name={`tds_or_tcs_deductions[${index}].tan`}
                  register={register}
                  defaultValue={tds.tan}
                  validation={{
                    required: {
                      value: true,
                      message: "TAN is required",
                    },
                    pattern: {
                      value: /^[A-Z]{4}[0-9]{5}[A-Z]$/,
                      message:
                        "TAN must be in the format: 4 capital letters, 5 digits, and 1 capital letter",
                    },
                    validate: (value) =>
                      value.length === 10 ||
                      "TAN must be exactly 10 characters",
                  }}
                  maxLength={10}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {errors.tds_or_tcs_deductions?.[index]?.tan?.message}
                </p>
              </div>
              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">TDS/TCS Amount</h6>
                <InputField
                  type="number"
                  className="input-box"
                  name={`tds_or_tcs_deductions[${index}].tds_or_tcs_amount`}
                  register={register}
                  defaultValue={tds.tds_or_tcs_amount}
                  validation={{
                    required: {
                      value: true,
                      message: "TDS/TCS is required",
                    },
                  }}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.tds_or_tcs_deductions?.[index]?.tds_or_tcs_amount
                      ?.message
                  }
                </p>
              </div>
              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">Gross Receipts</h6>
                <InputField
                  type="number"
                  className="input-box"
                  name={`tds_or_tcs_deductions[${index}].gross_receipts`}
                  register={register}
                  defaultValue={tds.gross_receipts}
                  validation={{
                    required: {
                      value: true,
                      message: "Gross Receipts is required",
                    },
                  }}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.tds_or_tcs_deductions?.[index]?.gross_receipts
                      ?.message
                  }
                </p>
              </div>
              <div className="">
                {index > 0 ? (
                  <button
                    type="button"
                    onClick={() => handleRemoveTDSDetails(index)}
                    className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                  >
                    <span className="mr-1.5">X</span>Remove
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
          <button
            onClick={handleTDSAddNew}
            className="text-[#334155] bg-[#F1F5F9] font-[500] text-[0.9rem] py-1 px-2 mt-4 rounded"
          >
            Add New
          </button>
          <hr className="my-[2rem]" />
          <div className="md:flex md:justify-between ">
            <h4 className="font-[600]  text-[1.1rem] md:text-[1.2rem] text-[#334155]">
              Self Assessment & Advance tax Paid
            </h4>

            <div className="flex flex-row-reverse gap-4">
              <InputField
                type="file"
                ref={aisFileInputRef} /* Pass ref here */
                // style={{ display: "none" }}
                className="hidden"
                onChange={handleAISFileChange}
              />
              <button
                onClick={handleAISFileUploadClick}
                className="text-[#9065B4] bg-[#EAE1F3] rounded py-2 px-3"
              >
                Import from AIS
              </button>
            </div>
          </div>

          {selfTaxFields.map((self, index) => (
            <div className="grid grid-cols-12 mt-[1rem]" key={index}>
              <div className="col-span-12 flex flex-row-reverse mb-[1rem] gap-3">
                <div>
                  {index > 0 ? (
                    <button
                      type="button"
                      onClick={() => handleRemoveChallanDetails(index)}
                      className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                    >
                      <span className="mr-1.5">X</span>Remove
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <InputField
                    type="file"
                    accept=".pdf"
                    // register={register}
                    id={`file-input-${index}`}
                    className="hidden"
                    onChange={(event) => handleFileChange(event, index)}
                    ref={fileInputRef}
                  />

                  <button
                    type="button"
                    className="text-[#334155] bg-[#F1F5F9] font-[500] text-[0.9rem] py-1 px-2 rounded"
                    onClick={() => handleFileUploadClick(index)}
                  >
                    Upload Challan
                  </button>
                </div>
              </div>

              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">BSR Code</h6>
                <InputField
                  type="text"
                  className="input-box"
                  name={`self_assessment_and_advance_tax_paid[${index}].bsr_code`}
                  register={register}
                  defaultValue={self.bsr_code}
                  validation={{
                    required: {
                      value: true,
                      message: "BSR Code is required",
                    },
                    pattern: {
                      value: /^[A-Z0-9]{7}$/, // Allows uppercase letters and numbers, 7 characters
                      message:
                        "BSR Code must be exactly 7 alphanumeric characters",
                    },
                    minLength: {
                      value: 7,
                      message: "BSR Code must be 7 characters",
                    },
                    maxLength: {
                      value: 7,
                      message: "BSR Code must be 7 characters",
                    },
                  }}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.self_assessment_and_advance_tax_paid?.[index]
                      ?.bsr_code?.message
                  }
                </p>
              </div>
              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">Challan No</h6>
                <InputField
                  type="text"
                  className="input-box"
                  name={`self_assessment_and_advance_tax_paid[${index}].challan_no`}
                  register={register}
                  defaultValue={self.challan_no}
                  validation={{
                    required: {
                      value: true,
                      message: "Challan No is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces only
                      message: "*Special characters are not allowed",
                    },
                    minLength: {
                      value: 5, // Minimum length (adjust as needed)
                      message: "Challan No must be at least 5 characters",
                    },
                    maxLength: {
                      value: 10, // Maximum length (adjust as needed)
                      message: "Challan No must not exceed 10 characters",
                    },
                  }}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.self_assessment_and_advance_tax_paid?.[index]
                      ?.challan_no?.message
                  }
                </p>
              </div>
              <div className="md:col-span-3 col-span-12 input-container">
                <h6 className="input-label">Date</h6>
                <InputField
                  type="date"
                  placeholder="Select"
                  defaultValue={dateChange(self.date)}
                  className="input-box"
                  name={`self_assessment_and_advance_tax_paid[${index}].date`}
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "Date is required",
                    },
                  }}
                />

                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.self_assessment_and_advance_tax_paid?.[index]?.date
                      ?.message
                  }
                </p>
              </div>
              <div className="md:col-span-3 col-span-12 group">
                <h6 className="input-label group-focus-within:text-customPurple">
                  Amount
                </h6>
                <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                  <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                    Rs.
                  </span>
                  <InputField
                    type="number"
                    className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                    name={`self_assessment_and_advance_tax_paid[${index}].amount`}
                    register={register}
                    defaultValue={self.amount}
                    validation={{
                      required: {
                        value: true,
                        message: "Amount is required",
                      },
                    }}
                  />
                </div>
                <p className="text-red-600 font-[500] text-[0.8rem]">
                  {
                    errors.self_assessment_and_advance_tax_paid?.[index]?.amount
                      ?.message
                  }
                </p>{" "}
              </div>
            </div>
          ))}
          <button
            onClick={handleSelfTaxAddNew}
            className="text-[#334155] bg-[#F1F5F9] font-[500] text-[0.9rem] py-1 px-2 mt-4 rounded"
          >
            Add New
          </button>
        </div>

        <div className="flex flex-row-reverse gap-4 mt-[1rem]">
          <Button
            px={"px-[1rem]"}
            py={"py-[0.5rem]"}
            name="Save and next"
            onClick={handleSubmit(handleSaveAndNext)}
            disabled={isDisabled}
            noValidate
          />

          <button
            onClick={handleGoBack}
            className="font-[500] md:px-[2%] px-[3%] py-[0.5%] rounded-md bg-[#E8EAED]"
          >
            <span className="block md:hidden">Back</span>
            <span className="hidden md:block">Back to tax Summary</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaxesPaid;
