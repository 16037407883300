import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import removeLiability from "assets/images/liabilities/removeLiability.svg";
import RedButton from "components/Button/RedButton";
import useDeleteLoans from "./data/useDeleteLoans";
import { showSuccessToast } from "components/toaster/toastHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { LIABILITIES_URL } from "constants/routes";

const RemoveLiability = ({ remove_id, loan_name, triggerElement, setIsReloadOverview}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldClose, setShouldClose] = useState(false);
  const [data, error, isLoading, deleteLoan] = useDeleteLoans();

  useEffect(() => {
  if(data){
    showSuccessToast("Loan Status Closed");
    // Set shouldClose if we're on the liabilities page
    if (location.pathname === LIABILITIES_URL) {
      setIsReloadOverview(true)
      setShouldClose(true); // Set this to trigger closing of dialog
    } else {
      navigate(LIABILITIES_URL); // Otherwise, navigate to the liabilities page
    }
  }
  }, [data]);

  const handleDeleteLoan = () => {
    deleteLoan(remove_id);
    
  }

  return (
    <div>
      <DialogBox
        triggerElement={
         triggerElement ? triggerElement :  <RedButton
         px={"px-[0.9rem]"}
         py={"py-[0.3rem]"}
         name="Remove Liability"
       />
        }
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Remove <span className="text-[#9065B4]">{loan_name}</span>?
          </h4>
        }
        content={(handleClose) => (
          <>
            <div className=" text-center flex flex-col items-center">
              <img
                src={removeLiability}
                alt="remove"
                className="w-[5rem] h-[5rem] mb-3"
              />
              <h4 className="text-[#DC2626] font-[600] text-[0.9rem]">
                Are you sure you want to remove this loan from your account?
              </h4>
              <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                This action is permanent and may affect your financial records.
              </h4>
              <div className="flex gap-2 mt-[1rem]">
                <button onClick={handleDeleteLoan} className="text-[#DC2626] font-[600] text-[0.9rem] border-1 border-[#FEE2E2] bg-[#FEF2F2] p-2 rounded-md">
                  Yes, Remove
                </button>
                <button onClick={handleClose} className="text-[#334155] font-[600] text-[0.9rem] border py-2 px-4 rounded-md">
                  Cancel
                </button>
              </div>
            </div>
            {/* Conditionally close the dialog if deletion is successful */}
            {shouldClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default RemoveLiability;