import useAPICall from "hooks/useAPICall";
import { POST_BANK_ACCOUNT_URL } from "constants/apis";

const usePostBankAccount = () => {
  const [data, error, isLoading, callPostBankAccount, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postBankAccount = (body) => {
    const url = POST_BANK_ACCOUNT_URL
    // var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    callPostBankAccount({
      url,
      method: "POST",
      statusObj,
      // config,
      defaultFallback,
      body
    });
  };

  return [data, error, isLoading, postBankAccount, setSuccessData, setError];
};

export default usePostBankAccount;
