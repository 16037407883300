import React, { useState } from "react";
import { Space, Drawer } from "antd";
import MobileForgotPswd from "./MobileForgotPswd";

const MobileDrawer = (props) => {
  const [currentScreen, setCurrentScreen] = useState("forgotPassword");
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");

  const showDrawer = () => {
    setOpen(true); 
  };

  const onClose = () => {
    setOpen(false);
    setCurrentScreen("forgotPassword"); // Reset state when drawer is closed
  };

  
  return (
    <>
      <Space>
        <div onClick={showDrawer}>{props.element}</div>
      </Space>

      <Drawer
        className="test"
        height={550}
        style={{ borderRadius: "20px 20px 0px 0px" }}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open} 
        key={placement}
      >
        <div className="">
          <MobileForgotPswd />
        </div>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
