import React, { useState, useContext, useEffect } from "react";
import { myContext } from "App";
import DocSlider from "./DocSlider";
import useGetDocsListDownload from "./data/useGetDownloadList";
import useGetDueDate from "./data/useGetDueDate";
import useGetUserBasic from "./data/getUserbasicDetails";
import SampleDocsSlider from "./SampleDocsSlider";

function ProfileAndCertificate() {
  const { isLoggedIn } = useContext(myContext);
  const [docImages, setDocImages] = useState([]);
  const [dueDateArr, setDueDateArr] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  const [
    docsListDownloadData,
    docsListDownloadError,
    docsListDownloadIsLoading,
    getDocsListDownload,
  ] = useGetDocsListDownload();

  const [dueDateData, dueDateError, dueDateIsLoading, getDueDate] =
    useGetDueDate();

  const [dataFetched, setDataFetched] = useState(false);
  const [date, setDate] = useState(new Date());
  const [filteredDates, setFilteredDates] = useState([]);

  useEffect(() => {
    if (isLoggedIn && !dataFetched) {
      getDocsListDownload();
      getDueDate();
      setDataFetched(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (docsListDownloadData) {
      setDocImages(docsListDownloadData.data.results);
    }
    if (dueDateData) {
      const flattenedData = [];
      Object.keys(dueDateData.data).forEach((month) => {
        dueDateData.data[month].forEach((item) => {
          flattenedData.push(item);
        });
      });
      setDueDateArr(flattenedData);
    }
  }, [docsListDownloadData, dueDateData]);

  useEffect(() => {
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const filtered = dueDateArr.filter((data) => {
      const eventDate = new Date(data.date);
      return (
        eventDate.getMonth() === currentMonth &&
        eventDate.getFullYear() === currentYear
      );
    });
    setFilteredDates(filtered);
  }, [date, dueDateArr]);

  return (
    <div className="flex flex-col gap-[1rem]">
      {/* finance tips */}
      <div className="bg-white rounded-xl shadow-md p-[1rem]">
        <h4 className="font-[500] text-[1rem] text-lightGray">Finance Tips</h4>

        <div className="bg-[#FAF8FC] px-[1rem] py-[1.5rem] rounded-md mt-[2rem]">
          <h4 className="font-[500] text-[0.9rem] text-darkGray">
            Maximize Tax Deductions
          </h4>
          <p className="text-[0.8rem] text-lightGray">
            Save taxes by using deductions under Section 80C for investments
            like Section 80D for heatlh insurance.
          </p>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-md p-[1rem] min-h-[36rem]">
        <h4 className="font-[500] text-[1rem] text-lightGray mb-[1.5rem] ">Upcoming due dates</h4>
        <div className="overflow-y-scroll scrollbar-hide max-h-[30rem]">
          {filteredDates.length > 0 ? (
            filteredDates.map((data, index) => (
              <div key={index} className="">
                <span className="mb-2 bg-[#D3C1E1] px-2 py-1 rounded-md text-[#344054] font-[500] text-[.8rem] leading-[.9rem]">
                  {data.date}
                </span>
                <ul>
                  <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                    {data.compliance_activity}
                  </li>
                  <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                    {data.penalty_fine_interest}
                  </li>
                </ul>
              </div>
            ))
          ) : (
            <p>No important dates for this month.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileAndCertificate;
