import React from "react";

function RegistrationStep() {
  const stepsArr = [
    {
      step: "STEP 01",
      head: "Order Online",
      para: "Start your registrtion right on our website.",
    },
    {
      step: "STEP 02",
      head: "Connect & Clarify",
      para: "Our rep will discuss pricing and documents. Approve & pay 50% down securely.",
    },
    {
      step: "STEP 03",
      head: "Upload Documents",
      para: "Submit your documents easily through our online portal..",
    },
    {
      step: "STEP 04",
      head: "We File for You",
      para: "Our experts handle filing & keep you updated with status tracking.",
    },
    {
      step: "STEP 05",
      head: "Government Review",
      para: "The government will review your application (Takes a few days typically).",
    },
    {
      step: "STEP 06",
      head: "Certificate Received",
      para: "We'll receive your official registration certificate (PDF).",
    },
    {
      step: "STEP 07",
      head: "Finalize & Download",
      para: "Pay the remaining 50%, download your certificate directly!",
    },
  ];
  return (
    <>
      <div className=" bg-gray-100 min-h-screen p-[6%]">
        <h2 className="lg:text-center md:text-center lg:px-[25%] md:px-[25%] font-[700] text-[1.9rem] leading-[2.5rem] md:text-[2.3rem] md:leading-[3rem] lg:text-[2.3rem] lg:leading-[3rem]">Effortless Registration in 7 Steps with BeyondTax</h2>
        <div className="flex gap-6 flex-wrap justify-center">
          {stepsArr.map((data) => {
            return (
              <>
                <div  className="lg:w-[20%] md:w-[20%] w-[100%] drop-shadow-md flex flex-col">
                  <h6 className=" bg-sky-100 text-center text-[1rem] leading-[1.5rem] font-[700] p-2 m-0">
                    {" "}
                    {data.step}
                  </h6>
                  <div className=" bg-white-body h-52 px-3 pt-16">
                    <h4 className="text-[1.5rem] leading-[2rem] font-[500] lg:text-[1.3rem] md:text-[1.3rem]">{data.head}</h4>
                    <p className=" text-gray-500 font-[400] text-[1rem] leading-[1.5rem]">{data.para}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default RegistrationStep;
