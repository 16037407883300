import React, { useContext, useEffect, useState } from "react";
import notification from "assets/images/dashboard/notification.svg";
import { Link, useLocation } from "react-router-dom";
import { ProfileContext } from "./Layout";

const TopProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const regexPath = /^\/income-tax-summary(\/.*)?$/;
  const regexPathAssets = /^\/assets(\/.*)?$/;
  const regexPathLiabilities = /^\/liabilities(\/.*)?$/;

  const { profilePic } = useContext(ProfileContext);

  const [showPP, setShopPP] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(false);

  useEffect(() => {
    if (profilePic) {
      setShopPP(true);
    }
  }, [profilePic]);

  const firstNameLetter = user?.full_name
    ? user?.full_name.split(" ")[0].charAt(0).toUpperCase()
    : user?.business_name.split(" ")[0].charAt(0).toUpperCase();
  const secondNameLetter = user?.full_name?.split(" ")[1]
    ? user.full_name.split(" ")[1].charAt(0).toUpperCase()
    : user?.business_name?.split(" ")[1]
    ? user.business_name.split(" ")[1].charAt(0).toUpperCase()
    : "";

  const finalLetter = firstNameLetter + secondNameLetter;

  useEffect(() => {
    if (user?.account_type === 2) {
      setBusinessProfile(true);
    }
  }, [user]);

  return (
    <div className="hidden md:block">
      <div className=" flex flex-row-reverse items-center gap-3">
        <Link
          className="text-black no-underline right-0"
          to={
            user?.account_type === 2
              ? "/income-tax-business-profile"
              : "/income-tax-profile"
          }
        >
          <div className="flex flex-row-reverse items-center gap-2">
            {businessProfile ? (
              <p className="text-[#9065B4] m-0 px-[1.5%] font-[500] text-[1rem] leading-[1.3rem] whitespace-nowrap">
                {user?.business_name}
              </p>
            ) : (
              <p className="text-darkGray m-0 px-[1.5%] font-[500] text-[1rem] leading-[1.3rem] whitespace-nowrap">
                {user?.full_name}
              </p>
            )}

            {showPP === false && (
              <div className=" w-[2.6rem] h-[2.4rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                {" "}
                <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                  {finalLetter}
                </p>
              </div>
            )}
            {showPP === true && (
              <div className=" w-[2.8rem] h-[2.6rem] rounded-full overflow-hidden">
                <img
                  className="w-full h-full object-cover "
                  src={profilePic}
                  alt="profile"
                />
              </div>
            )}

            <div className="">
              <img src={notification} alt="" />
            </div>
          </div>
        </Link>
        {(regexPath.test(location.pathname) ||
          regexPathAssets.test(location.pathname) ||
          regexPathLiabilities.test(location.pathname)) &&
          user?.pan_no && (
            <div className="flex">
              <h4 className="text-[#334155] font-[500] text-[1rem] m-0">PAN:</h4>
              &nbsp;
              <h4 className="text-[#64748B] font-[400] text-[1rem] m-0">
                {user.pan_no}
              </h4>
            </div>
          )}
      </div>
    </div>
  );
};

export default TopProfile;
