import React, { useEffect, useState } from "react";
import Input from "components/input/Input";
import logo from "assets/images/dashboard/logo.svg";
import "assets/images/login/Section.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import backIcon from "assets/images/login/backIcon.png";
import setPswd from "assets/images/login/setPswd.png";
import loginView from "assets/images/login/LoginFrontView.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";

//API's
import usePostSetPswd from "./Data/usePostSetPswd";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

function SetNewPswd() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const [data, error, isLoading, postSetPswd] = usePostSetPswd();
  const navigate = useNavigate();
  const location = useLocation();
  // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (data) {
      showSuccessToast("Password reseted");
      navigate("/newLogin");
    }
  }, [data, navigate]);

  useEffect(() => {
    if (submitClicked && password && confirmPassword) {
      setSubmitClicked(false);
    }
  }, [submitClicked]);

  useEffect(() => {
    if (error) {
      let desc =
        error?.err?.response?.data?.data?.non_field_errors ||
        "Something went wrong. Please try again.";
      showErrorToast("", desc);
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validatePswd = {
      otp_id: location.state.otp_id,
      password: password,
      confirm_password: confirmPassword,
    };

    if (password.length < 8) {
      showErrorToast("", "Password must be at least 8 characters long.");
      return;
    }
    if (password !== confirmPassword) {
      showErrorToast("", "Passwords do not match.");
      return;
    }
    setSubmitClicked(true);
    postSetPswd(validatePswd);
  };

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="slightDarkBlack grid grid-cols-12 bg-white-body h-screen">
        <div className=" col-span-6 p-4">
          <Link to={"/"}>
            {" "}
            <img src={logo} alt="" />
          </Link>
          <div className="flex flex-col items-center h-full justify-center ">
            <img src={setPswd} alt="set pswd" className="mb-4" />
            <div className=" w-[60%] ">
              <h2 className=" font-[700] leading-[2.1rem] text-[1.9rem] mb-4 flex justify-center items-center">
                Set new password
              </h2>
              <p className=" text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex justify-center items-center mb-4 text-center">
                Your new password must be different to previously used
                passwords.
              </p>
              <div className="relative w-full">
                <Input
                  label={"Password"}
                  type={showPassword ? "text" : "password"}
                  id={"password"}
                  placeholder={"Enter your password"}
                  value={password}
                  setValue={setPassword}
                />
                <p className="text-[#667085] mb-4">
                  Must be at least 8 characters.
                </p>
                {/* Eye toggle icon */}
                <span
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaEyeSlash size={18} />
                  ) : (
                    <FaEye size={18} />
                  )}
                </span>
              </div>
              <div className="relative w-full">
                <Input
                  label={"Confirm Password"}
                  id={"confirmPassword"}
                  placeholder={"Enter your Confirm Password"}
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                />
                {/* Eye toggle icon */}
                <span
                  className="absolute right-3 top-[3rem] transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <FaEyeSlash size={18} />
                  ) : (
                    <FaEye size={18} />
                  )}
                </span>
              </div>
            </div>
            <br />
            <button
              onClick={handleSubmit}
              className="bg-[#94A3B8] rounded-md px-2 py-1.5 w-[60%] text-white"
            >
              Reset password
            </button>
            <div className="flex justify-between my-2 items-center">
              <Link
                to="/newLogin"
                style={{ textDecoration: "none", color: "#667085" }}
              >
                <button className="m-0 flex gap-3">
                  <img src={backIcon} alt="backIcon" className="py-1" />
                  Back to log in
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-6 border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
          <div className="flex flex-col items-center justify-center h-full">
            <div>
              <img src={loginView} alt="loginView" />
            </div>
            <div className="flex flex-col items-center">
              <p className="m-0 text-[#334155] text-[1rem] font-[600]">
                Simplify Your Finances with Beyondtax
              </p>
              <p className="m-0 text-[#334155] text-[0.9rem]">
                Manage your finances, tax, and compliance all in one place.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default SetNewPswd;
