import React, { useState } from "react";
import { ServiceDetails } from "components/serviceDetails/GetServiceDetails";
function ServiceFaq(props) {
  const [showPara1, setShowPara1] = useState(true);
  const [showPara2, setShowPara2] = useState(false);
  const [showPara3, setShowPara3] = useState(false);
  const [showPara4, setShowPara4] = useState(false);
  const [showPara5, setShowPara5] = useState(false);

  return (
    <>
      {props.sendData && (
        <>
          {" "} 
          <div className="slightDarkBlack grid grid-cols-12 p-[8%]">
            <div className="lg:col-span-6 md:col-span-6 col-span-12">
              <h2 className="font-[700] lg:text-[3rem] lg:leading-[3.5rem]  md:text-[3rem] md:leading-[3.5rem] text-[1.4rem] leading-[1.6rem]">
                {ServiceDetails.serviceFaq.heading}
              </h2>
              <p className=" font-[500] text-[1rem] leading-[1.3rem] text-gray-500">
                {ServiceDetails.serviceFaq.paraBelowH}
              </p>
              <button className="FaqBtn lg:flex md:flex hidden">
                {ServiceDetails.serviceFaq.btn}
                {/* Contact Us */}
              </button>
            </div>
            <div className="lg:col-span-6 md:col-span-6 col-span-12">
              <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
                <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
                  {ServiceDetails.serviceFaq.difference.ques}
                </h5>

                <img
                  id="style1"
                  className={`${
                    showPara1 ? "" : "transform -rotate-180 duration-200"
                  } col-start-12`}
                  onClick={() => {
                    setShowPara1(!showPara1);
                  }}
                  src={ServiceDetails.serviceFaq.difference.img}
                />
                <br />

                {showPara1 && (
                  <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
                    {ServiceDetails.serviceFaq.difference.ans}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
                <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
                  {ServiceDetails.serviceFaq.time.ques}
                </h5>

                <img
                  id="style2"
                  className={`${
                    showPara2 ? "transform -rotate-180 duration-200" : ""
                  } col-start-12`}
                  onClick={() => {
                    setShowPara2(!showPara2);
                  }}
                  src={ServiceDetails.serviceFaq.img}
                />
                <br />

                {showPara2 && (
                  <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
                    {ServiceDetails.serviceFaq.time.ans}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
                <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
                  {ServiceDetails.serviceFaq.noAadhar.ques}
                </h5>

                <img
                  id="style3"
                  className={`${
                    showPara3 ? "transform -rotate-180 duration-200" : ""
                  } col-start-12`}
                  onClick={() => {
                    setShowPara3(!showPara3);
                  }}
                  src={ServiceDetails.serviceFaq.img}
                />
                <br />

                {showPara3 && (
                  <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
                    {ServiceDetails.serviceFaq.noAadhar.ans}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
                <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
                  {ServiceDetails.serviceFaq.moreBuss.ques}
                </h5>

                <img
                  id="style4"
                  className={`${
                    showPara4 ? "transform -rotate-180 duration-200" : ""
                  } col-start-12`}
                  onClick={() => {
                    setShowPara4(!showPara4);
                  }}
                  src={ServiceDetails.serviceFaq.img}
                />

                <br />
                {showPara4 && (
                  <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
                    {ServiceDetails.serviceFaq.moreBuss.ans}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-12 bg-white-body my-4 p-4 rounded-2xl drop-shadow-md items-center">
                <h5 className=" col-span-10 font-[500] text-[1.1rem] leading-[1.6rem]">
                  {ServiceDetails.serviceFaq.editForm.ques}
                </h5>

                <img
                  id="style5"
                  className={`${
                    showPara5 ? "transform -rotate-180 duration-200" : ""
                  } col-start-12`}
                  onClick={() => {
                    setShowPara5(!showPara5);
                  }}
                  src={ServiceDetails.serviceFaq.img}
                />

                <br />
                {showPara5 && (
                  <p className=" col-start-1 col-span-10 font-[400] text-[1rem] leading-[1.3rem] text-gray-500">
                    {ServiceDetails.serviceFaq.editForm.ans}
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ServiceFaq;
