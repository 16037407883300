import useAPICall from "hooks/useAPICall";
// import en from "../../../Lang/en.json";
import { POST_VALIDATE_OTP_API_URL } from "constants/apis";

const usePostValidateOtp = () => {
  const [data, error, isLoading, callPostValidateOtp, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(true);
    setSuccessData(undefined);  
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const validateOtp = (body) => {
    const url = POST_VALIDATE_OTP_API_URL;
    const config = {};
    callPostValidateOtp({
      url,
      method: "POST",
      statusObj, 
      config,
      defaultFallback,
      body,
    });
  };
  
  return [data, error, isLoading, validateOtp, setSuccessData, setError];
};

export default usePostValidateOtp;
