import Button from "components/Button/BtnOrange";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import AddNewVehicle from "./Add/AddNewVehicle";
import AddNewGold from "./Add/AddNewGold";
import AddNewFurniture from "./Add/AddNewFurniture";
import useGetPersonalCategoryAssets from "../data/useGetPersonalCategoryAssets";

const AddNewPersonalAsset = ({triggerElement}) => {
  const [getData, getError, getIsLoading, getPersonalCategoryAssets] =
  useGetPersonalCategoryAssets();
  const [isAddLand, setIsAddLand]=useState(false);
  const [isGold, setIsGold]=useState(false);
  const [isArt, setIsArt]= useState(false)
  const addVehicleBtnRef= useRef(null)
  const addGoldBtnRef= useRef(null)
  const addArtButtonRef= useRef(null)
  const [categoryData, setCategoryData]=useState([]);
  const [id, setId]= useState("")

  useEffect(()=>{
   getPersonalCategoryAssets()
  },[])

  useEffect(()=>{
   if(getData?.data){
    setCategoryData(getData.data)
   }
  },[getData])

  const handleOpenDilog=(type, ID)=>{
    setId(ID)
     if(type==="Vehicle"){
       setIsAddLand(true)
     }
     if(type==="Gold And Jewellery"){
      setIsGold(true)
     }
     if(type==="Furniture And Art"){
      setIsArt(true)
     }
  }


  useEffect(()=>{
    if(isAddLand){
      addVehicleBtnRef.current.click()
    }
    if(isGold){
      addGoldBtnRef.current.click()
    }
    if(isArt){
      addArtButtonRef.current.click()
    }
  },[isAddLand,isGold,isArt])
  
  return (
    <div>
      <DialogBox
        triggerElement={ triggerElement ? triggerElement :
          <Button
            px={"px-[0.9rem]"}
            py={"py-[0.3rem]"}
            name="+ Add New Personal Asset"
          />
        }

        heading={
          <h4 className="text-lightGray font-[500] text-[1rem]">
            Add to your Personal Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem] m-0">
            Choose an asset type
            </h4>
            <div className="border rounded-md mt-[1rem] mb-[0.6rem]">
              <div className="flex flex-col gap-2 p-[0.8rem]">
                {
                   categoryData.map((el,i)=>{
                    return(
                      <div
                      key={i} 
                      onClick={()=>{
                        handleOpenDilog(el.asset_name, el.id)
                        handleClose()
                      }}
                       className="bg-[#FAF8FC] border-1 border-[#EAE1F3] rounded-sm flex justify-start gap-2 items-center py-1 px-2 cursor-pointer">
                        <img src={el.asset_image} alt={el.asset_name} className="w-5 h-5" />
      
                        <p className="text-darkGray text-[0.9rem] m-0">
                        {el.asset_name}
                        </p>
                      </div>
                    )
                   })
                }
              </div>
            </div>
          </>
        )
        }
      />
      {
        isAddLand && <AddNewVehicle
        categoryId={id}
        setIsAddLand={setIsAddLand}
        isAddLand={isAddLand}
        triggerElement={
          <button ref={addVehicleBtnRef} className="hidden">
            Add Land
          </button>
        }
        />
      }
      {
        isGold && <AddNewGold
        categoryId={id}
         setIsGold={setIsGold}
         isGold={isGold}
         triggerElement={
          <button ref={addGoldBtnRef} className="hidden">
            Add Gold
          </button>
        }
        />
      }
      {
        isArt && <AddNewFurniture
        categoryId={id}
        setIsArt={setIsArt}
        isArt={isArt}
        triggerElement={
         <button ref={addArtButtonRef} className="hidden">
           Add Art
         </button>
       }
        />
      }
    </div>
  );
};

export default AddNewPersonalAsset;
