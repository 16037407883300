import React, { useState, useEffect } from "react";
import "assets/images/login/Section.svg";
import logo from "assets/images/dashboard/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import otpIcon from "assets/images/login/otpIcon.png";
import backIcon from "assets/images/login/backIcon.png";
import loginView from "assets/images/login/LoginFrontView.svg";

// API's
import usePostValidateOtp from "./Data/usePostValidateOtp";
import usePostSendOTP from "./Data/usePostSendOTP";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import usePostResendSendOTP from "./Data/usePostResendOtp";

function Otp() {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [submitClicked, setSubmitClicked] = useState(false);
  const [otpID, setOtpID] = useState("");

  const [data, error, isLoading, postValidateOtp] = usePostValidateOtp();
  const [SendData, SendError, SendisLoading, sendPostOtp] = usePostResendSendOTP();

  useEffect(() => {
    setOtpID(location.state.otp_id)
  }, []);
  
  useEffect(() => {
    if (data) {
      showSuccessToast("OTP validated successfully. Email verified");
      navigate("/set-password", {
        state: { otp_id: location.state.otp_id },
      });
      console.log("validateOtpData:", data);
    }
  }, [data, navigate]);

  console.log("otp id:", otpID)

  const handleSubmit = () => {
    const validationOtp = {
      // otp_id: location.state.otp_id,
      otp_id: otpID,
      otp: otp.join(""),
    };
    postValidateOtp(validationOtp);
  };

  useEffect(() => {
    if (error) {
      let desc =
        error?.err?.response?.data?.data?.message ||
        "Failed to validate OTP. Please try again.";
      showErrorToast("", desc);
    }
  }, [error]);

  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value === "") {
      const prevSibling = document.getElementById(`otp-${index - 1}`);
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    } else if (/^[0-9]$/.test(value) && index < 3) {
      const nextSibling = document.getElementById(`otp-${index + 1}`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      const prevSibling = document.getElementById(`otp-${index - 1}`);
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    }
  };

  const handleResend = (e) => {
    setSubmitClicked(true);
    // setOtpResent(true);
  };

  useEffect(() => {
    if (submitClicked && location.state.email) {
      sendPostOtp({ email: location.state.email });
      setSubmitClicked(false);
    }
  }, [submitClicked, location.state.email, sendPostOtp]);

  useEffect(() => {
    if (SendData) {
      showSuccessToast("OTP resent successfully");
      setOtpID(SendData?.data?.otp_id);
    } else if (SendError) {
      let desc = SendError.message || "Failed to resend OTP. Please try again.";
      showErrorToast("", desc);
    }
  }, [SendData, SendError]);

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="slightDarkBlack grid grid-cols-12 bg-white-body h-screen">
        <div className=" col-span-6 p-4">
          <Link to={"/"}>
            {" "}
            <img src={logo} alt="" />
          </Link>
          <div className="flex flex-col items-center  justify-center ">
            <img src={otpIcon} alt="otp icon" className="mb-4" />
            <div className=" w-[60%]">
              <h2 className=" font-[700] leading-[2.1rem] text-[1.9rem] mb-4 flex justify-center items-center">
                OTP verification
              </h2>
              <p className=" text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex justify-center items-center">
                We sent a verification link to <br />
                {location.state.email}
              </p>
              <div className="flex justify-center mb-4 space-x-2">
                {otp.map((data, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    className="w-20 h-20 text-center border rounded-md text-[1.9rem] font-medium text-[#9065B4] focus:outline-[#D6BBFB]"
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="bg-[#94A3B8] rounded-md px-2 py-1.5 w-[60%] text-white"
            >
              Verify email
            </button>

            <p className=" text-gray-400 text-center my-2">
              Didn't receive the email? {/* {!otpResent && ( */}
              <button onClick={handleResend} className="m-2 text-purple-500">
                Click to resend
              </button>
              {/* )} */}
            </p>
            <div className="flex justify-between my-2 items-center">
              <Link
                to="/newLogin"
                style={{ textDecoration: "none", color: "#667085" }}
              >
                <button className="m-0 flex gap-3">
                  <img src={backIcon} alt="backIcon" className="py-1" />
                  Back to log in
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-6 border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
          <div className="flex flex-col items-center justify-center h-full">
            <div>
              <img src={loginView} alt="loginView" />
            </div>
            <div className="flex flex-col items-center">
              <p className="m-0 text-[#334155] text-[1rem] font-[600]">
                Simplify Your Finances with Beyondtax
              </p>
              <p className="m-0 text-[#334155] text-[0.9rem]">
                Manage your finances, tax, and compliance all in one place.
              </p>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Otp;
