import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import Button from "components/Button/BtnOrange";
import InputField from "components/input/InputField";
import upload from "assets/images/liabilities/upload.svg";
import usePostIntellectualCommercial from "../data/usePostIntellectualCommercial";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import usePatchCommercialIntellectual from "../data/usePatchCommercialIntellectual";

const AddNewIntellectualCommercial = ({assetId, itemId, setOpenIntellectual, openIntellectual, setIsReload, triggerElement, detailData , patchData}) => {
const [
    postCommercialData,
    postCommercialError,
    postCommercialIsLoading,
    PostCommercial,
    ] = usePostIntellectualCommercial();
const [
        patchCommercialData,
        patchCommercialError,
        patchCommercialIsLoading,
        PatchCommercial,
        ] = usePatchCommercialIntellectual();
const [isClose, setIsClose]= useState(false);
const [doPatch, setDoPatch]= useState(false)
 const [newIntellectForm, setNewIntellectForm]= useState({
    associated_business_name :"",
    payout_frequency:"",
    estimated_income:"",
    agreement:null
 })
 useEffect(()=>{
    if(patchData.value){
      if(detailData?.commercial_information?.length !== 0){
        setDoPatch(true)
        const individualForm=detailData.commercial_information.filter((el,i)=>{
            return (el.id === patchData.id)
        })
        setNewIntellectForm(individualForm[0])
        }
    }
 },[detailData, patchData])
 useEffect(()=>{
  if(postCommercialData){
    showSuccessToast("Data posted successfully");
    setIsClose(true)
    setIsReload(true)
  }
  if(postCommercialError){
    showErrorToast("Error in posting data")
  }
 },[postCommercialData,postCommercialError])
 useEffect(()=>{
    if(patchCommercialData){
      showSuccessToast("Data updated successfully");
      setIsClose(true)
      setIsReload(true)
      setNewIntellectForm({
        associated_business_name :"",
        payout_frequency:"",
        estimated_income:"",
        agreement:null
     })
    }
    if(patchCommercialError){
      showErrorToast("Error in updating data")
    }
   },[patchCommercialData,patchCommercialError])
 const [errors, setErrors]= useState({})

 const validateNewIntellectForm = () => {
    const newErrors = {};
  
    // Associated Business Name: Required, only letters and spaces allowed
    const namePattern = /^[a-zA-Z\s]+$/;
    if (!newIntellectForm.associated_business_name) {
      newErrors.associated_business_name = "Associated Business Name is required.";
    } else if (!namePattern.test(newIntellectForm.associated_business_name)) {
      newErrors.associated_business_name = "Name should only contain letters and spaces.";
    }
  
    // Payout Frequency: Required
    // if (!newIntellectForm.payout_frequency) {
    //   newErrors.payout_frequency = "Payout Frequency is required.";
    // }
  
    // Estimated Income: Required, numeric, and positive
    // if (!newIntellectForm.estimated_income) {
    //   newErrors.estimated_income = "Estimated Income is required.";
    // } else if (isNaN(newIntellectForm.estimated_income) || newIntellectForm.estimated_income <= 0) {
    //   newErrors.estimated_income = "Estimated Income should be a positive number.";
    // }
  
    // if(doPatch){

    // }else{
    //      // Agreement File: Required file, valid format (PDF, JPG, PNG)
    // const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
    // if (!newIntellectForm.agreement) {
    //   newErrors.agreement = "Agreement file is required.";
    // } else if (newIntellectForm.agreement && !filePattern.test(newIntellectForm.agreement.name)) {
    //   newErrors.agreement = "File should be a valid format (PDF, JPG, or PNG).";
    // }
    // }
  
    setErrors(newErrors);
    console.log('newErrors', newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmitNewIntellectForm = () => {
    if (!validateNewIntellectForm()) return;
  
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append the form fields to the FormData only if they are valid
    if (isNotEmpty(newIntellectForm.associated_business_name)) {
      formData.append("associated_business_name", newIntellectForm.associated_business_name);
    }
    if (isNotEmpty(newIntellectForm.payout_frequency)) {
      formData.append("payout_frequency", newIntellectForm.payout_frequency);
    }
    if (isNotEmpty(newIntellectForm.estimated_income)) {
      formData.append("estimated_income", newIntellectForm.estimated_income);
    }
  
    // Check for the agreement field only if it's a valid file and not a string
    if (typeof newIntellectForm.agreement !== "string" && newIntellectForm.agreement) {
      formData.append("agreement", newIntellectForm.agreement);
    }
  
    console.log("New Intellect Form Data Submitted:", formData);
    console.log("Form in submission:", newIntellectForm);
  
    // API call based on the `doPatch` flag
    if (doPatch) {
      formData.append("id", newIntellectForm.id);
      PatchCommercial(formData, `${assetId}/`);
    } else {
      PostCommercial(formData, `${assetId}/?intellectual_property_id=${itemId}`);
    }
  };
  
  
 const handleInputChange=(e)=>{
    let { name, value, type, files } = e.target;
    setNewIntellectForm((prevForm)=>(
        {
            ...prevForm,
            [name]: type === 'file' ? files[0] : value
        }
    ))
 }

  const getContent = () => { 
    return (
        <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
        <div className="grid grid-cols-12 gap-3 items-center mt-3">
          <div className="flex flex-col gap-1 col-span-6 group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
              Name of Associated Business
            </label>
            <InputField
              type="text"
              className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
              value={newIntellectForm.associated_business_name}
              onChange={(e)=>handleInputChange(e)}
              name="associated_business_name"
              placeholder="Acme"
            />
            {errors.associated_business_name && <p className="text-red-500 text-[0.75rem]">{errors.associated_business_name}</p>}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3 mt-3">
        <div className="input-container col-span-6 flex flex-col gap-1 group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
              Payout Frequency
            </label>
            <InputField
              type="select"
              className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
              options={[
                { value: "", label: "Select Type" },
                { value: "Monthly", label: "Monthly" },
                { value: "Quarterly", label: "Quarterly" },
                { value: "Annually", label: "Annually" },
              ]}
              name="payout_frequency"
              value={newIntellectForm.payout_frequency}
              onChange={(e)=>handleInputChange(e)}
            />
            {errors.payout_frequency && <p className="text-red-500 text-[0.75rem]">{errors.payout_frequency}</p>}
          </div>
          <div className="col-span-6 group">
            <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
              Estimated Income
            </label>
            <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
              <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                Rs.
              </span>
              <InputField
                type="number"
                name="estimated_income"
                value={newIntellectForm.estimated_income}
                onChange={(e)=>handleInputChange(e)}
                className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
              />
            </div>
            {errors.estimated_income && <p className="text-red-500 text-[0.75rem]">{errors.estimated_income}</p>}
          </div>
        </div>
        <div onClick={()=>document.getElementById(`file-upload-agree`).click()} className="w-[44%] cursor-pointer px-3 py-1.5 flex items-center  border-[1px] rounded-lg gap-1.5 bg-white mt-4">
            <img src={upload} alt="uploadPic" />
            <p className="text-[#334155] m-0 font-[600] text-[0.9rem]">Upload Agreement</p>
          </div>
          {errors.agreement && <p className="text-red-500 text-[0.75rem]">{errors.agreement}</p>}
          <InputField 
            id={`file-upload-agree`}
            type="file"
            onChange={handleInputChange}
            className="hidden"
            name="agreement"
            />
      </div>
      );
  };
  return (
    <div>
      <DialogBox
        triggerElement={triggerElement}
        resetClose={setOpenIntellectual}
        isNotDefault={openIntellectual}
        heading={"Add Commercial User"}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} onClick={handleSubmitNewIntellectForm} />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewIntellectualCommercial;
