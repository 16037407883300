import Button from "components/Button/BtnOrange";
import React, { useEffect, useRef, useState } from "react";
import AddYourLiability from "../AddYourLiability";

// images
import personalLoanHeart from "assets/images/PersonalLoan/personalLoanHeart.svg";
import personalHome from "assets/images/PersonalLoan/Liabilities Icons.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { FAMILY_LOAN_DETAILED_VIEW} from "constants/routes";
import useGetIndividualLoansOverView from "../data/useGetIndividualLoansOverView";
import AddLoanTypeDialog from "../AddLoanTypeDialog";
import LiabilitiesHeading from "../LiabilitiesHeading";

const FamilyLoan = () => {
  const [getData, getError, getIsLoading, getLiabilitiesOverview] =
  useGetIndividualLoansOverView();
  const [initialState, setInitialState]=useState(false)
  const [loanDetails, setLoanDetails]=useState([])
  const loanButtonRef=useRef(null)
  const location = useLocation();
  // Extract the query parameters
  const queryParams = new URLSearchParams(location.search);
  // Get the `id` from the query parameters
  const id =decodeURIComponent(queryParams.get('id'));
  const name = decodeURIComponent(queryParams.get('name'));
  const loan_type = location.state?.loan_type;
  
  useEffect(()=>{
    getLiabilitiesOverview({},id)
  },[])
  useEffect(()=>{
   if(getData?.data.length>0){
     setInitialState(true)
     setLoanDetails(getData.data)
   }
  },[getData])
  const handleOpenLoanTypeModal=()=>{
    loanButtonRef.current.click();
  }
  const BankDetails = [
    {
      id: 1,
      bankName: "Dheeraj Rao",
      amount: "₹40 Thousand",
    },
  ];

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
        )}
     <div className="px-[1rem] bg-gray-100 h-screen">
      <LiabilitiesHeading 
        loan_type={loan_type}
      />
            <div  className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Liabilities Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
            ₹{getData?.data[0]?.total_loan_value || 0}  
          </h4>
        </div>

        <div className="p-[1rem] col-span-2" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Liabilities
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
          {getData?.data[0]?.total_liabilities || 0}           </h4>
        </div>

        <div className="p-[1rem] col-span-2" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1rem]">
          Active Liabilities
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
          {getData?.data[0]?.active_liabilities_count || 0}           </h4>
        </div>

        <div className="p-[1rem] col-span-2" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1rem]">
          Closed Liabilities
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">
          {getData?.data[0]?.closed_liabilities_count || 0}           </h4>
        </div>
        <div className="cursor-pointer flex justify-center items-center p-[1rem] col-span-3" >
        <AddYourLiability />
        </div>
      </div>
      <div className="border bg-white rounded-xl p-[1rem] mt-3">
      <div className="flex justify-between items-center">
      <p className="m-0 text-[#334155] font-[600] text-[1rem]">
          Family Loans{" "}
          <span className="text-[#DC2626] rounded-sm p-1 font-[600] bg-[#FEF2F2] text-[0.9rem]">
          {/* {dataToView?.total_family_and_informal_loan_amount || 0} Rs. */}
          {getData?.data[0]?.total_loan_value || 0}  Rs.
          </span>
        </p>
        <button onClick={handleOpenLoanTypeModal} className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                Add New
          </button>
      </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {/* <div className={`rounded-md border-2 border-dashed p-[1rem] ${initialState?"hidden":""}`}>
            <img src={personalLoanHeart} alt="heart" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Family Loan
            </h5>
            <div className="flex justify-end mt-[2.5rem]">
              <button onClick={handleOpenLoanTypeModal} className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                Add New
              </button>
            </div>{" "}
          </div> */}

          {/* when get data is present */}
          {loanDetails.map((item, index) => (
            <>
              <Link
              className={`no-underline ${initialState? "":"hidden"} bg-[#FEF2F2] border border-[#D9D9D9] rounded-md`}
              state={{ loan_type: loan_type }}
              to={{
                pathname: `${FAMILY_LOAN_DETAILED_VIEW}`,
                search: `?loan_id=${encodeURIComponent(item.id)}&id=${encodeURIComponent(id)}`,
                // state: { loan_type: name },  // Pass the state here
              }}
            //   to={LIABILITIES_PERSONAL_LOAN_DETAILS.replace(":loan_details", item.bankName)} // Dynamic URL with bankName
              key={index} // Move key to the outer element
              >
                <div className=" p-[1rem]" >
                  <img
                    src={personalHome}
                    alt="heart"
                    className="w-10 h-10"
                  />
                  <h5 className="text-[#64748B] font-[500] text-[1rem] mt-1">
                    {item.lender_name}
                  </h5>
                  <div className="flex justify-end mt-[1.2rem]">
                    <h4 className="text-[#334155] font-[600] text-[1.3rem]">
                    <span>&#8377;</span> {item.amount_borrowed}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            </>
          ))}
        </div>
      </div>
      <AddLoanTypeDialog
        loanType={loan_type}
        loanTypeId={id}
        triggerElement={
          <button ref={loanButtonRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
    </div>
      </div>
  );
};

export default FamilyLoan;
