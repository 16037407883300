import React, { useState } from "react";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";
import DiscardChangesDialog from "containers/privateRoutes/Assets/DiscardChangesDialog";

const DialogBox = ({
  triggerElement,
  heading,
  content,
  width = "max-w-lg",
  titleFont = "text-xl",
  closeButton,
  progressSteps = [],
  isNotDefault,
  resetClose,
  steps,
  onClose, // New prop for state reset function
}) => {
  const [open, setOpen] = useState(false);
  const [cross, setCross] = useState(false);

  const handleCross = () => {
    setCross(true);
  };

  const handleClose = () => {
    if (isNotDefault) {
      resetClose(false);
    }
    setOpen(false);
    if (onClose) {
      onClose(); // Call the onClose function to reset parent states
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const closeAllDialogs = () => {
    setCross(false);
    setOpen(false);
    if (onClose) {
      onClose(); // Reset any parent states if provided
    }
  };

  return (
    <>
      <div onClick={handleOpen} className="cursor-pointer">
        {triggerElement ? triggerElement : ""}
      </div>

      {open && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-65 flex justify-center items-center z-50">
          <div
            className={`bg-white relative p-6 rounded-2xl shadow-lg w-full ${width} max-h-[90vh] overflow-auto scrollbar-hide`}
          >
            {/* Close Button */}
            <button
              className="group absolute top-[1.5rem] right-7 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
              onClick={handleCross}
            >
              <img alt="" src={closeIcon} className="w-3 h-3 group-hover:hidden" />
              <img
                alt=""
                src={closeIconRed}
                className="w-4 h-4 hidden group-hover:block"
              />
            </button>

            <h2 className={`font-[600] mb-4 text-[#334155] ${titleFont}`}>
              {heading}
            </h2>

            {progressSteps.length > 0 && (
              <div className="flex items-center justify-between mb-4">
                {progressSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <div className="">
                      <div
                        className={`h-[3px] ${
                          steps ? "w-[229px]" : "w-[151px]"
                        } mb-1 rounded-md ${
                          step.visited
                            ? "bg-[#C1A7D8]"
                            : step.active
                            ? "bg-customPurple"
                            : "bg-lightGray"
                        }`}
                      ></div>

                      <div className="flex items-center gap-1">
                        {step.visited ? (
                          <input
                            type="checkbox"
                            checked={true}
                            readOnly
                            className={`form-checkbox w-3 h-3 rounded-full border-2 ${
                              step.visited
                                ? "border-[#C1A7D8] bg-[#C1A7D8]"
                                : "border-lightGray"
                            } appearance-none  checked:border-[#C1A7D8] custom-checkbox2`}
                            style={{ position: "relative" }} // To position the custom checkmark
                          />
                        ) : (
                          <input
                            type="radio"
                            checked={step.active}
                            readOnly
                            className={`form-radio w-3 h-3 rounded-full ${
                              step.active
                                ? "custom-radio text-customPurple"
                                : ""
                            } ${
                              step.visited
                                ? "accent-[#C1A7D8] text-[#C1A7D8] checked"
                                : ""
                            }`}
                          />
                        )}

                        <span
                          className={`font-[500] text-[0.75rem] ${
                            step.active ? "text-darkGray" : ""
                          } ${step.visited ? "text-[#C1A7D8]" : ""}`}
                        >
                          {step.label}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}

            <div className="">
              {typeof content === "function" ? content(handleClose) : content}
            </div>
            <div className="flex justify-end">
              <button onClick={handleClose}>{closeButton}</button>
            </div>
          </div>
        </div>
      )}

      {cross && (
        <DiscardChangesDialog
          open={cross}
          closeDiscardDialog={() => setCross(false)}
          close={closeAllDialogs} // Close all dialogs when discard changes is dismissed
        />
      )}
    </>
  );
};

export default DialogBox;
