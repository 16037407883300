import React from "react";

const Tooltip = ({ content, children, position = "top", showOnScreenSize = "md" }) => {
  const positions = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
  };

  const screenSizes = {
    sm: "sm:hidden group-hover:block",
    md: "md:hidden group-hover:block",
    lg: "lg:hidden group-hover:block",
    xl: "xl:hidden group-hover:block",
  };


  return (
    <div className="relative group inline-block">
      {children}
      <div
      className={`absolute ${positions[position]} hidden md:group-hover:block bg-black opacity-75 text-white font-[300] text-[0.8rem] rounded-2xl p-3 shadow-lg z-10 min-w-[250px] max-w-[400px] `}
      >
        {content}
       
      </div>
    </div>
  );
};

export default Tooltip;
