import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";
import alarmPic from "assets/images/assetsAndLiabilities/alarm-check.svg";
import manage from "assets/images/assets/manage.svg";
import monitor from "assets/images/assets/monitor.svg";
import assetMain from "assets/images/assets/assetMain.svg";
import AddNewLand from "./RealEstate/Add/AddNewLand";
import AddNewDepositType from "./Deposits/AddNewDepositType";
import useGetAsset from "./data/useGetAsset";
import AssetsOverViewPage from "./AssetsOverViewPage";
import useGetTotalAsset from "./data/useGetTotalAsset";
import AddNewGold from "./Personal Assets/Add/AddNewGold";
import AddNewVehicle from "./Personal Assets/Add/AddNewVehicle";
import AddNewFurniture from "./Personal Assets/Add/AddNewFurniture";
import AddNewDomain from "./Other Assets/Add/AddNewDomain";
import AddNewIntellectual from "./Other Assets/Add/AddNewIntellectual";
import AddNewLoan from "./Other Assets/Add/AddNewLoan";
import { ProfileContext } from "../Profile/Layout";

const AssetsLandingPage = () => {
  const realEstateBtnRef = useRef(null);
  const depositsBtnRef = useRef(null);
  const vehicleBtnRef = useRef(null);
  const goldBtnRef = useRef(null);
  const artBtnRef = useRef(null);
  const familyLoanBtnRef = useRef(null);
  const domainBtnRef = useRef(null);
  const intellectualBtnRef = useRef(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [individualId, setIndividualId] = useState("");

  const {setAssetIds}= useContext(ProfileContext)

  const [getData, error, isLoading, getAsset] = useGetAsset();
  const [
    getTotalAssetData,
    getTotalAssetError,
    getTotalAssetIsLoading,
    getTotalAsset,
  ] = useGetTotalAsset();

  useEffect(() => {
    getAsset();
    getTotalAsset();
  }, []);

  useEffect(() => {
    if (getData) {
      const typeWithId = {};
      getData.data.forEach((item) => {
        typeWithId[item.asset_name] = item.id;
      });
      setIndividualId(typeWithId);
      setAssetIds(typeWithId)
    }
  }, [getData]);

  const handleAssetTypeClick = (assetType, assetId) => {
    setSelectedAssetType(assetType);
    setSelectedAssetId(assetId);
    const realEstate = ["Land", "Buildings"];
    const deposits = ["Sukanya Samriddhi Yojana"];
    const vehicle = ["Vehicle"];
    const gold = ["Gold And Jewellery"];
    const art = ["Furniture And Art"];
    const familyLoan = ["Loans To Family/Friends"];
    const intellectual = ["Intellectual Property"];
    const domain = ["Domain"];
    if (realEstate.includes(assetType)) {
      realEstateBtnRef.current.click();
    } else if (deposits.includes(assetType)) {
      depositsBtnRef.current.click();
    } else if (vehicle.includes(assetType)) {
      vehicleBtnRef.current.click();
    } else if (gold.includes(assetType)) {
      goldBtnRef.current.click();
    } else if (art.includes(assetType)) {
      artBtnRef.current.click();
    } else if (familyLoan.includes(assetType)) {
      familyLoanBtnRef.current.click();
    } else if (domain.includes(assetType)) {
      domainBtnRef.current.click();
    } else if (intellectual.includes(assetType)) {
      intellectualBtnRef.current.click();
    }
  };

  const depositToBeDisabled = [
    "Fixed Deposit",
    "Provident Fund",
    "Term Insurance",
  ];

  const Landing = () => {
    return (
      <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100 ">
        <h4 className=" font-[600] text-darkGray text-[1.5rem] m-0">Assets </h4>

        <div className="grid grid-cols-2 mt-[1.5rem] bg-white rounded-xl p-[1rem] border">
          <div className="flex flex-col items-center justify-center">
            <img src={assetMain} alt="main assest" className="mb-[1rem]" />
            <h4 className="text-[1.2rem] text-darkGray font-[500] m-0">
              Track all assets in one place
            </h4>
            <div className="mt-[0.8rem]">
              <div className="flex gap-[0.6rem] items-center">
                <img src={manage} alt="access" />
                <h4 className="font-[500] text-lightGray text-[0.9rem] m-0">
                  Manage documents easily
                </h4>
              </div>
              <div className="flex gap-[0.6rem] items-center my-[0.5rem]">
                <img src={alarmPic} alt="alarmPic" />
                <h4 className="font-[500] text-lightGray text-[0.9rem] m-0">
                  Get timely reminders
                </h4>
              </div>
              <div className="flex gap-[0.6rem] items-center">
                <img src={monitor} alt="access" />
                <h4 className="font-[500] text-lightGray text-[0.9rem] m-0">
                  Monitor growth and returns
                </h4>
              </div>
            </div>
          </div>
          <div className="">
            <h4 className="text-[1rem] text-darkGray font-[500] m-0">
              Start building your wealth portfolio by adding you first asset
            </h4>
            <div className="grid grid-cols-2 gap-[0.5rem] mt-[1rem]">
              {/* real Estate */}
              <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                  Real Estate
                </h4>
                {getData?.data
                  ?.filter((item) => item.asset_category === "REAL ESTATE")
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                    >
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => {
                          handleAssetTypeClick(item.asset_name, item.id);
                        }}
                      >
                        <img src={item.asset_image} alt="real estate" />
                        <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                          {item.asset_name}
                        </h4>
                      </div>
                    </div>
                  ))}
              </div>

              {/* cash and Bank */}
              <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                  Cash & Bank (Coming Soon)
                </h4>
                {getData?.data
                  ?.filter((item) => item.asset_category === "CASH AND BANK")
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className={`border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3]  ${
                        isDisabled
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      }`}
                    >
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        // onClick={() => {
                        //   handleAssetTypeClick(item.asset_name, item.id);
                        //   handleClose();
                        // }}
                      >
                        <img src={item.asset_image} alt="" />
                        <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                          {item.asset_name}
                        </h4>
                      </div>
                    </div>
                  ))}
              </div>

              {/* deposits */}
              <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                  Deposits
                </h4>
                {getData?.data
                  ?.filter((item) => item.asset_category === "DEPOSITS")
                  .map((item, index) => {
                    const isDisabled = depositToBeDisabled.includes(
                      item.asset_name
                    );
                    return (
                      <div
                        key={item.id}
                        className={`border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3]  ${
                          isDisabled
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                        }`}
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            if (!isDisabled) {
                              handleAssetTypeClick(item.asset_name, item.id);
                            }
                          }}
                        >
                          <img src={item.asset_image} alt="" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* Other assets */}
              <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                  Other Assets
                </h4>
                {getData?.data
                  ?.filter((item) => item.asset_category === "OTHER ASSETS")
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                    >
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => {
                          handleAssetTypeClick(item.asset_name, item.id);
                        }}
                      >
                        <img src={item.asset_image} alt="" />
                        <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                          {item.asset_name}
                        </h4>
                      </div>
                    </div>
                  ))}
              </div>

              {/* personal assets */}
              <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                  Personal Assets
                </h4>
                {getData?.data
                  ?.filter((item) => item.asset_category === "PERSONAL ASSETS")
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                    >
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => {
                          handleAssetTypeClick(item.asset_name, item.id);
                        }}
                      >
                        <img src={item.asset_image} alt="" />
                        <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                          {item.asset_name}
                        </h4>
                      </div>
                    </div>
                  ))}
              </div>
              {/* market */}
              <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                  Market
                </h4>
                {getData?.data
                  ?.filter((item) => item.asset_category === "MARKETS")
                  .map((item, index) => (
                    <div
                      key={item.id}
                      className={`border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3]  ${
                        isDisabled
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      }`}
                    >
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        // onClick={() => {
                        //   handleAssetTypeClick(item.asset_name, item.id);
                        //   handleClose();
                        // }}
                      >
                        <img src={item.asset_image} alt="" />
                        <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                          {item.asset_name}
                        </h4>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <AddNewLand
          estateType={selectedAssetType}
          assetId={selectedAssetId}
          triggerElement={
            <button ref={realEstateBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />

        <AddNewDepositType
          depositType={selectedAssetType}
          assetId={selectedAssetId}
          triggerElement={
            <button ref={depositsBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />

        <AddNewGold
          categoryId={selectedAssetId}
          triggerElement={
            <button ref={goldBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />
        <AddNewVehicle
          categoryId={selectedAssetId}
          triggerElement={
            <button ref={vehicleBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />
        <AddNewFurniture
          categoryId={selectedAssetId}
          triggerElement={
            <button ref={artBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />

        <AddNewDomain
          categoryId={selectedAssetId}
          triggerElement={
            <button ref={domainBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />
        <AddNewIntellectual
          categoryId={selectedAssetId}
          triggerElement={
            <button ref={intellectualBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />
        <AddNewLoan
          categoryId={selectedAssetId}
          triggerElement={
            <button ref={familyLoanBtnRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />
      </div>
    );
  };

  return (
    <>
      {getTotalAssetData?.data?.total_assets_amount !== 0 ? (
        <AssetsOverViewPage
          assetId={individualId}
          getData={getTotalAssetData}
        />
      ) : (
        <Landing />
      )}
    </>
  );
};

export default AssetsLandingPage;
