import React from "react";
import ProfileNav from "containers/privateRoutes/Dashboard/ProfileNav";
import OrdersOverView from "./OrdersOverView";
function OrdersList() {
  return (
    <>
      <div className="grid grid-cols-12">
        <div className="min-h-screen bg-gray-100  p-[2%] col-span-12 md:col-span-12">
          <OrdersOverView />
        </div>
      </div>
    </>
  );
}

export default OrdersList;
