import { BUSINESS_PROFILE_URL, DASHBOARD_PAGE_URL } from "constants/routes";
import React from "react";
import { useNavigate } from "react-router-dom";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";


const BusinessMembersAndInvites = () => {
  const navigate = useNavigate();
  const handlePrevious = () => {
    navigate(BUSINESS_PROFILE_URL);
  };

  return (
    <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%]  md:px-4 px-3">
      <div className="py-[2.2rem] gap-[1rem] items-center md:flex hidden">
          <button onClick={handlePrevious}>
            <img src={previousBtn} alt="" className="w- h-" />
          </button>
          <h4 className="text-[#334155] text-[1.4rem] font-[600] m-0">
            Members & Invites
          </h4>  
        </div>
      <div className="bg-white-body rounded-xl border border-[#E2E8F0]">
        <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
          <div className="flex flex-col gap-2">
          <h4 className="text-[#334155] text-[1rem] font-[500] m-0">
            BeyondTax Members
          </h4>
          <p className="text-[#334155] m-0 text-[0.9rem]">Key People in The Organisation</p>
          </div>
          <button
            className="!border !border-[#9065B4] rounded-md text-[0.9rem] py-1 px-2 text-[#9065B4] font-[400]"
          >
            Add Member
          </button>
        </div>
        <div className="flex justify-between w-full px-6 py-3">
           <div className="flex flex-col gap-1 w-[20%]">
            <p className="text-[#64748B] ml-1 mr-0 mb-0 mt-0 text-[0.8rem]">Name</p>
            <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
            Mahadev BIradar
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%]">
           <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Designation</p>
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
           Board
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%]">
           <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Ownership Percentage</p>
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
           15%
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%]">
           <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Permission</p>
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
           Read & Download
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%] justify-end">
           {/* <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Name</p> */}
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%] font-[600]">
           Edit Member Details
            </div>
           </div>
        </div>
      </div>
      <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
        <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
          <div className="flex flex-col gap-2">
          <h4 className="text-[#334155] text-[1rem] font-[500] m-0">
          Guests
          </h4>
          <p className="text-[#334155] m-0 text-[0.9rem]">Manage who has access to this workspace</p>
          </div>
          <button
            className="!border !border-[#9065B4] rounded-md text-[0.9rem] py-1 px-2 text-[#9065B4] font-[400]"
          >
            Add Guest
          </button>
        </div>
        <div className="flex justify-between w-full px-6 py-3">
           <div className="flex flex-col gap-1 w-[20%]">
            <p className="text-[#64748B] ml-1 mr-0 mb-0 mt-0 text-[0.8rem]">Name</p>
            <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
            Mahadev BIradar
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%]">
           <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Designation</p>
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
           Board
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%]">
           <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">PAN Number</p>
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
           15%
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%]">
           <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Permission</p>
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%]">
           Read & Download
            </div>
           </div>
           <div className="flex flex-col gap-1 w-[20%] justify-end">
           {/* <p className="text-[#64748B] m-0 text-[0.8rem] ml-1 mr-0 mb-0 mt-0">Name</p> */}
           <div className="bg-[#F8FAFC] text-[#334155] px-2.5 py-1.5 rounded-sm w-[90%] font-[600]">
           Edit Guest Details
            </div>
           </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessMembersAndInvites;
