import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import useGetLiabilitiesOverview from './data/useGetLiabilitiesOverview';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineGraph = () => {
  const [getData, getError, getIsLoading, getLiabilitiesOverview] = useGetLiabilitiesOverview();
  const [chartData, setChartData] = useState(null); // State to store dynamic chart data

  useEffect(() => {
    // Fetch API Data on mount
    getLiabilitiesOverview();
  }, []);

  useEffect(() => {
    if (getData && getData.data && getData.data.data) {
      const loans = getData.data.data.loans;
      const loanNames = loans.map(loan => loan.loan_name); // Extract loan names
      const loanAmounts = loans.map(loan => loan.total_loan_amount); // Extract loan amounts
      
      // Create dynamic data for the chart
      const dynamicData = {
        labels: loanNames,
        datasets: [
          {
            label: 'Loan Data',
            data: loanAmounts,  // Dynamic loan amounts from API response
            fill: false,
            borderColor: '#9065B4', // Purple line color
            backgroundColor: '#8b5cf6',
            pointBackgroundColor: '#c084fc',  // Color of the points
            pointBorderColor: '#c084fc',
            pointHoverBackgroundColor: '#a855f7',
            pointHoverBorderColor: '#a855f7',
            tension: 0, // Slight curvature of the line
            pointRadius: 4, // Size of the points
            pointHoverRadius: 8, // Point size on hover
            borderWidth: 1.5,
          },
        ],
      };

      setChartData(dynamicData); // Update the state with dynamic chart data
    }
  }, [getData]); // This effect runs when `getData` updates

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: true, // Show tooltip when hovering on points
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove vertical gridlines
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 100000, // Adjust based on the loan amounts, you can make it dynamic too
        },
      },
    },
    elements: {
      point: {
        radius: 4,
        hoverRadius: 6,
      },
    },
  };

  return (
    <div className="max-w-4xl mx-auto p-1">
      {chartData ? (
        <Line data={chartData} options={options} />
      ) : (
        <p>Loading...</p> // Handle loading state
      )}
    </div>
  );
};

export default LineGraph;
