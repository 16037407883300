import React from "react";
import brownAF from "assets/images/home/article-fill(brown).svg";
import purpleAF from "assets/images/home/article-fill(purple).svg";
import RegistrationSlider from "./RegistrationSlider";
import ITSlider from "./ITSlider";
import { Link } from "react-router-dom";
import { GET_SERVICE_URL } from "constants/routes";


function Offers() {


  return (
    <>
      <div className=" p-[8%] grid grid-cols-12 offersDiv">
        <div className=" lg:col-span-6 md:col-span-6 col-span-12">
          <h2 className="lg:font-[700] lg:text-[3rem] lg:leading-[4rem] md:font-[700] md:text-[3rem] md:leading-[4rem] font-[700] text-[1.7rem] leading-[2.5rem]">Offering professional expertise in</h2>
          <p className=" text-gray-500  lg:text-[1rem] lg:leading-[1.5rem]  md:text-[1rem] md:leading-[1.5rem] font-[500] text-[0.8rem] leading-[1.3rem]">
            We simplify finance, offering expert guidance for your success –
            making complex financial matters straightforward and stress-free.
          </p>
        </div>
             <div className="offersBlock md:my-[4%] md:py-[4%] md:pl-[4%] lg:my-[4%] lg:py-[4%] lg:pl-[4%] col-span-12 md:bg-[#f0efef] lg:bg-[#f0efef] bg-white drop-shadow-md p-[8%] rounded-2xl grid grid-cols-12">
             <Link to={'/registrationPage' } className="col-span-12 no-underline grid grid-cols-12"> 
             <div className="md:col-span-6 col-span-12 lg:col-span-6">
            <img className=" w-[10%]" src={brownAF} />
            <h3 className="slightDarkBlack my-[3%] lg:font-[700] lg:leading-[2.5rem]  lg:text-[2rem] md:font-[700] md:leading-[2.5rem]  md:text-[2rem] font-[700] text-[1.4rem] leading-[1.6rem]">Registrations</h3>
            <p className=" text-gray-500 lg:font-[600] lg:text-[1.2rem] lg:leading-[1.5rem] md:font-[600] md:text-[1.2rem] md:leading-[1.5rem] font-[500] text-[0.7rem] leading-[1rem]">
              Launch with confidence, Seamless solutions for hasle-free new
              business registartions
            </p>
          </div> 
          </Link>  
      <div className=" col-span-12 lg:flex md:flex hidden">
{/*             
            {registraionListNames.map((i) => {
              return (
                <div className="  bg-light-orange pt-[15%] px-[2%] pb-[5%] my-[4%] mr-[2%] rounded-2xl ">
                  <h3>{i}</h3>
                </div>
              );
            })} */}
<RegistrationSlider />

          </div>
          <div className=" col-span-11 lg:hidden md:hidden flex">
<ul className="flex flex-wrap p-0 gap-1">
<Link className=" no-underline text-gray-700 satoshi" to={`${GET_SERVICE_URL}/udyam-msme-registration`}>  <li className=" bg-light-orange px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">Udyam Registration</li></Link>
  <li className=" bg-light-orange px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">Labour License</li>
  <li className=" bg-light-orange px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">FSSAI-Food License</li>
  <li className=" bg-light-orange px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">PF & ESI Compliance</li>

</ul>

          </div>
        </div>
       

        <div className="offersBlock my-[8%] md:py-[4%] md:pl-[4%] lg:py-[4%] lg:pl-[4%] col-span-12 md:bg-[#f0efef] lg:bg-[#f0efef] bg-white drop-shadow-md p-[8%] rounded-2xl grid grid-cols-12">
          <div className="md:col-span-6 col-span-12 lg:col-span-6">
            <img className=" w-[10%]" src={purpleAF} />
            <h3 className="slightDarkBlack my-[3%] lg:font-[700] lg:leading-[2.5rem]  lg:text-[2rem] md:font-[700] md:leading-[2.5rem]  md:text-[2rem] font-[700] text-[1.4rem] leading-[1.6rem]">Income Tax</h3>
            <p className=" text-gray-500 lg:font-[600] lg:text-[1.2rem] lg:leading-[1.5rem] md:font-[600] md:text-[1.2rem] md:leading-[1.5rem] font-[500] text-[0.7rem] leading-[1rem]">
            Maximise returns, minimise stress. Expert income tax services tailored for you.
            </p>
          </div>
         
          <div className="col-span-12 lg:flex md:flex hidden">
            {/* {ITListNames.map((i) => {
              return (
                <div className="  bg-light-purple pt-[15%] px-[2%] pb-[5%] my-[4%] mr-[2%] rounded-2xl">
                  <h3>{i}</h3>
                </div>
              );
            })} */}
            <ITSlider />
          </div>
          <div className=" col-span-11 lg:hidden md:hidden flex">
<ul className="flex flex-wrap p-0 gap-1">
  <li className=" bg-light-purple px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">Salaried Income Returns</li>
  <li className=" bg-light-purple px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">Business/Freelancing Income Returns</li>
  <li className=" bg-light-purple px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">Refund Re-Issue/Status</li>
  <li className=" bg-light-purple px-3 py-2 rounded-3xl font-[500] text-[0.8rem] leading-[1.4rem]">Reply to Notice/Order</li>

</ul>

          </div>
        </div>
      </div>

      {/* <div class="glide">
      <div class="glide__arrows" data-glide-el="controls">
    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
  </div>
  <div class="glide__track" data-glide-el="track">
    <ul class="glide__slides">
      <li class="glide__slide">0</li>
      <li class="glide__slide">1</li>
      <li class="glide__slide">2</li>
    </ul>
  </div>
</div> */}
    </>
  );
}

export default Offers;
