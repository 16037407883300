import useAPICall from "hooks/useAPICall";
import { PATCH_UPDATE_USER_URL } from "constants/apis";

const usePatchUserUpdate = () => {
  const [userUpdateData, userUpdateError, userUpdateIsLoading, callPatchUserUpdate, setSuccessUserUpdateData, setUserUpdateError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setuserUpdateError(en.something_went_wrong);
    setSuccessUserUpdateData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const userUpdateData = res;
        if (userUpdateData && typeof userUpdateData === "object") {
          setSuccessUserUpdateData(userUpdateData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const patchUserUpdate = (body) => {
    const url = PATCH_UPDATE_USER_URL;
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`,'Content-Type':'multipart/form-data'}}
    // console.log(body)
    console.log('patchUserUpdate running')
    console.log("Patch update user profile:", body)
    callPatchUserUpdate({
      url,
      method : "PATCH",
      statusObj,
      
      config,
      defaultFallback,
      body
    });
  };
  return [userUpdateData, userUpdateError, userUpdateIsLoading, patchUserUpdate, setSuccessUserUpdateData, setUserUpdateError];
};

export default usePatchUserUpdate;
