import useAPICall from "hooks/useAPICall";
import { GET_BLOG_DETAILS_URL } from "constants/apis";

const useGetBlogDetails = () => {
  const [BlogDetailsData, BlogDetailsError, BlogDetailsIsLoading, callGetBlogDetails, setSuccessBlogDetailsData, setBlogDetailsError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setBlogDetailsError(en.something_went_wrong);
    setSuccessBlogDetailsData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const BlogDetailsData = res;
        if (BlogDetailsData && typeof BlogDetailsData === "object") {
          setSuccessBlogDetailsData(BlogDetailsData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getBlogDetails = (body) => {
    const url = `${GET_BLOG_DETAILS_URL}/${body.id}`
    const config = {};
console.log(body)

    callGetBlogDetails({
      url,
      method : "GET",
      statusObj,
      config,

      defaultFallback,
      
    });
  };
  return [BlogDetailsData, BlogDetailsError, BlogDetailsIsLoading, getBlogDetails, setSuccessBlogDetailsData, setBlogDetailsError];
};

export default useGetBlogDetails;
