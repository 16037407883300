import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import useGetTaxSummaryAgri from "../Data/useGetTaxSummaryAgri";
import { useNavigate, useParams } from "react-router-dom";
import { myContext } from "App";
import usePostTaxSummaryAgri from "../Data/usePostTaxSummaryAgri";
import usePatchTaxSummaryAgri from "../Data/usePatchTaxSummaryAgri";
import { INCOME_TAX_SUMMARY_USER_DETAILS } from "constants/routes";
import Loader from "components/Loader/Loader";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

const TaxSummaryAgriExempt = forwardRef(({isDisabled}, ref) => {
  const { income_tax_return_id } = useParams();
  const navigate = useNavigate();
  const path = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
    ":detailType",
    "others"
  ).replace(":income_tax_return_id", income_tax_return_id);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [getData, getError, getIsLoading, getTaxSummaryAgri] =
    useGetTaxSummaryAgri();

  const [agriData, agriError, agriIsLoading, postCreateAgriDetails] =
    usePostTaxSummaryAgri();

  const [patchData, patchError, patchIisLoading, patchTaxSummaryAgri] =
    usePatchTaxSummaryAgri();

  useEffect(() => {
    getTaxSummaryAgri(income_tax_return_id);
  }, []);

  const defaultValue = {
    agriculture_incomes: [
      {
        land_details: [
          {
            district: "",
            measurement: "",
            pincode: "",
            country: "",
            land_status: 0,
            water_source: 0,
          },
        ],
        expences: "",
        gross_recipts: "",
        net_income: "",
        previous_unabsorbed_losses: "",
      },
    ],
    exempt_incomes: [
      {
        exemption_type: "1",
        exemptP: {
          descriptionP: "",
          amountP: "",
        },
        exemptN: {
          descriptionN: "",
          amountN: "",
        },
        other: {
          particular: "",
          description: "",
          amount: "",
        },
      },
    ],
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(onSubmit)(),
  }));
  const onSubmit = (data) => {
    setIsFormSubmitted(true);
    let flagForUpdate = false;
    let { alterdered, flag } = formatingSendData(data, flagForUpdate);
    if (flag) {
      patchTaxSummaryAgri(income_tax_return_id, alterdered);
    } else {
      postCreateAgriDetails(income_tax_return_id, alterdered);
    }
  };

  useEffect(() => {
    if (agriData) {
      showSuccessToast("agri Details posted")
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [agriData]);

  useEffect(() => {
    if (agriError) {
      showErrorToast("","Error in posting")
    }
  }, [agriError]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Details updated")
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [patchData]);

  useEffect(() => {
    if (patchError) {
      showErrorToast("","Error in updating")
    }
  }, [patchError]);

  const formatingSendData = (data, flag) => {
    let { agriculture_incomes, exempt_incomes } = data;
    let {
      id,
      created_at,
      updated_at,
      income_tax,
      income_tax_return,
      land_details,
      net_income,
      ...rest
    } = agriculture_incomes[0];
    if (id) {
      flag = true;
    }
    if (net_income) {
      net_income = Number(net_income);
    } else {
      net_income = 0;
    }
    land_details =
      land_details &&
      land_details.map((item) => {
        let { created_at, updated_at, agriculture_income, ...rest } = item;
        return rest;
      });
    agriculture_incomes[0] = {
      ...rest,
      land_details,
      ...(id !== undefined && { id }),
      // net_income,
    };
    exempt_incomes = exempt_incomes.map((item) => {
      let { exemption_type, exemptP, exemptN, other, id } = item;
      let { descriptionP, amountP } = exemptP;
      let { descriptionN, amountN } = exemptN;
      if (exemption_type == 1) {
        return {
          ...(id !== undefined && { id }),
          exemption_type,
          description: descriptionP,
          amount: amountP,
        };
      }
      if (exemption_type == 2) {
        return {
          ...(id !== undefined && { id }),
          exemption_type,
          description: descriptionN,
          amount: amountN,
        };
      }
      if (exemption_type == 3) {
        return {
          ...(id !== undefined && { id }),
          exemption_type,
          ...other,
        };
      }
    });
    let alterdered = {
      agriculture_incomes: [{ ...agriculture_incomes[0] }],
      exempt_incomes,
    };
    return { alterdered, flag };
  };

  const form = useForm({
    defaultValues: defaultValue,
  });

  const { control, handleSubmit, reset, formState, register, setValue } = form;
  const { errors } = formState;
  const { fields } = useFieldArray({
    control,
    name: "agriculture_incomes",
  });

  const { fields: exemptIncomesArr, append: exemptIncomesAppend, remove:removeExemptIncome } =
    useFieldArray({
      control,
      name: "exempt_incomes",
    });

  const watchFieldArray = useWatch({
    control,
    name: "exempt_incomes",
  });

  useEffect(() => {
    if (
      getData?.data?.data.agriculture_incomes.length > 0 ||
      getData?.data?.data.exempt_incomes.length > 0
    ) {
      let { agriculture_incomes, exempt_incomes } = getData?.data?.data;
      agriculture_incomes=agriculture_incomes.map((item)=>{
        if(item.land_details.length == 0){
          return {...item, land_details : defaultValue.agriculture_incomes.land_details}
        }
        return item
      })
      exempt_incomes = exempt_incomes.map((item) => {
        let { id, exemption_type, particular, description, amount } = item;

        if (exemption_type == 1) {
          return {
            id,
            exemption_type: exemption_type,
            exemptP: {
              descriptionP: description,
              amountP: amount,
            },
            exemptN: {
              descriptionN: "",
              amountN: "",
            },
            other: {
              particular: "",
              description: "",
              amount: "",
            },
          };
        }

        if (exemption_type == 2) {
          return {
            id,
            exemption_type: exemption_type,
            exemptP: {
              descriptionP: "",
              amountP: "",
            },
            exemptN: {
              descriptionN: description,
              amountN: amount,
            },
            other: {
              particular: "",
              description: "",
              amount: "",
            },
          };
        }

        if (exemption_type == 3) {
          return {
            id,
            exemption_type: exemption_type,
            exemptP: {
              descriptionP: "",
              amountP: "",
            },
            exemptN: {
              descriptionN: "",
              amountN: "",
            },
            other: {
              particular: particular,
              description: description,
              amount: amount,
            },
          };
        }
      });
      if (exempt_incomes.length == 0) {
        exempt_incomes = defaultValue.exempt_incomes;
      }
      if (agriculture_incomes.length == 0) {
        agriculture_incomes = defaultValue.agriculture_incomes;
      }

      let alteredData = {
        agriculture_incomes,
        exempt_incomes,
      };
      reset(alteredData);
    } else {
      reset(defaultValue);
    }
  }, [getData]);

  let exemptIncomeFields = exemptIncomesArr.map((field, index) => {
    return {
      ...field,
      exemption_type: Number(watchFieldArray[index]?.exemption_type),
    };
  });

  useEffect(() => {
    exemptIncomeFields = exemptIncomesArr.map((field, index) => {
      if (watchFieldArray[index]?.exemption_type === null) {
        setValue(
          `exempt_incomes.${index}.exemption_type`,
          field.exemption_type
        );
      }
      return {
        ...field,
        exemption_type: Number(watchFieldArray[index]?.exemption_type),
      };
    });
  }, [watchFieldArray]);

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => {
            return (
              <div
                key={index}
                className="border border-gray-200 rounded mt-4 p-[2%]"
              >
                <h6 className="mt-4 text-[#334155]">Agricultural Income</h6>
                <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.65rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Gross Agricultural Receipts
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-2  text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
                        Rs.
                      </span>
                        <InputField
                          type="number"
                          placeholder="Enter Amount"
                          name={`agriculture_incomes.${index}.gross_recipts`}
                          register={register}
                          validation={{
                            required: "*Gross Agri Reciepts required",
                          }}
                          className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                        />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.agriculture_incomes?.[index]?.gross_recipts
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.65rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Agriculture Expenses
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
                        Rs.
                      </span>
                        <InputField
                          type="number"
                          placeholder="Enter Amount"
                          name={`agriculture_incomes.${index}.expences`}
                          register={register}
                          validation={{
                            required: "*Agriculture expenses required",
                          }}
                          className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                        />
                    </div>
                    <p className="text-red-600">
                      {errors?.agriculture_incomes?.[index]?.expences?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.65rem] leading-[1rem] md:text-[#667085] text-[#9065B4] group-focus-within:text-customPurple">
                      Previous Unabsorbed Agriculture loss[if any]
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <span className="px-2  text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                        Rs.
                      </span>
                        <InputField
                          type="number"
                          placeholder="Enter Amount"
                          name={`agriculture_incomes.${index}.previous_unabsorbed_losses`}
                          register={register}
                          validation={{
                            required: "*Agriculture loss required",
                          }}
                          className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                        />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.agriculture_incomes?.[index]
                          ?.previous_unabsorbed_losses?.message
                      }
                    </p>
                  </div>
                </div>
                <hr className="text-[#E2E8F0] border mt-4" />
                <div className="flex flex-col md:flex-row justify-between items-left mt-3">
                  <h6 className="text-[#334155]">Land Details</h6>
                  <p className="text-[#9065B4] text-[0.8rem]">
                    If Net Income exceeds 5 Lakhs
                  </p>
                </div>
                <LandDetails
                  errors={errors}
                  control={control}
                  index={index}
                  register={register}
                  isDisabled={isDisabled}
                />
              </div>
            );
          })}
          <div className="border border-gray-200 rounded mt-4 p-[1%]">
            {exemptIncomeFields.map((field, index) => {
              return (
                <div key={index}>
                  <div className="flex flex-col md:flex-row justify-between items-left mb-3">
                    <h6 className="mt-4 text-[#334155]">Exempt Income</h6>
                    <div className="flex gap-2.5">
                      <label
                      onClick={()=>setValue(`exempt_incomes.${index}.exemption_type`,1)}
                      className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center">
                        {/* <input
                          value={1}
                          checked={field.exemption_type == 1}
                          {...register(
                            `exempt_incomes.${index}.exemption_type`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          type="radio"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                        /> */}
                      <InputField
                        type="radio"
                        name={`exempt_incomes.${index}.exemption_type`} // Use the same name for the group of radio buttons
                        value={1} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                        checked={field.exemption_type == 1}
                      />
                        PPF
                      </label>
                      <label
                      onClick={()=>setValue(`exempt_incomes.${index}.exemption_type`,2)}
                      className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center">
                        {/* <input
                          value={2}
                          checked={field.exemption_type == 2}
                          {...register(
                            `exempt_incomes.${index}.exemption_type`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          type="radio"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                        /> */}
                      <InputField
                        type="radio"
                        name={`exempt_incomes.${index}.exemption_type`} // Use the same name for the group of radio buttons
                        value={2} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                        checked={field.exemption_type == 2}
                      />
                        NRE A/c
                      </label>
                      <label
                      onClick={()=>setValue(`exempt_incomes.${index}.exemption_type`,3)}
                      className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center">
                        {/* <input
                          value={3}
                          checked={field.exemption_type == 3}
                          {...register(
                            `exempt_incomes.${index}.exemption_type`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          type="radio"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                        /> */}
                      <InputField
                        type="radio"
                        name={`exempt_incomes.${index}.exemption_type`} // Use the same name for the group of radio buttons
                        value={3} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                        checked={field.exemption_type == 3}
                      />
                        Others
                      </label>
                    </div>
                  </div>
                  {field.exemption_type == 1 && (
                    <ExemptForm index={index} errors={errors} />
                  )}
                  {field.exemption_type == 2 && (
                    <ExemptFormNre index={index} errors={errors} />
                  )}
                  {field.exemption_type == 3 && (
                    <OtherExemptForm index={index} errors={errors} />
                  )}
                              <div>
              {index === exemptIncomeFields.length-1 ? (
                <button
                  type="button"
                  disabled={isDisabled || false}
                  onClick={() => removeExemptIncome(index)}
                  className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                >
                  <span className="mr-1.5">X</span>Remove
                </button>
              ) : (
                ""
              )}
            </div>
                </div>
              );
            })}
            <button
              type="button"
              disabled={isDisabled || false}
              onClick={() =>
                exemptIncomesAppend({
                  exemption_type: 1,
                  exemptP: {
                    descriptionP: "",
                    amountP: "",
                  },
                  exemptN: {
                    descriptionN: "",
                    amountN: "",
                  },
                  other: {
                    particular: "",
                    description: "",
                    amount: "",
                  },
                })
              }
              className=" px-2 py-2 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] mt-3 text-[0.9rem]"
            >
              Add Interest
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

const ExemptForm = ({ index, errors }) => {
  const { register } = useFormContext();
  return (
    <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Description
        </h6>
        <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              placeholder="Enter Description"
              name={`exempt_incomes.${index}.exemptP.descriptionP`}
              register={register}
              validation={{
                required: "*Description loss required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.exemptP?.descriptionP?.message}
        </p>
      </div>
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Amount
        </h6>
        <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
          <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
            Rs.
          </span>
            <InputField
              type="number"
              placeholder="Enter Amount"
              name={`exempt_incomes.${index}.exemptP.amountP`}
              register={register}
              validation={{
                required: "*Amount loss required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.exemptP?.amountP?.message}
        </p>
      </div>
    </div>
  );
};
const ExemptFormNre = ({ index, errors }) => {
  const { register } = useFormContext();
  return (
    <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Description
        </h6>
        <div className="flex items-center border-[#94A3B8] border-1  rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              placeholder="Enter Description"
              name={`exempt_incomes.${index}.exemptN.descriptionN`}
              register={register}
              validation={{
                required: "Description is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.exemptN?.descriptionN?.message}
        </p>
      </div>
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Amount
        </h6>
        <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
          <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
            Rs.
          </span>
            <InputField
              type="number"
              placeholder="Enter Amount"
              name={`exempt_incomes.${index}.exemptN.amountN`}
              register={register}
              validation={{
                required: "Amount is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.exemptN?.amountN?.message}
        </p>
      </div>
    </div>
  );
};
const OtherExemptForm = ({ index, errors }) => {
  const { register } = useFormContext();
  return (
    <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Particulars
        </h6>
        <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              placeholder="Enter Particulars"
              name={`exempt_incomes.${index}.other.particular`}
              register={register}
              validation={{
                required: "Particular is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085]  w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.other?.particular?.message}
        </p>
      </div>
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Description
        </h6>
        <div className="flex items-center border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              placeholder="Enter Description"
              name={`exempt_incomes.${index}.other.description`}
              register={register}
              validation={{
                required: "Description is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085] border-[#94A3B8] border-1 w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.other?.description?.message}
        </p>
      </div>
      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
          Amount
        </h6>
        <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
          <span className="px-2  text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
            Rs.
          </span>
            <InputField
              type="number"
              placeholder="Enter Amount"
              name={`exempt_incomes.${index}.other.amount`}
              register={register}
              validation={{
                required: "*Amount is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
        </div>
        <p className="text-red-600">
          {errors?.exempt_incomes?.[index]?.other?.amount?.message}
        </p>
      </div>
    </div>
  );
};

const LandDetails = ({
  errors,
  control,
  register,
  index,
  isDisabled,
  handleSubmit,
  onSubmit,
}) => {
  const {
    fields: landDetailsFields,
    append: landDetailsAppend,
    remove: removeLand,
  } = useFieldArray({
    control,
    name: `agriculture_incomes.${index}.land_details`,
  });
  const removeItem = (index) => {
    removeLand(index);
  };
  return (
    <>
      {landDetailsFields.map((lField, lindex) => {
        return (
          <div key={lindex}>
            <div className="grid grid-cols-12 mt-1 ml-[-2px]">
              <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                  District
                </h6>
                <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                    <InputField
                      type="text"
                      placeholder="Enter District"
                      name={`agriculture_incomes.${index}.land_details.${lindex}.district`}
                      register={register}
                      validation={{
                        required: "*District is required",
                        pattern: {
                          value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                          message: "*Only letters and spaces are allowed", // Updated error message
                        }
                      }}
                      className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                    />
                </div>
                <p className="text-red-600">
                  {
                    errors?.agriculture_incomes?.[index]?.land_details?.[lindex]
                      ?.district?.message
                  }
                </p>
              </div>
              <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                  Measurement
                </h6>
                <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                    <InputField
                      type="number"
                      placeholder="Enter Measurements"
                      name={`agriculture_incomes.${index}.land_details.${lindex}.measurement`}
                      register={register}
                      validation={{
                        required: "*Measurment is required",
                      }}
                      className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                    />
                </div>
                <p className="text-red-600">
                  {
                    errors?.agriculture_incomes?.[index]?.land_details?.[lindex]
                      ?.measurement?.message
                  }
                </p>
              </div>
              <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                  Pincode
                </h6>
                <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                    <InputField
                      type="text"
                      placeholder="Enter Pincode"
                      name={`agriculture_incomes.${index}.land_details.${lindex}.pincode`}
                      register={register}
                      validation={{
                        required: "*PIN is required",
                        pattern: {
                          value: /^[1-9][0-9]{5}$/, // Ensures no leading 0 and exactly 6 digits
                          message: "Please enter a valid 6-digit PIN code starting with a non-zero digit",
                        },
                      }}
                      maxLength={6}
                      className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                    />
                </div>
                <p className="text-red-600">
                  {
                    errors?.agriculture_incomes?.[index]?.land_details?.[lindex]
                      ?.pincode?.message
                  }
                </p>
              </div>
              <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                  Country
                </h6>
                <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                    <InputField
                      type="text"
                      placeholder="Enter Country"
                      name={`agriculture_incomes.${index}.land_details.${lindex}.country`}
                      register={register}
                      validation={{
                        required: "*Country is required",
                        pattern: {
                          value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                          message: "*Only letters and spaces are allowed", // Updated error message
                        }
                      }}
                      className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                    />
                </div>
                <p className="text-red-600">
                  {
                    errors?.agriculture_incomes?.[index]?.land_details?.[lindex]
                      ?.country?.message
                  }
                </p>
              </div>
            </div>
            <div className="grid grid-cols-12  ">
              <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                  Status
                </h6>
                <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
              <InputField
                type="select"
                name={`agriculture_incomes.${index}.land_details.${lindex}.land_status`}
                register={register}
                validation={{
                  required: "*Status is required",
                  validate: (value) => value !== "0" || "*Status is required",
                }}
                options={[
                  { value: 0, label: "Select an option" },
                  { value: 1, label: "Own" },
                  { value: 2, label: "Held on lease" }
                ]}
                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
              />
                </div>
                <p className="text-red-600">
                  {
                    errors?.agriculture_incomes?.[index]?.land_details?.[lindex]
                      ?.land_status?.message
                  }
                </p>
              </div>
              <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                  Water Source
                </h6>
                <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
              <InputField
                type="select"
                name={`agriculture_incomes.${index}.land_details.${lindex}.water_source`}
                register={register}
                validation={{
                  required: "*Water Source is required",
                  validate: (value) => value !== "0" || "*Water Source is required",
                }}
                options={[
                  { value: 0, label: "Select an option" },
                  { value: 1, label: "Irrigated" },
                  { value: 2, label: "Rain-fed" }
                ]}
                className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none"
              />
                </div>
                <p className="text-red-600">
                  {
                    errors?.agriculture_incomes?.[index]?.land_details?.[lindex]
                      ?.water_source?.message
                  }
                </p>
              </div>
            </div>
            <div>
              {lindex > 0 ? (
                <button
                disabled={isDisabled || false}
                  type="button"
                  onClick={() => removeItem(lindex)}
                  className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                >
                  <span className="mr-1.5">X</span>Remove
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      })}
      <button
        type="button"
        disabled={isDisabled || false}
        onClick={() =>
          landDetailsAppend({
            district: "",
            measurement: "",
            pincode: "",
            country: "",
            land_status: 0,
            water_source: 0,
          })
        }
        className=" px-2 py-2 mt-4 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] text-[0.9rem]"
      >
        Add Land
      </button>
    </>
  );
};

export default TaxSummaryAgriExempt;
