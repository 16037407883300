import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import deletePic from "assets/images/incomeTax/removeCapital.png";
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
  FormProvider,
} from "react-hook-form";
import useGetTaxSummaryBusiness from "../Data/useGetTaxSummaryBusiness";
import { useNavigate, useParams } from "react-router-dom";
import { myContext } from "App";
import usePostTaxSummaryBusiness from "../Data/usePostTaxSummaryBusiness";
import usePatchTaxSummaryBusiness from "../Data/usePatchTaxSummaryBusiness";
import { INCOME_TAX_SUMMARY_USER_DETAILS } from "constants/routes";
import Loader from "components/Loader/Loader";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

const TaxSummaryBusiness = forwardRef(({ isDisabled }, ref) => {
  const { income_tax_return_id } = useParams();
  const navigate = useNavigate();
  const path = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
    ":detailType",
    "agri-exmpt"
  ).replace(":income_tax_return_id", income_tax_return_id);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [getData, getError, getIsLoading, getTaxSummaryBusiness] =
    useGetTaxSummaryBusiness();

  const [
    businessData,
    businessError,
    businessIsLoading,
    postCreateBusinessDetails,
  ] = usePostTaxSummaryBusiness();

  const [patchData, patchError, patchIisLoading, patchTaxSummaryBusiness] =
    usePatchTaxSummaryBusiness();

  useEffect(() => {
    getTaxSummaryBusiness(income_tax_return_id);
  }, []);

  useImperativeHandle(ref, () => ({
    addBusiness() {
      append({ ...defaultValues.business[0] });
    },
    handleSubmit: () => handleSubmit(onSubmit)(),
  }));

  const onSubmit = (data) => {
    setIsFormSubmitted(true);
    let flagForUpdate = false;
    let { modifiedData, flag } = formattingSendData(
      data.business,
      flagForUpdate
    );
    if (flag) {
      patchTaxSummaryBusiness(income_tax_return_id, modifiedData);
    } else {
      postCreateBusinessDetails(income_tax_return_id, modifiedData);
    }
  };

  useEffect(() => {
    if (businessData) {
      showSuccessToast("Business Details Posted");
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [businessData]);

  useEffect(() => {
    if (businessError) {
      showErrorToast("", "Error in posting");
    }
  }, [businessError]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Details updated");
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [patchData]);

  useEffect(() => {
    if (patchError) {
      showErrorToast("", "Error in updating");
    }
  }, [patchError]);

  const formattingSendData = (data, flag) => {
    let modifiedData = data.map((item) => {
      let { id, business_income_type, business_ad, business_ada } = item;

      if (id) {
        flag = true;
      }

      if (business_income_type == "44AD") {
        let {
          ad_business_name,
          ad_industry,
          ad_nature_of_business,
          ad_description,
          ad_gross_receipt_cheq_neft_rtgs_turnover,
          ad_gross_receipt_cheq_neft_rtgs_profit,
          ad_gross_receipt_cash_upi_turnover,
          ad_gross_receipt_cash_upi_profit,
          ad_fixed_asset,
          ad_inventory,
          ad_receivebles,
          ad_loans_and_advances,
          ad_investments,
          ad_cash_in_hand,
          ad_bank_balance,
          ad_other_assets,
          ad_capital,
          ad_secured_loans,
          ad_payables,
          ad_unsecured_loans,
          ad_advances,
          ad_other_liabilities,
        } = business_ad;

        return {
          ...(id !== undefined && { id }),
          business_income_type: business_income_type,
          business_name: ad_business_name,
          industry: ad_industry,
          nature_of_business: ad_nature_of_business,
          description: ad_description,
          gross_receipt_cheq_neft_rtgs_turnover:
            ad_gross_receipt_cheq_neft_rtgs_turnover,
          gross_receipt_cheq_neft_rtgs_profit:
            ad_gross_receipt_cheq_neft_rtgs_profit,
          gross_receipt_cash_upi_turnover: ad_gross_receipt_cash_upi_turnover,
          gross_receipt_cash_upi_profit: ad_gross_receipt_cash_upi_profit,
          fixed_asset: ad_fixed_asset,
          inventory: ad_inventory,
          receivebles: ad_receivebles,
          loans_and_advances: ad_loans_and_advances,
          investments: ad_investments,
          cash_in_hand: ad_cash_in_hand,
          bank_balance: ad_bank_balance,
          other_assets: ad_other_assets,
          capital: ad_capital,
          secured_loans: ad_secured_loans,
          payables: ad_payables,
          unsecured_loans: ad_unsecured_loans,
          advances: ad_advances,
          other_liabilities: ad_other_liabilities,
        };
      }

      if (business_income_type == "44ADA") {
        let {
          ada_business_name,
          ada_industry,
          ada_nature_of_business,
          ada_description,
          ada_gross_receipt_cheq_neft_rtgs_turnover,
          ada_gross_receipt_cheq_neft_rtgs_profit,
          ada_gross_receipt_cash_upi_turnover,
          ada_gross_receipt_cash_upi_profit,
          ada_fixed_asset,
          ada_inventory,
          ada_receivebles,
          ada_loans_and_advances,
          ada_investments,
          ada_cash_in_hand,
          ada_bank_balance,
          ada_other_assets,
          ada_capital,
          ada_secured_loans,
          ada_payables,
          ada_unsecured_loans,
          ada_advances,
          ada_other_liabilities,
        } = business_ada;

        return {
          ...(id !== undefined && { id }),
          business_income_type: business_income_type,
          business_name: ada_business_name,
          industry: ada_industry,
          nature_of_business: ada_nature_of_business,
          description: ada_description,
          gross_receipt_cheq_neft_rtgs_turnover:
            ada_gross_receipt_cheq_neft_rtgs_turnover,
          gross_receipt_cheq_neft_rtgs_profit:
            ada_gross_receipt_cheq_neft_rtgs_profit,
          gross_receipt_cash_upi_turnover: ada_gross_receipt_cash_upi_turnover,
          gross_receipt_cash_upi_profit: ada_gross_receipt_cash_upi_profit,
          fixed_asset: ada_fixed_asset,
          inventory: ada_inventory,
          receivebles: ada_receivebles,
          loans_and_advances: ada_loans_and_advances,
          investments: ada_investments,
          cash_in_hand: ada_cash_in_hand,
          bank_balance: ada_bank_balance,
          other_assets: ada_other_assets,
          capital: ada_capital,
          secured_loans: ada_secured_loans,
          payables: ada_payables,
          unsecured_loans: ada_unsecured_loans,
          advances: ada_advances,
          other_liabilities: ada_other_liabilities,
        };
      }
    });
    return { modifiedData, flag };
  };

  useEffect(() => {
    if (getData?.data?.results.length > 0) {
      let alteredData = [];
      let apiData = getData?.data?.results;
      apiData.map((item) => {
        let {
          id,
          business_income_type,
          business_name,
          industry,
          nature_of_business,
          description,
          gross_receipt_cheq_neft_rtgs_turnover,
          gross_receipt_cheq_neft_rtgs_profit,
          gross_receipt_cash_upi_turnover,
          gross_receipt_cash_upi_profit,
          fixed_asset,
          inventory,
          receivebles,
          loans_and_advances,
          investments,
          cash_in_hand,
          bank_balance,
          other_assets,
          capital,
          secured_loans,
          payables,
          unsecured_loans,
          advances,
          other_liabilities,
        } = item;
        let addData = {
          id: id,
          business_income_type: business_income_type,
          business_ad: {
            ad_business_name: business_name,
            ad_industry: industry,
            ad_nature_of_business: nature_of_business,
            ad_description: description,
            ad_gross_receipt_cheq_neft_rtgs_turnover:
              gross_receipt_cheq_neft_rtgs_turnover,
            ad_gross_receipt_cheq_neft_rtgs_profit:
              gross_receipt_cheq_neft_rtgs_profit,
            ad_gross_receipt_cash_upi_turnover: gross_receipt_cash_upi_turnover,
            ad_gross_receipt_cash_upi_profit: gross_receipt_cash_upi_profit,
            ad_fixed_asset: fixed_asset,
            ad_inventory: inventory,
            ad_receivebles: receivebles,
            ad_loans_and_advances: loans_and_advances,
            ad_investments: investments,
            ad_cash_in_hand: cash_in_hand,
            ad_bank_balance: bank_balance,
            ad_other_assets: other_assets,
            ad_capital: capital,
            ad_secured_loans: secured_loans,
            ad_payables: payables,
            ad_unsecured_loans: unsecured_loans,
            ad_advances: advances,
            ad_other_liabilities: other_liabilities,
          },
          business_ada: {
            ada_business_name: business_name,
            ada_industry: industry,
            ada_nature_of_business: nature_of_business,
            ada_description: description,
            ada_gross_receipt_cheq_neft_rtgs_turnover:
              gross_receipt_cheq_neft_rtgs_turnover,
            ada_gross_receipt_cheq_neft_rtgs_profit:
              gross_receipt_cheq_neft_rtgs_profit,
            ada_gross_receipt_cash_upi_turnover:
              gross_receipt_cash_upi_turnover,
            ada_gross_receipt_cash_upi_profit: gross_receipt_cash_upi_profit,
            ada_fixed_asset: fixed_asset,
            ada_inventory: inventory,
            ada_receivebles: receivebles,
            ada_loans_and_advances: loans_and_advances,
            ada_investments: investments,
            ada_cash_in_hand: cash_in_hand,
            ada_bank_balance: bank_balance,
            ada_other_assets: other_assets,
            ada_capital: capital,
            ada_secured_loans: secured_loans,
            ada_payables: payables,
            ada_unsecured_loans: unsecured_loans,
            ada_advances: advances,
            ada_other_liabilities: other_liabilities,
          },
        };
        alteredData.push(addData);
      });
      reset({
        business: alteredData,
      });
    } else {
      reset(defaultValues);
    }
  }, [getData]);

  const defaultValues = {
    business: [
      {
        business_income_type: "44AD",
        business_ad: {
          ad_business_name: "",
          ad_industry: 0,
          ad_nature_of_business: "",
          ad_description: "",
          ad_gross_receipt_cheq_neft_rtgs_turnover: "",
          ad_gross_receipt_cheq_neft_rtgs_profit: "",
          ad_gross_receipt_cash_upi_turnover: "",
          ad_gross_receipt_cash_upi_profit: "",
          ad_fixed_asset: "",
          ad_inventory: "",
          ad_receivebles: "",
          ad_loans_and_advances: "",
          ad_investments: "",
          ad_cash_in_hand: "",
          ad_bank_balance: "",
          ad_other_assets: "",
          ad_capital: "",
          ad_secured_loans: "",
          ad_payables: "",
          ad_unsecured_loans: "",
          ad_advances: "",
          ad_other_liabilities: "",
        },
        business_ada: {
          ada_industry: 0,
          ada_business_name: "",
          ada_nature_of_business: "",
          ada_description: "",
          ada_gross_receipt_cheq_neft_rtgs_turnover: "",
          ada_gross_receipt_cheq_neft_rtgs_profit: "",
          ada_gross_receipt_cash_upi_turnover: "",
          ada_gross_receipt_cash_upi_profit: "",
          ada_fixed_asset: "",
          ada_inventory: "",
          ada_receivebles: "",
          ada_loans_and_advances: "",
          ada_investments: "",
          ada_cash_in_hand: "",
          ada_bank_balance: "",
          ada_other_assets: "",
          ada_capital: "",
          ada_secured_loans: "",
          ada_payables: "",
          ada_unsecured_loans: "",
          ada_advances: "",
          ada_other_liabilities: "",
        },
      },
    ],
  };

  const methods = useForm({
    defaultValues,
  });

  const { control, handleSubmit, reset, formState, setValue } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "business",
  });

  const watchFieldArray = useWatch({
    control,
    name: "business",
  });

  let controlledFields = fields.map((field, index) => {
    return {
      ...field,
      business_income_type: watchFieldArray[index]?.business_income_type,
    };
  });

  useEffect(() => {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        business_income_type: watchFieldArray[index]?.business_income_type,
      };
    });
  }, [watchFieldArray]);

  const removeBusiness = (index) => {
    const updatedFields = [...watchFieldArray];
    updatedFields.splice(index, 1);
    reset({ business: updatedFields });
    // setTimeout(() => {
    //   handleSubmit(onSubmit)();
    // }, 0);
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {controlledFields.map((field, index) => {
            return (
              <BusinessForm
                index={index}
                field={field}
                removeBusiness={removeBusiness}
                key={field.id}
                errors={errors}
                setValue={setValue}
                isDisabledFormButton={isDisabled}
              />
            );
          })}
        </form>
      </FormProvider>
    </div>
  );
});

const BusinessForm = ({
  index,
  field,
  removeBusiness,
  errors,
  setValue,
  isDisabledFormButton,
}) => {
  const { register } = useFormContext();

  return (
    <div className="border border-gray-200 rounded mt-3 p-[2%]">
      <div className="mt-[1.5rem] flex flex-col md:flex-row items-start md:items-center justify-between h-auto md:h-[48px]">
        <div className="mb-2 hidden md:mb-0 md:flex md:flex-row items-right">
          <h6 className="text-[#334155]">Presumptive Business Income</h6>
        </div>
        <div className="md:hidden mb-2 md:mb-0 flex justify-between items-center w-[100%]">
          <h6 className={`text-[#334155] ${index > 0 ? "w-[60%]" : ""}`}>
            Presumptive Buisness Income
          </h6>
          {index > 0 && (
            <button
              type="button"
              disabled={isDisabledFormButton}
              onClick={() => removeBusiness(index)}
              className="flex items-center px-2.5 py-2 rounded-md w-[90px] bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] hover:cursor-pointer"
              // style={{ marginRight: "100px" }} // Adjust padding and margin for mobile view
            >
              <img src={deletePic} alt="deletePic" className="mr-1.5 size-4" />
              Remove
            </button>
          )}
        </div>
        <div
          className={`flex h-[100%] items-center justify-between md:justify-evenly ${
            index > 0 ? "w-[100%] md:w-[53%]" : "w-[100%] md:w-[39%]"
          }`}
        >
          <div className="flex items-center justify-evenly rounded bg-[#F1F5F9] px-[0.17rem] py-[0.3rem]">
            {console.log(
              "field.business_income_type",
              field.business_income_type
            )}
            <label
              onClick={() =>
                setValue(`business.${index}.business_income_type`, "44AD")
              }
              className={`font-[500] text-[0.8rem] px-1.5 py-1.5 rounded leading-[1rem] cursor-pointer w-1/2 ${
                field.business_income_type == "44AD"
                  ? "text-[#FFFFFF] bg-[#334155]"
                  : "text-[#64748B]"
              }`}
            >
              <input
                type="radio"
                {...register(`business.${index}.business_income_type`)}
                value="44AD"
                checked={field.business_income_type == "44AD"}
                className="hidden"
              />
              {/* <InputField
                type="radio"
                name={`business.${index}.business_income_type`} // Use the same name for the group of radio buttons
                value="44AD" // Set the value for this radio button
                register={register} // Registering with React Hook Form
                className="hidden"
                checked={field.business_income_type == "44AD"}
              /> */}
              44AD
            </label>
            <label
              onClick={() =>
                setValue(`business.${index}.business_income_type`, "44ADA")
              }
              className={`font-[500] text-[0.8rem] px-1.5 py-1.5 rounded leading-[1rem] cursor-pointer w-1/2 ${
                field.business_income_type == "44ADA"
                  ? "text-[#FFFFFF] bg-[#334155]"
                  : "text-[#64748B]"
              }`}
            >
              <input
                type="radio"
                {...register(`business.${index}.business_income_type`)}
                value="44ADA"
                checked={field.business_income_type == "44ADA"}
                className="hidden"
              />
              {/* <InputField
                type="radio"
                name={`business.${index}.business_income_type`} // Use the same name for the group of radio buttons
                value="44ADA" // Set the value for this radio button
                register={register} // Registering with React Hook Form
                className="hidden"
                checked={field.business_income_type == "44ADA"}
              /> */}
              44ADA
            </label>
          </div>
          <div>
            <button
              style={{ cursor: "default", pointerEvents: "none" }}
              type="button"
              className="w-full md:w-auto px-4 py-2 rounded-md bg-[#E2E8F0] text-[#334155] font-[500] text-[0.8rem] md:text-[1rem] hover:cursor-pointer"
            >
              Turn over upto 3 crores
            </button>
          </div>
          {index > 0 && (
            <button
              type="button"
              disabled={isDisabledFormButton}
              onClick={() => removeBusiness(index)}
              className="md:flex items-center px-2.5 py-2 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] hover:cursor-pointer hidden"
              // style={{ marginRight: "100px" }} // Adjust padding and margin for mobile view
            >
              <img src={deletePic} alt="deletePic" className="mr-1.5 size-4" />
              Remove
            </button>
          )}
        </div>
      </div>
      {field.business_income_type == "44AD" && (
        <BusinessFormAd index={index} errors={errors} />
      )}
      {field.business_income_type == "44ADA" && (
        <BusinessFormAda index={index} errors={errors} />
      )}
    </div>
  );
};

const BusinessFormAd = ({ index, errors }) => {
  const { register } = useFormContext();
  return (
    <>
      <div className="grid grid-cols-12 mt-1 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Business Name
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              placeholder="Enter Business Name"
              name={`business.${index}.business_ad.ad_business_name`}
              register={register}
              validation={{
                required: "*Business Name is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s().]*$/, // Allows letters, numbers, spaces, and ().
                  message:
                    "*Special characters other than (), and . are not allowed", // Error message if pattern is violated
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_business_name?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Industry
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="select"
              name={`business.${index}.business_ad.ad_industry`}
              register={register}
              validation={{
                required: "*Industry is required",
                validate: (value) => value !== "0" || "*Industry is required",
              }}
              options={[
                { value: "0", label: "Select an option" },
                { value: "1", label: "Farming" },
                { value: "2", label: "Dairy" },
                { value: "3", label: "Fisheries" },
                { value: "4", label: "Textiles And Garments" },
                { value: "5", label: "Automobiles And Auto Components" },
                { value: "6", label: "Electronics" },
                { value: "7", label: "Pharmaceuticals" },
                { value: "8", label: "Chemicals" },
                { value: "9", label: "Clothing And Apparel" },
                { value: "10", label: "It Services" },
              ]}
              className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_industry?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Nature of Business
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              name={`business.${index}.business_ad.ad_nature_of_business`}
              placeholder="Enter Nature"
              register={register}
              validation={{
                required: "*Nature of Business is required",
                pattern: {
                  value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                  message: "*Only letters and spaces are allowed", // Updated error message
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad?.ad_nature_of_business
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Description
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              name={`business.${index}.business_ad.ad_description`}
              placeholder="Description"
              register={register}
              validation={{
                required: "*Description is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_description?.message}
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <h6 className="mt-4  flex flex-col md:flex-row text-[#334155]">
        Gross Receipt Via Cheque/NEFT/DD&nbsp;
        <span className=" text-[#64748B]">at least 6% profit</span>
      </h6>
      <div className="grid grid-cols-12  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Turnover
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_gross_receipt_cheq_neft_rtgs_turnover`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Turnover is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad
                ?.ad_gross_receipt_cheq_neft_rtgs_turnover?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Profit
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_gross_receipt_cheq_neft_rtgs_profit`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Profit is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad
                ?.ad_gross_receipt_cheq_neft_rtgs_profit?.message
            }
          </p>
        </div>
      </div>
      <h6 className="mt-4  flex flex-col md:flex-row text-[#334155]">
        Gross Receipt Via Cash/UPI/Others&nbsp;
        <span className="text-[#64748B]">at least 8% profit </span>
      </h6>
      <div className="grid grid-cols-12  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Turnover
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_gross_receipt_cash_upi_turnover`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Turnover is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad
                ?.ad_gross_receipt_cash_upi_turnover?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Profit
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_gross_receipt_cash_upi_profit`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Profit is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad
                ?.ad_gross_receipt_cash_upi_profit?.message
            }
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <div className="flex justify-between flex-col md:flex-row items-left mt-4">
        <h6 className="text-[#334155]">Assets</h6>
        <p className="text-[#9065B4] text-[0.8rem]">
          Mandatory if Business Income, Ignore if Personal
        </p>
      </div>
      <div className="grid grid-cols-12 mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Fixed Assets
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_fixed_asset`}
              placeholder="Enter Assets"
              register={register}
              validation={{
                required: "*Fixed Assets is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_fixed_asset?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Inventory
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_inventory`}
              placeholder="Enter Inventory"
              register={register}
              validation={{
                required: "*Inventory is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_inventory?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Receivables
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_receivebles`}
              placeholder="Enter Recievebles"
              register={register}
              validation={{
                required: "*Recievebles is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_receivebles?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Loans & Advances
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_loans_and_advances`}
              placeholder="Enter Loan Amount"
              register={register}
              validation={{
                required: "*Loans & Advances is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad?.ad_loans_and_advances
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Investments
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_investments`}
              placeholder="Enter Investments"
              register={register}
              validation={{
                required: "*Investments is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none "
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_investments?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Cash in Hand
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_cash_in_hand`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Cash in Hand is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none "
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_cash_in_hand?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Bank Balance
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_bank_balance`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Bank Balance is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_bank_balance?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Other Assets
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_other_assets`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Other Assets is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_other_assets?.message}
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <h6 className="text-[#334155] mt-4">Liablities</h6>
      <div className="grid grid-cols-12 mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Capital
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_capital`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Capital is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_capital?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Secured Loans
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_secured_loans`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Secured Loans is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_secured_loans?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Payables
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_payables`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Payables is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_payables?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Unsecured Loans
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_unsecured_loans`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Unsecured Loans is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_unsecured_loans?.message}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Advances
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_advances`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Advances is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ad?.ad_advances?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Other Liablities
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ad.ad_other_liabilities`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Other Liablities is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ad?.ad_other_liabilities
                ?.message
            }
          </p>
        </div>
      </div>
    </>
  );
};

const BusinessFormAda = ({ index, errors }) => {
  const { register } = useFormContext();
  return (
    <>
      <div className="grid grid-cols-12 mt-1 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Business Name
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              name={`business.${index}.business_ada.ada_business_name`}
              placeholder="Enter Business Name"
              register={register}
              validation={{
                required: "*Business Name is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_business_name?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Industry
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="select"
              name={`business.${index}.business_ada.ada_industry`}
              register={register}
              validation={{
                required: "*Industry is required",
                validate: (value) => value !== "0" || "*Industry is required",
              }}
              options={[
                { value: "0", label: "Select an option" },
                { value: "1", label: "Farming" },
                { value: "2", label: "Dairy" },
                { value: "3", label: "Fisheries" },
                { value: "4", label: "Textiles And Garments" },
                { value: "5", label: "Automobiles And Auto Components" },
                { value: "6", label: "Electronics" },
                { value: "7", label: "Pharmaceuticals" },
                { value: "8", label: "Chemicals" },
                { value: "9", label: "Clothing And Apparel" },
                { value: "10", label: "It Services" },
              ]}
              className="text-[#334155] md:w-[100%] w-[100%] md:px-[.8rem] md:py-[.4rem] rounded  focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_industry?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Nature of Business
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              name={`business.${index}.business_ada.ada_nature_of_business`}
              placeholder="Enter Nature"
              register={register}
              validation={{
                required: "*Nature of Business is required",
                pattern: {
                  value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                  message: "*Only letters and spaces are allowed", // Updated error message
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {/* {
              errors.business?.[index]?.business_ada?.ada_nature_of_business
                ?.message
            } */}
            {
              errors.business?.[index]?.business_ada?.ada_nature_of_business
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Description
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="text"
              name={`business.${index}.business_ada.ada_nature_of_business`}
              placeholder="Optional"
              register={register}
              validation={{
                required: "*Description is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_description?.message}
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <h6 className="mt-4 text-[#334155]">
        Gross Receipt Via Cheque/NEFT/DD{" "}
        <span className="text-[#64748B]">at least 6% profit</span>
      </h6>
      <div className="grid grid-cols-12  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Turnover
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_gross_receipt_cheq_neft_rtgs_turnover`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Turnover is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada
                ?.ada_gross_receipt_cheq_neft_rtgs_turnover?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Profit
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_gross_receipt_cheq_neft_rtgs_profit`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Profit is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada
                ?.ada_gross_receipt_cheq_neft_rtgs_profit?.message
            }
          </p>
        </div>
      </div>
      <h6 className="mt-4 text-[#334155]">
        Gross Receipt Via Cash/UPI/Others{" "}
        <span className="text-[#64748B]">at least 8% profit </span>
      </h6>
      <div className="grid grid-cols-12  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Turnover
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_gross_receipt_cash_upi_turnover`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Turnover is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada
                ?.ada_gross_receipt_cash_upi_turnover?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
            Profit
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_gross_receipt_cash_upi_profit`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Profit is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada
                ?.ada_gross_receipt_cash_upi_profit?.message
            }
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <div className="flex flex-col md:flex-row justify-between items-center mt-4">
        <h6 className="text-[#334155]">Assets</h6>
        <p className="text-[#9065B4] text-[0.8rem]">
          Mandatory if Business Income, Ignore if Personal
        </p>
      </div>
      <div className="grid grid-cols-12 mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Fixed Assets
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_fixed_asset`}
              placeholder="Enter Assets"
              register={register}
              validation={{
                required: "*Fixed Assets is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_fixed_asset?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Inventory
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_inventory`}
              placeholder="Enter Inventory"
              register={register}
              validation={{
                required: "*Inventory is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_inventory?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Receivables
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_receivebles`}
              placeholder="Enter Recievebles"
              register={register}
              validation={{
                required: "*Recievebles is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_receivebles?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Loans & Advances
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_loans_and_advances`}
              placeholder="Enter Loan Amount"
              register={register}
              validation={{
                required: "*Loans & Advances is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada?.ada_loans_and_advances
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Investments
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_investments`}
              placeholder="Enter Investments"
              register={register}
              validation={{
                required: "*Investments is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_investments?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Cash in Hand
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_cash_in_hand`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Cash in Hand is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_cash_in_hand?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Bank Balance
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_bank_balance`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Bank Balance is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_bank_balance?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Other Assets
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_other_assets`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Other Assets is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_other_assets?.message}
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <h6 className="text-[#334155] mt-4">Liablities</h6>
      <div className="grid grid-cols-12 mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Capital
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_capital`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Capital is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_capital?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Secured Loans
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_secured_loans`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Secured Loans is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_secured_loans?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Payables
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_payables`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Payables is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_payables?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Unsecured Loans
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_unsecured_loans`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Unsecured Loans is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada?.ada_unsecured_loans
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Advances
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_advances`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Advances is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.business?.[index]?.business_ada?.ada_advances?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Other Liabilties
          </h6>
          <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
            <InputField
              type="number"
              name={`business.${index}.business_ada.ada_other_liabilities`}
              placeholder="Enter Amount"
              register={register}
              validation={{
                required: "*Other Liabilities is required",
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.business?.[index]?.business_ada?.ada_other_liabilities
                ?.message
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default TaxSummaryBusiness;
