import React,{useContext} from "react";
import successLogo from 'assets/images/Serviceimages/Success Icon.svg'
import { registrationSuccessModalContext } from 'App';

function SignupSuccessModal() {
const {registrationSuccessModal,setRegistrationSuccessModal} = useContext(registrationSuccessModalContext)

  return (
    <>
    {registrationSuccessModal &&  <div className=" z-20 flex justify-center items-center  fixed inset-0 bg-slate-500 bg-opacity-40 backdrop-blur-sm">
      <div className="w-[80%] bg-white-body p-4 rounded">
                  <img
                    className="col-span-full mx-auto"
                    src={successLogo}
                    alt="successLogo"
                  />


                  <p className="text-center my-2">
                    Our representative will give you a call back soon for
                    verification
                  </p>
                  <p

                    onClick={() => {
                    //   setLogin(true);
                    //   setRegistrationSuccess(false);
                    setRegistrationSuccessModal(false)
                    }}
                    className=" py-2 rounded-md my-2 text-center hover:cursor-pointer  bg-purple-dark-shade text-white font-medium"
                  >
                    Great!
                  </p>

              
                </div>
      </div>}
     
    </>
  );
}

export default SignupSuccessModal;
