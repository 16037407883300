import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useState, useEffect } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import { useParams } from "react-router-dom";

const EditLandAddress = ({
  flatNo,
  setflatNo,
  surveryNo,
  area,
  city,
  pincode,
  country,
  state,
  setSurveryNo,
  setarea,
  setcity,
  setpincode,
  setcountry,
  setstate,
  updateDetails,
}) => {
  const { estate_type } = useParams();

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  // State to manage errors and form validity
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    // Validate the form whenever the input values change
    const validateForm = () => {
      const newErrors = {};

      // Regular expressions for validation
      const positiveNumberRegex = /^[0-9]+$/; // For Survey Number (Whole numbers)
      const alphabetRegex = /^[A-Za-z\s]+$/; // For Area and City (Alphabets only)
      const pincodeRegex = /^[1-9]{1}[0-9]{5}$/; // For Pincode (6 digits, can't start with 0)

      if (estate_type === "land") {
        if (
          !surveryNo ||
          !positiveNumberRegex.test(surveryNo) ||
          parseInt(surveryNo) <= 0
        ) {
          newErrors.surveryNo =
            "Survey Number must be a whole number greater than 0.";
        }
      }
      if (estate_type === "building") {
        if (!flatNo.trim()) {
          newErrors.flatNo = "Flat number is required.";
        } else if (!/^[A-Za-z0-9\s\-\/\.]+$/.test(flatNo.trim())) {
          newErrors.flatNo =
            "Flat number can only contain alphanumeric characters, spaces, hyphens, slashes, or periods.";
        } else if (flatNo.trim().length < 3 || flatNo.trim().length > 20) {
          newErrors.flatNo =
            "Flat number should be between 3 and 20 characters.";
        }
      }
      if (!area || !alphabetRegex.test(area)) {
        newErrors.area = "Area must only contain alphabets.";
      }
      if (!city || !alphabetRegex.test(city)) {
        newErrors.city = "City must only contain alphabets.";
      }
      if (!pincode || !pincodeRegex.test(pincode)) {
        newErrors.pincode =
          "Pincode must be exactly 6 digits and cannot start with 0.";
      }
      if (!state) newErrors.state = "State is required.";
      if (!country) newErrors.country = "Country is required.";

      setErrors(newErrors);
      setFormValid(Object.keys(newErrors).length === 0); // If no errors, the form is valid
    };

    validateForm();
  }, [surveryNo, area, city, pincode, state, country, flatNo]);

  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" />
        }
        heading={`Edit ${estate_type === "land" ? "Land" : "Building"} Value`}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[10rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-2">
                {estate_type === "land" ? (
                  <div className="input-container">
                    <label className="input-label">Survey Number</label>
                    <InputField
                      value={surveryNo}
                      onChange={(e) => setSurveryNo(e.target.value)}
                      type="number"
                      placeholder="Enter Survey Number"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.surveryNo && (
                      <p className="text-red-500 text-xs">{errors.surveryNo}</p>
                    )}
                  </div>
                ) : (
                  <div className="input-container">
                    <label className="input-label">
                      Flat/Door/Block Number
                    </label>
                    <InputField
                      value={flatNo}
                      onChange={(e) => setflatNo(e.target.value)}
                      type="number"
                      placeholder="Enter Survey Number"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.flatNo && (
                      <p className="text-red-500 text-xs">{errors.flatNo}</p>
                    )}
                  </div>
                )}

                <div className="input-container">
                  <label className="input-label">Area</label>
                  <InputField
                    value={area}
                    onChange={(e) => setarea(e.target.value)}
                    type="text"
                    placeholder="Enter Area"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.area && (
                    <p className="text-red-500 text-xs">{errors.area}</p>
                  )}
                </div>

                <div className="input-container flex flex-col mt-[0.6rem]">
                  <label className="input-label">City</label>
                  <InputField
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                    type="text"
                    placeholder="Enter City"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.city && (
                    <p className="text-red-500 text-xs">{errors.city}</p>
                  )}
                </div>

                <div className="input-container mt-[0.2rem]">
                  <label className="input-label">Pincode</label>
                  <InputField
                    value={pincode}
                    onChange={(e) => setpincode(e.target.value)}
                    type="number"
                    placeholder="Enter Pincode"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.pincode && (
                    <p className="text-red-500 text-xs">{errors.pincode}</p>
                  )}
                </div>

                <div className="input-container mt-[0.4rem]">
                  <label className="input-label">State</label>
                  <InputField
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    type="select"
                    className="input-box border rounded-md bg-white"
                    options={[
                      { label: "Select State" },
                      ...indianStates.map((state) => ({ label: state })),
                    ]}
                  />
                  {errors.state && (
                    <p className="text-red-500 text-xs">{errors.state}</p>
                  )}
                </div>

                <div className="input-container mt-[0.4rem]">
                  <label className="input-label">Country</label>
                  <InputField
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                    type="select"
                    className="input-box border rounded-md bg-white"
                    options={[
                      { value: "", label: "Select Country" },
                      { value: "India", label: "India" },
                    ]}
                  />
                  {errors.country && (
                    <p className="text-red-500 text-xs">{errors.country}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-[1rem]">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={updateDetails}
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                disabled={!formValid} // Disable Save button if form is invalid
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default EditLandAddress;
