import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";
import usePatchLoanDetails from "../../data/usePatchLoanDetails";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const EditLoan = ({ editValue, detailData, setIsReload }) => {
  const [
    patchLoanData,
    patchLoanError,
    patchLoanIsLoading,
    patchLoanDetails,
  ] = usePatchLoanDetails();
  const { estate_type } = useParams();
  const removeBtnRef = useRef(null);
  const [name, setName] = useState("");
  const [isClose, setIsClose]=useState(false)
  const [assetType, setAssetType] = useState("active");
  
  const [errors, setErrors]= useState({})

  const [loanForm, setLoanForm]= useState({
    "borrower_name": "",
    "relation": "",
    "loan_type": "",
    "loan_purpose": "",
    "loan_agreement": null,
    "loan_start_date": "",
    "loan_end_date": "",
    "loan_due_date": "",
    "period":"",
    "mode_of_transaction": "",
    "amount_borrowed": "",
    "amount_received": "",
    "balance_amount":"",
    "interest_rate": "",
    "payout_period": "",
    "estimated_interest": "",
    "interest_charged":false,
    "asset_type":"active"
})

useEffect(()=>{
  if(detailData){
     const {total_active_assets, total_assets, total_assets_amount, total_idle_assets,repayment, user, ...rest}=detailData;
     setLoanForm(rest)
     setAssetType(rest.asset_type)
  }
},[detailData])

useEffect(()=>{
  if(patchLoanData){
    showSuccessToast("Data updated successfully")
    setIsReload(true)
    setIsClose(true)
  }
  if(patchLoanError){
    showErrorToast("Error in Updating")
  }
},[patchLoanData, patchLoanError])

const handleInputChange=(e)=>{
  let { name, value, type, files } = e.target;
  if(type==="radio"){
    const bool= value==="true"?true: value==="false"?false:null;
    value=bool
 }
  setLoanForm((prevForm) => ({
    ...prevForm,
    [name]: type === 'file' ? files[0] : value
  }));
}

const validateLoanForm = () => {
  const newErrors = {};

  if(editValue==="loanValue"){

  }else if(editValue==="loanDetails"){
  // Borrower Name: Required, only letters and spaces allowed
  const namePattern = /^[a-zA-Z\s]+$/;
  if (!loanForm.borrower_name) {
    newErrors.borrower_name = "Borrower name is required.";
  } else if (!namePattern.test(loanForm.borrower_name)) {
    newErrors.borrower_name = "Borrower name should only contain letters and spaces.";
  }

  // Relation: Required field
  // if (!loanForm.relation) {
  //   newErrors.relation = "Relation is required.";
  // }
  }else if(editValue==="loanInfo"){

      // Loan Type: Required field
      if (!loanForm.loan_type) {
        newErrors.loan_type = "Loan type is required.";
      }
    
      // Loan Purpose: Required field
      // if (!loanForm.loan_purpose) {
      //   newErrors.loan_purpose = "Loan purpose is required.";
      // }
    
      // if(typeof(loanForm.loan_agreement)==="string"){

      // }else{
      //        // Loan Agreement: Required, must be a valid file format (e.g., PDF, JPG, JPEG, PNG)
      //     const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
      //     if (!loanForm.loan_agreement) {
      //       newErrors.loan_agreement = "Loan agreement file is required.";
      //     } else if (!filePattern.test(loanForm.loan_agreement.name)) {
      //       newErrors.loan_agreement = "Loan agreement should be a valid file format (PDF, JPG, or PNG).";
      //     }
      // }    
      // Loan Start Date: Required, should be a valid date
      // if (!loanForm.loan_start_date) {
      //   newErrors.loan_start_date = "Loan start date is required.";
      // } else if (isNaN(new Date(loanForm.loan_start_date).getTime())) {
      //   newErrors.loan_start_date = "Loan start date should be a valid date.";
      // }
    
      // // Loan End Date: Required, should be a valid date and after Loan Start Date
      // if (!loanForm.loan_end_date) {
      //   newErrors.loan_end_date = "Loan end date is required.";
      // } else if (isNaN(new Date(loanForm.loan_end_date).getTime())) {
      //   newErrors.loan_end_date = "Loan end date should be a valid date.";
      // } else if (new Date(loanForm.loan_end_date) <= new Date(loanForm.loan_start_date)) {
      //   newErrors.loan_end_date = "Loan end date should be after the start date.";
      // }
    
      // // Loan Due Date: Required, should be a valid date and after Loan End Date
      // if (!loanForm.loan_due_date) {
      //   newErrors.loan_due_date = "Loan due date is required.";
      // } else if (isNaN(new Date(loanForm.loan_due_date).getTime())) {
      //   newErrors.loan_due_date = "Loan due date should be a valid date.";
      // } else if (new Date(loanForm.loan_due_date) <= new Date(loanForm.loan_end_date)) {
      //   newErrors.loan_due_date = "Loan due date should be after the end date.";
      // }
    
      // Period: Required, positive integer
      // if (!loanForm.period || isNaN(loanForm.period)) {
      //   newErrors.period = "Period is required and should be a number.";
      // } else if (loanForm.period <= 0) {
      //   newErrors.period = "Period must be a positive number.";
      // }
    
      // Mode of Transaction: Required field
      if (!loanForm.mode_of_transaction) {
        newErrors.mode_of_transaction = "Mode of transaction is required.";
      }
    
      // Amount Borrowed: Required, numeric, and positive
      // if (!loanForm.amount_borrowed || isNaN(loanForm.amount_borrowed)) {
      //   newErrors.amount_borrowed = "Amount borrowed is required and should be a number.";
      // } else if (loanForm.amount_borrowed <= 0) {
      //   newErrors.amount_borrowed = "Amount borrowed must be greater than 0.";
      // }
    
      // // Amount Received: Required, numeric, and positive
      // if (!loanForm.amount_received || isNaN(loanForm.amount_received)) {
      //   newErrors.amount_received = "Amount received is required and should be a number.";
      // } else if (loanForm.amount_received <= 0) {
      //   newErrors.amount_received = "Amount borrowed must be greater than 0.";
      // }
    
    // Balance Amount: Required, numeric, must be positive, and match Amount Borrowed - Amount Received
    // const calculatedBalance = loanForm.amount_borrowed - (loanForm.amount_received || 0);
    
    // if (loanForm.balance_amount === "" || loanForm.balance_amount === undefined) {
    //   newErrors.balance_amount = "Balance amount is required.";
    // } else if (isNaN(loanForm.balance_amount)) {
    //   newErrors.balance_amount = "Balance amount should be a number.";
    // } else if (loanForm.balance_amount <= 0) {
    //   newErrors.balance_amount = "Balance amount must be greater than 0.";
    // }
  }else if(editValue==="loanInterest"){
      // Interest Rate: Required, numeric, between 0 and 100
      // if (!loanForm.interest_rate || isNaN(loanForm.interest_rate)) {
      //   newErrors.interest_rate = "*required and number";
      // } else if (loanForm.interest_rate < 0 || loanForm.interest_rate > 100) {
      //   newErrors.interest_rate = "Interest rate should be between 0 and 100.";
      // }
    
      // Payout Period: Required field
      if (!loanForm.payout_period) {
        newErrors.payout_period = "Payout period is required.";
      }
    // Estimated Interest: Optional, numeric, positive
    if (loanForm.estimated_interest === "" || loanForm.estimated_interest === undefined) {
      // No error because it's optional
      newErrors.estimated_interest = "Estimated interest is required.";
    } else if (isNaN(loanForm.estimated_interest) || loanForm.estimated_interest < 0) {
      newErrors.estimated_interest = "Estimated interest should be a positive number.";
    }
  } 
  // Set error state and check if no errors remain
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const handleSubmit = () => {
  if (!validateLoanForm()) return;

  const formData = new FormData();

  // Append the id to the FormData
  formData.append("id", loanForm.id);

  // Helper function to check if a value is valid
  const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";

  // Append fields based on editValue
  if (editValue === "loanValue") {
    if (isNotEmpty(assetType)) {
      formData.append("asset_type", assetType);
    }
  } else if (editValue === "loanDetails") {
    if (isNotEmpty(loanForm.borrower_name)) {
      formData.append("borrower_name", loanForm.borrower_name);
    }
    if (isNotEmpty(loanForm.relation)) {
      formData.append("relation", loanForm.relation);
    }
  } else if (editValue === "loanInfo") {
    if (isNotEmpty(loanForm.loan_type)) {
      formData.append("loan_type", loanForm.loan_type);
    }
    if (isNotEmpty(loanForm.loan_purpose)) {
      formData.append("loan_purpose", loanForm.loan_purpose);
    }
    if (typeof loanForm.loan_agreement !== "string" && loanForm.loan_agreement) {
      formData.append("loan_agreement", loanForm.loan_agreement);
    }
    if (isNotEmpty(loanForm.loan_start_date)) {
      formData.append("loan_start_date", loanForm.loan_start_date);
    }
    if (isNotEmpty(loanForm.loan_end_date)) {
      formData.append("loan_end_date", loanForm.loan_end_date);
    }
    if (isNotEmpty(loanForm.loan_due_date)) {
      formData.append("loan_due_date", loanForm.loan_due_date);
    }
    if (isNotEmpty(loanForm.period)) {
      formData.append("period", loanForm.period);
    }
    if (isNotEmpty(loanForm.mode_of_transaction)) {
      formData.append("mode_of_transaction", loanForm.mode_of_transaction);
    }
    if (isNotEmpty(loanForm.amount_borrowed)) {
      formData.append("amount_borrowed", loanForm.amount_borrowed);
    }
    if (isNotEmpty(loanForm.amount_received)) {
      formData.append("amount_received", loanForm.amount_received);
    }
    if (isNotEmpty(loanForm.balance_amount)) {
      formData.append("balance_amount", loanForm.balance_amount);
    }
  } else if (editValue === "loanInterest") {
    if (isNotEmpty(loanForm.interest_charged)) {
      formData.append("interest_charged", loanForm.interest_charged);
    }
    if (isNotEmpty(loanForm.interest_rate)) {
      formData.append("interest_rate", loanForm.interest_rate);
    }
    if (isNotEmpty(loanForm.payout_period)) {
      formData.append("payout_period", loanForm.payout_period);
    }
    if (isNotEmpty(loanForm.estimated_interest)) {
      formData.append("estimated_interest", loanForm.estimated_interest);
    }
  }

  // Print FormData values to console
  for (let [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  console.log("Form in submission:", loanForm);

  // Send formData to your API
  patchLoanDetails(formData, loanForm.asset);
};

  useEffect(() => {
    if (editValue) {
      switch (editValue) {
        case "loanValue":
          setName("Edit Loan Vlaue");
          break;
        case "loanDetails":
          setName("Edit Borrower Information");
          break;
        case "loanInfo":
          setName("Edit Loan Information");
          break;
        case "loanInterest":
          setName("Edit Interest Details");
        default:
          break;
      }
    }
  }, [editValue]);
  const toggleAssetType = (type) => {
    setAssetType(type);
  };

  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };

  const getContent = () => {
    switch (editValue) {
      case "loanValue":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[1rem] px-[1rem] py-[0.7rem]">
              <div>
                <label className="input-label text-[0.75rem]">
                  Type of Asset
                </label>
                <div className="p-1 flex items-center border rounded-md overflow-hidden bg-white w-fit">
                  <button
                    onClick={() => toggleAssetType("active")}
                    className={`px-2 py-1.5 text-[0.8rem] font-medium ${
                      assetType === "active"
                        ? "bg-gray-800 text-white"
                        : "bg-white text-gray-500"
                    } rounded-md transition-all`}
                    style={{ minWidth: "100px" }} // ensures both buttons have a consistent width
                  >
                    Active Asset
                  </button>
                  <button
                    onClick={() => toggleAssetType("idle")}
                    className={`px-2 py-1.5 text-[0.8rem] font-medium ${
                      assetType === "idle"
                        ? "bg-gray-800 text-white"
                        : "bg-white text-gray-500"
                    } rounded-md transition-all`}
                    style={{ minWidth: "100px" }} // ensures both buttons have a consistent width
                  >
                    Idle Asset
                  </button>
                </div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  {assetType === "active"
                    ? "This asset is generating income."
                    : "This asset is idle and not generating income."}
                </label>
              </div>
            </div>
            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] mb-3`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Loan from the Assets?
              </h4>
              <div className="w-[20%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove`}
                  px={"px-[0.4rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        );
      case "loanDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-[1.5rem]">
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Borrower Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={loanForm.borrower_name}
                    onChange={handleInputChange}
                    name="borrower_name"
                    placeholder="Borrower Name"
                  />
                  {errors.borrower_name && <p className="text-red-500 text-[0.75rem]">{errors.borrower_name}</p>}
                </div>
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Relation
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={loanForm.relation}
                    onChange={handleInputChange}
                    name="relation"
                    placeholder="Relation"
                  />
                  {errors.relation && <p className="text-red-500 text-[0.75rem]">{errors.relation}</p>}
                </div>
              </div>
            </div>
          </>
        );
      case "loanInfo":
        return (
          <>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {/* purchase details */}
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Type
                </h4>
                <div className="grid grid-cols-12 py-1 gap-3">
                  <div className="input-container flex flex-col gap-1 col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Loan Type
                    </label>
                    <InputField
                      type="select"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                      options={[
                        { value: "", label: "Select Type" },
                        { value: "Secured", label: "SECURED" },
                        { value: "Unsecured", label: "UNSECURED" },
                        { value: "Hard", label: "Hard Loan" },
                      ]}
                      name="loan_type"
                      value={loanForm.loan_type}
                      onChange={handleInputChange}
                    />
                    {errors.loan_type && <p className="text-red-500 text-[0.75rem]">{errors.loan_type}</p>}
                  </div>

                  <div className="input-container col-span-4 mt-2.5 w-[100%] group">
                    <WhiteButton
                      name="Upload Agreement"
                      px={"px-[0.2rem]"}
                      py={"py-[0.5rem]"}
                      text={"text-[0.75rem]"}
                      mt={"mt-[12px]"}
                      onClick={()=>document.getElementById("file-upload").click()}
                    />
                    {errors.loan_agreement && <p className="text-red-500 text-[0.75rem]">{errors.loan_agreement}</p>}
                  </div>
                  <InputField 
                    id={`file-upload`}
                    type="file"
                    onChange={handleInputChange}
                    className="hidden"
                    name="loan_agreement"
                  />
                </div>
                <div className="grid grid-cols-12 py-1 gap-3 mt-2.5">
                  <div className="input-container flex flex-col gap-1 col-span-6 w-[100%] group">
                  <InputField
                    value={loanForm.loan_purpose}
                    type="text"
                    name="loan_purpose"
                    onChange={handleInputChange}
                    placeholder="Mention Purpose (If Any)"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                  />
                    {errors.loan_purpose && <p className="text-red-500 text-[0.75rem]">{errors.loan_purpose}</p>}
                  </div>
                </div>
              </div>
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Duration
                </h4>
                <div className="grid grid-cols-12 gap-2 mt-3">
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Start Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.loan_start_date}
                      name="loan_start_date"
                      onChange={handleInputChange}
                    />
                    {errors.loan_start_date && <p className="text-red-500 text-[0.75rem]">{errors.loan_start_date}</p>}
                  </div>
                  <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Period
                    </label>
                    <InputField
                      type="text"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.period}
                      onChange={handleInputChange}
                      name="period"
                      placeholder="period"
                    />
                    {errors.period && <p className="text-red-500 text-[0.75rem]">{errors.period}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-3">
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Due Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.loan_due_date}
                      name="loan_due_date"
                      onChange={handleInputChange}
                    />
                    {errors.loan_due_date && <p className="text-red-500 text-[0.75rem]">{errors.loan_due_date}</p>}
                  </div>
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      End Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.loan_end_date}
                      name="loan_end_date"
                      onChange={handleInputChange}
                    />
                    {errors.loan_end_date && <p className="text-red-500 text-[0.75rem]">{errors.loan_end_date}</p>}
                  </div>
                </div>
              </div>
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Amount
                </h4>
                <div className="grid grid-cols-12 py-1 gap-3 mt-3">
                  <div className="input-container flex flex-col gap-1 col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Mode of Transaction
                    </label>
                    <InputField
                      type="select"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                      options={[
                        { value: "", label: "Select Type" },
                        { value: "bank_transfer", label: "Bank Transfer" },
                        { value: "cash", label: "Cash" },
                        { value: "cheque", label: "Cheque" },
                        { value: "upi", label: "UPI" },
                        { value: "online_payment", label: "Online Payment" },
                      ]}
                      name="mode_of_transaction"
                      value={loanForm.mode_of_transaction}
                      onChange={handleInputChange}
                    />
                    {errors.mode_of_transaction && <p className="text-red-500 text-[0.75rem]">{errors.mode_of_transaction}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-2">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Amount Borrowed
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="amount_borrowed"
                        value={loanForm.amount_borrowed}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.amount_borrowed && <p className="text-red-500 text-[0.75rem]">{errors.amount_borrowed}</p>}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Payments Received
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="amount_received"
                        value={loanForm.amount_received}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.amount_received && <p className="text-red-500 text-[0.75rem]">{errors.amount_received}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-2">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Balance Amount
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="balance_amount"
                        value={loanForm.balance_amount}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.balance_amount && <p className="text-red-500 text-[0.75rem]">{errors.balance_amount}</p>}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "loanInterest":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-2 gap-3">
                <div className="input-container mt-[0.6rem]">
                  <label className="input-label">
                    Interest Charged on this loan?
                  </label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        name="interest_charged"
                        value={true}
                        className="mr-2 custom-radio"
                        onChange={handleInputChange}
                        checked={loanForm.interest_charged===true}
                      />
                      Yes
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                        type="radio"
                        name="interest_charged"
                        value={false}
                        onChange={handleInputChange}
                        checked={loanForm.interest_charged===false}
                        className="mr-2 custom-radio"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-3 items-center mt-3">
                <div className="flex flex-col gap-1  w-[20%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Interest Rate
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={loanForm.interest_rate}
                    onChange={handleInputChange}
                    name="interest_rate"
                    placeholder="Interest Rate"
                  />
                  {errors.interest_rate && <p className="text-red-500 text-[0.75rem]">{errors.interest_rate}</p>}
                </div>
                <div className="input-container w-[35%] flex flex-col gap-1 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Payout
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "monthly", label: "Monthly" },
                      { value: "quarterly", label: "Quarterly" },
                      { value: "annually", label: "Annually" },
                    ]}
                    name="payout_period"
                    value={loanForm.payout_period}
                    onChange={handleInputChange}
                  />
                  {errors.payout_period && <p className="text-red-500 text-[0.75rem]">{errors.payout_period}</p>}
                </div>
                <div className="w-[45%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Interest
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      name="estimated_interest"
                      value={loanForm.estimated_interest}
                      onChange={handleInputChange}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors.estimated_interest && <p className="text-red-500 text-[0.75rem]">{errors.estimated_interest}</p>}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" onClick={()=>setIsClose(false)}/>
        }
        heading={name}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"}  onClick={handleSubmit}/>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
      <RemoveAsset
        heading="Loan"
        assetId={loanForm.asset}
        deleteId={`${loanForm.asset}/?id=${loanForm.id}&model_type=loans_to_family_or_friends`}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditLoan;
