import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import friendsPic from "assets/images/assets/Friends.svg";
import domainPic from "assets/images/assets/domain2.svg";
import intellectualPic from "assets/images/assets/intellectual.svg";
import { Link, useLocation } from "react-router-dom";
import {
  ASSETS_OTHER_ASSET_DETAILED_URL,
  ASSETS_PERSONAL_ASSET_DETAILED_URL,
  ASSETS_TYPE_URL,
} from "constants/routes";
import AddOtherAsset from "./AddOtherAsset";
import useGetAssetTypeOverview from "../data/useGetAssetTypeOverview";
import AddNewDomain from "./Add/AddNewDomain";
import AddNewIntellectual from "./Add/AddNewIntellectual";
import AddNewLoan from "./Add/AddNewLoan";

const OtherAsset = () => {
  const [otherAssetData, setOtherAssetData]= useState({})
  const addDomainBtnRef= useRef(null)
  const addIntellectualBtnRef= useRef(null)
  const addLoanBtnRef = useRef(null)
  const [isAddDomain, setIsAddDomain]= useState(false)
  const [isAddIntellectual, setIsAddIntellectual]=useState(false)
  const [isAddLoan, setIsAddLoan]=useState(false)
  const [getData, getrror, getIsLoading, getRealEstate] =
  useGetAssetTypeOverview();
  const location = useLocation()
  const assetId=location.state?.assetId
  useEffect(() => {
    getRealEstate("other-assets");
  }, []);

  useEffect(()=>{
    if(getData?.data){
      setOtherAssetData(getData.data)
    }
  },[getData])

  useEffect(()=>{
    if(isAddDomain){
      addDomainBtnRef.current.click()
    }
  },[isAddDomain])

  useEffect(()=>{
    if(isAddIntellectual){
      addIntellectualBtnRef.current.click()
    }
  },[isAddIntellectual])

  useEffect(()=>{
    if(isAddLoan){
      addLoanBtnRef.current.click()
    }
  },[isAddLoan])

  const openHandleFormDomain=()=>{
    setIsAddDomain(true)
  }

  const openHandleFormIntellectual=()=>{
    setIsAddIntellectual(true)
  }

  const openHandleFormLoan=()=>{
    setIsAddLoan(true)
  }
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Other Assets"} />
      <div className=" mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Other Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">₹{otherAssetData?.total_assets_value}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{otherAssetData?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{otherAssetData?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{otherAssetData?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddOtherAsset />
        </div>
      </div>

      {/* Loans and Advances */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Loans and Advances
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {otherAssetData?.LoanAndAdvance?.[0]?.total_loans_value || 0} Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton onClick={openHandleFormLoan} px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {
            otherAssetData?.LoanAndAdvance?.length>0 ? <>
                      <Link
            to={`${ASSETS_TYPE_URL.replace(
              ":type",
              "other-asset-loan"
            )}/?id=${assetId["Loans To Family/Friends"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={friendsPic} alt="friendsPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Loans and Advances
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {otherAssetData?.LoanAndAdvance?.[0]?.total_loans_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={friendsPic} alt="friendsPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Loans and Advances</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button onClick={openHandleFormLoan} className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>

      {/* Intellectual */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Intellectual Property
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {otherAssetData?.IntellectualProperty?.[0]?.total_intellectual_property_value || 0} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton onClick={openHandleFormIntellectual} px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {
            otherAssetData?.IntellectualProperty?.length>0 ? <>
                         <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-intellectual"
              )}/?id=${assetId["Intellectual Property"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img
                src={intellectualPic}
                alt="intellectualPic"
                className="w-7 h-7 mb-2"
              />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Intellectual Property
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {otherAssetData?.IntellectualProperty?.[0]?.total_intellectual_property_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={intellectualPic} alt="intellectualPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Trademark</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button onClick={openHandleFormIntellectual} className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>

      {/* Domain */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Domain
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {otherAssetData?.domain?.[0]?.total_domains_value || 0} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton onClick={openHandleFormDomain} px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1rem]">
          {
            otherAssetData?.domain?.length > 0 ? <>
                        <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-domain"
              )}/?id=${assetId["Domain"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={domainPic} alt="domainPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Domain
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {otherAssetData?.domain?.[0]?.total_domains_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={domainPic} alt="domainPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Domain</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button onClick={openHandleFormDomain} className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>
      {isAddDomain && (
        <AddNewDomain
          categoryId={assetId["Domain"]}
          setIsAddDomain={setIsAddDomain}
          isAddDomain={isAddDomain}
          triggerElement={
            <button ref={addDomainBtnRef} className="hidden">
              Add Domain
            </button>
          }
        />
      )}
      {isAddIntellectual && (
        <AddNewIntellectual
          categoryId={assetId["Intellectual Property"]}
          setIsAddIntellectual={setIsAddIntellectual}
          isAddIntellectual={isAddIntellectual}
          triggerElement={
            <button ref={addIntellectualBtnRef} className="hidden">
              Add Intellectual
            </button>
          }
        />
      )}
      {isAddLoan && (
        <AddNewLoan
          categoryId={assetId["Loans To Family/Friends"]}
          setIsAddLoan={setIsAddLoan}
          isAddLoan={isAddLoan}
          triggerElement={
            <button ref={addLoanBtnRef} className="hidden">
              Add Land
            </button>
          }
        />
      )}
    </div>
  );
};

export default OtherAsset;
