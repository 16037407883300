import React, { useState } from 'react'

function Input(props) {
   
  return (
    <>
    <label className='font-[500] text-[0.9rem] slightDarkBlack' for='input'> {props.label}</label><br/>
    <input  type={props.type} value={props.value} className={`${props.border} border-1  rounded px-3 py-2  w-[100%] my-1 tracking-[0.05em]`} placeholder={props.placeholder} onChange={(e)=>{props.setValue(e.target.value)}}/>
    {/* <input id='input' type={props.type} value={props.value} className={`${props.border} border-1  rounded px-3 py-2  w-[100%] my-1 tracking-[0.05em]`} placeholder={props.placeholder} onChange={(e)=>{props.setValue(e.target.value)}}/> */}
    </>
  )
}

export default Input
