import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { myContext } from "App";
import { useState, useContext, useEffect } from "react";
import downloadImg from "assets/images/dashboard/download.svg";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";

export default function ConfirmDialog(props) {
  const [open, setOpen] = useState(false);

  const yesClicked = () => {
    if (props.href) {
      saveAs(props.href, "Document");
    }

    if (props.href_reports) {
      saveAs(props.href_reports, "Document");
    }
  };

  // console.log("props.href_reports:", props.href_reports)
  // console.log("props.href:", props.href)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  return (
    <>
      {props.href ||
      location.pathname === "/orders" ||
      location.pathname === "/dashboard" ? (
        <button onClick={handleClickOpen}>
          <div className="flex gap-2 items-center">
            <img src={downloadImg} />
            <span className="text-[#9065B4] text-[.9rem] font-[500] leading-[1.5rem]">
              Download
            </span>
          </div>
        </button>
      ) : (
        <button
          onClick={handleClickOpen}
          disabled={!props.href_reports}
          className={`font-[600] text-[1rem] text-[#64748B] bg-white rounded-md shadow-md py-2 px-4 border border-[#E2E8F0] ${
            !props.href_reports ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Download
        </button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: { xs: "90%", md: "35%" },
            height: { xs: "auto", md: "35%" },
          },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogContent className="md:font-[500] text-[1rem] md:text-[1.2rem] flex justify-center mt-6 items-center">
          <b>Download Document</b>
        </DialogContent>
        <DialogActions className="mx-2 my-4">
          <button
            onClick={handleClose}
            className=" py-2 px-4 rounded-md bg-purple-light-shade"
          >
            No
          </button>
          <button
            className=" text-white no-underline bg-purple-dark-shade py-2 px-4 rounded-md"
            onClick={() => {
              yesClicked();
              handleClose();
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
