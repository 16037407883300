import useAPICall from "hooks/useAPICall";
import { GET_DOWNLOAD_DOCUMENT_LIST_URL } from "constants/apis";

const useGetDocsListDownload = () => {
  const [docsListDownloadData, docsListDownloadError, docsListDownloadIsLoading, callGetDocsListDownload, setSuccessDocsListDownloadData, setDocsListDownloadError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setDocsListDownloadError(en.something_went_wrong);
    setSuccessDocsListDownloadData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const docsListDownloadData = res;
        if (docsListDownloadData && typeof docsListDownloadData === "object") {
          setSuccessDocsListDownloadData(docsListDownloadData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const getDocsListDownload = (body) => {
    const url = GET_DOWNLOAD_DOCUMENT_LIST_URL
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log(body)
    // console.log('getDocsListDownload running')
    callGetDocsListDownload({
      url,
      method : "GET",
      statusObj,
      
      config,
      defaultFallback,
      body
    });
  };
  return [docsListDownloadData, docsListDownloadError, docsListDownloadIsLoading, getDocsListDownload, setSuccessDocsListDownloadData, setDocsListDownloadError];
};

export default useGetDocsListDownload;
