// Layout.js
import React, { createContext, useContext, useEffect, useState } from "react";
import ProfileNav from "../Dashboard/ProfileNav";
import TopProfile from "./TopProfile";
import { Outlet, useLocation } from "react-router-dom";
import { myContext } from "App";

export const ProfileContext = createContext();

const Layout = ({ children }) => {
  const [isAccessToken, setIsAccessToken] = useState(null);
  const [incomeTaxReturnId, setIncomeTaxReturnId]= useState(null);
  const [assetIds, setAssetIds]= useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isReturnYear = queryParams.get("isReturnYear");
  const [profilePic, setProfilePic] = useState("");

  // console.log("props:", props)

  const [returnYear, setReturnYear] = useState(
    isReturnYear ? isReturnYear : ""
  );
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const token = searchParams.get("token");
  //   console.log("t1")
  //   if(token){
  //     console.log("token:", token )
  //     // setIsAccessToken();
  //   }
  // }, []);

  const useAccessToken = new URLSearchParams(location.search).get(
    "accessToken"
  );
  let permissionType = new URLSearchParams(location.search).get(
    "permissionType"
  );
  // console.log('permissioTypeinOg', permissionType)
  // console.log('useAccessToken',useAccessToken)

  useEffect(() => {
    if (useAccessToken && permissionType) {
      sessionStorage.setItem("accessToken", useAccessToken);
      sessionStorage.setItem("permissionType", permissionType);
    }
  }, [location, useAccessToken]);

  useEffect(() => {
    const storedProfilePic = JSON.parse(
      localStorage.getItem("user")
    )?.profile_picture;
    const presentReturnYear = JSON.parse(
      localStorage.getItem("user")
    )?.current_income_tax_return_year_name;
    if (presentReturnYear) {
      setReturnYear(presentReturnYear);
    }
    if (storedProfilePic) {
      setProfilePic(storedProfilePic);
    }
  }, []);

  const updateProfilePic = (newPic) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, profile_picture: newPic })
    );
    setProfilePic(newPic);
  };

  const updateIncomeTaxReturnId= (returnId)=>{
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, current_income_tax_return_id: returnId })
    );
    setIncomeTaxReturnId(returnId);
  }

  const getReturnYear = (year) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, current_income_tax_return_year_name: year })
    );
    console.log("year", year);
    setReturnYear(year);
  };
  return (
    <>
      <ProfileContext.Provider
        value={{ profilePic, updateProfilePic, returnYear, getReturnYear, updateIncomeTaxReturnId, incomeTaxReturnId , assetIds, setAssetIds}}
      >
        <div className="grid grid-cols-12 ">
          <div className="col-span-2 hidden md:block">
            <ProfileNav />
          </div>
          <div className="col-span-12 md:col-span-10">
            <div className=" absolute top-0 right-0 p-[2%] z-50">
              {location.pathname !== "/dashboard" ? <TopProfile /> : ""}
            </div>
            <Outlet />
          </div>
        </div>
      </ProfileContext.Provider>
    </>
  );
};

export default Layout;
