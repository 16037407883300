import React, { useEffect, useState } from "react";
import CustomizableDrawer from "../Reports/Drawer";
import Button from "components/Button/BtnOrange";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

// images
import bankIcon from "assets/images/taxProfile/bankIcon.svg";
import arrowRight from "assets/images/taxProfile/arrow-right.svg";
import success from "assets/images/taxProfile/successPrimary.svg";
import useGetBankAccounts from "./data/useGetBankAccounts";
import usePostBankAccount from "./data/usePostBankAccount";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";
import edit from "assets/images/taxProfile/edit.svg";
import usePatchBankAccount from "./data/usePatchBankAccount";
import useDeleteBankAccount from "./data/useDeleteAccount";
import usePutAccountBatchUpdate from "./data/usePutAccountBatchUpdate";

const MobileBankAccounts = (props) => {
  const [id, setId] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsccode, setIfscCode] = useState("");
  const [accountType, setAccountType] = useState("");
  // const [isPrimary, setIsPrimary] = useState("");
  const [cancel, setCancel] = useState(false);

  const [getData, getError, getIsLoading, getBankAccounts] =
    useGetBankAccounts();
  const [postData, postError, postIsLoading, postBankAccounts] =
    usePostBankAccount();
  const [patchData, patchError, patchIsLoading, patchBankAccounts] =
    usePatchBankAccount();
  const [deleteData, deleteError, deleteIsLoading, deleteBankAccounts] =
    useDeleteBankAccount();
  const [putData, putError, putIsLoading, putBankAccounts] =
    usePutAccountBatchUpdate();

  const [accounts, setAccounts] = useState([
    {
      bankName: "",
      accountNumber: "",
      IFSCcode: "",
      accountType: "",
      isPrimary: "", 
    },
  ]);

  useEffect(() => {
    getBankAccounts();
  }, []);

  useEffect(() => {
    if (postData) {
      showSuccessToast("Bank Details Added");
    }
    if (patchData) {
      showSuccessToast("Bank Details Updated");
    }
    if (deleteData) {
      showSuccessToast("Bank Details Deleted");
    }
    if (putData) {
      showSuccessToast("Primary Bank Account Updated");
    }
  }, [postData, patchData, deleteData]);

  useEffect(() => {
    if (getData?.data) {
      const bankDetails = getData.data.results;
      if (bankDetails?.length > 0) {
        const formattedBankDetails = bankDetails.map((detail) => ({
          id: detail.id,
          accountNumber: detail.account_no,
          IFSCcode: detail.ifsc_code,
          bankName: detail.bank_name,
          accountType: detail.type,
          isPrimary: detail.is_primary,
        }));
        setAccounts(formattedBankDetails);
      }
    }
  }, [getData]);

  const createBankDetails = () => {
    let createData = {
      account_no: accountNumber,
      ifsc_code: ifsccode,
      bank_name: bankName,
      type: parseInt(accountType),
      is_primary: false,
    };
    console.log("create:", createData);
    postBankAccounts(createData);
    // const updatedAccounts = [...addAccountFields];
    // updatedAccounts[addAccountFields.indexOf(account)].isReadOnly = true;
    // setAccounts(updatedAccounts);
  };

  const handleAddBankDetail = (index) => {
    createBankDetails();
  };

  const handleChange = (index, field, value) => {
    const updatedAccounts = [...accounts];
    updatedAccounts[index][field] = value;
    setAccounts(updatedAccounts);
  };

  

  const updateBankDetails = (account) => {
    const { id, accountNumber, IFSCcode, bankName, accountType } = account;
    const updateData = {
      account_no: accountNumber,
      ifsc_code: IFSCcode,
      bank_name: bankName,
      type: parseInt(accountType),
    };
    console.log("updateData:", updateData);
    patchBankAccounts(id, updateData);
  };

  const handleApplyChanges = (index) => {
    const account = accounts[index];
    updateBankDetails(account);
  };

  const remove = (account) => {
    const { id } = account;
    deleteBankAccounts(id);
  };

  const handleRemoveAccount = (index) => {
    remove(accounts[index]);
  };

  const handlePrimaryChange = (index) => {
    // Set the clicked account as primary and others as non-primary
    const newAccounts = accounts.map((account, i) => ({
      ...account,
      isPrimary: i === index, // Only the clicked account will be true
    }));
    setAccounts(newAccounts);
  };

  const putAccount = () => {
    console.log("account:", accounts)
    let put = accounts.map((detail) => ({
      id: detail.id,
      account_no: detail.accountNumber,
      ifsc_code: detail.IFSCcode,
      bank_name: detail.bankName,
      type: parseInt(detail.accountType),
      is_primary: detail.isPrimary,
    }));

    console.log("put data:", put);
    putBankAccounts(put);
  };

  const saveAsPrimary = (index) => {
    putAccount();
  };

  return (
    <div className="px-[1rem] py-[0.5rem]">
      <div className="flex">
        <button>
          <img src={previousBtn} alt="" className="w-4 h-4" />
        </button>
        <h4 className="m-0 text-[#334155] text-[1.3rem] px-3 py-3 font-[600] rounded">
          Bank Accounts
        </h4>
      </div>

      {accounts.map((account, index) => (
        <div className=" mt-[0.5rem]" key={index}>
          <div className=" flex justify-between items-center p-[1rem]">
            <div className="flex gap-3 items-center justify-center">
              <img src={bankIcon} alt="bank icon" />
              <h4 className="flex flex-col m-0 text-[1.1rem] font-[600] text-[#334155]">
                {account.bankName}
                {account.isPrimary && (
                    <div className="flex gap-1 mt-[0.1rem]">
                    <img src={success} alt="success icon"/>
                  <span className=" text-[0.8rem] text-[#64748B]">
                    Primary Account for Incoming Funds
                  </span>
                  </div>
                )}
              </h4>
            </div>
            <CustomizableDrawer
              triggerElement={<img src={arrowRight} alt="arrow right icon" />}
              height="500"
              heading={"Bank Details"}
              content={({ handleClose }) => (
                <>
                  {/* <hr className="m-0" /> */}
                  <div className="my-[1rem]">
                    <div className="flex flex-col justify-center items-center mb-[2rem]">
                      <img
                        src={bankIcon}
                        alt="bank icon"
                        className="w-12 h-12 mb-[1rem]"
                      />
                      <h4 className="flex flex-col m-0 text-[1.3rem] font-[500] text-[#334155]">
                        {account.bankName}
                      </h4>
                    </div>

                    <div className="flex justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={bankIcon}
                          alt="bank icon"
                          className=" mb-[1rem]"
                        />

                        <h4 className="text-[0.9rem] text-[#334155] font-[500] ">
                          Primary Account for Incoming Funds
                        </h4>
                      </div>

                      <input
                        className="peer appearance-none h-6 w-6  border-2 rounded-md checked:bg-[#F9F5FF] checked:border-[#9065B4] checked:before:content-['✔'] checked:before:text-[#9065B4] before:block before:text-center before:text-[1rem] checked:before:relative checked:before:bottom-[6px] "
                        value={account.isPrimary}
                        type="checkbox"
                        onChange={() => handlePrimaryChange(index)}
                        checked={account.isPrimary == true}
                      />
                    </div>
                    {/* <hr className="m-0" /> */}

                    {/* Account Details */}
                    <div className="my-[1rem]">
                      <div className="flex justify-between mb-[1rem]">
                        <h4 className="text-[1rem] font-[500] text-[#334155]">
                          Account Details
                        </h4>
                        <CustomizableDrawer
                          triggerElement={
                            <img
                              src={edit}
                              alt="edit icon"
                              onClick={handleClose}
                            />
                          }
                          height="500"
                          heading={"Edit Bank Details"}
                          content={({ handleClose }) => (
                            <>
                              {/* <hr className="m-0" /> */}
                              <div className="my-[1rem]">
                                <div className="input-container mb-[1rem]">
                                  <h6 className="input-label ">Bank Name</h6>
                                  <input
                                    className="input-box  text-[1rem] "
                                    value={account.bankName}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "bankName",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="input-container mb-[1rem]">
                                  <h6 className="input-label">
                                    Account Number
                                  </h6>
                                  <input
                                    className="input-box  text-[1rem]"
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "accountNumber",
                                        e.target.value
                                      )
                                    }
                                    value={account.accountNumber}
                                  />
                                </div>
                                <div className="input-container mb-[1rem]">
                                  <h6 className="input-label">IFSC Code</h6>
                                  <input
                                    className="input-box  text-[1rem]"
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "IFSCcode",
                                        e.target.value
                                      )
                                    }
                                    value={account.IFSCcode}
                                  />
                                </div>
                                <div className="input-container mb-[1rem]">
                                  <h6 className="input-label">Account Type</h6>
                                  <select
                                    className="input-box  text-[1rem]"
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "accountType",
                                        e.target.value
                                      )
                                    }
                                    value={account.accountType}
                                  >
                                    <option value="">
                                      Choose account type
                                    </option>
                                    <option value={1}>Savings Account</option>
                                    <option value={2}>Current Account</option>
                                  </select>
                                </div>
                                <CustomizableDrawer
                                  triggerElement={
                                    <button
                                      onClick={handleClose}
                                      className="border-1 border-[#FEE2E2] mb-[1rem] w-full bg-[#FEF2F2] text-[#DC2626] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                                    >
                                      Remove Account
                                    </button>
                                  }
                                  content={({ handleClose }) => (
                                    <>
                                      <div className="my-[1rem]">
                                        <h4 className="flex justify-center text-center text-[1.2rem] text-[#334155] font-[500] ">
                                        Are you Sure! <br></br>You're about to remove 
                                        This Bank Account?
                                        </h4>
                                      </div>
                                      <button
                                        onClick={() => {
                                          handleRemoveAccount(index);
                                          handleClose();
                                        }}
                                        className="border-1 border-[#FEE2E2]  my-[1rem] w-full bg-[#FEF2F2] text-[#DC2626] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                                      >
                                        Yes, Remove Account
                                      </button>
                                      <button
                                        onClick={handleClose}
                                        className="border bg-[#F1F5F9] w-full text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                                      >
                                        Discard Changes
                                      </button>
                                    </>
                                  )}
                                />
                                
                                <Button
                                  onClick={() => {
                                    handleApplyChanges(index);
                                    handleClose();
                                  }}
                                  
                                  name="Save Details"
                                  px={"px-[1rem]"}
                                  py={"py-[0.5rem]"}
                                />
                                <button
                                  onClick={handleClose}
                                  className="mt-[1rem] border bg-[#F1F5F9] w-full text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          )}
                        />
                      </div>
                      <div className="flex justify-between mb-[0.5rem]">
                        <h4 className="text-[1rem] font-[400] text-[#64748B]">
                          A/c Number
                        </h4>
                        <h4 className="text-[1.1rem] font-[400] text-[#334155]">
                          {account.accountNumber}
                        </h4>
                      </div>
                      <div className="flex justify-between">
                        <h4 className="text-[1rem] font-[400] text-[#64748B]">
                          IFSC Code
                        </h4>
                        <h4 className="text-[1.1rem] font-[400] text-[#334155]">
                          {account.IFSCcode}
                        </h4>
                      </div>
                      <div className="flex justify-between">
                        <h4 className="text-[1rem] font-[400] text-[#64748B]">
                          Bank Type
                        </h4>
                        <h4 className="text-[1.1rem] font-[400] text-[#334155]">
                          {account.accountType == 1
                            ? "Savings Bank"
                            : "Current Bank"}
                        </h4>
                      </div>
                    </div>
                    {/* Bank Details buttons */}
                    <Button
                      onClick={() => {saveAsPrimary();
                        handleClose();
                      }}
                      name="Save Changes"
                      px={"px-[1rem]"}
                      py={"py-[0.5rem]"}
                    />
                    <button
                      onClick={handleClose}
                      className="mt-[1rem] border bg-[#F1F5F9] w-full text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1.1rem]"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            />
          </div>
          <hr className="m-0" />
        </div>
      ))}

      {/* add accounts */}
      <CustomizableDrawer
        triggerElement={
          <button className="text-[#9065B4] w-full my-[2rem] font-[500] text-[1rem] bg-[#EAE1F3] rounded flex justify-center items-center py-2 px-2">
            + Add New Account
          </button>
        }
        height="500"
        heading={"Add New Bank"}
        content={({ handleClose }) => (
          <>
            <hr className="m-0" />
            <div className="my-[1rem]">
              <div className="input-container mb-[1rem]">
                <h6 className="input-label ">Bank Name</h6>
                <input
                placeholder="Enter Bank Name"
                  className="input-box bg-[#F8FAFC] "
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
              <div className="input-container mb-[1rem]">
                <h6 className="input-label">Account Number</h6>
                <input
                placeholder="Add your Account No."
                  className="input-box bg-[#F8FAFC] "
                  onChange={(e) => setAccountNumber(e.target.value)}
                  value={accountNumber}
                />
              </div>
              <div className="input-container mb-[1rem]">
                <h6 className="input-label">IFSC Code</h6>
                <input
                  placeholder="Enter Your IFSC Code"
                  className="input-box bg-[#F8FAFC] "
                  onChange={(e) => setIfscCode(e.target.value)}
                  value={ifsccode}
                />
              </div>
              <div className="input-container mb-[1rem]">
                <h6 className="input-label">Account Type</h6>
                <select
                  className="input-box bg-[#F8FAFC]"
                  onChange={(e) => setAccountType(e.target.value)}
                  value={accountType}
                >
                  <option value="">Select Account Type</option>
                  <option value={1}>Savings Account</option>
                  <option value={2}>Current Account</option>
                </select>
              </div>
              <Button
                onClick={() => {
                  handleAddBankDetail();
                  handleClose();
                }}
                name="Add Account"
                px={"px-[1rem]"}
                py={"py-[0.5rem]"}
              />

              <button
                onClick={handleClose}
                className="mt-[1rem] border bg-[#F1F5F9] w-full text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1.1rem]"
              >
                Cancel
              </button>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default MobileBankAccounts;
