import useAPICall from "hooks/useAPICall";
import { POST_REGISTRAION_API_URL } from "constants/apis";

const usePostRegistration = () => {
  const [registrationData, registrationError, isLoading, callPostRegistration, setSuccessRegistrationData, setRegistrationError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setRegistrationError(en.something_went_wrong);
    setSuccessRegistrationData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "created",
      callBack: (res) => {
        const registrationData = res;
        if (registrationData && typeof registrationData === "object") {
          setSuccessRegistrationData(registrationData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postRegistration = (body) => {
    const url = POST_REGISTRAION_API_URL;
    const config = {};
    // console.log(body)
    callPostRegistration({
      url,
      method: "POST",
      statusObj,
      config,
      defaultFallback,
      body
    });
  };
  return [registrationData, registrationError, isLoading, postRegistration, setSuccessRegistrationData, setRegistrationError];
};

export default usePostRegistration;
