import React, { useState, useContext, useEffect } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import "App.css";
import Input from "components/input/Input";
import GoogleLogin from "containers/LoginAndSignUp/GoogleLogin";
import { myContext, orderPlacedSuccessModalContext } from "App";
import GoogleLoginex from "containers/LoginAndSignUp/GoogleLogin";
import { loaderContext } from "App";
import usePostLogin from "containers/services/Data/usePostLogin";
import usePostWorkOrder from "containers/privateRoutes/Dashboard/data/usePostWorkOrder";
// import NormalRegistrationSheet from "./NormalRegistrationSheet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MobileForgotPswd from "./MobileForgotPswd";
import MobileSheetRegistration from "./MobileSheetRegistration";
import MobileDrawer from "./MobileDrawer";

const MobileLogin = (props) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  }; 
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const { loading, setLoading } = useContext(loaderContext);
  const { orderPlacedSuccessModal, setOrderPlacedSuccessModal } = useContext(
    orderPlacedSuccessModalContext
  );
  const [data, error, isLoading, postLogin, setSuccessData, setError] =
    usePostLogin();
  const [loginClicked, setLoginClicked] = useState(false);
  if (isLoading) {
    setLoading(true);
  }
  const [submitClicked, setSubmitClicked] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState();
  const [borderColor, setBorderColor] = useState("");
  const navigate = useNavigate();

  const validateInputs = () => {
    setBorderColor(""); 
    setErr(""); 

    if (!mobileNumber) {
      setErr("Mobile Number/ Email is required.");
      setBorderColor("border-red-500");
      return false;
    }

    if (!password) {
      setErr("Password is required.");
      setBorderColor("border-red-500");
      return false;
    }

    return true;
  };


  useEffect(() => {
    if (submitClicked) {
      if (validateInputs()) {
        postLogin({ email_or_mobile: mobileNumber, password: password });
      }
      setSubmitClicked(false); 
    }
  }, [submitClicked]);

  useEffect(() => {
    if (error) {
      const errorMessage = error.err.response?.data?.error?.errors[0]?.detail || "An error occurred";
      setErr(errorMessage);
      console.log(errorMessage);
      setBorderColor("border-red-500");
    }
  }, [error]);

  var token;
  var expiryDate;
  var user;

  const location = useLocation();
  
  useEffect(() => {
    if (data) {
      if (data.status_code === 200) {
        token = data.data.token;
        expiryDate = data.data.expiry;
        user = data.data.user;
        localStorage.setItem("token", token);
        localStorage.setItem("expiry", expiryDate);
        localStorage.setItem("user", JSON.stringify(user));
        setIsLoggedIn(true);
        setLoading(false);
        onClose();
        if(location.pathname == "/Incometax"){
          navigate("/income-tax-profile")
        }else{
          navigate("/dashboard");
        }
      }
    } else {
      // console.log(err)
    }
  }, [data]);


  return (
    <>
      <div className="">
        <h2 className=" font-[700] leading-[2.1rem] text-[1.4rem]">
          Welcome back
        </h2>
        <p className=" text-gray-500  mb-4 font-[400] text-[1rem] leading-[1.5rem]">
          Welcome back! Please enter your details.
        </p>
        <Input
          border={borderColor}
          label={"Mobile Number"}
          placeholder={"Mobile Number"}
          value={mobileNumber}
          setValue={setMobileNumber}
        />
        <Input
          border={borderColor}
          label={"Password"}
          placeholder={"Enter your password"}
          value={password}
          type={"password"}
          setValue={setPassword}
        />
        <div className="flex justify-between my-2 items-center">
          <div>
            {" "}
            <input type={"checkbox"} /> Remember me
          </div>
          <p className=" text-purple-500 m-0">
            <span
              className="hover:cursor-pointer text-purple-500"
              onClick={() => {
                setOpen(false);
              }} 
            > 
              <MobileDrawer
                serviceData={props}
                element={<p className=" text-[#9065B4]">Forgot password</p>}
              />
            </span>
          </p>
        </div>
        <br />
        <p className="text-center text-red-600 m-0">{err}</p>
        <button
          onClick={() => {
            setSubmitClicked(true);  
          }}
          className=" purpleBtn p-2 rounded  w-[100%] text-white"
        >
          Sign in
        </button>

        <GoogleLoginex />

        <p className=" text-gray-400 flex justify-center text-center">
          No account? &nbsp;
          <span
            onClick={() => {
              setOpen(false);
            }}
          >
            <MobileSheetRegistration
              element={<p className=" text-[#9065B4]">Create one</p>}
            />
          </span>
        </p>
      </div>
    </>
  );
};
export default MobileLogin;
