import React from "react";
import RegistrationTitle from "./RegistrationTitle";
import ServiceProvided from "./ServiceProvided";
import RegistrationStep from "./RegistrationStep";
import RegistrationReview from "./RegistrationReview";
import RegistrationDetails from "./RegistrationDetails";
import "./registration.css";

function Registration() {
  return (
    <>
      <RegistrationTitle />
      <ServiceProvided />
      <RegistrationStep />
      <RegistrationReview />
      <RegistrationDetails />
    </>
  );
}

export default Registration;
