import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button/BtnOrange";
import Clock from "assets/images/incomeTax/Clock.svg";
import download from "assets/images/incomeTax/download.svg";
import info from "assets/images/incomeTax/info.svg";
import dots from "assets/images/incomeTax/3dots.svg";
import x from "assets/images/incomeTax/x2.svg";
import {
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";
import useGetTaxSummary from "../Data/useGetTaxSummary";
import { ProfileContext } from "containers/privateRoutes/Profile/Layout";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";
import CustomizableDrawer from "containers/privateRoutes/Reports/Drawer";
import useGetTaxSummaryPdf from "../Data/useGetTaxSummaryPdf";
import InputField from "components/input/InputField";

const TaxSummary = ({isDisabled}) => {
  const navigate = useNavigate();
  const { income_tax_return_id } = useParams();
  const [summaryData, setSummaryData] = useState({});
  const [recommendRegime, setRecommendRegime] = useState("old");
  const [regimeSuggestion, setRegimeSuggestion] = useState("old");
  const [showRecommended, setShowRecommended] = useState(true);
  const { returnYear } = useContext(ProfileContext);

  const [getData, getError, getIsLoading, getTaxSummary] = useGetTaxSummary();
  const [getPdf, getPdfError, getIsLoadingPdf, getTaxSummaryPdf] = useGetTaxSummaryPdf();

  useEffect(() => {
    getTaxSummary(income_tax_return_id);
  }, []);

  useEffect(() => {
    if (getData?.data) {
      setSummaryData(getData.data);
      if (
        getData?.data?.old_regime?.tax_payable <=
        getData?.data?.new_regime?.tax_payable
      ) {
        setRecommendRegime("old");
        setRegimeSuggestion("old");
      } else {
        setRecommendRegime("new");
        setRegimeSuggestion("new");
      }
    }
  }, [getData]);

  useEffect(()=>{
    if(getPdf){
      const blob= new Blob([getPdf], { type: 'application/pdf'});
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl)
    }
  },[getPdf])

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };
 
  const handleSaveAndNext = () => {
    navigate(
      USER_INCOME_SUMMARY_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      ).replace(":type", "computation")
    );
  };

  const switchRegime = () => {
    if (recommendRegime === "old") {
      setRecommendRegime("new");
    } else {
      setRecommendRegime("old");
    }
    setShowRecommended(!showRecommended);
  };

  const handlePrevious = () => {
    navigate(
      INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  }

  const downloadPdf= ()=>{
    getTaxSummaryPdf(income_tax_return_id)
  }

  const { old_regime, new_regime } = summaryData;

  return (
    <div className="relative">
       {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
            <div className="flex gap-2 md:hidden">
        <button onClick={handlePrevious}>
          <img src={previousBtn} alt="" className="w-4 h-4 mb-2" />
        </button>
        <h4 className=" text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
          Summary
        </h4> 
      </div>
    <div className="bg-white border border-[#E2E8F0] rounded p-[0.7rem] md:p-[1.3rem] mb-[6rem] relative">
      <h4 className="font-[500] text-[1.1rem] text-[#334155] mb-2">
        Tax Summary&nbsp;&nbsp;
        {returnYear}
      </h4>
      <div className="flex flex-col items-center mb-4 md:mt-[1rem]">
        <div className="md:flex w-full items-center gap-4 justify-start hidden">
          <div className="text-[#64748B] flex items-center gap-1 cursor-pointer">
            <img src={Clock} alt="Clock Icon" />
            <h4 className="text-[1rem] font-[500] m-0">Compare Past Year</h4>
          </div>
          {/* <div className="text-[#64748B] flex items-center gap-1 cursor-pointer" onClick={downloadPdf}>
            <img src={download} alt="Download Icon" />
            <h4 className="text-[1rem] font-[500] m-0">Download Computation</h4>
          </div> */}
          <InputField
            type="file"
            name="summaryFile"
            downloadHandler={downloadPdf}
            fileDownloadForLink={true}
            fileDownload={true}
            imgSrc={download}  // Pass different image for Component 1
        />
        </div>
        <div className="md:hidden top-0 right-0 p-2 absolute">
          <CustomizableDrawer
            triggerElement={<img src={dots} alt="dotsPic" />}
            heading={"Category"}
            content={
              <>
                <hr className="w-full border-[2px] border-[#E2E8F0]" />
                <div className="flex justify-center items-center cursor-pointer gap-1 border border-[#E2E8F0] w-full p-2 rounded-md mb-2">
                  <img src={Clock} alt="clock" />
                  <h4 className="text-[#64748B] text-[1.1rem] m-0">
                    Compare Past Year
                  </h4>
                </div>
                <div className="flex justify-center items-center cursor-pointer gap-1 border border-[#E2E8F0] w-full p-2 rounded-md mb-2" onClick={downloadPdf}>
                  <img src={download} alt="download" />
                  <h4 className="text-[#64748B] text-[1.1rem] m-0">
                    Download Summary
                  </h4>
                </div>
                <div className="flex justify-center items-center cursor-pointer gap-1  border border-[#E2E8F0] w-full p-2 rounded-md mb-2">
                  <img src={Clock} alt="clock" />
                  <h4 className="text-[#64748B] text-[1.1rem] m-0">
                    Detailed View
                  </h4>
                </div>
              </>
            }
            placement="bottom"
          />
        </div>
        <table className="col-span-3 min-w-full  bg-[#F8FAFC] mt-1 border-separate border-spacing-0">
          <thead>
            <tr className="">
              <th className="bg-[#FFFFFF]"></th>
              {showRecommended && (
                <>
                  <th
                    className={`${
                      recommendRegime === "old"
                        ? "bg-[#F3EFF8] text-[#9065B4] text-[0.8rem] text-center !border !border-l-[#9065B4] !border-t-[#9065B4] !border-r-[#9065B4] !border-b-0 md:p-2 p-[0.3rem] rounded-tl-md rounded-tr-md"
                        : "bg-[#FFFFFF]"
                    }`}
                  >
                    <span
                      className={`${recommendRegime === "old" ? "" : "hidden"}`}
                    >
                      Recommended
                    </span>
                  </th>
                  <th
                    className={`${
                      recommendRegime === "new"
                        ? "bg-[#F3EFF8] text-[#9065B4] text-[0.8rem] text-center !border !border-l-[#9065B4] !border-t-[#9065B4] !border-r-[#9065B4] !border-b-0 md:p-2 p-[0.3rem] rounded-tl-md rounded-tr-md"
                        : "bg-[#FFFFFF]"
                    }`}
                  >
                    <span
                      className={`${recommendRegime === "new" ? "" : "hidden"}`}
                    >
                      Recommended
                    </span>
                  </th>
                </>
              )}
            </tr>
            <tr className="">
              <th className="md:w-[47rem] md:p-2 p-[0.3rem] !border !border-l-[#94A3B8] !border-t-[#94A3B8] !border-r-0 !border-b-0 rounded-tl-md"></th>
              <th
                className={`md:p-2 p-[0.3rem] text-center text-[#334155] md:w-[8.7rem] ${
                  recommendRegime === "old"
                    ? "bg-[#F3EFF8] border-l border-r border-t border-[#9065B4]"
                    : "bg-[#F1F5F9] !border !border-l-0 !border-t-[#94A3B8] !border-r-0 !border-b-0"
                }`}
              >
                Old Regime
              </th>
              <th
                className={`md:p-2 p-[0.3rem] bg-[#F1F5F9] text-center text-[#334155]  md:w-[8.7rem] ${
                  recommendRegime === "new"
                    ? "bg-[#F3EFF8] border-l border-r border-t border-[#9065B4]"
                    : "bg-[#F1F5F9] !border !border-l-0 !border-t-[#94A3B8] !border-r-[#94A3B8] !border-b-0 rounded-tr-md"
                }`}
              >
                New Regime
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] text-[13px] font-[700] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Income Sources
              </td>
              <td
                className={`md:p-2 p-[0.3rem]  ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              ></td>
              <td
                className={`md:p-2 p-[0.3rem] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              ></td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                <span className="block sm:hidden">Salary</span>
                <span className="hidden sm:block">Salary Income</span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_income_from_salaries}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_income_from_salaries || 0}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                <span className="block sm:hidden">Rental</span>
                <span className="hidden sm:block">Rental Income</span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem]  text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_rental_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_rental_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Capital Gains
              </td>
              <td
                className={`md:p-2 p-[0.3rem]  text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_capital_gains_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_capital_gains_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                <span className="block sm:hidden">Business</span>
                <span className="hidden sm:block">Business Income</span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem]  text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_income_from_business}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_income_from_business}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                <span className="block sm:hidden">Agricultural & Exempt</span>
                <span className="hidden sm:block">
                  Agricultural & Exempt Income
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_exempt_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_exempt_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Other Income
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_of_other_incomes}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_of_other_incomes}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr className="bg-[#F3EFF8]">
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#334155] font-[600] text-[0.9rem] md:text-[1rem] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Gross Total Income
              </td>
              <td
                className={`md:p-2 p-[0.3rem]  text-center text-[#334155] text-[0.8rem] md:text-[1rem] font-[500] ${
                  recommendRegime === "old"
                    ? "border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.gross_total_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#334155]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#334155] text-[0.8rem] md:text-[1rem] font-[500] ${
                  recommendRegime === "new"
                    ? "border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.gross_total_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#334155]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-[47rem] md:p-2 p-[0.3rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Deductions
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_deduction_amount}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_deduction_amount}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr className="bg-[#F3EFF8]">
              <td className="md:p-2 p-[0.3rem] w-[47rem] text-[#334155] font-[600] text-[0.9rem] md:text-[1rem] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Total Income
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#334155] text-[0.8rem] md:text-[1rem] font-[500] ${
                  recommendRegime === "old"
                    ? " border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.total_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#334155]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#334155] text-[0.8rem] md:text-[1rem] font-[500] ${
                  recommendRegime === "new"
                    ? "border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.total_income}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#334155]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="md:p-2 p-[0.3rem] w-[47rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Tax on Total Income
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.tax_liability_at_normal_rates}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.tax_liability_at_normal_rates}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="md:p-2 p-[0.3rem] w-[47rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Taxes Paid
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.tds_or_tcs + old_regime?.self_assessment_tax + old_regime?.advance_tax}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.tds_or_tcs + new_regime?.self_assessment_tax + new_regime?.advance_tax}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr>
              <td className="md:p-2 p-[0.3rem] w-[47rem] text-[#64748B] !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-0">
                Interest and Penalties
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "old"
                    ? "bg-[#FAF8FC] border-l border-r  border-[#9065B4]"
                    : ""
                }`}
              >
                {old_regime?.interest_and_penalty}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#64748B] ${
                  recommendRegime === "new"
                    ? "bg-[#FAF8FC] border-l border-r border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-0"
                }`}
              >
                {new_regime?.interest_and_penalty}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#64748B]">
                  Rs.
                </span>
              </td>
            </tr>
            <tr className="bg-[#F3EFF8]">
              <td className="md:p-2 p-[0.3rem] w-[47rem] text-[#334155] font-[600] text-[0.9rem] md:text-[1rem] rounded-bl-lg !border !border-l-[#94A3B8] !border-t-0 !border-r-0 !border-b-[#94A3B8]">
                Tax Payable/Refund
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#334155] text-[0.8rem] md:text-[1rem] font-[500] ${
                  recommendRegime === "old"
                    ? "border-l border-r border-b  border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-0 !border-b-[#94A3B8]"
                }`}
              >
                {old_regime?.tax_payable}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#334155]">
                  Rs.
                </span>
              </td>
              <td
                className={`md:p-2 p-[0.3rem] text-center text-[#334155] text-[0.8rem] md:text-[1rem] font-[500] rounded-br-lg ${
                  recommendRegime === "new"
                    ? "border-l border-r border-b border-[#9065B4]"
                    : "!border !border-l-0 !border-t-0 !border-r-[#94A3B8] !border-b-[#94A3B8]"
                }`}
              >
                {new_regime?.tax_payable}
                <span className="bg-[#FFFFFF] ml-1 p-1 rounded-md text-[#334155]">
                  Rs.
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="md:flex md:justify-center md:items-center  mt-4 !border !border-[#166534] md:!border-0 rounded-lg  bg-[#F0FDF4] w-[100%] md:w-[70%] px-2 py-2 md:px-0 md:py-0">
          <p className="md:hidden text-[#166534] flex gap-1 ">
            <img src={info} alt="info" />
            {regimeSuggestion === "old"
              ? "Old Regime Recommended"
              : "New Regime Recommended"}
          </p>
          <p className="md:text-center  text-[12px] md:text-[14px] font-[500]  text-[#166534] md:px-3 md:py-2">
            Based on our analysis, Beyond Tax recommends choosing the&nbsp;
            <span className="!font-[700]">
              {regimeSuggestion === "old" ? "Old Regime" : "New Regime"}
            </span>
            &nbsp;tax option. This choice could save you&nbsp;
            <span className="!font-[700]">
              ₹
              {regimeSuggestion === "old"
                ? `${(
                    new_regime?.tax_payable -
                    old_regime?.tax_payable
                  ).toFixed(1)}`
                : `${(
                    old_regime?.tax_payable -
                    new_regime?.tax_payable
                  ).toFixed(1)}`}
            </span>
            &nbsp;compared to the&nbsp;
            <span>
              {regimeSuggestion === "old" ? "New Regime" : "Old Regime"}
            </span>
            .
          </p>
        </div>
      </div>
      <div className="flex flex-row-reverse gap-4">
        <Button
          px={"px-[1rem]"}
          py={"py-[0.5rem]"}
          //   name="Save and next"
          name="Proceed"
          disabled={isDisabled}
          onClick={handleSaveAndNext}
        />

        {/* <button
          onClick={handleGoBack}
          className="font-[500] md:px-[2%] px-[3%] py-[0.5%] rounded-md bg-[#E8EAED]"
        >
          <span className="block md:hidden">Back</span>
          <span className="hidden md:block">Back to tax Summary</span>
        </button> */}
        <button
          className="font-[500] text-[#334155] rounded-md bg-[#F1F5F9] px-[2%] py-[0.5%]"
          onClick={switchRegime}
        >
          Switch Regime
        </button>
      </div>
    </div>
    </div>
  );
};

export default TaxSummary;
