import React, { useState, useContext, useEffect } from "react";
import useGetUser from "containers/services/Data/useGetUserDetails";
import { myContext } from "App";
import notification from "assets/images/dashboard/notification.svg";
import { Link } from "react-router-dom";
import show from "assets/images/incomeTax/show.svg";
import know_networth from "assets/images/incomeTax/know-networth.png";

import useGetDueDate from "containers/privateRoutes/Dashboard/data/useGetDueDate";
import useGetUserBasic from "containers/privateRoutes/Dashboard/data/getUserbasicDetails";

function IncomeTaxBasicProfile() {
  const [name, setName] = useState("");
  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const [docImages, setDocImages] = useState([]);
  const [dueDateArr, setDueDateArr] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [showNetWorthDetails, setShowNetWorthDetails] = useState(false);

  const [
    dueDateData,
    dueDateError,
    dueDateIsLoading,
    getDueDate,
    setSuccessDueDateData,
    setDueDateError,
  ] = useGetDueDate();

  const [firstLetter, setFirstLetter] = useState("");
  const [profilePic, setProfilePic] = useState("");

  const [UserBasicData, userBasicError, userBasicIsLoading, getUserBasic] =
    useGetUserBasic();
  const [showPP, setShopPP] = useState(false);
  const [date, setDate] = useState(new Date());
  const [filteredDates, setFilteredDates] = useState([]);

  const [UserData, userError, userIsLoading, getUser] = useGetUser();

  useEffect(() => {
    // getUserBasic();
    getDueDate();
  }, [isLoggedIn]);

  useEffect(() => {
    if (dueDateData) {
      const flattenedData = [];
      Object.keys(dueDateData.data).forEach((month) => {
        dueDateData.data[month].forEach((item) => {
          flattenedData.push(item);
        });
      });
      setDueDateArr(flattenedData);
    }
  }, [UserBasicData, dueDateData]);

  useEffect(() => {
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const filtered = dueDateArr.filter((data) => {
      const eventDate = new Date(data.date);
      return (
        eventDate.getMonth() === currentMonth &&
        eventDate.getFullYear() === currentYear
      );
    });
    setFilteredDates(filtered);
  }, [date, dueDateArr]);

  return (
    <>
      {dueDateIsLoading || userBasicIsLoading ? (
        <div className="bg-gray-100">
          <div role="status" className="max-w-full animate-pulse pt-20 pb-4">
            <div className="h-screen w-full bg-[#f5eef5] rounded dark:bg-[#FFFBFF] mb-4"></div>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="bg-purple-50 h-screen fixed py-[1%]">
          <div className="px-[4%] pt-[4rem]">
            <h5 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem] m-0">
              Net Worth
            </h5>
            <p className="font-[400] text-[.7rem] leading-[1rem] text-[#667085]">
              Income reported in ITRs till now
            </p>
            {showNetWorthDetails ? (
              <>
                <h4 className="font-[500] text-[1.3rem] leading-[1.4rem] flex justify-center gap-2 text-[#344054] text-center">
                  ₹ &middot;&middot;&middot;&middot; <img src={show} alt="" />
                </h4>
                <p className="font-[500] text-[1.3rem] leading-[1.5rem] text-[#344054]">
                  My Assets
                </p>
                <div className="flex justify-between">
                  <h4 className="font-[500] text-[1.3rem] leading-[1.4rem] flex gap-2 text-[#344054] text-center">
                    ₹ &middot;&middot;&middot;&middot;{" "}
                  </h4>
                  <button className="px-[2rem] py-[.4rem] bg-white rounded">
                    <p className="text-[#7C549C] font-[500] text-[.9rem] leading-[1.3rem] m-0">
                      ADD
                    </p>
                  </button>
                </div>
                <p className="font-[500] text-[1.3rem] leading-[1.5rem] text-[#344054]">
                  My Liabilities
                </p>
                <div className="flex justify-between">
                  <h4 className="font-[500] text-[1.3rem] leading-[1.4rem] flex gap-2 text-[#344054] text-center">
                    ₹ &middot;&middot;&middot;&middot;{" "}
                  </h4>
                  <button className="px-[2rem] py-[.4rem] bg-white rounded">
                    <p className="text-[#7C549C] font-[500] text-[.9rem] leading-[1.3rem] m-0">
                      ADD
                    </p>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="bg-white mt-4 p-[2rem] rounded-md text-center flex flex-col items-center">
                  <img
                    src={know_networth}
                    alt="know your networth "
                    className="mb-3"
                  />
                  <h4 className="font-[600] text-[1.1rem] text-[#334155]">
                    Know your Networth
                  </h4>
                  <p className="text-[#64748B] font-[400] text-[0.9rem] mb-[1rem]">
                    Enter assets and loans to calculate your net worth.
                  </p>

                  <button
                    className="px-3 py-2 rounded text-[#334155] bg-[#F1F5F9] border-1 border-[#E2E8F0] font-[500] text-[1rem]"
                    onClick={() => setShowNetWorthDetails(true)}
                  >
                    Calculate
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="p-[4%]">
            <h4 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem]">
              Upcoming Due Dates
            </h4>
            <div className="bg-[#E4E1EA] h-[14.5rem] p-3 overflow-y-scroll rounded-xl">
              {filteredDates.length > 0 ? (
                filteredDates.map((data, index) => (
                  <div key={index}>
                    <span className="bg-[#D3C1E1] px-2 py-1 rounded-md text-[#344054] font-[500] text-[.8rem] leading-[.9rem]">
                      {data.date}
                    </span>
                    <ul>
                      <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                        {data.compliance_activity}
                      </li>
                      <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                        {data.penalty_fine_interest}
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <p>No important dates for this month.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IncomeTaxBasicProfile;
