import React, { useEffect, useRef, useState } from "react";
import Glide from "@glidejs/glide";
import { Link } from "react-router-dom";
import { GET_SERVICE_URL } from "constants/routes";
import prev from "assets/images/home/chev.svg";
import next from "assets/images/home/next.svg";

const ServicesSlider = () => {
  const glideRef = useRef(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Initialize Glide.js when the component mounts
    const glide = new Glide(glideRef.current, {
      //   type: 'slider', // Type of the slider (carousel, slider, carousel-3d, or slider-3d)
      perView: 3.3, // Number of slides to show per view
      //   focusAt: 'center', // Set where the slider should focus
      gap: 16, // Space between slides (in pixels)
      breakpoints: {
        1280: {
          perView: 3.3,
        },
        768: {
          perView: 2,
        },
        576: {
          perView: 1,
        },
      },
    });

    glide.mount(); // Mount the Glide.js instance

    // Clean up the Glide.js instance when the component unmounts
    return () => {
      glide.destroy();
    };
  }, []); // Run this effect only once when the component mounts
  return (
    <div className="glide " ref={glideRef}>
      <div className=" flex justify-between items-center">
        <h5 className=" font-[500] text-[1.4rem] px-[2%] leading-[1.6rem] text-gray-400 m-0 ">
          Supporting services
        </h5>
        <div
          className="glide__arrows flex flex-row-reverse px-[2%] gap-2"
          data-glide-el="controls"
        >
          <button
            onClick={() => {
              setCount(1);
            }}
            className="glide__arrow glide__arrow--right "
            data-glide-dir=">"
          >
            <img src={next} />
          </button>
          <button
            onClick={() => {
              setCount(0);
            }}
            className="glide__arrow glide__arrow--left "
            data-glide-dir="<"
          >
            <img src={prev} />
          </button>
        </div>
      </div>
      <hr className=" w-full" />
      <div className="glide__track p-[2%]" data-glide-el="track">
        <ul className="glide__slides">
          <i className="glide__slide">
            {" "}
            <div className=" flex flex-col p-2 justify-between h-40 rounded-md bg-[#FAF8FC]">
              <h4 className="not-italic font-[600] text-[#667085] text-[1.3rem] leading-[1.6rem]">
                {" "}
                E-Verification{" "}
              </h4>
              <button className=" text-[#9065B4] bg-[#F3EFF8] rounded-md ">
                <span className="font-[600] text-[1rem] leading-[1.5rem]">
                  Raise Request
                </span>{" "}
              </button>
            </div>
          </i>
          <i className="glide__slide">
            {" "}
            <div className=" flex flex-col p-2 justify-between h-40 rounded-md bg-[#FAF8FC]">
              <h4 className="not-italic font-[600] text-[#667085] text-[1.3rem] leading-[1.6rem]">
                Refund Reissue
              </h4>
              <button className=" text-[#9065B4] bg-[#F3EFF8] rounded-md ">
                <span className="font-[600] text-[1rem] leading-[1.5rem]">
                  Raise Request
                </span>{" "}
              </button>
            </div>
          </i>{" "}
          <i className="glide__slide">
            {" "}
            <div className=" flex flex-col p-2 justify-between h-40 rounded-md bg-[#FAF8FC]">
              <h4 className="not-italic font-[600] text-[#667085] text-[1.3rem] leading-[1.6rem]">
                {" "}
                Tax Planning
              </h4>
              <button className=" text-[#9065B4] bg-[#F3EFF8] rounded-md ">
                <span className="font-[600] text-[1rem] leading-[1.5rem]">
                  Raise Request
                </span>{" "}
              </button>
            </div>
          </i>
          <i className="glide__slide">
            {" "}
            <div className=" flex flex-col p-2 justify-between h-40 rounded-md bg-[#FAF8FC]">
              <h4 className="not-italic font-[600] text-[#667085] text-[1.3rem] leading-[1.6rem]">
                {" "}
                Notice Management{" "}
              </h4>
              <button className=" text-[#9065B4] bg-[#F3EFF8] rounded-md ">
                <span className="font-[600] text-[1rem] leading-[1.5rem]">
                  Raise Request
                </span>{" "}
              </button>
            </div>
          </i>
          <i className="glide__slide">
            {" "}
            <div className=" flex flex-col p-2 justify-between h-40 rounded-md bg-[#FAF8FC]">
              <h4 className="not-italic font-[600] text-[#667085] text-[1.3rem] leading-[1.6rem]">
                {" "}
                Reports{" "}
              </h4>
              <button className=" text-[#9065B4] bg-[#F3EFF8] rounded-md ">
                <span className="font-[600] text-[1rem] leading-[1.5rem]">
                  Raise Request
                </span>{" "}
              </button>
            </div>
          </i>
          {/* Add more slides as needed */}
        </ul>
      </div>
    </div>
  );
};

export default ServicesSlider;
