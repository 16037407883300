import * as React from "react";
import Dialog from "@mui/material/Dialog";

// images
import SuccessIcon from "assets/images/incomeTax/SuccessIcon.png";
import { useNavigate } from "react-router-dom";

export default function DataImportSuccess(props) {
  const navigate = useNavigate();
  
  const handleClose = () => {
    navigate('/income-tax');
    console.log("navigate('/income-tax')")
  };

  return (
    
      <Dialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: { width: "40%", height: "80%" },
          component: "form",
        }}
      >
        <div className="px-28 py-24">
          <div className="flex justify-center items-center">
            <img src={SuccessIcon} alt="otp icon" className="mb-2 w-16 h-16" />
          </div>
          <h2 className="text-center font-[700] leading-[2.1rem] text-[1.4rem] flex ">
            Successfully your details have been imported
          </h2>
          <p className="text-center text-gray-500 font-[400] text-[0.9rem] leading-[1.5rem] flex ">
            Check your income tax profile to view your details
          </p>

          <button
            onClick={handleClose} 
            className="mt-2 purpleBtn p-2 rounded  w-[100%] text-white"
          >
            File returns and e-verify
          </button>
        </div>
      </Dialog>
    
  );
}
