import React from "react";
import IncomeTaxTitle from "./IncomeTaxTitle";
import MakeSpecial from "./MakeSpecial";
import IncomeTaxServicesFaq from "./IncomeTaxServicesFaq";
import IncomeTaxShortSteps from "./IncomeTaxShortSteps";
import TailoredTaxSolutions from "./IncomeTaxTailoredSolutions";
import IncomeTaxReviews from "./IncomeTaxReviews";

const IncomeTaxServices = () => {
 
  return (
    <div>
      <IncomeTaxTitle />
      <MakeSpecial/>
      <IncomeTaxShortSteps />
      <TailoredTaxSolutions />
      <IncomeTaxReviews />
      <IncomeTaxServicesFaq/>
    </div>
  );
};

export default IncomeTaxServices;
