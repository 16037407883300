import React from "react";
import step1 from "assets/images/incomeTax/step1.png";
import step2 from "assets/images/incomeTax/step2.png";
import step3 from "assets/images/incomeTax/step3.png";
import step4 from "assets/images/incomeTax/step4.png";
import step5 from "assets/images/incomeTax/step5.png";
import step6 from "assets/images/incomeTax/step6.png";

function IncomeTaxShortSteps() {
  return (
    <div className="px-[8%] py-[8%]">
      <h2 className="text-2xl font-[700] text-center text-[2rem] mb-10">
        Short Steps to <span className="bg-gradient-to-r from-[#7E0CDE]  via-[#D14588] to-[#E65757] text-transparent bg-clip-text">Easy Tax Filing</span>
      </h2>
      <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <StepCard
          stepNumber="STEP 01"
          title="Get Started"
          description="Log in or create an account to begin."
          icon={
            <img
              src={step1}
              alt="start"
              className=""
            />
          }
        />
        <StepCard
          stepNumber="STEP 02"
          title="Complete Profile"
          description="Fill in personal details and verify your PAN."
          icon={
            <img
              src={step2}
              alt="profile"
              className=""
            />
          }
        />
        <StepCard
          stepNumber="STEP 03"
          title="Upload Documents"
          description="Attach all necessary tax documents."
          icon={
            <img
              src={step3}
              alt="documents"
              className=""
            />
          }
        />
        <StepCard
          stepNumber="STEP 04"
          title="Compute"
          description="Review your tax summary and optimize your returns."
          icon={
            <img
              src={step4}
              alt="compute"
              className=""
            />
          }
        />
        <StepCard
          stepNumber="STEP 05"
          title="Review and Confirm"
          description="Our Reviewer will double-check your details and provide a final quote."
          icon={
            <img
              src={step5}
              alt="review"
              className=""
            />
          }
        />
        <StepCard
          stepNumber="STEP 06"
          title="File and Verify"
          description="Make the payment, submit your returns, and set reminders for future deadlines."
          icon={
            <img
              src={step6}
              alt="file"
              className=""
            />
          }
        />
      </div>
    </div>
  );
}

function StepCard({ stepNumber, title, description, icon }) {
  return (
    <div className="bg-[#F3EFF8] rounded-3xl shadow-md">
      <h3 className="mb-1 text-xl font-bold px-4 pt-4">{stepNumber}</h3>
      <div className="bg-white-body rounded-b-3xl mt-3 p-4 ">
        <div className="mb-2">{icon}</div>
        <h4 className="text-xl font-bold text-gray-800 mb-2 mt-4">{title}</h4>
        <p className="font-[400] text-[#9092A3]">{description}</p>
      </div>
    </div>
  );
}

export default IncomeTaxShortSteps;
