import useAPICall from "hooks/useAPICall";
import {GET_GOLD_DETAILS_URL, GET_INTELECTUAL_DETAILS_URL, GET_LOAN_DETAILS_URL} from "constants/apis";

const useGetIntellectualDetails = () => {
  const [data, error, isLoading, callGetIntellectualDetails, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getIntellectualDetails = (asset_id) => {
    const url = GET_INTELECTUAL_DETAILS_URL.replace(":asset_id", asset_id);
    callGetIntellectualDetails({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
    });
  };

  return [data, error, isLoading, getIntellectualDetails, setSuccessData, setError];
};

export default useGetIntellectualDetails;
 