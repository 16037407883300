import React from "react";
import removeLiability from "assets/images/liabilities/removeLiability.svg";
import closeIcon from "assets/images/reports/closebutton.png";
import closeIconRed from "assets/images/reports/x.svg";

const DiscardChangesDialog = ({ open, close, closeDiscardDialog }) => {
  return (
    <div>
      {open && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-65 flex justify-center items-center z-50 ">
          <div
            className={`max-w-lg bg-white relative p-6 rounded-2xl shadow-lg w-full max-h-[90vh] overflow-auto scrollbar-hide`}
          >
            <button
              className="group absolute top-[1.5rem] right-7 text-gray-600 hover:text-gray-800 p-2 hover:bg-[#FEF2F2] hover:rounded-full hover:p-2"
              onClick={closeDiscardDialog}
            >
              <img src={closeIcon} className="w-3 h-3 group-hover:hidden" alt=""/>
              <img
              alt=""
                src={closeIconRed}
                className="w-4 h-4 hidden group-hover:block "
              />
            </button>
            <h2 className="font-[600] text-xl text-[#334155]">Discard?</h2>
            <div className=" text-center flex flex-col items-center">
              <img
                src={removeLiability}
                alt="remove"
                className="w-[5rem] h-[5rem] mb-3"
              />
              <h4 className="text-[#DC2626] font-[600] text-[0.9rem]">
                Are you sure you want to Discard Changes ?
              </h4>

              <div className="flex gap-2 mt-[1rem]">
                <button
                  onClick={closeDiscardDialog}
                  className="text-[#334155] font-[600] text-[0.9rem] border py-1 px-3  rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={close}
                  className="text-[#DC2626] font-[600] text-[0.9rem] border-1 border-[#FEE2E2] bg-[#FEF2F2]  py-1 px-3 rounded-md"
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscardChangesDialog;
