import React from "react";
import BTImg from "assets/images/Serviceimages/image 19.png";

function Footer() {
  return (
    <>
      <div className="footer z-[4]">
        <div style={{ margin: "2%", margin: "0px" }}>
          <img src={BTImg} />
          <div className="md:flex md:gap-[3rem] md:flex-row flex-col">
            <h4>Become an agent</h4>
            <h4>Blog</h4>
          </div>
          <hr />
          <div className="footerLastDIv">
          <div className="flex gap-4 md:hidden">
              <p>Terms</p>
              <p>Privacy</p>
            </div>
            <p>© 2024 Beyondtax. All rights reserved.</p>
            <div className="md:flex md:gap-4 md:block hidden">
              <p>Terms</p>
              <p>Privacy</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
