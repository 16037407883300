import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import InputField from "components/input/InputField";
import upload from "assets/images/liabilities/upload.svg";
import deleteIcon from "assets/images/assets/delete.svg";
import usePostLandDetails from "../../data/usePostLandDetails";
import { useNavigate } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";
import { showSuccessToast } from "components/toaster/toastHelper";
import usePostBuildingDetails from "../../data/usePostBuildingDetails";
import "../../assets.css";
import deleteRedIcon from "assets/images/assets/redDelete.svg";
import datePicker from "assets/images/incomeTax/datePicker.png";

const AddNewLand = ({ triggerElement, estateType, assetId, setIsReload }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];
  const [surveryNo, setSurveryNo] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [landPhoto, setlandPhoto] = useState("");
  const [ownershipPercentage, setOwnershipPercentage] = useState("");
  const [stampDuty, setStampDuty] = useState("");
  const [registrationFee, setRegistrationFee] = useState("");
  const [brokerageFee, setBrokerageFee] = useState("");
  const [otherExpenses, setOtherExpenses] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [buildingType, setBuldingType] = useState("");
  const [flatNo, setFlatNo] = useState("");
  const [loanTaken, setloanTaken] = useState("");
  const [valuationReport, setValuationReport] = useState("");
  const [estimatedMarketValue, setEstimatedMarketValue] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [insuranceTaken, setInsuranceTaken] = useState("");
  const [saleDeed, setSaleDeed] = useState("");
  const [assetType, setAssetType] = useState("active");
  const [capitalExpense, setCapitalExpense] = useState(false);
  const [mandateExpense, setMandateExpense] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const [postLandData, postLandError, postLandIsLoading, postLandDetails] =
    usePostLandDetails();
  const [
    postBuildingData,
    postBuildingError,
    postBuildingIsLoading,
    postBuildingDetails,
  ] = usePostBuildingDetails();

  const toggleAssetType = (type) => {
    setAssetType(type);
  };
  const progressSteps = [
    {
      label: "Address",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: `${estateType == "Buildings" ? "Building" : "Land"} Value`,
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: `${estateType == "Buildings" ? "" : "Capital"} Expenses`,
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  const [capital, setCapital] = useState([
    {
      id: "",
      expenseType: "",
      amtPaid: "",
      expenseDate: "",
      uploadInvoice: "",
    },
  ]);

  const handleAddCapitalExpense = () => {
    setCapital([
      ...capital,
      {
        id: "",
        expenseType: "",
        amtPaid: "",
        expenseDate: "",
        uploadInvoice: "",
      },
    ]);
  };

  const [mandate, setMandate] = useState([
    {
      id_mandate: "",
      expense_Type: "",
      amt_Paid: "",
      expense_Date: "",
      uploadCertificate: "",
    },
  ]);

  const handleAddMandate = () => {
    setMandate([
      ...mandate,
      {
        id_mandate: "",
        expense_Type: "",
        amt_Paid: "",
        expense_Date: "",
        uploadCertificate: "",
      },
    ]);
  };

  const handleChangeMandate = (index, field, value) => {
    const new_Accounts = [...mandate];
    if (field === "uploadCertificate" && value instanceof File) {
      new_Accounts[index][field] = value;
    } else {
      new_Accounts[index][field] = value;
    }
    setMandate(new_Accounts);
  };

  const handleChange = (index, field, value) => {
    const newAccounts = [...capital];

    if (field === "uploadInvoice" && value instanceof File) {
      newAccounts[index][field] = value;
    } else {
      newAccounts[index][field] = value;
    }

    setCapital(newAccounts);
  };

  const resetState = () => {
    setSurveryNo("");
    setArea("");
    setCity("");
    setPincode("");
    setState("");
    setCountry("");
    setlandPhoto("");
    setAssetType("active");
    setOwnershipPercentage("");
    setStampDuty("");
    setRegistrationFee("");
    setBrokerageFee("");
    setOtherExpenses("");
    setTotalCost("");
    setloanTaken("");
    setCapitalExpense(false);
    setMandateExpense(false);
    setBuldingType("");
    setFlatNo("");
    setEstimatedMarketValue("");
    setCapital([
      {
        expenseType: "",
        amtPaid: "",
        expenseDate: "",
        uploadInvoice: "",
      },
    ]);
    setMandate([
      // ...mandate,
      {
        expense_Type: "",
        amt_Paid: "",
        expense_Date: "",
        uploadCertificate: "",
      },
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  };

  const removeCapitalExpense = (index) => {
    setCapital((prevCapital) => prevCapital.filter((_, i) => i !== index));
  };
  const removeMandateExpense = (index) => {
    setMandate((prevMandate) => prevMandate.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  useEffect(() => {
    if (postLandData) {
      showSuccessToast("Land Details posted");
      setIsClose(true);
      // setIsReload(true);
      navigate(ASSETS_TYPE_URL.replace(":type", "real-estate-land"), {
        state: { landId: assetId },
      });
    }
  }, [postLandData]);

  useEffect(() => {
    if (postBuildingData) {
      showSuccessToast("Building Details posted");
      setIsClose(true);
      // setIsReload(true);
      navigate(ASSETS_TYPE_URL.replace(":type", "real-estate-building"), {
        state: { buildingId: assetId },
      });
    }
  }, [postBuildingData]);

  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const errors = {};

    if (estateType === "Land") {
      if (!surveryNo) {
        errors.surveryNo = "Survey number is required.";
      } else if (isNaN(surveryNo) || !/^\d+$/.test(surveryNo)) {
        errors.surveryNo = "Survey no. must be a whole no.";
      }
    }

    if (!area.trim()) {
      errors.area = "Area is required.";
    } else if (!/^[A-Za-z\s]+$/.test(area.trim())) {
      errors.area = "Area can only contain letters.";
    }

    if (!city.trim()) {
      errors.city = "City is required.";
    } else if (!/^[A-Za-z\s]+$/.test(city.trim())) {
      errors.city = "City can only contain letters.";
    }

    if (!pincode) {
      errors.pincode = "Pincode is required.";
    } else if (!/^[1-9][0-9]{5}$/.test(pincode)) {
      errors.pincode =
        "Pincode must be a 6-digit number and cannot start with 0.";
    }

    if (!state.trim()) errors.state = "State is required.";
    if (!country.trim()) errors.country = "Country is required.";

    if (estateType === "Buildings") {
      if (!buildingType.trim()) {
        errors.buildingType = "Building type is required.";
      }
      if (!flatNo.trim()) {
        errors.flatNo = "Flat number is required.";
      } else if (!/^[A-Za-z0-9\s\-\/\.]+$/.test(flatNo.trim())) {
        errors.flatNo =
          "Flat number can only contain alphanumeric characters, spaces, hyphens, slashes, or periods.";
      } else if (flatNo.trim().length < 3 || flatNo.trim().length > 20) {
        errors.flatNo = "Flat number should be between 3 and 20 characters.";
      }
    }

    if (currentStep === 1) {
      if (!ownershipPercentage) {
        errors.ownershipPercentage = "Ownership percentage is required.";
      } else if (
        isNaN(ownershipPercentage) ||
        ownershipPercentage < 0 ||
        ownershipPercentage > 100
      ) {
        errors.ownershipPercentage =
          "Ownership percentage must be between 0 and 100.";
      }

      // if (!assetType.trim()) errors.assetType = "Asset Type is required.";
      // if (!loanTaken.trim()) errors.loanTaken = "Loan taken is required.";

      if (estateType === "Buildings") {
        // if (!insuranceTaken.trim())
        //   errors.insuranceTaken = "Insurance taken is required.";
        // if (!purchaseDate) errors.purchaseDate = "Purchase Date is required.";
        // if (!valuationReport)
        //   errors.valuationReport = "Valuation report is required.";
        // if (!saleDeed) errors.saleDeed = "Sale Deed report is required.";
        // if (!estimatedMarketValue) {
        //   errors.estimatedMarketValue = "Market value is required";
        // } else if (
        //   isNaN(estimatedMarketValue) ||
        //   !/^\d+$/.test(estimatedMarketValue)
        // ) {
        //   errors.estimatedMarketValue = "Market value must be a whole number.";
        // }
      }

      // [
      //   "stampDuty",
      //   "registrationFee",
      //   "brokerageFee",
      //   "otherExpenses",
      //   "totalCost",
      // ].forEach((field) => {
      //   const value = eval(field);

      //   // Convert camel case to spaced words and capitalize the first letter
      //   const formattedFieldName = field
      //     .replace(/([A-Z])/g, " $1")
      //     .replace(/^./, (str) => str.toUpperCase());

      //   if (!value) {
      //     errors[field] = `${formattedFieldName} is required.`;
      //   } else if (isNaN(value) || value <= 0 || value % 1 !== 0) {
      //     errors[field] = `${formattedFieldName} must be a positive whole no.`;
      //   }
      // });
    }

    if (currentStep === 2) {
      // capital.forEach((expense, index) => {
      //   const expenseErrors = {};

      //   if (!expense.expenseType.trim()) {
      //     expenseErrors.expenseType = "Expense type is required.";
      //   }

      //   if (!expense.amtPaid) {
      //     expenseErrors.amtPaid = "Amount paid is required.";
      //   } else if (
      //     isNaN(expense.amtPaid) ||
      //     expense.amtPaid <= 0 ||
      //     expense.amtPaid % 1 !== 0
      //   ) {
      //     expenseErrors.amtPaid =
      //       "Amount paid must be a positive whole number.";
      //   }

      //   if (!expense.expenseDate) {
      //     expenseErrors.expenseDate = "Expense date is required.";
      //   } else {
      //     const datePattern = /^\d{4}-\d{2}-\d{2}$/;
      //     if (!datePattern.test(expense.expenseDate)) {
      //       expenseErrors.expenseDate =
      //         "Expense date must be in YYYY-MM-DD format.";
      //     }
      //   }

      //   if (!expense.uploadInvoice) {
      //     expenseErrors.uploadInvoice = "Invoice upload is required.";
      //   }

      //   if (Object.keys(expenseErrors).length > 0) {
      //     errors[`capital[${index}]`] = expenseErrors;
      //   }
      // });

      if (estateType === "Buildings") {
        // mandate.forEach((expense, index) => {
        //   const expenseErrors = {};

        //   if (!expense.expense_Type.trim()) {
        //     expenseErrors.expense_Type = "Expense type is required.";
        //   }

        //   if (!expense.amt_Paid) {
        //     expenseErrors.amt_Paid = "Amount paid is required.";
        //   } else if (
        //     isNaN(expense.amt_Paid) ||
        //     expense.amt_Paid <= 0 ||
        //     expense.amt_Paid % 1 !== 0
        //   ) {
        //     expenseErrors.amt_Paid =
        //       "Amount paid must be a positive whole number.";
        //   }

        //   if (!expense.expense_Date) {
        //     expenseErrors.expense_Date = "Expense date is required.";
        //   } else {
        //     const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        //     if (!datePattern.test(expense.expense_Date)) {
        //       expenseErrors.expense_Date =
        //         "Expense date must be in YYYY-MM-DD format.";
        //     }
        //   }

        //   if (!expense.uploadCertificate) {
        //     expenseErrors.uploadCertificate = "Certificate upload is required.";
        //   }

        //   if (Object.keys(expenseErrors).length > 0) {
        //     errors[`mandate[${index}]`] = expenseErrors;
        //   }
        // });
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const createLandData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(surveryNo)) formData.append("survey_number", surveryNo);
    if (isNotEmpty(area)) formData.append("area", area);
    if (isNotEmpty(city)) formData.append("city", city);
    if (isNotEmpty(pincode)) formData.append("pin_code", pincode);
    if (isNotEmpty(state)) formData.append("state", state);
    if (isNotEmpty(country)) formData.append("country", country);
    if (isNotEmpty(assetType)) formData.append("asset_type", assetType);
    if (isNotEmpty(ownershipPercentage)) formData.append("ownership_percentage", ownershipPercentage);
    if (isNotEmpty(stampDuty)) formData.append("stamp_duty", stampDuty);
    if (isNotEmpty(registrationFee)) formData.append("registration_fee", registrationFee);
    if (isNotEmpty(brokerageFee)) formData.append("brokerage_fee", brokerageFee);
    if (isNotEmpty(otherExpenses)) formData.append("other_expenses", otherExpenses);
    if (isNotEmpty(totalCost)) formData.append("total_cost", totalCost);
    if (isNotEmpty(loanTaken)) formData.append("loan_taken", loanTaken);
  
    // Append land photo if available and valid
    if (landPhoto) formData.append("land_photo", landPhoto);
  
    // Append capital expenses array if available and valid
    capital.forEach((item, index) => {
      if (isNotEmpty(item.expenseType)) formData.append(`capital_expenses[${index}]expense_type`, item.expenseType);
      if (isNotEmpty(item.expenseDate)) formData.append(`capital_expenses[${index}]expense_date`, item.expenseDate);
      if (isNotEmpty(item.amtPaid)) formData.append(`capital_expenses[${index}]amount_paid`, item.amtPaid);
      if (item.uploadInvoice) formData.append(`capital_expenses[${index}]upload_invoice`, item.uploadInvoice);
    });
  
    // Submit the form data via the API call
    postLandDetails(formData, assetId);
  };
  

  const createBuildingData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(buildingType)) formData.append("building_type", buildingType);
    if (isNotEmpty(flatNo)) formData.append("flat_door_block_no", flatNo);
    if (isNotEmpty(area)) formData.append("area", area);
    if (isNotEmpty(city)) formData.append("city", city);
    if (isNotEmpty(pincode)) formData.append("pin_code", pincode);
    if (isNotEmpty(state)) formData.append("state", state);
    if (isNotEmpty(country)) formData.append("country", country);
    if (isNotEmpty(assetType)) formData.append("asset_type", assetType);
    if (isNotEmpty(ownershipPercentage)) formData.append("ownership_percentage", ownershipPercentage);
    if (isNotEmpty(stampDuty)) formData.append("stamp_duty", stampDuty);
    if (isNotEmpty(registrationFee)) formData.append("registration_fee", registrationFee);
    if (isNotEmpty(brokerageFee)) formData.append("brokerage", brokerageFee);
    if (isNotEmpty(otherExpenses)) formData.append("other_purchase_expenses", otherExpenses);
    if (isNotEmpty(totalCost)) formData.append("total_cost", totalCost);
    if (isNotEmpty(loanTaken)) formData.append("loan_taken", loanTaken);
    if (isNotEmpty(estimatedMarketValue)) formData.append("estimated_market_value", estimatedMarketValue);
    if (isNotEmpty(purchaseDate)) formData.append("purchase_date", purchaseDate);
    if (isNotEmpty(insuranceTaken)) formData.append("insurance_taken", insuranceTaken);
  
    // Append land photo if available and valid
    if (landPhoto) formData.append("land_photo", landPhoto);
  
    // Append valuation report if available and valid
    if (valuationReport) formData.append("valuation_report", valuationReport);
  
    // Append sale deed if available and valid
    if (isNotEmpty(saleDeed)) formData.append("sale_deed", saleDeed);
  
    // Append capital expenses if available and valid
    capital.forEach((item, index) => {
      if (isNotEmpty(item.expenseType)) formData.append(`capital_expenses[${index}]expense_type`, item.expenseType);
      if (isNotEmpty(item.expenseDate)) formData.append(`capital_expenses[${index}]expense_date`, item.expenseDate);
      if (isNotEmpty(item.amtPaid)) formData.append(`capital_expenses[${index}]amount_paid`, item.amtPaid);
      if (item.uploadInvoice) formData.append(`capital_expenses[${index}]upload_invoice`, item.uploadInvoice);
    });
  
    // Append mandates if available and valid
    mandate.forEach((item, index) => {
      if (isNotEmpty(item.expense_Type)) formData.append(`mandates[${index}]expense_type`, item.expense_Type);
      if (isNotEmpty(item.expense_Date)) formData.append(`mandates[${index}]expense_date`, item.expense_Date);
      if (isNotEmpty(item.amt_Paid)) formData.append(`mandates[${index}]amount_paid`, item.amt_Paid);
      if (item.uploadCertificate) formData.append(`mandates[${index}]upload_certificate`, item.uploadCertificate);
    });
  
    // Submit the form data via the API call
    postBuildingDetails(formData, assetId);
  };
  

  const handleNext = () => {
    const isValid = validateInputs();

    if (isValid) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      } else {
        if (estateType === "Land") {
          createLandData();
        } else {
          createBuildingData();
        }
      }
    } else {
      console.log("Validation failed.");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCapitalExpense = () => {
    setCapitalExpense(true);
  };

  const handleMandateExpense = () => {
    console.log("Adding a new mandate expense");
    setMandateExpense(true);
  };

  const handleLoanTaken = (e) => {
    setloanTaken(e.target.value);
  };
  const handleInsuranceTaken = (e) => {
    setInsuranceTaken(e.target.value);
  };

  const onEditAddress = () => {
    setCurrentStep(0);
  };

  const onEditLand = () => {
    setCurrentStep(1);
  };
  const onEditCapital = () => {
    setCurrentStep(2);
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              What's the address of your {estateType}?
            </h4>

            {/* form */}
            <div className="overflow-y-auto max-h-[350px] scrollbar-hide">
              <div
                className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] ${
                  estateType == "Buildings" ? "mb-[3.6rem]" : "mb-[6rem]"
                }`}
              >
                <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                  {estateType == "Buildings" ? (
                    <>
                      <div className="input-container mb-[0.4rem]">
                        <label className="input-label">Building Type</label>
                        <InputField
                          value={buildingType}
                          onChange={(e) => setBuldingType(e.target.value)}
                          type="select"
                          className="input-box border rounded-md bg-white"
                          options={[
                            { value: "", label: "Select Type" },
                            { value: "apartment", label: "Apartment" },
                            { value: "house", label: "House" },
                            { value: "commercial", label: "Commercial" },
                            { value: "industrial", label: "Industrial" },
                            { value: "other", label: "Other" },
                          ]}
                        />
                        {errors.buildingType && (
                          <span className=" text-red-500 text-[0.7rem]">
                            {errors.buildingType}
                          </span>
                        )}
                      </div>
                      <div></div>
                      <div className="input-container">
                        <label className="input-label">
                          Flat/Door/Block No.
                        </label>
                        <InputField
                          value={flatNo}
                          onChange={(e) => setFlatNo(e.target.value)}
                          type="text"
                          placeholder="Flat/Door/Block No."
                          className="input-box border rounded-md bg-white"
                        />
                        <br></br>
                        {errors.flatNo && (
                          <span className=" text-red-500 text-[0.7rem]">
                            {errors.flatNo}
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="input-container">
                      <label className="input-label">Survey Number</label>
                      <InputField
                        value={surveryNo}
                        onChange={(e) => setSurveryNo(e.target.value)}
                        type="number"
                        placeholder="Enter Survey Number"
                        className="input-box border rounded-md bg-white"
                      />
                      {errors.surveryNo && (
                        <span className=" text-red-500 text-[0.7rem]">
                          {errors.surveryNo}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="input-container">
                    <label className="input-label">Area</label>
                    <InputField
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                      type="text"
                      placeholder="Enter Area"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.area && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.area}
                      </span>
                    )}
                  </div>

                  <div className="input-container flex flex-col mt-[0.6rem] ">
                    <label className="input-label">City</label>
                    <InputField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      placeholder="Enter City"
                      className="input-box border rounded-md bg-white "
                    />
                    {errors.city && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.city}
                      </span>
                    )}
                  </div>

                  <div className="input-container mt-[0.2rem]">
                    <label className="input-label">Pincode</label>
                    <InputField
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      type="number"
                      placeholder="Enter Pincode"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.pincode && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.pincode}
                      </span>
                    )}
                  </div>

                  <div className="input-container mt-[0.4rem]">
                    <label className="input-label">State</label>
                    <InputField
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      type="select"
                      className="input-box border rounded-md bg-white"
                      options={[
                        { label: "Select State" },
                        ...indianStates.map((state) => ({ label: state })),
                      ]}
                    />
                    {errors.state && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.state}
                      </span>
                    )}
                  </div>

                  <div className="input-container mt-[0.4rem]">
                    <label className="input-label">Country</label>
                    <InputField
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      type="select"
                      className="input-box border rounded-md bg-white"
                      options={[
                        { value: "", label: "Select Country" },
                        { value: "India", label: "India" },
                      ]}
                    />
                    {errors.country && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.country}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Add Value of your {estateType}
            </h4>

            <div className="overflow-y-auto max-h-[350px] scrollbar-hide">
              {/* type of asset */}
              <div className="flex justify-between items-start rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[1rem] px-[1rem] py-[0.7rem]">
                <div className="">
                  <label className="input-label">Type of Asset</label>
                  <div
                    className={`p-1 flex gap-2 items-center border bg-white rounded-md overflow-hidden ${
                      assetType === "idle" ? "w-[100%]" : "w-[100%]"
                    }`}
                  >
                    <button
                      onClick={() => toggleAssetType("active")}
                      className={`px-2 py-1 text-[0.8rem] ${
                        assetType === "active"
                          ? "bg-gray-800 text-white"
                          : "bg-white text-gray-500"
                      } rounded-md`}
                    >
                      Active Asset
                    </button>
                    <button
                      onClick={() => toggleAssetType("idle")}
                      className={`px-2 py-1 text-[0.8rem]  ${
                        assetType === "idle"
                          ? "bg-gray-800 text-white"
                          : "bg-white text-gray-500"
                      } rounded-md`}
                    >
                      Idle Asset
                    </button>
                  </div>
                  <label className="text-[0.7rem] font-[500] text-customPurple">
                    {assetType == "active"
                      ? "This asset is generating income."
                      : "This asset is idle income."}
                  </label>
                  {errors.assetType && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.assetType}
                    </span>
                  )}
                </div>

                <div className="w-[35%] input-container mt-[0.4rem]">
                  <label className="input-label flex flex-col ">
                    Percentage Of Ownership
                  </label>
                  <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[40%]">
                    <InputField
                      value={ownershipPercentage}
                      onChange={(e) => setOwnershipPercentage(e.target.value)}
                      className="text-[#334155] text-[0.9rem] bg-white focus:outline-none px-1 py-1 w-[90%] rounded-l"
                      // className="mt-1 border bg-white w-[30%] focus:outline-customPurple rounded-md px-2 py-1"
                      type="text"
                    />
                    <span className="group-focus-within:text-customPurple pr-2 py-1 text-lightGray text-[0.9rem] rounded-r-md">
                      %
                    </span>
                  </div>
                  <br></br>
                  {errors.ownershipPercentage && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.ownershipPercentage}
                    </span>
                  )}
                </div>
              </div>

              {/* market value */}
              {estateType == "Buildings" ? (
                <div className="rounded-md bg-[#F1F5F9] mb-[1rem] px-[1rem] py-[0.7rem]">
                  <h4 className="text-[#334155] font-[500] text-[1rem]">
                    Market Value
                  </h4>
                  <div className="w-[25%]">
                    <InputField
                      type="file"
                      accept=".pdf"
                      id={`file-input-valuation`}
                      className="hidden"
                      onChange={(e) => setValuationReport(e.target.files[0])}
                    />
                    <WhiteButton
                      onClick={() =>
                        document.querySelector(`#file-input-valuation`).click()
                      }
                      name={"Add Report"}
                      px={"px-[0.4rem"}
                      py={"py-[0.3rem]"}
                    />
                  </div>
                  {errors.valuationReport && (
                    <span className=" text-red-500 text-[0.7rem]">
                      {errors.valuationReport}
                    </span>
                  )}

                  <div className="w-[50%] input-container mt-[1rem]">
                    <label className="input-label">
                      Estimated Market Value
                    </label>
                    <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                        value={estimatedMarketValue}
                        onChange={(e) =>
                          setEstimatedMarketValue(e.target.value)
                        }
                        type="number"
                      />
                    </div>
                    {errors.estimatedMarketValue && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.estimatedMarketValue}
                      </span>
                    )}
                  </div>
                  <label className="input-label">As per Valuation Report</label>
                </div>
              ) : (
                ""
              )}
              {/* purchase details */}
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[500] text-[1rem]">
                  Purchase Details
                </h4>
                <div className="grid grid-cols-2 gap-1">
                  {estateType == "Buildings" ? (
                    <>
                      <div className="input-container mb-[0.4rem]">
                        <label className="input-label">Purchase Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            value={purchaseDate}
                            type="date"
                            onChange={(e) => setPurchaseDate(e.target.value)}
                          />
                        </div>
                        {errors.purchaseDate && (
                          <span className=" text-red-500 text-[0.7rem]">
                            {errors.purchaseDate}
                          </span>
                        )}
                      </div>
                      <div></div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="input-container">
                    <label className="input-label">Stamp Duty</label>
                    <InputField
                      value={stampDuty}
                      onChange={(e) => setStampDuty(e.target.value)}
                      type="number"
                      placeholder="Enter Stamp Duty"
                      className="input-box border rounded-md bg-white"
                    />
                    {errors.stampDuty && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.stampDuty}
                      </span>
                    )}
                  </div>

                  <div className="input-container">
                    <label className="input-label">Registration Fee</label>
                    <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                        value={registrationFee}
                        onChange={(e) => setRegistrationFee(e.target.value)}
                        type="number"
                        placeholder="Enter Registration Fee"
                      />
                    </div>
                    {errors.registrationFee && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.registrationFee}
                      </span>
                    )}
                  </div>

                  <div className="input-container mt-[0.4rem] ">
                    <label className="input-label">Brokerage (if any)</label>
                    <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                        value={brokerageFee}
                        onChange={(e) => setBrokerageFee(e.target.value)}
                        type="number"
                        placeholder="Enter Brokerage"
                      />
                    </div>
                    {errors.brokerageFee && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.brokerageFee}
                      </span>
                    )}
                  </div>

                  <div className="input-container mt-[0.4rem]">
                    <label className="input-label">
                      Other Purchase Expenses
                    </label>
                    <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                        value={otherExpenses}
                        onChange={(e) => setOtherExpenses(e.target.value)}
                        type="number"
                        placeholder="Enter Other Expenses"
                      />
                    </div>
                    {errors.otherExpenses && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.otherExpenses}
                      </span>
                    )}
                  </div>

                  <div className="input-container mt-[0.4rem]">
                    <label className="input-label">Total Cost</label>
                    <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                      <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                        value={totalCost}
                        onChange={(e) => setTotalCost(e.target.value)}
                        type="number"
                        placeholder="Enter Total Cost"
                      />
                    </div>
                    {errors.totalCost && (
                      <span className=" text-red-500 text-[0.7rem]">
                        {errors.totalCost}
                      </span>
                    )}
                  </div>
                  {estateType == "Buildings" ? (
                    <>
                      <div className="mt-[2rem]">
                        <InputField
                          type="file"
                          accept=".pdf"
                          id={`file-input-sale`}
                          className="hidden"
                          onChange={(e) => setSaleDeed(e.target.files[0])}
                        />
                        <WhiteButton
                          onClick={() =>
                            document.querySelector(`#file-input-sale`).click()
                          }
                          name="Upload Sale Deed"
                          px={"px-[0.4rem]"}
                          py={"py-[0.2rem]"}
                        />
                        {errors.saleDeed && (
                          <span className=" text-red-500 text-[0.7rem]">
                            {errors.saleDeed}
                          </span>
                        )}
                      </div>
                      <div className="input-container mt-[0.6rem]">
                        <label className="input-label">
                          Insurance taken for this property?
                        </label>
                        <div className="flex gap-3 items-center mt-[0.5rem]">
                          <label className="input-label focus-within:text-customPurple">
                            <InputField
                              type="radio"
                              value="true"
                              className="mr-2 custom-radio"
                              onChange={handleInsuranceTaken}
                              checked={insuranceTaken == "true"}
                            />
                            Yes
                          </label>
                          <label className="input-label focus-within:text-customPurple">
                            <InputField
                              type="radio"
                              value="false"
                              className="mr-2 custom-radio"
                              onChange={handleInsuranceTaken}
                              checked={insuranceTaken == "false"}
                            />
                            No
                          </label>
                          {errors.insuranceTaken && (
                            <span className=" text-red-500 text-[0.7rem]">
                              {errors.insuranceTaken}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="input-container mt-[0.6rem]">
                    <label className="input-label">
                      Taken any loan against this property?
                    </label>
                    <div className="flex gap-3 items-center mt-[0.5rem]">
                      <label className="input-label focus-within:text-customPurple">
                        <InputField
                          onChange={handleLoanTaken}
                          type="radio"
                          value="true"
                          className="mr-2 custom-radio"
                          checked={loanTaken == "true"}
                        />
                        Yes
                      </label>
                      <label className="input-label focus-within:text-customPurple">
                        <InputField
                          onChange={handleLoanTaken}
                          type="radio"
                          value="false"
                          className="mr-2 custom-radio"
                          checked={loanTaken == "false"}
                        />
                        No
                      </label>

                      {errors.loanTaken && (
                        <span className=" text-red-500 text-[0.7rem]">
                          {errors.loanTaken}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Any expense on your {estateType}?
            </h4>
            <div className="overflow-y-auto max-h-[350px] scrollbar-hide">
              {/* capital expense */}

              {capitalExpense ? (
                <div
                  className={`${
                    estateType == "Buildings" ? "" : "min-h-[350px] "
                  }`}
                >
                  <h4 className="text-[#334155] font-[500] text-[1rem] mt-[1rem]">
                    Capital Expenses
                  </h4>

                  {capital.map((item, index) => (
                    <div
                      key={item.id}
                      className={`rounded-md bg-[#F1F5F9] mt-[0.6rem] ${
                        estateType == "Buildings" ? "" : "mb-[1rem]"
                      }`}
                    >
                      <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                        <div className="input-container">
                          <label className="input-label">Expense Type</label>
                          <InputField
                            onChange={(e) =>
                              handleChange(index, "expenseType", e.target.value)
                            }
                            value={item.expenseType}
                            type="select"
                            className="input-box border rounded-md bg-white"
                            options={[
                              { value: "", label: "Select Type" },
                              {
                                value: "maintenance",
                                label: "Maintenance",
                              },
                              { value: "renovation", label: "Renovation" },
                              { value: "taxes", label: "Taxes" },
                              { value: "utilities", label: "Utilities" },
                              { value: "other", label: "Other" },
                            ]}
                          />
                          {errors[`capital[${index}]`] &&
                            errors[`capital[${index}]`].expenseType && (
                              <span className="error text-[0.7rem] text-red-500">
                                {errors[`capital[${index}]`].expenseType}
                              </span>
                            )}
                        </div>

                        <div className="input-container">
                          <label className="input-label">Amount Paid</label>
                          <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                            <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                              Rs.
                            </span>
                            <InputField
                              className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                              onChange={(e) =>
                                handleChange(index, "amtPaid", e.target.value)
                              }
                              value={item.amtPaid}
                              type="number"
                              placeholder="Enter Amount Paid"
                            />
                          </div>
                          {errors[`capital[${index}]`] &&
                            errors[`capital[${index}]`].amtPaid && (
                              <span className="error text-[0.7rem] text-red-500">
                                {errors[`capital[${index}]`].amtPaid}
                              </span>
                            )}
                        </div>

                        <div className="input-container mt-[0.3rem] ">
                          <label className="input-label">Expense Date</label>
                          <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                            <span className="p-[0.5rem] rounded-l-md">
                              <img src={datePicker} className="w-5 h-5" />
                            </span>
                            <InputField
                              className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "expenseDate",
                                  e.target.value
                                )
                              }
                              value={item.expenseDate}
                              type="date"
                            />
                          </div>
                          {errors[`capital[${index}]`] &&
                            errors[`capital[${index}]`].expenseDate && (
                              <span className="error text-[0.7rem] text-red-500">
                                {errors[`capital[${index}]`].expenseDate}
                              </span>
                            )}
                        </div>
                        <div>
                          <div className="mt-[1.7rem] flex gap-2">
                            <InputField
                              type="file"
                              accept=".pdf"
                              id={`file-input-capital-${index}`}
                              className="hidden"
                              onChange={(e) => {
                                handleChange(
                                  index,
                                  "uploadInvoice",
                                  e.target.files[0]
                                );
                              }}
                            />

                            <button
                              onClick={() =>
                                document
                                  .querySelector(`#file-input-capital-${index}`)
                                  .click()
                              }
                              className="text-lightGray bg-white font-[600] text-[0.8rem] border-1 border py-[0.1rem] px-[0.7rem] rounded-md flex gap-2 items-center"
                            >
                              <img src={upload} alt="upload" />
                              Upload Invoice
                            </button>

                            <button
                              onClick={() => removeCapitalExpense(index)}
                              className="group"
                            >
                              <img
                                src={deleteIcon}
                                alt="delete icon"
                                className="w-11 h-10 group-hover:hidden"
                              />
                              <img
                                src={deleteRedIcon}
                                alt="delete icon"
                                className="w-11 h-10 hidden group-hover:block"
                              />
                            </button>
                          </div>
                          {errors[`capital[${index}]`] &&
                            errors[`capital[${index}]`].uploadInvoice && (
                              <span className="error text-[0.7rem] text-red-500">
                                {errors[`capital[${index}]`].uploadInvoice}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}

                  {estateType == "Buildings" && (
                    <div className="w-[50%] mt-2">
                      <WhiteButton
                        onClick={handleAddCapitalExpense}
                        name="Add another capital expense"
                        px={"px-[0.6rem]"}
                        py={"py-[0.4rem]"}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`${
                    estateType == "Buildings" ? "" : "min-h-[300px] "
                  }`}
                >
                  <h4 className="text-[#334155] font-[500] text-[1rem] mt-[1rem]">
                    Capital Expenses
                  </h4>
                  <div
                    className={`flex justify-center rounded-md bg-[#F1F5F9] mt-[0.8rem] py-[1.5rem] ${
                      estateType == "Buildings" ? "" : "mb-[15rem]"
                    }`}
                  >
                    <button
                      onClick={handleCapitalExpense}
                      className="text-lightGray text-[0.9rem] border rounded-md font-[500] py-1 px-3 hover:bg-white"
                    >
                      Add Capital expense
                    </button>
                  </div>
                </div>
              )}

              {/* mandate expense */}
              {estateType == "Buildings" &&
                (mandateExpense ? (
                  <div className="">
                    <h4 className="text-[#334155] font-[500] text-[1rem] mt-[1rem]">
                      Mandate Expenses
                    </h4>

                    {mandate.map((item, index) => {
                      // console.log(`Rendering mandate item at index ${index}`);
                      return (
                        <div
                          key={index}
                          className={`rounded-md bg-[#F1F5F9] mt-[0.6rem] ${
                            estateType == "Buildings" ? "" : "mb-[1rem]"
                          }`}
                        >
                          <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                            <div className="input-container">
                              <label className="input-label">
                                Expense Type
                              </label>
                              <InputField
                                onChange={(e) =>
                                  handleChangeMandate(
                                    index,
                                    "expense_Type",
                                    e.target.value
                                  )
                                }
                                value={item.expense_Type}
                                type="select"
                                className="input-box border rounded-md bg-white"
                                options={[
                                  { value: "", label: "Select Type" },
                                  {
                                    value: "maintenance",
                                    label: "Maintenance",
                                  },
                                  { value: "insurance", label: "Insurance" },
                                  { value: "repair", label: "Repair" },
                                  { value: "tax", label: "Tax" },
                                  { value: "other", label: "Other" },
                                ]}
                              />
                              {errors[`mandate[${index}]`] &&
                                errors[`mandate[${index}]`].expense_Type && (
                                  <span className="error text-[0.7rem] text-red-500">
                                    {errors[`mandate[${index}]`].expense_Type}
                                  </span>
                                )}
                            </div>

                            <div className="input-container">
                              <label className="input-label">Amount Paid</label>
                              <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                                <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                                  Rs.
                                </span>
                                <InputField
                                  className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                                  onChange={(e) =>
                                    handleChangeMandate(
                                      index,
                                      "amt_Paid",
                                      e.target.value
                                    )
                                  }
                                  value={item.amt_Paid}
                                  type="number"
                                  placeholder="Enter Amount Paid"
                                />
                              </div>
                              {errors[`mandate[${index}]`] &&
                                errors[`mandate[${index}]`].amt_Paid && (
                                  <span className="error text-[0.7rem] text-red-500">
                                    {errors[`mandate[${index}]`].amt_Paid}
                                  </span>
                                )}
                            </div>

                            <div className="input-container mt-[0.3rem] ">
                              <label className="input-label">
                                Expense Date
                              </label>
                              <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                                <span className="p-[0.5rem] rounded-l-md">
                                  <img src={datePicker} className="w-5 h-5" />
                                </span>
                                <InputField
                                  className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                                  onChange={(e) =>
                                    handleChangeMandate(
                                      index,
                                      "expense_Date",
                                      e.target.value
                                    )
                                  }
                                  value={item.expense_Date}
                                  type="date"
                                />
                              </div>
                              {errors[`mandate[${index}]`] &&
                                errors[`mandate[${index}]`].expense_Date && (
                                  <span className="error text-[0.7rem] text-red-500">
                                    {errors[`mandate[${index}]`].expense_Date}
                                  </span>
                                )}
                            </div>

                            <div>
                              <div className="mt-[1.7rem] flex gap-2">
                                <InputField
                                  type="file"
                                  accept=".pdf"
                                  id={`file-input-${index}`}
                                  className="hidden"
                                  onChange={(e) => {
                                    handleChangeMandate(
                                      index,
                                      "uploadCertificate",
                                      e.target.files[0]
                                    ); // Pass file or value
                                  }}
                                />

                                <button
                                  onClick={() =>
                                    document
                                      .querySelector(`#file-input-${index}`)
                                      .click()
                                  }
                                  className="text-lightGray bg-white font-[600] text-[0.7rem] border-1 border px-[0.7rem] rounded-md flex gap-2 items-center"
                                >
                                  <img
                                    src={upload}
                                    alt="upload"
                                    className="w-4 h-4"
                                  />
                                  Upload Certificate
                                </button>

                                <button
                                  className="group"
                                  onClick={() => removeMandateExpense(index)}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete icon"
                                    className="w-11 h-10 group-hover:hidden"
                                  />
                                  <img
                                    src={deleteRedIcon}
                                    alt="delete icon"
                                    className="w-11 h-10 hidden group-hover:block"
                                  />
                                </button>
                              </div>
                              {errors[`mandate[${index}]`] &&
                                errors[`mandate[${index}]`]
                                  .uploadCertificate && (
                                  <span className="error text-[0.7rem] text-red-500">
                                    {
                                      errors[`mandate[${index}]`]
                                        .uploadCertificate
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <h4 className="text-[#334155] font-[500] text-[1rem] mt-[1rem]">
                      Mandate Expenses
                    </h4>
                    <div className="flex justify-center rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[6rem] py-[1.5rem]">
                      <button
                        onClick={handleMandateExpense}
                        className="text-lightGray text-[0.9rem] border rounded-md font-[500] py-1 px-3 hover:bg-white"
                      >
                        Add Mandate expense
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Review your details
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              <div className="grid grid-cols-12">
                <div className="mt-[1rem] col-span-12 flex justify-between items-center">
                  <h4 className="text-[#334155] font-[500] text-[1rem]">
                    Land Address
                  </h4>
                  <WhiteButton
                    name="Edit"
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    onClick={onEditAddress}
                  />
                </div>
                <div className="col-span-10 ">
                  <div className="">
                    {estateType == "Buildings" ? (
                      <InputField
                        name="area"
                        readOnly
                        value={`${flatNo} , ${buildingType}`}
                        className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem]"
                      />
                    ) : (
                      <InputField
                        name="area"
                        readOnly
                        value={area}
                        className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem]"
                      />
                    )}
                    {estateType == "Buildings" ? (
                      <InputField
                        name="area"
                        readOnly
                        value={`${area} , ${city} , ${pincode}`}
                        className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem] mt-1"
                      />
                    ) : (
                      <InputField
                        name="area"
                        readOnly
                        value={`${city} , ${pincode}`}
                        className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem] mt-1"
                      />
                    )}

                    <InputField
                      name="area"
                      readOnly
                      value={`${state} , ${country}`}
                      className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem] mt-1"
                    />
                  </div>
                </div>
                <div className="col-span-12 mt-[1rem] flex justify-between items-center">
                  <h4 className="text-[#334155] font-[500] text-[1rem]">
                    Land Value
                  </h4>
                  <WhiteButton
                    name="Edit"
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    onClick={onEditLand}
                  />
                </div>
                <div className="col-span-10 ">
                  <div className="grid grid-cols-2 mb-[1rem] flex  divide-x border rounded-xl ">
                    <div className="p-[1rem]">
                      <h4 className="text-lightGray font-[500] text-[0.7rem]">
                        Land Status
                      </h4>
                      <h4
                        value={assetType}
                        className="text-darkGray font-[500] text-[1rem] m-0"
                      >
                        {/* Active Asset */}
                        {assetType}
                      </h4>
                    </div>
                    <div className="p-[1rem]">
                      <h4 className="text-lightGray font-[500] text-[0.7rem]">
                        Percentage Of Ownership
                      </h4>
                      <h4 className="text-darkGray font-[500] text-[1rem] m-0">
                        {ownershipPercentage} %
                      </h4>
                    </div>
                  </div>
                  {/* purchase details */}

                  <div className="border bg-white rounded-xl p-[1rem]">
                    <h4 className="text-darkGray font-[500] text-[0.9rem] m-0">
                      Purchase Details
                    </h4>
                    {/* form */}
                    <div className="mt-2 flex justify-between ">
                      <div className="flex flex-col gap-[1rem]">
                        <div className="input-container flex flex-col">
                          <label className="text-[0.7rem] text-customPurple">
                            Total Cost
                          </label>
                          <InputField
                            className=" w-[70%] text-[1rem] mt-[0.4rem] font-[500] focus:outline-none text-customPurple"
                            value={`${totalCost} Rs.`}
                            readOnly
                          />
                        </div>

                        <div className="input-container group flex flex-col">
                          <label className="text-lightGray text-[0.7rem] ">
                            Stamp Duty
                          </label>
                          <InputField
                            className="w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                            value={`${stampDuty} Rs.`}
                            readOnly
                          />
                        </div>

                        <div className="input-container group flex flex-col">
                          <label className="text-lightGray text-[0.7rem] ">
                            Brokerage (if any)
                          </label>
                          <InputField
                            className="w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                            value={`${brokerageFee} Rs.`}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-[1rem]">
                        <div className="input-container group flex flex-col">
                          <label className="text-lightGray text-[0.7rem] ">
                            Loan against this land
                          </label>
                          <InputField
                            className=" w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                            value={loanTaken == "true" ? "Yes" : "No"}
                            readOnly
                          />
                        </div>

                        <div className="input-container group flex flex-col">
                          <label className="text-lightGray text-[0.7rem] ">
                            Registration Fee
                          </label>
                          <InputField
                            className="w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                            value={`${registrationFee} Rs.`}
                            readOnly
                          />
                        </div>

                        <div className="input-container group flex flex-col">
                          <label className="text-lightGray text-[0.7rem] ">
                            Other Expenses
                          </label>
                          <InputField
                            className="w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                            value={`${otherExpenses} Rs.`}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* capital */}
                <div className="mt-[1rem] col-span-12 flex justify-between items-center">
                  <h4 className="text-[#334155] font-[500] text-[1rem]">
                    Capital Expenses on Land
                  </h4>
                  <WhiteButton
                    name="Edit"
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    onClick={onEditCapital}
                  />
                </div>
                <div className="col-span-10 ">
                  <div className="border bg-white rounded-xl p-[1rem]  mb-[2rem]">
                    {/* form */}
                    <div className="mt-2 space-y-4">
                      {capital.map((item, index) => (
                        <div className="grid grid-cols-2 gap-2">
                          <div className="input-container group flex flex-col">
                            <label className="text-lightGray text-[0.7rem] ">
                              Expense Type
                            </label>
                            <InputField
                              className=" w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                              value={item.expenseType}
                              readOnly
                            />
                          </div>

                          <div className="input-container group flex flex-col">
                            <label className="text-[0.7rem] text-customPurple">
                              Amount Paid
                            </label>
                            <InputField
                              className=" w-[70%] text-[1rem] mt-[0.4rem] font-[500] focus:outline-none text-customPurple"
                              value={`${item.amtPaid} Rs.`}
                              readOnly
                            />
                          </div>
                          <div className="input-container group flex flex-col">
                            <label className="text-lightGray text-[0.7rem] ">
                              Date
                            </label>
                            <InputField
                              className="w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                              value={item.expenseDate}
                              readOnly
                            />
                          </div>

                          <div className="w-[80%] mt-[1rem]">
                            <button
                              onClick={() => {
                                if (item.uploadInvoice) {
                                  const fileURL = URL.createObjectURL(
                                    item.uploadInvoice
                                  );
                                  window.open(fileURL, "_blank");
                                }
                              }}
                              className="text-lightGray bg-white font-[600] text-[0.8rem] border-1 border py-[0.2rem] px-[0.6rem] rounded-md flex gap-2 items-center"
                            >
                              <img
                                src={upload}
                                alt="upload"
                                className="w-4 h-4"
                              />
                              View Invoice
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* mandate */}
                {estateType == "Buildings" && (
                  <>
                  <div className="col-span-12 flex justify-between items-center">
                  <h4 className="text-[#334155] font-[500] text-[1rem]">
                    Mandate Expenses
                  </h4>
                  <WhiteButton
                    name="Edit"
                    px={"px-[0.6rem]"}
                    py={"py-[0.2rem]"}
                    onClick={onEditCapital}
                  />
                </div>
                <div className="col-span-10 ">
                  <div className="border bg-white rounded-xl p-[1rem]  mb-[2rem]">
                    {/* form */}
                    <div className="mt-2 space-y-4">
                      {mandate.map((item, index) => (
                        <div className="grid grid-cols-2 gap-2" key={index}>
                          <div className="input-container group flex flex-col">
                            <label className="text-lightGray text-[0.7rem] ">
                              Expense Type
                            </label>
                            <InputField
                              className=" w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                              value={item.expense_Type}
                              readOnly
                            />
                          </div>

                          <div className="input-container group flex flex-col">
                            <label className="text-[0.7rem] text-customPurple">
                              Amount Paid
                            </label>
                            <InputField
                              className=" w-[70%] text-[1rem] mt-[0.4rem] font-[500] focus:outline-none text-customPurple"
                              value={`${item.amt_Paid} Rs.`}
                              readOnly
                            />
                          </div>
                          <div className="input-container group flex flex-col">
                            <label className="text-lightGray text-[0.7rem] ">
                              Date
                            </label>
                            <InputField
                              className="w-[70%] text-[1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none "
                              value={item.expense_Date}
                              readOnly
                            />
                          </div>

                          <div className="w-[80%] mt-[1rem]">
                            <button
                              onClick={() => {
                                if (item.uploadCertificate) {
                                  const fileURL = URL.createObjectURL(
                                    item.uploadCertificate
                                  );
                                  window.open(fileURL, "_blank");
                                }
                              }}
                              className="text-lightGray bg-white font-[600] text-[0.8rem] border-1 border py-[0.2rem] px-[0.6rem] rounded-md flex gap-2 items-center"
                            >
                              <img
                                src={upload}
                                alt="upload"
                                className="w-4 h-4"
                              />
                              View Invoice
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
</>
                
                )}
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">{estateType}</span> to your
            Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div
              className={`${
                currentStep == 2 && (capitalExpense || mandateExpense)
                  ? "flex justify-between"
                  : "flex justify-end"
              }`}
            >
              {currentStep == 2 ? (
                <>
                  {capitalExpense && estateType == "Land" ? (
                    <WhiteButton
                      onClick={handleAddCapitalExpense}
                      name="Add another expense"
                      px={"px-[0.6rem]"}
                      py={"py-[0.4rem]"}
                    />
                  ) : (
                    <div></div>
                  )}
                  {estateType == "Buildings" && mandateExpense && (
                    <WhiteButton
                      onClick={handleAddMandate}
                      name="Add another Mandate"
                      px={"px-[0.6rem]"}
                      py={"py-[0.4rem]"}
                    />
                  )}
                </>
              ) : (
                ""
              )}

              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={currentStep === 0 ? handleClose : handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                {currentStep < 3 ? (
                  <Button
                    onClick={handleNext}
                    name={
                      currentStep == 2 &&
                      (capitalExpense === true || mandateExpense === true)
                        ? "Preview & Save"
                        : "Next"
                    }
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                ) : (
                  <Button
                    onClick={handleNext}
                    name={"Add to Assets"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                )}
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewLand;
