import { GET_ASSETS_CATEGORY, GET_FAMILY_LIABILITIES_OVERVIEW, GET_LIABILITIES_OVERVIEW, GET_LOAN_TYPES, GET_TAX_SUMMARY_RENTAL_URL } from "constants/apis";
import useAPICall from "hooks/useAPICall";

const useGetPersonalCategoryAssets = () => {
  const [
    data,
    error,
    isLoading,
    callGetPersonalCategoryAssets,
    setSuccessData,
    setError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getPersonalCategoryAssets = (body) => {
    // console.log("get profile details API: ", data);
    const url = GET_ASSETS_CATEGORY.replace(":category", "PERSONAL ASSETS")
    callGetPersonalCategoryAssets({
      url,
      method: "GET",
      statusObj,
    //   config,
      defaultFallback,
      body,
    });
  };

  return [
    data,
    error,
    isLoading,
    getPersonalCategoryAssets,
    setSuccessData,
    setError,
  ];
};

export default useGetPersonalCategoryAssets;
