import useAPICall from "hooks/useAPICall";
import { POST_SEND_PAN_OTP } from "constants/apis";

const usePostSendPanOTP = () => {
  const [data, error, isLoading, callPostSendPanOtp, setSuccessData, setError] =
  useAPICall(undefined, "");

  const defaultFallback = () => {
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postSendPanOtp = (body) => {
    const url = POST_SEND_PAN_OTP;
    var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log("send PAN OTP API: ", body);
    callPostSendPanOtp({
      url,
      method: "POST",
      statusObj,
      config,
      defaultFallback,
      body,
    });
  };

  return [data, error, isLoading, postSendPanOtp, setSuccessData, setError];
};

export default usePostSendPanOTP;
