import useAPICall from "hooks/useAPICall";
import { POST_WORK_ORDER_URL } from "constants/apis";

const usePostWorkOrder = () => {
  const [workOrderData, workOrderError, workOrderIsLoading, callPostWorkOrder, setSuccessworkOrderData, setworkOrderError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setworkOrderError(en.something_went_wrong);
    setSuccessworkOrderData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const workOrderData = res;
        if (workOrderData && typeof workOrderData === "object") {
          setSuccessworkOrderData(workOrderData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const postWorkOrder = (body) => {
    const url = POST_WORK_ORDER_URL;
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log(body)
    // console.log('postWorkOrder running')
    callPostWorkOrder({
      url,
      method : "POST",
      statusObj,
      
      config,
      defaultFallback,
      body
    });
  };
  return [workOrderData, workOrderError, workOrderIsLoading, postWorkOrder, setSuccessworkOrderData, setworkOrderError];
};

export default usePostWorkOrder;
