import React, { useEffect, useState } from 'react'
import AssetsHeading from '../AssetsHeading'
import WhiteButton from 'components/Button/WhiteButton'
import info1 from "assets/images/incomeTax/info1.svg";
import EditArt from './Edit/EditArt';
import { useLocation } from 'react-router-dom';
import useGetArtDetails from '../data/useGetArtDetails';

const FurnitureDetailedView = () => {
  const location = useLocation();
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const itemName = location.state?.itemName;
  const [isReload, setIsRelaod]= useState(false)
  const [getArtData, getArtError, getArtIsLoading, getArt] =
  useGetArtDetails();
  const [detailData, setDetailData]=useState({})
  useEffect(()=>{
    getArt(`${assetId}/?id=${itemId}`)
  },[])

  useEffect(()=>{
   if(isReload){
    getArt(`${assetId}/?id=${itemId}`)
    setIsRelaod(false)
   }
  },[isReload])
  useEffect(()=>{
    if(getArtData?.data){
      setDetailData(getArtData.data)
      console.log("getArtData", getArtData)
    }
  },[getArtData])
  const handleViewPdf =()=>{
    if(detailData.invoice){
      window.open(detailData.invoice, "_blank")
    }
  }
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
    <AssetsHeading asset={"Personal Asset"} asset_type={"Art & Collectibles"} asset_detail={itemName} assetId={assetId}/>
    <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
      <div className="flex justify-between items-center">
        <h4 className="text-darkGray font-[500] text-[1rem] m-0">
        Painting Value 
        </h4>
        <EditArt editValue="artValue" detailData={detailData} setIsRelaod={setIsRelaod}/>
      </div>
      <div className="flex justify-between mt-[1rem]">
        <div className="">
          <h4 className="text-customPurple font-[500] text-[1.7rem] ">
            ₹ {detailData?.total_furniture_art_value || 0}
          </h4>
          <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
            <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
              {detailData?.assets_ratio || 0} % of Total Assets (₹ {detailData?.total_assets_value || 0})
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem] flex justify-between">
       <div className='border bg-white rounded-xl p-[1rem] w-[46.5%]'>
          <div className='flex justify-between'>
             <div>
                <p className='m-0 text-[#334155] font-[500]'>Item Details</p>
             </div>
             <div className='flex gap-2.5'>
               <EditArt editValue="artDetails" detailData={detailData} setIsRelaod={setIsRelaod}/>
             </div>
          </div>
             <div className='mt-2.5 flex flex-col gap-1.5'>
               <p className='text-[#64748B] m-0 font-[500] text-[0.8rem]'>Type</p>
               <p className='text-[#334155] m-0 text-[1.1rem] font-[500]'>{detailData.item_type}</p>
             </div>
             <div className='mt-2.5 flex flex-col gap-1.5'>
               <p className='text-[#64748B] m-0 font-[500] text-[0.8rem]'>Item Name</p>
               <p className='text-[#334155] m-0 text-[1.1rem] font-[500]'>{detailData.item_name}</p>
             </div>
             <div className='mt-2.5 flex flex-col gap-1.5'>
               <p className='text-[#64748B] m-0 font-[500] text-[0.8rem]'>Artist/Creator</p>
               <p className='text-[#334155] m-0 text-[1.1rem] font-[500]'>{detailData.artist_creator}</p>
             </div>
             <div className='mt-2.5 flex flex-col gap-1.5'>
               <p className='text-[#64748B] m-0 font-[500] text-[0.8rem]'>Description</p>
               <p className='text-[#64748B] m-0 text-[o.9rem]'>{detailData.description}</p>
             </div>
       </div>
        <div className='border bg-white rounded-xl p-[1rem] w-[52%] h-[180px]'>
        <div className="flex justify-between">
            <div>
              <p className="m-0 text-[#334155] font-[500]">Purchase Details</p>
            </div>
            <div className="flex gap-2.5">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View Invoice"
                onClick={handleViewPdf}
              />
              <EditArt editValue="artPurchaseDetails" detailData={detailData} setIsRelaod={setIsRelaod}/>
            </div>
          </div>
          <div className="flex gap-16 mt-2.5">
            <div className="w-[30%] flex flex-col gap-1.5'">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Purchase Date
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.purchase_date}
              </p>
            </div>
            <div className='flex flex-col gap-1.5'>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Purchase Price
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.purchase_price} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
          </div>
          <div className="flex gap-16 mt-2.5">
            <div className="w-[30%] flex flex-col gap-1.5'">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Estimated Sale Value
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.estimated_sale_value} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
            <div className='flex flex-col gap-1.5'>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Loan Taken?
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">{detailData.loan_taken===true ? "Yes": "No"}</p>
            </div>
          </div>
       </div>
    </div>
    <div className='mt-3'>
       <button className='text-[#334155] bg-[#F1F5F9] px-3.5 py-2 rounded-md font-[600] text-[0.9rem]'>Back To Assets</button>
    </div>
</div>
  )
}

export default FurnitureDetailedView