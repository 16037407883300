import React from "react";

// images
import SuccessIcon from "assets/images/incomeTax/SuccessIcon.png";
import { useNavigate } from "react-router-dom";

export default function PanSuccessDialog(props) {

  const handleLetsGo = () => {
    console.log("Navigating to /income-tax");
    // navigate("/income-tax");
    // navigate("/income-tax", { replace: true });
    // window.location.reload();
    window.location.href = "/income-tax";
  };

  return (
    <div className="">
      <div className="flex justify-center items-center">
        <img src={SuccessIcon} alt="otp icon" className="mb-4 w-16 h-16" />
      </div>
      <h2 className="text-center font-[700] leading-[2.1rem] text-[1.4rem] ">
        PAN Linked Successfully
      </h2>
      <p className="text-center text-gray-500 font-[400] text-[0.9rem] leading-[1.5rem] flex ">
        Check your income tax profile to view your details
      </p>

      <button
        onClick={handleLetsGo}
        className="mt-2 purpleBtn p-2 rounded  w-[100%] text-white"
      >
        File Returns and E-Verify
      </button>
    </div>
  );
}
