import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import datePicker from "assets/images/incomeTax/datePicker.png";
import deletePic from "assets/images/incomeTax/removeCapital.png";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import useGetCapitalGains from "./Data/useGetCapitalGains";
import { useNavigate, useParams } from "react-router-dom";
import usePostCapitalGains from "./Data/usePostCapitalGains";
import usePatchCapitalGains from "./Data/usePatchCapitalGains";
import { toast } from "react-toastify";
import { INCOME_TAX_SUMMARY_USER_DETAILS } from "constants/routes";
import Loader from "components/Loader/Loader";
import InputField from "components/input/InputField";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

const TaxSummaryCapitalGains = forwardRef(({ isDisabled }, ref) => {
  const { income_tax_return_id } = useParams();
  const navigate = useNavigate();
  const path = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
    ":detailType",
    "business"
  ).replace(":income_tax_return_id", income_tax_return_id);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [termType, setTermType] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedStt, setIsCheckedStt] = useState(false);

  const handleTermTypeChange = (event) => {
    const value = event.target.value;
    setTermType(value);
  };

  const [
    capitalGainsData,
    capitalGainsError,
    capitalGainsIsLoading,
    postCapitalGainsData,
  ] = usePostCapitalGains();
  const [getData, getError, getIsLoading, getCapitalGainsData] =
    useGetCapitalGains();
  const [patchData, patchError, patchIsLoading, patchCapitalGainsData] =
    usePatchCapitalGains();

  const [buyers, setBuyers] = useState([
    { name: "", pan: "", aadhar: "", percentage_of_ownership: "" },
  ]);

  const addBuyer = () => {
    setBuyers([
      ...buyers,
      { name: "", pan: "", aadhar: "", percentage_of_ownership: "" },
    ]);
  };

  const removeBuyer = (buyerIndex) => {
    setBuyers(buyers.filter((_, index) => index !== buyerIndex));
  };

  const defaultValue = {
    gain: [
      {
        asset_type: 1,
        house: {
          ownership_percent: "",
          purchase_date: "",
          sale_date: "",
          held_for_no_of_days: "",
          sale_consideration: "",
          purchase_price: "",
          transfer_expense: "",
          improvement_expense: "",
          description: "",
          date: "",
          amount: "",
          gain_or_loss: "",
          property_door_no: "",
          property_area: "",
          property_city: "",
          property_pin: "",
          property_state: "",
          property_country: "",
          buyer_details: buyers,
          term_type: 1,
        },
        shares: {
          stt_paid: "",
          isn_code: "",
          fair_value_per_unit: "",
          sale_price_per_unit: "",
          purchase_price_per_unit: "",
          no_of_units: "",
          date: "",
          gain_or_loss: "",
          purchase_price: "",
          transfer_expense: "",
          purchase_date: "",
          sale_date: "",
          held_for_no_of_days: "",
          term_type: "",
        },
      },
    ],
  };

  const form = useForm({
    defaultValues: defaultValue,
  });

  const { register, handleSubmit, formState, setValue, reset, control, watch } =
    form;
  const { errors } = formState;
  const { fields, append } = useFieldArray({
    name: "gain",
    control,
  });

  const watchFieldArray = useWatch({
    control,
    name: "gain",
  });

  let controlledFields = fields.map((field, index) => {
    // console.log("field:", field.buyer_details);
    return {
      ...field,
      asset_type: Number(watchFieldArray[index]?.asset_type),
      term_type: Number(watchFieldArray[index]?.term_type),
    };
  });

  useEffect(() => {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        asset_type: Number(watchFieldArray[index]?.asset_type),
      };
    });
  }, [watchFieldArray]);

  useEffect(() => {
    getCapitalGainsData(income_tax_return_id);
  }, []);

  useEffect(() => {
    if (capitalGainsData) {
      showSuccessToast("Capital Gains Data uploaded");
      if (isFormSubmitted) {
        navigate(path);
      }
    }
    if (patchData) {
      showSuccessToast("Capital Gains Data updated");
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [capitalGainsData, patchData]);

  useEffect(() => {
    if (capitalGainsError) {
      showErrorToast("Data not uploaded");
    }
    if (patchError) {
      showErrorToast("Data not updated");
    }
  }, [patchError, capitalGainsError]);

  useEffect(() => {
    if (getData && getData.data?.total_count >= 1) {
      const totalCount = getData?.data?.total_count || 0;
      const capitalData = getData.data?.results || [];
      const initializedData = Array.from({ length: totalCount }, (_, index) => {
        const capitalGain = capitalData[index] || defaultValue;

        if (capitalGain.asset_type === 1) {
          return {
            id: capitalGain.id,
            asset_type: capitalGain.asset_type,
            ownership_percent: capitalGain.ownership_percent,
            purchase_date: capitalGain.purchase_date,
            sale_date: capitalGain.sale_date,
            held_for_no_of_days: capitalGain.held_for_no_of_days,
            sale_consideration: capitalGain.sale_consideration,
            purchase_price: capitalGain.purchase_price,
            transfer_expense: capitalGain.transfer_expense,
            improvement_expense: capitalGain.improvement_expense,
            description: capitalGain.description,
            date: capitalGain.date,
            amount: capitalGain.amount,
            gain_or_loss: capitalGain.gain_or_loss,
            property_door_no: capitalGain.property_door_no,
            property_area: capitalGain.property_area,
            property_city: capitalGain.property_city,
            property_pin: capitalGain.property_pin,
            property_state: capitalGain.property_state,
            property_country: capitalGain.property_country,
            term_type: capitalGain.term_type,
            buyer_details: capitalGain.buyer_details,
          };
        } else if (capitalGain.asset_type === 2) {
          return {
            id: capitalGain.id,
            asset_type: capitalGain.asset_type,
            purchase_date: capitalGain.purchase_date,
            sale_date: capitalGain.sale_date,
            held_for_no_of_days: capitalGain.held_for_no_of_days,
            transfer_expense: capitalGain.transfer_expense,
            date: capitalGain.date,
            gain_or_loss: capitalGain.gain_or_loss,
            term_type: capitalGain.term_type,
            stt_paid: capitalGain.stt_paid,
            isn_code: capitalGain.isn_code,
            fair_value_per_unit: capitalGain.fair_value_per_unit,
            sale_price_per_unit: capitalGain.sale_price_per_unit,
            purchase_price_per_unit: capitalGain.purchase_price_per_unit,
            no_of_units: capitalGain.no_of_units,
          };
        }
      });
      reset({ gain: initializedData });
    } else {
      reset(defaultValue);
    }
  }, [getData]);

  useImperativeHandle(ref, () => ({
    addCapitalGainOrLoss() {
      append({ ...defaultValue.gain[0] });
    },
    handleSubmit: () => handleSubmit(onSubmit)(),
  }));

  const sendData = (data, flag) => {
    let formattedData = data.gain.map((gain) => {
      let { id, asset_type } = gain;
      if (id) {
        flag = true;
      }

      if (asset_type == 1) {
        return {
          ...(id !== undefined && { id }),
          asset_type: 1,
          ownership_percent: gain.ownership_percent,
          purchase_date: gain.purchase_date,
          sale_date: gain.sale_date,
          date: gain.date,
          held_for_no_of_days: gain.held_for_no_of_days,
          term_type: gain.term_type,
          sale_consideration: gain.sale_consideration,
          purchase_price: gain.purchase_price,
          amount: gain.amount,
          transfer_expense: gain.transfer_expense,
          improvement_expense: gain.improvement_expense,
          description: gain.description,
          gain_or_loss: gain.gain_or_loss,
          property_door_no: gain.property_door_no,
          property_area: gain.property_area,
          property_city: gain.property_city,
          property_pin: gain.property_pin,
          property_state: gain.property_state,
          property_country: gain.property_country,
          buyer_details: gain.buyer_details.map((buyer) => ({
            name: buyer.name,
            pan: buyer.pan,
            aadhar: buyer.aadhar,
            percentage_of_ownership: buyer.percentage_of_ownership,
          })),
        };
      }
      if (asset_type == 2) {
        return {
          ...(id !== undefined && { id }),
          asset_type: 2,
          purchase_date: gain.purchase_date,
          sale_date: gain.sale_date,
          held_for_no_of_days: gain.held_for_no_of_days,
          term_type: gain.term_type,
          transfer_expense: gain.transfer_expense,
          gain_or_loss: gain.gain_or_loss,
          stt_paid: gain.stt_paid,
          isn_code: gain.term_type == 2 ? gain.isn_code : null,
          date: gain.term_type == 2 ? gain.date : null,
          fair_value_per_unit:
            gain.term_type == 2 ? gain.fair_value_per_unit : null,
          sale_price_per_unit: gain.sale_price_per_unit,
          purchase_price_per_unit: gain.purchase_price_per_unit,
          no_of_units: gain.term_type == 1 ? gain.no_of_units : null,
        };
      }
    });

    return { formattedData, flag };
  };

  const onSubmit = (data) => {
    setIsFormSubmitted(true);
    let flagForUpdate = false;
    let { formattedData, flag } = sendData(data, flagForUpdate);
    if (flag) {
      // console.log("formattedData patch:", formattedData[0].buyer_details[0])
      patchCapitalGainsData(income_tax_return_id, formattedData);
    } else {
      postCapitalGainsData(income_tax_return_id, formattedData);
    }
  };

  const removeProperty = (index) => {
    const updatedFields = [...watchFieldArray];
    updatedFields.splice(index, 1);
    reset({ gain: updatedFields });
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {controlledFields.map((field, index) => {
            return (
              <div
                index={index}
                key={field.id}
                field={field}
                className="border border-gray-200 rounded mt-4 p-[2%]"
              >
                <div className="mt-[1.5rem] flex flex-col md:flex-row md:items-center md:justify-between md:h-[50px] gap-1">
                  <div className="hidden md:block">
                    <h6 className="text-[#334155]">Asset Details</h6>
                  </div>
                  <div className="md:hidden flex justify-between items-center">
                    <div className="">
                      <h6 className="text-[#334155]">Asset Details</h6>
                    </div>
                    <div>
                      {index > 0 ? (
                        <button
                          type="button"
                          disabled={isDisabled || false}
                          onClick={() => removeProperty(index)}
                          className="px-2.5 py-2 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center "
                        >
                          <img
                            src={deletePic}
                            alt="deletePic"
                            className="mr-1.5 size-4"
                          />
                          Remove
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex h-[50px] md:h-[100%] items-center md:justify-evenly ${
                      index > 0 ? "w-[100%] md:w-[48%]" : "w-[100%] md:w-[48%]"
                    }`}
                  >
                    <div className="bg-[#F1F5F9] h-[87%] flex items-center w-[100%] md:w-[70%] justify-evenly rounded px-1">
                      <span
                        onClick={() => setValue(`gain.${index}.asset_type`, 1)}
                        className={`font-[500] text-[0.71rem] md:text-[0.8rem]  px-2.5 py-1.5 rounded leading-[1rem] cursor-pointer ${
                          field.asset_type === 1
                            ? "text-[#FFFFFF] bg-[#334155]"
                            : "text-[#64748B]"
                        }`}
                      >
                        <InputField
                          type="radio"
                          name={`gain.${index}.asset_type`}
                          value="1"
                          checked={field.asset_type === 1}
                          className="hidden"
                          register={register}
                        />
                        House Property
                      </span>
                      <span
                        onClick={() => setValue(`gain.${index}.asset_type`, 2)}
                        className={`font-[500] text-[0.8rem] px-2.5 py-1.5 rounded leading-[1rem] cursor-pointer ${
                          field.asset_type === 2
                            ? "text-[#FFFFFF] bg-[#334155]"
                            : "text-[#64748B]"
                        }`}
                      >
                        <InputField
                          type="radio"
                          name={`gain.${index}.asset_type`}
                          value="2"
                          checked={field.asset_type === 2}
                          className="hidden"
                          register={register}
                        />
                        Listed Shares/Mutual Funds
                      </span>
                    </div>
                    {index > 0 ? (
                      ""
                    ) : (
                      <div>
                        <button
                          type="button"
                          className=" md:p-[1%] px-3 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem] hidden md:block"
                        >
                          Calculator
                        </button>
                      </div>
                    )}
                    <div className="">
                      {index > 0 ? (
                        <button
                          type="button"
                          onClick={() => removeProperty(index)}
                          className="px-2.5 py-2 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] md:flex md:items-center hidden"
                        >
                          <img
                            src={deletePic}
                            alt="deletePic"
                            className="mr-1.5 size-4"
                          />
                          Remove
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {field.asset_type === 1 ? (
                  <>
                    <div className="flex mt-4 w-full justify-start h-[37px] items-center">
                      <div className="h-[100%] flex items-center  mr-2">
                        <h6 className="text-[#64748B] whitespace-nowrap">
                          Percentage of Ownership
                        </h6>
                      </div>
                      <div className="  h-[100%] flex items-left justify-between border-[#94A3B8] border-1 rounded sm:w-[80px]">
                        <InputField
                          type="number"
                          className="  flex items-center w-[60px] sm:w-[60px] px-2 py-1 text-[#334155] text-[0.8rem] font-[400] focus:outline-none"
                          name={`gain.${index}.ownership_percent`}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "Percentage of Ownership is required",
                            },
                            min: {
                              value: 0,
                              message:
                                "Percentage of Ownership must be at least 0",
                            },
                            max: {
                              value: 100,
                              message:
                                "Percentage of Ownership cannot exceed 100",
                            },

                            validate: (value) =>
                              Number.isInteger(Number(value)) ||
                              "Percentage of Ownership must be a whole number",
                          }}
                          min={0} // Minimum value
                          max={100} // Maximum value
                          step={1} // Only allow whole numbers
                        />

                        <span className="bg-[#FAF8FC] flex items-center h-full text-[#64748B] w-[20px] justify-center rounded-r ">
                          %
                        </span>
                      </div>
                    </div>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.gain?.[index]?.ownership_percent?.message}
                    </p>

                    <div className="grid grid-cols-12  mt-1.5 ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                          Purchase Date
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple">
                          <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center ">
                            <img
                              src={datePicker}
                              alt="datePicker"
                              className="group-focus-within:text-customPurple"
                            />
                          </span>
                          <InputField
                            type="date"
                            placeholder="Select"
                            className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            name={`gain[${index}].purchase_date`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Purchase date is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.purchase_date?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Sale Date
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple">
                          <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                            <img src={datePicker} alt="datePicker" />
                          </span>
                          <InputField
                            type="date"
                            placeholder="Select"
                            className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            name={`gain[${index}].sale_date`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Sale date is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.sale_date?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Held For
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                          <span className="px-2 h-full text-[#64748B] md:w-[19%] flex items-center justify-center bg-[#F1F5F9] text-[14px] rounded-l-md group-focus-within:text-customPurple">
                            Days
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Days"
                            className="text-[#667085] w-[75%] py-[.3rem] px-[0.7rem] items-center focus:outline-none"
                            name={`gain[${index}].held_for_no_of_days`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Held for no of days is required",
                              },
                              min: {
                                value: 1, // Optional: Set minimum value if needed
                                message: "Held for at least 1 day",
                              },
                              validate: (value) => {
                                const intValue = Number(value);
                                return (
                                  Number.isInteger(intValue) ||
                                  "Only whole numbers are allowed"
                                ); // Check for whole number
                              },
                            }}
                            min={1} // Set minimum value to prevent negative or zero if desired
                            step={1} // Allow only whole numbers
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.held_for_no_of_days?.message}
                        </p>
                      </div>
                    </div>
                    {/* /house term type */}
                    <div className="flex justify-start items-center gap-2 mt-2.5">
                      <label className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center">
                        <InputField
                          type="radio"
                          value="1"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                          onChange={() =>
                            setValue(`gain.${index}.term_type`, 1)
                          }
                          checked={field.term_type == 1}
                          name={`gain.${index}.term_type`}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          }}
                        />
                        Short Term
                      </label>
                      <label className="col-span-3 font-[500] text-[.9rem] leading-[1rem] hover:cursor-pointer text-[#9065B4] flex items-center">
                        <InputField
                          type="radio"
                          value="2"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                          onChange={() =>
                            setValue(`gain.${index}.term_type`, 2)
                          }
                          checked={field.term_type == 2}
                          name={`gain.${index}.term_type`}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          }}
                        />
                        Long Term
                      </label>
                      <p className="text-red-600 font-[500] text-[0.8rem]">
                        {errors.gain?.[index]?.term_type?.message}
                      </p>
                    </div>

                    <hr className="text-[#E2E8F0] border mt-4" />
                    <div className="flex items-center justify-between mt-2">
                      <h6 className="mt-3 text-[#334155]">Sale Details</h6>
                      {index > 0 ? (
                        <button
                          type="button"
                          className=" md:p-[1%] px-3 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem] ml:auto"
                        >
                          Calculator
                        </button>
                      ) : (
                        <button
                          type="button"
                          className=" md:p-[1%] px-3 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem] ml:auto md:hidden"
                        >
                          Calculator
                        </button>
                      )}
                    </div>
                    <div className="grid grid-cols-12  ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                          Sale Consideration
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple  h-[44px] md:h-[37px]">
                          <span className="px-2  h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].sale_consideration`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Sale Consideration is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.sale_consideration?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Purchase Price
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <span className="px-2 h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                            name={`gain[${index}].purchase_price`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Purchase Price is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.purchase_price?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                          Transfer Expense
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <span className="px-2  h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                            name={`gain[${index}].transfer_expense`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Transfer Expense is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.transfer_expense?.message}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <label className="flex items-center cursor-pointer">
                        <div className="relative">
                          <InputField
                            // disabled={isDisabled || false}
                            type="checkbox"
                            className="sr-only"
                            name={`gain[${index}].improvement_expense`}
                            register={register}
                            // {...register(
                            //   `gain[${index}].improvement_expense`,
                            //   {}
                            // )}
                            onChange={(e) => {
                              setValue(
                                `gain[${index}].improvement_expense`,
                                e.target.checked
                              );
                            }}
                          />
                          <div
                            className={`block w-9 h-5 rounded-full ${
                              watch(`gain[${index}].improvement_expense`)
                                ? "border-[0.6px] border-[#9065B4]"
                                : "border-[0.6px] border-[#94A3B8]"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-1 top-1 w-3 h-3 rounded-full transition-transform transform ${
                              watch(`gain[${index}].improvement_expense`)
                                ? "translate-x-4 bg-[#9065B4]"
                                : "bg-[#94A3B8]"
                            }`}
                          ></div>
                        </div>
                        <span
                          className={`ml-3 text-[16px] font-[500] ${
                            watch(`gain[${index}].improvement_expense`)
                              ? "text-[#9065B4]"
                              : "text-[#94A3B8]"
                          }`}
                        >
                          Improvement Expense
                        </span>
                      </label>
                      {/* {errors.gain?.[index]?.improvement_expense && (
                      <p className="text-red-600 font-[500] text-[0.8rem]">
                        {errors.gain[index].improvement_expense.message}
                      </p>
                    )} */}
                    </div>
                    <div className="grid grid-cols-12  ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          Description
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="text"
                            placeholder="Enter Description"
                            className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded  focus:outline-none "
                            name={`gain[${index}].description`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Description is required",
                              },
                              pattern: {
                                value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                                message: "*Special characters are not allowed", // Error message if pattern is violated
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.description?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Date
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple ">
                          <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                            <img src={datePicker} alt="datePicker" />
                          </span>
                          <InputField
                            type="date"
                            placeholder="Select"
                            className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            name={`gain[${index}].date`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Date is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.date?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          Amount
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <span className="px-2 h-full text-[#667085] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].amount`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Amount is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.amount?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#9065B4] text-[#9065B4] group-focus-within:text-customPurple">
                          Gain/Loss
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <span className="px-2  h-full text-[#9065B4] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].gain_or_loss`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Gain/Loss is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.gain_or_loss?.message}
                        </p>
                      </div>
                    </div>
                    <hr className="text-[#E2E8F0] border mt-4 " />
                    <div className="flex flex-row md:flex-row items-left justify-between mt-2">
                      <h6 className="mt-3 text-[#334155] whitespace-nowrap">
                        Property Details
                      </h6>
                      <button
                        type="button"
                        className=" md:p-[1%] px-1 py-0.9 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[0.7rem] whitespace-nowrap hover:cursor-pointer"
                      >
                        Get Address From Tax Profile
                      </button>
                    </div>
                    <div className="grid grid-cols-12 ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          Flat/Door/Block No.
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="text"
                            placeholder="Enter Flat/Door/Block No"
                            className="text-[#667085] w-[100%] md:w-[100%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].property_door_no`}
                            register={register}
                            validation={{
                              required: "*Flat No is required",
                              pattern: {
                                value: /^[a-zA-Z0-9-\/]+$/, // Allows letters, numbers, dashes, and slashes
                                message: "Please enter a valid flat number",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.property_door_no?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          Area
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="text"
                            placeholder="Enter Area"
                            className="text-[#667085] w-[100%] md:w-[100%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].property_area`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Area is required",
                              },
                              pattern: {
                                value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                                message: "*Special characters are not allowed", // Error message if pattern is violated
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.property_area?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          City
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="text"
                            placeholder="Enter City"
                            className="text-[#667085] w-[100%] md:w-[100%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].property_city`}
                            register={register}
                            validation={{
                              required: "*City is required",
                              pattern: {
                                value: /^[a-zA-Z\s]*$/, // Allows letters, numbers, and spaces
                                message:
                                  "*Numbers and Special characters are not allowed", // Error message if pattern is violated
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.property_city?.message}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          PIN Code.
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="text"
                            placeholder="Enter PinCode"
                            className="text-[#667085] w-[100%] md:w-[100%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].property_pin`}
                            register={register}
                            validation={{
                              required: "*PIN is required",
                              pattern: {
                                value: /^[1-9][0-9]{5}$/, // Ensures 6 digits, first digit 1-9
                                message:
                                  "Please enter a valid 6-digit PIN code that does not start with 0",
                              },
                            }}
                            maxLength={6}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.property_pin?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          State
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="select"
                            className="text-[#667085] w-[100%] md:w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                            name={`gain[${index}].property_state`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "State is required",
                              },
                            }}
                            options={[
                              { value: "", label: "Select" },
                              {
                                value: "Andhra Pradesh",
                                label: "Andhra Pradesh",
                              },
                              {
                                value: "Arunachal Pradesh",
                                label: "Arunachal Pradesh",
                              },
                              { value: "Assam", label: "Assam" },
                              { value: "Bihar", label: "Bihar" },
                              { value: "Chhattisgarh", label: "Chhattisgarh" },
                              { value: "Goa", label: "Goa" },
                              { value: "Gujarat", label: "Gujarat" },
                              { value: "Haryana", label: "Haryana" },
                              {
                                value: "Himachal Pradesh",
                                label: "Himachal Pradesh",
                              },
                              { value: "Jharkhand", label: "Jharkhand" },
                              { value: "Karnataka", label: "Karnataka" },
                              { value: "Kerala", label: "Kerala" },
                              {
                                value: "Madhya Pradesh",
                                label: "Madhya Pradesh",
                              },
                              { value: "Maharashtra", label: "Maharashtra" },
                              { value: "Manipur", label: "Manipur" },
                              { value: "Meghalaya", label: "Meghalaya" },
                              { value: "Mizoram", label: "Mizoram" },
                              { value: "Nagaland", label: "Nagaland" },
                              { value: "Odisha", label: "Odisha" },
                              { value: "Punjab", label: "Punjab" },
                              { value: "Rajasthan", label: "Rajasthan" },
                              { value: "Sikkim", label: "Sikkim" },
                              { value: "Tamil Nadu", label: "Tamil Nadu" },
                              { value: "Telangana", label: "Telangana" },
                              { value: "Tripura", label: "Tripura" },
                              {
                                value: "Uttar Pradesh",
                                label: "Uttar Pradesh",
                              },
                              { value: "Uttarakhand", label: "Uttarakhand" },
                              { value: "West Bengal", label: "West Bengal" },
                            ]}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.property_state?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                          Country
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                          <InputField
                            type="select"
                            className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                            name={`gain[${index}].property_country`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Country is required",
                              },
                            }}
                            options={[
                              { value: "", label: "Select" },
                              { value: "India", label: "India" },
                            ]}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.property_country?.message}
                        </p>
                      </div>
                    </div>
                    <hr className="text-[#E2E8F0] border mt-4 " />
                    <div className="flex items-center justify-between h-[50px]">
                      <h6 className="mt-[1.5rem] text-[#334155]">
                        Buyers Details
                      </h6>
                    </div>
                    {/* buyer details */}
                    <div
                      key={form.id}
                      className="grid grid-cols-12 md:mt-[-60px]"
                    >
                      {/* {console.log("buyer_details:'", buyers)} */}
                      {buyers.map((buyer, buyerIndex) => (
                        <>
                          {/* {console.log("buyers:", buyers)}
                        {console.log("controlled fields:", field.buyer_details)} */}
                          <div
                            key={buyerIndex}
                            className="col-span-12 flex justify-end items-center h-[100%] md:justify-end"
                          >
                            <div className="flex gap-[1rem] items-center w-full mt-4 md:w-auto ">
                              <div className="">
                                {buyerIndex > 0 ? (
                                  <button
                                    type="button"
                                    disabled={isDisabled || false}
                                    onClick={() => removeBuyer(buyerIndex)}
                                    className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                                  >
                                    <span className="mr-1.5">X</span>Remove
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>

                              <h6 className="text-[#64748B] md:mr-2 whitespace-nowrap">
                                Percentage of Ownership
                              </h6>
                              <div className="flex items-center justify-between border-[#94A3B8] border-1 rounded w-[100px] h-[90%]">
                                <InputField
                                  type="number"
                                  className="flex items-center text-[#334155] text-[0.8rem] font-[400] w-[80%] px-2 py-2 focus:outline-none"
                                  name={`gain[${index}].buyer_details[${buyerIndex}].percentage_of_ownership`}
                                  register={register}
                                  validation={{
                                    required: {
                                      value: true,
                                      message:
                                        "Percentage of Ownership is required",
                                    },
                                    min: {
                                      value: 0,
                                      message:
                                        "Percentage of Ownership must be at least 0",
                                    },
                                    max: {
                                      value: 100,
                                      message:
                                        "Percentage of Ownership cannot exceed 100",
                                    },
                                    validate: (value) =>
                                      Number.isInteger(Number(value)) ||
                                      "Percentage of Ownership must be a whole number",
                                  }}
                                  min={0} // Minimum value
                                  max={100} // Maximum value
                                  step={1} // Only allow whole numbers
                                />

                                <span className="bg-[#FAF8FC] flex items-center h-[40px] text-[#64748B] w-[45%] justify-center rounded-r ">
                                  %
                                </span>
                              </div>
                              <p className="text-red-600 font-[500] text-[0.8rem]">
                                {
                                  errors.gain?.[index]?.buyer_details?.[
                                    buyerIndex
                                  ]?.percentage_of_ownership?.message
                                }
                              </p>
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Name
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="text"
                                placeholder="Enter Name"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded  focus:outline-none "
                                name={`gain[${index}].buyer_details[${buyerIndex}].name`}
                                register={register}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Name is required",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                                    message:
                                      "*Numbers and special characters are not allowed", // Error message if pattern is violated
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.gain?.[index]?.buyer_details?.[
                                  buyerIndex
                                ]?.name?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              PAN
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="text"
                                placeholder="Enter PAN"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded  focus:outline-none "
                                name={`gain[${index}].buyer_details[${buyerIndex}].pan`}
                                register={register}
                                maxLength={10}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "PAN is required",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z0-9]{10}$/, // Allows exactly 10 letters or digits, no spaces or special characters
                                    message:
                                      "*PAN must be exactly 10 alphanumeric characters", // Error message if pattern is violated
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.gain?.[index]?.buyer_details?.[
                                  buyerIndex
                                ]?.pan?.message
                              }
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ml-[-5px]">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              Aadhar
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                type="text"
                                placeholder="Enter Aadhar"
                                className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded  focus:outline-none "
                                name={`gain[${index}].buyer_details[${buyerIndex}].aadhar`}
                                register={register}
                                maxLength={12}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Aadhar is required",
                                  },
                                  pattern: {
                                    value: /^[0-9]{12}$/, // Allows exactly 12 digits, no letters or special characters
                                    message: "*Must be exactly 12 digits", // Error message if pattern is violated
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.gain?.[index]?.buyer_details?.[
                                  buyerIndex
                                ]?.aadhar?.message
                              }
                            </p>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="flex justify-between mt-3">
                      <button
                        disabled={isDisabled || false}
                        type="button"
                        onClick={addBuyer}
                        className=" md:p-[1%] px-3 py-1 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] hover:cursor-pointer"
                      >
                        <span className="text-[1.3rem] mr-1.5">+</span>
                        Add Buyer
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="grid grid-cols-12  mt-1.5 ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Purchase Date
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple">
                          <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                            <img src={datePicker} alt="datePicker" />
                          </span>
                          <InputField
                            type="date"
                            placeholder="Select"
                            className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            name={`gain[${index}].purchase_date`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Purchase date is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.purchase_date?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Sale Date
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple">
                          <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                            <img src={datePicker} alt="datePicker" />
                          </span>
                          <InputField
                            type="date"
                            placeholder="Select"
                            className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            name={`gain[${index}].sale_date`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Sale date is required",
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.sale_date?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Held For
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                          <span className="px-2.5 text-[#64748B] h-full md:w-[19.5%] flex items-center justify-center bg-[#F1F5F9] text-[14px] rounded-l-md group-focus-within:text-customPurple">
                            Days
                          </span>

                          <InputField
                            type="number"
                            placeholder="Enter Days"
                            className="text-[#667085] w-[100%] md:w-[83%] py-[.3rem] rounded px-1 placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                            name={`gain[${index}].held_for_no_of_days`}
                            register={register}
                            min={0} // Optional: ensures that negative numbers aren't accepted
                            pattern="^[0-9]*$" // Regex pattern to allow only whole numbers
                            validation={{
                              required: {
                                value: true,
                                message: "Held for number of days is required",
                              },
                              validate: (value) => {
                                const isWholeNumber = /^\d+$/.test(value);
                                return (
                                  isWholeNumber ||
                                  "Only whole numbers are allowed"
                                );
                              },
                            }}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.held_for_no_of_days?.message}
                        </p>
                      </div>
                    </div>
                    {/* term type mutual */}
                    <div className="flex justify-start items-center gap-2 mt-2.5">
                      <label className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center">
                        <InputField
                          type="radio"
                          value="1"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                          onChange={() =>
                            setValue(`gain.${index}.term_type`, 1)
                          }
                          checked={field.term_type == 1}
                          name={`gain.${index}.term_type`}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          }}
                        />
                        Short Term
                      </label>
                      <label className="col-span-3 font-[500] text-[.9rem] leading-[1rem] hover:cursor-pointer text-[#9065B4] flex items-center">
                        <InputField
                          type="radio"
                          value="2"
                          className="ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer"
                          onChange={() =>
                            setValue(`gain.${index}.term_type`, 2)
                          }
                          checked={field.term_type == 2}
                          name={`gain.${index}.term_type`}
                          register={register}
                          validation={{
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          }}
                        />
                        Long Term
                      </label>
                    </div>
                    <hr className="text-[#E2E8F0] border mt-4" />
                    <div className="flex items-center justify-between mt-2">
                      <h6 className="mt-3 text-[#334155]">Fund Details</h6>
                      {index > 0 ? (
                        <button
                          type="button"
                          className=" md:p-[1%] px-3 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem]"
                        >
                          Calculator
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="grid grid-cols-12  ml-[-2px]">
                      {termType == "2" || field.term_type == 2 ? (
                        <>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1 ">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                              ISIN Code
                            </h6>
                            <div className="mt-[1.1rem] flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <InputField
                                type="text"
                                placeholder="Enter ISIN Code"
                                className="text-[#667085] w-[100%] md:w-[100%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                name={`gain[${index}].isn_code`}
                                register={register}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "ISIN Code is required",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z0-9]{12}$/, // Ensures exactly 12 alphanumeric characters (no spaces)
                                    message:
                                      "*ISIN Code must be exactly 12 alphanumeric characters",
                                  },
                                  validate: (value) =>
                                    value.length === 12 ||
                                    "ISIN Code must be exactly 12 characters", // Additional length validation
                                }}
                                maxLength={12} // Limits input to 12 characters
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.gain?.[index]?.isn_code?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Date
                            </h6>
                            <div className="mt-[1.1rem] flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%]  focus-within:border-customPurple">
                              <span className="px-2.5 text-[#64748B] h-[37px] md:w-[16%] flex items-center justify-center">
                                <img src={datePicker} alt="datePicker" />
                              </span>
                              <InputField
                                type="date"
                                placeholder="Select"
                                className="text-[#667085] w-[100%] md:w-[83%]  md:py-[.3rem] rounded px-1 md:px-1 py-[0.7rem] placeholder-[#334155] placeholder:text-[14px] items-center focus:outline-none"
                                name={`gain[${index}].date`}
                                register={register}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Date is required",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.gain?.[index]?.date?.message}
                            </p>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              Fair Value/Unit <br />
                              <span className="font-[500] text-[0.6rem] text-slate-400 group-focus-within:text-customPurple">
                                Value of Asset as on 31st Jan 2018
                              </span>
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <span className="px-2  text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <InputField
                                type="number"
                                placeholder="Enter Amount"
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                                name={`gain[${index}].fair_value_per_unit`}
                                register={register}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "Fair Value/Unit is required",
                                  },
                                  min: {
                                    value: 1, // Ensures number is greater than 0
                                    message:
                                      "Fair Value/Unit must be greater than 0",
                                  },
                                  validate: (value) => {
                                    const intValue = Number(value);
                                    return (
                                      Number.isInteger(intValue) ||
                                      "Only whole numbers are allowed"
                                    ); // Checks for whole number
                                  },
                                }}
                                min={1} // Prevents negative values or zero
                                step={1}
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {
                                errors.gain?.[index]?.fair_value_per_unit
                                  ?.message
                              }
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                              No of units
                            </h6>
                            <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                              <span className="px-2  h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                                Rs.
                              </span>
                              <InputField
                                type="number"
                                placeholder="Enter Amount"
                                className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                name={`gain[${index}].no_of_units`}
                                register={register}
                                validation={{
                                  required: {
                                    value: true,
                                    message: "No of units is required",
                                  },
                                  min: {
                                    value: 1, // Ensures number is greater than 0
                                    message:
                                      "Number of units must be greater than 0",
                                  },
                                  validate: (value) => {
                                    const intValue = Number(value);
                                    return (
                                      Number.isInteger(intValue) ||
                                      "Only whole numbers are allowed"
                                    ); // Checks for whole number
                                  },
                                }}
                                min={1} // Prevents negative values or zero
                                step={1} // Allows only whole numbers
                              />
                            </div>
                            <p className="text-red-600 font-[500] text-[0.8rem]">
                              {errors.gain?.[index]?.no_of_units?.message}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="grid grid-cols-12  ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Sale Price/Unit
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                          <span className="px-2  h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].sale_price_per_unit`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Sale Price/Unit is required",
                              },
                              min: {
                                value: 1, // Ensures number is greater than 0
                                message:
                                  "Sale Price/Unit must be greater than 0",
                              },
                              validate: (value) => {
                                const intValue = Number(value);
                                return (
                                  Number.isInteger(intValue) ||
                                  "Only whole numbers are allowed"
                                ); // Checks for whole number
                              },
                            }}
                            min={1} // Prevents negative values or zero
                            step={1} // Allows only whole numbers
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.sale_price_per_unit?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Purchase Price/Unit
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                          <span className="px-2  h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].purchase_price_per_unit`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Purchase Price/Unit is required",
                              },
                              min: {
                                value: 1, // Ensures number is greater than 0
                                message:
                                  "Purchase Price/Unit must be greater than 0",
                              },
                              validate: (value) => {
                                const intValue = Number(value);
                                return (
                                  Number.isInteger(intValue) ||
                                  "Only whole numbers are allowed"
                                ); // Checks for whole number
                              },
                            }}
                            min={1} // Prevents negative values or zero
                            step={1} // Allows only whole numbers
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {
                            errors.gain?.[index]?.purchase_price_per_unit
                              ?.message
                          }
                        </p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <label className="flex items-center cursor-pointer">
                        <div className="relative">
                          <InputField
                            type="checkbox"
                            className="sr-only"
                            name={`gain[${index}].stt_paid`}
                            register={register}
                            // {...register(`gain[${index}].stt_paid`, {})}
                            onChange={(e) => {
                              setValue(
                                `gain[${index}].stt_paid`,
                                e.target.checked
                              );
                            }}
                          />
                          <div
                            className={`block w-9 h-5 rounded-full ${
                              watch(`gain[${index}].stt_paid`)
                                ? "border-[0.6px] border-[#9065B4]"
                                : "border-[0.6px] border-[#94A3B8]"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-1 top-1 w-3 h-3 rounded-full transition-transform transform ${
                              watch(`gain[${index}].stt_paid`)
                                ? "translate-x-4 bg-[#9065B4]"
                                : "bg-[#94A3B8]"
                            }`}
                          />
                        </div>
                        <span
                          className={`ml-3 text-[16px] font-[500] ${
                            watch(`gain[${index}].stt_paid`)
                              ? "text-[#9065B4]"
                              : "text-[#94A3B8]"
                          }`}
                        >
                          STT Paid
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-12  ml-[-2px]">
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Transfer Expense
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                          <span className="px-2 h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].transfer_expense`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Transfer Expense is required",
                              },
                              min: {
                                value: 1, // Ensures number is greater than 0
                                message:
                                  "Transfer Expense must be greater than 0",
                              },
                              validate: (value) => {
                                const intValue = Number(value);
                                return (
                                  Number.isInteger(intValue) ||
                                  "Only whole numbers are allowed"
                                ); // Checks for whole number
                              },
                            }}
                            min={1} // Prevents negative values or zero
                            step={1}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.transfer_expense?.message}
                        </p>
                      </div>
                      <div className="group md:col-span-3 col-span-12 pt-3 pl-1  ">
                        <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#64748B] text-[#64748B] group-focus-within:text-customPurple">
                          Gain/Loss
                        </h6>
                        <div className="flex items-center border-[#94A3B8] border-1 rounded md:w-[95%] w-[100%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                          <span className="px-2  h-full text-[#64748B] bg-[#F1F5F9] md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
                            Rs.
                          </span>
                          <InputField
                            type="number"
                            placeholder="Enter Amount"
                            className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded  focus:outline-none"
                            name={`gain[${index}].gain_or_loss`}
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Gain/Loss is required",
                              },
                              min: {
                                value: 1, // Ensures number is greater than 0
                                message: "Gain/Loss must be greater than 0",
                              },
                              validate: (value) => {
                                const intValue = Number(value);
                                return (
                                  Number.isInteger(intValue) ||
                                  "Only whole numbers are allowed"
                                ); // Checks for whole number
                              },
                            }}
                            min={1} // Prevents negative values or zero
                            step={1}
                          />
                        </div>
                        <p className="text-red-600 font-[500] text-[0.8rem]">
                          {errors.gain?.[index]?.gain_or_loss?.message}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </form>
      </FormProvider>
    </div>
  );
});

export default TaxSummaryCapitalGains;
