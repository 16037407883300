import React, { useState, useEffect, useRef } from "react";

const OtpModal = ({ isOpen, closeModal, handleOtpSubmit, handleResend, otp_session_id, mobileNo }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(30);
  const inputRefs = useRef([]);

  // Countdown logic
  useEffect(() => {
    let countdown = null;
    if (isOpen && timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    }
    return () => clearTimeout(countdown);
  }, [timer, isOpen]);

  // Focus on next input when a digit is entered
  const handleOtpChange = (value, index) => {
    if (/^\d*$/.test(value)) { // Only allow numbers
      let otpCopy = [...otp];
      otpCopy[index] = value;
      setOtp(otpCopy);

      // Move to the next field automatically
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Move back to previous input on Backspace
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendOtp = () => {
    setTimer(30); // Reset the timer to 30 seconds
    setOtp(["", "", "", ""]); // Clear previous OTP
    inputRefs.current[0].focus(); // Focus on the first input
    // Logic to resend OTP
    handleResend();
  };

  const handleSubmit = () => {
    const completeOtp = otp.join("");
    if (completeOtp.length === otp.length) {
      handleOtpSubmit(completeOtp); // Submit OTP as a string
    } else {
      alert("Please enter a valid OTP."); // Simple validation for empty fields
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-[#FFFFFF]  md:px-24 md:py-28 px-6 py-10 w-[90%] sm:w-[70%]  md:w-[500px] rounded-lg flex justify-center items-center relative">
          <div className="w-[80%]">
          <button onClick={closeModal} className="absolute top-2 right-4 cursor-pointer font-[500]">x</button>
            <h6 className="m-0 text-[1.2rem] md:text-[1.4rem] font-[600] text-[#334155]"
            style={{ textAlign: window.innerWidth >= 768 ? "left" : "center" }}
            >
              Verify Mobile Number
            </h6>
            <p className="m-0 text-[#64748B] text-[0.7rem]"
            style={{ textAlign: window.innerWidth >= 768 ? "left" : "center" }}
            >
              We have sent an OTP to the registered mobile number {mobileNo}.
            </p>
            <div className="flex gap-2 mt-3 w-[100%]">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  placeholder="0"
                  className="border-[1px] border-[#94A3B8] w-[25%] md:h-14 text-center text-xl text-[#9065B4] font-[600] placeholder:text-[#D0D5DD] placeholder:text-xl rounded-md focus:outline-none shadow-sm"
                  ref={(el) => (inputRefs.current[index] = el)} // Assigning refs
                />
              ))}
            </div>
            <div className="flex justify-between items-center mb-4">
              <span className="text-[#94A3B8] text-[0.8rem]">
                {timer > 0 ? `Resend OTP in ${timer}s` : ""}
              </span>  
              {timer === 0 && (
                <button
                  onClick={handleResendOtp}
                  className="text-[#9065B4] text-[0.8rem]"
                >
                  Resend OTP
                </button>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className={`w-full py-1 rounded-md border-[1px] text-center text-[0.9rem] ${
                otp.every((digit) => digit !== "")
                  ? "bg-[#9065B4] border-[#9065B4] text-[#FFFFFF]" // Purple button
                  : "bg-[#94A3B8] border-[#94A3B8] text-[#FFFFFF]" // Gray button
              }`}
              disabled={otp.some((digit) => digit === "")} 
            >
              Confirm OTP
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default OtpModal;
