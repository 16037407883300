import React, { createContext, useContext, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import TaxSummarySalary from "./TaxSummarySalary";
import TaxSummaryRental from "./TaxSummaryRental";
import TaxSummaryFooter from "./TaxSummaryFooter";
import TaxSummaryCapitalGains from "./TaxSummaryCapitalGains";
import TaxSummaryBusiness from "./TaxSummaryBusiness";
import TaxSummaryAgriExempt from "./TaxSummaryAgriExempt";
import TaxSummaryOthers from "./TaxSummaryOthers";
import { INCOME_TAX_SUMMARY_URL, INCOME_TAX_SUMMARY_USER_DETAILS } from "constants/routes";
import downArrow from './../../../../assets/images/incomeTax/downArrow.svg';
import upArrow from './../../../../assets/images/incomeTax/upArrow.svg';
import previousBtn from "assets/images/incomeTax/previousBtn.svg";
import { ProfileContext } from "containers/privateRoutes/Profile/Layout";

export const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

const UserDetailsSummaryPage = () => {
  const { detailType, income_tax_return_id } = useParams();
  const {returnYear}= useContext(ProfileContext)

  const salaryRef = useRef();

  const rentalRef = useRef();

  const capitalRef = useRef();

  const businessRef = useRef();

  const agriExemptRef = useRef();

  const othersRef = useRef();

  let isDisabled=false;
  const permissionType = sessionStorage.getItem('permissionType');
  if (permissionType === 'Read') {
    isDisabled=true;
  } else if (permissionType === 'Edit') {
    isDisabled=false;
  } else if (permissionType === 'Download') {
    isDisabled=true;
  } else if (permissionType === 'Read & Download') {
    isDisabled=false;
  }

  const handleFormSubmit = () => {
    if (salaryRef.current) {
      salaryRef.current.handleSubmit();
    }
    if (rentalRef.current) {
      rentalRef.current.handleSubmit();
    }
    if (capitalRef.current) {
      capitalRef.current.handleSubmit();
    }
    if (businessRef.current) {
      businessRef.current.handleSubmit();
    }
    if (agriExemptRef.current) {
      agriExemptRef.current.handleSubmit();
    }
    if (othersRef.current) {
      othersRef.current.handleSubmit();
    }
  };

  const callSalaryAddEmployee = () => {
    if (salaryRef.current) {
      salaryRef.current.salaryAddEmployee(); // Call the exposed child function
    }
  };

  const callRentalAddProperty = () => {
    if (rentalRef.current) {
      rentalRef.current.rentalAddProperty(); // Call the exposed child function
    }
  };

  const callCapitalGainOrLoss = () => {
    if (capitalRef.current) {
      capitalRef.current.addCapitalGainOrLoss(); // Call the exposed child function
    }
  };

  const callAddBusiness = () => {
    if (businessRef.current) {
      businessRef.current.addBusiness(); // Call the exposed child function
    }
  };

  let displayText = "";
  switch (detailType) {
    case "salary":
      displayText = "Salary";
      break;
    case "rental":
      displayText = "Rental";
      break;
    case "capital-gains":
      displayText = "Capital Gains";
      break;
    case "business":
      displayText = "Business";
      break;
    case "agri-exmpt":
      displayText = "Agri & Exmpt";
      break;
    case "others":
      displayText = "Others";
      break;
    default:
      displayText = "Unknown";
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Hook to navigate programmatically

  const pathToComponentMap = {
    "salary": "Salary Income",
    "rental": "Rental Income",
    "business": "Business Income",
    "agri-exmpt": "Agriculture & Exempt",
    "others": "Other Income",
    "capital-gains": "Capital Gains Income",
  };

  const currentComponent =
    Object.keys(pathToComponentMap).find((key) =>
      location.pathname.includes(key)
    ) || "Salary"; // Default to 'Salary' if no match

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleComponentClick = (path) => {
    navigate(
      INCOME_TAX_SUMMARY_USER_DETAILS.replace(
        ":income_tax_return_id",
        income_tax_return_id
      ).replace(":detailType", path)
    ); // Update with your base path
    setDropdownOpen(false);
  }; 

  const components = [
    { name: "Other Income", path: "others" },
    { name: "Agriculture & Exempt", path: "agri-exmpt" },
    { name: "Business Income", path: "business" },
    { name: "Capital Gains Income", path: "capital-gains" },
    { name: "Rental Income", path: "rental" },
    { name: "Salary Income", path: "salary" },
  ];

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 md:col-span-12  md:bg-gray-100 md:min-h-screen  px-4 border border-gray-200">
        <h4 className="text-[#334155] text-[1.4rem] leading-[2.7rem] pt-2 md:m-0 ml-2 mt-2 hidden md:block">
          Tax Summary&nbsp;&nbsp;
          <span className="text-[#64748B] text-[1.1rem] font-[500]">
          {returnYear}
          </span>
        </h4>
      <div className="flex gap-2 md:hidden items-center">
        <button onClick={handleGoBack}>
          <img src={previousBtn} alt="" className="w-6 h-6 mb-2" />
        </button>
        <h4 className=" text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
          {displayText}
        </h4>
      </div>

        <div className="bg-white-body rounded-xl md:pl-[2%] md:p-[2%] my-[2%]">
          <div className="bg-gray-100 rounded p-1 hidden md:block">
            <div className="flex justify-start gap-3 p-1">
              <NavLink
                to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":detailType", "salary")}
                className={({ isActive }) =>
                  `no-underline  rounded py-1 px-2.5  ${
                    isActive
                      ? "bg-[#334155] text-[#FFFFFF]"
                      : "bg-gray-100 text-black"
                  }`
                }
              >
                <button>Salary</button>
              </NavLink>

              <NavLink
                to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":detailType", "rental")}
                className={({ isActive }) =>
                  `no-underline  rounded py-1 px-2.5 ${
                    isActive
                      ? "bg-[#334155] text-[#FFFFFF]"
                      : "bg-gray-100 text-black"
                  }`
                }
              >
                <button>Rental</button>
              </NavLink>

              <NavLink
                to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":detailType", "capital-gains")}
                className={({ isActive }) =>
                  `no-underline  rounded py-1 px-2.5 ${
                    isActive
                      ? "bg-[#334155] text-[#FFFFFF]"
                      : "bg-gray-100 text-black"
                  }`
                }
              >
                <button>Capital Gains</button>
              </NavLink>

              <NavLink
                to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":detailType", "business")}
                className={({ isActive }) =>
                  `no-underline  rounded py-1 px-2.5 ${
                    isActive
                      ? "bg-[#334155] text-[#FFFFFF]"
                      : "bg-gray-100 text-black"
                  }`
                }
              >
                <button>Business</button>
              </NavLink>

              <NavLink
                to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":detailType", "agri-exmpt")}
                className={({ isActive }) =>
                  `no-underline  rounded py-1 px-2.5 ${
                    isActive
                      ? "bg-[#334155] text-[#FFFFFF]"
                      : "bg-gray-100 text-black"
                  }`
                }
              >
                <button>Agri & Exmpt</button>
              </NavLink>

              <NavLink
                to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":detailType", "others")}
                className={({ isActive }) =>
                  `no-underline  rounded py-1 px-2.5 ${
                    isActive
                      ? "bg-[#334155] text-[#FFFFFF]"
                      : "bg-gray-100 text-black"
                  }`
                }
              >
                <button>Others</button>
              </NavLink>
            </div>
          </div>
          <div className={`dropdown-container md:hidden fixed bottom-[12vh] left-1/2 transform -translate-x-1/2 w-[226px] z-50 bg-[#F1F5F9] ${dropdownOpen? 'rounded-b-[1rem]': 'rounded-[1rem]'}`}>
            <div className="dropdown-item relative w-[100%] p-2">
              <div className="w-100% flex items-center justify-center">
              <div
                onClick={toggleDropdown}
                className={`dropdown-header p-2 bg-[#334155] text-white w-[95%]  cursor-pointer rounded-md`}
                style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}
              >
                <span className="text-[1rem]">{pathToComponentMap[currentComponent]}</span>
                <span>{dropdownOpen ? <img src={downArrow}/> : <img src={upArrow}/>}</span>
              </div>
              </div>
              {dropdownOpen && (
                <div className="dropdown-content pl-2 pr-2 bg-[#F1F5F9] rounded-tl-[1rem] rounded-tr-[1rem]  absolute bottom-full left-0 w-[100%]">
                  {components.map(
                    (component) =>
                      currentComponent !== component.path && (
                        <div
                          key={component.name}
                          className={`block p-2 rounded-md cursor-pointer text-[1rem] ${
                            currentComponent === component.path
                              ? "bg-[#334155] text-[#FFFFFF]"
                              : "hover:bg-gray-100 text-[#64748B]"
                          }`}
                          onClick={() => handleComponentClick(component.path)}
                        >
                          {component.name}
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          </div>

          <>
            {displayText === "Salary" ? (
              <>
                <FormContext.Provider value={{ salaryRef, handleFormSubmit }}>
                  <TaxSummarySalary ref={salaryRef} isDisabled={isDisabled}/>
                  <TaxSummaryFooter
                    handleAddClickForm={callSalaryAddEmployee}
                    value="salary"
                  />
                </FormContext.Provider>
              </>
            ) : displayText === "Rental" ? (
              <>
                <FormContext.Provider value={{ salaryRef, handleFormSubmit }}>
                  <TaxSummaryRental ref={rentalRef} isDisabled={isDisabled}/>
                  <TaxSummaryFooter
                    handleAddClickForm={callRentalAddProperty}
                    value="rental"
                  />
                </FormContext.Provider>
              </>
            ) : displayText === "Capital Gains" ? (
              <>
                <FormContext.Provider value={{ capitalRef, handleFormSubmit }}>
                  <TaxSummaryCapitalGains ref={capitalRef} isDisabled={isDisabled}/>
                  <TaxSummaryFooter
                    handleAddClickForm={callCapitalGainOrLoss}
                    value="capital"
                  />
                </FormContext.Provider>
              </>
            ) : displayText === "Business" ? (
              <>
                <FormContext.Provider value={{ businessRef, handleFormSubmit }}>
                  <TaxSummaryBusiness ref={businessRef} isDisabled={isDisabled}/>
                  <TaxSummaryFooter
                    handleAddClickForm={callAddBusiness}
                    value="business"
                  />
                </FormContext.Provider>
              </>
            ) : displayText === "Agri & Exmpt" ? (
              <>
                <FormContext.Provider
                  value={{ agriExemptRef, handleFormSubmit }}
                >
                  <TaxSummaryAgriExempt ref={agriExemptRef} isDisabled={isDisabled}/>
                  <TaxSummaryFooter />
                </FormContext.Provider>
              </>
            ) : displayText === "Others" ? (
              <>
                <FormContext.Provider value={{ othersRef, handleFormSubmit }}>
                  <TaxSummaryOthers ref={othersRef} isDisabled={isDisabled}/>
                  <TaxSummaryFooter />
                </FormContext.Provider>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsSummaryPage;
