import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";
import usePatchArtDetails from "../../data/usePatchArtDetails";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const EditArt = ({ editValue, detailData, setIsRelaod }) => {
  const [
    patchArtData,
    patchArtError,
    patchArtIsLoading,
    patchArtDetails,
  ] = usePatchArtDetails();
  const { estate_type } = useParams();
  const removeBtnRef = useRef(null);
  const [name, setName] = useState("");
  const [errors, setErrors]= useState([])
  const [isClose, setIsClose]= useState(false)
  const [artForm, setArtForm]=useState({
    item_type: "",
    item_name: "",
    artist_creator: "",
    description: "",
    purchase_date: "",
    purchase_price: "",
    estimated_sale_value: "",
    loan_taken: false,
    invoice: null
  })

  useEffect(()=>{
    if(detailData){
       const {total_active_assets, total_assets, total_assets_amount, total_idle_assets, user, ...rest}=detailData;
       setArtForm(rest)
    }
  },[detailData])

  useEffect(()=>{
    if(patchArtData){
      showSuccessToast("Data updated successfully")
      setIsRelaod(true)
      setIsClose(true)
    }
    if(patchArtError){
      showErrorToast("Error in Updating")
    }
  },[patchArtData, patchArtError])

  const handleInputChange=(e)=>{
    let { name, value, type, files } = e.target;
     if(type==="radio"){
        const bool= value==="true"?true: value==="false"?false:null;
        value=bool
     }
    setArtForm((prevForm) => ({
      ...prevForm,
      [name]: type === 'file' ? files[0] : value
    }));
  }

  const validateArtForm = () => {
    const newErrors = {};

    if(editValue==="artValue"){

    }else if(editValue==="artDetails"){
        // Item Type: Required field
        if (!artForm.item_type) {
          newErrors.item_type = "Item type is required.";
        }
      
        // Item Name: Required field, only letters and spaces allowed
        const namePattern = /^[a-zA-Z\s]+$/;
        if (!artForm.item_name) {
          newErrors.item_name = "Item name is required.";
        } else if (!namePattern.test(artForm.item_name)) {
          newErrors.item_name = "Item name should only contain letters and spaces.";
        }
      
        // // Artist/Creator: Required field, only letters and spaces allowed
        // if (!artForm.artist_creator) {
        //   newErrors.artist_creator = "Artist/Creator name is required.";
        // } else if (!namePattern.test(artForm.artist_creator)) {
        //   newErrors.artist_creator = "Artist/Creator name should only contain letters and spaces.";
        // }
      
        // // Description: Required field
        // if (!artForm.description) {
        //   newErrors.description = "Description is required.";
        // }
    }else if(editValue==="artPurchaseDetails"){
      //  // Purchase Date: Required, should be a valid date
      //  if (!artForm.purchase_date) {
      //   newErrors.purchase_date = "Purchase date is required.";
      // } else if (isNaN(new Date(artForm.purchase_date).getTime())) {
      //   newErrors.purchase_date = "Purchase date should be a valid date.";
      // }
    
      // // Purchase Price: Required, numeric, and positive
      // if (!artForm.purchase_price || isNaN(artForm.purchase_price)) {
      //   newErrors.purchase_price = "Purchase price is required and should be a number.";
      // } else if (artForm.purchase_price <= 0) {
      //   newErrors.purchase_price = "Purchase price must be greater than 0.";
      // }
    
      // // Estimated Sale Value: Required, numeric, and positive
      // if (!artForm.estimated_sale_value || isNaN(artForm.estimated_sale_value)) {
      //   newErrors.estimated_sale_value = "Estimated sale value is required and should be a number.";
      // } else if (artForm.estimated_sale_value <= 0) {
      //   newErrors.estimated_sale_value = "Estimated sale value must be greater than 0.";
      // }
    
      // if(typeof(artForm.invoice) === "string"){

      // }else{
      //         // Invoice File: Required, must be a valid file format (e.g., PDF, JPG, PNG)
      //     const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
      //     if (!artForm.invoice) {
      //       newErrors.invoice = "Invoice file is required.";
      //     } else if (!filePattern.test(artForm.invoice.name)) {
      //       newErrors.invoice = "Invoice should be a valid file format (PDF, JPG, or PNG).";
      //     }
      // }
    } 

    // Set error state and check if no errors remain
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateArtForm()) return;
  
    // Create a new FormData object
    const formData = new FormData();
  
    // Helper function to check if a value is not empty
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append the "id" field
    if (isNotEmpty(artForm.id)) {
      formData.append("id", artForm.id);
    }
  
    if (editValue === "artValue") {
      // No additional fields for this case
    } else if (editValue === "artDetails") {
      // Append non-empty details fields
      if (isNotEmpty(artForm.item_type)) {
        formData.append("item_type", artForm.item_type);
      }
      if (isNotEmpty(artForm.item_name)) {
        formData.append("item_name", artForm.item_name);
      }
      if (isNotEmpty(artForm.artist_creator)) {
        formData.append("artist_creator", artForm.artist_creator);
      }
      if (isNotEmpty(artForm.description)) {
        formData.append("description", artForm.description);
      }
    } else if (editValue === "artPurchaseDetails") {
      // Append non-empty purchase details fields
      if (isNotEmpty(artForm.purchase_date)) {
        formData.append("purchase_date", artForm.purchase_date);
      }
      if (isNotEmpty(artForm.purchase_price)) {
        formData.append("purchase_price", artForm.purchase_price);
      }
      if (isNotEmpty(artForm.estimated_sale_value)) {
        formData.append("estimated_sale_value", artForm.estimated_sale_value);
      }
      if (isNotEmpty(artForm.loan_taken)) {
        formData.append("loan_taken", artForm.loan_taken);
      }
  
      // Handle the invoice file field
      if (typeof artForm.invoice !== "string" && artForm.invoice) {
        formData.append("invoice", artForm.invoice); // File
      }
    }
  
    // Debugging: Print FormData values
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    console.log("form in sub", artForm);
  
    // Send FormData to the API
    patchArtDetails(formData, artForm.asset);
  };
  

  useEffect(() => {
    if (editValue) {
      switch (editValue) {
        case "artValue":
          setName("Edit Painting Vlaue");
          break;
        case "artDetails":
          setName("Edit Item Details");
          break;
        case "artPurchaseDetails":
          setName("Edit Purchase Details");
          break;
        default:
          break;
      }
    }
  }, [editValue]);

  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };
  const getContent = () => {
    switch (editValue) {
      case "artValue":
        return (
          <>
            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] mb-3`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Painting from the Assets?
              </h4>
              <h4 className="text-[0.7rem] font-[500] text-lightGray">
                This action is permanent and may affect your financial records.
              </h4>
              <div className="w-[20%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove`}
                  px={"px-[0.4rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        );
      case "artDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Type
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Painting", label: "Painting" },
                      { value: "SILVER", label: "Silver" }
                    ]}
                    name="item_type"
                    value={artForm.item_type}
                    onChange={handleInputChange}
                  />
                  {errors.item_type && <p className="text-red-500 text-[0.75rem]">{errors.item_type}</p>}
                </div>
                <div className="input-container col-span-6 w-[100%] flex flex-col gap-1.5 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Item Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={artForm.item_name}
                    onChange={handleInputChange}
                    name="item_name"
                  />
                  {errors.item_name && <p className="text-red-500 text-[0.75rem]">{errors.item_name}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Artist/Creator
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={artForm.artist_creator}
                    onChange={handleInputChange}
                    name="artist_creator"
                  />
                  {errors.artist_creator && <p className="text-red-500 text-[0.75rem]">{errors.artist_creator}</p>}
                </div>

                <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Description
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={artForm.description}
                    onChange={handleInputChange}
                    name="description"
                  />
                  {errors.description && <p className="text-red-500 text-[0.75rem]">{errors.description}</p>}
                </div>
              </div>
            </div>
          </>
        );
      case "artPurchaseDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-12 gap-3 mb-3 mt-1.5">
                <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Date
                  </label>
                  <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={artForm.purchase_date}
                      name="purchase_date"
                      onChange={handleInputChange}
                    />
                    {errors.purchase_date && <p className="text-red-500 text-[0.75rem]">{errors.purchase_date}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mb-3">
                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Price
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="purchase_price"
                          value={artForm.purchase_price}
                          onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.purchase_price && <p className="text-red-500 text-[0.75rem]">{errors.purchase_price}</p>}
                </div>

                <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Sale Value
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="estimated_sale_value"
                          value={artForm.estimated_sale_value}
                          onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.estimated_sale_value && <p className="text-red-500 text-[0.75rem]">{errors.estimated_sale_value}</p>}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div className="input-container">
                  <label className="input-label">Taken any loan?</label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                      type="radio"
                      value={true}
                      name="loan_taken"
                      checked={artForm.loan_taken===true}
                      onChange={handleInputChange}
                      className="mr-2 custom-radio"
                    />
                      Yes
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                    <InputField
                      type="radio"
                      value={false}
                      name="loan_taken"
                      checked={artForm.loan_taken===false}
                      onChange={handleInputChange}
                      className="mr-2 custom-radio"
                    />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mb-3">
                <div className="col-span-4">
                  <WhiteButton
                    name="Upload Invoice"
                    px={"px-[0.6rem]"}
                    py={"py-[0.52rem]"}
                    text={"text-[0.9rem]"}
                    mt={"mt-[1.2rem]"}
                    border={"!border-[1px] !border-[#94A3B8]"}
                    onClick={()=>document.getElementById("file-upload").click()}
                  />
                  {errors.invoice && <p className="text-red-500 text-[0.75rem]">{errors.invoice}</p>}
                </div>
                <InputField 
                  id={`file-upload`}
                  type="file"
                  onChange={handleInputChange}
                  className="hidden"
                  name="invoice"
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" onClick={()=>setIsClose(false)}/>
        }
        heading={name}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} onClick={handleSubmit} />
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
      <RemoveAsset
        heading="Painting"
        assetId={artForm.asset}
        deleteId={`${artForm.asset}/?id=${artForm.id}&model_type=art_and_collectibles`}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditArt;
