import useAPICall from "hooks/useAPICall";
  import { GET_ORDER_DETAILS_URL } from "constants/apis";

const useGetOrderDetails = () => {
  const [orderData, orderError, orderIsLoading, callGetOrder, setSuccessOrderData, setOrderError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setOrderError(en.something_went_wrong);
    setSuccessOrderData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const orderData = res;
        if (orderData && typeof orderData === "object") {
          setSuccessOrderData(orderData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const getOrderDetails = (body) => {
    const url = GET_ORDER_DETAILS_URL 
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log(body)
    // console.log('getOrderDetails running')
    callGetOrder({
      url,
      method : "GET",
      statusObj,
      
      config,
      defaultFallback,
      body
    });
  };
  return [orderData, orderError, orderIsLoading, getOrderDetails, setSuccessOrderData, setOrderError];
};

export default useGetOrderDetails;
