import React, { useContext, useEffect, useState } from "react";
import FormDialog from "./Dialog";
import { ServiceDetails } from "components/serviceDetails/GetServiceDetails";
import docImg from "assets/images/Serviceimages/Frame 1000000961.svg";

import { myContext } from "App";
import usePostWorkOrder from "containers/privateRoutes/Orders/data/usePostWorkOrder";
import { orderPlacedSuccessModalContext } from "App";
import { useSearchParams } from "react-router-dom";
import NormalLoginSheet from "components/BottomSheet/NormalLoginSheet";

function ServiceTitle(props) {
  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const [applyNowClicked, setApplyNowClicked] = useState(false);
  const { orderPlacedSuccessModal, setOrderPlacedSuccessModal } = useContext(
    orderPlacedSuccessModalContext
  );

  // const [showModal, setShowModal] = useState(false);
  const [
    workOrderData,
    workOrderError,
    workOrderIsLoading,
    postWorkOrder,
    setSuccessworkOrderData,
    setworkOrderError,
  ] = usePostWorkOrder();
  // console.log(props.sendData)
  useEffect(() => {
    if (applyNowClicked && isLoggedIn) {
      postWorkOrder({
        service_id: props.sendData.id,
        // service_name: props.sendData.service_title,
      });
    }
  }, [applyNowClicked]);
  // console.log(props.sendData.id)
  // console.log(orderPlacedSuccessModal);
  // console.log(workOrderData);

  useEffect(() => {
    if (workOrderData) {
      console.log(workOrderData);
      setOrderPlacedSuccessModal(true);
    }
  }, [workOrderData]);

  return (
    <>
      {props.sendData && (
        <>
          <div className="slightDarkBlack belowNav px-[8%] pb-[5%] pt-[15%] grid grid-cols-12 gap-[6%]">
            <div className=" col-span-4 lg:flex md:flex hidden">
              <img
                className=" h-full"
                src={ServiceDetails.header.img}
                alt="doc"
              />
            </div>
            <div className="md:col-span-8 col-span-12 lg:col-span-8 detailsPart">
              <h1 className=" font-[700] md:text-[3.4rem] text-[2.2rem] lg:pt-0 md:pt-0 pt-8 lg:text-[3.4rem] md:leading-[4rem] leading-[3rem] lg:leading-[4rem]">
                {ServiceDetails.header.heading}
              </h1>
              <p className=" font-[500] md:text-[1rem] text-[0.8rem] lg:text-[1rem] leading-[1.3rem] text-gray-400 ">
                {ServiceDetails.header.paraBelowH}
              </p>
              <div className="lg:grid md:grid lg:grid-cols-12 md:grid-cols-12 items-center gap-4">
                <p className="bg-white-body md:col-span-6 col-span-12 lg:col-span-6 m-0 my-auto rounded-lg drop-shadow-sm px-[1rem] py-[0.7rem] text-[1rem] font-[500] leading-[1.5rem]">
                  Get Certificate starting from ₹
                  {props.sendData.certificate_price}/-
                </p>

                <div className="md:col-span-4 col-span-12 lg:col-span-4 lg:flex md:flex hidden">
                  <FormDialog serviceData={props} btnName={"Apply Now"} />
                </div>
 
                {isLoggedIn !== true && (
                  <>
                    <div className="md:hidden col-span-12 min-w-[100%]  lg:hidden ">
                      <NormalLoginSheet
                        serviceId={props.sendData.id}
                        serviceData={props}
                        element={
                          <button
                            type="primary"
                            className={`border-1 mx-auto rounded bg-[#9065B4]   min-w-[100%] py-[.8rem] my-4 px-[8.5rem] `}
                          >
                            <span className="text-white font-[500] whitespace-nowrap  text-[1rem] leading-[1.5rem]">
                              {" "}
                              Apply now
                            </span>
                          </button>
                        }
                      />
                    </div>{" "}
                  </>
                )}

                {isLoggedIn && (
                  <>
                    {" "}
                    <button
                      onClick={() => {
                        setApplyNowClicked(true);
                      }}
                      className={`border-1 lg:hidden md:hidden mx-auto rounded py-[.6rem] mt-4 w-[100%] bg-[#9065B4] min-w-[100%] `}
                    >
                      <span className="text-white font-[500] whitespace-nowrap text-[1rem] leading-[1.5rem]">
                        {" "}
                        Apply now
                      </span>
                    </button>
                  </>
                )}
              </div>

              <div className="grid grid-cols-12 md:gap-4 lg:gap-4 mt-4">
                <div className="md:col-span-4 col-span-12  lg:col-span-4 md:mt-[4rem] mt-[1rem] lg:mt-[4rem] flex items-center bg-white-body p-[0.6rem] gap-4 rounded-xl drop-shadow-md ">
                  <img
                    className="md:w-[3.5rem] w-10 lg:w-[3.5rem]"
                    src={ServiceDetails.header.con1.img}
                  />
                  <p className="m-0 font-[500] text-[1rem] leading-[1.4rem]">
                    Text us on Whatsapp
                  </p>
                </div>
                <div className="md:col-span-4 col-span-12  lg:col-span-4 md:mt-[4rem] mt-[1rem] lg:mt-[4rem] flex items-center bg-white-body p-[0.6rem] gap-4 rounded-xl drop-shadow-md">
                  <img
                    className="md:w-[3.5rem] w-10 lg:w-[3.5rem]"
                    src={ServiceDetails.header.con2.img}
                  />
                  <p className="m-0 font-[500] text-[1rem] leading-[1.4rem]">
                    Schedule a Call
                  </p>
                </div>
                <div className="md:col-span-4 col-span-12  lg:col-span-4 md:mt-[4rem] mt-[1rem] lg:mt-[4rem] flex items-center bg-white-body p-[0.6rem] gap-4 rounded-xl drop-shadow-md">
                  <img
                    className="md:w-[3.5rem] w-10 lg:w-[3.5rem]"
                    src={ServiceDetails.header.con3.img}
                  />
                  <p className="m-0 font-[500] text-[1rem] leading-[1.4rem]">
                    Call our Expert
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal
          show={showModal}
          onClose={() => {
            setShowModal(false);
          } } /> */}
        </>
      )}
    </>
  );
}

export default ServiceTitle;

// props.sendData.header.button
