import React, { useContext, useEffect, useState } from "react";
//new pics
import sample from "assets/images/incomeTax/Sample.svg";
import eye from "assets/images/incomeTax/eye.svg";
import dots from "assets/images/incomeTax/3dots.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import { useNavigate } from "react-router-dom";
import calendarIcon from "assets/images/incomeTax/calendar-days.svg";
import editPen from "assets/images/incomeTax/edit-3.svg";
import edit_icon from "assets/images/incomeTax/edit-profile.svg";
import plus from "assets/images/incomeTax/plus.svg";
import useGetTaxProfileDetails from "./Data/useGetTaxProfileDetails";
import CustomizableDrawer from "../Reports/Drawer";
import { useForm } from "react-hook-form";
import usePatchTaxProfileDetails from "./Data/usePatchTaxProfileDetails";
import usePostTaxProfilePasswordChange from "./Data/usePostTaxProfilePasswordChange";
import usePostTaxProfileMobileUpdate from "./Data/usePostTaxProfileMobileUpdate";
import usePostTaxProfileMobileVerify from "./Data/usePostTaxProfileMobileVerify";
import usePostTaxProfileAddress from "./Data/usePostTaxProfileAddress";
import usePatchTaxProfileAddress from "./Data/usePatchTaxProfileAddress";
import usePostTaxProfileEmailUpdate from "./Data/usePostTaxProfileEmailUpdate";
import usePostTaxProfileEmailVerify from "./Data/usePostTaxProfileEmailVerify";
import usePatchTaxProfilePicAddOrUpdate from "./Data/usePatchTaxProfilePicAddOrUpdate";
import useDeleteTaxProfilePic from "./Data/useDeleteTaxProfilePic";
import InputField from "components/input/InputField";
import HeadingProfile from "../Tax Profile/HeadingProfile";
import { getValue } from "@testing-library/user-event/dist/utils";
import { ProfileContext } from "../Profile/Layout";

function IncomeTaxProfile() {
  const navigate = useNavigate();
  const [basicDetails, setBasicDetails] = useState(false);
  const [addressDetails, setAddressDetails] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState(false);
  const [emailDetails, setEmailDetails] = useState(false);
  const [mobileBasicExpand, setMobileBasicExpand] = useState(false);
  const [mobileAddressExpand, setMobileAddressExpand] = useState(false);
  const [mobileAccountExpand, setMobileAccountExpand] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const [otpId, setOtpId] = useState("");
  const [otpSentEmail, setOtpSentEmail] = useState(false);
  const [otpIdEmail, setOtpIdEmail] = useState(false);
  const [mobilePurpose, setMobilePurpose] = useState(false);
  const [showPicView, setShowPicView] = useState(null);
  const { updateProfilePic } = useContext(ProfileContext);
  const [flagForAddressPostOrUpdate, setFlagForAddressPostOrUpdate] =
    useState(false);
  const [profilePicError, setProfilePicError]= useState("")
  const [readOnly, setReadOnly]=useState(false);
  const [tempPicUrl, setTempPicUrl]= useState(null);

  const [getProfileData, getError, getIsLoading, getTaxProfileDetails] =
    useGetTaxProfileDetails();

  const [patchData, patchError, patchIsLoading, patchTaxProfileBasicDetails] =
    usePatchTaxProfileDetails();

  const [passData, passError, passIsLoading, postTaxProfilePasswordChange] =
    usePostTaxProfilePasswordChange();

  const [mobileData, mobileError, mobileIsLoading, postTaxProfileMobileChange] =
    usePostTaxProfileMobileUpdate();

  const [addressData, addressError, addressIsLoading, postTaxProfileAddress] =
    usePostTaxProfileAddress();

  const [
    addressPatchData,
    addressPatchError,
    addressPatchIsLoading,
    patchTaxProfileAddress,
  ] = usePatchTaxProfileAddress();

  const [picPatchData, picPatchError, picPatchIsLoading, patchTaxProfilePic] =
    usePatchTaxProfilePicAddOrUpdate();

  const [
    deletePicData,
    deletePicError,
    deletePicIsLoading,
    deleteTaxProfilePic,
  ] = useDeleteTaxProfilePic();
  const [
    mobileOtpData,
    mobileOtpError,
    mobileOtpIsLoading,
    postTaxProfileMobileOtp,
  ] = usePostTaxProfileMobileVerify();

  const [emailData, emailError, emailIsLoading, postTaxProfileEmailChange] =
    usePostTaxProfileEmailUpdate();

  const [
    emailOtpData,
    emailOtpError,
    emailOtpIsLoading,
    postTaxProfileEmailOtp,
  ] = usePostTaxProfileEmailVerify();
  useEffect(() => {
    getTaxProfileDetails();
  }, []);

  useEffect(() => {
    console.log("profileData", getProfileData)
    if (getProfileData?.data?.profile_address.length > 0) {
      let address = getProfileData?.data?.profile_address;
      address = address.map((el) => {
        const { id, ...addressWithOutId } = el;
        addressWithOutId.rent_status=addressWithOutId.rent_status.toString()
        if (id) {
          setFlagForAddressPostOrUpdate(true);
        }
        return addressWithOutId;
      });
      addressReset({ addresses: address });
    }else{
      // console.log("iam here")
      // if(window.innerWidth < 768){
      //   setAddressDetails(false)
      // }else{
      //   setAddressDetails(true)
      // }
    }
    if (getProfileData?.data) {
      console.log('getProfileData?.data', getProfileData?.data)
      const {
        full_name,
        fathers_name,
        date_of_birth,
        gender,
        maritual_status,
        profile_picture,
      } = getProfileData?.data;
      const dateArray = date_of_birth?.split("/").reverse().join("-");
      const addressBasic = {
        full_name: full_name,
        fathers_name: fathers_name,
        date_of_birth: dateArray,
        gender: gender,
        maritual_status: maritual_status,
      };
      resetBasic(addressBasic);
      setShowPicView(profile_picture);
      updateProfilePic(profile_picture)
    }else{
      // console.log("Iam also here")
      // if(window.innerWidth < 768){
      //   setBasicDetails(false)
      // }else{
      //   setBasicDetails(true)
      // }
    }
  }, [getProfileData]);
  useEffect(() => {
    if (patchData) {
      getTaxProfileDetails();
      changingToView();
      showSuccessToast("Basic Details Updated Successfully");
    }
  }, [patchData]);
  useEffect(() => {
    if (patchError) {
      showErrorToast("Error in Updating Basic Details");
    }
  }, [patchError]);
  useEffect(() => {
    if (passData) {
      changeToViewPass();
      showSuccessToast("Password Changed Successfully");
    }
  }, [passData]);
  useEffect(() => {
    if (passError) {
      showErrorToast("Error in Updating Password");
    }
  }, [passError]);

  useEffect(() => {
    if (addressData) {
      changingToViewAddress();
      getTaxProfileDetails();
      showSuccessToast("Address Updated Successfully");
    }
  }, [addressData]);
  useEffect(() => {
    if (addressError) {
      showErrorToast("Error in Updating Address");
    }
  }, [addressError]);

  useEffect(() => {
    if (addressPatchData) {
      changingToViewAddress();
      setFlagForAddressPostOrUpdate(false);
      getTaxProfileDetails();
      showSuccessToast("Address Updated Successfully");
    }
  }, [addressPatchData]);
  useEffect(() => {
    if (addressPatchError) {
      showErrorToast("Error in Updating Address");
    }
  }, [addressPatchError]);

  useEffect(() => {
    if (mobileData) {
      console.log("mobileData", mobileData);
      showSuccessToast("OTP Sent Successfully");
      setOtpSent(true); // Show OTP field
      setOtpId(mobileData.data.otp_id);
      setMobilePurpose(mobileData.data.purpose)
    }
  }, [mobileData]);
  useEffect(() => {
    if (mobileError) {
      showErrorToast("Error in sending OTP");
    }
  }, [mobileError]);
  useEffect(() => {
    if (mobileOtpData) {
      console.log("mobileData", mobileData);
      getTaxProfileDetails();
      showSuccessToast("Phone Number Updated Successfully");
      setOtpSent(false);
      changeToViewPhone();
    }
  }, [mobileOtpData]);
  useEffect(() => {
    if (mobileOtpError) {
      showErrorToast("Error in Updating Phone Number");
    }
  }, [mobileOtpError]);

  useEffect(() => {
    if (emailData) {
      console.log("email", emailData);
      showSuccessToast("OTP Sent Successfully");
      getTaxProfileDetails();
      setOtpSentEmail(true); // Show OTP field
      setOtpIdEmail(emailData.data.otp_id);
    }
  }, [emailData]);
  useEffect(() => {
    if (emailError) {
      showErrorToast("Error in sending OTP");
    }
  }, [emailError]);
  useEffect(() => {
    if (emailOtpData) {
      console.log("mobileData", mobileData);
      showSuccessToast("Email Updated Successfully");
      setOtpSentEmail(false);
      changeToViewEmail();
      getTaxProfileDetails()
    }
  }, [emailOtpData]);
  useEffect(() => {
    if (emailOtpError) {
      showErrorToast("Error in Updating Email");
    }
  }, [emailOtpError]);

  useEffect(() => {
    if (picPatchData) {
      console.log("picPatchData", picPatchData)
      // getTaxProfileDetails();
      setShowPicView(tempPicUrl);
      updateProfilePic(tempPicUrl)
      showSuccessToast("Profile Pic Added.");
    }
  }, [picPatchData]);

  useEffect(() => {
    if (picPatchError) {
      showErrorToast("Error in Uploading Pic.");
    }
  }, [picPatchError]);

  useEffect(() => {
    if (deletePicData) {
      setShowPicView(null);
      updateProfilePic(null)
      showSuccessToast("Profile Pic Deleted.");
    }
  }, [deletePicData]);

  useEffect(() => {
    if (deletePicError) {
      showErrorToast("Error in Deleting Pic.");
    }
  }, [deletePicError]);

  const changingToEdit = () => {
    setBasicDetails(true);
  };
  const changingToView = () => {
    setBasicDetails(false);
  };
  const changingToEditAddress = () => {
    setAddressDetails(true);
  };
  const changingToViewAddress = () => {
    setAddressDetails(false);
  };
  const changeToEditPass = () => {
    setPasswordDetails(true);
  };
  const changeToViewPass = () => {
    setPasswordDetails(false);
  };
  const changeToEditPhone = () => {
    setPhoneDetails(true);
  };
  const changeToViewPhone = () => {
    setPhoneDetails(false);
  };
  const changeToEditEmail = () => {
    setEmailDetails(true);
  };
  const changeToViewEmail = () => {
    setEmailDetails(false);
  };
  const expandingBasicDetailsView = () => {
    setMobileBasicExpand(true);
  };
  const expandingAdressDetailsView = () => {
    setMobileAddressExpand(true);
  };
  const expandingAccountDetailsView = () => {
    setMobileAccountExpand(true);
  };
  const openingDrawer = (val) => {
    setOpenDrawer(val);
  };
  const changingToViewDrawer = () => {
    setAddressDetails(false);
    setBasicDetails(false);
    setEmailDetails(false);
    setPhoneDetails(false);
    setPasswordDetails(false);
  };

  // basic details react hook form
  const defaultValueBasic = {
    full_name: "",
    fathers_name: "",
    date_of_birth: "",
    gender: 1,
    maritual_status: 1,
  };
  const {
    register: registerFormBasic,
    handleSubmit: handleSubmitBasic,
    reset: resetBasic,
    formState: { errors: errorsBasic },
  } = useForm({
    defaultValues: defaultValueBasic,
  });

  const submitingBasicForm = () => {
    handleSubmitBasic(onSubmitBasic)();
  };

  const onSubmitBasic = (data) => {
    const { date_of_birth } = data;
    const dateArray = date_of_birth?.split("-").reverse().join("/");
    data.date_of_birth = dateArray;
    patchTaxProfileBasicDetails(data);
  };

  // address react hook form

  const defaultValuesAddress = {
    addresses: [
      {
        address_type: 1, // Default value for other fields
        rent_status: '1',
        door_no: "",
        permise_name: "",
        street: "",
        area: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        rental_agreement: null,
      },
      {
        address_type: 2, // Default value for other fields
        rent_status: '1',
        door_no: "",
        permise_name: "",
        street: "",
        area: "",
        city: "",
        state: "",
        pincode: "",
        country: "",
        rental_agreement: null,
      },
    ],
  };
  const {
    register: registerFormAddress,
    handleSubmit: handleSubmitAddress,
    watch,
    setValue,
    reset: addressReset,
    trigger,
    getValues,
    formState: { errors:addressErrors },
  } = useForm({
    defaultValues: defaultValuesAddress,
  });

  // const [rentStatus0, rentStatus1] = watchAddress([
  //   'addresses[0].rent_status',
  //   'addresses[1].rent_status'
  // ]);
  const rentStatus0 = watch("addresses[0].rent_status");

  const addressFormSubmission = () => {
    handleSubmitAddress(onSubmitAddress)();
  };
  const handleInputChange = (e) => {
    setValue(e.target.name, e.target.value);
  };
  const handleFileChange = (e) => {
    // Get the file from the event and update it in the form state
    const file = e.target.files[0]; // Assuming only one file will be uploaded
    setValue("addresses[0].rental_agreement", file); // Set the file value in the form state
  };
  const cRent = watch("addresses[0].rent_status");
  const handleCheckboxChange =async (e) => {
    if (e.target.checked) {
      setValue("addresses[1].rent_status", cRent);
      setValue("addresses[1].door_no", getValues("addresses[0].door_no"));
      setValue("addresses[1].permise_name", getValues("addresses[0].permise_name"));
      setValue("addresses[1].street", getValues("addresses[0].street"));
      setValue("addresses[1].area", getValues("addresses[0].area"));
      setValue("addresses[1].city", getValues("addresses[0].city"));
      setValue("addresses[1].state", getValues("addresses[0].state"));
      setValue("addresses[1].pincode", getValues("addresses[0].pincode"));
      setValue("addresses[1].country", getValues("addresses[0].country"));
      setValue("addresses[1].rental_agreement", getValues("addresses[0].rental_agreement"));
    } else {
      setValue("addresses[1].rent_status", "2");
      setValue("addresses[1].door_no", "");
      setValue("addresses[1].permise_name", "");
      setValue("addresses[1].street", "");
      setValue("addresses[1].area", "");
      setValue("addresses[1].city", "");
      setValue("addresses[1].state", "");
      setValue("addresses[1].pincode", "");
      setValue("addresses[1].country", "");
      setValue("addresses[1].rental_agreement", null);
    }
    await trigger("addresses[1]");
  };
  const onSubmitAddress = (data) => {
    console.log("data Address to submit", data);
    const formData = new FormData();
    // Append form fields to FormData
    data.addresses.forEach((address, index) => {
      if (address.address_type) {
        formData.append(
          `addresses[${index}].address_type`,
          address.address_type
        );
      }
      if (address.door_no) {
        formData.append(`addresses[${index}].door_no`, address.door_no);
      }
      if (address.permise_name) {
        formData.append(
          `addresses[${index}].permise_name`,
          address.permise_name
        );
      }
      if (address.street) {
        formData.append(`addresses[${index}].street`, address.street);
      }
      if (address.area) {
        formData.append(`addresses[${index}].area`, address.area);
      }
      if (address.city) {
        formData.append(`addresses[${index}].city`, address.city);
      }
      if (address.state) {
        formData.append(`addresses[${index}].state`, address.state);
      }
      if (address.pincode) {
        formData.append(`addresses[${index}].pincode`, address.pincode);
      }
      if (address.country) {
        formData.append(`addresses[${index}].country`, address.country);
      }
      if(address.rent_status){
        formData.append(`addresses[${index}].rent_status`, address.rent_status);
      }

      // Append file if exists
      if (
        address.rental_agreement &&
        address.rental_agreement[0] &&
        typeof address.rental_agreement[0] === "object"
      ) {
        formData.append(
          `addresses[${index}].rental_agreement`,
          address.rental_agreement[0]
        );
      }
      if (flagForAddressPostOrUpdate) {
        formData.append(
          `addresses[${index}].id`,
          `${getProfileData?.data?.profile_address[index].id}`
        );
      }
    });
    if (flagForAddressPostOrUpdate) {
      patchTaxProfileAddress(formData);
    } else {
      postTaxProfileAddress(formData);
    }
  };

  // password react hook form

  const {
    register: registerPass,
    handleSubmit: handleSubmitPass,
    formState: { errors },
    watch: watchPass,
    reset: resetPass,
  } = useForm();

  const onSubmitPass = (data) => {
    // Handle form submission
    console.log("Form Data:", data);
    postTaxProfilePasswordChange(data);
    resetPass();
  };

  const passwordFormSubmission = () => {
    handleSubmitPass(onSubmitPass)();
  };

  // Mobile update react hook Form
  const {
    register: registerMobile,
    handleSubmit: handleSubmitMobile,
    formState: { errors: errorsMobile },
    watch: watchMobile,
    reset: resetMobile,
  } = useForm();

  const onSubmitMobile = (data) => {
    // Handle form submission
    console.log("Form Data:", data);
    postTaxProfileMobileChange({ mobile_number: data.mobile_number });
  };

  const onSubmitOtp = (data) => {
    console.log("formData", data);
    postTaxProfileMobileOtp({ otp_id: otpId, otp: data.otp, purpose:mobilePurpose });
    resetMobile();
  };

  const mobileFormSubmission = () => {
    otpSent
      ? handleSubmitMobile(onSubmitOtp)()
      : handleSubmitMobile(onSubmitMobile)();
    // handleSubmitMobile(otpSent ? onSubmitOtp : onSubmitMobile)();
  };

  // Email update react hook form

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
    watch: watchEmail,
    reset: resetEmail,
  } = useForm();

  const emailFormSubmission = () => {
    otpSentEmail
      ? handleSubmitEmail(onSubmitOtpEmail)()
      : handleSubmitEmail(onSubmitEmail)();
  };

  const onSubmitEmail = (data) => {
    // Handle form submission
    console.log("Form Data Email:", data);
    const formData = new FormData();
    formData.append("email", data.email);
    postTaxProfileEmailChange(formData);
  };

  const onSubmitOtpEmail = (data) => {
    console.log("formData email", data);
    console.log("form", { otp_id: otpIdEmail, otp: data.otp });
    const formData = new FormData();
    formData.append("otp", data.otp);
    formData.append("otp_id", otpIdEmail);
    postTaxProfileEmailOtp(formData);
    resetEmail();
  };

  // profile pic api
  const profilePicUpdation = (e) => {
    const uploadPic = e.target.files[0];
    // if (!uploadPic) return;
    setProfilePicError("");

    if (!uploadPic) {
      setProfilePicError("No file selected. Please choose a file.");
      return;
    }

    // Validate file type
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(uploadPic.type)) {
      setProfilePicError("Invalid file type. Please upload a JPEG or PNG image.");
      return;
    }
    const previewUrl = URL.createObjectURL(uploadPic);
    setTempPicUrl(previewUrl)

    const formData = new FormData();
    formData.append("profile_picture", uploadPic);
    patchTaxProfilePic(formData);
  };

  const deleteProfilePic = () => {
    deleteTaxProfilePic();
  };
  return (
    <div className="grid grid-cols-12 relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="col-span-12 md:col-span-12 bg-white-body md:bg-gray-100 w-[100%]  md:px-4 px-3">
        {/* <h4 className="font-[500] text-[1.4rem] text-[#334155] leading-[2.7rem] pt-2 md:m-0 ml-2 mt-2 md:block">
          Profile
        </h4> */}
        <HeadingProfile/>
        {basicDetails ? (
          <>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] ">
              <form>
                <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                  <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                    Basic Details
                  </h4>
                  <button
                    type="button"
                    className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                    onClick={() => {
                      submitingBasicForm();
                    }}
                  >
                    DONE
                  </button>
                </div>
                <div className="md:flex hidden">
                  <div className="flex justify-between w-[100%] gap-2 p-6">
                    <div className="w-[79%]">
                      <div className="flex justify-between items-center">
                        <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                          Name
                        </h4>
                        <div className="w-[38%]">
                        <InputField
                          type="text"
                          name="full_name"
                          placeholder="Enter your Full Name"
                          register={registerFormBasic}
                          validation={{
                            required: "Name is required",
                            minLength: { value: 3, message: "Name must be at least 3 characters long" }
                          }}
                          className="!border !border-[#E2E8F0] rounded-sm w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                        />
                      {errorsBasic.full_name && (
                        <p className="text-red-500">{errorsBasic.full_name.message}</p>
                      )}
                        </div>
                      </div>
                      <div className="flex justify-between items-center mt-[1rem]">
                        <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                          Father’s Name
                        </h4> 
                        <div className="w-[38%]">
                        <InputField
                          type="text"
                          name="fathers_name"
                          placeholder="Enter Father's Name"
                          register={registerFormBasic}
                          validation={
                            {
                              required: "Father’s name is required",
                              minLength: { value: 3, message: "Father’s name must be at least 3 characters long" }
                            }
                          }
                          className="!border !border-[#E2E8F0] rounded-sm w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                        />
                      {errorsBasic.fathers_name && (
                        <p className="text-red-500">{errorsBasic.fathers_name.message}</p>
                      )}
                        </div>
                      </div>
                      <div className="flex justify-between items-center mt-[1rem]">
                        <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                          Date of Birth
                        </h4>
                        <div className="relative w-[38%]">
                         <InputField
                          type="date"
                          name="date_of_birth"
                          placeholder="Select Date"
                          validation={
                            {
                              required: "Date of Birth is required",
                            } 
                          }
                          register={registerFormBasic}
                          className=" !border !border-[#E2E8F0] rounded-sm w-full text-[#334155] py-1.5 px-3 focus:outline-none pr-10"
                        />
                      {errorsBasic.date_of_birth && (
                        <p className="text-red-500">{errorsBasic.date_of_birth.message}</p>
                      )}
                        </div>
                      </div>
                      <div className="flex justify-between items-center mt-[1rem]">
                        <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                          Gender
                        </h4>
                        <div className="w-[38%]">
                        <InputField
                            type="select"
                            name="gender"
                            register={registerFormBasic}
                            validation={
                              {
                                required: "Gender is required"
                              }
                            }
                            options={[
                              { value: "", label: "Select an option" },
                              {value:1, label: "Male" },
                              {value:2, label: "Female" },
                              {value:3, label: "Other" },
                            ]}
                            className="!border !border-[#E2E8F0] rounded-sm w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                         />
                       {errorsBasic.gender && (
                        <p className="text-red-500">{errorsBasic.gender.message}</p>
                      )}
                        </div>
                      </div>
                      <div className="flex justify-between items-center mt-[1rem]">
                        <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                          Marital Status
                        </h4>
                        <div className="w-[38%]">
                        <InputField
                            type="select"
                            name="maritual_status"
                            register={registerFormBasic}
                            validation={
                              {
                                required: "Marital status is required"
                              }
                            }
                            options={[
                              { value: "", label: "Select an option" },
                              {value:1, label: "Married" },
                              {value:2, label: "Unmarried" },
                              {value:3, label: "Prefer not to disclose" },
                            ]}
                            className="!border !border-[#E2E8F0] rounded-sm w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                         />
                      {errorsBasic.maritual_status && (
                        <p className="text-red-500">{errorsBasic.maritual_status.message}</p>
                      )}
                        </div>
                      </div>
                    </div>
                    <div className="w-[16%] flex flex-col items-center gap-2">
                      {showPicView ? (
                        <>
                          <img
                            src={showPicView}
                            className="w-[160px] h-[160px] overflow-hidden rounded-[50%]"
                          />
                          {profilePicError && <p className="text-red-500">{profilePicError}</p>}
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={() =>
                                document.getElementById("picId").click()
                              }
                              className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
                            >
                              Update
                            </button>
                            <button
                              type="button"
                              onClick={deleteProfilePic}
                              className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
                            >
                              Remove
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-[160px] h-[160px]  border border-[#E2E8F0] rounded-[50%]"></div>
                          {profilePicError && <p className="text-red-500">{profilePicError}</p>}
                          <div>
                            <button
                              type="button"
                              onClick={() =>
                                document.getElementById("picId").click()
                              }
                              className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] bg-[#FFFFFF] rounded-md"
                            >
                              Add Photo
                            </button>
                          </div>
                        </>
                      )}
                      <InputField
                        type="file"
                        id="picId"
                        className="hidden"
                        onChange={profilePicUpdation}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-[#FAF8FC] px-6 py-[1rem] rounded-xl md:hidden">
                  <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                    Basic Details
                  </h4>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Basic Details Update"}
                      height={400}
                      content={
                        <>
                          <div className="md:hidden">
                            <div className="flex justify-between w-[100%] gap-2 p-6">
                              <div className="w-[100%]">
                                <div className="flex flex-col gap-1.5">
                                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                                    Name
                                  </h4>
                                   <InputField
                                      type="text"
                                      name="full_name"
                                      placeholder="Enter your Name"
                                      register={registerFormBasic}
                                      validation={{
                                        required: "Name is required",
                                        minLength: { value: 3, message: "Name must be at least 3 characters long" }
                                      }}
                                      className="!border !border-[#E2E8F0] rounded-lg w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                                    />
                                  {errorsBasic.full_name && (
                                    <p className="text-red-500">{errorsBasic.full_name.message}</p>
                                  )}
                                </div>
                                <div className="flex flex-col gap-1.5 mt-[1rem]">
                                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                                    Father’s Name
                                  </h4>
                                   <InputField
                                      type="text"
                                      name="fathers_name"
                                      placeholder="Enter your Father's Name"
                                      register={registerFormBasic}
                                      validation={
                                        {
                                          required: "Father’s name is required",
                                          minLength: { value: 3, message: "Father’s name must be at least 3 characters long" }
                                        }
                                      }
                                      className="!border !border-[#E2E8F0] rounded-lg w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none"
                                    />
                                    {errorsBasic.fathers_name && (
                                      <p className="text-red-500">{errorsBasic.fathers_name.message}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-1.5 mt-[1rem]">
                                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                                    Date of Birth
                                  </h4>
                                  <div className="relative w-[100%]">
                                   <InputField
                                      type="date"
                                      name="date_of_birth"
                                      placeholder="DOB"
                                      register={registerFormBasic}
                                      validation={
                                        {
                                          required: "Date of Birth is required",
                                        } 
                                      }
                                       className=" !border !border-[#E2E8F0] rounded-sm w-full text-[#334155] py-1.5 px-3 focus:outline-none bg-[#F8FAFC] pr-10"
                                    />
                                    {errorsBasic.date_of_birth && (
                                      <p className="text-red-500">{errorsBasic.date_of_birth.message}</p>
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1.5 mt-[1rem]">
                                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                                    Gender
                                  </h4>
                                    <InputField
                                      type="select"
                                      name="gender"
                                      register={registerFormBasic}
                                      options={[
                                        { value: "", label: "Select an option" },
                                        {value:1, label: "Male" },
                                        {value:2, label: "Female" },
                                        {value:3, label: "Other" },
                                      ]}
                                      validation={
                                        {
                                          required: "Gender is required"
                                        }
                                      }
                                     className="!border !border-[#E2E8F0] rounded-lg w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none bg-[#F8FAFC]"
                                  />
                                  {errorsBasic.gender && (
                                    <p className="text-red-500">{errorsBasic.gender.message}</p>
                                  )}
                                </div>
                                <div className="flex flex-col gap-1.5 mt-[1rem]">
                                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                                    Marital Status
                                  </h4>
                                  <InputField
                                      type="select"
                                      name="maritual_status"
                                      register={registerFormBasic}
                                      validation={
                                        {
                                          required: "Marital status is required"
                                        }
                                      }
                                      options={[
                                        { value: "", label: "Select an option" },
                                        {value:1, label: "Married" },
                                        {value:2, label: "Unmarried" },
                                        {value:3, label: "Prefer not to disclose" },
                                      ]}
                                     className="!border !border-[#E2E8F0] rounded-lg w-[100%] text-[#334155] py-1.5 px-1.5 focus:outline-none bg-[#F8FAFC]"
                                  />
                                  {errorsBasic.maritual_status && (
                                    <p className="text-red-500">{errorsBasic.maritual_status.message}</p>
                                  )}
                                </div>
                                <div className="mt-[1rem] w-[100%] flex items-center justify-between">
                                  <button
                                    type="button"
                                    onClick={submitingBasicForm}
                                    className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                                  >
                                    Update
                                  </button>
                                  <button
                                    type="button"
                                    onClick={changingToView}
                                    className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 1 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <>
            {showPicView ? (
              <>
                <div className="md:hidden flex flex-col justify-center items-center gap-2">
                  <img
                    src={showPicView}
                    className="border-2 border-[#9065B4] rounded-[50%]"
                    height="30%"
                    width="30%"
                  />
                  <div className="flex gap-2">
                    <button
                      onClick={() =>
                        document.getElementById("viewPicId").click()
                      }
                      className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Update
                    </button>
                    <button
                      onClick={deleteProfilePic}
                      className="border border-[#E2E8F0] text-[#DC2626] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="md:hidden flex flex-col justify-center items-center gap-2">
                  <div className="h-[100px] w-[30%] border-2 border-[#9065B4] rounded-[50%]"></div>
                  <div className="">
                    <button
                      onClick={() =>
                        document.getElementById("viewPicId").click()
                      }
                      className="border border-[#E2E8F0] text-[#334155] py-2 px-[0.7rem] font-[600] bg-[#FFFFFF] rounded-md"
                    >
                      Add Photo
                    </button>
                  </div>
                </div>
              </>
            )}
              <InputField
                type="file"
                id="viewPicId"
                className="hidden"
                onChange={profilePicUpdation}
              />
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-0 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Basic Details
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEdit}
                >
                  EDIT
                </button>
              </div>
              {/* mobileView */}
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileBasicExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Basic Details
                </h4>
                <div
                  className={`${mobileBasicExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEdit();
                    openingDrawer(1);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingBasicDetailsView}
                  className={`${mobileBasicExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`md:flex justify-between w-[100%] gap-2 p-6 ${
                  mobileBasicExpand ? "block" : "hidden"
                }`}
              >
                <div className="md:w-[79%]">
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 md:gap-0">
                    <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                      Name
                    </h4>
                    <div
                      className={`!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-md py-1 px-1.5 md:w-[38%] w-[100%] ${
                        getProfileData?.data?.full_name ? "text-[#334155]" : "text-gray-400"
                      }`}
                    >
                    {getProfileData?.data?.full_name
                      ? getProfileData?.data?.full_name
                      : "Enter your Name"}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 md:gap-0 mt-[1rem]">
                    <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                      Father’s Name
                    </h4>
                    <div
                        className={`!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-md py-1 px-1.5 md:w-[38%] w-[100%] ${
                          getProfileData?.data?.fathers_name ? "text-[#334155]" : "text-gray-400"
                        }`}
                      >
                    {getProfileData?.data?.fathers_name
                      ? getProfileData?.data?.fathers_name
                      : "Enter Fathers Name"}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 md:gap-0 mt-[1rem]">
                    <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                      Date of Birth
                    </h4>
                    <div
                      className={`!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-md py-1 px-1.5 md:w-[38%] w-[100%] ${
                        getProfileData?.data?.date_of_birth ? "text-[#334155]" : "text-gray-400"
                      }`}
                    >
                    {getProfileData?.data?.date_of_birth
                      ? getProfileData?.data?.date_of_birth
                      : "Enter Date of birth"}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 md:gap-0 mt-[1rem]">
                    <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                      Gender
                    </h4>
                    <div
                      className={`!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-md py-1 px-1.5 md:w-[38%] w-[100%] ${
                        getProfileData?.data?.gender ? "text-[#334155]" : "text-gray-400"
                      }`}
                    >
                    {getProfileData?.data?.gender === 1
                        ? "Male"
                        : getProfileData?.data?.gender === 2
                        ? "Female"
                        : getProfileData?.data?.gender === 3
                        ? "Others"
                        : "Gender not specified"}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-1 md:gap-0 mt-[1rem]">
                    <h4 className="text-[#64748B] text-[1rem] font-[500] m-0">
                      Marital Status
                    </h4>
                    <div
                        className={`!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-md py-1 px-1.5 md:w-[38%] w-[100%] ${
                          getProfileData?.data?.maritual_status ? "text-[#334155]" : "text-gray-400"
                        }`}
                      >
                    {getProfileData?.data?.maritual_status === 1
                        ? "Married"
                        : getProfileData?.data?.maritual_status === 2
                        ? "Unmarried"
                        : getProfileData?.data?.maritual_status === 3
                        ? "Prefer not to disclose"
                        : "Marital status not provided"}
                    </div>
                  </div>
                </div>
                <div className="w-[18%] md:flex justify-center hidden ">
                  {
                    showPicView ? <img
                    src={showPicView}
                    className="w-[180px] h-[180px] overflow-hidden rounded-[50%]"
                  /> : <div className="w-[180px] h-[180px]  border border-[#E2E8F0] rounded-[50%]"></div>
                  }

            {/* {showPicView ? (
                  <div className="flex justify-center">
                    <img
                    src={showPicView}
                    className="w-[100%] h-[75%] overflow-hidden rounded-[50%]"
                  />
                  </div> 
            ) : (
              <div className="flex flex-col gap-2 items-center justify-center">
              <div className="w-[150px] h-[150px] border border-[#E2E8F0] rounded-full"></div>
               <div>
                <button
                  type="button"
                  onClick={() =>
                    document.getElementById("viewPicIdLanding").click()
                  }
                  className="border border-[#E2E8F0] text-[#334155] py-2 px-[1.2rem] bg-[#FFFFFF] rounded-md"
                >
                  Add Photo
                </button>
               </div>
              </div>
            )}
              <InputField
                type="file"
                id="viewPicIdLanding"
                className="hidden"
                onChange={profilePicUpdation}
              /> */}
                </div>
              </div>
            </div>
          </>
        )}
        {addressDetails ? (
          <form>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] mt-5 hidden md:block">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <button
                  type="button"
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={addressFormSubmission}
                >
                  DONE
                </button>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address
                  </p>
                  <div className="flex gap-[1rem] w-[36%] items-center justify-end">
                    <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                        {/* <InputField
                          type="radio"
                          name="addresses[0].rent_status" // Use the same name for the group of radio buttons
                          value={1} // Set the value for this radio button
                          register={registerFormAddress} // Registering with React Hook Form
                          className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        /> */}
                      <input
                        type="radio"
                        value={1}
                        {...registerFormAddress("addresses[0].rent_status")}
                        // checked={getProfileData?.data?.profile_address[0].rent_status == 1}
                        className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                      />
                      Owned
                    </label>
                    <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                        {/* <InputField
                          type="radio"
                          name="addresses[0].rent_status" // Use the same name for the group of radio buttons
                          value={2} // Set the value for this radio button
                          register={registerFormAddress} // Registering with React Hook Form
                          className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        /> */}
                      <input
                        type="radio"
                        value={2}
                        // checked={getProfileData?.data?.profile_address[0].rent_status == 2}
                        {...registerFormAddress("addresses[0].rent_status")}
                        className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                      />
                      Rented
                    </label>
                    {rentStatus0 == 2 && (
                      <div className="">
                          <InputField
                            type="file"
                            accept=".pdf"
                            register={registerFormAddress}
                            name="addresses[0].rental_agreement"
                            onChange={handleFileChange}
                            id="rental_agreement"
                            className="hidden" // Custom class to hide the input
                          />
                        <label
                          htmlFor="rental_agreement"
                          className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md cursor-pointer inline-block"
                        >
                          Upload Rental Agreement
                        </label>
                      </div>
                    )}
                    {/* <p className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md">
                      Upload Rental Agreement
                    </p> */}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Flat / Door No.
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <InputField
                         type="text"
                         name="addresses[0].door_no"
                         onChange={handleInputChange}
                         placeholder="Flat / Door No."
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Flat / Door No. is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                              message: "Invalid Flat / Door No.",
                            },
                            minLength: {
                              value: 3,
                              message: "Door number should be at least 3 characters",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[0]?.door_no && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].door_no.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                      Premise Name
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                       <InputField
                         type="text"
                         name="addresses[0].permise_name"
                         onChange={handleInputChange}
                         placeholder="Premise Name"
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Premise Name is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                              message: "Invalid Premise Name",
                            },
                            minLength: {
                              value: 3,
                              message: "Premise Name should be at least 3 characters",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[0]?.permise_name && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].permise_name.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                      Road / Street
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                       <InputField
                         type="text"
                         name="addresses[0].street"
                         onChange={handleInputChange}
                         validation={
                          {
                            required: "Road / Street is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                              message: "Invalid Road / Street",
                            },
                            minLength: {
                              value: 3,
                              message: "Street name should be at least 3 characters",
                            },
                          }
                         }
                         placeholder="Road / Street"
                         register={registerFormAddress}
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[0]?.street && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].street.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Area Locality*
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                       <InputField
                         type="text"
                         name="addresses[0].area"
                         onChange={handleInputChange}
                         validation={{
                          required: "Area Locality is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                            message: "Invalid Area Locality (only letters, numbers, spaces, hyphens, commas, and periods allowed)",
                          },
                        }}
                         placeholder="Area Locality"
                         register={registerFormAddress}
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[0]?.area && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].area.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                      Pincode / Zipcode
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                       <InputField
                         type="number"
                         name="addresses[0].pincode"
                         onChange={handleInputChange}
                         placeholder="Pincode/Zipcode"
                         validation={
                          {
                            required: "Pincode is required",
                            pattern: {
                              value: /^[0-9]{6}$/,
                              message: "Pincode must be exactly 6 digits",
                            },
                          }
                         }
                         register={registerFormAddress}
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[0]?.pincode && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].pincode.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Town / City
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                       <InputField
                         type="text"
                         name="addresses[0].city"
                         onChange={handleInputChange}
                         placeholder="Town/City"
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Town/City is required",
                            minLength: {
                              value: 3,
                              message: "City name should be at least 3 characters",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[0]?.city && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].city.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      State
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                         <InputField
                           type="select"
                           onChange={handleInputChange}
                           name="addresses[0].state"
                           placeholder="State"
                           register={registerFormAddress}
                           validation={
                            {
                              required: "State is required",
                            }
                           }
                           options={[
                            { value: "", label: "Select an Option", disabled:true },
                            { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                            { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                            { value: "Assam", label: "Assam" },
                            { value: "Bihar", label: "Bihar" },
                            { value: "Chhattisgarh", label: "Chhattisgarh" },
                            { value: "Goa", label: "Goa" },
                            { value: "Gujarat", label: "Gujarat" },
                            { value: "Haryana", label: "Haryana" },
                            { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                            { value: "Jharkhand", label: "Jharkhand" },
                            { value: "Karnataka", label: "Karnataka" },
                            { value: "Kerala", label: "Kerala" },
                            { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                            { value: "Maharashtra", label: "Maharashtra" },
                            { value: "Manipur", label: "Manipur" },
                            { value: "Meghalaya", label: "Meghalaya" },
                            { value: "Mizoram", label: "Mizoram" },
                            { value: "Nagaland", label: "Nagaland" },
                            { value: "Odisha", label: "Odisha" },
                            { value: "Punjab", label: "Punjab" },
                            { value: "Rajasthan", label: "Rajasthan" },
                            { value: "Sikkim", label: "Sikkim" },
                            { value: "Tamil Nadu", label: "Tamil Nadu" },
                            { value: "Telangana", label: "Telangana" },
                            { value: "Tripura", label: "Tripura" },
                            { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                            { value: "Uttarakhand", label: "Uttarakhand" },
                            { value: "West Bengal", label: "West Bengal" },
                            {
                              value: "Andaman and Nicobar Islands",
                              label: "Andaman and Nicobar Islands",
                            },
                            { value: "Chandigarh", label: "Chandigarh" },
                            {
                              value: "Dadra and Nagar Haveli and Daman and Diu",
                              label: "Dadra and Nagar Haveli and Daman and Diu",
                            },
                            { value: "Delhi", label: "Delhi" },
                            { value: "Lakshadweep", label: "Lakshadweep" },
                            { value: "Puducherry", label: "Puducherry" },
                          ]}
                           className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                         />
                      {addressErrors?.addresses?.[0]?.state && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].state.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Country
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                           type="select"
                           name="addresses[0].country"
                           register={registerFormAddress}
                           onChange={handleInputChange}
                           validation={
                            {
                              required: "Country is required",
                            }
                           }
                           placeholder="Country"
                           options={[
                            { value: "", label: "Select an option", disabled: true},
                            {label: "India" }
                           ]}
                           className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none"
                         />
                      {addressErrors?.addresses?.[0]?.country && (
                        <p className="text-red-500">
                          {addressErrors.addresses[0].country.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-[2rem]  text-[#334155] text-[0.9rem] font-[500]">
                  Permanent address
                  <div className="flex items-center mt-1">
                    <InputField
                      type="checkbox"
                      className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
                      onChange={handleCheckboxChange} // Pass the onChange handler
                      id="customCheckbox"
                    />
                    <label
                      htmlFor="customCheckbox"
                      className="ml-2 text-[#334155] text-[0.9rem] font-[500]"
                    >
                      Check if your communication address is your permanent
                      address
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Flat / Door No.
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                         type="text"
                         placeholder="Flat/Door No"
                         name="addresses[1].door_no"
                         validation={
                          {
                            required: "Flat / Door No. is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                              message: "Invalid Flat / Door No.",
                            },
                            minLength: {
                              value: 3,
                              message: "Door number should be at least 3 characters",
                            },
                          }
                         }
                         register={registerFormAddress}
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.door_no && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].door_no.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                      Premise Name
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                         type="text"
                         placeholder="Premise Name"
                         name="addresses[1].permise_name"
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Premise Name is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                              message: "Invalid Premise Name",
                            },
                            minLength: {
                              value: 3,
                              message: "Premise Name should be at least 3 characters",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.permise_name && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].permise_name.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                      Road / Street
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                         type="text"
                         placeholder="Road/Street"
                         name="addresses[1].street"
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Road / Street is required",
                            pattern: {
                              value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                              message: "Invalid Road / Street",
                            },
                            minLength: {
                              value: 3,
                              message: "Street name should be at least 3 characters",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.street && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].street.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Area Locality*
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                         type="text"
                         placeholder="Area Locality"
                         name="addresses[1].area"
                         register={registerFormAddress}
                         validation={{
                          required: "Area Locality is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                            message: "Invalid Area Locality (only letters, numbers, spaces, hyphens, commas, and periods allowed)",
                          },
                        }}
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.area && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].area.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                      Pincode / Zipcode
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                         type="number"
                         placeholder="Pincode/Zipcode"
                         name="addresses[1].pincode"
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Pincode is required",
                            pattern: {
                              value: /^[0-9]{6}$/,
                              message: "Pincode must be exactly 6 digits",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.pincode && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].pincode.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Town / City
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                         type="text"
                         placeholder="Town/City"
                         name="addresses[1].city"
                         register={registerFormAddress}
                         validation={
                          {
                            required: "Town/City is required",
                            minLength: {
                              value: 3,
                              message: "City name should be at least 3 characters",
                            },
                          }
                         }
                         className="text-[#334155] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.city && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].city.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      State
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                        <InputField
                           type="select"
                           name="addresses[1].state"
                           placeholder="State"
                           register={registerFormAddress}
                           validation={
                            {
                              required: "State is required",
                            }
                           }
                           options={[
                            { value: "", label: "Select an Option", disabled:true },
                            { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                            { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                            { value: "Assam", label: "Assam" },
                            { value: "Bihar", label: "Bihar" },
                            { value: "Chhattisgarh", label: "Chhattisgarh" },
                            { value: "Goa", label: "Goa" },
                            { value: "Gujarat", label: "Gujarat" },
                            { value: "Haryana", label: "Haryana" },
                            { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                            { value: "Jharkhand", label: "Jharkhand" },
                            { value: "Karnataka", label: "Karnataka" },
                            { value: "Kerala", label: "Kerala" },
                            { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                            { value: "Maharashtra", label: "Maharashtra" },
                            { value: "Manipur", label: "Manipur" },
                            { value: "Meghalaya", label: "Meghalaya" },
                            { value: "Mizoram", label: "Mizoram" },
                            { value: "Nagaland", label: "Nagaland" },
                            { value: "Odisha", label: "Odisha" },
                            { value: "Punjab", label: "Punjab" },
                            { value: "Rajasthan", label: "Rajasthan" },
                            { value: "Sikkim", label: "Sikkim" },
                            { value: "Tamil Nadu", label: "Tamil Nadu" },
                            { value: "Telangana", label: "Telangana" },
                            { value: "Tripura", label: "Tripura" },
                            { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                            { value: "Uttarakhand", label: "Uttarakhand" },
                            { value: "West Bengal", label: "West Bengal" },
                            {
                              value: "Andaman and Nicobar Islands",
                              label: "Andaman and Nicobar Islands",
                            },
                            { value: "Chandigarh", label: "Chandigarh" },
                            {
                              value: "Dadra and Nagar Haveli and Daman and Diu",
                              label: "Dadra and Nagar Haveli and Daman and Diu",
                            },
                            { value: "Delhi", label: "Delhi" },
                            { value: "Lakshadweep", label: "Lakshadweep" },
                            { value: "Puducherry", label: "Puducherry" },
                          ]}
                           className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                         />
                      {addressErrors?.addresses?.[1]?.state && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].state.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                      Country
                    </h6>
                    <div className="flex flex-col border-[#E2E8F0] border rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <InputField
                        type="select"
                        placeholder="Country"
                        name="addresses[1].country"
                        register={registerFormAddress}
                        validation={
                          {
                            required: "Country is required",
                          }
                         }
                        options={[
                          { value: "", label: "Select an option", disabled:true, className:"text-[#667085]"},
                          {label: "India" }
                        ]}
                        className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none"
                      />
                      {addressErrors?.addresses?.[1]?.country && (
                        <p className="text-red-500">
                          {addressErrors.addresses[1].country.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#FAF8FC] px-6 py-[1rem] rounded-xl md:hidden">
              <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                Address
              </h4>
              <div className="md:hidden">
                <CustomizableDrawer
                  // triggerElement={<img src={dots} alt="dotsPic" />}
                  heading={"Address"}
                  height={400}
                  content={
                    <>
                      <div className="p-3">
                        <div className="">
                          <p className="m-0 text-[#334155] text-[1rem] font-[500]">
                            Communication Address
                          </p>
                          <div className="flex gap-[1.5rem] items-center justify-normal mt-2">
                            <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                              {/* <InputField
                                type="radio"
                                name="addresses[0].rent_status" // Use the same name for the group of radio buttons
                                value={1} // Set the value for this radio button
                                register={registerFormAddress} // Registering with React Hook Form
                                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                              /> */}
                            <input
                              type="radio"
                              value={1}
                              {...registerFormAddress("addresses[0].rent_status")}
                              // checked={getProfileData?.data?.profile_address[0].rent_status == 1}
                              className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                            />
                              Owned
                            </label>
                            <label className="font-[500] text-[.9rem] text-[#334155] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                              {/* <InputField
                                type="radio"
                                name="addresses[0].rent_status" // Use the same name for the group of radio buttons
                                value={2} // Set the value for this radio button
                                register={registerFormAddress} // Registering with React Hook Form
                                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                              /> */}
                              <input
                                type="radio"
                                value={2}
                                // checked={getProfileData?.data?.profile_address[0].rent_status == 2}
                                {...registerFormAddress("addresses[0].rent_status")}
                                className="peer ml-1 mr-1 text-[#334155] text-[0.9rem] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                              />
                              Rented
                            </label>
                            {rentStatus0 == 2 && (
                              <div className="">
                                <InputField
                                  type="file"
                                  accept=".pdf"
                                  register={registerFormAddress}
                                  onChange={handleFileChange}
                                  name="addresses[0].rental_agreement"
                                  id="rental_agreement"
                                  className="hidden" // Custom class to hide the input
                                />
                                <label
                                  htmlFor="rental_agreement"
                                  className="m-0 text-[#64748B] text-[0.9rem] font-[500] bg-[#F1F5F9] px-2 py-1 rounded-md cursor-pointer inline-block"
                                >
                                  Upload Proof
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Flat / Door No.
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                                 <InputField
                                    type="text"
                                    placeholder="Flat/Door No."
                                    onChange={handleInputChange}
                                    name="addresses[0].door_no"
                                    register={registerFormAddress}
                                    validation={
                                      {
                                        required: "Flat / Door No. is required",
                                        pattern: {
                                          value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                          message: "Invalid Flat / Door No.",
                                        },
                                        minLength: {
                                          value: 3,
                                          message: "Door number should be at least 3 characters",
                                        },
                                      }
                                     }
                                    className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                  />
                            {addressErrors?.addresses?.[0]?.door_no && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].door_no.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                              Premise Name
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                                 <InputField
                                    type="text"
                                    placeholder="Premise Name"
                                    onChange={handleInputChange}
                                    name="addresses[0].permise_name"
                                    register={registerFormAddress}
                                    validation={
                                      {
                                        required: "Premise Name is required",
                                        pattern: {
                                          value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                          message: "Invalid Premise Name",
                                        },
                                        minLength: {
                                          value: 3,
                                          message: "Premise Name should be at least 3 characters",
                                        },
                                      }
                                     }
                                    className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                  />
                              {addressErrors?.addresses?.[0]?.permise_name && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].permise_name.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                              Road / Street
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="text"
                                  placeholder="Road/Street"
                                  name="addresses[0].street"
                                  onChange={handleInputChange}
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Road / Street is required",
                                      pattern: {
                                        value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                        message: "Invalid Road / Street",
                                      },
                                      minLength: {
                                        value: 3,
                                        message: "Street name should be at least 3 characters",
                                      },
                                    }
                                   }
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                            {addressErrors?.addresses?.[0]?.street && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].street.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Area Locality*
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                                <InputField
                                  type="text"
                                  placeholder="Area Locality"
                                  name="addresses[0].area"
                                  onChange={handleInputChange}
                                  validation={{
                                    required: "Area Locality is required",
                                    pattern: {
                                      value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                      message: "Invalid Area Locality (only letters, numbers, spaces, hyphens, commas, and periods allowed)",
                                    },
                                  }}
                                  register={registerFormAddress}
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                              {addressErrors?.addresses?.[0]?.area && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].area.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                              Pincode / Zipcode
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                                <InputField
                                  type="number"
                                  placeholder="Pincode/Zipcode"
                                  name="addresses[0].pincode"
                                  onChange={handleInputChange}
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Pincode is required",
                                      pattern: {
                                        value: /^[0-9]{6}$/,
                                        message: "Pincode must be exactly 6 digits",
                                      },
                                    }
                                   }
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                              {addressErrors?.addresses?.[0]?.pincode && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].pincode.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Town / City
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="text"
                                  placeholder="Town/City"
                                  name="addresses[0].city"
                                  onChange={handleInputChange}
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Town/City is required",
                                      minLength: {
                                        value: 3,
                                        message: "City name should be at least 3 characters",
                                      },
                                    }
                                   }
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                              {addressErrors?.addresses?.[0]?.city && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].city.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              State
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="select"
                                  placeholder="State"
                                  name="addresses[0].state"
                                  onChange={handleInputChange}
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "State is required",
                                    }
                                   }
                                   options={[
                                    { value: "", label: "Select an Option" },
                                    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                                    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                                    { value: "Assam", label: "Assam" },
                                    { value: "Bihar", label: "Bihar" },
                                    { value: "Chhattisgarh", label: "Chhattisgarh" },
                                    { value: "Goa", label: "Goa" },
                                    { value: "Gujarat", label: "Gujarat" },
                                    { value: "Haryana", label: "Haryana" },
                                    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                                    { value: "Jharkhand", label: "Jharkhand" },
                                    { value: "Karnataka", label: "Karnataka" },
                                    { value: "Kerala", label: "Kerala" },
                                    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                                    { value: "Maharashtra", label: "Maharashtra" },
                                    { value: "Manipur", label: "Manipur" },
                                    { value: "Meghalaya", label: "Meghalaya" },
                                    { value: "Mizoram", label: "Mizoram" },
                                    { value: "Nagaland", label: "Nagaland" },
                                    { value: "Odisha", label: "Odisha" },
                                    { value: "Punjab", label: "Punjab" },
                                    { value: "Rajasthan", label: "Rajasthan" },
                                    { value: "Sikkim", label: "Sikkim" },
                                    { value: "Tamil Nadu", label: "Tamil Nadu" },
                                    { value: "Telangana", label: "Telangana" },
                                    { value: "Tripura", label: "Tripura" },
                                    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                                    { value: "Uttarakhand", label: "Uttarakhand" },
                                    { value: "West Bengal", label: "West Bengal" },
                                    {
                                      value: "Andaman and Nicobar Islands",
                                      label: "Andaman and Nicobar Islands",
                                    },
                                    { value: "Chandigarh", label: "Chandigarh" },
                                    {
                                      value: "Dadra and Nagar Haveli and Daman and Diu",
                                      label: "Dadra and Nagar Haveli and Daman and Diu",
                                    },
                                    { value: "Delhi", label: "Delhi" },
                                    { value: "Lakshadweep", label: "Lakshadweep" },
                                    { value: "Puducherry", label: "Puducherry" },
                                  ]}
                                  className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                                />
                              {addressErrors?.addresses?.[0]?.state && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].state.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Country
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="select"
                                  placeholder="Country"
                                  name="addresses[0].country"
                                  onChange={handleInputChange}
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Country is required",
                                    }
                                   }
                                  options={[
                                    { value: "", label: "Select an option" },
                                    {label: "India" }
                                  ]}
                                  className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none"
                                />
                                {addressErrors?.addresses?.[0]?.country && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[0].country.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-[2rem]  text-[#334155] text-[1rem] font-[500]">
                          Permanent address
                          <div className="flex items-center mt-1">
                             <InputField
                                type="checkbox"
                                className="custom-checkbox h-5 w-5 rounded-md !border !border-[#9065B4] bg-[#F9F5FF] checked:bg-[#F9F5FF] checked:border-[#9065B4] focus:ring-0"
                                onChange={handleCheckboxChange} // Pass the onChange handler
                                id="customCheckbox"
                              />
                            <label
                              htmlFor="customCheckbox"
                              className="ml-2 text-[#334155] text-[0.9rem] font-[500]"
                            >
                              Check if your communication address is your
                              permanent address
                            </label>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-[10px] ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Flat / Door No.
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="text"
                                  placeholder="Flat/Door No."
                                  name="addresses[1].door_no"
                                  validation={
                                    {
                                      required: "Flat / Door No. is required",
                                      pattern: {
                                        value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                        message: "Invalid Flat / Door No.",
                                      },
                                      minLength: {
                                        value: 3,
                                        message: "Door number should be at least 3 characters",
                                      },
                                    }
                                   }
                                  register={registerFormAddress}
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                               {addressErrors?.addresses?.[1]?.door_no && (
                                  <p className="text-red-500">
                                    {addressErrors.addresses[1].door_no.message}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                              Premise Name
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="text"
                                  placeholder="Premise Name"
                                  name="addresses[1].permise_name"
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Premise Name is required",
                                      pattern: {
                                        value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                        message: "Invalid Premise Name",
                                      },
                                      minLength: {
                                        value: 3,
                                        message: "Premise Name should be at least 3 characters",
                                      },
                                    }
                                   }
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                                {addressErrors?.addresses?.[1]?.permise_name && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[1].permise_name.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                              Road / Street
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                                <InputField
                                  type="text"
                                  placeholder="Road/Street"
                                  name="addresses[1].street"
                                  validation={
                                    {
                                      required: "Road / Street is required",
                                      pattern: {
                                        value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                        message: "Invalid Road / Street",
                                      },
                                      minLength: {
                                        value: 3,
                                        message: "Street name should be at least 3 characters",
                                      },
                                    }
                                   }
                                  register={registerFormAddress}
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                              {addressErrors?.addresses?.[1]?.street && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[1].street.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Area Locality*
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="text"
                                  name="addresses[1].area"
                                  placeholder="Area Locality"
                                  register={registerFormAddress}
                                  validation={{
                                    required: "Area Locality is required",
                                    pattern: {
                                      value: /^[a-zA-Z0-9\s\-\,\.]{3,}$/,
                                      message: "Invalid Area Locality (only letters, numbers, spaces, hyphens, commas, and periods allowed)",
                                    },
                                  }}
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                              {addressErrors?.addresses?.[1]?.area && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[1].area.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#64748B] group-focus-within:text-customPurple">
                              Pincode / Zipcode
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="number"
                                  placeholder="Pincode/Zipcode"
                                  name="addresses[1].pincode"
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Pincode is required",
                                      pattern: {
                                        value: /^[0-9]{6}$/,
                                        message: "Pincode must be exactly 6 digits",
                                      },
                                    }
                                   }
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                                {addressErrors?.addresses?.[1]?.pincode && (
                                  <p className="text-red-500">
                                    {addressErrors.addresses[1].pincode.message}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-2 mt-[5px] ml-[-2px]">
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Town / City
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                               <InputField
                                  type="text"
                                  placeholder="Town/City"
                                  name="addresses[1].city"
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Town/City is required",
                                      minLength: {
                                        value: 3,
                                        message: "City name should be at least 3 characters",
                                      },
                                    }
                                   }
                                  className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                                />
                              {addressErrors?.addresses?.[1]?.city && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[1].city.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              State
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                                <InputField
                                  type="select"
                                  name="addresses[1].state"
                                  placeholder="State"
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "State is required",
                                    }
                                   }
                                   options={[
                                    { value: "", label: "Select an Option" },
                                    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                                    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                                    { value: "Assam", label: "Assam" },
                                    { value: "Bihar", label: "Bihar" },
                                    { value: "Chhattisgarh", label: "Chhattisgarh" },
                                    { value: "Goa", label: "Goa" },
                                    { value: "Gujarat", label: "Gujarat" },
                                    { value: "Haryana", label: "Haryana" },
                                    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                                    { value: "Jharkhand", label: "Jharkhand" },
                                    { value: "Karnataka", label: "Karnataka" },
                                    { value: "Kerala", label: "Kerala" },
                                    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                                    { value: "Maharashtra", label: "Maharashtra" },
                                    { value: "Manipur", label: "Manipur" },
                                    { value: "Meghalaya", label: "Meghalaya" },
                                    { value: "Mizoram", label: "Mizoram" },
                                    { value: "Nagaland", label: "Nagaland" },
                                    { value: "Odisha", label: "Odisha" },
                                    { value: "Punjab", label: "Punjab" },
                                    { value: "Rajasthan", label: "Rajasthan" },
                                    { value: "Sikkim", label: "Sikkim" },
                                    { value: "Tamil Nadu", label: "Tamil Nadu" },
                                    { value: "Telangana", label: "Telangana" },
                                    { value: "Tripura", label: "Tripura" },
                                    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                                    { value: "Uttarakhand", label: "Uttarakhand" },
                                    { value: "West Bengal", label: "West Bengal" },
                                    {
                                      value: "Andaman and Nicobar Islands",
                                      label: "Andaman and Nicobar Islands",
                                    },
                                    { value: "Chandigarh", label: "Chandigarh" },
                                    {
                                      value: "Dadra and Nagar Haveli and Daman and Diu",
                                      label: "Dadra and Nagar Haveli and Daman and Diu",
                                    },
                                    { value: "Delhi", label: "Delhi" },
                                    { value: "Lakshadweep", label: "Lakshadweep" },
                                    { value: "Puducherry", label: "Puducherry" },
                                  ]}
                                  className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
                                />
                              {addressErrors?.addresses?.[1]?.state && (
                                <p className="text-red-500">
                                  {addressErrors.addresses[1].state.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                            <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#64748B] group-focus-within:text-customPurple">
                              Country
                            </h6>
                            <div className="flex flex-col border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                              <InputField
                                  type="select"
                                  name="addresses[1].country"
                                  placeholder="Country"
                                  register={registerFormAddress}
                                  validation={
                                    {
                                      required: "Country is required",
                                    }
                                   }
                                  options={[
                                    { value: "", label: "Select an option" },
                                    {label: "India" }
                                  ]}
                                  className="text-[#334155] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none"
                                />
                                {addressErrors?.addresses?.[1]?.country && (
                                  <p className="text-red-500">
                                    {addressErrors.addresses[1].country.message}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-[1rem] w-[95%] flex items-center justify-between">
                          <button
                            type="button"
                            onClick={addressFormSubmission}
                            className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={changingToViewAddress}
                            className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  }
                  placement="bottom"
                  showDrawerWithOutTrigger={openDrawer === 2 ? true : false}
                  changingToViewDrawer={changingToViewDrawer}
                  bgColor="#FAF8FC"
                />
              </div>
            </div>
          </form>
        ) : (
          <>
            <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem]">
              <div className="md:flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl hidden">
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <button
                  className="!border !border-[#9065B4] rounded-md bg-white-body text-[0.9rem] py-1 px-1.5 text-[#9065B4]"
                  onClick={changingToEditAddress}
                >
                  EDIT
                </button>
              </div>
              {/* mobile view */}
              <div
                className={`flex justify-between items-center bg-[#FAF8FC] px-6 py-[1rem] md:hidden ${
                  mobileAddressExpand
                    ? "rounded-tl-xl rounded-tr-xl"
                    : "rounded-xl "
                }`}
              >
                <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
                  Address
                </h4>
                <div
                  className={`${mobileAddressExpand ? "block" : "hidden"}`}
                  onClick={() => {
                    changingToEditAddress();
                    openingDrawer(2);
                  }}
                >
                  <img src={editPen} alt="editPen" />
                </div>
                <div
                  onClick={expandingAdressDetailsView}
                  className={`${mobileAddressExpand ? "hidden" : "block"}`}
                >
                  <img src={plus} alt="plusPic" />
                </div>
              </div>
              <div
                className={`p-6 md:block ${
                  mobileAddressExpand ? "block" : "hidden"
                }`}
              >
                <div className="md:flex justify-between items-center hidden">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address
                  </p>
                  <div className="flex gap-[1rem] w-[28%] items-center justify-end">
                    <p className="m-0 text-[#334155] text-[0.9rem] font-[500] rounded-md bg-[#F1F5F9] py-1.5 px-2">
                      {getProfileData?.data?.profile_address?.length === 0 || 
                      !getProfileData?.data?.profile_address[0]?.rent_status
                        ? "Owned" // Default value if no address or rent status
                        : getProfileData?.data?.profile_address[0]?.rent_status === 1
                        ? "Owned"
                        : "Rented"}
                    </p>

                    <p className="m-0 text-[#64748B] text-[0.9rem] font-[500]">
                      {getProfileData?.data?.profile_address?.length > 0 && 
                      getProfileData?.data?.profile_address[0]?.rent_status === 2 && (
                        <a
                          href={
                            getProfileData?.data?.profile_address[0]?.rental_agreement || "#"
                          }
                          target={
                            getProfileData?.data?.profile_address[0]?.rental_agreement
                              ? "_blank"
                              : ""
                          }
                          className={`no-underline text-inherit ${
                            !getProfileData?.data?.profile_address[0]?.rental_agreement &&
                            "pointer-events-none text-gray-400"
                          }`}
                        >
                          View Rental Agreement
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 mb-4 flex-col md:hidden">
                  <p className="m-0 text-[#334155] text-[0.9rem] font-[500]">
                    Communication Address <span>[Rented]</span>
                  </p>
                  <p className="m-0 text-[#334155] text-[0.8rem] font-[500] rounded-lg bg-[#F1F5F9] py-2 px-2.5 w-[50%]">
                    View Address Proof
                  </p>
                </div>
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 1
                  </h4>
                  <div
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      getProfileData?.data?.profile_address?.length > 0 &&
                      (getProfileData?.data?.profile_address[0]?.door_no ||
                        getProfileData?.data?.profile_address[0]?.permise_name)
                        ? ""
                        : "text-gray-400"
                    }`}
                    >
                  {getProfileData?.data?.profile_address?.length > 0
                      ? (getProfileData?.data?.profile_address[0]?.door_no
                          ? getProfileData?.data?.profile_address[0]?.door_no + ", "
                          : "") +
                        (getProfileData?.data?.profile_address[0]?.permise_name
                          ? getProfileData?.data?.profile_address[0]?.permise_name
                          : "")
                      : "Door No, Premise Name (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 2
                  </h4>
                  <div
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      getProfileData?.data?.profile_address?.length > 0 &&
                      (getProfileData?.data?.profile_address[0]?.street ||
                        getProfileData?.data?.profile_address[0]?.area || getProfileData?.data?.profile_address[0]?.pincode  )
                        ? ""
                        : "text-gray-400"
                    }`}
                    >
                  {getProfileData?.data?.profile_address?.length > 0
                    ? (getProfileData?.data?.profile_address[0]?.street
                        ? getProfileData?.data?.profile_address[0]?.street + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[0]?.area
                        ? getProfileData?.data?.profile_address[0]?.area + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[0]?.pincode
                        ? getProfileData?.data?.profile_address[0]?.pincode
                        : "")
                    : "Street, Area, Pincode (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 3
                  </h4>
                  <div
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      getProfileData?.data?.profile_address?.length > 0 &&
                      (getProfileData?.data?.profile_address[0]?.city ||
                        getProfileData?.data?.profile_address[0]?.state || getProfileData?.data?.profile_address[0]?.country)
                        ? ""
                        : "text-gray-400"
                    }`}
                    >
                  {getProfileData?.data?.profile_address?.length > 0
                    ? (getProfileData?.data?.profile_address[0]?.city
                        ? getProfileData?.data?.profile_address[0]?.city + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[0]?.state
                        ? getProfileData?.data?.profile_address[0]?.state + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[0]?.country
                        ? getProfileData?.data?.profile_address[0]?.country
                        : "")
                    : "City, State, Country (Incomplete)"}
                  </div>
                </div>
                <div className="mt-[2rem] md:mb-[1.5rem] mb-[1rem] text-[#334155] text-[0.9rem] font-[500] ">
                  Permanent Address
                </div>
                <div className="flex flex-col gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 1
                  </h4>
                  <div
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      getProfileData?.data?.profile_address?.length > 0 &&
                      (getProfileData?.data?.profile_address[0]?.door_no ||
                        getProfileData?.data?.profile_address[0]?.permise_name)
                        ? ""
                        : "text-gray-400"
                    }`}
                    >
                  {getProfileData?.data?.profile_address?.length > 0
                    ? (getProfileData?.data?.profile_address[1]?.door_no
                        ? getProfileData?.data?.profile_address[1]?.door_no + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[1]?.permise_name
                        ? getProfileData?.data?.profile_address[1]?.permise_name
                        : "")
                    : "Door No, Premise Name (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 2
                  </h4>
                  <div
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      getProfileData?.data?.profile_address?.length > 0 &&
                      (getProfileData?.data?.profile_address[0]?.street ||
                        getProfileData?.data?.profile_address[0]?.area || getProfileData?.data?.profile_address[0]?.pincode)
                        ? ""
                        : "text-gray-400"
                    }`}
                    >
                  {getProfileData?.data?.profile_address?.length > 0
                    ? (getProfileData?.data?.profile_address[1]?.street
                        ? getProfileData?.data?.profile_address[1]?.street + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[1]?.area
                        ? getProfileData?.data?.profile_address[1]?.area + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[1]?.pincode
                        ? getProfileData?.data?.profile_address[1]?.pincode
                        : "")
                    : "Street, Area, Pincode (Incomplete)"}
                  </div>
                </div>
                <div className="flex flex-col mt-2.5 md:mt-0 gap-1.5">
                  <h4 className="text-[#64748B] text-[1rem] font-[500] m-0 md:hidden">
                    Address Line - 3
                  </h4>
                  <div
                    className={`bg-[#F8FAFC] text-[#334155] w-full py-1 px-1.5 md:mt-2.5 font-[400] border-[1px] border-[#E2E8F0] md:border-[#F8FAFC] md:border rounded-lg md:rounded-none overflow-hidden whitespace-nowrap ${
                      getProfileData?.data?.profile_address?.length > 0 &&
                      (getProfileData?.data?.profile_address[0]?.city ||
                        getProfileData?.data?.profile_address[0]?.state || getProfileData?.data?.profile_address[0]?.country )
                        ? ""
                        : "text-gray-400"
                    }`}
                    >
                  {getProfileData?.data?.profile_address?.length > 0
                    ? (getProfileData?.data?.profile_address[1]?.city
                        ? getProfileData?.data?.profile_address[1]?.city + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[1]?.state
                        ? getProfileData?.data?.profile_address[1]?.state + ", "
                        : "") +
                      (getProfileData?.data?.profile_address[1]?.country
                        ? getProfileData?.data?.profile_address[1]?.country
                        : "")
                    : "City, State, Country (Incomplete)"}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="bg-white-body rounded-xl border border-[#E2E8F0] md:mt-5 mt-[1.5rem] md:mb-5 mb-[5rem]">
          <div
            className={`bg-[#FAF8FC] px-6 py-[1rem] rounded-tl-xl rounded-tr-xl flex justify-between ${
              mobileAccountExpand ? "rounded-tl-xl rounded-tr-xl" : "rounded-xl"
            }`}
          >
            <h4 className="text-[#334155] text-[1.1rem] font-[500] m-0">
              Account Settings
            </h4>
            <img
              src={plus}
              alt="plusPic"
              className={`md:hidden ${
                mobileAccountExpand ? "hidden" : "block"
              }`}
              onClick={expandingAccountDetailsView}
            />
          </div>
          <div
            className={`md:grid md:grid-cols-12 p-6 md:gap-[3rem] flex flex-col gap-3 ${
              mobileAccountExpand ? "block" : "hidden"
            }`}
          >
            <div className="md:col-span-4">
              {passwordDetails ? (
                <form>
                  <div className="md:block hidden">
                    <div>
                      <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                        Current Password
                      </h6>
                      <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                           <InputField
                              type="password"
                              name="old_password"
                              register={registerPass}
                              validation={{ required: "Current password is required" }} // Validation rules
                              placeholder="********" // Placeholder for the password input
                              className="w-full bg-transparent border-none outline-none text-[#334155]"
                            />
                        <img src={eye} alt="eye" />
                      </div>
                      {errors.old_password && (
                        <p className="text-red-500 text-sm">
                          {errors.old_password.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-3">
                      <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                        New Password
                      </h6>
                      <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                           <InputField
                              type="password"
                              name="new_password"
                              register={registerPass}
                              validation={{
                                required: "New password is required",
                                minLength: {
                                  value: 8,
                                  message: "Must be 8 characters or more",
                                },
                              }} // Validation rules
                              placeholder="********" // Placeholder for the password input
                              className="w-full bg-transparent border-none outline-none text-[#334155]"
                            />
                        <img src={eye} alt="eye" />
                      </div>
                      {errors.new_password && (
                        <p className="text-red-500 text-sm">
                          {errors.new_password.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-3">
                      <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                        Confirm New Password
                      </h6>
                      <div className="!border !border-[#E2E8F0] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                           <InputField
                              type="password"
                              name="confirm_new_password"
                              register={registerPass}
                              validation={{
                                required: "Confirm new password is required",
                                validate: (value) =>
                                  value === watchPass("new_password") ||
                                  "Passwords do not match",
                              }} // Validation rules
                              placeholder="********" // Placeholder for the password input
                              className="w-full bg-transparent border-none outline-none text-[#334155]"
                            />
                        <img src={eye} alt="eye" />
                      </div>
                      {errors.confirm_new_password && (
                        <p className="text-red-500 text-sm">
                          {errors.confirm_new_password.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-3 flex items-center justify-between">
                      <button
                        type="button"
                        className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                        onClick={() => {
                          passwordFormSubmission();
                        }}
                      >
                        Update Password
                      </button>
                      <button
                        type="button"
                        className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                        onClick={changeToViewPass}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Password"}
                      height={400}
                      content={
                        <>
                          <div className="p-3">
                            <div>
                              <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                                Current Password
                              </h6>
                              <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                                  <InputField
                                    type="password"
                                    name="old_password"
                                    register={registerPass}
                                    validation={{ required: "Current password is required" }} // Validation rules
                                    placeholder="********" // Placeholder for the password input
                                    className="w-full bg-transparent border-none outline-none text-[#334155]"
                                  />
                                <img src={eye} alt="eye" />
                              </div>
                              {errors.old_password && (
                                <p className="text-red-500 text-sm">
                                  {errors.old_password.message}
                                </p>
                              )}
                            </div>
                            <div className="mt-3">
                              <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                                New Password
                              </h6>
                              <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                                  <InputField
                                    type="password"
                                    name="new_password"
                                    register={registerPass}
                                    validation={{
                                      required: "New password is required",
                                      minLength: {
                                        value: 8,
                                        message: "Must be 8 characters or more",
                                      },
                                    }} // Validation rules
                                    placeholder="********" // Placeholder for the password input
                                    className="w-full bg-transparent border-none outline-none text-[#334155]"
                                  />
                                <img src={eye} alt="eye" />
                              </div>
                              <h6 className="m-0 text-[#64748B] text-[0.8rem] mt-1 text-right w-[98%]">
                                Must be 8 characters or more
                              </h6>
                              {errors.new_password && (
                                <p className="text-red-500 text-sm">
                                  {errors.new_password.message}
                                </p>
                              )}
                            </div>
                            <div className="mt-3">
                              <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                                Confirm New Password
                              </h6>
                              <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                                 <InputField
                                    type="password"
                                    name="confirm_new_password"
                                    register={registerPass}
                                    validation={{
                                      required: "Confirm new password is required",
                                      validate: (value) =>
                                        value === watchPass("new_password") ||
                                        "Passwords do not match",
                                    }} // Validation rules
                                    placeholder="********" // Placeholder for the password input
                                    className="w-full bg-transparent border-none outline-none text-[#334155]"
                                 />
                                <img src={eye} alt="eye" />
                              </div>
                              <h6 className="m-0 text-[#64748B] text-[0.8rem] mt-1 text-right w-[98%]">
                                Password label
                              </h6>
                              {errors.confirm_new_password && (
                                <p className="text-red-500 text-sm">
                                  {errors.confirm_new_password.message}
                                </p>
                              )}
                            </div>
                            <div className="mt-3 flex items-center justify-between">
                              <button
                                className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                                onClick={passwordFormSubmission}
                              >
                                Update Password
                              </button>
                              <button
                                className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                                onClick={changeToViewPass}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </>
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 3 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </form>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Password
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0">****************</p>
                    <div className="flex items-center justify-center gap-1">
                      <img src={eye} alt="eye" />
                      <img
                        src={edit_icon}
                        alt="editPen"
                        className="md:hidden"
                        onClick={() => {
                          changeToEditPass();
                          openingDrawer(3);
                        }}
                      />
                    </div>
                  </div>
                  <h6
                    className="m-0 text-[#64748B] text-[0.8rem] mt-1 text-right w-[98%] cursor-pointer hidden md:block"
                    onClick={() => {
                      changeToEditPass();
                    }}
                  >
                    Change Password
                  </h6>
                </>
              )}
            </div>
            <div className="md:col-span-4">
              {phoneDetails ? (
                <form>
                  <div className="hidden md:block">
                    <div>
                      <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                        Phone Number
                      </h6>
                      <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                           <InputField
                              type="number"
                              name="mobile_number"
                              register={registerMobile}
                              validation={{
                                required: "Phone number is required",
                              }} // Validation rules
                              placeholder="Enter Phone No."
                              className="w-full text-[0.9rem] bg-transparent outline-none"
                            />
                      </div>
                      {errorsMobile.mobile_number && (
                        <p className="text-red-500">{errorsMobile.mobile_number.message}</p>
                      )}
                    </div>
                    {otpSent && (
                      <div className="mt-3">
                        <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                          OTP
                        </h6>
                        <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                            <InputField
                              type="number"
                              name="otp"
                              register={registerMobile}
                              validation={{
                                required: "OTP is required",
                              }} // Validation rules
                              placeholder="Enter OTP."
                              className="w-full text-[0.9rem] bg-transparent outline-none"
                            />
                        </div>
                        {errorsMobile.otp && <p>{errorsMobile.otp.message}</p>}
                      </div>
                    )}
                    <div className="mt-3 flex items-center justify-between">
                      <button
                        type="button"
                        className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                        onClick={mobileFormSubmission}
                      >
                        Update Phone
                      </button>
                      <button
                        type="button"
                        className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                        onClick={changeToViewPhone}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Mobile Number"}
                      height={400}
                      content={
                        <>
                          <div>
                            <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                              Phone Number
                            </h6>
                            <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                              <InputField
                                type="number"
                                name="mobile_number"
                                register={registerMobile}
                                validation={{
                                  required: "Phone number is required",
                                }} // Validation rules
                                placeholder="Enter Phone No."
                                className="w-full text-[0.9rem] bg-transparent outline-none"
                              />
                            </div>
                            {errors.mobile_number && (
                              <p>{errors.mobile_number.message}</p>
                            )}
                          </div>
                          {otpSent && (
                            <div className="mt-3">
                              <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                                OTP
                              </h6>
                              <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                                     <InputField
                                        type="number"
                                        name="otp"
                                        register={registerMobile}
                                        validation={{
                                          required: "OTP is required",
                                        }} // Validation rules
                                        placeholder="Enter OTP."
                                        className="w-full text-[0.9rem] bg-transparent outline-none"
                                      />
                              </div>
                              {errors.otp && <p>{errors.otp.message}</p>}
                            </div>
                          )}
                          <div className="mt-3 flex items-center justify-between">
                            <button
                              className="bg-[#9065B4] border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                              onClick={mobileFormSubmission}
                            >
                              Update Phone
                            </button>
                            <button
                              className="bg-[#FFFFFF] border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                              onClick={changeToViewPhone}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 4 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </form>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Phone Number
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0 text-[0.9rem]">
                      {getProfileData?.data?.mobile_number ? getProfileData?.data?.mobile_number:"Enter Phone No."}
                    </p>
                    <p
                      className="m-0 text-[0.7rem] text-[#9065B4] cursor-pointer hidden md:block"
                      onClick={changeToEditPhone}
                    >
                      EDIT
                    </p>
                    <img
                      src={edit_icon}
                      alt="editPen"
                      className="md:hidden"
                      onClick={() => {
                        changeToEditPhone();
                        openingDrawer(4);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="md:col-span-4">
              {emailDetails ? (
                <form>
                  <div className="hidden md:block">
                    <div>
                      <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                        Email Address
                      </h6>
                      <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                        {/* <input
                          {...registerEmail("email", {
                            required: "Email is required",
                          })}
                          className="w-full text-[0.9rem] bg-transparent outline-none"
                          placeholder="Enter Email."
                        /> */}
                           <InputField
                              type="text"
                              name="email"
                              register={registerEmail}
                              validation={{
                                required: "Email is required",
                              }} // Validation rules
                              placeholder="Enter Email."
                              className="w-full text-[0.9rem] bg-transparent outline-none"
                            />
                      </div>
                      {errorsEmail.email && <p>{errorsEmail.email.message}</p>}
                    </div>
                    {otpSentEmail && (
                      <div className="mt-3">
                        <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                          OTP
                        </h6>
                        <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                          {/* <p className="m-0 text-[0.9rem]">+91-9876543210</p>
                           */}
                          {/* <input
                            {...registerEmail("otp", {
                              required: "OTP is required",
                            })}
                            className="w-full text-[0.9rem] bg-transparent outline-none"
                            placeholder="Enter OTP."
                          /> */}
                           <InputField
                              type="text"
                              name="otp"
                              register={registerEmail}
                              validation={{
                                required: "OTP is required",
                              }} // Validation rules
                              placeholder="Enter OTP."
                              className="w-full text-[0.9rem] bg-transparent outline-none"
                            />
                        </div>
                        {errorsEmail.otp && <p>{errorsEmail.otp.message}</p>}
                      </div>
                    )}
                    <div className="mt-3 flex items-center justify-between">
                      <button
                        type="button"
                        className="bg-[#9065B4] cursor-pointer border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                        onClick={emailFormSubmission}
                      >
                        Update Email
                      </button>
                      <button
                        type="button"
                        className="bg-[#FFFFFF] cursor-pointer border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                        onClick={changeToViewEmail}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="md:hidden">
                    <CustomizableDrawer
                      // triggerElement={<img src={dots} alt="dotsPic" />}
                      heading={"Mail Address"}
                      height={400}
                      content={
                        <>
                          <div>
                            <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                              Email Address
                            </h6>
                            <div className="!border !border-[#E2E8F0]  rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                              <p className="m-0 text-[0.9rem]">
                                Dheerajrao@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center justify-between">
                            <button
                              className="bg-[#9065B4] cursor-pointer border border-[#9065B4] text-[#FFFFFF] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                              onClick={changeToViewEmail}
                            >
                              Update Phone
                            </button>
                            <button
                              className="bg-[#FFFFFF] cursor-pointer border border-[#94A3B8] text-[#334155] rounded-md px-2.5 py-1.5 w-[48%] text-[0.9rem]"
                              onClick={changeToViewEmail}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      }
                      placement="bottom"
                      showDrawerWithOutTrigger={openDrawer === 5 ? true : false}
                      changingToViewDrawer={changingToViewDrawer}
                      bgColor="#FAF8FC"
                    />
                  </div>
                </form>
              ) : (
                <>
                  <h6 className="m-0 text-[#64748B] text-[0.8rem] mb-1">
                    Email Address
                  </h6>
                  <div className="!border !border-[#E2E8F0] bg-[#F8FAFC] rounded-sm py-1 px-1.5 w-[98%] text-[#334155] flex items-center justify-between">
                    <p className="m-0 text-[0.9rem]">
                      {getProfileData?.data?.email ? getProfileData?.data?.email : "Enter Email"}
                    </p>
                    <p
                      className="m-0 text-[0.7rem] text-[#9065B4] cursor-pointer hidden md:block"
                      onClick={changeToEditEmail}
                    >
                      EDIT
                    </p>
                    <img
                      src={edit_icon}
                      alt="editPen"
                      className="md:hidden"
                      onClick={() => {
                        changeToEditEmail();
                        openingDrawer(5);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomeTaxProfile;
