import React, { useEffect, useState } from "react";
import { Space, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import usePostSendPanOTP from "./Data/usePostSendPanOTP";
import PanCardOtpDialog from "./PanCardOtpDialog";
import SuccessIcon from "assets/images/incomeTax/SuccessIcon.png";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";


const EnterPanDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [panNumber, setPanNumber] = useState("");
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, error, isLoading, sendPanOtp] = usePostSendPanOTP();

  useEffect(() => {
    if (data) {
      showSuccessToast("OTP sent successfully")
      setShowOtpDialog(true);
      // console.log("redirecting to otp dialog box:")
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      let desc= error.message || "Try again in sometime.";
      showErrorToast("",desc)
    }
  }, [error]);

  const handleLinkPan = () => {
    if (panNumber) {
      console.log("PAN Number:", panNumber);
      user.pan_no = panNumber;
      console.log("user pan no:", user.pan_no);

      localStorage.setItem("user", JSON.stringify(user));

      if (user && user.email) {
        sendPanOtp({ pan_number: user.pan_no });
      }
    } else {
      showErrorToast("","Please enter a valid PAN number")
    }
  };

  const handlePanChange = (e) => {
    setPanNumber(e.target.value);
  };

  const handleClose = () => {
    navigate('/income-tax');
    console.log("navigate('/income-tax')")
  };

  return (
    <>
      <Space>
        <div onClick={showDrawer}>{props.element}</div>
      </Space>

      <Drawer
        className="test"
        height={400}
        style={{ borderRadius: "20px 20px 0px 0px", position: "relative" }}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <div className="">
          {props.importData ? (
            <div>
                <div className="flex justify-center items-center">
            <img src={SuccessIcon} alt="otp icon" className="mb-2 w-16 h-16" />
          </div>
          <h2 className="text-center font-[700] leading-[2.1rem] text-[1.4rem] flex ">
            Successfully your details have been imported
          </h2>
          <p className="text-center text-gray-500 font-[400] text-[0.9rem] leading-[1.5rem] flex ">
            Check your income tax profile to view your details
          </p>

          <button
            onClick={handleClose} 
            className="mt-2 purpleBtn p-2 rounded  w-[100%] text-white"
          >
            File returns and e-verify
          </button>
            </div>
          ) : (
            <>
              {showOtpDialog ? (
                <PanCardOtpDialog
                  open={showOtpDialog}
                  otp_id={data.data.otp_id}
                  pan_Number={panNumber}
                />
              ) : (
                <>
                  <h2 className="font-[700] leading-[2.1rem] text-[1.4rem] flex">
                    PAN Verification
                  </h2>
                  <p className="text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex">
                    Connect your PAN to automatically import your tax data
                  </p>
                  <h6 className="text-[1rem] font-[500] mt-4">PAN Number</h6>
                  <input
                    type="text"
                    maxLength="10"
                    className="w-full h-12 text-center border rounded-md text-[1.1rem] font-medium text-[#9065B4] focus:outline-[#D6BBFB]"
                    placeholder="Enter your PAN Number"
                    value={panNumber}
                    onChange={handlePanChange}
                  />

                  <button
                    onClick={handleLinkPan}
                    className="purpleBtn p-2 rounded w-[100%] text-white mt-4"
                  >
                    {isLoading ? "Sending OTP..." : "Verify"}
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default EnterPanDrawer;
