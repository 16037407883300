import useAPICall from "hooks/useAPICall";
import { PATCH_CAPITAL_DETAILS_URL } from "constants/apis";

const usePatchCapitalDetails = () => {
  const [data, error, isLoading, callPatchCapitalDetails, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const patchCapitalDetails = (body, asset_id) => {
    const url = PATCH_CAPITAL_DETAILS_URL.replace(":asset_id", asset_id);

    callPatchCapitalDetails({
      url,
      method: "PATCH",
      statusObj,
      defaultFallback,
      body,
    });
  };

  return [data, error, isLoading, patchCapitalDetails, setSuccessData, setError];
};

export default usePatchCapitalDetails;
