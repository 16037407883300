import useAPICall from "hooks/useAPICall";
import { POST_RESEND_PAN_OTP } from "constants/apis";

const usePostResendPanOTP = () => {
  const [data, error, isLoading, callPostResendPanOtp, setSuccessData, setError] =
  useAPICall(undefined, "");

  const defaultFallback = () => {
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postResendPanOtp = (body) => {
    const url = POST_RESEND_PAN_OTP;
    var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log("Resend PAN OTP API: ", body);
    callPostResendPanOtp({
      url,
      method: "POST",
      statusObj,
      config,
      defaultFallback,
      body,
    });
  };

  return [data, error, isLoading, postResendPanOtp, setSuccessData, setError];
};

export default usePostResendPanOTP;
