import * as React from "react";
import { useState, useEffect } from "react";
import uploadImg from "assets/images/dashboard/upload.svg";
import attachImg from "assets/images/orders/attachment-line.svg";
import usePostDocs from "containers/privateRoutes/Orders/data/usePostDocs.js";
import usePostDocsByBT from "../Orders/data/usePostDocsByBT";
import "./Upload.css";
import Upload from "antd/es/upload/Upload";
import done from "assets/images/dashboard/_Checkbox base.svg";
import done2 from "assets/images/dashboard/done2.png";
import trash from "assets/images/dashboard/trash-2.svg";
import { Drawer} from "antd";
import useGetOrderDetails from "../Orders/data/useGetOrderDetails";

export default function UploadDialog(props) {

  const [
    docUploadDataBT,
    docUploadBTErrors,
    docUploadBTIsLoading,
    postDocsByBT,
    setSuccessdocUploadDataBT,
    setdocUploadBTErrors,
  ] = usePostDocsByBT();

  const [byUser, setByUser] = useState(true);
  const [byBT, setByBT] = useState(false);

  const [
    docUploadData,
    docUploadErrors,
    docUploadIsLoading,
    postDocs,
    setSuccessdocUploadData,
    setdocUploadErrors,
  ] = usePostDocs();

  const [submitClicked, setSubmitClicked] = useState(false);
  const [aadhar, setAadhar] = useState(null);
  const [Pan, setPAN] = useState(null);
  const [drivingLicense, setDrivingLicense] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [addressProof, setaddressProof] = useState(null);
  const [aadhaarReq, setAadharReq] = useState(false);
  const [PANReq, setPANReq] = useState(false);
  const [DLReq, setDLReq] = useState(false);
  const [addressProofReq, setAddressProofReq] = useState(false);
  const [bankDetailsReq, setBankDetailsReq] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");

  const [
    orderData,
    orderError,
    orderIsLoading,
    getOrderDetails,
    setSuccessOrderData,
    setOrderError,
  ] = useGetOrderDetails();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (submitClicked && byUser) {
      console.log(props.workOrderId);
      postDocs({
        work_order_id: props.workOrderId,
        "documents[0].document_name": "Aadhaar",
        "documents[1].document_name": "PAN",
        "documents[2].document_name": "Driving License",
        "documents[0].document_file": aadhar,
        "documents[1].document_file": Pan,
        "documents[2].document_file": drivingLicense,
      });
    }
  }, [submitClicked, byUser]); 

  useEffect(() => {
    if (submitClicked && byBT) {
      postDocsByBT({
        work_order_id: props.workOrderId,
        "documents[0].document_name": "Aadhaar",
        "documents[1].document_name": "PAN",
        "documents[2].document_name": "Driving License",
        "documents[0].document_file": aadhar,
        "documents[1].document_file": Pan,
        "documents[2].document_file": drivingLicense,
      });
    }
  }, [submitClicked, byBT]);


  useEffect(() => {
    if (props.docList.includes("Aadhaar")) {
      setAadharReq(true);
    }
    if (props.docList.includes("PAN")) {
      setPANReq(true);
    }
    if (props.docList.includes("Driving License")) {
      setDLReq(true);
    }
    if (props.docList.includes("Bank Details")) {
      setBankDetailsReq(true);
    }
    if (props.docList.includes("Address Proof")) {
      setAddressProofReq(true);
    }
  }, [props.docList]);

  const handleAadharChange = (info) => {
    if (info.file.status === "done") {
      // File uploaded successfully, update state with the uploaded file
      setAadhar(info.aadhar.originFileObj);
    } else if (info.aadhar?.status === "removed") {
      setAadhar(null);
    }
  };

  return (
    <>
      <button onClick={showDrawer} className="flex gap-2 items-center ">
        <img src={uploadImg} alt="" />
        <span className="text-[#9065B4] ">Upload</span>
      </button>
      <Drawer
        className="test"
        height={550}
        style={{ borderRadius: "20px 20px 0px 0px" }}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        PaperProps={{
          // style: { width: "50%", height: "50%", borderRadius: "4%" },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            onClose();
          },
        }}
      >
        <div className="">
          <p className="font-[600] text-[1.6rem] leading-[1.6rem] mb-10">
            Service Details
          </p>
          <div className="mb-8">
            <div className="grid grid-cols-12">
              <p className="col-span-8 font-[600] text-[1rem] leading-[1rem] text-[#344054]">
                {props.serviceName}
              </p>
              <p className="col-span-4 font-[600] text-[1rem] leading-[1rem] text-[#667085]">
                {props.createdAt}
              </p>
            </div>
            <div className="grid grid-cols-12 ">
              <p className="col-span-8 font-[400] text-[1rem] leading-[1rem] text-[#344054]">
                {props.workOrderId}
              </p>
              <p className="col-span-4 font-[700] text-[1.3rem] leading-[1rem] text-[#344054">
                {props.amountPaid}
              </p>
            </div>
          </div>

          <h5 className="mb-4 font-[600] text-[1.4rem] leading-[1.6rem]">
            Documents Upload
          </h5>
        </div>

        <header className="grid grid-cols-12  mb-4">
          <div className=" col-span-12 grid grid-cols-12">
            <div
              className={`${
                byUser
                  ? "bg-purple-light-shade border-b-2 border-purple-900"
                  : ""
              } col-span-6 hover:cursor-pointer`}
            >
              <h6
                onClick={() => {
                  if (byUser === false) {
                    setByUser(!byUser);
                    setByBT(!byBT);
                  }
                }}
                className=" text-gray-500 text-center pt-3"
              >
                Uploaded by me
              </h6>
              <hr className=" mt-[1rem] mb-0" />
            </div>
            <div
              className={`${
                byBT ? "bg-purple-light-shade border-b-2 border-purple-900" : ""
              } col-span-6 hover:cursor-pointer`}
            >
              <h6
                onClick={() => {
                  if (byBT === false) {
                    setByUser(!byUser);
                    setByBT(!byBT);
                  }
                }}
                className=" text-gray-500 text-center pt-3"
              >
                Uploaded by Beyondtax
              </h6>
              <hr className=" mt-[1rem] mb-0" />
            </div>
          </div>
        </header>
        <form>
          <div className="grid grid-cols-12 gap-3">
            {/* <UploadComponent /> */}
            {aadhaarReq && (
              <>
                <div className="flex col-span-10 justify-between">
                  {aadhar === null && (
                    <>
                      <span className="font-[500]">Aadhaar Card</span>
                      <Upload
                        // className="col-span-12 "
                        // action={setAadhar()}
                        //  onChange={handleAadharChange}
                        listType="picture"
                        maxCount={1}
                        progress={{ strokeColor: "blue", strokeWidth: 4 }}
                        showRemoveIcon={true}
                        onRemove={true}
                        // customRequest={{}}
                        beforeUpload={(file) => {
                          setAadhar(file);
                          console.log(aadhar);
                        }}
                        fileList={
                          aadhar ? [{ uid: "-1", name: aadhar.name }] : []
                        }
                      >
                        <label
                          onClick={() => {
                            // handleUpload();
                          }}
                          // for="aadhar"
                          // name="cover_image"
                          className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3 py-2 gap-2"
                        >
                          <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                          Attach file
                        </label>
                      </Upload>
                    </>
                  )}
                  {aadhar && (
                    <>
                      <div className="flex justify-between border-1 w-[100%] border-purple-900 rounded-md p-2">
                        <div className="flex flex-column">
                          {" "}
                          <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                            {aadhar.name}
                          </p>
                          <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                            100% uploaded
                          </p>
                        </div>
                        <div className="flex gap-2 items-center">
                          {" "}
                          <img src={done2} alt="" className="w-4 h-4" />
                          <img
                            className="w-4 h-4"
                            onClick={() => {
                              setAadhar(null);
                            }}
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {PANReq && (
              <>
                <div className="flex col-span-10 justify-between">
                  {Pan === null && (
                    <>
                      <span className="font-[500]">PAN</span>
                      <Upload
                        listType="picture"
                        className="col-span-12 "
                        // action={setAadhar()}
                        //  onChange={handleAadharChange}
                        beforeUpload={(file) => {
                          setPAN(file);
                          console.log(Pan);
                        }}
                        fileList={Pan ? [{ uid: "-1", name: Pan.name }] : []}
                      >
                        <label
                          onClick={() => {
                            // handleUpload();
                          }}
                          // for="aadhar"
                          // name="cover_image"
                          className=" rounded bg-purple-100 text-purple-900 flex text-sm px-3 py-2  gap-2"
                        >
                          <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                          Attach file
                        </label>
                      </Upload>
                    </>
                  )}

                  {Pan && (
                    <>
                      <div className="flex justify-between border-1 w-[100%] border-purple-900 rounded-md p-2">
                        <div className="flex flex-column">
                          {" "}
                          <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                            {Pan.name}
                          </p>
                          <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                            100% uploaded
                          </p>
                        </div>
                        <div className="flex gap-2 items-center">
                          {" "}
                          <img src={done2} alt="" className="w-4 h-4" />
                          <img
                            className="w-4 h-4"
                            onClick={() => {
                              setPAN(null);
                            }}
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}{" "}
            {DLReq && (
              <>
                <div className="flex col-span-10 justify-between">
                  {drivingLicense === null && (
                    <>
                      <span className="font-[500]">Driving License</span>
                      <Upload
                        listType="picture"
                        className="col-span-12 "
                        // action={setAadhar()}
                        //  onChange={handleAadharChange}
                        beforeUpload={(file) => {
                          setDrivingLicense(file);
                          console.log(drivingLicense);
                        }}
                        fileList={
                          drivingLicense
                            ? [{ uid: "-1", name: drivingLicense.name }]
                            : []
                        }
                      >
                        <label
                          onClick={() => {
                            // handleUpload();
                          }}
                          // for="aadhar"
                          // name="cover_image"
                          className=" rounded bg-purple-100 text-purple-900 flex text-sm px-3 py-2  gap-2"
                        >
                          <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                          Attach file
                        </label>
                      </Upload>
                    </>
                  )}

                  {drivingLicense && (
                    <>
                      <div className="flex justify-between border-1 w-[100%] border-purple-900 rounded-md p-2">
                        <div className="flex flex-column">
                          {" "}
                          <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                            {drivingLicense.name}
                          </p>
                          <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                            100% uploaded
                          </p>
                        </div>
                        <div className="flex gap-2 items-center">
                          {" "}
                          <img src={done2} alt="" className="w-4 h-4" />
                          <img
                            className="w-4 h-4"
                            onClick={() => {
                              setDrivingLicense(null);
                            }}
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}{" "}
            {addressProofReq && (
              <>
                <div className="flex col-span-10 justify-between">
                  {addressProof === null && (
                    <>
                      <span>Address Proof</span>
                      <Upload
                        listType="picture"
                        className="col-span-12 "
                        // action={setAadhar()}
                        //  onChange={handleAadharChange}
                        beforeUpload={(file) => {
                          setaddressProof(file);
                          console.log(addressProof);
                        }}
                        fileList={
                          addressProof
                            ? [{ uid: "-1", name: addressProof.name }]
                            : []
                        }
                      >
                        <label
                          onClick={() => {
                            // handleUpload();
                          }}
                          // for="aadhar"
                          // name="cover_image"
                          className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                        >
                          <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                          Attach file
                        </label>
                      </Upload>
                    </>
                  )}

                  {addressProof && (
                    <>
                      <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                        <div className="flex justify-between">
                          {" "}
                          <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                            {addressProof.name}
                          </p>
                          <img src={done} alt="" />
                        </div>
                        <div className="flex justify-between">
                          {" "}
                          <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                            100% uploaded
                          </p>
                          <img
                            onClick={() => {
                              setaddressProof(null);
                            }}
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}{" "}
            {bankDetailsReq && (
              <>
                <div className="flex col-span-10 justify-between">
                  {bankDetails === null && (
                    <>
                      <span>Bank Details</span>
                      <Upload
                        listType="picture"
                        className="col-span-12 "
                        // action={setAadhar()}
                        //  onChange={handleAadharChange}
                        beforeUpload={(file) => {
                          setBankDetails(file);
                          console.log(bankDetails);
                        }}
                        fileList={
                          bankDetails
                            ? [{ uid: "-1", name: bankDetails.name }]
                            : []
                        }
                      >
                        <label
                          onClick={() => {
                            // handleUpload();
                          }}
                          // for="aadhar"
                          // name="cover_image"
                          className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                        >
                          <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                          Attach file
                        </label>
                      </Upload>
                    </>
                  )}

                  {bankDetails && (
                    <>
                      <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                        <div className="flex justify-between">
                          {" "}
                          <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                            {bankDetails.name}
                          </p>
                          <img src={done} alt="" />
                        </div>
                        <div className="flex justify-between">
                          {" "}
                          <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                            100% uploaded
                          </p>
                          <img
                            onClick={() => {
                              setBankDetails(null);
                            }}
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </form>
        <div className="flex mt-2">
          <button
            className="bg-purple-dark-shade text-white px-[1rem] py-[.5rem] rounded"
            onClick={() => {
              setSubmitClicked(true);
              // handleUpload();
            }}
          >
            Submit
          </button>
        </div>
      </Drawer>
    </>
  );
}
