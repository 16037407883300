import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { myContext } from "App";
import { useLocation } from "react-router-dom";

// API's
import useGetUser from "containers/services/Data/useGetUserDetails";
import usePatchUserType from "./data/usePatchUserType";

//images
import arrow from "assets/images/navbar/chevron-down.svg";
import { showSuccessToast } from "components/toaster/toastHelper";

export default function UserTypeDialog(props) {
  const [open, setOpen] = useState(false);
  const [typeClicked, setTypeClicked] = useState(false);
  const [userType, setUserType] = useState("");
  const [userValue, setUserValue] = useState("");
  const [saveChanges, setSaveChanges] = useState(false);
  const { isLoggedIn } = useContext(myContext);

  const location = useLocation();
  const showDialog = location.state?.setOpen || false;

  const user = JSON.parse(localStorage.getItem("user"));

  const [
    userTypeUpdateData,
    userTypeUpdateError,
    userTypeUpdateIsLoading,
    updateUserType,
  ] = usePatchUserType();

  const [UserData, userError, userIsLoading, getUser] = useGetUser();

  useEffect(() => {
    if (showDialog && !UserData) {
      getUser();
    }
  }, [showDialog, getUser, UserData]);

  useEffect(() => {
    if (UserData) {
      if (UserData.data.client_type === null) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [UserData]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (UserData) {
      setUserType(UserData.data.client_type || "");
    }
  }, [UserData, userTypeUpdateData]);

  useEffect(() => {
    if (saveChanges) {
      updateUserType({
        client_type: userValue,
      });
      handleClose();
    }
    setSaveChanges(false);
  }, [saveChanges, updateUserType]);

  useEffect(() => {
    if (userTypeUpdateData) {
      showSuccessToast("User Type updated")
    }
  }, [userTypeUpdateData]);

  const clientTypeArr = [
    { name: "Individual", value: "1" },
    { name: "Proprietorship (Business)", value: "2" },
    { name: "HUF", value: "3" },
    { name: "Firm", value: "4" },
    { name: "LLP", value: "5" },
    { name: "Private Limited Company", value: "6" },
    { name: "Public Limited Company", value: "7" },
    { name: "Trust", value: "8" },
  ];

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { width: "50%", height: "55%", borderRadius: "4%" },
      }}
    >
      <div className="p-6 relative h-full">
        <label className="font-[600] text-[1rem] text-[#334155] mb-2">
          User Type
        </label>
        <br />

        <div className=" relative text-center">
          <input
            onClick={() => {
              setTypeClicked(!typeClicked);
            }}
            value={userType}
            onChange={(e) => {
              setUserType(e.target.value);
            }}
            className=" border-1 border-[#94A3B8] rounded px-3 py-2  w-[100%] my-1 tracking-[0.05em] text-[#334155]"
          />
          <img
            src={arrow}
            alt="arrow"
            className={`${
              typeClicked
                ? "transform -rotate-180 duration-200 absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                : "absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
            } ml-6`}
          />
        </div>
        {typeClicked && (
          <div className=" absolute bg-white-body drop-shadow-md p-2 md:w-[16rem] rounded-lg lg:w-[20rem] border-1">
            {clientTypeArr.map((data) => {
              return (
                <>
                  <p
                    key={data.name}
                    onClick={() => {
                      setUserType(data.name);
                      setUserValue(data.value);
                      setTypeClicked(false);
                    }}
                    className="text-[#334155] hover:cursor-pointer m-0"
                  >
                    {data.name}
                  </p>
                </>
              );
            })}
          </div>
        )}

        <div className=" absolute bottom-6 right-6 ">
          <button
            onClick={() => {
              setSaveChanges(true);
            }}
            className=" p-2 md:p-[1%] px-3  rounded-md bg-purple-dark-shade text-white"
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
}
