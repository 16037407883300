import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import useGetBlogDetails from "./Data/useGetBlogPost";
import "./Blogs.css";
import { loaderContext } from "App";
import BlogsList from "./BlogsList";
import tableAndChairs from "assets/images/blogs/blogTableImg.svg";

function BlogDetails() {
  const location = useLocation();
  const [blogNumber, setBlogNumber] = useState();
  const [content, setContent] = useState();
  const [date, setDate] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const { loading, setLoading } = useContext(loaderContext);

  const [
    BlogDetailsData,
    BlogDetailsError,
    BlogDetailsIsLoading,
    getBlogDetails,
  ] = useGetBlogDetails();

  if (BlogDetailsIsLoading) {
    setLoading(true);
  }

  useEffect(() => {
    if (blogNumber) {
      console.log("blogNumber", blogNumber);
      getBlogDetails({ id: blogNumber });
    }
  }, [blogNumber]);
  useEffect(() => {
    if (BlogDetailsData) {
      const dateString = BlogDetailsData.data.created_at.split(" ")[0]; // Extract date part
      const [day, month, year] = dateString.split("-"); // Assuming format is "DD-MM-YYYY"
      const date = new Date(`${year}-${month}-${day}T00:00:00`); // Reformat as "YYYY-MM-DD"
      const options = { day: "numeric", month: "short", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-GB", options);

      setContent(BlogDetailsData.data.content);
      setLoading(false);
      console.log(BlogDetailsData.data);
      // setDate(BlogDetailsData.data.created_at.split(" ")[0]);
      setDate(formattedDate);
      setHeading(BlogDetailsData.data.title);
      setDescription(BlogDetailsData.data.description);
      console.log("contnet:", BlogDetailsData.data.content)
    }
  }, [BlogDetailsData]);

  useEffect(() => {
    if (location.state && location.state.id) {
      setBlogNumber(location.state.id); // Use the blog ID directly
    }
  }, [location]);

  return (
    <>
      {BlogDetailsData && (
        <div className="lg:pt-[10rem] md:pt-[10rem] pt-[5rem] px-4">
          <p className=" text-center font-[700] text-[0.9rem] leading-[1.5rem] text-[#9065B4] my-4">
            {" "}
            Published on {date}
          </p>
          <h1 className=" lg:px-[4rem] md:px-[4rem] text-center lg:font-[600] lg:text-[2.3rem] lg:leading-[4rem] md:font-[600] md:text-[2.3rem] md:leading-[4rem] font-[600] text-[1.9rem] leading-[2.7rem] text-[#272727] my-4">
            {heading}
          </h1>
          <p className=" text-center lg:font-[400] lg:text-[0.9rem] lg:leading-[1.3rem] md:font-[400] md:text-[0.9rem] md:leading-[1.3rem] font-[400] text-[1rem] leading-[1.7rem] text-[#9092A3] my-4 lg:px-[8rem] md:px-[8rem]">
            {description}
          </p>
          <div className="justify-center flex gap-[1rem]">
            <h4 className=" py-1 px-3 rounded-2xl text-purple-800 font-[500] text-[0.9rem] bg-purple-100">
              Audit
            </h4>
            <h4 className=" py-1 px-3 rounded-2xl text-pink-800 font-[500] text-[0.9rem] bg-pink-100">
              Tax
            </h4>
            <h4 className=" py-1 px-3 rounded-2xl text-blue-800 font-[500] text-[0.9rem] bg-blue-100">
              Research
            </h4>
          </div>
          <div className="flex justify-end my-[1rem] ">
            <img
              src={tableAndChairs}
              alt=""
              className="md:w-[70rem] md:h-[22rem] md:object-cover object-cover h-[14rem]"
            />
          </div>
          <div className="forBlogs lg:mx-[20rem] md:mx-[20rem] mt-[3rem] text-[0.9rem]">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <BlogsList />
        </div>
      )}
    </>
  );
}

export default BlogDetails;
