import React from "react";
import addFrnd from "assets/images/incomeTax/addFrnd.png";
import { useFormContext } from "./UserDetailsSummaryPage";
import { INCOME_TAX_SUMMARY_URL } from "constants/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const TaxSummaryFooter = ({ handleAddClickForm, value }) => {
  const navigate = useNavigate();
  const { income_tax_return_id } = useParams();

  const { handleFormSubmit } = useFormContext();
  const location =useLocation();
  let isDisabled=false;
  const useAccessToken = new URLSearchParams(location.search).get('accessToken');
  const token =useAccessToken || sessionStorage.getItem('accessToken');
  const permissionType = sessionStorage.getItem('permissionType');
  if (permissionType === 'Read') {
    isDisabled=true;
  } else if (permissionType === 'Edit') {
    isDisabled=false;
  } else if (permissionType === 'Download') {
    isDisabled=true;
  } else if (permissionType === 'Read & Download') {
    isDisabled=false;
  }

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  return (
    <div className="flex justify-between mt-4 mb-4 w-[100%] h-[145px] md:h-auto">
      <div className="w-[65%]">
        {value === "salary" ? (
          <button
            type="button"
            disabled={isDisabled}
            onClick={handleAddClickForm}
            className="md:mb-0 mb-[4rem] md:px-3 px-2 py-2 md:py-2 rounded-md bg-gray-100 text-[#334155] w-[36%] flex gap-2 items-center font-[500]"
          >
            <img src={addFrnd} alt="addFrnd" className="w-4" />
            {/* Add Another Employee */}
            <span className="block md:hidden">Add</span>
            <span className="hidden md:block">Add Another Employee</span>
          </button>
        ) : value === "rental" ? (
          <button
            type="button"
            disabled={isDisabled}
            onClick={handleAddClickForm}
            className=" md:p-[1%] md:px-3 md:py-2 py-1 px-2 rounded-md bg-gray-100 text-[#334155] flex gap-2 items-center font-[500]"
          >
            <span className="text-[1.5rem]">+</span>
            {/* Add Another Property */}
            <span className="block md:hidden">Add</span>
            <span className="hidden md:block">Add Another Property</span>
          </button>
        ) : value === "capital" ? (
          <button
            type="button"
            disabled={isDisabled}
            onClick={handleAddClickForm}
            className=" md:p-[1%] px-3 md:py-2 py-1 rounded-md bg-gray-100 text-[#334155]  flex gap-2 items-center font-[500]"
          >
            <span className="text-[1.5rem]">+</span>
            {/* Add Another Capital
            Gain/Loss */}
            <span className="block md:hidden">Add</span>
            <span className="hidden md:block">
              Add Another Capital Gain/Loss
            </span>
          </button>
        ) : value === "business" ? (
          <button
            type="button"
            disabled={isDisabled}
            onClick={handleAddClickForm}
            className=" md:p-[1%] px-3 md:py-2 py-1 rounded-md bg-gray-100 text-[#334155]  flex gap-2 items-center font-[500]"
          >
            <span className="text-[1.5rem]">+</span>
            {/* Add Business */}
            <span className="block md:hidden">Add</span>
            <span className="hidden md:block">Add Business</span>
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="w-[35%] flex justify-end items-start  gap-2 md:gap-2">
        <button
          onClick={handleGoBack}
          type="button"
          className=" md:p-[1%]  px-3 py-2 rounded-md bg-gray-100 text-[#334155] font-[500] md:mb-0 mb-[4rem]"
        >
          {/* Back To Tax Summary */}
          <span className="block md:hidden">Back</span>
          <span className="hidden md:block">Back To Tax Summary</span>
        </button>
        <button
          type="button"
          disabled={isDisabled}
          onClick={() => handleFormSubmit()}
          className=" mr-[0.5rem] md:mr-0 md:p-[1%] px-3 py-2 rounded-md bg-[#9065B4] text-[#FFFFFF] font-[500] md:mb-0 mb-[4rem]"
        >
          {/* Save and Next */}
          <span className="block md:hidden">Save</span>
          <span className="hidden md:block">Save and Next</span>
        </button>
      </div>
    </div>
  );
};

export default TaxSummaryFooter;
