import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { myContext } from "App";
import { useState, useContext, useEffect } from "react";
import downloadImg from 'assets/images/orders/download-line.svg'
import qrCode from 'assets/images/orders/QRCode.svg'

export default function PayDialog() {
  const [open, setOpen] = useState(false);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button onClick={handleClickOpen} className=" border-[#9065B4] px-[2rem] rounded-lg border-2">
       <b className=" text-[#9065B4]">Pay</b></button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { width: "50%", height: "60%",borderRadius:'4%' },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <div className="flex justify-between">
        <DialogTitle>
          <h5 className="m-0">Payment</h5>
        </DialogTitle>
     
          <button onClick={handleClose} className=" px-3" >
            x 
          </button>
          
     </div>
        <DialogContent>
<div className="flex justify-center ">
    <div >
        <div className=" mx-auto">
   <p  className="text-center mb-2"><b >Your payment amount of ₹7,280 is due</b></p> 
    <p className="text-center mb-2" >pay the remaining amount to download the document</p>
    </div>
    <div className="grid grid-cols-12 my-[8%]">
        <div className=" col-span-6">
            <h6 className="text-center mb-6">Bank Details</h6>
            <p className="mb-1 text-center">Account No.: 8872617382</p>
            <p className="mb-1 text-center">IFSC Code: KKBK0072637</p>
            <p className="mb-1 text-center">Recipient Name: Beyond tax lmtd</p>
        </div>
        <div className="col-span-2 flex items-center justify-center">  <p > OR</p> </div>
<div className="col-span-4">
    <h6 className="text-center">QR Code</h6>
<img className=" mx-auto" src={qrCode} alt="" />
</div>
    </div>
    <div>
        <p className="text-center text-sm px-[13%]  ">If your payment is done it will take 2-3 hours to reflect in the system
or contact us at beyondtaxindia@gmail.com</p >
    </div>
    </div>
</div>
        </DialogContent>
       
      </Dialog>
    </>
  );
}
