import React, { useState, useEffect, useContext } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import "App.css";
import Input from "components/input/Input";
import GoogleLogin from "containers/LoginAndSignUp/GoogleLogin";
import usePostRegistration from "containers/services/Data/usePostRegistration";
import { registrationSuccessModalContext } from "App";
import NormalLoginSheet from "./NormalLoginSheet";

const MobileSheetRegistration = ({ serviceId, element }) => {
  const { registrationSuccessModal, setRegistrationSuccessModal } = useContext(
    registrationSuccessModalContext
  );
  const [isOtp, setIsOtp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(true);
  const [otp, setOtp] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [RegisterPassword, setRegisterPassword] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState();
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [type, setType] = useState("Individual");
  const [typeClicked, setTypeClicked] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [BusinessMobNumber, setBusinessMobNumber] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessTypeCode, setBusinessTypeCode] = useState(1);
  const [borderColor, setBorderColor] = useState("");
  const [busNameErr, setBusNameErr] = useState(false);
  const [busConPersonErr, setBusConPersonErr] = useState(false);
  const [busMobNoErr, setBusMobNoErr] = useState(false);
  const [busDOBErr, setBusDOBErr] = useState(false);
  const [MobNoErr, setMobNoErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [emailErr, setemailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [showErr, setShowErr] = useState();
  const [password, setPassword] = useState();
  const [registerMobileNo, setRegisterMobileNo] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [DOB, setDOB] = useState("");
  const [state, setState] = useState();
  const [email, setEmail] = useState("");
  const [sendRegistration, setSendRegistration] = useState(false);
  // console.log(props.data)
  const [
    registrationData,
    registrationError,
    registrationIsLoading,
    postRegistration,
    setSuccessRegistrationData,
    setRegistrationError,
  ] = usePostRegistration();

  const clientTypeArr = [
    "Individual",
    "Proprietorship (Business)",
    "HUF",
    "Firm",
    "LLP",
    "Private Limited Company",
    "Public Limited Company",
    "Trust",
  ];
  useEffect(() => {
    if (sendRegistration) {
      if (type === "Individual") {
        postRegistration({
          mobile_number: registerMobileNo,
          full_name: registerName,
          // date_of_birth: DOB,
          state: state,
          email: email,
          password: RegisterPassword,
          client_type: "1",
        });
      } else {
        postRegistration({
          mobile_number: registerMobileNo,
          // full_name: registerName,
          // date_of_birth: DOB,
          state: state,
          email: email,
          password: RegisterPassword,
          client_type: "3",
          business_name: businessName,
          // business_contact_person: contactPerson,
          business_mobile_number: BusinessMobNumber,
          // business_email: businessEmail,
        });
      }
    }
  }, [sendRegistration]);

  useEffect(() => {
    if (registrationError) {
      setSendRegistration(false);
      console.log(registrationError.err.response.data.data);
      if (registrationError.err.response.data.data?.full_name?.length > 0) {
        setNameErr(true);
      }
      if (registrationError.err.response.data.data?.business_name?.length > 0) {
        setBusNameErr(true);
      }
      if (
        registrationError.err.response.data.data?.business_contact_person
          ?.length > 0
      ) {
        setBusConPersonErr(true);
      }
      if (
        registrationError.err.response.data.data?.business_mobile_number
          ?.length > 0
      ) {
        setBusMobNoErr(true);
      }
      if (registrationError.err.response.data.data?.date_of_birth?.length > 0) {
        setBusDOBErr(true);
      }
      if (registrationError.err.response.data.data?.mobile_number?.length > 0) {
        setMobNoErr(true);
      }
      if (registrationError.err.response.data.data?.password?.length > 0) {
        setpasswordErr(true);
      }
      if (registrationError.err.response.data.data?.state?.length > 0) {
        setstateErr(true);
      }
      if (registrationError.err.response.data.data?.email?.length > 0) {
        setemailErr(true);
      }

      // setemailErr(true);
    }
  }, [registrationError]);
  useEffect(() => {
    if (registrationData) {
      setOpen(false);
      setRegistrationSuccessModal(true);
    }
  }, [registrationData]);

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  return (
    <>
      <Space>
        <div onClick={showDrawer}>{element}</div>
      </Space>
      <Drawer
        className="test"
        height={600}
        style={{ borderRadius: "20px 20px 0px 0px" }}
        // title="Sign up"
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        PaperProps={{
          style: { width: "50%", height: "60%", borderRadius: "4%" },
        }}
      >
        <h4>Sign up</h4>
        <div className=" h-[30rem] overflow-y-scroll scrollbar-hide">
          {/* <div className=" justify-center grid grid-cols-12 ">
                <div className="col-span-full">
                  <button className="border-solid border-gray-500 border-1 rounded-2 p-1 w-full">
                    <div className="flex">
                      <img className=" w-8" src={googleLogo} />
                      <span className="py-1">Continue with google</span>{" "}
                    </div>
                  </button>
                  <p className=" text-center">or</p>
                  <input
                    value={registerMobileNo}
                    onChange={(e) => {
                      setRegisterMobileNo(e.target.value);
                    }}
                    placeholder="Mobile Number"
                    className=" py-2 px-1 border-solid border-gray-500 border-1 rounded-2 col-span-full w-full my-1"
                  />

                  <input
                    value={registerName}
                    onChange={(e) => {
                      setRegisterName(e.target.value);
                    }}
                    placeholder="Your Name"
                    className=" py-2 px-1 border-solid border-gray-500 border-1 rounded-2 col-span-full w-full my-1"
                  />

                  <input
                    value={RegisterPassword}
                    onChange={(e) => {
                      setRegisterPassword(e.target.value);
                    }}
                    placeholder="Set Password"
                    className=" py-2 px-1 border-solid border-gray-500 border-1 rounded-2 col-span-full w-full my-1"
                  />
                  <input
                    value={DOB}
                    onChange={(e) => {
                      setDOB(e.target.value);
                    }}
                    placeholder="DOB"
                    className=" py-2 px-1 border-solid border-gray-500 border-1 rounded-2 col-span-full w-full my-1"
                  />

                  <select
                    className="w-full border-1 border-black rounded py-2"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  >
                    <option>state</option>
                    <option>Telangana</option>
                    <option>AR</option>
                  </select>

                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email ID (optional)"
                    className=" py-2 px-1 border-solid border-gray-500 border-1 rounded-2 col-span-full w-full my-1"
                  />

                  <p
                    onClick={() => {
                      setSendRegistration(true);
                    }}
                    className="py-2 rounded-md  text-center hover:cursor-pointer my-1 bg-button-orange font-medium"
                  >
                    Get OTP
                  </p>

                  <p className="font-medium text-center">
                    Already have an account?{" "}
                    <span
                      className="text-orange-300 hover:cursor-pointer"
                      onClick={() => {
                        setLogin(!login);
                        setSignup(!signup);
                      }}
                    >
                      Log in
                    </span>
                  </p>
                </div>
              </div> */}
          {/* <select
                  className=" border-1 rounded px-3 py-2  w-[100%] my-1 tracking-[0.05em]"
                  id="type"
                >
                  <option > Individual</option>
                  <option > Proprietorship (business)</option>
                  <option > HUF </option>
                  <option > Firm</option>
                  <option > LLP </option>
                  <option > Private Limited Company</option>
                  <option > Public Limited Company </option>
                  <option > Trust </option>
                </select> */}
          <label
            className="font-[500] text-[0.9rem] slightDarkBlack"
            for="type"
          >
            {" "}
            User Type
          </label>
          <br />
          <div className=" relative text-center">
            <input
              onClick={() => {
                setTypeClicked(true);
              }}
              // onBlur={() => {
              //   setTypeClicked(false);
              // }}
              style={{ fontFamily: "" }}
              // onClick={() => {
              // setSearchedClick(true);
              // }}
              // onChange={(e) => {
              //   // change(e.target.value);
              //   // setReq(e.target.value);
              // }}
              value={type}
              // onChange={(e)=>{setType(e.target.value)}}
              className=" border-1 rounded px-3 py-2  w-[100%] my-1 tracking-[0.05em]"
              // placeholder={` ${searchClicked ? "" : "Search"}`}
            />
          </div>
          {typeClicked && (
            <div className=" absolute bg-white-body drop-shadow-md p-2 md:w-[16rem] rounded-lg lg:w-[20rem]">
              {clientTypeArr.map((data) => {
                return (
                  <>
                    {/* <p onClick={()=>{console.log('evfc')}}>injve</p> */}
                    <p
                      key={data}
                      onClick={() => {
                        setType(data);
                        setTypeClicked(false);
                        setBusinessTypeCode(businessTypeCode + 1);
                      }}
                      className=" hover:cursor-pointer m-0"
                    >
                      {data}
                    </p>
                  </>
                );
              })}
            </div>
          )}
          {type === "Individual" ? (
            <>
              <Input
                border={`${nameErr ? "border-red-500" : ""}`}
                label={"Name"}
                placeholder={"Enter your name"}
                value={registerName}
                // type={"email"}
                setValue={setRegisterName}
              />
              {nameErr && (
                <>
                  {" "}
                  <span className=" text-red-500 ">Name is required</span>
                  <br />
                </>
              )}
              {/* {  busNameErr,busConPersonErr,busMobNoErr,busDOBErr,MobNoErr,passwordErr,stateErr,emailErr} */}
              <Input
                border={`${MobNoErr ? "border-red-500" : ""}`}
                label={"Mobile Number"}
                placeholder={"Enter mobile number"}
                value={registerMobileNo}
                // type={"email"}
                setValue={setRegisterMobileNo}
              />
              {MobNoErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Mobile Number is required</span>
                  <br />
                </>
              )}
              <Input
                border={`${emailErr ? "border-red-500" : ""}`}
                label={"Email"}
                placeholder={"Enter your email"}
                value={email}
                type={"email"}
                setValue={setEmail}
              />{" "}
              {emailErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Email is required</span>
                  <br />
                </>
              )}
              <Input
                border={`${passwordErr ? "border-red-500" : ""}`}
                label={"Password"}
                placeholder={"Create a password"}
                value={RegisterPassword}
                type={"password"}
                setValue={setRegisterPassword}
              />
              <p className=" text-sm text-gray-500 m-0">
                Must be at least 8 characters
              </p>
              {passwordErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Password is required</span>
                  <br />
                </>
              )}

              {/* DOB */}
              {/* <Input
                border={`${busDOBErr ? "border-red-500" : ""}`}
                label={"DOB"}
                placeholder={"DD/MM/YYYY"}
                value={DOB}
                // type={"email"}
                setValue={setDOB}
              />
              {busDOBErr && (
                <>
                  {" "}
                  <span className=" text-red-500">This field is required</span>
                  <br />
                </>
              )} */}

              {/* state */}
              {/* <label>State</label>
              <select
                className={`w-full border-1  rounded py-2  px-3 text-gray-500 ${
                  stateErr ? "border-red-500" : ""
                }`}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option>Select state</option>
                <option>Andhra Pradesh</option>
                <option>Arunachal Pradesh</option>
                <option>Assam</option>
                <option>Bihar</option>
                <option>Chhattisgarh</option>
                <option>Goa</option>
                <option>Gujarat</option>
                <option>Haryana</option>
                <option>Himachal Pradesh</option>
                <option>Jammu and Kashmir</option>
                <option>Jharkhand</option>
                <option>Karnataka</option>
                <option>Kerala</option>
                <option>Madhya Pradesh</option>
                <option>Maharashtra</option>
                <option>Manipur</option>
                <option>Meghalaya</option>
                <option>Mizoram</option>
                <option>Nagaland</option>
                <option>Odisha</option>
                <option>Punjab</option>
                <option>Rajasthan</option>
                <option>Sikkim</option>
                <option>Tamil Nadu</option>
                <option>Telangana</option>
                <option>Tripura</option>
                <option>Uttar Pradesh</option>
                <option>Uttarakhand</option>
                <option>West Bengal</option>
                <option>Andaman and Nicobar Islands</option>
                <option>Chandigarh</option>
                <option>Dadra and Nagar Haveli</option>
                <option>Daman and Diu</option>
                <option>Delhi</option>
                <option>Lakshadweep</option>
                <option>Puducherry</option>
              </select>{" "}
              {stateErr && (
                <>
                  {" "}
                  <span className=" text-red-500">This field is required</span>
                  <br />
                </>
              )} */}
            </>
          ) : (
            <>
              <Input
                border={`${busNameErr ? "border-red-500" : ""}`}
                label={"Business Name"}
                placeholder={"Enter your Business name"}
                value={businessName}
                // type={"email"}
                setValue={setBusinessName}
              />
              {busNameErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Business Name is required</span>
                  <br />
                </>
              )}
              
              <Input
                border={`${busMobNoErr ? "border-red-500" : ""}`}
                label={"Business Mobile Number"}
                placeholder={"Enter mobile number"}
                value={BusinessMobNumber}
                type={"email"}
                setValue={setBusinessMobNumber}
              />{" "}
              {busMobNoErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Business Mobile Number is required</span>
                  <br />
                </>
              )}
              <Input
                border={`${emailErr ? "border-red-500" : ""}`}
                label={"Email"}
                placeholder={"Enter your email"}
                value={email}
                type={"email"}
                setValue={setEmail}
              />{" "}
              {emailErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Email is required</span>
                  <br />
                </>
              )}
              <Input
                border={`${passwordErr ? "border-red-500" : ""}`}
                label={"Password"}
                placeholder={"Create a password"}
                value={RegisterPassword}
                type={"password"}
                setValue={setRegisterPassword}
              />
              <p className=" text-sm text-gray-500 m-0">
                Must be at least 8 characters
              </p>
              {passwordErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Password is required</span>
                  <br />
                </>
              )}
              {/* <Input
                border={`${busDOBErr ? "border-red-500" : ""}`}
                label={"DOB"}
                placeholder={"DD/MM/YYYY"}
                value={DOB}
                // type={"email"}
                setValue={setDOB}
              />
              {busDOBErr && (
                <>
                  {" "}
                  <span className=" text-red-500">This field is required</span>
                  <br />
                </>
              )} */}
              {/* <label>State</label>
              <select
                className={`w-full border-1  rounded py-2  px-3 text-gray-500 ${
                  stateErr ? "border-red-500" : ""
                }`}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option>Select state</option>
                <option>Andhra Pradesh</option>
                <option>Arunachal Pradesh</option>
                <option>Assam</option>
                <option>Bihar</option>
                <option>Chhattisgarh</option>
                <option>Goa</option>
                <option>Gujarat</option>
                <option>Haryana</option>
                <option>Himachal Pradesh</option>
                <option>Jammu and Kashmir</option>
                <option>Jharkhand</option>
                <option>Karnataka</option>
                <option>Kerala</option>
                <option>Madhya Pradesh</option>
                <option>Maharashtra</option>
                <option>Manipur</option>
                <option>Meghalaya</option>
                <option>Mizoram</option>
                <option>Nagaland</option>
                <option>Odisha</option>
                <option>Punjab</option>
                <option>Rajasthan</option>
                <option>Sikkim</option>
                <option>Tamil Nadu</option>
                <option>Telangana</option>
                <option>Tripura</option>
                <option>Uttar Pradesh</option>
                <option>Uttarakhand</option>
                <option>West Bengal</option>
                <option>Andaman and Nicobar Islands</option>
                <option>Chandigarh</option>
                <option>Dadra and Nagar Haveli</option>
                <option>Daman and Diu</option>
                <option>Delhi</option>
                <option>Lakshadweep</option>
                <option>Puducherry</option>
              </select>
              {stateErr && (
                <>
                  {" "}
                  <span className=" text-red-500">This field is required</span>
                  <br />
                </>
              )} */}
              <Input
                border={`${MobNoErr ? "border-red-500" : ""}`}
                label={"Mobile Number"}
                placeholder={"Enter mobile number"}
                value={registerMobileNo}
                // type={"email"}
                setValue={setRegisterMobileNo}
              />
              {MobNoErr && (
                <>
                  {" "}
                  <span className=" text-red-500">Mobile Number is required</span>
                  <br />
                </>
              )}
            </>
          )}

          <p
            onClick={() => {
              setSendRegistration(true);
              setNameErr(false);
              setBusNameErr(false);
              // setBusConPersonErr(false);
              setemailErr(false);
              setBusMobNoErr(false);
              // setBusDOBErr(false);
              setMobNoErr(false);
              setpasswordErr(false);
              setstateErr(false);
            }}
            className=" py-2 rounded-md text-white text-center hover:cursor-pointer mb-2 mt-3 purpleBtn font-medium"
          >
            Create account
          </p>
          {/* <button className="border-solid justify-center flex my-2 border-gray-300 border-1 rounded-2 p-1 w-full">
                  <img className=" w-8 mx-1" src={googleLogo} />
                  <span className=" p-1">Continue with google</span>{" "}
                </button> */}
          <GoogleLogin />
          <p className=" text-gray-400 text-center">
            Already have an account?{" "}
            <span
              className="hover:cursor-pointer text-purple-500"
              onClick={() => {
                setOpen(false);
              }}
            >
              <NormalLoginSheet
                serviceId={serviceId}
                element={<p className=" text-[#9065B4]">Login</p>}
              />
            </span>
          </p>
        </div>
      </Drawer>
    </>
  );
};
export default MobileSheetRegistration;
