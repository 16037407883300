import useAPICall from "hooks/useAPICall";
  import { GET_SUMMARY_LIST_URL } from "constants/apis";

const useGetSummaryDetails = () => {
  const [summaryData, summaryError, summaryIsLoading, callGetSummary, setSuccessSummaryData, setsummaryError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setsummaryError(en.something_went_wrong);
    setSuccessSummaryData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const summaryData = res;
        if (summaryData && typeof summaryData === "object") {
          setSuccessSummaryData(summaryData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const getSummaryDetails = (body) => {
    const url = GET_SUMMARY_LIST_URL  
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log(body)
    console.log('getSummaryDetails API:', summaryData)
    callGetSummary({
      url,
      method : "GET",
      statusObj,
      
      config,
      defaultFallback,
      body
    });
  };
  return [summaryData, summaryError, summaryIsLoading, getSummaryDetails, setSuccessSummaryData, setsummaryError];
};

export default useGetSummaryDetails;
