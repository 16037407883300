import useAPICall from "hooks/useAPICall";
import { POST_UPLOAD_DOCUMENTS_BY_BT_URL } from "constants/apis";

const usePostDocsByBT = () => {
  const [
    docUploadDataBT,
    docUploadBTErrors,
    docUploadBTIsLoading,
    callPostDocsByBT,
    setSuccessdocUploadDataBT,
    setdocUploadBTErrors,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setdocUploadBTErrors(en.something_went_wrong);
    setSuccessdocUploadDataBT(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const docUploadDataBT = res;
        if (docUploadDataBT && typeof docUploadDataBT === "object") {
          setSuccessdocUploadDataBT(docUploadDataBT);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
  // console.log(localStorage.getItem('token'))
  const postDocsByBT = (body) => {
    const url = POST_UPLOAD_DOCUMENTS_BY_BT_URL;
    // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
    var config = {
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    // console.log(body)
    console.log("postDocs running");
    callPostDocsByBT({
      url,
      method: "POST",
      statusObj,

      config,
      defaultFallback,
      body,
    });
  };
  return [
    docUploadDataBT,
    docUploadBTErrors,
    docUploadBTIsLoading,
    postDocsByBT,
    setSuccessdocUploadDataBT,
    setdocUploadBTErrors,
  ];
};

export default usePostDocsByBT;
