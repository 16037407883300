import React from "react";
import CustomizableDrawer from "../Reports/Drawer";

const RemoveCard = () => {
  return (
    <div>
      <CustomizableDrawer
        triggerElement={
          <button className="bg-[#FEF2F2] text-[#DC2626] rounded border border-[#FEE2E2] py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
            Remove Card
          </button>
        }
        placement="bottom"
        heading={""}
        content={
          <div className="px-3 py-3">
            <p className="text-[1.2rem] font-[500] text-[#334155] text-center">
              You-re about to remove a government-verified ID from your account?
            </p>
            <div className="">
              <button className="w-[100%] bg-[#FEF2F2] text-[#DC2626] rounded border border-[#FEE2E2] py-[0.8rem] px-[1.3rem] font-[600] text-[0.9rem]">
                Yes, Remove Card
              </button>
              <button className="w-[100%] mt-[1rem] font-[500] px-[1rem] py-[0.8rem] rounded-md bg-[#E8EAED] font-[600] text-[0.9rem]">
                Discard Changes
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RemoveCard;
