import React, { useEffect, useState } from "react";
import TimeLineChart from "./TimeLineChart";
import CustomizableDrawer from "../Reports/Drawer";
import HeadingProfile from "./HeadingProfile";

// images
import filter from "assets/images/taxProfile/filter.svg";
import lock from "assets/images/taxProfile/lock.svg";
import arrow_up from "assets/images/taxProfile/arrow-up.svg";
import progress from "assets/images/taxProfile/progress.svg";
import time from "assets/images/taxProfile/time.svg";
import editdata from "assets/images/taxProfile/editdata.svg";
import add from "assets/images/taxProfile/add.svg";
import arrowRight from "assets/images/taxProfile/arrow-right.svg";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";


const Invites = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const accessLogsArray = [
    {
      id: 1,
      name: "Mahadev biradar",
      logged_files: "14 Files",
      date_time: "[02-09-24/15-00PM] - [02-09-24/15-25PM]",
      time_log: "25 Minutes",
      date: "02-09-24/3:00pm",
    },
    {
      id: 2,
      name: "Mahadev biradar",
      logged_files: "14 Files",
      date_time: "[02-09-24/15-00PM] - [02-09-24/15-25PM]",
      time_log: "25 Minutes",
      date: "02-09-24/3:00pm",
    },
    {
      id: 3,
      name: "Mahadev biradar",
      logged_files: "14 Files",
      date_time: "[02-09-24/15-00PM] - [02-09-24/15-25PM]",
      time_log: "25 Minutes",
      date: "02-09-24/3:00pm",
    },
  ];

  const MobileMyStakeHolders = () => {
    const [name, setName] = useState("");
    const [pan, setPan] = useState("");
    const [type, setType] = useState("");
    const [permission, setPermission] = useState("");

    const [holder, setHolder] = useState([
      {
        name: "",
        pan: "",
        type: "",
        permission: "",
      },
    ]);

    const handleChange = (index, field, value) => {
      const updatedAccounts = [...holder];
      updatedAccounts[index][field] = value;
      setHolder(updatedAccounts);
    };

    return (
      <div className="border border-[#E2E8F0] rounded-xl ">
        <h4 className="text-[#334155] bg-[#FAF8FC] p-3 rounded-xl  md:text-[1.2rem] text-[1.1rem] md:font-[500] font-[600] ">
          My Stakeholders
        </h4>
        {/* view stake holders */}
        {holder.map((item, index) => (
          <div className="p-[1rem]">
            <div className="flex justify-between items-center">
              <div>
                <h4 className="text-[#334155] text-[1rem] font-[600]">
                  Mahadev birader
                  {/* items.name */}
                </h4>
                <h4 className="text-[#64748B] text-[0.9rem] font-[500]">
                  Pan: KKBK0012323
                  {/* item.pan */}
                </h4>
                <h4 className=" text-[#22C55E] text-[0.8rem] font-[500]">
                  Chartered Accountant
                  {/* item.type */}
                </h4>
              </div>
              <div className="flex gap-2 items-center">
                <h4 className="m-0 text-[#334155] text-[0.8rem] font-[500]">
                  Read & Download
                  {/* item.permission */}
                </h4>
                <CustomizableDrawer
                  triggerElement={
                    <img src={arrowRight} alt="arrow right icon" />
                  }
                  height="500"
                  heading={"My Stakeholder"}
                  content={({ handleClose }) => (
                    <>
                      <div className="my-[1rem]">
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Name
                          </h6>
                          <input
                            className="input-box bg-[#F8FAFC] text-[0.9rem]"
                            value={item.name}
                            onChange={(e) =>
                              handleChange(index, "name", e.target.value)
                            }
                          />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Pan
                          </h6>
                          <input
                            className="input-box bg-[#F8FAFC] text-[0.9rem] "
                            onChange={(e) =>
                              handleChange(index, "pan", e.target.value)
                            }
                            value={item.pan}
                          />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Type
                          </h6>
                          <input
                            className="input-box bg-[#F8FAFC] text-[0.9rem]"
                            onChange={(e) =>
                              handleChange(index, "type", e.target.value)
                            }
                            value={item.type}
                          />
                        </div>
                        <div className="input-container mb-[1rem]">
                          <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                            Permission
                          </h6>
                          <select
                            className="input-box bg-[#F8FAFC] text-[0.9rem]"
                            onChange={(e) =>
                              handleChange(index, "permission", e.target.value)
                            }
                            value={item.permission}
                          >
                            <option value="">Choose Permission type</option>
                            <option value={1}>Read</option>
                            <option value={2}>Read & Download</option>
                            <option value={2}>Can Share Access</option>
                          </select>
                        </div>
                        <CustomizableDrawer
                          triggerElement={
                            <button
                              onClick={handleClose}
                              className="border-1 border-[#FEE2E2] w-full bg-[#FEF2F2] text-[#DC2626] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                            >
                              Remove member
                            </button>
                          }
                          content={({ handleClose }) => (
                            <>
                              <div className="my-[1rem]">
                                <h4 className="flex justify-center text-center text-[1.2rem] text-[#334155] font-[500] ">
                                  Are you Sure! <br></br>You're about to remove
                                  a Stakeholder?
                                </h4>
                              </div>
                              <button
                                onClick={() => {
                                  // handleRemoveAccount(index);
                                  handleClose();
                                }}
                                className="border-1 border-[#FEE2E2]  my-[1rem] w-full bg-[#FEF2F2] text-[#DC2626] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                              >
                                Yes, Remove Member
                              </button>
                              <button
                                onClick={handleClose}
                                className="border bg-[#F1F5F9] w-full text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1rem]"
                              >
                                Discard Changes
                              </button>
                            </>
                          )}
                        />
                        <div className="flex gap-3 ">
                          <button
                            onClick={() => {
                              // handleAddBankDetail();
                              handleClose();
                            }}
                            className="mt-[1rem] w-full  bg-[#9065B4]  text-white rounded py-[0.5rem] px-[1rem] font-[400] text-[1.1rem]"
                          >
                            Update
                          </button>

                          <button
                            onClick={handleClose}
                            className="mt-[1rem] w-full border-1 border-[#94A3B8] bg-[#F1F5F9]  text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1.1rem]"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <hr />
          </div>
        ))}

        {/* Add stakeholder */}
        <CustomizableDrawer
          triggerElement={
            <div className="mx-[1rem] mb-[1rem]">
              <button className="p-2 text-[#9065B4] w-full font-[500] text-[1rem] bg-[#EAE1F3] rounded flex justify-center items-center ">
                + Add New Stakeholder
              </button>
            </div>
          }
          height="500"
          heading={"New Stakeholder"}
          content={({ handleClose }) => (
            <>
              <div className="my-[1rem]">
                <div className="input-container mb-[1rem]">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Name
                  </h6>
                  <input
                    className="input-box bg-[#F8FAFC] text-[0.9rem]"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-container mb-[1rem]">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Pan
                  </h6>
                  <input
                    className="input-box bg-[#F8FAFC] text-[0.9rem] "
                    onChange={(e) => setPan(e.target.value)}
                    value={pan}
                  />
                </div>
                <div className="input-container mb-[1rem]">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Type
                  </h6>
                  <input
                    className="input-box bg-[#F8FAFC] text-[0.9rem]"
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                  />
                </div>
                <div className="input-container mb-[1rem]">
                  <h6 className="text-[0.9rem] font-[500] text-[#64748B]">
                    Permission
                  </h6>
                  <select
                    className="input-box bg-[#F8FAFC] text-[0.9rem]"
                    onChange={(e) => setPermission(e.target.value)}
                    value={permission}
                  >
                    <option value="">Choose Permission type</option>
                    <option value={1}>Read</option>
                    <option value={2}>Read & Download</option>
                    <option value={2}>Can Share Access</option>
                  </select>
                </div>
                <div className="flex gap-3 ">
                  <button
                    onClick={() => {
                      // handleAddBankDetail();
                      handleClose();
                    }}
                    className="mt-[1rem] w-full  bg-[#9065B4]  text-white rounded py-[0.5rem] px-[1rem] font-[500] text-[1.1rem]"
                  >
                    Save
                  </button>

                  <button
                    onClick={handleClose}
                    className="mt-[1rem] w-full border-1 border-[#94A3B8] bg-[#F1F5F9]  text-[#334155] rounded py-[0.5rem] px-[1rem] font-[500] text-[1.1rem]"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        />
      </div>
    );
  };

  const MyStakeHolders = () => {
    const [stakeholder, setStakeholder] = useState([
      {
        name: "",
        panNumber: "",
        type: "",
        permission: "",
        isNewAccount: true,
        isReadOnly: false,
      },
    ]);
    const handleAddStakeHolder = () => {
      setStakeholder([
        ...stakeholder,
        {
          name: "",
          panNumber: "",
          type: "",
          permission: "",
          isNewAccount: true,
          isReadOnly: false,
        },
      ]);
    };

    const handleChange = (index, field, value) => {
      const newAccounts = [...stakeholder];
      newAccounts[index][field] = value;
      setStakeholder(newAccounts);
    };

    const handleEditDetails = (index) => {
      const updatedAccounts = [...stakeholder];
      updatedAccounts[index].isReadOnly = false;
      setStakeholder(updatedAccounts);
    };

    const handleCancel = (index) => {
      const updatedAccounts = [...stakeholder];
      updatedAccounts[index].isReadOnly = true;
      setStakeholder(updatedAccounts);
    };

    return (
      <>
        <div className="md:border border-[#E2E8F0] rounded-xl">
          <div className="bg-[#FAF8FC] px-3 py-3 m-0 rounded-xl">
            <h4 className="text-[#334155] text-[1.2rem] font-[500] ">
              My Stakeholders
            </h4>
            <h6 className="text-[#334155] text-[0.9rem] font-[500] m-0">
              Manage Stakeholders who can access your profile
            </h6>
          </div>

          {/* input fields */}
          <div className="md:bg-white px-3 py-3 rounded">
            {stakeholder.map((stake, index) => (
              <>
                <div className="flex" key={index}>
                  <div className="input-container ">
                    <h6 className="input-label ">Name</h6>
                    <input
                      className={`input-box bg-[#F8FAFC] text-[0.9rem] ${
                        stake.isReadOnly ? "border-none" : ""
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.name}
                      onChange={(e) =>
                        handleChange(index, "name", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">PAN Number</h6>
                    <input
                      className={`input-box bg-[#F8FAFC] text-[0.9rem] ${
                        stake.isReadOnly ? "border-none" : ""
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.panNumber}
                      onChange={(e) =>
                        handleChange(index, "panNumber", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Type </h6>
                    <input
                      className={`input-box bg-[#F8FAFC] text-[0.9rem] ${
                        stake.isReadOnly ? "border-none" : ""
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.type}
                      onChange={(e) =>
                        handleChange(index, "type", e.target.value)
                      }
                    />
                  </div>
                  <div className="input-container ">
                    <h6 className="input-label ">Permission</h6>
                    <select
                      className={`input-box bg-[#F8FAFC]  ${
                        stake.isReadOnly
                          ? "border-none text-[0.9rem]"
                          : "text-[1rem]"
                      }`}
                      readOnly={stake.isReadOnly}
                      value={stake.permission}
                      onChange={(e) =>
                        handleChange(index, "permission", e.target.value)
                      }
                    >
                      <option value="">Choose Permission type</option>
                      <option value={1}>Read</option>
                      <option value={2}>Read & Download</option>
                      <option value={2}>Can Share Access</option>
                    </select>
                  </div>
                  <div className="">
                    {stake.isNewAccount ? (
                      <>
                        <button
                          //   onClick={() => handleAddBankDetail(index)}
                          className="mb-1 bg-[#9065B4] w-[60%] text-white rounded py-2 px-3 font-[600] text-[0.8rem]"
                        >
                          Add Account
                        </button>
                        <button className=" bg-[#F1F5F9] w-[60%] text-[#334155] rounded py-2 px-6 font-[600] text-[0.8rem]">
                          Cancel
                        </button>
                      </>
                    ) : stake.isReadOnly ? (
                      <>
                        <button className="w-[60%] text-[#334155] rounded py-2 px-2 font-[600] text-[0.8rem]"></button>
                        <button
                          onClick={() => handleEditDetails(index)}
                          className=" bg-[#F1F5F9] text-[#334155] rounded py-2 px-3 font-[600] text-[0.8rem]"
                        >
                          Edit Details
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          //   onClick={() => handleApplyChanges(index)}
                          className=" mb-1 w-[60%] bg-[#9065B4] text-white rounded p-2 font-[600] text-[0.8rem]"
                        >
                          Apply Changes
                        </button>

                        {/* When user clicks on delete account a dialog box appers check in UI */}
                        <button
                          //   onClick={() => handleRemoveAccount(index)}
                          className="mb-1 w-[60%] bg-[#FEF2F2] text-[#DC2626] rounded p-2  font-[600] text-[0.8rem]"
                        >
                          Delete Account
                        </button>
                        <button
                          onClick={() => handleCancel(index)}
                          className=" bg-[#F1F5F9]  w-[60%] p-2 text-[#334155] rounded font-[600] text-[0.8rem]"
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <hr className="" />
              </>
            ))}

            <div className="flex justify-center items-center rounded py-3 border-dashed border-2 border-[#E2E8F0]">
              <button
                onClick={handleAddStakeHolder}
                className="flex items-center gap-2 text-[#334155] font-[600]"
              >
                <img src={add} alt="add account icon" />
                Add New Stakeholder
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const DataUsage = () => {
    // const accessLogsArray = [
    //   {
    //     id: 1,
    //     name: "Mahadev biradar",
    //     logged_files: "14 Files",
    //     date_time: "[02-09-24/15-00PM] - [02-09-24/15-25PM]",
    //     time_log: "25 Minutes",
    //   },
    //   {
    //     id: 2,
    //     name: "Mahadev biradar",
    //     logged_files: "14 Files",
    //     date_time: "[02-09-24/15-00PM] - [02-09-24/15-25PM]",
    //     time_log: "25 Minutes",
    //   },
    //   {
    //     id: 3,
    //     name: "Mahadev biradar",
    //     logged_files: "14 Files",
    //     date_time: "[02-09-24/15-00PM] - [02-09-24/15-25PM]",
    //     time_log: "25 Minutes",
    //   },
    // ];

    return (
      <div className="border border-[#E2E8F0] rounded-xl mt-[2rem] ">
        <div className="flex justify-between items-center bg-[#FAF8FC] px-3 py-3 m-0 rounded-xl">
          <div className="">
            <h4 className="text-[#334155] md:text-[1.2rem] text-[1.1rem] md:font-[500] font-[600]">
              Data Usage Analysis
            </h4>
            <h6 className="text-[#334155] text-[0.9rem] font-[500] m-0 md:block hidden">
              Track how stakeholders access and use your data.
            </h6>
          </div>
          <div className="flex gap-[1rem]">
            <select className="input-box text-[0.9rem] md:block hidden">
              <option value="">Select Stakeholder</option>
              <option value={1}>All stakeholders</option>
              <option value={2}>2</option>
            </select>
            <button className="flex items-center gap-1 bg-[#F3EFF8] text-[#9065B4] border rounded text-[0.9rem] pl-[0.5rem] md:py-[0.5rem] md:pr-[1.1rem] pr-[0.8rem] py-[0.4rem] font-[500]">
              Filter
              <img src={filter} alt="filter icon" className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="bg-white  rounded-xl p-3">
          <div className="grid grid-cols-12 gap-3">
            {/* Time Spent */}
            <div className="md:col-span-6 col-span-12 md:border rounded-lg md:px-[1rem] md:py-[1.8rem]">
              <div className="grid grid-cols-12 gap-2">
                <div className="md:col-span-4 col-span-12 md:block flex justify-between items-center border-1 border-[#FFEDD5] rounded-lg p-3 bg-[#FFF7ED] text-[#EA580C]">
                  <h4 className="flex items-center gap-2 text-[1rem] font-[600] md:font-[500] ">
                    <img src={time} alt="time icon" /> Time Spent
                  </h4>
                  <h4 className="text-[1.2rem]  md:font-[600] font-[500] text-center ml-3 ">
                    32 mins
                  </h4>
                </div>

                <div className="md:col-span-8 col-span-12 border rounded-lg md:bg-[#FAF8FC] bg-[#E2E8F0] text-[#334155] px-3">
                  <div className="flex justify-between items-center">
                    <h4 className="text-[#334155] text-[1rem] font-[600] md:font-[500] md:border p-1 rounded m-0">
                      Total Data-52 Files
                    </h4>
                    <img
                      src={editdata}
                      alt="edit icon"
                      className=" w-14 h-14"
                    />
                  </div>

                  {/* Progress Bar */}
                  <div className="relative pt-2 md:block hidden">
                    <div className="overflow-hidden h-3 flex rounded bg-[#E2E8F0]">
                      <div
                        className="shadow-none flex flex-col whitespace-nowrap justify-center bg-[#9065B4]"
                        style={{ width: "52%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className=" md:col-span-4 col-span-12 md:block flex justify-between items-center  border-1 border-[#E2E8F0] rounded-lg py-3 px-2  bg-[#F1F5F9] text-[#334155]">
                  <h4 className="flex items-center gap-2 text-[1rem] font-[600] md:font-[500] ">
                    <img src={arrow_up} alt="time icon" /> Data Access
                  </h4>
                  <h4 className="text-[1.2rem] md:font-[600] font-[500] text-center ">
                    14/28 Files
                  </h4>
                </div>

                <div className="md:col-span-4 col-span-12  border-1 border-[#ECFDF3] rounded-lg p-3 bg-[#ECFDF5] text-[#027A48]">
                  <div className="md:block flex justify-between items-center">
                    <h4 className="flex items-center gap-2 text-[1rem] font-[600] md:font-[500] ">
                      <img src={progress} alt="time icon" /> Progress
                    </h4>
                    <h4 className="text-[1.2rem] md:font-[600] font-[500] flex justify-end ">
                      50%
                    </h4>
                  </div>
                  {/* Progress Bar */}
                  <div className="relative pt-2 md:hidden">
                    <div className="overflow-hidden h-3 flex rounded bg-[#E2E8F0]">
                      <div
                        className="shadow-none flex flex-col whitespace-nowrap justify-center bg-[#16A34A]"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-4 col-span-12 md:block flex justify-between items-center border-1 border-[#FEE2E2] text-[#DC2626] rounded-lg py-3 px-2 bg-[#FEF2F2]">
                  <h4 className="flex items-center gap-2 text-[1rem] font-[600] md:font-[500] ">
                    <img src={lock} alt="time icon" /> Locked Files
                  </h4>
                  <h4 className="text-[1.2rem] md:font-[600] font-[500] flex justify-end ">
                    24 Files
                  </h4>
                </div>
              </div>
            </div>
            {/* Timeline */}
            <div className="md:col-span-6 col-span-12 border rounded-lg p-3">
              <div className="flex justify-between items-center">
                <h4 className="text-[1.1rem] text-[#334155] font-[500] ">
                  Timeline
                </h4>
                <select className="text-[1rem]">
                  {/* <option value="">Select Stakeholder</option> */}
                  <option value={1}>Monthly</option>
                  <option value={2}>Yearly</option>
                </select>
              </div>
              <div className="mt-[1rem] pr-[1rem]">
                <TimeLineChart />
              </div>
            </div>
          </div>

          {/* Access Logs */}
          <div className="md:flex hidden justify-between items-center mt-[1.8rem]">
            <h4 className="text-[#334155] text-[1.2rem] font-[500] ">
              Access Logs
            </h4>

            <button className="flex items-center gap-1 bg-[#F3EFF8] text-[#9065B4] border rounded text-[0.9rem] px-[0.5rem] py-[0.4rem]  font-[500]">
              Sort
              <img src={filter} alt="filter icon" className="w-4 h-4" />
            </button>
          </div>

          {/* table */}
          <div className="hidden md:grid grid-cols-12 gap-1 my-[1rem] border border-[#E2E8F0] rounded ">
            <table className="col-span-12  ">
              <tr className="bg-[#F3EFF8]  rounded text-[#111827] ">
                <th className="py-[1%] px-4  col-span-2 text-center text-[1rem] font-[500]  ">
                  Name
                </th>
                <th className=" px-4  col-span-4 text-center text-[1rem] font-[500]">
                  logged Files
                </th>
                <th className=" px-4 col-span-4 text-center text-[1rem] font-[500] ">
                  Date/Time
                </th>
                <th className=" px-4  col-span-2 text-center text-[1rem] font-[500]">
                  Time Log
                </th>
              </tr>

              {accessLogsArray.map((item) => {
                return (
                  <tr className="rounded text-[#111827] ">
                    <td className="px-4 py-2 p-0 text-center font-[500]">
                      {item.name}
                    </td>
                    <td className=" px-4 p-0   text-center font-[500]">
                      {item.logged_files}
                    </td>
                    <td className=" px-4 p-0  text-center font-[500]">
                      {item.date_time}
                    </td>
                    <td className="px-4   p-0  text-center font-[500]">
                      {item.time_log}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const MobileAccessLogs = () => {
    return (
      <>
        <div className="border border-[#E2E8F0] rounded-xl mt-[2rem] mb-[5rem]">
          <div className="flex justify-between items-center px-3 py-3 bg-[#FAF8FC] m-0 rounded-xl">
            <h4 className="text-[#334155] md:text-[1.3rem] text-[1.1rem] md:font-[500] font-[600]">
              Access Logs
            </h4>

            <button className="flex items-center gap-1 bg-[#F3EFF8] text-[#9065B4] border rounded text-[0.9rem] px-[0.5rem] py-[0.4rem]  font-[500]">
              Sort
              <img src={filter} alt="filter icon" className="w-4 h-4" />
            </button>
          </div>

          {accessLogsArray.map((item, index) => (
            <div className="bg-white rounded-2xl px-3 py-2">
              <div className=" border p-2 rounded" key={index}>
                <h4 className="text-[#334155] text-[1rem] font-[600]">
                  {item.name}
                </h4>
                <h4 className="text-[#64748B] text-[0.8rem] font-[400]">
                  Date/Time:{item.date}
                </h4>
                <h4 className="flex justify-end text-[#64748B] text-[0.9rem] font-[500]">
                  Logged Files:{item.logged_files}
                </h4>
              </div>
            </div>
          ))}
        </div>
        <div className="my-[4rem] text-white">.</div>
      </>
    );
  };

  return (
    <div className="md:bg-gray-100 pb-[2rem] md:mb-0 ">

      <div className="px-3">
      <HeadingProfile />
        <div className="flex md:hidden">
          <button>
            <img src={previousBtn} alt="" className="w-4 h-4" />
          </button>
          <h4 className="text-[#334155] text-[1.3rem] font-[600] px-3 py-3 m-0 rounded">
            Invites
          </h4>
        </div>
        {isMobile ? (
          <div className="mb-[4rem]">
            <MobileMyStakeHolders />
            <DataUsage />
            <MobileAccessLogs />
          </div>
        ) : (
          <>
            <MyStakeHolders />
            <DataUsage />
          </>
        )}
      </div>
    </div>
  );
};

export default Invites;
