import React from 'react'
import HomeTitle from './HomeTitle'
import './Home.css'
import EventDate from './EventDate'
import DiveHome from './DiveHome'
import Offers from './Offers'
import Reason from './Reason'
import loader from 'assets/images/loader/loader2.gif'
import ServiceFaq from 'containers/services/ServiceFaq'

function Home() {


  return (<>
    <div className=''>
      {/* <img className=' h-screen w-screen' src={loader}/> */}
      <HomeTitle />
      <EventDate />
      <DiveHome />
      <Offers />
      <Reason />

    </div></>
  )
}

export default Home;
