import React from "react";
import { useParams } from "react-router-dom";
import LandDetailedView from "./LandDetailedView";

const RealEstateDetailedView = () => {
  const { estate_type } = useParams();

  let displayText = "";

  switch (estate_type) {
    case "land":
      displayText = <LandDetailedView />;
      break;
    case "building":
      displayText = <LandDetailedView />;

      break;
    default:
      displayText = "Unknown";
  }

  return <div>{displayText}</div>;
};

export default RealEstateDetailedView;
