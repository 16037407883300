import {POST_TAX_PROFILE_MOBILE_CHANGE_URL, POST_TAX_PROFILE_MOBILE_OTP_URL} from "constants/apis";
import useAPICall from "hooks/useAPICall";
const usePostTaxProfileMobileVerify = () => {
    const [
        data,
        error,
        isLoading,
        callPostTaxProfileMobileOtp,
        setSuccessData,
        setError,
      ] = useAPICall(undefined, "");
    
      const defaultFallback = () => {
        // setError(en.something_went_wrong);
        setSuccessData(undefined);
      };
      const statusObj = [
        {
          status_code: 200,
          status_text: "OK",
          callBack: (res) => {
            const data = res;
            if (data && typeof data === "object") {
              setSuccessData(data);
            } else {
              defaultFallback();
            }
          },
        },
        {
          status_text: "Bad Request",
          status_code: 400,
          callBack: defaultFallback,
        },
        {
          status_text: "Internal Server Error",
          status_code: 500,
          callBack: defaultFallback,
        },
      ];
      const postTaxProfileMobileOtp = ( body) => {
        const url = POST_TAX_PROFILE_MOBILE_OTP_URL
        var config = {
          headers: { Authorization: `token ${localStorage.getItem("token")}` },
        };
    
        callPostTaxProfileMobileOtp({
          url,
          method: "POST",
          statusObj,
          config,
          defaultFallback,
          body,
        });
      };
    
      return [
        data,
        error,
        isLoading,
        postTaxProfileMobileOtp,
        setSuccessData,
        setError,
      ];
}

export default usePostTaxProfileMobileVerify