import React from "react";
import { useParams } from "react-router-dom";
import RealEstate from "./RealEstate/RealEstate";
import Cash from "./CashAndBank/Cash";
import PersonalAsset from "./Personal Assets/PersonalAsset";
import Deposits from "./Deposits/Deposits";
import Building from "./RealEstate/Building";
import Land from "./RealEstate/Land";
import SukanyaSamridhi from "./Deposits/SukanyaSamridhi";
import OtherAsset from "./Other Assets/OtherAsset";
import Gold from "./Personal Assets/Gold";
import Vehicle from "./Personal Assets/Vehicle";
import Furniture from "./Personal Assets/Furniture";
import Loan from "./Other Assets/Loan";
import Intellectual from "./Other Assets/Intellectual";
import Domain from "./Other Assets/Domain";

const AssetsComponets = () => {
  const { type } = useParams();

  let displayText = "";

  switch (type) {
    case "real-estate":
      displayText = <RealEstate />;
      break;
    case "real-estate-land":
      displayText = <Land />;
      break;
    case "real-estate-building":
      displayText = <Building />;
      break;
    case "cash":
      displayText = <Cash />;
      break;
    case "personal-asset":
      displayText = <PersonalAsset />;
      break;
    case "personal-asset-gold":
      displayText = <Gold />;
      break;
    case "personal-asset-vehicle":
      displayText = <Vehicle />;
      break;
    case "personal-asset-furniture":
      displayText = <Furniture />;
      break;
    case "deposits":
      displayText = <Deposits />;
      break;
    case "deposits-sukanya-samridhi":
      displayText = <SukanyaSamridhi />;
      break;

    case "other-asset":
      displayText = <OtherAsset />;
      break;
    case "other-asset-loan":
      displayText = <Loan />;
      break;
    case "other-asset-intellectual":
      displayText = <Intellectual />;
      break;
    case "other-asset-domain":
      displayText = <Domain />;
      break;
    default:
      displayText = "Redirected to wrong page!!";
  }
  return <div>{displayText}</div>;
};

export default AssetsComponets;
