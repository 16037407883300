import React from 'react'
import inboxImg from 'assets/images/registration/Featured icon.svg'
import Button from 'components/Button/BtnOrange'
import { Link } from 'react-router-dom'
import { GET_SERVICE_URL } from 'constants/routes'

function ServiceProvided() {
  return (
    <>
    <div className='px-[6%]'>
    <h4 className='font-[700] text-[1.rem] leading-[2.6rem] md:text-[2.3rem] md:leading-[3rem] lg:text-[2.3rem] lg:leading-[3rem]'>We provide popular services like</h4>
    <div className='serviceWrapper grid grid-cols-12'>
<div className='main h-72 flex flex-col lg:col-span-3 md:col-span-3 col-span-12 bg-purple-light-shade m-2 md:gap-16 gap-24 lg:gap-40 p-4 '>
    <img className='w-9' src={inboxImg} alt=''/>
    <div className='ol px-[6%]'> 
    <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem] pb-[20%]'>Udyam registration</p>
    <Link className='no-underline' to={`${GET_SERVICE_URL}/udyam-msme-registration`}> <Button
                px={"px-[2rem] w-[100%]"}
                py={"py-[0.5rem] "}
                name={
                  <>
               <span>Get Service</span>
                  </>
                }
              /></Link></div>
</div>
<div className='main flex h-72 flex-col lg:col-span-3 md:col-span-3 col-span-12 bg-purple-light-shade m-2 md:gap-16 gap-24 lg:gap-40 p-4 '>
    <img className='w-9' src={inboxImg} alt=''/>
    {/* <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem]'>Labour License</p> */}
    <div className='ol px-[6%]'> 
    <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem] lg:pb-[30%] md:pb-[30%] pb-[20%]'>Labour License</p>
    <Link className='no-underline' to={`${GET_SERVICE_URL}/udyam-msme-registration`}> <Button
                px={"px-[2rem] w-[100%]"}
                py={"py-[0.5rem] "}
                name={
                  <>
               <span>Get Service</span>
                  </>
                }
              /></Link></div>
</div>
<div className='main flex h-72 flex-col lg:col-span-3 md:col-span-3 col-span-12 bg-purple-light-shade m-2 md:gap-16 gap-24 lg:gap-40 p-4 '>
    <img className='w-9' src={inboxImg} alt=''/>
    {/* <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem]'>FSSAI - Food License</p> */}
    <div className='ol px-[6%]'> 
    <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem] pb-[20%]'>FSSAI - Food License</p>
    <Link className='no-underline' to={`${GET_SERVICE_URL}/udyam-msme-registration`}> <Button
                px={"px-[2rem] w-[100%]"}
                py={"py-[0.5rem] "}
                name={
                  <>
               <span>Get Service</span>
                  </>
                }
              /></Link></div>
</div>
<div className='main flex h-72 flex-col lg:col-span-3 md:col-span-3 col-span-12 bg-purple-light-shade m-2 md:gap-16 gap-24 lg:gap-40 p-4 '>
    <img className='w-9' src={inboxImg} alt=''/>
    {/* <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem]'>PF & ESI Compliance</p> */}
    <div className='ol px-[6%]'> 
    <p className=' mb-0 pr-[25%] font-[700] text-[1.5rem] text-[#4D4D4D] leading-[1.9rem] pb-[20%]'>PF & ESI Compliance</p>
    <Link className='no-underline' to={`${GET_SERVICE_URL}/udyam-msme-registration`}> <Button
                px={"px-[2rem] w-[100%]"}
                py={"py-[0.5rem] "}
                name={
                  <>
               <span>Get Service</span>
                  </>
                }
              /></Link></div>
</div>
    </div></div>

    </>
  )
}

export default ServiceProvided
