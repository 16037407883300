import useAPICall from "hooks/useAPICall";
import { USE_GET_OLD_REGIME_URL } from "constants/apis";

const useGetOldRegime = () => {
  const [data, error, isLoading, callGetOldRegime, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getOldRegime = (income_tax_return_id) => {
    const url = USE_GET_OLD_REGIME_URL.replace(":income_tax_return_id", income_tax_return_id);
    var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    callGetOldRegime({
      url,
      method: "GET",
      statusObj,
      config, 
      defaultFallback,
    });
  };

  return [data, error, isLoading, getOldRegime, setSuccessData, setError];
};

export default useGetOldRegime;
