import React from "react";
import networthImg from "assets/images/incomeTax/Networth.png";
import regularImg from "assets/images/incomeTax/imgContent.png";
import dashboard from "assets/images/incomeTax/dashboardImg.png";
import dataImport from "assets/images/incomeTax/dataImportImg.png";
import dueDate from "assets/images/incomeTax/dueDateImg.png";


const MakeSpecial = () => {
  return (
    <div className="py-[8%] px-[8%]">
      <h2 className="lg:font-[700] lg:text-[2.3rem] lg:leading-[3rem] md:font-[700] md:text-[2rem] md:leading-[3rem] font-[700] text-[2rem] leading-[2.8rem]">
        What Makes{" "}
        <span className="bg-gradient-to-r from-[#D14588] via-[#E65757]  to-[#7E0CDE] text-transparent bg-clip-text">
          Us
        </span>{" "}
        Special
      </h2>

      <div className="mt-7 ">
        <div className=" grid grid-cols-12 gap-4 mb-4">
          <div className="rounded-lg md:rounded-3xl col-span-12 md:col-span-6 drop-shadow-md bg-[#DCDCFC]">
            <h2 className="font-[700] text-[1rem] px-4 pt-4">User-friendly Dashboard</h2>
            <p className="font-[500] text-[#9092A3] px-4">Handle yout taxes smoothly with our easy-to-navigate platform</p>
            <img src={dashboard} alt="" className="mt-4 md:rounded-br-3xl  rounded-br-lg"/>

          </div> 
          <div className="rounded-lg md:rounded-3xl col-span-12 md:col-span-6 drop-shadow-md bg-[#FFDEDE]">
            <h2 className="font-[700] text-[1rem] px-4 pt-4">Data Import & Automated Filing</h2>
            <p className="font-[500] text-[#9092A3] px-4">Save time with automated data import and error-free filing.</p>
            <img src={dataImport} alt="" className="mt-4 md:rounded-br-3xl rounded-br-lg"/>

          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className=" rounded-lg md:rounded-3xl col-span-12 md:col-span-4 drop-shadow-md  bg-white-body ">
            <h2 className="font-[700] text-[1rem] px-4 pt-4">
              Never Miss a Due Date
            </h2>
            <p className="font-[500] text-[#9092A3] px-4">
              Get automatic reminders and alerts to stay compliant.
            </p>
            <img src={dueDate} alt="due date" className="mt-4"/>
          </div>
          <div className="rounded-lg md:rounded-3xl col-span-12 md:col-span-4 drop-shadow-md  bg-white-body px-4 pt-4">
            <h2 className="font-[700] text-[1rem]">
              Regular & Annual Mandates
            </h2>
            <p className="font-[500] text-[#9092A3]">
              Stay on top of yout financial obligations effortlessly.
            </p>
            <img src={regularImg} alt="" className=""/>

          </div>
          <div className="rounded-lg md:rounded-3xl col-span-12 md:col-span-4 drop-shadow-md  bg-white-body">
            <h2 className="font-[700] text-[1rem] px-4 pt-4">
              Net Worth Tracking
            </h2>
            <p className="font-[500] text-[#9092A3] px-4">
              Stay on top of you financial obligations effortlessly.
            </p>
            <img src={networthImg} alt="" className="px-2"/>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeSpecial;
