import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { myContext } from "App";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

// images
import logo from "assets/images/dashboard/logo.svg";
import loginView from "assets/images/login/loginView.svg";

// APIs
import usePatchBusinessUserDetails from "./data/usePatchBusinessUserDetails";
import WelcomeModal from "./WelcomeModal";


const BusinessDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { setIsLoggedIn } = useContext(myContext);
  const [businessType, setBusinessType] = useState("");
  const [businessCategory, setbusinessCategory] = useState("");
  const [gstRegistered, setgstRegistered] = useState(true);
  const [gstNumber, setgstNumber] = useState("");
  const [annualTurnover, setannualTurnover] = useState("");
  const [state, setstate] = useState("");
  const [errors, setErrors] = useState({});

  const [patchData, error, isLoading, patchDetails] =
    usePatchBusinessUserDetails();

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Details added");
      if(localStorage.getItem("user")){
         setIsOpen(true)
      }else{
        navigate("/newLogin");
      }
    }
  }, [patchData, setIsLoggedIn]);

  useEffect(() => {
    if (error) {
      showErrorToast("Invalid Details");
    }
  }, [error]);


  const validateFields = () => {
    let newErrors = {};
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    if (!businessType || businessType === "") {
      newErrors.businessType = "Business Type is required";
    }
    if (!businessCategory) {
      newErrors.businessCategory = "Business category is required";
    }
    if (!gstRegistered) {
      newErrors.gstRegistered = "GST Registered is required";
    }
    if (!gstNumber.trim()) {
      newErrors.gstNumber = "GST Number is required (e.g., 22AAAAA0000A1Z5)";
    } else if (gstNumber.length !== 15) {
      newErrors.gstNumber = "GST Number must be exactly 15 characters long (e.g., 22AAAAA0000A1Z5)";
    } else if (!gstRegex.test(gstNumber)) {
      newErrors.gstNumber = "GST Number must be in the correct format (e.g., 22AAAAA0000A1Z5)";
    }
    
    
    
    if (!annualTurnover) {
      newErrors.annualTurnover = "Annual Turnover is required";
    }
    if (!state) {
      newErrors.state = "State is required";
    }
  
    setErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handlePatchDetails();
    }
  };

  const handlePatchDetails = () => {
    if (validateFields()) {
      let updateData = {
        user_id: location.state.user_id,
        // business_type: parseInt(businessType),
        business_type: businessType,
        business_category: businessCategory,
        gst_registered: Boolean(gstRegistered),
        gst_number: gstNumber,
        annual_turnover: annualTurnover,
        state: state,
      };
      patchDetails(updateData);
    }
  };

  const handleGstRegistered = (e) => {
    setgstRegistered(e.target.value);
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleGoToNextPage = () => {
    setIsOpen(false);
    navigate("/dashboard");
  };

  return (
    <div className="bg-[#FFFFFF] w-full min-h-screen flex">
      <div className="md:w-[50%] w-[100%] min-h-screen flex items-center justify-center">
        <div className="md:w-[60%] w-[80%]">
          <div className="flex flex-col items-center">
            <img src={logo} alt="logo" width="60%" />
            <p className="m-0 text-[#334155] text-[0.93rem]">
              All-in-one financial care
            </p>
          </div>
          <div className="flex items-center justify-center mt-3">
            <p className="text-[#334155] font-[600] m-0 md:text-[1rem] text-[0.9rem]">
              Share a few business details
            </p>
          </div>
          <div className="md:w-[90%] p-2 mt-2 flex flex-col justify-center gap-3">
            <div className="flex flex-col gap-1 ">
              <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[500]">
                Business Type
              </p>
              <InputField
              onKeyDown={handleKeyDown}
                type="select"
                // name="businessType"
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                options={[
                  { value: "", label: "Select an Option" },
                  { value: "Proprietorship", label: "Proprietorship" },
                  { value: "HUF", label: "HUF" },
                  { value: "Firm", label: "Firm" },
                  { value: "LLP", label: "LLP" },
                  { value: "Private Limited Company", label: "Private Limited Company" },
                  { value: "Public Limited Company", label: "Public Limited Company" },
                  { value: "Trust", label: "Trust" },
                ]}
              />

              {errors.businessType && (
                <p className="text-[0.9rem] text-red-600 m-0">
                  {errors.businessType}
                </p>
              )}  
            </div>
            <div className="flex flex-col gap-1 ">
              <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[500]">
                Business Category
              </p>
              <InputField
              onKeyDown={handleKeyDown}
                type="select"
                name="businessCategory"
                value={businessCategory}
                onChange={(e) => setbusinessCategory(e.target.value)}
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                options={[
                  { value: "", label: "Select an Option" },
                  { value: "Consultancy", label: "Consultancy" },
                  { value: "Technology", label: "Technology" },
                  { value: "Construction", label: "Construction" },
                  { value: "Clothing", label: "Clothing" },
                  { value: "Agriculture", label: "Agriculture" },
                  { value: "Salaried", label: "Salaried" },
                  { value: "Real Estate", label: "Real Estate" },
                  { value: "Food & beverage", label: "Food & beverage" },
                  { value: "Consulting", label: "Consulting" },
                  { value: "Rental", label: "Rental" },
                  { value: "Sports", label: "Sports" },
                  { value: "Decors", label: "Decors" },
                  { value: "Retail", label: "Retail" },
                  { value: "Healthcare", label: "Healthcare" },
                ]}
              />
              
              {errors.businessCategory && (
                <p className="text-[0.9rem] text-red-600 m-0">
                  {errors.businessCategory}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[500]">
                GST Registered?
              </p>
              <div className="flex gap-[1rem] w-[36%] items-center">
                <label className="font-[500] text-[#334155] text-[0.83rem] md:text-[0.9rem] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                  <InputField
                  onKeyDown={handleKeyDown}
                    type="radio"
                    // name="gstRegistered"  // Same name for both options
                    value="true"
                    checked = {gstRegistered == "true"}
                    onChange={handleGstRegistered}
                    className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                  />
                  Yes
                </label>

                <label className="font-[500] text-[#334155] text-[0.83rem] md:text-[0.9rem] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                  <InputField
                  onKeyDown={handleKeyDown}
                    type="radio"
                    // name="gstRegistered"  // Same name for both options
                    value="false"
                    checked = {gstRegistered == "false"}
                    onChange={handleGstRegistered}
                    className="peer ml-1 mr-1 text-[#D0D5DD] hover:cursor-pointer focus:accent-customPurple custom-radio"
                  />
                  No
                </label>
              </div>
              {errors.gstRegistered && (
                <p className="text-[0.9rem] text-red-600 m-0">
                  {errors.gstRegistered}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[500]">
                Annual Turnover
              </p>
              <InputField
              onKeyDown={handleKeyDown}
                type="select"
                name="annualTurnover"
                value={annualTurnover}
                onChange={(e) => setannualTurnover(e.target.value)}
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                options={[
                  { value: "", label: "Select an Option" },
                  { value: "Upto 15 Lakhs", label: "Upto 15 Lakhs" },
                  { value: "15 to 25 Lakhs", label: "15 to 25 Lakhs" },
                  { value: "25 to 50 Lakhs", label: "25 to 50 Lakhs" },
                ]}
              />

              {errors.annualTurnover && (
                <p className="text-[0.9rem] text-red-600 m-0">
                  {errors.annualTurnover}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <p className="m-0 text-[#334155] text-[0.83rem] md:text-[0.9rem] font-[500]">
                Gst Number
              </p>
              <InputField
              onKeyDown={handleKeyDown}
                name="gstNumber"
                placeholder="Enter GST Number"
                type="text"
                validation={{ required: true }}
                value={gstNumber}
                onChange={(e) => setgstNumber(e.target.value)}
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
              />
              {errors.gstNumber && (
                <p className="text-[0.9rem] text-red-600 m-0">
                  {errors.gstNumber}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <p className="m-0 text-[#334155] font-[500] text-[0.83rem] md:text-[0.9rem]">
                State
              </p>
              <InputField
              onKeyDown={handleKeyDown}
                type="select"
                name="state"
                value={state}
                onChange={(e) => setstate(e.target.value)}
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                options={[
                  { value: "", label: "Select an Option" },
                  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
                  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
                  { value: "Assam", label: "Assam" },
                  { value: "Bihar", label: "Bihar" },
                  { value: "Chhattisgarh", label: "Chhattisgarh" },
                  { value: "Goa", label: "Goa" },
                  { value: "Gujarat", label: "Gujarat" },
                  { value: "Haryana", label: "Haryana" },
                  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
                  { value: "Jharkhand", label: "Jharkhand" },
                  { value: "Karnataka", label: "Karnataka" },
                  { value: "Kerala", label: "Kerala" },
                  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
                  { value: "Maharashtra", label: "Maharashtra" },
                  { value: "Manipur", label: "Manipur" },
                  { value: "Meghalaya", label: "Meghalaya" },
                  { value: "Mizoram", label: "Mizoram" },
                  { value: "Nagaland", label: "Nagaland" },
                  { value: "Odisha", label: "Odisha" },
                  { value: "Punjab", label: "Punjab" },
                  { value: "Rajasthan", label: "Rajasthan" },
                  { value: "Sikkim", label: "Sikkim" },
                  { value: "Tamil Nadu", label: "Tamil Nadu" },
                  { value: "Telangana", label: "Telangana" },
                  { value: "Tripura", label: "Tripura" },
                  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
                  { value: "Uttarakhand", label: "Uttarakhand" },
                  { value: "West Bengal", label: "West Bengal" },
                  {
                    value: "Andaman and Nicobar Islands",
                    label: "Andaman and Nicobar Islands",
                  },
                  { value: "Chandigarh", label: "Chandigarh" },
                  {
                    value: "Dadra and Nagar Haveli and Daman and Diu",
                    label: "Dadra and Nagar Haveli and Daman and Diu",
                  },
                  { value: "Delhi", label: "Delhi" },
                  { value: "Lakshadweep", label: "Lakshadweep" },
                  { value: "Puducherry", label: "Puducherry" },
                ]}
              />

              {errors.state && (
                <p className="text-[0.9rem] text-red-600 m-0">{errors.state}</p>
              )}
            </div>
            <div className="">
              <button
                className="text-[#FFFFFF] bg-[#94A3B8] rounded-md px-2 py-1.5 w-[100%]"
                // onClick={() => setIsOpen(true)}
                onClick={handlePatchDetails}
              >
                Next
              </button>
            </div>
            {/* <WelcomeModal isOpen={isOpen} handleGoToNextPage={handleGoToNextPage} closeModal={closeModal}/> */}
          </div>
        </div>
      </div>
      <div className="w-[50%] min-h-screen border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
        <div className="flex flex-col items-center justify-center h-full gap-5">
          <div>
            <img src={loginView} alt="loginView" />
          </div>
          <div className="flex flex-col items-center">
            <p className="m-0 text-[#334155] text-[1rem] font-[600]">
              Unlock your business potential
            </p>
            <p className="m-0 text-[#334155] text-[0.9rem] text-center">
              Share your details and get personalized financial solutions
              tailored <br /> to your needs
            </p>
          </div>
        </div>
      </div>
      {isOpen && (
        <WelcomeModal
          isOpen={isOpen}
          handleGoToNextPage={handleGoToNextPage}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default BusinessDetails;
