
import React, { useEffect, useRef, useState } from 'react';
import Glide from '@glidejs/glide';
import { ServiceDetails } from "components/serviceDetails/GetServiceDetails";
import prev from 'assets/images/Serviceimages/stepsPrev.svg'
import next from 'assets/images/Serviceimages/stepNext.svg'
import active from 'assets/images/Serviceimages/active.svg'
import inactive from 'assets/images/Serviceimages/inactive.svg'
import leftPrev from 'assets/images/Serviceimages/leftPrev.svg'
import rightNext from 'assets/images/Serviceimages/rightPrev.svg'




const DualSlider = (props) => {

  const [count,setCount] = useState(1)
 
  const horizontalGlideRef = useRef(null);
  const verticalGlideRef = useRef(null);
  const horizontalGlideApi = useRef(null);
  const verticalGlideApi = useRef(null);

  const stepByStepArr = props.sendData.step_by_step_description;
  // console.log(stepByStepArr[0].step1);

  useEffect(() => {
    // Initialize horizontal Glide slider
    if (horizontalGlideRef.current) {
      horizontalGlideApi.current = new Glide(horizontalGlideRef.current, {
        type: 'carousel',
        perView: 1, // Display one slide at a time
        // autoplay: 3000,
        // direction: 'ltr', // Slide horizontally (left to right)
        // Add other Glide.js options as needed
      });

      horizontalGlideApi.current.mount();
    }

    // Initialize vertical Glide slider
    if (verticalGlideRef.current) {
      verticalGlideApi.current = new Glide(verticalGlideRef.current, {
        type: 'carousel',
        perView: 1, // Display one slide at a time
        // autoplay: 3000,
        // direction: 'ttb', // Slide vertically (top to bottom)
        // Add other Glide.js options as needed
      });

      verticalGlideApi.current.mount();
    }

    return () => {
      if (horizontalGlideApi.current) horizontalGlideApi.current.destroy();
      if (verticalGlideApi.current) verticalGlideApi.current.destroy();
    };
  }, []);

  const moveHorizontal = (direction) => {
    if (horizontalGlideApi.current && verticalGlideApi.current) {
      if (direction === 'prev') {
        horizontalGlideApi.current.go('<');
        verticalGlideApi.current.go('<');
      } else {
        horizontalGlideApi.current.go('>');
        verticalGlideApi.current.go('>');
      }
    }
  };

//   const moveHorizontal = (direction) => {
//     if (verticalGlideApi.current) {
//       if (direction === 'prev') {
//         verticalGlideApi.current.go('<');
//       } else {
//         verticalGlideApi.current.go('>');
//       }
//     }
//   };

  return (
    <div className="dual-slider">
        <div className='grid grid-cols-12 justify-evenly items-center'>
        <button className=' lg:hidden md:hidden flex' onClick={() => moveHorizontal('prev')}><img onClick={()=>{if(count>1){setCount(count-1)}else{setCount(8)}}} className='mx-auto' src={leftPrev} alt=''/> </button>
      
      <div className="horizontal-slider col-span-10 md:col-span-5 lg:col-span-5 relative">
        
        <div ref={verticalGlideRef} className="glide ">
          <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            <li className="glide__slide ">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            <li className="glide__slide">
            <div className="col-span-6">
                <div className=" bg-white-body flex justify-center rounded-3xl">
                  <img
                    className="py-[8rem] px-[4rem]"
                    src={ServiceDetails.thirdDiv.img}
                  />
                </div>
              </div>
            </li>
            {/* Add more slides as needed */}
          </ul>
          </div>
        </div>
    
      </div>

      <button className=' lg:hidden md:hidden flex' onClick={() => moveHorizontal('next')}><img onClick={()=>{if(count<8){setCount(count+1)}else{setCount(1)}}} className='mx-auto' src={rightNext} alt=''/>  </button>

      <div className="vertiacl-slider  col-span-12 lg:col-span-6 md:col-span-6 grid grid-cols-12">
        <div ref={horizontalGlideRef } className="glide verticalGlide col-span-12 overflow-hidden">
          <div className="glide__track  md:h-[100%] lg:h-[100%] h-[320px]" data-glide-el="track">
            <ul className="glide__slides verticalSlides ">
              {props.sendData &&
            <>

<li className="glide__slide verticalSlide ">
<div className="flex flex-col mr-11">
                        <p className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 01
                        </p>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[0].step1}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-12 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 02</p>
                        </div>
                      </div>
            </li>
            <li className="glide__slide verticalSlide ">
            <div className="flex flex-col mr-11 ">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 02
                        </span>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[1].step2}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-12 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 03</p>
                        </div>
                      </div>
            </li>
            <li className="glide__slide verticalSlide">
            <div className="flex flex-col mr-11 ">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 03
                        </span>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[2].step3}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-12 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 04</p>
                        </div>
                      </div>
            

            </li>
            <li className="glide__slide verticalSlide">
        
            <div className="flex flex-col mr-11 ">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 04
                        </span>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[3].step4}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-14 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 05</p>
                        </div>
                      </div>

            </li>
            <li className="glide__slide verticalSlide">
            <div className="flex flex-col mr-11 ">
                        <span className=" mt-20 bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 05
                        </span>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[4].step5}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-12 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 06</p>
                        </div>
                      </div>

            </li>
            <li className="glide__slide verticalSlide">
            <div className="flex flex-col mr-11 ">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 06
                        </span>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[5].step6}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-12 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 07</p>
                        </div>
                      </div>

            </li>
            <li className="glide__slide verticalSlide">
           
            <div className="flex flex-col mr-11  ">
                        <span className=" bg-purple-dark-shade font-[700] text-[1rem] leading-[1.3rem] text-center  text-white px-2  rounded-xl lg:mx-24 md:mx-10  py-1">
                          STEP 07
                        </span>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[6].step7}</p>
                        {/* <hr className=' ' /> */}
                        <div className='flex justify-center py-3' >
<div className=' border-l-2 border-black lg:h-20 h-6 md:h-12 '></div>
                        </div>
                        <div className="">
                          <p className="px-2 font-[700] text-[1rem] leading-[1.3rem] text-center text-white bg-purple-400 py-1 lg:mx-24 md:mx-10  rounded-xl">STEP 08</p>
                        </div>
                      </div>

            </li>
            <li className="glide__slide rotate-[270deg]  w-[100%] p-[1rem] pr-[4rem] justify-start">
          
            <div className=" flex flex-col lg:mr-32 justify-center items-center">
                        <p className=" bg-purple-dark-shade font-[700] text-[1rem] text-center lg:mx-24 md:mx-10  leading-[1.3rem] text-white px-2 h-[100%] rounded-xl py-1">
                          STEP 08
                        </p>
                        <p className=' font-[500] lg:text-[1.5rem] lg:leading-[1.8rem] md:text-[1.5rem] md:leading-[1.8rem] text-[1rem] leading-[1.3rem] text-center  text-gray-600 mt-4'>{stepByStepArr[7].step8}</p>
                       
                      </div>


            </li>

            </>
            }
            </ul>
          </div>
        </div>
        {/* <button onClick={() => moveHorizontal('prev')}>Previous Horizontal</button>
        <button onClick={() => moveHorizontal('next')}>Next Horizontal</button> */}
      </div>
      <div className='lg:flex md:flex hidden flex-col '>
      <button onClick={() => moveHorizontal('prev')}><img onClick={()=>{if(count>1){setCount(count-1)}else{setCount(8)}}} className='mx-auto' src={prev} alt=''/> </button>
<img alt=' ' src={active} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >1 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >2 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >3 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >4 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >5 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >6 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
<img alt=' ' src={`${count >7 ? active:inactive}`} className=' my-1 w-8 mx-auto' />
        <button onClick={() => moveHorizontal('next')}><img onClick={()=>{if(count<8){setCount(count+1)}else{setCount(1)}}} className='mx-auto' src={next} alt=''/>  </button>
    </div></div></div>
  );
};

export default DualSlider;