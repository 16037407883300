import React, { useEffect, useRef, useState } from "react";
import Glide from "@glidejs/glide";
import { Link } from "react-router-dom";
import { GET_SERVICE_URL } from "constants/routes";
import prev from "assets/images/home/chev.svg";
import next from "assets/images/home/next.svg";

const RegistrationSlider = () => {
  const glideRef = useRef(null);
  const [count, setCount] = useState(0);

  const registraionListNames = [
    "Trade Licence",
    "Labour License",
    "FSSAI - Food Licence",
    "PF & ESI Compliance",
  ];

  useEffect(() => {
    // Initialize Glide.js when the component mounts
    const glide = new Glide(glideRef.current, {
      //   type: 'slider', // Type of the slider (carousel, slider, carousel-3d, or slider-3d)
      perView: 3.3, // Number of slides to show per view
      //   focusAt: 'center', // Set where the slider should focus
      gap: 16, // Space between slides (in pixels)
      breakpoints: {
        1280: {
          perView: 3.3,
        },
        768: {
          perView: 2,
        },
        576: {
          perView: 1,
        },
      },
    });

    glide.mount(); // Mount the Glide.js instance

    // Clean up the Glide.js instance when the component unmounts
    return () => {
      glide.destroy();
    };
  }, []); // Run this effect only once when the component mounts
  return (
    <div className="glide" ref={glideRef}>
      <div
        className="glide__arrows flex flex-row-reverse px-[2%] gap-2"
        data-glide-el="controls"
      >
        {count === 0 ? (
          <button
            onClick={() => {
              setCount(1);
            }}
            className="glide__arrow glide__arrow--right "
            data-glide-dir=">"
          >
            <img src={next} />
          </button>
        ) : (
          <img src={next} />
        )}
        {count === 1 ? (
          <button
            onClick={() => {
              setCount(0);
            }}
            className="glide__arrow glide__arrow--left "
            data-glide-dir="<"
          >
            <img src={prev} />
          </button>
        ) : (
          <img src={prev} />
        )}
      </div>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          <Link
            className=" no-underline text-gray-700 satoshi"
            to={`${GET_SERVICE_URL}/udyam-msme-registration`}
          >
            {" "}
            <i className="glide__slide">
              {" "}
              <div className="  bg-light-orange  h-72  flex flex-col-reverse pb-[5%]  mr-[2%] rounded-2xl ">
                <h3 className=" not-italic pl-[5%] font-[700] text-[2.2rem] leading-[3rem] pr-[3rem]	satoshi">
                  Udyam Registration
                </h3>
              </div>
            </i>
          </Link>
          <i className="glide__slide">
            {" "}
            <div className="  bg-light-orange  h-72  flex flex-col-reverse pb-[5%]  mr-[2%] rounded-2xl ">
              <h3 className=" not-italic pl-[5%] font-[700] text-gray-700 text-[2.2rem] leading-[3rem] pr-[3rem]	">
                Labour License
              </h3>
            </div>
          </i>{" "}
          <i className="glide__slide">
            {" "}
            <div className="  bg-light-orange  h-72  flex flex-col-reverse pb-[5%]  mr-[2%] rounded-2xl ">
              <h3 className="not-italic pl-[5%] font-[700] text-gray-700 text-[2.2rem] leading-[3rem] pr-[3rem]	">
                FSSAI - Food Licence
              </h3>
            </div>
          </i>
          <i className="glide__slide">
            {" "}
            <div className="  bg-light-orange  h-72  flex flex-col-reverse pb-[5%]  mr-[2%] rounded-2xl ">
              <h3 className="not-italic pl-[5%] font-[700] text-gray-700 text-[2.2rem] leading-[3rem] pr-[3rem]	">
                PF & ESI Compliance
              </h3>
            </div>
          </i>
          {/* Add more slides as needed */}
        </ul>
      </div>
    </div>
  );
};

export default RegistrationSlider;
