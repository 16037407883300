import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_OTHER_ASSET_DETAILED_URL} from "constants/routes";
import AddOtherAsset from "./AddOtherAsset";
import useGetDomainDetails from "../data/useGetDomainDetails";
import domainPic from "assets/images/assets/domain2.svg";
import AddNewDomain from "./Add/AddNewDomain";
import Button from "components/Button/BtnOrange";

const Domain = () => {
  const location = useLocation();
  const [isAddDomain, setIsAddDomain]=useState(false)
  const addDomainBtnRef = useRef(null)
  const [reload, setIsReload]= useState(false)

  const queryParams = new URLSearchParams(location.search);

  const domainId =
    location.state?.landId || decodeURIComponent(queryParams.get("id"));

  const [getDomainData, getDomainError, getDomainIsLoading, getDomain] =
    useGetDomainDetails();


  useEffect(() => {
    getDomain(domainId);
  }, []);

  useEffect(()=>{
    if(reload){
      getDomain(domainId)
    }
  },[reload])

  useEffect(()=>{
    if(isAddDomain){
      addDomainBtnRef.current.click()
    }
  },[isAddDomain])

  const openHandleForm=()=>{
    setIsAddDomain(true)
  }


  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Other Assets"} asset_type={"Domain"}/>
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1rem]">
          Total Loans Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">₹{getDomainData?.data[0]?.total_domains_value}</h4>
        </div>  

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getDomainData?.data[0]?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getDomainData?.data[0]?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.6rem] m-0">{getDomainData?.data[0]?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
        <Button onClick={openHandleForm} name={"+ Add New Loan"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
       
        <div className="grid grid-cols-4 gap-2 ">
          {getDomainData ? (
            getDomainData?.data?.map((item, index) => (
              <Link
                to={`${ASSETS_OTHER_ASSET_DETAILED_URL.replace(
                  ":other_asset_type",
                  "Domain"
                )}?id=${domainId}/?domain_id=${item.id}`}
                state={{ assetId: domainId, itemId: item.id, itemName: item?.domain_name }}
                key={item.id}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={domainPic} alt="domainPic" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                   {item?.domain_name}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item?.purchase_price}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={domainPic} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Loans and Advances</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      {isAddDomain && (
        <AddNewDomain
          categoryId={domainId}
          setIsAddDomain={setIsAddDomain}
          isAddDomain={isAddDomain}
          setIsReload={setIsReload}
          triggerElement={
            <button ref={addDomainBtnRef} className="hidden">
              Add Domain
            </button>
          }
        />
      )}
    </div>
  );
};

export default Domain;
