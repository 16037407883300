import React from 'react'
import pic from 'assets/images/login/BusinessDetailsMobileView.svg'

const WelcomeModal = ({ isOpen, closeModal, handleGoToNextPage }) => {
  return (
    isOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
             <div className='w-[85%] sm:w-[70%] md:w-[300px] bg-[#FFFFFF] rounded-2xl px-8 py-6 relative'>
                {/* <div className='absolute top-2 right-3 font-[500] cursor-pointer' onClick={closeModal}>x</div> */}
                <div className='px-3 py-3.5 flex items-center justify-center'>
                    <img src={pic} alt='pic'/>
                </div>
                <div className='flex flex-col justify-center p-2'>
                    <h6 className='m-0 text-[#334155] font-[600] text-[1rem] md:text-[1.2rem] mb-2 text-center'>Welcome to Beyondtax!</h6>
                    <p className='m-0 text-[#334155] text-center mb-3 font-[500] md:text-[1rem] text-[0.9rem]'>All-in-one financial care for your business.</p>
                    <button className='bg-[#9065B4] rounded-lg text-[#FFFFFF] w-full px-2 py-1.5 md:text-[0.9rem] text-[0.8rem]' onClick={handleGoToNextPage}>Go to the dashboard</button>
                </div>
             </div> 
        </div>
    )
  )
}

export default WelcomeModal