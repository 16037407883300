import Button from "components/Button/BtnOrange";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import useGetAsset from "./data/useGetAsset";
import AddNewLand from "./RealEstate/Add/AddNewLand";
import AddNewDepositType from "./Deposits/AddNewDepositType";
import AddNewGold from "./Personal Assets/Add/AddNewGold";
import AddNewVehicle from "./Personal Assets/Add/AddNewVehicle";
import AddNewFurniture from "./Personal Assets/Add/AddNewFurniture";
import AddNewDomain from "./Other Assets/Add/AddNewDomain";
import AddNewIntellectual from "./Other Assets/Add/AddNewIntellectual";
import AddNewLoan from "./Other Assets/Add/AddNewLoan";

const ChooseAsset = () => {
  const realEstateBtnRef = useRef(null);
  const depositsBtnRef = useRef(null);
  const vehicleBtnRef = useRef(null);
  const goldBtnRef = useRef(null);
  const artBtnRef = useRef(null);
  const familyLoanBtnRef = useRef(null);
  const domainBtnRef = useRef(null);
  const intellectualBtnRef = useRef(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [getData, error, isLoading, getAsset] = useGetAsset();

  useEffect(() => {
    getAsset();
  }, []);

  const handleAssetTypeClick = (assetType, assetId) => {
    setSelectedAssetType(assetType);
    setSelectedAssetId(assetId);
    const realEstate = ["Land", "Buildings"];
    const deposits = ["Sukanya Samriddhi Yojana"];
    const vehicle = ["Vehicle"];
    const gold = ["Gold And Jewellery"];
    const art = ["Furniture And Art"];
    const familyLoan = ["Loans To Family/Friends"];
    const intellectual = ["Intellectual Property"];
    const domain = ["Domain"];

    if (realEstate.includes(assetType)) {
      realEstateBtnRef.current.click();
    } else if (deposits.includes(assetType)) {
      depositsBtnRef.current.click();
    } else if (vehicle.includes(assetType)) {
      vehicleBtnRef.current.click();
    } else if (gold.includes(assetType)) {
      goldBtnRef.current.click();
    } else if (art.includes(assetType)) {
      artBtnRef.current.click();
    } else if (familyLoan.includes(assetType)) {
      familyLoanBtnRef.current.click();
    } else if (domain.includes(assetType)) {
      domainBtnRef.current.click();
    } else if (intellectual.includes(assetType)) {
      intellectualBtnRef.current.click();
    }
  };

  return (
    <div>
      <DialogBox
        triggerElement={
          <Button
            px={"px-[0.9rem]"}
            py={"py-[0.3rem]"}
            name="+ Add New Asset"
          />
        }
        heading={
          <h4 className="text-lightGray font-[500] text-[1rem] m-0">
            Add to your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem] m-0">
              Choose an asset type
            </h4>
            <div className="overflow-y-auto max-h-[480px] scrollbar-hide">
              <div className="grid grid-cols-2 gap-[0.5rem] mt-2">
                {/* cash and Bank */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Cash & Bank (Coming Soon)
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.asset_category === "CASH AND BANK")
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className={`border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3]  ${
                          isDisabled
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                        }`}
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          // onClick={() => {
                          //   handleAssetTypeClick(item.asset_name, item.id);
                          //   handleClose();
                          // }}
                        >
                          <img src={item.asset_image} alt="" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* real Estate */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Real Estate
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.asset_category === "REAL ESTATE")
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleAssetTypeClick(item.asset_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.asset_image} alt="real estate" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* market */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Market
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.asset_category === "MARKETS")
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className={`border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3]  ${
                          isDisabled
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                        }`}
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          // onClick={() => {
                          //   handleAssetTypeClick(item.asset_name, item.id);
                          //   handleClose();
                          // }}
                        >
                          <img src={item.asset_image} alt="" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* personal assets */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Personal Assets
                  </h4>
                  {getData?.data
                    ?.filter(
                      (item) => item.asset_category === "PERSONAL ASSETS"
                    )
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleAssetTypeClick(item.asset_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.asset_image} alt="" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* deposits */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Deposits
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.asset_category === "DEPOSITS")
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleAssetTypeClick(item.asset_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.asset_image} alt="" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* Other assets */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Other Assets
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.asset_category === "OTHER ASSETS")
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleAssetTypeClick(item.asset_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.asset_image} alt="" />
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.asset_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      />
      <AddNewLand
        estateType={selectedAssetType}
        assetId={selectedAssetId}
        triggerElement={
          <button ref={realEstateBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />

      <AddNewDepositType
        depositType={selectedAssetType}
        assetId={selectedAssetId}
        triggerElement={
          <button ref={depositsBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />

      <AddNewGold
        categoryId={selectedAssetId}
        triggerElement={
          <button ref={goldBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewVehicle
        categoryId={selectedAssetId}
        triggerElement={
          <button ref={vehicleBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewFurniture
        categoryId={selectedAssetId}
        triggerElement={
          <button ref={artBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />

      <AddNewDomain
        categoryId={selectedAssetId}
        triggerElement={
          <button ref={domainBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewIntellectual
        categoryId={selectedAssetId}
        triggerElement={
          <button ref={intellectualBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewLoan
        categoryId={selectedAssetId}
        triggerElement={
          <button ref={familyLoanBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
    </div>
  );
};

export default ChooseAsset;
