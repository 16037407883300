import doc from "assets/images/Serviceimages/Frame 1000000961.svg";

import aadhar from "assets/images/Serviceimages/aadhar.svg";
import pan from "assets/images/Serviceimages/pan.svg";
import bank from "assets/images/Serviceimages/bank.svg";
import address from "assets/images/Serviceimages/address.svg";
import due from "assets/images/Serviceimages/due.svg";
import govtfee from "assets/images/Serviceimages/govtfee.svg";
import renewal from "assets/images/Serviceimages/renewal.svg";

import firstImg from "assets/images/Serviceimages/thirdDiv1.svg";

import arUp from "assets/images/Serviceimages/arrowUp.svg";
import arDown from "assets/images/Serviceimages/arDown.svg";

import whatsApp from "assets/images/Serviceimages/whatsApp.svg";
import phone from "assets/images/Serviceimages/phone.svg";
import headphone from "assets/images/Serviceimages/headPhones.svg";

import circle from "assets/images/Serviceimages/Ellipse 18.png";
import triangle from "assets/images/Serviceimages/Polygon 3.png";
import star from "assets/images/Serviceimages/Star 1.png";

export const ServiceDetails = {
  header: {  
    img: doc,
    heading: " Udyam - MSME Registration",
    paraBelowH: `Ready to give your business the recognition it deserves? With
Udyam Registration, you open doors to growth, savings, and opportunities and with us, it's a breeze. Let’s get your business registered today!`,
    paraBBH: "Get Certificate starting from ₹500/-",
    button: "Apply Now",
    con1: { img: whatsApp, details: "Text us on Whatsapp" },
    con2: { img: phone, details: "Schedule a Call" },
    con3: { img: headphone, details: "Call our Expert" },
  },
  secondDiv: {
    heading: `Udyam Registration: Your Business’s Official Stamp of Approval`,
    li1: "What is Udyam",
    li2: "Eligibility",
    li3: "Checklist",
    li4: "Essentials",
    li5: "Benefits",
    whatIsUdyam: "What’s Udyam - MSME Registration?",
    whatIsUdyamAns: `  Udyam Registration is the government's official stamp of
    approval for your business. It recognizes you as a Micro,
    Small, or Medium Enterprise (MSME) and comes with a host of
    benefits.`,
    eligbility: "Who is Eligible to get Udyam - MSME Registration?",
    eligbilityLi1: `Businesses involved in manufacturing or services.`,
    eligbilityLi2: `Companies that do not exceed the investment and turnover
    thresholds set for micro, small, or medium enterprises.`,
    docsRequired: `What are the documents required for Udyam Registration?`,
    aadharimg: aadhar,
    panImg: pan,
    bankImg: bank,
    renewalImg: renewal,
    addressImg: address,
    dueImg: due,
    govtFeeImg: govtfee,
    aadhar: "Aadhar",
    pan: "PAN",
    bankDetails: "Bank Details",
    businessAddProof: "Business Address Proof",
    dueDates: `What is the Due Date, Govt. Fee payable & Renewal Date fees
for Udyam Registration?`,
    duedatesDiv: {
      boldDue: `Due Date`,
      paraDue: `There is no specific due date for Udyam Registration.
Businesses can apply for it at any time.`,
      boldGov: `Govt. Fee`,
      paraGov: ` Udyam Registration is free of charge; government does not
levy any fee for this registration.`,
      boldRenew: "Renewal",
      renewPara: ` Once and Done: No Renewals Needed Lifetime Validity –
One-time process, no expiry date!`,
    },
    benefits: "What are the Benefits of Udyam Registration?",
    pinkLoan: {
      heading: `Easy Bank Loans`,
      des: `Think of it as a fast pass in the bank loan queue.`,
      img: circle,
    },
    pinkTax: {
      heading: `Fee & Tax Relief`,
      des: `Pay less Govt. challan amount & tax, save more money!`,
      img: triangle,
    },
    pinkPriority: {
      heading: `Becomes Priority`,
      des: `Get ahead of others when bidding for government tenders.`,
      img: star,
    },
  },
  thirdDiv: {
    heading: "The Step-by-Step Guide: How to Register for Udyam Registration",
    img: firstImg,
  },
  serviceFaq: {
    heading: "Frequently Asked Questions",
    paraBelowH: `Find answers to common questions and concerns about our program.`,
    btn: "Contact Us",
    difference: {
      ques: ` What’s the difference between Udyog Aadhaar and Udyam
    Registration?`,
      ans: `Udyog Aadhaar is the old system; Udyam Registration is
    the new and improved one.`,
      img: arUp,
    },
    time: {
      ques: `How quickly can I get registered for Udyam Certificate?`,
      ans: `If you're all set with your documents,it can be same-day
    fast!`,
    },
    img: arDown,
    noAadhar: {
      ques: `I don't have an Aadhaar card, now what?`,
      ans: `You'll need to get one; it's an essential requirement.`,
    },
    moreBuss: {
      ques: `What if I have more than one business?`,
      ans: `Each business needs its own unique registration.`,
    },
    editForm: {
      ques: `Made a mistake on the form, can I fix it?`,
      ans: ` Absolutely, with the edit feature, you can make
    corrections.`,
    },
  },
};
