import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import InputField from "components/input/InputField";
import "../../Liabilities/liabilities.css";
import EditLandValue from "./Edit/EditLandValue";
import EditPurchaseDetails from "./Edit/EditPurchaseDetails";
import EditCapitalExpense from "./Edit/EditCapitalExpense";
import EditLandAddress from "./Edit/EditLandAddress";
import AddFurniture from "./Add/AddFurniture";
import AddBuildingPhoto from "./Add/AddBuildingPhoto";
import { useLocation, useParams } from "react-router-dom";
import EditMandateExpense from "./Edit/EditMandateExpense";
import useGetLandDetails from "../data/usegetLandDetails";
import useGetBuildingDetails from "../data/useGetBuildingDetail";
import usePatchLandDetails from "../data/usePatchLandDetails";
import { showSuccessToast } from "components/toaster/toastHelper";
import usePostFurnitureDetails from "../data/usePostFurniture";
import usePatchFurnitureDetails from "../data/usePatchFurniture";
import usePatchBuildingDetails from "../data/usePatchBuildingDetails";
import usePostCapitalDetails from "../data/usePostCapitalData";
import usePatchCapitalDetails from "../data/usePatchCapitalDetails";
import usePostMandateDetails from "../data/usePostMandateDetails";
import usePatchMandateDetails from "../data/usePatchMandateDetails";

const LandDetailedView = () => {
  const location = useLocation();
  const { estate_type } = useParams();
  const [surveryNo, setSurveryNo] = useState();
  const [area, setarea] = useState();
  const [city, setcity] = useState();
  const [pincode, setpincode] = useState();
  const [state, setstate] = useState();
  const [country, setcountry] = useState();
  const [landPhoto, setlandPhoto] = useState();
  const [assetType, setassetType] = useState();
  const [ownershipPercent, setownershipPercent] = useState();
  const [stampDuty, setstampDuty] = useState();
  const [registrationFee, setregistrationFee] = useState();
  const [brokerageFee, setbrokerageFee] = useState();
  const [otherExpenses, setotherExpenses] = useState();
  const [totalCost, settotalCost] = useState();
  const [loanTaken, setloanTaken] = useState("");
  const [insuranceTaken, setinsuranceTaken] = useState();
  const [purchaseDate, setpurchaseDate] = useState();
  const [buildingType, setbuildingType] = useState();
  const [furExpenseType, setFurExpenseType] = useState("");
  const [furExpenseDate, setFurExpenseDate] = useState("");
  const [furAmtPaid, setFurAmtPaid] = useState("");
  const [furUploadInvoice, setFurUploadInvoice] = useState("");
  const [valuationReport, setvaluationReport] = useState("");
  const [estimatedMarketValue, setestimatedMarketValue] = useState("");
  const [flatNo, setflatNo] = useState("");
  const [saleDeed, setsaleDeed] = useState("");
  const [capExpenseType, setcapExpenseType] = useState("");
  const [capExpenseDate, setcapExpenseDate] = useState("");
  const [capAmtPaid, setcapAmtPaid] = useState("");
  const [capUploadInvoice, setcapUploadInvoice] = useState("");

  const [manExpenseType, setmanExpenseType] = useState("");
  const [manExpenseDate, setmanExpenseDate] = useState("");
  const [manAmtPaid, setmanAmtPaid] = useState("");
  const [manUploadCertificate, setmanUploadCertificate] = useState("");

  const [isClose, setIsClose] = useState(false);

  const [getLandData, getLandError, getLandIsLoading, getLand] =
    useGetLandDetails();

  const [
    getBuildingData,
    getBuildingError,
    getBuildingIsLoading,
    getBuildindDetails,
  ] = useGetBuildingDetails();

  const queryParams = new URLSearchParams(location.search);
  const id = decodeURIComponent(queryParams.get("id"));
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const assetBuildingId = location.state?.assetId;
  const assetCategory = location.state?.assetCategory;
  const asset_type = location.state?.asset_type;
  const removeId =
    estate_type == "land"
      ? `${assetId}/?id=${itemId}&model_type=${"land"}`
      : `${assetId}/?id=${itemId}&model_type=${"building"}`;

  const [patchData, patchError, patchIsLoading, patchLandDetails] =
    usePatchLandDetails();

  const [
    patchBuildingData,
    patchBuildingError,
    patchBuildingIsLoading,
    patchBuildingDetails,
  ] = usePatchBuildingDetails();
  const [
    postFurnitureData,
    postFurnitureError,
    postFurnitureIsLoading,
    postFurnitureDetails,
  ] = usePostFurnitureDetails();

  const [
    patchFurnitureData,
    patchFurnitureError,
    patchFurnitureIsLoading,
    patchFurnitureDetails,
  ] = usePatchFurnitureDetails();

  const [
    postCapitalData,
    postCapitalError,
    postCapitalIsLoading,
    postCapitalDetails,
  ] = usePostCapitalDetails();

  const [
    patchCapitalData,
    patchCapitalError,
    patchCapitalIsLoading,
    patchCapitalDetails,
  ] = usePatchCapitalDetails();

  const [
    postMandateData,
    postMandateError,
    postMandateIsLoading,
    postMandateDetails,
  ] = usePostMandateDetails();

  const [
    patchMandateData,
    patchMandateError,
    patchMandateIsLoading,
    patchMandateDetails,
  ] = usePatchMandateDetails();

  const [capital, setCapital] = useState([
    {
      id: "",
      expense_type: "",
      amount_paid: "",
      expense_date: "",
      upload_invoice: "",
    },
  ]);

  const [mandate, setMandate] = useState([
    {
      expense_type: "",
      amount_paid: "",
      expense_date: "",
      upload_certificate: "",
    },
  ]);

  const [furniture, setFurniture] = useState([
    {
      land: "",
      expense_type: "",
      amount_paid: "",
      expense_date: "",
      upload_invoice: "",
    },
  ]);

  useEffect(() => {
    if (estate_type == "land") {
      getLand(id);
    } else {
      getBuildindDetails(id);
    }
  }, []);

  useEffect(() => {
    if (postCapitalData) {
      showSuccessToast("Capital Details Updated");
      if (estate_type == "land") {
        getLand(id);
      } else {
        getBuildindDetails(id);
      }
      setIsClose(true);
      setTimeout(() => {
        setIsClose(false); // Reset isClose after a small delay
      }, 500);
    }
  }, [postCapitalData]);

  useEffect(() => {
    if (patchCapitalData) {
      showSuccessToast("Capital Details Updated");
      if (estate_type == "land") {
        getLand(id);
      } else {
        getBuildindDetails(id);
      }
      setIsClose(true);
      setTimeout(() => {
        setIsClose(false); // Reset isClose after a small delay
      }, 500);
    }
  }, [patchCapitalData]);

  useEffect(() => {
    if (postFurnitureData) {
      showSuccessToast("Furniture Details Uploaded");
      if (estate_type == "land") {
        getLand(id);
      } else {
        getBuildindDetails(id);
      }
      setIsClose(true);
      setTimeout(() => {
        setIsClose(false); // Reset isClose after a small delay
      }, 500);
    }
  }, [postFurnitureData]);

  useEffect(() => {
    if (patchFurnitureData) {
      showSuccessToast("Furniture Details Updated");
      if (estate_type == "land") {
        getLand(id);
      } else {
        getBuildindDetails(id);
      }
      setIsClose(true);
      setTimeout(() => {
        setIsClose(false); // Reset isClose after a small delay
      }, 500);
    }
  }, [patchFurnitureData]);

  const [isPhotoAvailable, setIsPhotoAvailable] = useState(false);

  useEffect(() => {
    if (estate_type == "land") {
      if (patchData) {
        showSuccessToast("Land Details Updated");
        setIsClose(true);
        setIsPhotoAvailable(true);
        setTimeout(() => {
          setIsClose(false);
        }, 500);
        getLand(id);
      }
    } else {
      if (patchBuildingData) {
        showSuccessToast("Building Details Updated");
        setIsClose(true);
        setIsPhotoAvailable(true);
        setTimeout(() => {
          setIsClose(false); // Reset isClose after a small delay
        }, 500);
        getBuildindDetails(id);
      }
    }
  }, [patchData, patchBuildingData]);

  useEffect(() => {
    if (estate_type == "building") {
      if (patchMandateData) {
        showSuccessToast("Mandate Details Updated");
        setIsClose(true);
        setTimeout(() => {
          setIsClose(false);
        }, 500);
      }
      if (postMandateData) {
        showSuccessToast("Mandate Details Uploaded");
        setIsClose(true);
        setTimeout(() => {
          setIsClose(false);
        }, 500);
      }
    }
  }, [patchMandateData, postMandateData]);

  const [assetRatio, setAssetRatio] = useState("");
  const [totalAssetsValue, setTotalAssetsValue] = useState("");

  useEffect(() => {
    if (estate_type == "land") {
      if (getLandData?.data) {
        const data = getLandData?.data;
        setSurveryNo(data.survey_number);
        setarea(data?.area);
        setcity(data.city);
        setpincode(data.pin_code);
        setstate(data.state);
        setcountry(data.country);
        setlandPhoto(data.land_photo);
        setassetType(data?.asset_type);
        setownershipPercent(data?.ownership_percentage);
        setstampDuty(data.stamp_duty);
        setregistrationFee(data.registration_fee);
        setbrokerageFee(data.brokerage_fee);
        setotherExpenses(data.other_expenses);
        settotalCost(data.total_cost);
        setloanTaken(data.loan_taken);
        setCapital(data?.capital_expenses);
        setFurniture(data?.furnitures_and_fixtures);
        setAssetRatio(data?.assets_ratio);
        setTotalAssetsValue(data?.total_assets_value);
      }
    } else {
      if (getBuildingData?.data) {
        const data = getBuildingData?.data;
        // setSurveryNo(data.survery_no );
        setbuildingType(data?.building_type);
        setflatNo(data?.flat_door_block_no);
        setarea(data?.area);
        setcity(data.city);
        setpincode(data.pin_code);
        setstate(data.state);
        setcountry(data.country);
        setlandPhoto(data.building_photo);
        setassetType(data?.asset_type);
        setownershipPercent(data?.ownership_percentage);
        setstampDuty(data?.stamp_duty);
        setregistrationFee(data.registration_fee);
        setbrokerageFee(data.brokerage);
        settotalCost(data.total_cost);
        setloanTaken(data.loan_taken);
        setCapital(data?.capital_expenses);
        setMandate(data?.mandates);
        setpurchaseDate(data?.purchase_date);
        setinsuranceTaken(data?.insurance_taken);
        setvaluationReport(data?.valuation_report);
        setestimatedMarketValue(data?.estimated_market_value);
        setotherExpenses(data?.other_purchase_expenses);
        setsaleDeed(data?.sale_deed);
        setFurniture(data?.furnitures_and_fixtures);
        setAssetRatio(data?.assets_ratio);
        setTotalAssetsValue(data?.total_assets_value_count);
      }
    }
  }, [getLandData, getBuildingData]);

  const btnRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const furBtnRef = useRef(null);
  const [selectedFurItem, setSelectedFurItem] = useState(null);

  const MandateBtnRef = useRef(null);
  const [selectedMandateItem, setSelectedMandateItem] = useState(null);

  const handleEditClick = (item) => {
    setSelectedItem(item); // Set the selected item to be edited
    if (btnRef.current) {
      btnRef.current.click();
    }
  };

  const handleFurnitureClick = (item) => {
    setSelectedFurItem(item);
    if (furBtnRef.current) {
      furBtnRef.current.click();
    }
  };

  const handleMandateClick = (item) => {
    setSelectedMandateItem(item);
    if (MandateBtnRef.current) {
      MandateBtnRef.current.click();
    }
  };

  const updateLandData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(itemId)) formData.append("encoded_land_id", itemId);
    if (isNotEmpty(surveryNo)) formData.append("survey_number", surveryNo);
    if (isNotEmpty(area)) formData.append("area", area);
    if (isNotEmpty(city)) formData.append("city", city);
    if (isNotEmpty(pincode)) formData.append("pin_code", pincode);
    if (isNotEmpty(state)) formData.append("state", state);
    if (isNotEmpty(country)) formData.append("country", country);
    if (isNotEmpty(assetType)) formData.append("asset_type", assetType);
    if (isNotEmpty(ownershipPercent)) formData.append("ownership_percentage", ownershipPercent);
    if (isNotEmpty(stampDuty)) formData.append("stamp_duty", stampDuty);
    if (isNotEmpty(registrationFee)) formData.append("registration_fee", registrationFee);
    if (isNotEmpty(brokerageFee)) formData.append("brokerage_fee", brokerageFee);
    if (isNotEmpty(otherExpenses)) formData.append("other_expenses", otherExpenses);
    if (isNotEmpty(totalCost)) formData.append("total_cost", totalCost);
    if (isNotEmpty(loanTaken)) formData.append("loan_taken", loanTaken);
  
    // Append the land photo if it's a valid File instance
    if (landPhoto instanceof File) {
      formData.append("land_photo", landPhoto);
    }
  
    // Append capital expenses data
    capital.forEach((item, index) => {
      if (isNotEmpty(item.id)) formData.append(`capital_expenses[${index}]id`, item.id);
      if (isNotEmpty(item.expense_type)) formData.append(`capital_expenses[${index}]expense_type`, item.expense_type);
      if (isNotEmpty(item.expense_date)) formData.append(`capital_expenses[${index}]expense_date`, item.expense_date);
      if (isNotEmpty(item.amount_paid)) formData.append(`capital_expenses[${index}]amount_paid`, item.amount_paid);
  
      // Append the invoice file if it's a valid File instance
      if (item.upload_invoice instanceof File) {
        formData.append(`capital_expenses[${index}]upload_invoice`, item.upload_invoice);
      }
    });
  
    // Submit the form data via the API call
    patchLandDetails(formData, assetId);
  };
  

  const updateBuildingData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(itemId)) formData.append("encoded_building_id", itemId);
    if (isNotEmpty(buildingType)) formData.append("building_type", buildingType);
    if (isNotEmpty(flatNo)) formData.append("flat_door_block_no", flatNo);
    if (isNotEmpty(area)) formData.append("area", area);
    if (isNotEmpty(city)) formData.append("city", city);
    if (isNotEmpty(pincode)) formData.append("pin_code", pincode);
    if (isNotEmpty(state)) formData.append("state", state);
    if (isNotEmpty(country)) formData.append("country", country);
    if (isNotEmpty(assetType)) formData.append("asset_type", assetType);
    if (isNotEmpty(ownershipPercent)) formData.append("ownership_percentage", ownershipPercent);
    if (isNotEmpty(stampDuty)) formData.append("stamp_duty", stampDuty);
    if (isNotEmpty(registrationFee)) formData.append("registration_fee", registrationFee);
    if (isNotEmpty(brokerageFee)) formData.append("brokerage", brokerageFee);
    if (isNotEmpty(otherExpenses)) formData.append("other_purchase_expenses", otherExpenses);
    if (isNotEmpty(totalCost)) formData.append("total_cost", totalCost);
    if (isNotEmpty(loanTaken)) formData.append("loan_taken", loanTaken);
    if (isNotEmpty(estimatedMarketValue)) formData.append("estimated_market_value", estimatedMarketValue);
    if (isNotEmpty(purchaseDate)) formData.append("purchase_date", purchaseDate);
    if (isNotEmpty(insuranceTaken)) formData.append("insurance_taken", insuranceTaken);
  
    // Append files if they are valid
    if (landPhoto instanceof File) formData.append("building_photo", landPhoto);
    if (valuationReport instanceof File) formData.append("valuation_report", valuationReport);
  
    // Submit the form data via the API call
    patchBuildingDetails(formData, assetBuildingId);
  };
  

  const updateDetails = () => {
    if (estate_type == "land") {
      updateLandData();
    } else {
      updateBuildingData();
    }
  };

  const createFurnitureData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(furExpenseType)) formData.append("expense_type", furExpenseType);
    if (isNotEmpty(furExpenseDate)) formData.append("expense_date", furExpenseDate);
    if (isNotEmpty(furAmtPaid)) formData.append("amount_paid", furAmtPaid);
  
    // Append the invoice file if it's a valid File instance
    if (furUploadInvoice instanceof File) {
      formData.append("upload_invoice", furUploadInvoice);
    }
  
    // Submit the form data via the API call
    postFurnitureDetails(formData, id);
  };
  

  const updateFurnitureData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(selectedFurItem.id)) formData.append("id", selectedFurItem.id);
    if (isNotEmpty(selectedFurItem.expense_type)) formData.append("expense_type", selectedFurItem.expense_type);
    if (isNotEmpty(selectedFurItem.expense_date)) formData.append("expense_date", selectedFurItem.expense_date);
    if (isNotEmpty(selectedFurItem.amount_paid)) formData.append("amount_paid", selectedFurItem.amount_paid);
  
    // Append the invoice file if it's a valid File instance
    if (selectedFurItem.upload_invoice instanceof File) {
      formData.append("upload_invoice", selectedFurItem.upload_invoice);
    }
  
    // Submit the form data via the API call
    patchFurnitureDetails(formData, id);
  };
  

  const createCapitalData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(capExpenseType)) formData.append("expense_type", capExpenseType);
    if (isNotEmpty(capExpenseDate)) formData.append("expense_date", capExpenseDate);
    if (isNotEmpty(capAmtPaid)) formData.append("amount_paid", capAmtPaid);
  
    // Append the invoice file if it's a valid File instance
    if (capUploadInvoice instanceof File) {
      formData.append("upload_invoice", capUploadInvoice);
    }
  
    // Submit the form data via the API call
    postCapitalDetails(formData, id);
  };
  

  const updateCapitalData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(selectedItem.id)) formData.append("id", selectedItem.id);
    if (isNotEmpty(selectedItem.expense_type)) formData.append("expense_type", selectedItem.expense_type);
    if (isNotEmpty(selectedItem.expense_date)) formData.append("expense_date", selectedItem.expense_date);
    if (isNotEmpty(selectedItem.amount_paid)) formData.append("amount_paid", selectedItem.amount_paid);
  
    // Append the invoice file if it's a valid File instance
    if (selectedItem.upload_invoice instanceof File) {
      formData.append("upload_invoice", selectedItem.upload_invoice);
    }
  
    // Submit the form data via the API call
    patchCapitalDetails(formData, id);
  };
  

  const createMandateData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(manExpenseType)) formData.append("expense_type", manExpenseType);
    if (isNotEmpty(manExpenseDate)) formData.append("expense_date", manExpenseDate);
    if (isNotEmpty(manAmtPaid)) formData.append("amount_paid", manAmtPaid);
  
    // Append the certificate file if it's a valid File instance
    if (manUploadCertificate instanceof File) {
      formData.append("upload_certificate", manUploadCertificate);
    }
  
    // Submit the form data via the API call
    postMandateDetails(formData, id);
  };
  
  const updateMandateData = () => {
    const formData = new FormData();
  
    // Helper function to check if a value is valid
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
  
    // Append valid fields to the FormData
    if (isNotEmpty(selectedMandateItem.id)) formData.append("id", selectedMandateItem.id);
    if (isNotEmpty(selectedMandateItem.expense_type)) formData.append("expense_type", selectedMandateItem.expense_type);
    if (isNotEmpty(selectedMandateItem.expense_date)) formData.append("expense_date", selectedMandateItem.expense_date);
    if (isNotEmpty(selectedMandateItem.amount_paid)) formData.append("amount_paid", selectedMandateItem.amount_paid);
  
    // Append the certificate file if it's a valid File instance
    if (selectedMandateItem.upload_certificate instanceof File) {
      formData.append("upload_certificate", selectedMandateItem.upload_certificate);
    }
  
    // Submit the form data via the API call
    patchMandateDetails(formData, id);
  };
  

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading
        asset={"Real Estate"}
        asset_detail={assetCategory}
        asset_type={asset_type}
        assetId={assetId}
      />
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between items-center">
          <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            {estate_type === "land" ? "Land" : "Building"} Value
          </h4>
          {!isClose && (
            <EditLandValue
              assetId={assetId}
              deleteId={removeId}
              // deleteItem={deleteItem}
              estimatedMarketValue={estimatedMarketValue}
              valuationReport={valuationReport}
              setvaluationReport={setvaluationReport}
              assetType={assetType}
              ownershipPercent={ownershipPercent}
              setassetType={setassetType}
              setownershipPercent={setownershipPercent}
              setestimatedMarketValue={setestimatedMarketValue}
              updateDetails={updateDetails}
            />
          )}
        </div>

        <div className="flex justify-between mt-[1rem]">
          <div className="">
            <h4 className="text-customPurple font-[500] text-[1.7rem] ">
              ₹{totalCost}
            </h4>
            <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
              <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
                {assetRatio} % of Total Assets (₹{totalAssetsValue})
              </h4>
            </div>
          </div>

          <div className="grid grid-cols-2 divide-x bg-[#F8FAFC] border rounded-md ">
            <div className="p-[1rem]">
              <h4 className="text-lightGray font-[500] text-[0.8rem]">
                Land Status
              </h4>
              <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
                {assetType == "active" ? "Active" : "Idle"} Asset
              </h4>
            </div>
            <div className="p-[1rem]">
              <h4 className="text-lightGray font-[500] text-[0.8rem]">
                Percentage Of Ownership
              </h4>
              <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
                {ownershipPercent} %
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 mt-[1.5rem] gap-[1rem]">
        {/* purchase Details */}
        <div className="col-span-5 border bg-white rounded-xl p-[1rem]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Purchase Details
            </h4>

            <div className="flex gap-[1rem]">
              {estate_type === "building" ? (
                <WhiteButton
                  onClick={() => {
                    if (saleDeed) {
                      window.open(saleDeed, "_blank");
                    }
                  }}
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="View Sale Deed"
                />
              ) : (
                " "
              )}
              {!isClose && (
                <EditPurchaseDetails
                  stampDuty={stampDuty}
                  registrationFee={registrationFee}
                  brokerageFee={brokerageFee}
                  otherExpenses={otherExpenses}
                  totalCost={totalCost}
                  loanTaken={loanTaken}
                  setstampDuty={setstampDuty}
                  setregistrationFee={setregistrationFee}
                  setbrokerageFee={setbrokerageFee}
                  setotherExpenses={setotherExpenses}
                  settotalCost={settotalCost}
                  setloanTaken={setloanTaken}
                  updateDetails={updateDetails}
                />
              )}
            </div>
          </div>
          {/* form */}
          <div className="mt-2 flex justify-between ">
            <div className="flex flex-col gap-[1rem]">
              <div className="input-container group flex flex-col">
                <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                  Total Cost
                </label>
                <InputField
                  className=" w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                  value={totalCost}
                  readOnly
                />
              </div>
              {estate_type === "building" ? (
                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Insurance taken?
                  </label>
                  <InputField
                    className="w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={insuranceTaken === true ? "Yes" : "No"}
                    readOnly
                  />
                </div>
              ) : (
                ""
              )}

              <div className="input-container group flex flex-col">
                <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                  Stamp Duty
                </label>
                <InputField
                  className="w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                  value={stampDuty}
                  readOnly
                />
              </div>

              <div className="input-container group flex flex-col">
                <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                  Brokerage (if any)
                </label>
                <InputField
                  className="w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                  value={brokerageFee}
                  readOnly
                />
              </div>
            </div>

            <div className="flex flex-col gap-[1rem]">
              {estate_type === "building" ? (
                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Purchase Date
                  </label>
                  <InputField
                    className=" w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={purchaseDate}
                    readOnly
                  />
                </div>
              ) : (
                ""
              )}
              <div className="input-container group flex flex-col">
                <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                  Loan against this {estate_type}
                </label>
                <InputField
                  className=" w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                  value={loanTaken === true ? "Yes" : "No"}
                  readOnly
                />
              </div>

              <div className="input-container group flex flex-col">
                <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                  Registration Fee
                </label>
                <InputField
                  className="w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                  value={registrationFee}
                  readOnly
                />
              </div>

              <div className="input-container group flex flex-col">
                <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                  Other Expenses
                </label>
                <InputField
                  className="w-[70%] text-[1.1rem] mt-[0.4rem] text-lightGray font-[500] focus:outline-none group-hover:text-customPurple"
                  value={otherExpenses}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        {/* land address */}
        <div className="col-span-7 border bg-white rounded-xl p-[1rem]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              {estate_type === "land" ? "Land" : "Building"} Address
            </h4>
            <div className="flex gap-2 ">
              {!isPhotoAvailable &&
              (estate_type == "land"
                ? getLandData?.data?.land_photo === null
                : getBuildingData?.data?.building_photo === null) ? (
                <AddBuildingPhoto
                  updateDetails={updateDetails}
                  landPhoto={landPhoto}
                  setlandPhoto={setlandPhoto}
                />
              ) : (
                <button
                  onClick={() => {
                    if (landPhoto) {
                      window.open(landPhoto, "_blank");
                    }
                  }}
                  className="py-[0.3rem] px-[0.4rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md text-[0.8rem] font-[600] text-customPurple m-0"
                >
                  View Photo of the Asset
                </button>
              )}
              {/* )}  */}
              {!isClose && (
                <EditLandAddress
                  flatNo={flatNo}
                  setflatNo={setflatNo}
                  surveryNo={surveryNo}
                  area={area}
                  city={city}
                  pincode={pincode}
                  country={country}
                  state={state}
                  setSurveryNo={setSurveryNo}
                  setarea={setarea}
                  setcity={setcity}
                  setpincode={setpincode}
                  setcountry={setcountry}
                  setstate={setstate}
                  updateDetails={updateDetails}
                />
              )}
            </div>
          </div>
          {estate_type === "building" ? (
            <div>
              <h4 className="text-customPurple font-[500] text-[0.8rem] mt-2">
                Building Type
              </h4>
              <h4 className="text-customPurple font-[500] text-[1.1rem]">
                {buildingType
                  ? buildingType.charAt(0).toUpperCase() + buildingType.slice(1)
                  : ""}
              </h4>
            </div>
          ) : (
            ""
          )}

          <div className="mt-2">
            <div className="">
              <InputField
                name="area"
                readOnly
                value={area}
                className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem]"
              />
              <InputField
                name="area"
                readOnly
                value={`${city} ${pincode}`}
                className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem] mt-1"
              />
              <InputField
                name="area"
                readOnly
                value={`${state} ${country}`}
                className="border rounded-md w-full text-lightGray font-[500] py-1 px-2 text-[0.9rem] mt-1"
              />
            </div>
          </div>
        </div>
      </div>

      {/* furniture and fixtures */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Furniture & Fixtures
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              0 Rs.
            </h4>
          </div>
          {getLandData?.data?.furnitures_and_fixtures.length !== 0 &&
            !isClose && (
              <AddFurniture
                postData={createFurnitureData}
                furExpenseType={furExpenseType}
                furExpenseDate={furExpenseDate}
                furAmtPaid={furAmtPaid}
                furUploadInvoice={furUploadInvoice}
                setfurExpenseType={setFurExpenseType}
                setfurExpenseDate={setFurExpenseDate}
                setfurAmtPaid={setFurAmtPaid}
                setfurUploadInvoice={setFurUploadInvoice}
              />
            )}
        </div>

        {getLandData?.data?.furnitures_and_fixtures.length == 0 ? (
          <div className="flex flex-col justify-center items-center py-[1rem]">
            {!isClose && (
              <AddFurniture
                postData={createFurnitureData}
                furExpenseType={furExpenseType}
                furExpenseDate={furExpenseDate}
                furAmtPaid={furAmtPaid}
                furUploadInvoice={furUploadInvoice}
                setfurExpenseType={setFurExpenseType}
                setfurExpenseDate={setFurExpenseDate}
                setfurAmtPaid={setFurAmtPaid}
                setfurUploadInvoice={setFurUploadInvoice}
              />
            )}
            <h4 className="text-darkGray font-[500] text-[0.6rem] m-0">
              Additional Assets added to Land
            </h4>
          </div>
        ) : (
          <div className="hidden md:grid grid-cols-12 gap-1 my-[1rem] border border-[#E2E8F0] rounded ">
            <table className="col-span-12  ">
              <tr className="bg-[#F1F5F9]  rounded text-lightGray ">
                <th className="py-[1%] px-4 border col-span-2  text-[0.8rem] font-[500]  ">
                  Expense Type
                </th>
                <th className=" px-4  col-span-4 border text-[0.8rem] font-[500]">
                  Expense Date
                </th>
                <th className=" px-4 col-span-4 border text-[0.8rem] font-[500] ">
                  Amount Paid
                </th>
                <th className=" px-4  col-span-2 border text-[0.8rem] font-[500]">
                  Actions
                </th>
              </tr>

              {furniture.map((item, index) => {
                return (
                  <tr key={item.id} className="rounded text-darkGray">
                    <td className="px-4 py-3 p-0 border font-[500] text-[1rem]">
                      {item.expense_type}
                    </td>
                    <td className="px-4 p-0 border font-[500] text-[1rem]">
                      {item.expense_date}
                    </td>
                    <td className="px-4 p-0 border font-[500] text-[1rem]">
                      {item.amount_paid}
                    </td>
                    <td className="px-4 py-2 border p-0 font-[500] text-[1rem]">
                      <div className="flex gap-3">
                        <WhiteButton
                          onClick={() =>
                            window.open(item.upload_invoice, "_blank")
                          }
                          px={"px-[0.8rem]"}
                          py={"py-[0.3rem]"}
                          name="View Invoice"
                        />
                        <WhiteButton
                          onClick={() => handleFurnitureClick(item)}
                          px={"px-[0.8rem]"}
                          py={"py-[0.3rem]"}
                          name="Edit"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
            {selectedFurItem && !isClose && (
              <AddFurniture
                setSelectedFurItem={setSelectedFurItem}
                updateFurnitureData={updateFurnitureData}
                selectedFurItem={selectedFurItem}
                triggerElement={<button ref={furBtnRef} className="hidden" />}
              />
            )}
          </div>
        )}
      </div>

      {/* capital Expense */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Capital Expense
            </h4>
            <h4 className="font-[500] text-[1rem] m-0 p-1 bg-[#FEF2F2] text-[#DC2626] rounded-md">
              5,00,000 Rs.
            </h4>
          </div>
          {!isClose && (
            <EditCapitalExpense
              capExpenseDate={capExpenseDate}
              setcapExpenseDate={setcapExpenseDate}
              capExpenseType={capExpenseType}
              setcapExpenseType={setcapExpenseType}
              capAmtPaid={capAmtPaid}
              setcapAmtPaid={setcapAmtPaid}
              capUploadInvoice={capUploadInvoice}
              setcapUploadInvoice={setcapUploadInvoice}
              createLandData={createCapitalData}
              // handleChange={handleChange}
              // capital={capital}
              // setCapital={setCapital}
            />
          )}
        </div>

        <div className="hidden md:grid grid-cols-12 gap-1 my-[1rem] border border-[#E2E8F0] rounded ">
          <table className="col-span-12  ">
            <tr className="bg-[#F1F5F9]  rounded text-lightGray ">
              <th className="py-[1%] px-4 border col-span-2  text-[0.8rem] font-[500]  ">
                Expense Type
              </th>
              <th className=" px-4  col-span-4 border text-[0.8rem] font-[500]">
                Expense Date
              </th>
              <th className=" px-4 col-span-4 border text-[0.8rem] font-[500] ">
                Amount Paid
              </th>
              <th className=" px-4  col-span-2 border text-[0.8rem] font-[500]">
                Actions
              </th>
            </tr>

            {capital.map((item, index) => {
              return (
                <tr key={item.id} className="rounded text-darkGray">
                  <td className="px-4 py-3 p-0 border font-[500] text-[1rem]">
                    {item.expense_type.charAt(0).toUpperCase() +
                      item.expense_type.slice(1)}
                  </td>

                  <td className="px-4 p-0 border font-[500] text-[1rem]">
                    {item.expense_date}
                  </td>
                  <td className="px-4 p-0 border font-[500] text-[1rem]">
                    {item.amount_paid}
                  </td>
                  <td className="px-4 py-2 border p-0 font-[500] text-[1rem]">
                    <div className="flex gap-3">
                      <WhiteButton
                        onClick={() =>
                          window.open(item.upload_invoice, "_blank")
                        }
                        px={"px-[0.8rem]"}
                        py={"py-[0.3rem]"}
                        name="View Invoice"
                      />
                      <WhiteButton
                        onClick={() => handleEditClick(item)} // Set the selected item
                        px={"px-[0.8rem]"}
                        py={"py-[0.3rem]"}
                        name="Edit"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
          {selectedItem && !isClose && (
            <EditCapitalExpense
              setselectedItem={setSelectedItem}
              updateLandData={updateCapitalData}
              selectedItem={selectedItem} // Pass the selected item to the dialog
              triggerElement={<button ref={btnRef} className="hidden" />} // Hidden button reference to trigger dialog
            />
          )}
        </div>
      </div>

      {/* mandate expense */}
      {estate_type === "building" ? (
        <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
          <div className="flex justify-between">
            <div className="flex items-center gap-[1rem]">
              {" "}
              <h4 className="text-darkGray font-[500] text-[1rem] m-0">
                Mandate Expense
              </h4>
              <h4 className="font-[500] text-[1rem] m-0 p-1 bg-[#FEF2F2] text-[#DC2626] rounded-md">
                5,00,000 Rs.
              </h4>
            </div>
            {!isClose && (
              <EditMandateExpense
                manExpenseDate={manExpenseDate}
                setmanExpenseDate={setmanExpenseDate}
                manExpenseType={manExpenseType}
                setmanExpenseType={setmanExpenseType}
                manAmtPaid={manAmtPaid}
                setmanAmtPaid={setmanAmtPaid}
                manUploadCertificate={manUploadCertificate}
                setmanUploadCertificate={setmanUploadCertificate}
                createMandateData={createMandateData}
              />
            )}
          </div>

          <div className="hidden md:grid grid-cols-12 gap-1 my-[1rem] border border-[#E2E8F0] rounded ">
            <table className="col-span-12  ">
              <tr className="bg-[#F1F5F9]  rounded text-lightGray ">
                <th className="py-[1%] px-4 border col-span-2  text-[0.8rem] font-[500]  ">
                  Expense Type
                </th>
                <th className=" px-4  col-span-4 border text-[0.8rem] font-[500]">
                  Expense Date
                </th>
                <th className=" px-4 col-span-4 border text-[0.8rem] font-[500] ">
                  Amount Paid
                </th>
                <th className=" px-4  col-span-2 border text-[0.8rem] font-[500]">
                  Actions
                </th>
              </tr>

              {mandate.map((item) => {
                return (
                  <tr className="rounded text-darkGray" key={item.id}>
                    <td className="px-4 py-3 p-0 border font-[500] text-[1rem]">
                      {item.expense_type.charAt(0).toUpperCase() +
                        item.expense_type.slice(1)}
                    </td>

                    <td className=" px-4 p-0 border   font-[500] text-[1rem]">
                      {item.expense_date}
                    </td>
                    <td className=" px-4 p-0 border  font-[500] text-[1rem]">
                      {item.amount_paid}
                    </td>
                    <td className="px-4 py-2 border p-0 font-[500] text-[1rem]">
                      {/* {item.actions} */}
                      <div className="flex gap-3 ">
                        <WhiteButton
                          onClick={() =>
                            window.open(item.upload_certificate, "_blank")
                          }
                          px={"px-[0.8rem]"}
                          py={"py-[0.3rem]"}
                          name="View Document"
                        />{" "}
                        <WhiteButton
                          onClick={() => handleMandateClick(item)}
                          px={"px-[0.8rem]"}
                          py={"py-[0.3rem]"}
                          name="Edit"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
            {selectedMandateItem && !isClose && (
              <EditMandateExpense
                setSelectedMandateItem={setSelectedMandateItem}
                updateMandateData={updateMandateData}
                selectedMandateItem={selectedMandateItem} // Pass the selected item to the dialog
                triggerElement={
                  <button ref={MandateBtnRef} className="hidden" />
                } // Hidden button reference to trigger dialog
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LandDetailedView;
