import React, { useEffect, useState } from "react";
import Input from "components/input/Input";
import logo from "assets/images/dashboard/logo.svg";
import "assets/images/login/Section.svg";
import { Link, useNavigate } from "react-router-dom";
import backIcon from "assets/images/login/backIcon.png";

//API's
import usePostSendOTP from "./Data/usePostSendOTP";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import loginView from "assets/images/login/LoginFrontView.svg";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [err, setErr] = useState("");

  const [data, error, isLoading, postOtp] = usePostSendOTP();
  const navigate = useNavigate();

  const validateInputs = () => {
    setErr(""); // Clear any previous errors

    if (!email.trim()) {
      setErr("Email is required.");
      return false;
    }

    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setErr("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (submitClicked && email) {
      postOtp({ email: email });
      setSubmitClicked(false);
    }
  }, [submitClicked, email]);

  useEffect(() => {
    if (data) {
      showSuccessToast("OTP sent successfully");
      navigate("/otp", { state: { email: email, otp_id: data.data.otp_id } });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      let desc =
        error?.err?.response?.data?.data?.message ||
        "Something went wrong. Please try again.";
      showErrorToast("", desc);
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      setSubmitClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
    {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
    <div className="slightDarkBlack h-screen grid grid-cols-12 bg-white-body">
      <div className="col-span-6 p-4">
        <Link to={"/"}>
          {" "}
          <img src={logo} alt="" />
        </Link>
        <div className="flex flex-col items-center h-full justify-center ">
          <div className=" w-[60%]">
            <h2 className=" font-[600] leading-[2.1rem] text-[1.9rem] mb-8">
              Forgot password
            </h2>

            <Input
              onKeyDown={handleKeyDown}
              label={"Email"}
              placeholder={"Enter your email"}
              type="email"
              value={email}
              setValue={setEmail}
            />
            {/* <p className="font-[600] flex justify-center items-center">or</p>
            <Input
              label={"Mobile Number"}
              placeholder={"Enter your Mobile Number"}
              value={mobileNumber}
              setValue={setMobileNumber}
            /> */}
          </div>
          <br />
          <p className="text-center text-[0.9rem] text-red-600 m-0">{err}</p>

          <button
            onClick={handleSubmit}
            className="bg-[#94A3B8] rounded-md px-2 py-1.5 w-[60%] text-white"
          >
            Reset password
          </button>
          <div className="flex justify-between my-2 items-center">
            <Link
              to="/newLogin"
              style={{ textDecoration: "none", color: "#667085" }}
            >
              <button className="m-0 flex gap-3">
                <img src={backIcon} alt="backIcon" className="py-1" />
                Back to log in
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-span-6 border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
        <div className="flex flex-col items-center justify-center h-full">
          <div>
            <img src={loginView} alt="loginView" />
          </div>
          <div className="flex flex-col items-center">
            <p className="m-0 text-[#334155] text-[1rem] font-[600]">
              Simplify Your Finances with Beyondtax
            </p>
            <p className="m-0 text-[#334155] text-[0.9rem]">
              Manage your finances, tax, and compliance all in one place.
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ForgotPassword;
