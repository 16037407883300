import React,{useContext} from "react";
import BlogsTitle from "./BlogsTitle.js";
import RecentBlogs from "./RecentBlogs.js";
import BlogsList from "./BlogsList.js";
import Exmaple from "./exmaple.js";
import './Blogs.css'
import { loaderContext } from "App";
import { useSearchParams } from "react-router-dom";


function Blogs() {
  const {loading,setLoading} = useContext(loaderContext)

  const [searchParams] = useSearchParams()

  let value = (searchParams.get('workOrderId'))

  return (
    <>
{loading }
      <BlogsTitle />
      <RecentBlogs />
      <BlogsList />
{/* <Exmaple /> */}
    </>
  );
}

export default Blogs;
