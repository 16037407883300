import useAPICall from "hooks/useAPICall";
import { GET_SERVICES_LIST_URL } from "constants/apis";


const useGetServiceList = () => {
  const [servicesListdata, servicesListerror, servicesListIsLoading, callGetServicesList, setSuccessServicesListData, setServicesListError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setServicesListError(en.something_went_wrong);
    setSuccessServicesListData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const servicesListdata = res;
        if (servicesListdata && typeof servicesListdata === "object") {
          setSuccessServicesListData(servicesListdata);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server servicesListError",
      status_code: 500,
      callBack: defaultFallback
    }
  ];

  const getServicesList = () => {
    const url = GET_SERVICES_LIST_URL;
    const config = {};
    callGetServicesList({
      url,
      method: "GET",
      statusObj,
      config,
      defaultFallback,
    });
  };
  return [servicesListdata, servicesListerror, servicesListIsLoading, getServicesList, setSuccessServicesListData, setServicesListError];
};

export default useGetServiceList;
