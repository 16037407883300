import React, { useEffect,useContext } from "react";
import { useParams} from "react-router-dom";
import ServiceTitle from "./ServiceTitle";
import ServicesDescription from "./ServicesDescription";
import ServicesGuide from "./ServicesGuide";
import ServiceFaq from "./ServiceFaq";
import "./Service.css";
import useGetService from "./Data/useGetService";
import { useLocation } from "react-router-dom";

import { loaderContext } from "App"; 
 
function ServicePage() {
  const { servicesSlug } = useParams();
  const {loading,setLoading} = useContext(loaderContext)


  const [data, error, isLoading, getService, setSuccessData, setError] =
    useGetService();
  useEffect(() => {
    getService(servicesSlug);
  }, []);

  if(isLoading){
    setLoading(true)
  }
if(data){
  setLoading(false)

}
  // console.log(data)

  // console.log(data, error, isLoading)
  // if (data) {
  //   console.log("data", data.data.data.faq);
  // }

  return (
    <> 
      {data && (
        <>
          <ServiceTitle sendData={data.data.data} />
          <ServicesDescription sendData={data.data.data} />
          <ServicesGuide sendData={data.data.data} />
          <ServiceFaq sendData={data.data.data} />
        </>
      )}
 
    </>
  );
}

export default ServicePage;
