import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
} from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import usePostTaxSummaryOthers from "../Data/usePostTaxSummaryOthers";
import useGetTaxSummaryOther from "../Data/useGetTaxSummaryOthers";
import { myContext } from "App";
import usePatchTaxSummaryOther from "../Data/usePatchTaxSummaryOther";
import { useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

const TaxSummaryOthers = forwardRef(({isDisabled}, ref) => {
  const { income_tax_return_id } = useParams();

  const [otherData, otherError, otherIsLoading, postOtherDetails] =
    usePostTaxSummaryOthers();

  const [getData, getError, getIsLoading, getTaxSummaryOther] =
    useGetTaxSummaryOther();

  const [patchData, patchError, patchIisLoading, patchTaxSummaryOther] =
    usePatchTaxSummaryOther();

  const defaultValue = {
    interest_incomes: [
      {
        interest_income_type: 1,
        description: "",
        interest_amount: "",
      },
    ],
    interest_on_it_refunds: [
      {
        particular: "",
        description: "",
        amount: "",
        interest_on_it_refunds_status: 1,
      },
    ],
    dividend_incomes: [
      {
        particular: "",
        description: "",
        amount: "",
      },
    ],
    income_from_betting: [
      {
        particular: "",
        description: "",
        amount: "",
      },
    ],
  };

  const form = useForm({
    defaultValues: defaultValue,
  });
  const { register, control, handleSubmit, reset, formState, resetField, setValue } =
    form;

  const { errors } = formState;

  useEffect(() => {
    getTaxSummaryOther(income_tax_return_id);
  }, []);

  const onSubmit = (data) => {
    console.log("submit", data);
    let {
      interest_incomes,
      interest_on_it_refunds,
      dividend_incomes,
      income_from_betting,
    } = data;
    let flagForUpdate = false;
    const f_interest_incomes = interest_incomes.every((item) => "id" in item);
    const f_interest_on_it_refunds = interest_on_it_refunds.every(
      (item) => "id" in item
    );
    const f_dividend_incomes = dividend_incomes.every((item) => "id" in item);
    const f_income_from_betting = income_from_betting.every(
      (item) => "id" in item
    );

    if (
      f_dividend_incomes ||
      f_income_from_betting ||
      f_interest_incomes ||
      f_interest_on_it_refunds
    ) {
      flagForUpdate = true;
    }
    interest_incomes = interest_incomes.map((item) => {
      const { created_at, updated_at, income_tax, income_tax_return, ...rest } =
        item;
      return rest;
    });
    interest_on_it_refunds = interest_on_it_refunds.map((item) => {
      const { created_at, updated_at, income_tax, income_tax_return, ...rest } =
        item;
      return rest;
    });
    dividend_incomes = dividend_incomes.map((item) => {
      const { created_at, updated_at, income_tax, income_tax_return, ...rest } =
        item;
      return rest;
    });
    income_from_betting = income_from_betting.map((item) => {
      const { created_at, updated_at, income_tax, income_tax_return, ...rest } =
        item;
      return rest;
    });
    const alteredData = {
      interest_incomes,
      dividend_incomes,
      income_from_betting,
      interest_on_it_refunds,
    };
    if (flagForUpdate) {
      patchTaxSummaryOther(income_tax_return_id, alteredData);
    } else {
      postOtherDetails(income_tax_return_id, alteredData);
    }
  };

  // Use useEffect to reset form values when getData changes
  useEffect(() => {
    let modifiedData = defaultValue;
    if (getData?.data?.data) {
      let {
        dividend_incomes,
        income_from_betting,
        interest_incomes,
        interest_on_it_refunds,
      } = getData?.data?.data;
      if (dividend_incomes.length == 0) {
        dividend_incomes = defaultValue.dividend_incomes;
      }
      if (income_from_betting == 0) {
        income_from_betting = defaultValue.income_from_betting;
      }
      if (interest_incomes == 0) {
        interest_incomes = defaultValue.interest_incomes;
      }
      if (interest_on_it_refunds == 0) {
        interest_on_it_refunds = defaultValue.interest_on_it_refunds;
      }
      modifiedData = {
        interest_incomes,
        interest_on_it_refunds,
        income_from_betting,
        dividend_incomes,
      };
    }
    if (getData?.data?.data) {
      reset(modifiedData);
    } else {
      reset({
        defaultValue,
      });
    }
  }, [getData]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(onSubmit)(),
  }));

  useEffect(() => {
    if (otherData) {
      showSuccessToast("Other Details posted")
    }
  }, [otherData]);

  useEffect(() => {
    if (otherError) {
      console.log("Other error: ", otherError);
      showErrorToast("","Error in Posting")
    }
  }, [otherError]);

  useEffect(() => {
    if (getError) {
      console.log("get Other error: ", getError);
      showErrorToast("","Error")
    }
  }, [getError]);

  useEffect(() => {
    if (patchData) {
      console.log("patch", patchData);
      showSuccessToast("Details updated")
    }
  }, [patchData]);

  useEffect(() => {
    if (patchError) {
      console.log("patch", patchData);
      showErrorToast("","Error in updating" )
    }
  }, [patchError]);

  const {
    fields: interestIncomeFields,
    append: appendInterestIncome,
    remove: removeInterestIncome,
  } = useFieldArray({
    name: "interest_incomes",
    control,
  });

  const {
    fields: interestRefundFields,
    append: appendInterestRefund,
    remove: removeInterestRefund,
  } = useFieldArray({
    name: "interest_on_it_refunds",
    control,
  });

  const {
    fields: dividendIncomeFields,
    append: appendDividendIncome,
    remove: removeDividendIncome,
  } = useFieldArray({
    name: "dividend_incomes",
    control,
  });

  const {
    fields: bettingIncomeFields,
    append: appendBettingIncome,
    remove: removeBettingIncome,
  } = useFieldArray({
    name: "income_from_betting",
    control,
  });

  const watchFieldArrayIncome = useWatch({
    control,
    name: "interest_incomes",
  });

  const watchFieldArrayRefund = useWatch({
    control,
    name: "interest_on_it_refunds",
  });
  let controlledFields = interestIncomeFields.map((field, index) => {
    if (watchFieldArrayIncome) {
      return {
        ...field,
        interest_income_type: Number(
          watchFieldArrayIncome[index]?.interest_income_type
        ),
      };
    } else {
      return {
        ...field,
      };
    }
  });

  let refundControlledFields = interestRefundFields.map((field, index) => {
    if (watchFieldArrayRefund) {
      return {
        ...field,
        interest_on_it_refunds_status: Number(
          watchFieldArrayRefund[index]?.interest_on_it_refunds_status
        ),
      };
    } else {
      return {
        ...field,
      };
    }
  });

  const removeItem = (index, str) => {
    if (str === "interest") {
      removeInterestIncome(index);
    }
    if (str === "refund") {
      removeInterestRefund(index);
    }
    if (str === "dividend") {
      removeDividendIncome(index);
    }
    if (str === "betting") {
      removeBettingIncome(index);
    }
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="border border-[#64748B] rounded mt-4 p-[1%]">
          {controlledFields.map((field, index) => {
            return (
              <div key={field.id} className="mb-4">
                <div className="flex flex-col md:flex-row justify-between items-left mb-3">
                  <h6 className="mt-4 text-[#334155]">Interest Income</h6>
                  <div className="flex flex-col md:flex-row gap-2.5">
                    <label 
                      onClick={()=>setValue(`interest_incomes.${index}.interest_income_type`,1)}
                     className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                      {/* <input
                        value={1}
                        {...register(
                          `interest_incomes.${index}.interest_income_type`
                        )}
                        type="radio"
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_income_type === 1}
                      /> */}
                      <InputField
                        type="radio"
                        name={`interest_incomes.${index}.interest_income_type`} // Use the same name for the group of radio buttons
                        value={1} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_income_type === 1}
                      />
                      Savings Bank Acc
                    </label>
                    <label 
                    onClick={()=>setValue(`interest_incomes.${index}.interest_income_type`,2)}
                    className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                      {/* <input
                        value={2}
                        {...register(
                          `interest_incomes.${index}.interest_income_type`
                        )}
                        type="radio"
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_income_type === 2}
                      /> */}
                      <InputField
                        type="radio"
                        name={`interest_incomes.${index}.interest_income_type`} // Use the same name for the group of radio buttons
                        value={2} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_income_type === 2}
                      />
                      Fixed Deposits
                    </label>
                    <label 
                    onClick={()=>setValue(`interest_incomes.${index}.interest_income_type`,3)}
                    className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                      {/* <input
                        value={3}
                        {...register(
                          `interest_incomes.${index}.interest_income_type`
                        )}
                        type="radio"
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_income_type === 3}
                      /> */}
                      <InputField
                        type="radio"
                        name={`interest_incomes.${index}.interest_income_type`} // Use the same name for the group of radio buttons
                        value={3} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_income_type === 3}
                      />
                      Others
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Description
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <InputField
                        type="text"
                        name={`interest_incomes.${index}.description`}
                        register={register}
                        placeholder="Enter Description"
                        validation={{
                          required: "*Description is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {errors?.interest_incomes?.[index]?.description?.message}
                    </p>
                  </div>
                  {/* <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Account Number
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                       <InputField
                        type="text"
                        placeholder="Enter Account No"
                        className="text-[#667085] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                  </div> */}
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Interest Amount
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
                      <span className="px-3 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`interest_incomes.${index}.interest_amount`}
                        register={register}
                        placeholder="Enter Amount"
                        validation={{
                          required: "*Amount is required",
                        }}
                        className="text-[#667085] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.interest_incomes?.[index]?.interest_amount
                          ?.message
                      }
                    </p>
                  </div>
                </div>
                <div>
                  {index > 0 ? (
                    <button
                      type="button"
                      disabled={isDisabled || false}
                      onClick={() => removeItem(index, "interest")}
                      className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                    >
                      <span className="mr-1.5">X</span>Remove
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
          <button
            onClick={() =>
              appendInterestIncome({
                interest_income_type: 1,
                description: "",
                interest_amount: "",
              })
            }
            type="button"
            disabled={isDisabled || false}
            className=" px-2 py-2 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] mt-3 text-[0.9rem]"
          >
            Add Interest
          </button>
          <hr className="text-[#E2E8F0] border mt-4" />
          {refundControlledFields.map((field, index) => {
            return (
              <div key={field.id}>
                <div className="flex flex-col md:flex-row justify-between items-left mb-3">
                  <h6 className="mt-4 text-[#334155]">Interest on IT Refund</h6>
                  <div className="flex gap-2.5">
                    <label 
                    onClick={()=>setValue(`interest_on_it_refunds.${index}.interest_on_it_refunds_status`,1)}
                    className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                      {/* <input
                        type="radio"
                        value={1}
                        {...register(
                          `interest_on_it_refunds.${index}.interest_on_it_refunds_status`
                        )}
                        checked={field.interest_on_it_refunds_status === 1}
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                      /> */}
                      <InputField
                        type="radio"
                        name={`interest_on_it_refunds.${index}.interest_on_it_refunds_status`} // Use the same name for the group of radio buttons
                        value={1} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_on_it_refunds_status === 1}
                      />
                      Recieved
                    </label>
                    <label 
                     onClick={()=>setValue(`interest_on_it_refunds.${index}.interest_on_it_refunds_status`,2)}
                     className="col-span-3 font-[500] text-[.9rem] text-[#64748B] leading-[1rem] hover:cursor-pointer flex items-center focus-within:text-customPurple peer-checked:text-customPurple">
                      {/* <input
                        type="radio"
                        value={2}
                        {...register(
                          `interest_on_it_refunds.${index}.interest_on_it_refunds_status`
                        )}
                        checked={field.interest_on_it_refunds_status === 2}
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                      /> */}
                      <InputField
                        type="radio"
                        name={`interest_on_it_refunds.${index}.interest_on_it_refunds_status`} // Use the same name for the group of radio buttons
                        value={2} // Set the value for this radio button
                        register={register} // Registering with React Hook Form
                        className="peer ml-1 mr-1 text-[#E2E8F0] text-[12px] h-[21px] hover:cursor-pointer focus:accent-customPurple custom-radio"
                        checked={field.interest_on_it_refunds_status === 2}
                      />
                      Pending
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Particulars
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <InputField
                        type="text"
                        name={`interest_on_it_refunds.${index}.particular`}
                        register={register}
                        placeholder="Enter Particulars"
                        validation={{
                          required: "*Particular is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085]  w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.interest_on_it_refunds?.[index]?.particular
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Description
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                       <InputField
                        type="text"
                        name={`interest_on_it_refunds.${index}.description`}
                        register={register}
                        placeholder="Enter Description"
                        validation={{
                          required: "*Description is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085] w-[100%] md:w-[100%]  md:px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.interest_on_it_refunds?.[index]?.description
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Amount
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <span className="px-3  text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`interest_on_it_refunds.${index}.amount`}
                        register={register}
                        placeholder="Enter Amount"
                        validation={{
                          required: "*Amount is required",
                        }}
                        className="text-[#667085]  w-[85%] px-[.7rem] md:py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {errors?.interest_on_it_refunds?.[index]?.amount?.message}
                    </p>
                  </div>
                </div>
                <div>
                  {index > 0 ? (
                    <button
                      type="button"
                      disabled={isDisabled || false}
                      onClick={() => removeItem(index, "refund")}
                      className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                    >
                      <span className="mr-1.5">X</span>Remove
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
          <button
            onClick={() =>
              appendInterestRefund({
                particular: "",
                description: "",
                amount: "",
                interest_on_it_refunds_status: 1,
              })
            }
            type="button"
            disabled={isDisabled || false}
            className=" px-2 py-2 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] mt-3 text-[0.9rem]"
          >
            Add Interest
          </button>
          <hr className="text-[#E2E8F0] border mt-4" />
          {dividendIncomeFields.map((field, index) => {
            return (
              <div key={field.id} className="relative">
                <div className="flex justify-between items-center mb-2">
                  <h6 className="mt-4 text-[#334155]">Dividend Income</h6>
                  <button
                    type="button"
                    className=" md:p-[1%] px-2 py-2 rounded-md bg-[#F3EFF8] text-[#9065B4] font-[500] text-[1rem] absolute right-0 transform translate-x-[-21px]"
                  >
                    Import Data
                  </button>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
                      Particulars
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                       <InputField
                        type="text"
                        name={`dividend_incomes.${index}.particular`}
                        register={register}
                        placeholder="Enter Particulars"
                        validation={{
                          required: "*Particular is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {errors?.dividend_incomes?.[index]?.particular?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Description
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <InputField
                        type="text"
                        name={`dividend_incomes.${index}.description`}
                        register={register}
                        placeholder="Enter Description"
                        validation={{
                          required: "*Description is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085]  w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {errors?.dividend_incomes?.[index]?.description?.message}
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Amount
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <span className="px-3 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`dividend_incomes.${index}.amount`}
                        register={register}
                        placeholder="Enter Description"
                        validation={{
                          required: "*Amount is required",
                        }}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {errors?.dividend_incomes?.[index]?.amount?.message}
                    </p>
                  </div>
                </div>
                <div>
                  {index > 0 ? (
                    <button
                      type="button"
                      disabled={isDisabled || false}
                      onClick={() => removeItem(index, "dividend")}
                      className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                    >
                      <span className="mr-1.5">X</span>Remove
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
          <button
            onClick={() =>
              appendDividendIncome({
                particular: "",
                description: "",
                amount: "",
              })
            }
            type="button"
            disabled={isDisabled || false}
            className=" px-2 py-2 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] mt-3 text-[0.9rem]"
          >
            Add Dividend
          </button>
          <hr className="text-[#E2E8F0] border mt-4" />
          {bettingIncomeFields.map((field, index) => {
            return (
              <div key={field.id}>
                <div className="flex justify-between items-center mb-2">
                  <h6 className="mt-4 text-[#334155]">
                    Income from Online Games/ Betting/Lottery/ Crossword
                    Puzzles/Horse Races etc
                  </h6>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Particulars
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <InputField
                        type="text"
                        name={`income_from_betting.${index}.particular`}
                        register={register}
                        placeholder="Enter Particulars"
                        validation={{
                          required: "*Particular is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085]  w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.income_from_betting?.[index]?.particular
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Description
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <InputField
                        type="text"
                        name={`income_from_betting.${index}.description`}
                        register={register}
                        placeholder="Enter Description"
                        validation={{
                          required: "*Description is required",
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                            message: "*Special characters are not allowed", // Error message if pattern is violated
                          }
                        }}
                        className="text-[#667085]  w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {
                        errors?.income_from_betting?.[index]?.description
                          ?.message
                      }
                    </p>
                  </div>
                  <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
                    <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#334155] group-focus-within:text-customPurple">
                      Amount
                    </h6>
                    <div className="flex items-center border-[#94A3B8] border-1 rounded w-[95%] h-[44px] md:h-[37px] focus-within:border-customPurple">
                      <span className="px-3 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name={`income_from_betting.${index}.amount`}
                        register={register}
                        placeholder="Enter Amount"
                        validation={{
                          required: "*Amount is required",
                        }}
                        className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
                      />
                    </div>
                    <p className="text-red-600">
                      {errors?.income_from_betting?.[index]?.amount?.message}
                    </p>
                  </div>
                </div>
                <div>
                  {index > 0 ? (
                    <button
                      type="dividend"
                      disabled={isDisabled || false}
                      onClick={() => removeItem(index, "betting")}
                      className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                    >
                      <span className="mr-1.5">X</span>Remove
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
          <button
            onClick={() =>
              appendBettingIncome({
                particular: "",
                description: "",
                amount: "",
              })
            }
            type="button"
            disabled={isDisabled || false}
            className=" px-2 py-2 rounded-md bg-[#F1F5F9] text-[#334155] flex  items-center font-[500] mt-3 text-[0.9rem] mb-2"
          >
            Add Income
          </button>
        </div>
      </form>
    </div>
  );
});

export default TaxSummaryOthers;
