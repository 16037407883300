import {
  UserIcon,
  ClipboardIcon,
  ReceiptPercentIcon,
  ChartBarIcon
} from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import isInViewport from "./isInViewport";
import Profile from "assets/images/dashboardNav/user-3-line.svg";
import Income from "assets/images/dashboardNav/heroicons-outline_receipt-tax.svg";
import Orders from "assets/images/dashboardNav/lets-icons_order.svg";
import Dashboard from "assets/images/dashboardNav/bar-chart-2 (1).svg";
import { myContext } from "App";
import CProfile from "assets/images/dashboardNav/user-3-line (1).svg";
import CIncome from "assets/images/dashboardNav/heroicons-outline_receipt-tax (1).svg";
import COrders from "assets/images/dashboardNav/lets-icons_order (1).svg";
import CDashboard from "assets/images/dashboardNav/bar-chart-2.svg";
import NavBar from "./NavBar";


let WIN_PREV_POSITION = window.pageYOffset;

const NAV = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: Dashboard,
    activeIcon: CDashboard,
  },
  {
    name: "Orders",
    link: "/orders",
    icon: Orders,
    activeIcon: COrders,
  },
  {
    name: "Income Tax",
    link: "/income-tax",
    icon: Income,
    activeIcon: CIncome,
  },
  {
    name: "Profile",
    link: "/profile",
    icon: Profile,
    activeIcon: CProfile,
  },
];

const FooterNav = (props) => {

  const {isLoggedIn} = useContext(myContext)
  const containerRef = useRef(null);

  const location = useLocation();

  const [activeIcons, setActiveIcons] = useState(NAV.map(item => item.icon));
  
  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return; 
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos; 
  };

  const handleIconClick = (index) => {
    setActiveIcons(NAV.map((item, idx) => idx === index ? item.activeIcon : item.icon));
  };

  if (!isLoggedIn) {
    return null;
  }
  
 

  return (
    <div
      ref={containerRef}
      className="FooterNav p-3 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
        return (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-gray-500 dark:text-gray-500 no-underline relative ${
                active ? "text-[#9065B4] dark:text-[#9065B4] "  : ""
              }`}
              onClick={() => handleIconClick(index)}
              target={item.target ? item.target : ""}
            >
              <img
                src={activeIcons[index]}
                alt={item.name}
                className={`w-6 h-6 ${active ? "text-[#9065B4]" : ""}`}
              />
              <span className={`font-[600] text-[0.9rem] leading-none mt-1 ${active ? "text-[#9065B4]" : ""}`}>{item.name}</span>
            </Link>
        );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
