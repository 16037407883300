import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import useGetReports from "../Reports/data/useGetReports";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const IncomeTaxInsightsChart = ({ data }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const [getData, getError, getIsLoading, getReports] = useGetReports();

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (data) {
      const years = data?.income_tax_insight?.map((item) => {
        const yearRange = item.year;
        // const yearParts = yearRange.split(" - ");
        // const lastYear = yearParts[1].slice(-2);
        // return `20${lastYear}`;
        return yearRange;
      });

      const incomeData = data?.income_tax_insight?.map(
        (item) => item.total_income
      );

      setChartData({
        labels: years,
        datasets: [
          {
            label: "Income",
            data: incomeData,
            backgroundColor: "#276EF1",
            barThickness: 35,
            borderRadius: 6,
          },
        ],
      });
    }
  }, [data]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}L`,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          color: "#475569",
          // stepSize: 1,
          callback: (value) => `₹${value}L`,
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: "#475569",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="h-60">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default IncomeTaxInsightsChart;
