import React from "react";
import { ServiceDetails } from "components/serviceDetails/GetServiceDetails";
function ServicesDescription(props) {


  return (
    <>
      { props.sendData && ( <> <div className="slightDarkBlack lg:my-0 md:my-0 my-20 px-[8%]">
        <h2 className="font-[700] lg:text-[3rem] md:text-[3rem] text-[1.7rem] leading-[2.5rem] md:leading-[3.5rem] lg:leading-[3.5rem] md:text-center  lg:text-center md:p-[7%] lg:p-[7%]">{ServiceDetails.secondDiv.heading}</h2>
       
          <div className="grid grid-cols-12 ">
            <div className=" col-span-3 lg:flex md:flex hidden  py-[8rem] mx-auto ">
              <div >
                <ul className=" sticky top-28">
                  <li className=" py-[1rem] font-[500] text-[1rem] leading-[1.4rem]  transition hover:duration-1000  hover:bg-purple-light-shade"><a className="no-underline text-black" href="#SDornage">{ServiceDetails.secondDiv.li1}</a></li>
                  <li className=" py-[1rem] font-[500] text-[1rem] leading-[1.4rem]  transition hover:duration-1000 hover:bg-purple-light-shade "><a className="no-underline text-black" href="#SDgreen">{ServiceDetails.secondDiv.li2}</a></li>
                  <li className=" py-[1rem] font-[500] text-[1rem] leading-[1.4rem]  transition hover:duration-1000 hover:bg-purple-light-shade "><a className="no-underline text-black" href="#SDred">{ServiceDetails.secondDiv.li3}</a></li>
                  <li className=" py-[1rem] font-[500] text-[1rem] leading-[1.4rem]  transition hover:duration-1000 hover:bg-purple-light-shade "><a className="no-underline text-black" href="#SDpurple">{ServiceDetails.secondDiv.li4}</a></li>
                  <li className=" py-[1rem] font-[500] text-[1rem] leading-[1.4rem]  transition hover:duration-1000 hover:bg-purple-light-shade "><a className="no-underline text-black" href="#SDpink">{ServiceDetails.secondDiv.li5}</a></li>
                </ul>
                </div>
            </div> 
            <div className="lg:col-span-9 md:col-span-9 col-span-12 rounded-3xl lg:bg-gray-100 md:bg-gray-100 lg:px-[12%] lg:py-[5rem] md:px-[12%] md:py-[5rem] py-6 ">
              <div className=' my-4 px-3 py-6 rounded-xl' id="SDornage">   
                <p className=" font-[700] md:text-[1.4rem] text-[1.1rem] lg:text-[1.4rem] leading-[1.5rem] mb-[1rem]">{ServiceDetails.secondDiv.whatIsUdyam}</p>
                <p className="font-[400] text-[1rem] leading-[1.4rem] text-gray-600">{ServiceDetails.secondDiv.whatIsUdyamAns}</p>
              </div> 
              <div className=' my-4 px-3 py-6 rounded-xl' id="SDgreen">    
                <p className=" font-[700] md:text-[1.4rem] text-[1.1rem] lg:text-[1.4rem] leading-[1.5rem] mb-[1rem]">{ServiceDetails.secondDiv.eligbility}</p>
                <ol>
                  <li className="font-[400] text-[1rem] leading-[1.4rem] text-gray-600">{ServiceDetails.secondDiv.eligbilityLi1}</li>
                  <li className="font-[400] text-[1rem] leading-[1.4rem] text-gray-600">{ServiceDetails.secondDiv.eligbilityLi2}</li>
                </ol>
              </div>
              <div className=' my-4 px-3 py-6 rounded-xl' id="SDred">    
                <p className=" font-[700] md:text-[1.4rem] text-[1.1rem] lg:text-[1.4rem] leading-[1.5rem] mb-[1rem]">{ServiceDetails.secondDiv.docsRequired}</p>
         
                  <div className="grid grid-cols-12">
             <div className=" col-span-12 lg:col-span-6 md:col-span-6 flex gap-3 items-center">
                      <img
                        className="picInRedDIv "
                        src={ServiceDetails.secondDiv.aadharimg}
                      />
                      <p className=" font-[500] text-[1.2rem] leading-[1.5rem]">{ServiceDetails.secondDiv.aadhar}</p>
                  </div>
             <div className=" col-span-12 lg:col-span-6 md:col-span-6 flex gap-3 items-center">

                      <img
                        className=" picInRedDIv"
                        src={ServiceDetails.secondDiv.panImg}
                      />
                      <p className="  font-[500] text-[1.2rem] leading-[1.5rem]">{ServiceDetails.secondDiv.pan}</p>
                    </div> 
             <div className=" col-span-12 lg:col-span-6 md:col-span-6 flex gap-3 items-center">
                     <img
                        className=" picInRedDIv"
                        src={ServiceDetails.secondDiv.bankImg}
                      />
                      <p className="  font-[500] text-[1.2rem] leading-[1.5rem]">{ServiceDetails.secondDiv.bankDetails}</p>
                      </div>
             <div className=" col-span-12 lg:col-span-6 md:col-span-6 flex gap-3 items-center">

                      <img
                        className="picInRedDIv "
                        src={ServiceDetails.secondDiv.addressImg}
                      />
                      <p className="  font-[500] text-[1.2rem] leading-[1.5rem]">{ServiceDetails.secondDiv.businessAddProof}</p>
                      </div></div>
              </div>
              <div className=' my-4 px-3 py-6 rounded-xl' id="SDpurple">   
                <p className=" font-[700] md:text-[1.4rem] text-[1.1rem] lg:text-[1.4rem] leading-[1.5rem] mb-[1rem]">{ServiceDetails.secondDiv.dueDates}</p>
                <div className="whiteInPurple p-2">
                  <img src={ServiceDetails.secondDiv.dueImg} />
                  <b className="">{ServiceDetails.secondDiv.duedatesDiv.boldDue}</b>
                  <p className=" my-2 text-sm font-[500] text-[0.8rem] leading-[1rem]" >{ServiceDetails.secondDiv.duedatesDiv.paraDue}</p>
                </div>
                <div className="whiteInPurple p-2">
                  <img src={ServiceDetails.secondDiv.govtFeeImg} />
                  <b className="">{ServiceDetails.secondDiv.duedatesDiv.boldGov}</b>
                  <p className=" my-2 text-sm font-[500] text-[0.8rem] leading-[1rem]">{ServiceDetails.secondDiv.duedatesDiv.paraGov}</p>
                </div>
                <div className="whiteInPurple p-2">
                  <img src={ServiceDetails.secondDiv.renewalImg} />
                  <b className="">{ServiceDetails.secondDiv.duedatesDiv.boldRenew}</b>
                  <p className=" my-2 text-sm font-[500] text-[0.8rem] leading-[1rem]">{ServiceDetails.secondDiv.duedatesDiv.renewPara}</p>
                </div>
              </div>
              <div className=' my-4 px-3 py-6 rounded-xl' id="SDpink">   
                <p className=" font-[700] md:text-[1.4rem] text-[1.1rem] lg:text-[1.4rem] leading-[1.5rem] mb-[1rem]">{ServiceDetails.secondDiv.benefits}</p>
                <div className="grid grid-cols-12 gap-2">
                  <div className="darkPink rounded-2xl h-48 p-[1rem] col-span-12 lg:col-span-4 md:col-span-4 relative">
                    <h5 className="my-3">{ServiceDetails.secondDiv.pinkLoan.heading}</h5>
                    <p className=" text-sm text-[0.8rem] leading-[1rem]">{ServiceDetails.secondDiv.pinkLoan.des}</p>
                    <img className="absolute w-[40%] right-0 bottom-0 rounded-br-2xl" src={ServiceDetails.secondDiv.pinkLoan.img} alt=""/>
                  </div>
                  <div className="darkPink rounded-2xl h-48 p-[1rem] col-span-12 lg:col-span-4 md:col-span-4 relative">
                    <h5 className="my-3">{ServiceDetails.secondDiv.pinkTax.heading}</h5>
                    <p className=" text-sm text-[0.8rem] leading-[1rem]"> {ServiceDetails.secondDiv.pinkTax.des}</p>
                    <img className="absolute w-[40%] right-0 bottom-0 rounded-br-2xl" src={ServiceDetails.secondDiv.pinkTax.img} alt=""/>
                  </div>
                  <div className="darkPink rounded-2xl h-48 p-[1rem] col-span-12 lg:col-span-4 md:col-span-4 relative">
                    <h5 className="my-3">{ServiceDetails.secondDiv.pinkPriority.heading}</h5>
                    <p className=" text-sm text-[0.8rem] leading-[1rem]">{ServiceDetails.secondDiv.pinkPriority.des}</p>
                    <img className="absolute w-[40%] right-0 bottom-0 rounded-br-2xl" src={ServiceDetails.secondDiv.pinkPriority.img} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
      </>)}
    </>
  );
}

export default ServicesDescription;
