import useAPICall from "hooks/useAPICall";
import { POST_NEW_REGISTRAION_API_URL } from "constants/apis";

const usePostNewSignUp = () => {
  const [
    registrationData,
    registrationError,
    isLoading,
    callPostNewSignUp,
    setSuccessRegistrationData,
    setRegistrationError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setRegistrationError(en.something_went_wrong);
    setSuccessRegistrationData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const registrationData = res;

        if (registrationData && typeof registrationData === "object") {
          setSuccessRegistrationData(registrationData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postNewSignup = (body) => {
    const url = POST_NEW_REGISTRAION_API_URL;
    const config = {};
    // console.log(body)
    callPostNewSignUp({
      url,
      method: "POST",
      statusObj,
      config,
      defaultFallback,
      body,
    });
  };
  return [
    registrationData,
    registrationError,
    isLoading,
    postNewSignup,
    setSuccessRegistrationData,
    setRegistrationError,
  ];
};

export default usePostNewSignUp;
