import React, { useEffect, useState } from "react";
import { Drawer as AntDrawer } from "antd";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import closeIcon from "assets/images/reports/closebutton.png";

const CustomizableDrawer = ({
  triggerElement,
  heading,
  content,
  placement = "bottom",
  height = 300,
  onClose,
  view,
  showDrawerWithOutTrigger=false,
  changingToViewDrawer,
  bgColor
}) => {
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const showDrawer = () => {
    if (isSmallScreen) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    if(changingToViewDrawer) changingToViewDrawer();
  };

  useEffect(()=>{
    if (isSmallScreen) {
      if(showDrawerWithOutTrigger){
        setOpen(true);
      }
    }
  },[showDrawerWithOutTrigger])

  return (
    <>
      <div onClick={showDrawer} className="cursor-pointer">
        {triggerElement ? triggerElement : ''}
      </div>

      {isSmallScreen && (
        <AntDrawer
          height={height}
          style={{ borderRadius: "20px 20px 0px 0px" }}
          placement={placement}
          closable={false}
          // onClose={handleClose}
          open={open}
          styles={{ body: { padding: 0 } }} 
        >
          <div className={`flex justify-between px-[1.5rem] py-3 bg-[#F3EFF8] rounded-tl-[20px] rounded-tr-[20px] ${bgColor?`bg-[${bgColor}]`:''}`}>
            <h4 className="font-[600] text-[1.3rem] text-[#334155] m-0">
              {heading}
            </h4>
            <button onClick={handleClose}>
              <img src={closeIcon} className="w-4 h-4" />
            </button>
          </div>
          {/* <h4 className="mt-[1rem] font-[400] text-[1.1rem] text-[#334155]">{content}</h4> */}
          <div className="mt-[1rem] font-[400] text-[1.1rem] text-[#334155] px-[1.5rem]">
            {typeof content === "function" ? content({ handleClose }) : content}
          </div>
        </AntDrawer>
      )}
    </>
  );
};

CustomizableDrawer.propTypes = {
  triggerElement: PropTypes.node,
  // content: PropTypes.node.isRequired,
  // content: PropTypes.node.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  heading: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  height: PropTypes.number,
  onClose: PropTypes.func,
};

CustomizableDrawer.defaultProps = {
  placement: "bottom",
  height: 300,
  onClose: null,
};

export default CustomizableDrawer;
