import useAPICall from "hooks/useAPICall";
import { POST_STAKEHOLDER_URL } from "constants/apis";

const usePostStakeHolder = () => {
  const [data, error, isLoading, callPostStakeholder, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postStakeHolder = (body) => {
    const url = POST_STAKEHOLDER_URL
    // var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    callPostStakeholder({
      url,
      method: "POST",
      statusObj,
    //   config,
      defaultFallback,
      body
    });
  };

  return [data, error, isLoading, postStakeHolder, setSuccessData, setError];
};

export default usePostStakeHolder;
