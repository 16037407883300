import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useState, useEffect } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";

const EditPurchaseDetails = ({
  stampDuty,
  registrationFee,
  brokerageFee,
  otherExpenses,
  totalCost,
  loanTaken,
  setstampDuty,
  setregistrationFee,
  setbrokerageFee,
  setotherExpenses,
  settotalCost,
  setloanTaken,
  updateDetails,
}) => {
  // State to track validation errors
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    // Validate the form whenever the input values change
    const validateForm = () => {
      const newErrors = {};

      const positiveNumberRegex = /^[+]?\d*\.?\d+$/;

      // if (!stampDuty || !positiveNumberRegex.test(stampDuty) || parseFloat(stampDuty) <= 0) {
      //   newErrors.stampDuty = "Stamp Duty must be a positive number.";
      // }
      // if (!registrationFee || !positiveNumberRegex.test(registrationFee) || parseFloat(registrationFee) <= 0) {
      //   newErrors.registrationFee = "Registration Fee must be a positive number.";
      // }
      // if (!brokerageFee || !positiveNumberRegex.test(brokerageFee) || parseFloat(brokerageFee) <= 0) {
      //   newErrors.brokerageFee = "Brokerage Fee must be a positive number.";
      // }
      // if (!otherExpenses || !positiveNumberRegex.test(otherExpenses) || parseFloat(otherExpenses) <= 0) {
      //   newErrors.otherExpenses = "Other Expenses must be a positive number.";
      // }
      // if (!totalCost || !positiveNumberRegex.test(totalCost) || parseFloat(totalCost) <= 0) {
      //   newErrors.totalCost = "Total Cost must be a positive number.";
      // }

      setErrors(newErrors);
      setFormValid(Object.keys(newErrors).length === 0); // If no errors, the form is valid
    };

    validateForm();
  }, [
    stampDuty,
    registrationFee,
    brokerageFee,
    otherExpenses,
    totalCost,
    loanTaken,
  ]);

  const handleLoanTaken = (e) => {
    setloanTaken(e.target.value === "true");
  };

  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" />
        }
        heading={"Edit Purchase Details"}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[10rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-2">
                <div className="input-container">
                  <label className="input-label">Stamp Duty</label>
                  <InputField
                    value={stampDuty}
                    onChange={(e) => setstampDuty(e.target.value)}
                    type="number"
                    placeholder="Enter Stamp Duty"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.stampDuty && (
                    <p className="text-red-500 text-xs">{errors.stampDuty}</p>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Registration Fee</label>
                  <InputField
                    value={registrationFee}
                    onChange={(e) => setregistrationFee(e.target.value)}
                    type="number"
                    placeholder="Enter Registration Fee"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.registrationFee && (
                    <p className="text-red-500 text-xs">
                      {errors.registrationFee}
                    </p>
                  )}
                </div>

                <div className="input-container mt-[0.4rem]">
                  <label className="input-label">Brokerage (if any)</label>
                  <InputField
                    value={brokerageFee}
                    onChange={(e) => setbrokerageFee(e.target.value)}
                    type="number"
                    placeholder="Enter Brokerage"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.brokerageFee && (
                    <p className="text-red-500 text-xs">
                      {errors.brokerageFee}
                    </p>
                  )}
                </div>

                <div className="input-container mt-[0.4rem]">
                  <label className="input-label">Other Purchase Expenses</label>
                  <InputField
                    value={otherExpenses}
                    onChange={(e) => setotherExpenses(e.target.value)}
                    type="number"
                    placeholder="Enter Other Expenses"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.otherExpenses && (
                    <p className="text-red-500 text-xs">
                      {errors.otherExpenses}
                    </p>
                  )}
                </div>

                <div className="input-container mt-[0.4rem]">
                  <label className="input-label">Total Cost</label>
                  <InputField
                    value={totalCost}
                    onChange={(e) => settotalCost(e.target.value)}
                    type="number"
                    placeholder="Enter Total Cost"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.totalCost && (
                    <p className="text-red-500 text-xs">{errors.totalCost}</p>
                  )}
                </div>

                <div className="input-container mt-[0.6rem]">
                  <label className="input-label">
                    Taken any loan against this property?
                  </label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        value="true"
                        className="mr-2 custom-radio"
                        onChange={handleLoanTaken}
                        checked={loanTaken == true}
                        name="loan_taken"
                      />
                      Yes
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        value="false"
                        className="mr-2 custom-radio"
                        onChange={handleLoanTaken}
                        checked={loanTaken == false} 
                        name="loan_taken"
                      />
                      No
                    </label>
                  </div>
                  {/* {errors.loanTaken && (
                    <p className="text-red-500 text-xs">{errors.loanTaken}</p>
                  )} */}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-[1rem]">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={updateDetails}
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                disabled={!formValid} // Disable Save button if form is invalid
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default EditPurchaseDetails;
