import { PATCH_TAX_SUMMARY_OTHERS_URL } from "constants/apis";
import useAPICall from "hooks/useAPICall";


const usePatchTaxSummaryOther = () => {
    const [
        data,
        error,
        isLoading,
        callPatchUpdateDetails,
        setSuccessData,
        setError,
      ] = useAPICall(undefined, "");
    
      const defaultFallback = () => {
        // setError(en.something_went_wrong);
        setSuccessData(undefined);
      };
      const statusObj = [
        {
          status_code: 200,
          status_text: "OK",
          callBack: (res) => {
            const data = res;
            if (data && typeof data === "object") {
              setSuccessData(data);
            } else {
              defaultFallback();
            }
          },
        },
        {
          status_text: "Bad Request",
          status_code: 400,
          callBack: defaultFallback,
        },
        {
          status_text: "Internal Server Error",
          status_code: 500,
          callBack: defaultFallback,
        },
      ];
      const patchTaxSummaryOther = (income_tax_return_id, body) => {
        const url = PATCH_TAX_SUMMARY_OTHERS_URL.replace(":income_tax_return_id",income_tax_return_id);
        var config = {
          headers: { Authorization: `token ${localStorage.getItem("token")}` },
        };
        // console.log("update details API: ", data);
        console.log("patchUserUpdate running", body);
    
        callPatchUpdateDetails({
          url,
          method: "PATCH",
          statusObj,
          config,
          defaultFallback,
          body,
        });
      };
    
      return [
        data,
        error,
        isLoading,
        patchTaxSummaryOther,
        setSuccessData,
        setError,
      ];
}

export default usePatchTaxSummaryOther