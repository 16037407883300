import React, { useState} from "react";
import qrCode from "assets/images/orders/QRCode.svg";
import { Drawer } from "antd";

export default function MobilePayDrawer(props) {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        onClick={showDrawer}
        className=" bg-[#9065B4] px-[2.5rem] py-1 rounded-lg border-2 text-[#F9FAFB]"
      >
        Pay
      </button>
      <Drawer
        className="test"
        height={600}
        style={{ borderRadius: "20px 20px 0px 0px" }}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        PaperProps={{
          style: { width: "50%", height: "60%", borderRadius: "4%" },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            onClose();
          },
        }}
      >
        <div>
          <p className="font-[700] text-[1.5rem] leading-[1.6rem] mb-10 text-[#344054]">
            Service Details
          </p>
          <div className="mb-8">
            <div className="grid grid-cols-12">
              <p className="col-span-8 font-[600] text-[1rem] leading-[1rem] text-[#344054]">
                {props.serviceName}
              </p>
              <p className="col-span-4 font-[600] text-[1rem] leading-[1rem] text-[#667085]">
                {props.createdAt}
              </p>
            </div>
            <div className="grid grid-cols-12 ">
              <p className="col-span-8 font-[400] text-[1rem] leading-[1rem] text-[#344054]">
                {props.workOrderId}
              </p>
              <p className="col-span-4 font-[700] text-[1.3rem] leading-[1rem] text-[#344054]">
                {props.amountPaid}
              </p>
            </div>
          </div>
          <h5 className="mb-4 font-[700] text-[1.5rem] leading-[1.6rem] text-[#344054]">
            Payment
          </h5>
        </div>

        <div className="flex justify-center ">
            <div>
              <div className=" mx-auto">
                <p className="text-center mb-2 font-[600] text-[#344054] text-[1rem]">
                  Your Payment Amount of {props.amountPaid} is due
                </p>
                <p className="text-center mb-2 text-[#667085] font-[500] text-[0.9rem]">
                  Pay the remaining amount to download the document
                </p>
              </div>
              <div className="flex flex-column my-[8%] gap-4">
                <div className=" ">
                  <h6 className="text-center mb-6 font-[600] text-[#344054] text-[1rem]">Bank Details</h6>
                  <p className="mb-1 text-center font-[600] text-[#667085] text-[1rem]">Account No.: 8872617382</p>
                  <p className="mb-1 text-center font-[600] text-[#667085] text-[1rem]">IFSC Code: KKBK0072637</p>
                  <p className="mb-1 text-center font-[600] text-[#667085] text-[1rem]">
                    Recipient Name: Beyond tax lmtd
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  {" "}
                  <p className="font-[600] text-[#344054] text-[0.9rem]"> OR</p>{" "}
                </div>
                <div className="">
                  <h6 className="text-center font-[600] text-[#344054] text-[1rem]">QR Code</h6>
                  <img className=" mx-auto" src={qrCode} alt="" />
                </div>
              </div>
              <div>
                <p className="text-center text-sm text-[#667085] font-[500] text-[0.9rem]  ">
                  If your payment is done it will take 2-3 hours to reflect in
                  our system.
                </p>
                <p className="text-center text-sm text-[#667085] font-[500] text-[0.9rem]">contact us at beyondtaxindia@gmail.com</p>
              </div>
            </div>
          </div>
      </Drawer>
    </>
  );
}
