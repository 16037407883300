import useAPICall from "hooks/useAPICall";
import { PATCH_BANK_ACCOUNT_URL } from "constants/apis";

const usePatchBankAccount = () => {
  const [data, error, isLoading, callPatchBankAccount, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const patchBankAccount = (bank_id, body) => {
    const url = PATCH_BANK_ACCOUNT_URL.replace(":bank_id", bank_id);
    // var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}

    callPatchBankAccount({
      url,
      method: "PUT",
      statusObj,
      // config,
      defaultFallback,
      body,
    });
  };

  return [data, error, isLoading, patchBankAccount, setSuccessData, setError];
};

export default usePatchBankAccount;
