import React, { useState, useEffect } from 'react';
import businessP from "assets/images/incomeTax/businessProfessionals.png";
import salaried from "assets/images/incomeTax/salariedIndividuals.png";
import specialCase from "assets/images/incomeTax/specialcases.png";
import BIcon1 from "assets/images/incomeTax/BIcon1.png";
import BIcon2 from "assets/images/incomeTax/BIcon2.png";
import BIcon3 from "assets/images/incomeTax/BIcon3.png";
import BIcon4 from "assets/images/incomeTax/BIcon4.png";
import Sicon1 from "assets/images/incomeTax/Sicon1.png";
import Sicon2 from "assets/images/incomeTax/Sicon2.png";
import Sicon3 from "assets/images/incomeTax/Sicon3.png";
import Cicon2 from "assets/images/incomeTax/Cicon2.png";
import Cicon3 from "assets/images/incomeTax/Cicon3.png";
import Cicon4 from "assets/images/incomeTax/Cicon4.png";


function TailoredTaxSolutions() {
  const [activeTab, setActiveTab] = useState('BusinessOwners');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const sections = [
    {
      id: 'SalariedIndividuals',
      title: 'Salaried Individuals',
      imageSrc: salaried,
      content: [
        { icon: Sicon1 , title: 'Single Employer', description: 'Easy tax filing for individuals with a single employer, maximizing your deductions effortlessly.' },
        { icon: Sicon2 , title: 'Multiple Employers', description: 'Seamless tax solutions for job switchers, ensuring all your employment income is properly reported.' },
        { icon: Sicon3 , title: 'Pensioners & Retirees', description: 'Simplifying the management of your pension income and other earnings.' },
        { icon: BIcon2 , title: 'Salaried with Freelancing', description: 'Salary and freelance income, covering all your earnings comprehensively.' },
      ],
    },
    {
      id: 'BusinessOwners',
      title: 'Business Owners',
      imageSrc: businessP,
      content: [
        { icon: BIcon1 , title: 'Small Business Owners', description: 'Simplified tax solutions tailored for small businesses, ensuring easy filing and detailed financial management.' },
        { icon: BIcon2 , title: 'Freelancers & Solopreneurs', description: 'Comprehensive tax filing for freelancers and solopreneurs, making sure all income sources are accurately reported.' },
        { icon: BIcon3 , title: 'Startups', description: 'Efficient tax management for startup founders, keeping compliance straightforward and helping you stay funding-ready.' },
        { icon: BIcon4 , title: 'Professionals', description: 'Specialized tax filing for professionals under presumptive tax schemes, reducing paperwork and compliance hassles.' },
      ],
    },
    {
      id: 'SpecialCases',
      title: 'Special Cases',
      imageSrc: specialCase,
      content: [
        { icon: BIcon4 , title: 'Rental Income', description: 'Efficient tax management for rental income earners, ensuring all allowable deductions are claimed.' },
        { icon: Cicon2 , title: 'Investors', description: 'Tailored tax solutions for investors, focusing on accurate capital gains reporting and tax optimization.' },
        { icon: Cicon3 , title: 'NRIs', description: 'Comprehensive tax solutions for NRIs, making it easy to file for foreign income.' },
        { icon: Cicon4 , title: 'Crypto and Stock Traders', description: 'Specialized tax solutions for crypto and stock traders, focusing on precise capital gains tracking and compliance.' },
      ]
    },
  ];

  const renderDesktopView = () => (
    <div className="">
      <div className="grid grid-cols-12 mb-10 ">
        {sections.map((section) => (
          <button
            key={section.id}
            className={`col-span-4 text-[1.1rem] font-[500] mx-4 px-4 py-4 rounded ${activeTab === section.id ? 'bg-white shadow font-[700] text-[1.2rem] text-[#8F64B2] border-b-4 border-[#8F64B2]' : ''}`}
            onClick={() => setActiveTab(section.id)}
          >
            {section.title}
          </button>
        ))}
      </div>
      <div className="bg-white-body shadow-md grid-cols-12 grid px-4">
        <div className="col-span-4 py-4">
          <img src={sections.find(s => s.id === activeTab).imageSrc} alt={sections.find(s => s.id === activeTab).title} className="pt-2 rounded-lg" />
        </div>
        <div className="col-span-8 py-4 grid grid-cols-2 gap-4">
            {sections.find(s => s.id === activeTab).content.map((item, index) => (
              <div className="flex justify-center items-center bg-[#F5F5F5] rounded-lg shadow px-2 ">
              <div className="">
                <img src={item.icon} alt={item.title} className="w-20 h-8" />
              </div>
              <div key={index} className="pt-3 px-2">
                <h4 className="text-[1rem] font-[700] mb-2">{item.title}</h4>
                <p className="text-[0.9rem] font-[400] text-[#717D96]">{item.description}</p>
              </div>
              </div>
              
            ))}
        </div>
      </div>
    </div>
  );

  const bgColors = ['bg-[#E1E1FE]', 'bg-[#CEE9DE]', 'bg-[#FEE2E2]'];

  const renderMobileView = () => (
    <>
      {sections.map((section, index) => (
        <div key={section.id} className={`mb-8 rounded-lg py-4 px-4 ${bgColors[index]}`}>
          <h3 className="text-[1.3rem] font-[700] text-center mb-4 mt-4">{section.title}</h3>
          <div className="space-y-4">
            {section.content.map((item, index) => (
              <div className='flex justify-center items-center bg-white px-2 rounded-lg shadow'>
              <div className="">
                <img src={item.icon} alt={item.title} className="w-20 h-8" />
              </div>
              <div key={index} className="pt-3 px-2">
                <h4 className="text-[1rem] font-[700] mb-2">{item.title}</h4>
                <p className="text-[0.9rem] font-[400] text-[#717D96]">{item.description}</p>
              </div>
              </div>
              
            ))}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div className="px-[8%] py-[8%]">
      <div className="max-w-7xl">
      <h2 className="font-[700] text-[1.9rem] md:mb-16 mb-10">
          <span className="bg-gradient-to-r from-[#7E0CDE] from-70%  to-[#D14588] text-transparent bg-clip-text">
            Tailored Tax Solutions
          </span>{" "}
          for Diverse Needs
        </h2>
        {isMobile ? renderMobileView() : renderDesktopView()}
      </div>
    </div>
  );
}

export default TailoredTaxSolutions;
