import React, { useContext, useEffect, useState } from "react";
import invite from "assets/images/dashboard/invite.svg";
import bell from "assets/images/dashboard/bell.svg";
import File from "assets/images/dashboard/File.svg";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "./Layout";

const HeaderDashboardProfile = () => {
  const navigate = useNavigate();
  const [activeType, setActiveType] = useState("connect");
  const userName = JSON.parse(localStorage.getItem("user"))
    ?.full_name?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const user = JSON.parse(localStorage.getItem("user"));
  const [showPP, setShopPP] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(false);
  const { profilePic } = useContext(ProfileContext);

  useEffect(() => {
    if (profilePic) {
      setShopPP(true);
    }
  }, [profilePic]);

  const firstNameLetter = user?.full_name
    ? user?.full_name.split(" ")[0].charAt(0).toUpperCase()
    : user?.business_name.split(" ")[0].charAt(0).toUpperCase();
  const secondNameLetter = user?.full_name?.split(" ")[1]
    ? user.full_name.split(" ")[1].charAt(0).toUpperCase()
    : user?.business_name?.split(" ")[1]
    ? user.business_name.split(" ")[1].charAt(0).toUpperCase()
    : "";

  const finalLetter = firstNameLetter + secondNameLetter;

  const handleToggleActive = (type) => {
    setActiveType(type);
  };
  return (
    <div className="bg-white border-b px-[1rem] py-[1.2rem] grid grid-cols-12">
      <div className="col-span-9 flex justify-between items-center pr-[1.5rem]">
        <h4 className="text-darkGray text-[1.3rem] m-0">Welcome, {userName}</h4>
        <div className="flex gap-[2rem]">
          <div className="bg-[#F3EFF8] p-1 rounded-md font-[500]">
            <button
              onClick={() => handleToggleActive("having")}
              className={`px-2 py-1 text-[0.8rem] ${
                activeType === "having"
                  ? "bg-customPurple text-white"
                  : "text-customPurple"
              } rounded-md`}
            >
              Having a business?
            </button>

            <button
              onClick={() => handleToggleActive("connect")}
              className={`px-2 py-1 text-[0.8rem] ${
                activeType === "connect"
                  ? "bg-customPurple text-white"
                  : "text-customPurple"
              } rounded-md`}
            >
              Connect your Business
            </button>
          </div>

          <button
            onClick={() => navigate("/sample-stakeholder")}
            className="flex gap-2 items-center text-customPurple text-[0.9rem] font-[500] rounded-md bg-[#F3EFF8] py-[0.4rem] px-[0.9rem]"
          >
            <img src={invite} alt="" /> Invite
          </button>
        </div>
      </div>

      <div className="col-span-3 flex items-center gap-[1rem] pl-[1rem]">
        <button className="p-[0.4rem] rounded-full border">
          <img src={File} alt="" />
        </button>
        <button className="p-[0.4rem] rounded-full border">
          <img src={bell} alt="" />
        </button>

        <Link
          className="text-black no-underline right-0"
          to={
            user?.account_type === 2
              ? "/income-tax-business-profile"
              : "/income-tax-profile"
          }
        >
          <div className="flex items-center gap-1">
            {showPP === false && (
              <div className=" w-[2.5rem] h-[2.5rem] rounded-full overflow-hidden bg-purple-dark-shade flex items-center">
                {" "}
                <p className="m-0 w-full text-center text-white font-[500] text-[1rem] leading-[1.3rem]">
                  {finalLetter}
                </p>
              </div>
            )}
            {showPP === true && (
              <div className=" w-[2.5rem] h-[2.5rem] rounded-full overflow-hidden">
                <img
                  className="w-full h-full object-cover "
                  src={profilePic}
                  alt="profile"
                />
              </div>
            )}
            {businessProfile ? (
              <p className="text-darkGray m-0 font-[500] text-[0.9rem] whitespace-nowrap">
                {user?.business_name}
              </p>
            ) : (
              <p className="text-darkGray m-0 font-[500] text-[0.9rem] whitespace-nowrap">
                {userName}
              </p>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HeaderDashboardProfile;
