import React from "react";
import SummaryMainPage from "./SummaryMainPage";

function IncomeTaxSummary() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 md:col-span-12 ">
        <SummaryMainPage />
      </div>
    </div>
  );
}

export default IncomeTaxSummary;
