import React from "react";
import ProfileAndCertificate from "./ProfileAndCertificate";
import "containers/privateRoutes/Dashboard/Order.css";
import DashboardContentNew from "./DashboardNew";
import HeaderDashboardProfile from "../Profile/HeaderDashboardProfile";

function Dashboard() {
  return (
    <div className="border-l">
      <HeaderDashboardProfile />
      <div className="grid grid-cols-12 bg-gray-100 px-[1rem] pb-[4rem] pt-[1rem]">
        <div className="col-span-12 md:col-span-9 ">
          {/* <DashboardContent /> */}
          <DashboardContentNew />
        </div>
        <div className="col-span-3 hidden md:block pl-[1rem]">
          <ProfileAndCertificate />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
