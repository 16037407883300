import React from "react";

function WhiteButton({ name, onClick, px, py, hidden, disabled = false }) {
  return (
    <div>
      <button
        className={`flex w-[100%] items-center whitespace-nowrap bg-white rounded border ${px} ${py} ${hidden} ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        <p className="text-center w-[100%] text-lightGray text-[0.9rem] font-[600] m-0">
          {name}
        </p>
      </button>
    </div>
  );
}

export default WhiteButton;
