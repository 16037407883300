import useAPICall from "hooks/useAPICall";
import { GET_BLOGS_LIST_URL } from "constants/apis";

const useGetBlogsList = () => {
  const [
    BlogsData,
    BlogsError,
    BlogsIsLoading,
    callGetBlog,
    setSuccessBlogsData,
    setBlogsError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setBlogsError(en.something_went_wrong);
    setSuccessBlogsData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const BlogsData = res;
        if (BlogsData && typeof BlogsData === "object") {
          setSuccessBlogsData(BlogsData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getBlogsList = (body) => {
    const url = `${GET_BLOGS_LIST_URL}?page_size=${body.page_size}&page=${body.page}`;
    const config = {};

    callGetBlog({
      url,
      method: "GET",
      statusObj,
      config,
      defaultFallback,
    });
  };
  return [
    BlogsData,
    BlogsError,
    BlogsIsLoading,
    getBlogsList,
    setSuccessBlogsData,
    setBlogsError,
  ];
};

export default useGetBlogsList;
