import useAPICall from "hooks/useAPICall";
// import {GET_BLOG_API_URL} from "constants/apis"
import { GET_SERVICE_API_URL } from "constants/apis";

const useGetService = () => {
  const [data, error, isLoading, callGetService, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getService = (servicesSlug) => {
    const url = GET_SERVICE_API_URL.replace(":slug", servicesSlug); 
    const config = {}
    callGetService({
      url,
      method: "GET",
      statusObj,
      config,
      defaultFallback,
    });
  };
  return [data, error, isLoading, getService, setSuccessData, setError];
};

export default useGetService;
