import React, { useContext, useEffect, useState } from "react";
import { orderPlacedSuccessModalContext } from "App";
import successLogo from "assets/images/Serviceimages/Success Icon.svg";
import { myContext } from "App";
import useGetUserBasic from "containers/privateRoutes/Dashboard/data/getUserbasicDetails";
import { Link } from "react-router-dom";

function OrderPlacedModal() {
  const { orderPlacedSuccessModal, setOrderPlacedSuccessModal } = useContext(
    orderPlacedSuccessModalContext
  );
  const { isLoggedIn, setIsloggedIn } = useContext(myContext);
  const [name, setName] = useState("");
  const [UserBasicData, userBasicError, userBasicIsLoading, getUserBasic] =
    useGetUserBasic();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     getUserBasic();
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    if (UserBasicData) {
      setName(UserBasicData.data.full_name.split(" ")[0]);
    }
  }, [UserBasicData]);

  return (
    <>
      {orderPlacedSuccessModal && UserBasicData && (
        <div className=" z-20 flex justify-center items-center  fixed inset-0 bg-slate-500 bg-opacity-40 backdrop-blur-sm">
          <div className="w-[90%] bg-white-body p-4 rounded">
            <img
              className="col-span-full w-20 mx-auto"
              src={successLogo}
              alt="successLogo"
            />

            <p className="text-center text-[1.4rem] font-[500] leading-[1.5rem] my-2">
              {name}, Your order has been placed for
              <br />{" "}
            </p>
            <p className=" text-gray-500 text-center text-[0.9rem font-[400] leading-[1.4rem] ">
              Our representative will give you a call back soon
            </p>

            <Link to={"/dashboard"} className=" no-underline">
              {" "}
              <p
                onClick={() => {
                  //   setLogin(true);
                  //   setRegistrationSuccess(false);
                  //   setRegistrationSuccessModal(false)
                  setOrderPlacedSuccessModal(false);
                  // console.log('clicked')
                }}
                className=" py-2 rounded-md my-2 text-center text-[1rem] font-[500] leading-[1.5rem] hover:cursor-pointer bg-purple-light-shade text-[#9065B4] "
              >
                Check your order
              </p>
            </Link>
            <p
              onClick={() => {
                //   setLogin(true);
                //   setRegistrationSuccess(false);
                //   setRegistrationSuccessModal(false)
                setOrderPlacedSuccessModal(false);
                // console.log('clicked')
              }}
              className=" py-2 rounded-md my-2 text-center text-[1rem] font-[500] leading-[1.5rem] hover:cursor-pointer  bg-purple-dark-shade text-white "
            >
              Continue
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderPlacedModal;
