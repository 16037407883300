import useAPICall from "hooks/useAPICall";
import {POST_GOOGLE_TOKEN_URL} from "constants/apis";

const usePostGoogleLogin = () => {
  const [googleLoginData, googleLoginError, googleLoginIsLoading, callPostGoogleLoginLogin, setSuccessGoogleLoginData, setGoogleLoginError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setGoogleLoginError(en.something_went_wrong);
    setSuccessGoogleLoginData(undefined);
  };

  const statusObj = [ 
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const googleLoginData = res;
        if (googleLoginData && typeof googleLoginData === "object") {
          setSuccessGoogleLoginData(googleLoginData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server googleLoginError",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postGoogleLogin = (body) => {
    const url = `${POST_GOOGLE_TOKEN_URL}${body.token}`;
    console.log(body)
    callPostGoogleLoginLogin({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      body
    });
  };
  return [googleLoginData, googleLoginError, googleLoginIsLoading, postGoogleLogin, setSuccessGoogleLoginData, setGoogleLoginError];
};

export default usePostGoogleLogin;
