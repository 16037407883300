import useAPICall from "hooks/useAPICall";
import { GET_UPCOMING_DUE_DATES_URL } from "constants/apis";

const useGetDueDate = () => {
  const [
    dueDateData,
    dueDateError,
    dueDateIsLoading,
    callGetDueDate,
    setSuccessDueDateData,
    setDueDateError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setDueDateError(en.something_went_wrong);
    setSuccessDueDateData(undefined);
  };
 
  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const dueDateData = res;
        if (dueDateData && typeof dueDateData === "object") {
          setSuccessDueDateData(dueDateData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server dueDateError",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getDueDate = () => {
    const url = GET_UPCOMING_DUE_DATES_URL;
    const config={};
    callGetDueDate({
      url,
      method: "GET",
      statusObj,
      config,
      defaultFallback,
    });
  };
  return [
    dueDateData,
    dueDateError,
    dueDateIsLoading,
    getDueDate,
    setSuccessDueDateData,
    setDueDateError,
  ];
};

export default useGetDueDate;
