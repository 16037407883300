import React, { useState } from "react";
import Input from "components/input/Input";
import Button from "components/Button/BtnOrange";

function RegistrationDetails() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phnNo, setPhnNo] = useState('');
  return (
    <>
      <div id="registerPart" className="p-[6%] md:grid hidden lg:grid grid-cols-12">
        <div className=" col-span-6 justify-center">
          <h2>Lets start a journey together!</h2>
          <p>
            Skip the hassle! BeyondTax takes care of registration, so you can
            focus on your business.
          </p>
        </div>
        <div className=" col-span-6 rounded-xl bg-gray-100 p-[5%] gap-4 grid grid-cols-12">
          <div className=" col-span-6">
            <Input   value={firstName}
                // type={"email"}
                setValue={setFirstName} label={"First name"} placeholder={"First name"} />
          </div>
          <div className=" col-span-6">
            <Input   value={lastName}
                // type={"email"}
                setValue={setLastName} label={"Last name"} placeholder={"Last name"} />
          </div>
          <div className=" col-span-12">
            <Input   value={email}
                type={"email"}
                setValue={setEmail} label={"Email"} placeholder={"you@company.com"} />
          </div>
          <div className=" col-span-12">
            <Input   value={phnNo}
                // type={"email"}
                setValue={setPhnNo} label={"Mobile Number"} placeholder={"Mobile Number"} />
          </div>
          <div className="col-span-12 w-[100%]">
          <Button   px={"px-[2rem] w-[100%]"}
                py={"py-[0.5rem] "} name={'Submit'}/></div>
        </div>
      </div>
    </>
  );
}

export default RegistrationDetails;
