import useAPICall from "hooks/useAPICall";
import { GET_USER_BASIC_DETAILS_URL } from "constants/apis";

const useGetUserBasic = () => {
  const [UserBasicData, userBasicError, userBasicIsLoading, callGetUserBasic, setSuccessUserBasicData, setUserBasicError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setUserBasicError(en.something_went_wrong);
    setSuccessUserBasicData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const UserBasicData = res;
        if (UserBasicData && typeof UserBasicData === "object") {
          setSuccessUserBasicData(UserBasicData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
// console.log(localStorage.getItem('token'))
  const getUserBasic = (body) => {
    const url = GET_USER_BASIC_DETAILS_URL
  // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
  var config = {headers :{ Authorization : `token ${localStorage.getItem('token')}`}}
    // console.log(body)
    callGetUserBasic({
      url,
      method : "GET",
      statusObj,
      
      config,
      defaultFallback,
      body
    });
  };
  return [UserBasicData, userBasicError, userBasicIsLoading, getUserBasic, setSuccessUserBasicData, setUserBasicError];
};

export default useGetUserBasic;
