import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePostSendPanOTP from "./Data/usePostSendPanOTP";
import PanCardOtpDialog from "./PanCardOtpDialog";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const PanNumberDialog = (props) => {
  const [panNumber, setPanNumber] = useState("");
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, error, isLoading, sendPanOtp] = usePostSendPanOTP();

  useEffect(() => {
    if (data) {
      showSuccessToast("OTP sent successfully")
      setShowOtpDialog(true);
      // console.log("redirecting to otp dialog box:")
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      let desc=error.message || "Try again in sometime."
      showErrorToast("", desc)
    }
  }, [error]);

  // const handleLinkPan = () => {
  //   if (panNumber) {
  //     console.log("PAN Number:", panNumber);
  //     user.pan_no = panNumber;
  //     console.log("user pan no:", user.pan_no);

  //     localStorage.setItem("user", JSON.stringify(user));

  //     if (user && user.email) {
  //       sendPanOtp({ pan_number: user.pan_no });
  //     }
  //   } else {
  //     showErrorToast("", "Please enter a valid PAN number")
  //   }
  // };

  const handleLinkPan = () => {
    // Check if the PAN number is exactly 10 characters and follows the alphanumeric pattern
    const panPattern = /^[a-zA-Z0-9]{10}$/;
  
    if (panNumber && panPattern.test(panNumber)) {
      console.log("PAN Number:", panNumber);
      user.pan_no = panNumber;
      console.log("user pan no:", user.pan_no);
  
      localStorage.setItem("user", JSON.stringify(user));
  
      if (user && user.email) {
        sendPanOtp({ pan_number: user.pan_no });
      }
    } else {
      // Show error if the PAN number is invalid (less than 10 characters or contains invalid characters)
      showErrorToast("", "Please enter a valid 10-digit PAN number");
    }
  };
  

  const handlePanChange = (e) => {
    setPanNumber(e.target.value);
  };

  return (
    <Dialog
      open={props.open}
      // onClose={props.handleCloseDialog}
      PaperProps={{
        style: { width: "45%", height: "80%" },
      }}
    >
      <div className="px-28 py-24">
        {showOtpDialog ? (
          <PanCardOtpDialog
            open={showOtpDialog}
            otp_id={data.data.otp_id}
            pan_Number={panNumber}
          />
        ) : (
          <>
            <h2 className="font-[700] leading-[2.1rem] text-[1.4rem] flex">
              PAN Verification
            </h2>
            <p className="text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex">
              Connect your PAN to automatically import your tax data
            </p>
            <h6 className="text-[1rem] font-[500] mt-4">PAN Number</h6>
            <input
              type="text"
              maxLength="10"
              className="w-full h-12 text-center border rounded-md text-[1.1rem] font-medium text-[#9065B4] focus:outline-[#D6BBFB]"
              placeholder="Enter your PAN Number"
              value={panNumber}
              onChange={handlePanChange}
            />

            <button
              onClick={handleLinkPan}
              className="purpleBtn p-2 rounded w-[100%] text-white mt-4"
            >
              {isLoading ? "Sending OTP..." : "Verify"}
            </button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default PanNumberDialog;
