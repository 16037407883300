import useAPICall from "hooks/useAPICall";
import { GET_ORDER_UPLOADED_DOCUMENTS, POST_UPLOAD_DOCUMENTS_URL } from "constants/apis";

const useGetOrderUploadedDocuments = () => {
  const [
    docUploadData,
    docUploadErrors,
    docUploadIsLoading,
    callGetDocs,
    setSuccessdocUploadData,
    setdocUploadErrors,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setdocUploadErrors(en.something_went_wrong);
    setSuccessdocUploadData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const docUploadData = res;
        if (docUploadData && typeof docUploadData === "object") {
          setSuccessdocUploadData(docUploadData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];
  // console.log(localStorage.getItem('token'))
  const getDocs = (work_order_id, body  ) => {
    const url = GET_ORDER_UPLOADED_DOCUMENTS.replace(":work_order_id",work_order_id);
    // var  headers={ authentication : `token ${localStorage.getItem('token')}`}
    // console.log(body)
    console.log("postDocs running");
    callGetDocs({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      body,
    });
  };
  return [
    docUploadData,
    docUploadErrors,
    docUploadIsLoading,
    getDocs,
    setSuccessdocUploadData,
    setdocUploadErrors,
  ];
};

export default useGetOrderUploadedDocuments;
