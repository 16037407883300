import { POST_TAX_SUMMARY_AGRI_URL, POST_TAX_SUMMARY_BUSINESS_URL, POST_TAX_SUMMARY_RENTAL_URL } from "constants/apis";
import useAPICall from "hooks/useAPICall";

const usePostTaxSummaryAgri = () => {
  const [
    data,
    error,
    isLoading,
    callPostTaxSummaryAgri,
    setSuccessData,
    setError,
  ] = useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 201,
      status_text: "Created",
      callBack: (res) => {
        const data = res;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const PostTaxSummaryAgri = (income_tax_return_id,body) => {
    const url = POST_TAX_SUMMARY_AGRI_URL.replace(":income_tax_return_id",income_tax_return_id);
    var config = {
      headers: { Authorization: `token ${localStorage.getItem("token")}` },
    };
    console.log("create profile details API: ", data);
    console.log("body", body);
    callPostTaxSummaryAgri({
      url,
      method: "POST",
      statusObj,
      config,
      defaultFallback,
      body,
    });
  };

  return [
    data,
    error,
    isLoading,
    PostTaxSummaryAgri,
    setSuccessData,
    setError,
  ];
};

export default usePostTaxSummaryAgri;
