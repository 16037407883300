import React, { useEffect, useState } from "react";
import useGetDueDate from "containers/privateRoutes/Dashboard/data/useGetDueDate";
import Calendar from "react-calendar";

function EventDate() {
  const [filteredDates, setFilteredDates] = useState([]);
  const [activeStartDate, setActiveStartDate] = useState(new Date());

  const [dueDateData, dueDateError, dueDateIsLoading, getDueDate] =
    useGetDueDate();

  useEffect(() => {
    getDueDate();
  }, []);

  const [dueDateArr, setDueDateArr] = useState([]);

  useEffect(() => {
    if (dueDateData) {
      const flattenedData = [];
      Object.keys(dueDateData.data).forEach((month) => {
        dueDateData.data[month].forEach((item) => {
          flattenedData.push(item);
        });
      });
      setDueDateArr(flattenedData);
    }
  }, [dueDateData]);

  const markDates = ({ date, view }) => {
    if (view === "month") {
      const currentDate = new Date();
      const isCurrentDate =
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear();

      const markedDate = dueDateArr.find(
        (data) => new Date(data.date).toDateString() === date.toDateString()
      );

      if (isCurrentDate) {
        return "current-date";
      }

      if (markedDate) {
        return "marked-date";
      }
    }
    return null;
  };

  useEffect(() => {
    const currentMonth = activeStartDate.getMonth();
    const currentYear = activeStartDate.getFullYear();

    const filtered = dueDateArr.filter((data) => {
      const eventDate = new Date(data.date);
      return (
        eventDate.getMonth() === currentMonth &&
        eventDate.getFullYear() === currentYear
      );
    });
    setFilteredDates(filtered);
  }, [activeStartDate, dueDateArr]);

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  };

  return (
    <div className="datePicker p-[8%]">
      <h2 className=" font-[700] lg:text-[3rem]  md:text-[3rem] text-[2rem] leading-[4rem]">
        Upcoming details
      </h2>
      <div className="flex justify-center ">
        <div className="  lg:p-[5%] lg:flex md:flex lg:drop-shadow-md  md:p-[5%] md:drop-shadow-md  bg-white-body rounded-xl ">
          <Calendar
            onActiveStartDateChange={handleActiveStartDateChange} 
            value={activeStartDate}
            view="month"
            tileClassName={markDates}
            className="react-calendar"
          />
          <div className="impDates bg-gray-50 max-h-96 overflow-y-scroll w-80">
            <h4>Important dates</h4>

            {filteredDates.length > 0 ? (
              filteredDates.map((data, index) => (
                <div key={index}>
                  <span className="bg-[#D3C1E1] px-2 py-1 rounded-md text-[#344054] font-[500] text-[.8rem] leading-[.9rem]">
                    {data.date}
                  </span>
                  <ul>
                    <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                      {data.compliance_activity}
                    </li>
                    <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                      {data.penalty_fine_interest}
                    </li>
                  </ul>
                </div>
              ))
            ) : (
              <p>No important dates for this month.</p>
            )}
          </div>
        </div>
      </div>
      <div className="secondDivAni lg:flex md:flex hidden align-middle">
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Due date 24 jan2024
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
        <div className="bg-purple-light-shade rounded-3xl  py-[0.1rem] px-[1rem] my-4 mx-1">
          {" "}
          <p className="whitespace-nowrap m-0 text-[0.1rem] ">
            ITR • Late fee ₹5000
          </p>
        </div>
      </div>
    </div>
  );
}

export default EventDate;
