import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import usePostTaxSummaryRental from "../Data/usePostTaxSummaryRental";
import { myContext } from "App";
import useGetTaxSummaryRental from "../Data/useGetTaxSummaryRental";
import usePatchTaxSummaryRental from "../Data/usePatchTaxSummaryRental";
import { useNavigate, useParams } from "react-router-dom";
import { INCOME_TAX_SUMMARY_USER_DETAILS } from "constants/routes";
import Loader from "components/Loader/Loader";
import ErrorToaster from "components/toaster/ErrorToaster";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

const TaxSummaryRental = forwardRef(({isDisabled}, ref) => {
  const { income_tax_return_id } = useParams();
  const navigate = useNavigate();
  const path = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
    ":detailType",
    "capital-gains"
  ).replace(":income_tax_return_id", income_tax_return_id);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [rentalData, rentalError, rentalIsLoading, postCreateRentalDetails] =
    usePostTaxSummaryRental();

  const [getData, getError, getIsLoading, getTaxSummaryRental] =
    useGetTaxSummaryRental();

  const [patchData, patchError, patchIisLoading, patchTaxSummaryRental] =
    usePatchTaxSummaryRental();

  useEffect(() => {
    getTaxSummaryRental(income_tax_return_id);
  }, []);

  useImperativeHandle(ref, () => ({
    rentalAddProperty() {
      append({ ...defaultValues.properties[0] });
    },
    handleSubmit: () => handleSubmit(onSubmit)(),
  }));

  const formattingSendData = (data, flag) => {
    console.log("informating" , data)
    let modifiedData = data.map((item) => {
      let {
        id,
        let_property,
        occupancy_status,
        ownership_percent,
        self_property,
      } = item;
      if (id) {
        flag = true;
      }
      if (occupancy_status == 1) {
        let {
          tenant_name,
          tenant_aadhar,
          tenant_pan,
          let_property_door_no,
          let_property_area,
          let_property_city,
          let_property_pincode,
          let_property_state,
          let_property_country,
          let_annual_rent,
          let_property_tax_paid,
          let_standard_deduction,
          let_interest_on_home_loan_dcp,
          let_interest_on_home_loan_pc,
          let_net_rental_income,
        } = let_property;

        return {
          ...(id !== undefined && { id }),
          occupancy_status: occupancy_status,
          tenant_name: tenant_name,
          tenant_aadhar: tenant_aadhar,
          tenant_pan: tenant_pan,
          property_door_no: let_property_door_no,
          property_area: let_property_area,
          property_city: let_property_city,
          property_pincode: let_property_pincode,
          property_state: let_property_state,
          property_country: let_property_country,
          annual_rent: Number(let_annual_rent),
          property_tax_paid: let_property_tax_paid,
          standard_deduction: let_standard_deduction,
          interest_on_home_loan_dcp: let_interest_on_home_loan_dcp,
          interest_on_home_loan_pc: let_interest_on_home_loan_pc,
          net_rental_income: let_net_rental_income,
          ownership_percent: ownership_percent,
        };
      }
      if (occupancy_status == 2) {
        let {
          self_property_door_no,
          self_property_area,
          self_property_city,
          self_property_pincode,
          self_property_state,
          self_property_country,
          self_property_tax_paid,
          self_standard_deduction,
          self_interest_on_home_loan_dcp,
          self_interest_on_home_loan_pc,
          self_net_rental_income,
        } = self_property;

        return {
          ...(id !== undefined && { id }),
          occupancy_status: occupancy_status,
          ownership_percent: ownership_percent,
          tenant_name: "",
          tenant_aadhar: "",
          tenant_pan: "",
          property_door_no: self_property_door_no,
          property_area: self_property_area,
          property_city: self_property_city,
          property_pincode: self_property_pincode,
          property_state: self_property_state,
          property_country: self_property_country,
          annual_rent: 0,
          property_tax_paid: self_property_tax_paid,
          standard_deduction: self_standard_deduction,
          interest_on_home_loan_dcp: self_interest_on_home_loan_dcp,
          interest_on_home_loan_pc: self_interest_on_home_loan_pc,
          net_rental_income: self_net_rental_income,
        };
      }
    });
    return { modifiedData, flag };
  };

  const onSubmit = (data) => {
    setIsFormSubmitted(true);
    let flagForUpdate = false;
    let { modifiedData, flag } = formattingSendData(
      data.properties,
      flagForUpdate
    );
    console.log("modifiedData", modifiedData)
    if (flag) {
      patchTaxSummaryRental(income_tax_return_id, modifiedData);
    } else {
      postCreateRentalDetails(income_tax_return_id, modifiedData);
    }
  };

  useEffect(() => {
    if (rentalData) {
      showSuccessToast("Rental Details posted")
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [rentalData]);

  useEffect(() => {
    if (rentalError) {
      console.log("rental error: ", rentalError);
      showErrorToast("","Error in posting data")
    }
  }, [rentalError]);

  useEffect(() => {
    if (getError) {
      console.log("get rental error: ", getError);
      showErrorToast("","Error in getting data")
    }
  }, [getError]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Details updated")
      if (isFormSubmitted) {
        navigate(path);
      }
    }
  }, [patchData]);

  useEffect(() => {
    if (patchError) {
      showErrorToast("","Patch Error")
    }
  }, [patchError]);

  const defaultValues = {
    properties: [
      {
        occupancy_status: 1,
        ownership_percent: 100,
        let_property: {
          tenant_name: "",
          tenant_aadhar: "",
          tenant_pan: "",
          let_property_door_no: "",
          let_property_area: "",
          let_property_city: "",
          let_property_pincode: "",
          let_property_state: "",
          let_property_country: "",
          let_annual_rent: "",
          let_property_tax_paid: "",
          let_standard_deduction: "",
          let_interest_on_home_loan_dcp: "",
          let_interest_on_home_loan_pc: "",
          let_net_rental_income: "",
        },
        self_property: {
          self_property_door_no: "",
          self_property_area: "",
          self_property_city: "",
          self_property_pincode: "",
          self_property_state: "",
          self_property_country: "",
          self_property_tax_paid: "",
          self_standard_deduction: "",
          self_interest_on_home_loan_dcp: "",
          self_interest_on_home_loan_pc: "",
          self_net_rental_income: "",
        },
      },
    ],
  };

  const methods = useForm({
    defaultValues,
  });

  const { control, handleSubmit, reset, formState, setValue } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  });

  const watchFieldArray = useWatch({
    control,
    name: "properties",
  });

  let controlledFields = fields.map((field, index) => {
    return {
      ...field,
      occupancy_status: Number(watchFieldArray[index]?.occupancy_status),
    };
  });

  useEffect(() => {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        occupancy_status: Number(watchFieldArray[index]?.occupancy_status),
      };
    });
  }, [watchFieldArray]);

  const removeProperty = (index) => {
    const updatedFields = [...watchFieldArray];
    updatedFields.splice(index, 1);
    reset({ properties: updatedFields });
    // setTimeout(() => {
    //   handleSubmit(onSubmit)();
    // }, 0);
  };

  useEffect(() => {
    if (getData?.data?.results.length > 0) {
      let alteredData = [];
      let apiData = getData?.data?.results;
      apiData.map((item, i) => {
        let {
          id,
          occupancy_status,
          tenant_name,
          tenant_aadhar,
          tenant_pan,
          property_door_no,
          property_area,
          property_city,
          property_pincode,
          property_state,
          property_country,
          annual_rent,
          property_tax_paid,
          standard_deduction,
          interest_on_home_loan_dcp,
          interest_on_home_loan_pc,
          net_rental_income,
          ownership_percent,
        } = item;
        let addData = {
          id: id,
          occupancy_status: occupancy_status,
          ownership_percent: ownership_percent,
          let_property: {
            tenant_name: tenant_name,
            tenant_aadhar: tenant_aadhar,
            tenant_pan: tenant_pan,
            let_property_door_no: property_door_no,
            let_property_area: property_area,
            let_property_city: property_city,
            let_property_pincode: property_pincode,
            let_property_state: property_state,
            let_property_country: property_country,
            let_annual_rent: annual_rent,
            let_property_tax_paid: property_tax_paid,
            let_standard_deduction: standard_deduction,
            let_interest_on_home_loan_dcp: interest_on_home_loan_dcp,
            let_interest_on_home_loan_pc: interest_on_home_loan_pc,
            let_net_rental_income: net_rental_income,
          },
          self_property: {
            self_property_door_no: property_door_no,
            self_property_area: property_area,
            self_property_city: property_city,
            self_property_pincode: property_pincode,
            self_property_state: property_state,
            self_property_country: property_country,
            self_property_tax_paid: property_tax_paid,
            self_standard_deduction: standard_deduction,
            self_interest_on_home_loan_dcp: interest_on_home_loan_dcp,
            self_interest_on_home_loan_pc: interest_on_home_loan_pc,
            self_net_rental_income: net_rental_income,
          },
        };
        alteredData.push(addData);
      });
      reset({
        properties: alteredData,
      });
    } else {
      reset(defaultValues);
    }
  }, [getData]);

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {controlledFields.map((field, index) => {
            return (
              <PropertyForm
                index={index}
                field={field}
                removeProperty={removeProperty}
                key={field.id}
                errors={errors}
                setValue={setValue}
                isDisabled={isDisabled}
              />
            );
          })}
        </form>
      </FormProvider>
    </div>
  );
});

const PropertyForm = ({ index, field, removeProperty, errors, setValue, isDisabled}) => {
  const { register, watch } = useFormContext();
  const handleInput=(e)=>{
    console.log("evalue", e.target.value, e.target.name)
    setValue(`${e.target.name}`, e.target.value)
  }
  // console.log("watchin", watch(`properties.${index}.ownership_percent`))
  return (
    <div className="border border-gray-200 rounded md:mt-4 md:p-[2%] px-[2%] mb-2 md:mb-0">
      <div className="hidden md:block">
        <div className="flex items-center justify-between h-[50px]">
          <div className="h-[100%]">
            <h4 className="mt-3 text-[#334155]">Property Details</h4>
          </div>
          <div
            className={`flex h-[100%] items-center justify-evenly ${
              index > 0 ? "w-[67%] gap-[10px]" : "w-[53%] gap-[20px]"
            }`}
          >
            <div className="h-[100%] flex items-center">
              <h6 className="text-[#64748B] ">Percentage of Ownership</h6>
            </div>
            <div className="text-[#64748B]  h-[100%] flex flex-col w-[11.5%]">
              <span className="bg-[#FAF8FC] h-[75%] flex items-center rounded px-1 text-[#64748B] text-[0.8rem] font-[400] w-[100%]">
                <InputField
                  type="number"
                  name={`properties.${index}.ownership_percent`}
                  register={register}
                  onChange={handleInput}
                  validations={
                    {
                      required: "Required*",
                      min: {
                        value: 0,
                        message: "0%-100%*",
                      },
                      max: {
                        value: 100,
                        message: "0%-100%",
                      },
                    }
                  }
                  className="w-[96%] focus:outline-none"
                />
                %
              </span>
              <p className="text-red-600">
                {errors.properties?.[index]?.ownership_percent?.message}
              </p>
            </div>
            <div className="bg-[#F1F5F9] h-[87%] flex items-center w-[185px] justify-evenly rounded px-1">
              <label
                onClick={()=>setValue(`properties.${index}.occupancy_status`,1)}
                className={`font-[500] text-[0.8rem] px-2.5 py-1.5 rounded leading-[1rem] cursor-pointer ${
                  field.occupancy_status == 1
                    ? "text-[#FFFFFF] bg-[#334155]"
                    : "text-[#64748B]"
                }`}
              >
                {/* <input
                  type="radio"
                  {...register(`properties.${index}.occupancy_status`, {
                    valueAsNumber: true,
                  })}
                  value={1}
                  checked={field.occupancy_status == 1}
                  className="hidden"
                /> */}
                <InputField
                  type="radio"
                  name={`properties.${index}.occupancy_status`} // Use the same name for the group of radio buttons
                  value={1} // Set the value for this radio button
                  register={register} // Registering with React Hook Form
                  validation={{
                    valueAsNumber: true,
                  }}
                  checked={field.occupancy_status == 1}
                  className="hidden"
                />
                Let-Out
              </label>
              <label
                onClick={()=>setValue(`properties.${index}.occupancy_status`,2)}
                className={`font-[500] text-[0.8rem] px-2.5 py-1.5 rounded leading-[1rem] cursor-pointer ${
                  field.occupancy_status == 2
                    ? "text-[#FFFFFF] bg-[#334155]"
                    : "text-[#64748B]"
                }`}
              >
                {/* <input
                  type="radio"
                  {...register(`properties.${index}.occupancy_status`, {
                    valueAsNumber: true,
                  })}
                  value={2}
                  checked={field.occupancy_status == 2}
                  className="hidden"
                /> */}
                <InputField
                  type="radio"
                  name={`properties.${index}.occupancy_status`} // Use the same name for the group of radio buttons
                  value={2} // Set the value for this radio button
                  register={register} // Registering with React Hook Form
                  checked={field.occupancy_status == 2}
                  validation={{
                    valueAsNumber: true,
                  }}
                  className="hidden"
                />
                Self-Occupied
              </label>
            </div>
            {index > 0 ? (
              <button
                type="button"
                disabled={isDisabled || false}
                onClick={() => removeProperty(index)}
                className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
              >
                <span className="mr-1.5">X</span>Remove Property
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <div>
          <div className="flex justify-between items-center w-[100%]">
            <h4 className="mt-3 text-[#334155] w-[56%] text-[1.3rem]">Property Details</h4>
            <div className="w-[49%] flex justify-end">
              {index > 0 ? (
                <button
                  type="button"
                  onClick={() => removeProperty(index)}
                  className="px-2.5 py-1.5 rounded-md bg-[#FEF2F2] text-[#DC2626] text-[0.8rem] font-[500] flex items-center"
                >
                  <span className="mr-1.5">X</span>Remove Property
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex gap-[12px] h-[40px] items-center">
            <div className="">
              <h6 className="text-[#64748B] ">Percentage of Ownership</h6>
            </div>
            <div className="text-[#64748B]  flex flex-col w-[16%] h-[100%]">
              <span className="bg-[#FAF8FC] h-[100%] flex items-center rounded px-1 text-[#64748B] text-[0.8rem] font-[400] w-[100%]">
                {/* <input
                  type="number"
                  {...register(`properties.${index}.ownership_percent`)}
                  className="w-[96%] focus:outline-none"
                /> */}
                <InputField 
                 type='number'
                 name={`properties.${index}.ownership_percent`}
                 register={register}
                 onChange={handleInput}
                 validation={
                  {
                    required: "Required*",
                    min: {
                      value: 0,
                      message: "0%-100%*",
                    },
                    max: {
                      value: 100,
                      message: "0%-100%*",
                    },
                  }
                 }
                 className="w-[96%] focus:outline-none"
                />
                %
              </span>
              <p className="text-red-600">
                {errors.properties?.[index]?.ownership_percent?.message}
              </p>
            </div>
          </div>
          <div className="h-[40px]">
            <div className="bg-[#F1F5F9] h-[100%] flex items-center w-[185px] justify-evenly rounded px-1">
              <label
               onClick={()=>setValue(`properties.${index}.occupancy_status`,1)}
                className={`font-[500] text-[0.8rem] px-2.5 py-1.5 rounded leading-[1rem] cursor-pointer ${
                  field.occupancy_status == 1
                    ? "text-[#FFFFFF] bg-[#334155]"
                    : "text-[#64748B]"
                }`}
              >
                {/* <input
                  type="radio"
                  {...register(`properties.${index}.occupancy_status`, {
                    valueAsNumber: true,
                  })}
                  value={1}
                  checked={field.occupancy_status == 1}
                  className="hidden"
                /> */}
                <InputField
                  type="radio"
                  name={`properties.${index}.occupancy_status`} // Use the same name for the group of radio buttons
                  value={1} // Set the value for this radio button
                  register={register} // Registering with React Hook Form
                  validation={{
                    valueAsNumber: true,
                  }}
                  checked={field.occupancy_status == 1}
                  className="hidden"
                />
                Let-Out
              </label>
              <label
               onClick={()=>setValue(`properties.${index}.occupancy_status`,2)}
                className={`font-[500] text-[0.8rem] px-2.5 py-1.5 rounded leading-[1rem] cursor-pointer ${
                  field.occupancy_status == 2
                    ? "text-[#FFFFFF] bg-[#334155]"
                    : "text-[#64748B]"
                }`}
              >
                {/* <input
                  type="radio"
                  {...register(`properties.${index}.occupancy_status`, {
                    valueAsNumber: true,
                  })}
                  value={2}
                  checked={field.occupancy_status == 2}
                  className="hidden"
                /> */}
                <InputField
                  type="radio"
                  name={`properties.${index}.occupancy_status`} // Use the same name for the group of radio buttons
                  value={2} // Set the value for this radio button
                  register={register} // Registering with React Hook Form
                  checked={field.occupancy_status == 2}
                  validation={{
                    valueAsNumber: true,
                  }}
                  className="hidden"
                />
                Self-Occupied
              </label>
            </div>
          </div>
        </div>
      </div>

      {field.occupancy_status == "1" && (
        <LetOutForm index={index} errors={errors} />
      )}
      {field.occupancy_status == "2" && (
        <SelfOccupiedForm index={index} errors={errors} />
      )}
    </div>
  );
};

const LetOutForm = ({ index, errors }) => {
  const { register } = useFormContext();
  const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", 
    "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", 
    "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", 
    "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", 
    "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", 
    "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", 
    "Delhi", "Lakshadweep", "Puducherry"
  ];
  return (
    <>
      <h6 className="mt-[1.5rem] text-[#334155]">Tenant</h6>
      <div className="grid grid-cols-12 md:mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#667085]  group-focus-within:text-customPurple">
            Tenant name
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.let_property.tenant_name`}
              register={register}
              validation={{
                required: "*Tenant name is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.properties?.[index]?.let_property?.tenant_name?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Tenant Aadhar
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
             <InputField
              type="text"
              name={`properties.${index}.let_property.tenant_aadhar`}
              register={register}
              validation={{
                required: "*Tenant Aadhar is required",
                pattern: {
                  value: /^\d{12}$/, // Ensures exactly 12 digits
                  message: "*Please enter a valid 12-digit Aadhaar number",
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.properties?.[index]?.let_property?.tenant_aadhar?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Tenant PAN
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <InputField
              type="text"
              name={`properties.${index}.let_property.tenant_pan`}
              register={register}
              validation={{
                required: "*Tenant PAN is required",
                pattern: {
                  value: /^[A-Za-z0-9]{10}$/, // Ensures exactly 10 alphanumeric characters
                  message: "*Please enter a valid 10-digit alphanumeric PAN",
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.properties?.[index]?.let_property?.tenant_pan?.message}
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-2.5" />
      <h6 className="mt-3 text-[#334155]">Address</h6>
      <div className="grid grid-cols-12 mt-[-15px] md:ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Flat/Door/Block No.
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text" 
              name={`properties.${index}.let_property.let_property_door_no`}
              register={register}
              validation={{
                required: "*Flat No is required",
                pattern: {
                  value: /^[a-zA-Z0-9-\/]+$/, // Allows letters, numbers, dashes, and slashes
                  message: "Please enter a valid flat number",
                },
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_door_no
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1 ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Area
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.let_property.let_property_area`}
              register={register}
              validation={{
                required: "*Area is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_area
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1 ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            City
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.let_property.let_property_city`}
              register={register}
              validation={{
                required: "*City is required",
                pattern: {
                  value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                  message: "*Only letters and spaces are allowed", // Updated error message
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_city
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            PIN Code.
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.let_property.let_property_pincode`}
              register={register}
              validation={{
                required: "*PIN is required",
                pattern: {
                  value: /^[1-9][0-9]{5}$/, // Ensures no leading 0 and exactly 6 digits
                  message: "Please enter a valid 6-digit PIN code starting with a non-zero digit",
                },
              }}
              maxLength={6}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_pincode
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1 ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            State
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <InputField
                type="select"
                name={`properties.${index}.let_property.let_property_state`}
                register={register}
                validation={{
                  required: "*State is required",
                  validate: (value) => value !== "Select an option" || "*State is required",
                }}
                options={[
                  { label: "Select an option" },
                  ...indianStates.map((state) => ({ label: state }))
                ]}
                className="text-[#667085] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
              />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_state
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1 ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Country
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
              <InputField
                type="select"
                name={`properties.${index}.let_property.let_property_country`}
                register={register}
                validation={{
                  required: "*Country is required",
                  validate: (value) =>
                    value !== "Select an option" || "*Country is required",
                  
                }}
                options={[
                  { value: "", label: "Select an option" },
                  {label: "India" }
                ]}
                className="text-[#667085] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded  focus:outline-none"
              />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_country
                ?.message
            }
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4" />
      <h6 className="mt-4 text-[#334155]">Rent Details</h6>
      <div className="grid grid-cols-12 gap-2 mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Annual rent received
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9]  h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.let_property.let_annual_rent`}
              register={register}
              validation={{
                required: "*Annual rent is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {errors.properties?.[index]?.let_property?.let_annual_rent?.message}
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Property Taxes Paid
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.let_property.let_property_tax_paid`}
              register={register}
              validation={{
                required: "*Property tax is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_property_tax_paid
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Standard Deduction @30%
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8]  rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.let_property.let_standard_deduction`}
              register={register}
              readOnly={true}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_standard_deduction
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 mb-2 gap-2 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Interest on Home Loan
            <br />
            (During Construction Period)
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.let_property.let_interest_on_home_loan_dcp`}
              register={register}
              validation={{
                required: "*Interest on Home Loan is required (DC)",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property
                ?.let_interest_on_home_loan_dcp?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Interest on Home Loan
            <br />
            (Post Construction)
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.let_property.let_interest_on_home_loan_pc`}
              register={register}
              validation={{
                required: "*Interest on Home Loan is required (PC)",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property
                ?.let_interest_on_home_loan_pc?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pt-0.5 pl-1">
          <h6 className="mb-[19.2px] font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Net Rental Income
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              readOnly={true}
              name={`properties.${index}.let_property.let_net_rental_income`}
              register={register}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.let_property?.let_net_rental_income
                ?.message
            }
          </p>
        </div>
      </div>
    </>
  );
};

const SelfOccupiedForm = ({ index, errors }) => {
  const { register } = useFormContext();
  const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", 
    "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", 
    "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", 
    "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", 
    "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", 
    "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", 
    "Delhi", "Lakshadweep", "Puducherry"
  ];
  return (
    <>
      <h6 className="mt-3 text-[#334155]">Address</h6>
      <div className="grid grid-cols-12 md:mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Flat/Door/Block No.
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.self_property.self_property_door_no`}
              register={register}
              validation={{
                required: "*Flat No is required",
                pattern: {
                  value: /^[a-zA-Z0-9-\/]+$/, // Allows letters, numbers, dashes, and slashes
                  message: "Please enter a valid flat number",
                },
              }}
              className="text-[#667085] w-[100%]   px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_door_no
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1 md:ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Area
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.self_property.self_property_area`}
              register={register}
              validation={{
                required: "*Area is required",
                pattern: {
                  value: /^[a-zA-Z0-9\s]*$/, // Allows letters, numbers, and spaces
                  message: "*Special characters are not allowed", // Error message if pattern is violated
                }
              }}
              className="text-[#667085] w-[100%]   px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_area
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1 md:ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            City
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.self_property.self_property_city`}
              register={register}
              validation={{
                required: "*City is required",
                pattern: {
                  value: /^[a-zA-Z\s]*$/, // Allows only letters and spaces
                  message: "*Only letters and spaces are allowed", // Updated error message
                }
              }}
              className="text-[#667085] w-[100%] md:w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_city
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#667085] group-focus-within:text-customPurple">
            PIN Code.
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
              type="text"
              name={`properties.${index}.self_property.self_property_pincode`}
              register={register}
              validation={{
                required: "*PIN is required",
                pattern: {
                  value: /^[1-9][0-9]{5}$/, // Ensures no leading 0 and exactly 6 digits
                  message: "Please enter a valid 6-digit PIN code starting with a non-zero digit",
                },
              }}
              maxLength={6} 
              className="text-[#667085] w-[100%]  px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_pincode
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1 md:ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] text-[#667085] group-focus-within:text-customPurple">
            State
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <InputField
                type="select"
                name={`properties.${index}.self_property.self_property_state`}
                register={register}
                validation={{
                  required: "*State is required",
                  validate: (value) => value !== "Select an option" || "*State is required",
                }}
                options={[
                  { label: "Select an option" },
                  ...indianStates.map((state) => ({ label: state }))
                ]}
                className="text-[#667085]  w-[100%] md:px-[.8rem] md:py-[.4rem] rounded  focus:outline-none"
              />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_state
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1 pt-0.5  md:ml-[-5px]">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Country
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
             <InputField
                type="select"
                name={`properties.${index}.self_property.self_property_country`}
                register={register}
                validation={ {
                  required: "*Country is required",
                  validate: (value) =>
                    value !== "Select an option" || "*Country is required",
                }}
                options={[
                  { value: "", label: "Select an option" },
                  {label: "India" }
                ]}
                className="text-[#667085] md:w-[100%] w-[100%] px-[.8rem] py-[.4rem] rounded focus:outline-none"
              />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_country
                ?.message
            }
          </p>
        </div>
      </div>
      <hr className="text-[#E2E8F0] border mt-4 mb-4" />
      <h6 className=" text-[#334155]">Interest Details</h6>
      <div className="grid grid-cols-12  md:mt-[-15px] ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem] md:text-[#667085] text-[#667085] group-focus-within:text-customPurple">
            Property Taxes Paid
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.self_property.self_property_tax_paid`}
              register={register}
              validation={{
                required: "*Property tax is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_property_tax_paid
                ?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Standard Deduction @30%
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              readOnly={true}
              name={`properties.${index}.self_property.self_standard_deduction`}
              register={register}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_standard_deduction
                ?.message
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 mb-2  ml-[-2px]">
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Interest on Home Loan
            <br />
            (During Construction Period)
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.self_property.self_interest_on_home_loan_dcp`}
              register={register}
              validation={{
                required: "*Interest on Home Loan is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property
                ?.self_interest_on_home_loan_dcp?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-1 font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Interest on Home Loan
            <br />
            (Post Construction)
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8] rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center group-focus-within:text-customPurple rounded-l-md">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.self_property.self_interest_on_home_loan_pc`}
              register={register}
              validation={{
                required: "*Interest on Home Loan is required",
              }}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property
                ?.self_interest_on_home_loan_pc?.message
            }
          </p>
        </div>
        <div className="group md:col-span-3 col-span-12 md:pt-3 pl-1">
          <h6 className="mb-[19.2px] font-[500] text-[0.8rem] leading-[1rem]  text-[#667085] group-focus-within:text-customPurple">
            Net Rental Income
          </h6>
          <div className="flex items-center border-1 border-[#94A3B8]  rounded w-[95%] focus-within:border-customPurple h-[44px] md:h-[37px]">
            <span className="px-2 text-[#64748B] bg-[#F1F5F9] h-full md:w-[15%] flex items-center justify-center rounded-l-md group-focus-within:text-customPurple">
              Rs.
            </span>
            <InputField
              type="number"
              name={`properties.${index}.self_property.self_net_rental_income`}
              register={register}
              readOnly={true}
              className="text-[#667085] w-[100%] md:w-[85%] px-[.7rem] py-[.3rem] rounded focus:outline-none"
            />
          </div>
          <p className="text-red-600">
            {
              errors.properties?.[index]?.self_property?.self_net_rental_income
                ?.message
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default TaxSummaryRental;
