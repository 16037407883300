import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, 
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import useGetReports from "./data/useGetReports";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = () => {
  const [getData, getError, getIsLoading, getReports] =
    useGetReports();

  useEffect(() => {
    getReports();
  }, [])
 
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  useEffect(() => {
    if (getData && getData.data && getData.data.graph_data) {
      const years = getData.data.graph_data.map(item => {
        const yearRange = item.income_tax_return_year_name;
        const yearParts = yearRange.split(' - ');
        const lastYear = yearParts[1].slice(-2); 
        return `20${lastYear}`; 
        
      });
       
      const incomeData = getData.data.graph_data.map(item => item.total_income_earned);
      const taxData = getData.data.graph_data.map(item => item.total_tax_paid);

      setChartData({
        labels: years,
        datasets: [
          {
            label: "Income",
            data: incomeData,
            backgroundColor: "#9065B4", 
            barThickness: 25,
            borderRadius: 6,
          },
          {
            label: "Tax",
            data: taxData,
            backgroundColor: "#C1A7D8", 
            barThickness: 25,
            borderRadius: 6,
          },
        ],
      });
    }
    
  }, [getData]);


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          color: "#475569", 
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}L`,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          color: "#475569", 
          callback: (value) => `${value}L`,
         
        },
        grid: {
          display: false, 
        },
       
      },
      x: {
        ticks: {
          color: "#475569", 
        },
        grid: {
          display: false, 
        },
      },
    },
  };


  return (
    <div className="h-60">
      <Bar data={chartData} options={options} />
    </div>
  );
}; 

export default BarChartComponent;
