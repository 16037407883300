import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import "../../../Liabilities/liabilities.css";
import upload from "assets/images/liabilities/upload.svg";
import Button from "components/Button/BtnOrange";

const EditAccountDetails = ({
  accHolderName,
  accNo,
  depositDate,
  depositAmt,
  balanceToDate,
  totalBalance,
  setAccHolderName,
  setaccNo,
  setdepositDate,
  setdepositAmt,
  setbalanceToDate,
  settotalBalance,
  update,
  uploadDepositProof,
  setuploadDepositProof,
  uploadProofBirth,
  setuploadProofBirth,
}) => {
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!accHolderName)
      newErrors.accHolderName = "Account Holder Name is required.";
    if (!accNo || isNaN(accNo))
      newErrors.accNo = "Account Number is required and should be numeric.";
    // if (!depositAmt || isNaN(depositAmt) || depositAmt <= 0)
    //   newErrors.depositAmt =
    //     "Deposit Amount must be a valid number greater than 0.";
    // if (!depositDate) newErrors.depositDate = "Deposit Date is required.";
    // if (!balanceToDate || isNaN(balanceToDate) || balanceToDate < 0)
    //   newErrors.balanceToDate =
    //     "Balance to Date must be a valid number and cannot be negative.";
    // if (!totalBalance || isNaN(totalBalance) || totalBalance < 0)
    //   newErrors.totalBalance =
    //     "Total Balance must be a valid number and cannot be negative.";
    // if (!uploadDepositProof) newErrors.uploadDepositProof = "Proof is required";
    // if (!uploadProofBirth) newErrors.uploadProofBirth = "Proof is required";

    setErrors(newErrors);
    return newErrors;
  };

  const handleSave = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      update();
    }
  };

  // Effect to dynamically set isFormValid whenever fields or errors change
  useEffect(() => {
    const hasErrors = Object.keys(validate()).length > 0;
    setIsFormValid(!hasErrors);
  }, [
    accHolderName,
    accNo,
    depositDate,
    depositAmt,
    balanceToDate,
    totalBalance,
    uploadDepositProof,
    uploadProofBirth,
  ]);
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" />
        }
        heading={"Edit Account Details"}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem]">
              <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">
                    Account Holder Name (Girl Child)
                  </label>
                  <InputField
                    value={accHolderName}
                    onChange={(e) => setAccHolderName(e.target.value)}
                    type="text"
                    placeholder="Enter Name"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.accHolderName && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.accHolderName}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Account Number</label>
                  <InputField
                    value={accNo}
                    onChange={(e) => setaccNo(e.target.value)}
                    placeholder="Enter Acc. No."
                    type="number"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.accNo && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.accNo}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem]">
              <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Deposit Amount</label>
                  <InputField
                    value={depositAmt}
                    onChange={(e) => setdepositAmt(e.target.value)}
                    type="number"
                    placeholder="Enter Deposit Amount"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.depositAmt && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.depositAmt}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Deposit Date</label>
                  <InputField
                    value={depositDate}
                    onChange={(e) => setdepositDate(e.target.value)}
                    type="date"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.depositDate && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.depositDate}
                    </span>
                  )}
                </div>

                <div className="w-[80%] mt-[0.7rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-deposit`}
                    className="hidden"
                    onChange={(e) => setuploadDepositProof(e.target.files[0])}
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input-deposit`).click()
                    }
                    className="text-darkGray bg-white font-[600] text-[0.9rem] border-1 border-lightGray py-[0.3rem] px-[0.8rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Proof
                  </button>
                  {errors.uploadDepositProof && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.uploadDepositProof}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem]">
              <h4 className="text-[#334155] font-[500] text-[0.9rem]">
                Current Amount
              </h4>
              <div className="grid grid-cols-2">
                <div className="input-container">
                  <label className="input-label">Balance to Date</label>
                  <InputField
                    value={balanceToDate}
                    onChange={(e) => setbalanceToDate(e.target.value)}
                    type="number"
                    placeholder="Enter Balance to Date"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.balanceToDate && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.balanceToDate}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Total Balance</label>
                  <InputField
                    value={totalBalance}
                    onChange={(e) => settotalBalance(e.target.value)}
                    type="number"
                    placeholder="Enter Total Balance"
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.totalBalance && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.totalBalance}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-[0.7rem]">
              <InputField
                type="file"
                accept=".pdf"
                id={`file-input-birth`}
                className="hidden"
                onChange={(e) => setuploadProofBirth(e.target.files[0])}
              />
              <button
                onClick={() =>
                  document.querySelector(`#file-input-birth`).click()
                }
                className="text-darkGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.3rem] px-[1.3rem] rounded-md flex gap-2 items-center"
              >
                <img src={upload} alt="upload" />
                Upload Birth Proof
              </button>
              {errors.uploadProofBirth && (
                <span className="text-red-500 text-[0.7rem]">
                  {errors.uploadProofBirth}
                </span>
              )}
            </div>

            <div className="flex justify-end gap-[1rem] mt-[1rem]">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={handleSave}
                disabled={!isFormValid}
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default EditAccountDetails;
