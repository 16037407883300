import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { myContext } from "App";
import { useState, useContext, useEffect } from "react";
import usePostLogout from "containers/privateRoutes/Orders/data/usePostLogout";

export default function LogoutDialog() {
  const [open, setOpen] = useState(false);
  const [logOutClicked, setLogOutClicked] = useState(false);

  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);

  const [
    logoutData,
    LogoutError,
    LogoutIsLoading,
    postLogout,
    setSuccessLogoutData,
    setLogoutError,
  ] = usePostLogout();

  useEffect(() => {
    if (logOutClicked) {
      postLogout();
      localStorage.clear();
      setIsLoggedIn(false);
    }
  }, [logOutClicked]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <button onClick={handleClickOpen} className=" border-1 px-[1rem] py-[0.5rem] border-purple-800 text-purple-800 rounded-lg">Logout</button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          // style: { width: "40%", height: "30%" },
          style: {width: "90%", maxWidth: "500px", height: "28%"},
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogContent >
          <b className="text-[#0F172A] font-[600] text-[1.2rem]">Log Out</b>
          <p className="text-[#64748B] font-[500] text-[1rem]">Are you sure you want to log out?</p>
        </DialogContent>
         <DialogActions style={{ paddingTop: 0, height: "29%", paddingBottom:"25px", paddingRight:"8px" }}>
          <button onClick={handleClose} className=" no-underline bg-purple-light-shade py-2 px-4 rounded-md">
            Cancel
          </button>
          <button
            onClick={() => {
              setLogOutClicked(true);
            }}
            className="mx-2 my-4 py-2 px-4 rounded-md bg-purple-dark-shade text-white"
          >
            Log Out
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}




