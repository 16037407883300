import React, { useState, useContext, useEffect } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import "App.css";
import Input from "components/input/Input";
import { myContext, orderPlacedSuccessModalContext } from "App";
import GoogleLoginex from "containers/LoginAndSignUp/GoogleLogin";
import { loaderContext } from "App";
import usePostLogin from "containers/services/Data/usePostLogin";
import { Link, useNavigate } from "react-router-dom";
import MobileSheetRegistration from "./MobileSheetRegistration";
import MobileDrawer from "./MobileDrawer";
import FormDialog from "containers/services/Dialog";
import usePostWorkOrder from "containers/privateRoutes/Orders/data/usePostWorkOrder";
import { showSuccessToast } from "components/toaster/toastHelper";

const NormalLoginSheet = (props) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const showDrawer = () => {
    setOpen(true);
    if(props.isDialogOpen === 'fpswd'){
      if(window.innerWidth < 768){
        setIsDialogOpen(false)
      }else{
        setOpen(false);
        setIsDialogOpen(true)
      }
    }
  };
  const onClose = () => { 
    setOpen(false);
  };

  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const { loading, setLoading } = useContext(loaderContext);
  const [data, error, isLoading, postLogin] = usePostLogin();
  
  const [submitClicked, setSubmitClicked] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState();
  const [borderColor, setBorderColor] = useState("");
  const navigate = useNavigate();

  const validateInputs = () => {
    setBorderColor(""); 
    setErr(""); 

    if (!mobileNumber) {
      setErr("Mobile Number/ Email is required.");
      setBorderColor("border-red-500");
      return false;
    }

    if (!password) {
      setErr("Password is required.");
      setBorderColor("border-red-500");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (submitClicked) {
      if (validateInputs()) {
        postLogin({ email_or_mobile: mobileNumber, password: password });
      }
      setSubmitClicked(false); 
    }
  }, [submitClicked]);

  useEffect(() => {
    if (error) {
      const errorMessage = error.err.response?.data?.error?.errors[0]?.detail || "An error occurred";
      setErr(errorMessage);
      console.log(errorMessage);
      setBorderColor("border-red-500");
      // toast.error(errorMessage);
    }
  }, [error]);



  var token;
  var expiryDate;
  var user;

  const [workOrderData, workOrderError, workOrderIsLoading, postWorkOrder] =
    usePostWorkOrder();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
//  useEffect(()=>{
//   if(props?.isDialogOpen === 'fpswd'){
//     setIsDialogOpen(true);
//     setOpen(false)
//   }
//  },[props.isDialogOpen])

  useEffect(() => {
    if(workOrderData){
      console.log("order posted");
      showSuccessToast("order posted")
    }
  }, [workOrderData]);

  useEffect(() => {
    if (data) {
      if (data.status_code === 200) {
        token = data.data.token;
        expiryDate = data.data.expiry;
        user = data.data.user;
        localStorage.setItem("token", token);
        localStorage.setItem("expiry", expiryDate);
        localStorage.setItem("user", JSON.stringify(user));
        setIsLoggedIn(true);
        setLoading(false);
        setIsDialogOpen(true);
        console.log("DialogOpen value:", isDialogOpen);
        if (props.fileNowPathMobile) {
          navigate("/income-tax-profile");
        } else {
          if (props.serviceData && props.serviceData.sendData) {
            postWorkOrder({
              service_id: props.serviceData.sendData.id,
              service_name: props.serviceData.sendData.service_title,
            });
            showSuccessToast("Order Done")
          }
        }
      }
    }
  }, [data]);

  // useEffect(() => {
  //   setInterval(() => {
  //     setIsDialogOpen(true);
  //   }, 5000)
  // }, [])
  

  if (isDialogOpen) {
    console.log("isdialogOpen:", isDialogOpen);
    return <FormDialog state={{ setOpen: true }} />
  }

  return (
    <>
      <Space> 
        <div
          onClick={() => {
            showDrawer();
          }}
        >
          {props.element}
        </div>
      </Space>
      <Drawer
        className="test"
        height={550}
        style={{ borderRadius: "20px 20px 0px 0px" }}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <div>
          <h2 className=" font-[700] leading-[2.1rem] text-[1.4rem]">
            Welcome back
          </h2>
          <p className=" text-gray-500  mb-4 font-[400] text-[1rem] leading-[1.5rem]">
            Welcome back! Please enter your details.
          </p>
          <Input
            border={borderColor}
            label={"Mobile Number"}
            placeholder={"Mobile Number"}
            value={mobileNumber}
            setValue={setMobileNumber}
          />
          <Input
            border={borderColor}
            label={"Password"}
            placeholder={"Enter your password"}
            value={password}
            type={"password"}
            setValue={setPassword}
          />
          <div className="flex justify-between my-2 items-center">
            <div>
              {" "}
              <input type={"checkbox"} /> Remember me
            </div>
            <p className=" text-purple-500 m-0">
              <span
                className="hover:cursor-pointer text-purple-500"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <MobileDrawer
                  element={<p className=" text-[#9065B4]">Forgot password</p>}
                />
              </span>
            </p>
          </div>
          <br />
          <p className="text-center text-red-600 m-0">{err}</p>
          <button
            onClick={() => {
              setSubmitClicked(true);
            }}
            className=" purpleBtn p-2 rounded  w-[100%] text-white"
          >
            Sign in
          </button>
          <GoogleLoginex />  

          <p className=" text-gray-400 flex justify-center text-center">
            No account? &nbsp;
            <span
              onClick={() => {
                setOpen(false);
              }}
            >
              <MobileSheetRegistration
                element={<p className=" text-[#9065B4]">Create one</p>}
              />
            </span>
          </p>
        </div>
      </Drawer>
      {/* {console.log("FormDialog:", isDialogOpen)} */}
      {/* {isDialogOpen && <FormDialog state={{ setOpen: true }} />} */}
    </>
  );
};
export default NormalLoginSheet;
