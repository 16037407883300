import React from "react";
import { useParams } from "react-router-dom";
import VehiclesDetailedView from "./VehiclesDetailedView";
import GoldDetailedView from "./GoldDetailedView";
import FurnitureDetailedView from "./FurnitureDetailedView";

const PersonalAssetDetailedView = () => {
  const { personal_asset_type } = useParams();

  let displayText = "";

  switch (personal_asset_type) {
    case "Vehicle":
      displayText = <VehiclesDetailedView />;
      break;
    case "GoldAndJewellery":
      displayText = <GoldDetailedView />;
      break;
    case "FurnitureAndArt":
      displayText = <FurnitureDetailedView />;
      break;
    default:
      displayText = "Unknown";
  }

  return <div>{displayText}</div>;
};

export default PersonalAssetDetailedView;
