import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TimeLineChart = () => {
  const [chartData, setChartData] = useState({
    labels: ["2nd Sep", "5th Sep", "10th Sep", "20th Sep", "30th Sep"], // Static Dates
    datasets: [
      {
        label: "Time Spent",
        data: [10, 17, 32, 25, 30], // Time in minutes
        backgroundColor: "#C1A7D8",
        barThickness: 30,
        borderRadius: 6,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} minutes`,
        },
      },
    },
    scales: {
      y: {
        ticks: {
            beginAtZero: true,
            color: "#334155",
            stepSize: 15,  // Step size of 15 minutes
            callback: (value) => {
              const hours = Math.floor(value / 60);
              const minutes = value % 60;
              return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
            },
          },
        grid: {
          display: true,
        },
      },
      x: {
        ticks: {
          color: "#334155",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="h-[13rem]">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TimeLineChart;
