import RedButton from "components/Button/RedButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import removeLiability from "assets/images/liabilities/removeLiability.svg";
import useDeleteAsset from "./data/useDeleteAsset";
import { useNavigate, useLocation } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";

const RemoveAsset = ({ assetId, triggerElement, heading, deleteId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldClose, setShouldClose] = useState(false);
  const [deleteData, isError, isLoading, deleteDetails] = useDeleteAsset();

  useEffect(() => {
    if (isError) {
      showErrorToast("Details not deleted. Try Again.");
    }
  }, [isError]);

  useEffect(() => {
    if (deleteData) {
      showSuccessToast("Details deleted successfully");
      if (location.pathname.includes("land")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "real-estate-land"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("building")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "real-estate-building"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("sukanya")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "deposits-sukanya-samridhi"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("GoldAndJewellery")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "personal-asset-gold"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("Vehicle")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "personal-asset-vehicle"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("FurnitureAndArt")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "personal-asset-furniture"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("LoansToFamilyFriends")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "other-asset-loan"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("IntellectualProperty")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "other-asset-intellectual"
          )}?id=${assetId}`
        );
      } else if (location.pathname.includes("Domain")) {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "other-asset-domain"
          )}?id=${assetId}`
        );
      }
    }
  }, [deleteData]);

  const handleDeleteLoan = () => {
    deleteDetails(deleteId);
  };

  return (
    <div>
      <DialogBox
        triggerElement={
          triggerElement ? (
            triggerElement
          ) : (
            <RedButton
              px={"px-[0.9rem]"}
              py={"py-[0.3rem]"}
              name="Remove Asset"
            />
          )
        }
        heading={
          <div className="font-[600] text-xl text-[#334155]">
            Remove <span className="text-[#9065B4]">{heading}</span>?
          </div>
        }
        content={(handleClose) => (
          <>
            <div className=" text-center flex flex-col items-center">
              <img
                src={removeLiability}
                alt="remove"
                className="w-[5rem] h-[5rem] mb-3"
              />
              <h4 className="text-[#DC2626] font-[600] text-[0.9rem]">
                Are you sure you want to discard this {heading} from your
                assets?
              </h4>
              {/* <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                This action is permanent and may affect your financial records.
              </h4> */}
              <div className="flex gap-2 mt-[1rem]">
                <button
                  onClick={handleDeleteLoan}
                  className="text-[#DC2626] font-[600] text-[0.9rem] border-1 border-[#FEE2E2] bg-[#FEF2F2] p-2 rounded-md"
                >
                  Yes, Remove
                </button>
                <button
                  onClick={handleClose}
                  className="text-[#334155] font-[600] text-[0.9rem] border py-2 px-4 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
            {/* Conditionally close the dialog if deletion is successful */}
            {shouldClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default RemoveAsset;
